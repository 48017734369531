import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import clsx from 'clsx'
import {format} from 'date-fns'

import RightLayoutPopup from '../../../../Components/RightLayoutPopup'
import DatePicker from '../../../../Components/DatePicker'
import Dropbox from '../../../../Components/Dropbox'

import useScrollLock from '../../../../../utils/useScrollLock'

const CreateDocument = ({documentTypeList, setLoading, handleCreateDocument, onClose}: any) => {
  const intl = useIntl()

  const [documentNumber, setDocumentNumber] = useState<string>('')
  const [documentType, setDocumentType] = useState<any>('')
  const [issueDate, setIssueDate] = useState<any>(null)
  const [loadIssueDate, setLoadIssueDate] = useState<any>('')
  const [expireDate, setExpireDate] = useState<any>(null)
  const [loadExpireDate, setLoadExpireDate] = useState<any>('')
  const [description, setDescription] = useState<any>('')

  const {scrollLock, scrollUnlock} = useScrollLock()

  useEffect(() => {
    scrollLock()
  }, [])

  const handleCreateDisbaled = () => {
    return !documentNumber || !documentType || !description
  }

  return (
    <RightLayoutPopup
      title={intl.formatMessage({id: 'CreateDocument.create.title'})}
      className='carrier-create-document-wrap'
      isCloseIcon
      closeOnClick={() => onClose()}
    >
      <div>
        <div className='row'>
          <div className='fv-row mb-10 col-md-6'>
            <label className='form-label fs-6 fw-bolder text-dark'>
              {intl.formatMessage({id: 'CreateDocument.create.documentNumber'})}
            </label>
            <input
              placeholder={intl.formatMessage({id: 'CreateDocument.create.documentNumber'})}
              className={clsx('form-control form-control-lg form-control-solid')}
              onChange={(event: any) => setDocumentNumber(event.target.value)}
              value={documentNumber}
              type='text'
              name='name'
              autoComplete='off'
            />
          </div>

          <div className={'col-md-6 mb-10'}>
            <label className='form-label fs-6 fw-bolder text-dark'>
              {intl.formatMessage({id: 'CreateDocument.create.documentType'})}
            </label>
            <Dropbox
              hideArrow
              id={'documentType'}
              customClass={'form-select'}
              placeholder={intl.formatMessage({id: 'CreateDocument.create.documentType'})}
              customSearchValue={''}
              customOnSearch={(e: any) => {}}
              disabledSearch
              onScroll={() => {}}
              setScrollCalled={() => {}}
              currentPageIndex={0}
              menuList={
                documentTypeList &&
                documentTypeList.map((item: any) => {
                  return {
                    id: item?.documentTypeId,
                    title: item?.description,
                  }
                })
              }
              selectedItemData={(item: any) => {
                if (item?.id) {
                  setDocumentType(item?.id)
                }
              }}
            />
          </div>
        </div>

        <div className='row'>
          <div className='fv-row mb-10 col-md-6'>
            <label className='form-label fs-6 fw-bolder text-dark'>
              {intl.formatMessage({id: 'CreateDocument.dataTable.issueDate'})}
            </label>
            <DatePicker
              value={issueDate}
              onSelect={(data: any) => {
                setIssueDate(data)
                setLoadIssueDate(format(data, 'yyyy-MM-dd'))
              }}
              selected={!!loadIssueDate}
              customDate={issueDate}
              disabledDays={new Date()}
            />
          </div>

          <div className='fv-row mb-10 col-md-6'>
            <label className='form-label fs-6 fw-bolder text-dark'>
              {intl.formatMessage({id: 'CreateDocument.dataTable.expireDate'})}
            </label>
            <DatePicker
              value={expireDate}
              onSelect={(data: any) => {
                setExpireDate(data)
                setLoadExpireDate(format(data, 'yyyy-MM-dd'))
              }}
              selected={!!loadExpireDate}
              customDate={expireDate}
            />
          </div>
        </div>

        <div className='row'>
          <div className='col-md-12 mb-10'>
            <label className='form-label fs-6 fw-bolder text-dark'>
              {intl.formatMessage({id: 'Carrier.freightPosting.description'})}
            </label>
            <input
              placeholder={intl.formatMessage({id: 'Carrier.freightPosting.description'})}
              className={clsx('form-control form-control-lg form-control-solid')}
              onChange={(event: any) => setDescription(event.target.value)}
              value={description}
              type='text'
              name='name'
              autoComplete='off'
            />
          </div>
        </div>

        <div className='text-center mt-5 row freightPost-popup-container'>
          <div className={'col-md-6'}>
            <button
              onClick={() => {
                onClose()
                scrollUnlock()
              }}
              type='button'
              id='kt_sign_up_submit'
              className='btn btn-lg btn-danger w-100 mb-5 '
            >
              {intl.formatMessage({id: 'CreateNewContainerPopup.cancel'})}
            </button>
          </div>
          <div className={'col-md-6'}>
            <button
              className={'btn btn-success w-100'}
              onClick={() => {
                setLoading(true)
                handleCreateDocument({
                  documentNumber: documentNumber,
                  documentType: documentType,
                  description: description,
                  issueDate: !!issueDate ? Math.round(new Date(issueDate).getTime() / 1000) : null,
                  expireDate: !!expireDate
                    ? Math.round(new Date(expireDate).getTime() / 1000)
                    : null,
                })
              }}
              disabled={handleCreateDisbaled()}
            >
              {intl.formatMessage({id: 'Admin.Adverts.createProposal.create'})}
            </button>
          </div>
        </div>
      </div>
    </RightLayoutPopup>
  )
}

export default CreateDocument
