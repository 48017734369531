import React, {Suspense} from 'react'
import {useIntl} from 'react-intl'

import RightLayoutPopup from '../../../../Components/RightLayoutPopup'

const fileExtentionList: any = ['jpg', 'jpeg', 'png', 'jfif', 'pjpeg', 'pjp', 'svg', 'webp']

const videoExtensionList: any = [
  'webm',
  'mpg',
  'mpeg',
  'ogg',
  'mp4',
  'm4p',
  'm4v',
  'avi',
  'wmv',
  'mov',
  'flv',
]

const ShowDetails = ({setShowFile, selectedFile}: any) => {
  const intl = useIntl()

  return (
    <RightLayoutPopup
      title={intl.formatMessage({id: 'ADMIN.FILEREVIEW.DETAILTITLE'})}
      className='file-review-view-wrap'
      isCloseIcon
      closeOnClick={() => setShowFile(false)}
    >
      <div className='row'>
        <div className={'col-md-4 mb-5 '}>
          <p className={'mb-0 text-muted'}>{intl.formatMessage({id: 'admin.fileReview.title'})}</p>
          <b>{selectedFile?.title}</b>
        </div>
        <div className={'col-md-4 mb-5 '}>
          <p className={'mb-0 text-muted'}>
            {intl.formatMessage({id: 'ADMIN.FILEREVIEW.TABLE.FILETYPE'})}
          </p>
          <b>{selectedFile?.type}</b>
        </div>

        <div className={'col-md-4 mb-5 '}>
          <p className={'mb-0 text-muted'}>
            {intl.formatMessage({id: 'Admin.Adverts.table_status'})}
          </p>
          <b>{selectedFile?.status}</b>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-12'>
          {selectedFile.fileType === 'pdf' && selectedFile?.url && (
            <iframe
              src={selectedFile?.url}
              title={selectedFile?.title}
              width='100%'
              height='580px'
            />
          )}
          {fileExtentionList.includes(selectedFile?.fileType) && selectedFile?.url && (
            <Suspense fallback={<>Loading...</>}>
              <img
                className={'w-100 h-100 img-fluid'}
                src={selectedFile?.url}
                alt={selectedFile?.title}
              />
            </Suspense>
          )}

          {videoExtensionList.includes(selectedFile?.fileType) && selectedFile?.url && (
            <video
              className='home-preview-video'
              loop={true}
              autoPlay={true}
              muted={true}
              style={{width: '100%'}}
            >
              <source src={selectedFile?.url} />
            </video>
          )}
        </div>
      </div>

      <div className='text-center mt-5 row view-details-freight-popup'>
        <div className={'col-md-6 advert-details-modal-button-close'}>
          <button
            onClick={() => {
              setShowFile(false)
            }}
            type='button'
            id='kt_sign_up_submit'
            className='btn btn-lg btn-danger w-100 mb-5 '
          >
            {intl.formatMessage({id: 'Carrier.freightPosting.view.close'})}
          </button>
        </div>
      </div>
    </RightLayoutPopup>
  )
}

export {ShowDetails}
