import React, {useState, useEffect} from 'react'
import {useIntl} from 'react-intl'
import clsx from 'clsx'

import RightLayoutPopup from '../../../../Components/RightLayoutPopup'

import useScrollLock from '../../../../../utils/useScrollLock'

const UploadFile = ({
  selectedVehicle,
  vehicleMetadata,
  uploadVehicleLicense,
  uploadVehiclePlate,
  uploadVehicleCarnet,
  onClose,
}: any) => {
  const intl = useIntl()
  const {scrollLock} = useScrollLock()
  useEffect(() => {
    scrollLock()
  }, [])

  const [vehicleFiles, setVehicleFiles] = useState<any>({
    vehiclePlate: null,
    vehicleCarnet: null,
    vehicleLicense: null,
  })

  return (
    <>
      <RightLayoutPopup
        title={intl.formatMessage({id: 'Carrier.vehicles.upload.uploadDoc'})}
        className='carrier-file-vehicle-side-wrap'
        isCloseIcon
        closeOnClick={() => onClose()}
      >
        <div className={''}>
          <div className={''}>
            <div className='fv-row mb-10'>
              <label className='form-label fs-6 fw-bolder text-dark'>
                {intl.formatMessage({id: 'Carrier.vehicles.upload.truckCarnet'})}
              </label>
              <input
                disabled={
                  vehicleMetadata?.find((item: any) => item?.fileType?.code === 'TIR_CARNET')
                    ?.fileStatus?.fileStatusId === 4
                }
                placeholder={intl.formatMessage({
                  id: 'Carrier.vehicles.upload.truckCarnet.placeHolder',
                })}
                className={clsx('form-control form-control-lg')}
                onChange={(event: any) =>
                  setVehicleFiles((item: any) => ({
                    ...item,
                    vehicleCarnet: event.target.files[0],
                  }))
                }
                type='file'
                name='Vehicle Carnet'
                autoComplete='off'
              />
              <p className={'text-danger mb-3'}>
                {vehicleMetadata?.find((item: any) => item?.fileType?.code === 'TIR_CARNET')
                  ?.fileStatus?.fileStatusId === 4 &&
                  intl.formatMessage({id: 'Carrier.drivers.file.alreadyApproved'})}
              </p>
              <p className={'text-danger mb-3'}>
                {vehicleMetadata?.find((item: any) => item?.fileType?.code === 'TIR_CARNET')
                  ?.fileStatus?.fileStatusId === 1 &&
                  intl.formatMessage({id: 'Carrier.drivers.file.approved'})}
              </p>
            </div>
            <div className='fv-row mb-10'>
              <label className='form-label fs-6 fw-bolder text-dark'>
                {intl.formatMessage({id: 'Carrier.vehicles.upload.numberPlate'})}
              </label>
              <input
                disabled={
                  vehicleMetadata?.find((item: any) => item?.fileType?.code === 'PLATE')?.fileStatus
                    ?.fileStatusId === 4
                }
                placeholder={intl.formatMessage({id: 'Carrier.vehicles.upload.numberPlate'})}
                className={clsx('form-control form-control-lg')}
                onChange={(event: any) =>
                  setVehicleFiles((item: any) => ({
                    ...item,
                    vehiclePlate: event.target.files[0],
                  }))
                }
                type='file'
                name='SRC License'
                autoComplete='off'
              />
              <p className={'text-danger mb-3'}>
                {vehicleMetadata?.find((item: any) => item?.fileType?.code === 'PLATE')?.fileStatus
                  ?.fileStatusId === 4 &&
                  intl.formatMessage({id: 'Carrier.drivers.file.alreadyApproved'})}
              </p>
              <p className={'text-danger mb-3'}>
                {vehicleMetadata?.find((item: any) => item?.fileType?.code === 'PLATE')?.fileStatus
                  ?.fileStatusId === 1 && intl.formatMessage({id: 'Carrier.drivers.file.approved'})}
              </p>
            </div>
            <div className='fv-row mb-10'>
              <label className='form-label fs-6 fw-bolder text-dark'>
                {intl.formatMessage({id: 'Carrier.vehicles.upload.vehicleLicense'})}
              </label>
              <input
                disabled={
                  vehicleMetadata?.find((item: any) => item?.fileType?.code === 'VEHICLE_LICENCE')
                    ?.fileStatus?.fileStatusId === 4
                }
                placeholder={intl.formatMessage({
                  id: 'Carrier.vehicles.upload.vehicleLicense.placeHolder',
                })}
                className={clsx('form-control form-control-lg')}
                onChange={(event: any) =>
                  setVehicleFiles((item: any) => ({
                    ...item,
                    vehicleLicense: event.target.files[0],
                  }))
                }
                type='file'
                name='Vehicle License'
                autoComplete='off'
              />
              <p className={'text-danger mb-3'}>
                {vehicleMetadata?.find((item: any) => item?.fileType?.code === 'VEHICLE_LICENCE')
                  ?.fileStatus?.fileStatusId === 4 &&
                  intl.formatMessage({id: 'Carrier.drivers.file.alreadyApproved'})}
              </p>
              <p className={'text-danger mb-3'}>
                {vehicleMetadata?.find((item: any) => item?.fileType?.code === 'VEHICLE_LICENCE')
                  ?.fileStatus?.fileStatusId === 1 &&
                  intl.formatMessage({id: 'Carrier.drivers.file.approved'})}
              </p>
            </div>
          </div>
        </div>

        <div className='text-center mt-5 row freightPost-popup-container'>
          <div className={'col-md-6'}>
            <button
              onClick={onClose}
              type='button'
              id='kt_sign_up_submit'
              className='btn btn-lg btn-danger w-100 mb-5 '
            >
              {intl.formatMessage({id: 'CreateNewContainerPopup.cancel'})}
            </button>
          </div>
          <div className={'col-md-6'}>
            <button
              className={'btn btn-info px-5 w-100'}
              onClick={() => {
                vehicleFiles.vehicleLicense &&
                  uploadVehicleLicense({
                    id: selectedVehicle.id,
                    file: vehicleFiles.vehicleLicense,
                  })
                vehicleFiles.vehiclePlate &&
                  setTimeout(
                    () =>
                      uploadVehiclePlate({
                        id: selectedVehicle.id,
                        file: vehicleFiles.vehiclePlate,
                      }),
                    100
                  )
                vehicleFiles.vehicleCarnet &&
                  setTimeout(
                    () =>
                      uploadVehicleCarnet({
                        id: selectedVehicle.id,
                        file: vehicleFiles.vehicleCarnet,
                      }),
                    200
                  )
              }}
            >
              {intl.formatMessage({id: 'Carrier.vehicles.upload.uploadDocument'})}
            </button>
          </div>
        </div>
      </RightLayoutPopup>
    </>
  )
}

export default UploadFile
