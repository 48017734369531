import React from 'react'
import {useIntl} from 'react-intl'

import {Header} from '../app/pages/DefaultPages/NewHomePage/Header/Header'
import {FooterContent} from '../app/pages/DefaultPages/NewHomePage/FooterContent/FooterContent'
import {BlogsData} from '../utils/common'

import './blogs.scss'

const BlogsComponent = () => {
  const intl = useIntl()

  return (
    <div className='about-us-wrap-content main-content-home-wrap'>
      <Header />
      <div className='container mt-15' style={{padding: 0}}>
        <div className='row mb-8'>
          <div className='col-md-12 d-flex justify-content-center blog_main_title_wrap'>
            <h1>{intl.formatMessage({id: 'footer.blog.title'})}</h1>
          </div>
        </div>
        <div className='row'>
          {BlogsData(intl).map((item: any) => {
            return (
              <div className='col-4 mb-10 blog_mobile_wrap'>
                <article className='blog-card' style={{paddingBottom: '5px'}}>
                  <div className='blog-card__background'>
                    <div className='card__background--wrapper'>
                      <div
                        className='card__background--main'
                        style={{backgroundImage: `url("${item.imgUrl}")`}}
                      >
                        <div className='card__background--layer'></div>
                      </div>
                    </div>
                  </div>
                  <div className='blog-card__head' onClick={() => window.open(item.url, '_blank')}>
                    <span className='date__box'>
                      <span className='date__month'>
                        {intl.formatMessage({id: 'relatedBlogs.readMore'})}
                      </span>
                    </span>
                  </div>
                  <div className='blog-card__info'>
                    <h5>{item.title}</h5>
                    <p className='mt-2'>{item.description}</p>
                    <a
                      href={item.url}
                      rel='noreferrer'
                      className='btn btn--with-icon mt-2 read-more-wrap'
                      target='_blank'
                    >
                      <i className='btn-icon fa fa-long-arrow-right'></i>
                      {intl.formatMessage({id: 'relatedBlogs.readMore'})}
                    </a>
                  </div>
                </article>
              </div>
            )
          })}
        </div>
      </div>
      <FooterContent />
    </div>
  )
}

export default BlogsComponent
