import React from 'react';
import './Loading.scss'

const Loading = () => {
    return <div className={'loading-component-main-layer'}>
        <button className={'loading-btn'}></button >
    </div>;
};

export default Loading;
