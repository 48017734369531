import React, {useState} from 'react'
import Slider from 'react-input-slider'

const NumberSlider = ({value, disabled, min, max, defaultValue}: any) => {
  const [state, setState] = useState({x: defaultValue, y: max})
  return (
    <Slider
      disabled={disabled}
      xmin={min}
      xmax={max}
      axis='x'
      x={state.x}
      styles={{
        track: {width: '100%'},
      }}
      onChange={({x}: any) => {
        setState((state) => ({...state, x}))
        value && value(x)
      }}
    />
  )
}

export default NumberSlider
