import React, {useEffect, useState} from 'react'
import {toast} from 'react-toastify'
import {useIntl} from 'react-intl'

import {PageTitle} from '../../../../_metronic/layout/core'
import {KTSVG} from '../../../../_metronic/helpers'

import Loading from '../../../Components/Loading'
import DataTableAdvanced from '../../../Components/DataTableAdvanced'
import Modal from '../../../Components/Modal'
import CreateDriver from './Popups/CreateDriver'
import EditDriver from './Popups/EditDriver'
import ShowDriverDetail from './Popups/ShowDriverDetail'
import ShowFile from './Popups/ShowFile'

import useScrollLock from '../../../../utils/useScrollLock'

import {DriversService} from '../../../../services/CarrierServices/DriversService'

import './Drivers.scss'

const Drivers = () => {
  const intl = useIntl()
  /**
   ** UTILS
   **/
  const driverServ = new DriversService()
  const [loading, setLoading] = useState<boolean>(false)
  const [pageMIndex, setPageMIndex] = useState<number>(0)
  /* const intl = useIntl()*/

  /*List States*/
  const [driverList, setDriverList] = useState<any[]>([])
  const [driverMetadata, setDriverMetadata] = useState<any[]>([])

  /*Screens and modals*/
  const [selectedDriver, setSelectedDriver] = useState<any>(null)
  const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false)
  const [showCreatePopup, setShowCreatePopup] = useState<boolean>(false)
  const [showUpdatePopup, setShowUpdatePopup] = useState<boolean>(false)
  const [showDetailPopup, setShowDetailPopup] = useState<boolean>(false)
  const [showFilePopup, setShowFilePopup] = useState<boolean>(false)

  const [isNextPage, setIsNextPage] = useState<boolean>(false)

  const {scrollUnlock} = useScrollLock()
  useEffect(() => {
    if (
      !showFilePopup &&
      !showDetailPopup &&
      !showCreatePopup &&
      !showDeletePopup &&
      !showUpdatePopup
    )
      scrollUnlock()
  }, [showFilePopup, showDetailPopup, showCreatePopup, showDeletePopup, showUpdatePopup])

  /*Popup States*/
  const [newDriver, setNewDriver] = useState<any>({
    name: '',
    surname: '',
    mobile: '',
    phoneCode: '+90',
  })
  const [updatedDriver, setUpdatedDriver] = useState<any>({
    id: -1,
    name: '',
    surname: '',
    mobile: '',
    phoneCode: '',
  })
  const [driverFiles, setDriverFiles] = useState<any>({srcLicense: null, driverLicense: null})

  /**
   *! API CALL START
   **/
  const getDriversList = (pageIndex?: number) => {
    driverServ
      .getDrivers(pageIndex)
      .then((resp: any) => {
        setDriverList(resp?.data)
        setLoading(false)
        setIsNextPage(false)
      })
      .catch((e) => {
        setIsNextPage(false)
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const getDriver = (userId: number) => {
    return driverServ.getDriver(userId)
  }

  const deleteDriver = (userId: number) => {
    driverServ
      .deleteDriver(userId)
      .then((resp: any) => {
        toast.success(intl.formatMessage({id: 'Carrier.drivers.driverDeletedSuccess'}))
        getDriversList(pageMIndex)
        setShowDeletePopup(false)
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const updateDriver = (data: {
    id: number
    name: string
    surname: string
    mobile: string
    phoneCode: string
  }) => {
    driverServ
      .updateDriver(data.id, data.name, data.surname, data.mobile, data.phoneCode)
      .then((resp: any) => {
        toast.success(intl.formatMessage({id: 'Carrier.drivers.driverUpdatedSuccess'}))
        getDriversList(pageMIndex)
        setShowUpdatePopup(false)
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const createDriver = (data: {
    name: string
    surname: string
    mobile: string
    phoneCode: string
  }) => {
    driverServ
      .createDriver(data.name, data.surname, data.mobile, data.phoneCode)
      .then((resp: any) => {
        toast.success(intl.formatMessage({id: 'Carrier.drivers.driverCreatedSuccess'}))
        getDriversList(pageMIndex)
        setShowCreatePopup(false)
        setNewDriver({name: '', surname: '', mobile: '', phoneCode: '+90'})
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const uploadDriverLicense = (userId: number, file: any) => {
    driverServ
      .uploadDriverLicense(userId, file)
      .then((resp: any) => {
        toast.success(intl.formatMessage({id: 'Carrier.drivers.driverLicenseSuccess'}))
        setLoading(false)
        setShowFilePopup(false)
        setDriverFiles({...driverFiles, driverLicense: null})
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const uploadSrcLicense = (userId: number, file: any) => {
    driverServ
      .uploadSrcLicense(userId, file)
      .then((resp: any) => {
        toast.success(intl.formatMessage({id: 'Carrier.drivers.srcSuccess'}))
        setLoading(false)
        setShowFilePopup(false)
        setDriverFiles({...driverFiles, srcLicense: null})
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const getAllMetadata = (userId: number) => {
    driverServ
      .getAllMetadata(userId)
      .then((resp: any) => {
        setDriverMetadata(resp.data)
        setLoading(false)
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
        setLoading(false)
      })
  }

  const downloadFiles = (url: string, type: string, name: string) => {
    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application+file/json',
      },
    })
      .then((res: any) => res.blob())
      .then((resp: any) => {
        // create file link in browser's memory
        const href = URL.createObjectURL(new Blob([resp]))
        // create "a" HTML element with href to file & click
        const link = document.createElement('a')
        link.href = href
        link.setAttribute('download', type + '.' + name.split('.').reverse()[0]) //or any other extension
        document.body.appendChild(link)
        link.click()
        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link)
        URL.revokeObjectURL(href)
        setLoading(false)
      })
  }
  /**
   *! API CALL END
   **/

  const onPaginationChange = (page: number) => {
    if (!isNextPage) {
      getDriversList(page)
      setPageMIndex(page)
    }

    setIsNextPage(true)
  }

  useEffect(() => {
    setLoading(true)
    getDriversList(pageMIndex)
  }, [])

  return (
    <>
      {loading && <Loading />}
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'Carrier.drivers.Title'})}</PageTitle>
      <div className={'d-flex align-items-center justify-content-end'}>
        <button
          onClick={() => setShowCreatePopup(true)}
          className='btn btn-sm btn-light-primary mb-5 border border-2 fs-4'
        >
          <KTSVG path='media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
          {intl.formatMessage({id: 'Carrier.drivers.createNewDriver'})}
        </button>
      </div>
      <div>
        {!driverList.length && (
          <div className='alert alert-warning mb-5 fw-bolder' role='alert'>
            {intl.formatMessage({id: 'Carrier.drivers.noData'})}
          </div>
        )}
        <DataTableAdvanced
          columns={[
            {
              Header: 'ID',
              accessor: 'id',
            },
            {
              Header: intl.formatMessage({id: 'Carrier.drivers.name'}),
              accessor: 'name',
            },

            {
              Header: intl.formatMessage({id: 'Carrier.drivers.lastName'}),
              accessor: 'surname',
            },
            {
              Header: intl.formatMessage({id: 'Freight.users.countryCode'}),
              accessor: 'countryCode',
              cellStyle: {
                textAlign: 'center',
              },
            },
            {
              Header: intl.formatMessage({id: 'Carrier.drivers.phone'}),
              accessor: 'mobile',
            },
            {
              Header: intl.formatMessage({id: 'Carrier.drivers.status'}),
              accessor: 'status',
            },
            {
              Header: intl.formatMessage({id: 'Carrier.drivers.temporaryPass'}),
              accessor: 'initialPassword',
            },
          ]}
          data={
            driverList &&
            driverList?.map((item: any) => {
              return {
                id: item?.userId,
                name: item?.name,
                surname: item?.surname,
                countryCode: item?.countryCode,
                mobile: item?.mobile,
                status: item?.userStatus?.description,
                initialPassword: item?.initialPassword,
              }
            })
          }
          editBtn={(row: any) => {
            setLoading(true)
            setSelectedDriver(row.values)
            getDriver(row.values.id)
              .then((resp: any) => {
                setLoading(false)
                setUpdatedDriver((item: any) => ({
                  ...item,
                  id: resp.data.userId,
                  name: resp.data.name,
                  surname: resp.data.surname,
                  mobile: resp.data.mobile,
                  phoneCode: resp.data.countryCode,
                }))
                setShowUpdatePopup(true)
              })
              .catch((e) => {
                toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
                setLoading(false)
              })
          }}
          showDetailBtn={(row: any) => {
            setLoading(true)
            setSelectedDriver(row.values)
            getDriver(row.values.id)
              .then((resp: any) => {
                setLoading(false)
                setUpdatedDriver((item: any) => ({
                  ...item,
                  id: resp.data.userId,
                  name: resp.data.name,
                  surname: resp.data.surname,
                  mobile: resp.data.mobile.replace(resp.data.countryCode, ''),
                  phoneCode: resp.data.countryCode,
                }))
                getAllMetadata(resp.data.userId)
                setShowDetailPopup(true)
              })
              .catch((e) => {
                toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
                setLoading(false)
              })
          }}
          showFile={(row: any) => {
            setLoading(true)
            getAllMetadata(row.values.id)
            setSelectedDriver(row.values)
            setShowFilePopup(true)
          }}
          deleteRow={(row: any) => {
            setSelectedDriver(row.values)
            setShowDeletePopup(true)
          }}
          deleteText={intl.formatMessage({id: 'Carrier.drivers.tabel.deleteText'})}
          editableRows={['']}
          setPageMIndex={onPaginationChange}
          pageMIndex={pageMIndex}
        />
      </div>

      {/*DELETE POPUP*/}
      {showDeletePopup && (
        <Modal
          closeBtnSettings={{
            show: true,
            onClick: () => setShowDeletePopup(false),
          }}
          customAll={
            <div className={''}>
              <h3 className={'mt-5 mb-15'}>
                {intl.formatMessage({id: 'Carrier.drivers.delete.confirmDelete'})}
              </h3>
              <div className={'row'}>
                <div className={'col-md-6'}>
                  <button
                    className={'btn btn-danger w-100'}
                    onClick={() => {
                      setShowDeletePopup(false)
                    }}
                  >
                    {intl.formatMessage({id: 'Carrier.drivers.delete.cancel'})}
                  </button>
                </div>
                <div className={'col-md-6'}>
                  <button
                    className={'btn btn-success w-100'}
                    onClick={() => {
                      setLoading(true)
                      deleteDriver(selectedDriver.id)
                    }}
                  >
                    {intl.formatMessage({id: 'Carrier.drivers.delete.approve'})}
                  </button>
                </div>
              </div>
            </div>
          }
        />
      )}

      {/*CREATE POPUP*/}
      {showCreatePopup && (
        <CreateDriver
          setNewDriver={setNewDriver}
          newDriver={newDriver}
          setLoading={setLoading}
          createDriver={createDriver}
          onClose={() => {
            setShowCreatePopup(false)
            setNewDriver({name: '', surname: '', mobile: ''})
          }}
        />
      )}

      {/*UPDATE POPUP*/}
      {showUpdatePopup && (
        <EditDriver
          setUpdatedDriver={setUpdatedDriver}
          updatedDriver={updatedDriver}
          setLoading={setLoading}
          updateDriver={updateDriver}
          onClose={() => setShowUpdatePopup(false)}
        />
      )}

      {/*DETAIL POPUP*/}
      {showDetailPopup && (
        <ShowDriverDetail
          setUpdatedDriver={setUpdatedDriver}
          updatedDriver={updatedDriver}
          driverMetadata={driverMetadata}
          setLoading={setLoading}
          downloadFiles={downloadFiles}
          onClose={() => setShowDetailPopup(false)}
        />
      )}

      {/*FILE POPUP*/}
      {showFilePopup && (
        <ShowFile
          driverMetadata={driverMetadata}
          setDriverFiles={setDriverFiles}
          driverFiles={driverFiles}
          setLoading={setLoading}
          uploadDriverLicense={uploadDriverLicense}
          uploadSrcLicense={uploadSrcLicense}
          selectedDriver={selectedDriver}
          onClose={() => setShowFilePopup(false)}
        />
      )}
    </>
  )
}
export {Drivers}
