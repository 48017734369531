/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {useLocation} from 'react-router'
import {shallowEqual, useSelector} from 'react-redux'

import {getCurrentUrl} from '../../../helpers'

import {RootState} from '../../../../setup'
import {AsideMenuItem} from './AsideMenuItem'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'

export function AsideMenuMain() {
  const intl = useIntl()
  const {pathname} = useLocation()
  const role: any = useSelector<RootState>(({auth}) => auth.role, shallowEqual)
  const urlPath = getCurrentUrl(pathname)
  const FREIGHT_OPERATOR = () => (
    <>
      <AsideMenuItem to='#' icon='' title='§ FREIGHT OPERATOR §' fontIcon='bi-layers' />
      <AsideMenuItem
        to='/fr-op/dashboard'
        icon='/media/icons/duotune/general/gen001.svg'
        title={intl.formatMessage({id: 'SideMenu.freightOperator.homePage'})}
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItemWithSub
        title={intl.formatMessage({id: 'SideMenu.carrierOperator.freight'})}
        to={
          urlPath &&
          ['/fr-op/create-new-advert', '/fr-op/create-quick-new-advert'].includes(urlPath)
            ? urlPath
            : ''
        }
        icon='/media/freight.svg'
      >
        <AsideMenuItem
          to='/fr-op/create-new-advert'
          icon='/media/icons/duotune/arrows/arr013.svg'
          title={intl.formatMessage({id: 'SideMenu.freightOperator.newFreight'})}
          fontIcon='bi-layers'
        />
        <AsideMenuItem
          to='/fr-op/create-quick-new-advert'
          icon='/media/icons/duotune/general/create_freight_icon.svg'
          title={intl.formatMessage({id: 'Carrier.freight.createQuickFreight'})}
          fontIcon='bi-layers'
        />
      </AsideMenuItemWithSub>
      <AsideMenuItem
        to='/fr-op/my-adverts'
        icon='/media/icons/duotune/general/gen056.svg'
        title={intl.formatMessage({id: 'SideMenu.freightOperator.myFreights'})}
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/fr-op/company-information'
        icon='/media/icons/duotune/general/gen019.svg'
        title={intl.formatMessage({id: 'SideMenu.freightOperator.companyInfo'})}
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/fr-op/track-on-map'
        title={intl.formatMessage({id: 'SideMenu.freightOperator.whereTools'})}
        fontIcon='bi-sticky'
        icon='/media/icons/duotune/maps/map003.svg'
      />
      <AsideMenuItem
        to='/fr-op/addresses'
        title={intl.formatMessage({id: 'SideMenu.freightOperator.addresses'})}
        fontIcon='bi-sticky'
        icon='/media/icons/duotune/general/gen018.svg'
      />
      <AsideMenuItem
        to='/fr-op/invoice-address'
        title={intl.formatMessage({id: 'SideMenu.freightOperator.billingAddresses'})}
        fontIcon='bi-sticky'
        icon='/media/billing_address.svg'
      />
      <AsideMenuItem
        to='/fr-op/users'
        title={intl.formatMessage({id: 'SideMenu.freightOperator.user'})}
        fontIcon='bi-sticky'
        icon='/media/icons/duotune/general/gen049.svg'
      />
      <AsideMenuItem
        to='/fr-op/client'
        icon='/media/icons/duotune/general/client_icon.svg'
        title={intl.formatMessage({id: 'SideMenu.carrierOperator.clients'})}
        fontIcon='bi-layers'
      />

      <AsideMenuItemWithSub
        title={intl.formatMessage({id: 'freight.myListing.group.vehicle'})}
        to={
          urlPath && ['/fr-op/my-listings', '/fr-op/my-messages'].includes(urlPath) ? urlPath : ''
        }
        icon='/media/trade.svg'
      >
        <AsideMenuItem
          to='/fr-op/my-listings'
          icon='/media/listing.svg'
          title={intl.formatMessage({id: 'freight.myListings.title'})}
          fontIcon='bi-layers'
        />

        <AsideMenuItem
          to='/fr-op/my-messages'
          icon='/media/messages.svg'
          title={intl.formatMessage({id: 'freight.myListing.myMessages'})}
          fontIcon='bi-layers'
        />
      </AsideMenuItemWithSub>
    </>
  )
  const SYSTEM_OPERATOR = () => (
    <>
      <AsideMenuItem to='#' icon='' title='§ SYSTEM OPERATOR §' fontIcon='bi-layers' />
      <AsideMenuItem
        to='/admin/dashboard'
        icon='/media/icons/duotune/general/gen001.svg'
        title={intl.formatMessage({id: 'SideMenu.systemOperator.homePage'})}
        fontIcon='bi-app-indicator'
      />

      <AsideMenuItemWithSub
        title={intl.formatMessage({id: 'sidemenu.freightOperations.title'})}
        to={urlPath && ['/admin/adverts', '/admin/containers'].includes(urlPath) ? urlPath : ''}
        icon='/media/frOp.svg'
      >
        <AsideMenuItem
          to='/admin/adverts'
          icon='/media/icons/duotune/communication/com009.svg'
          title={intl.formatMessage({id: 'SideMenu.systemOperator.freights'})}
          fontIcon='bi-app-indicator'
        />

        <AsideMenuItem
          to='/admin/containers'
          icon='/media/icons/duotune/ecommerce/ecm009.svg'
          title={intl.formatMessage({id: 'SideMenu.systemOperator.containers'})}
          fontIcon='bi-layers'
        />
      </AsideMenuItemWithSub>

      <AsideMenuItem
        to='/admin/transports'
        icon='/media/transports.svg'
        title={intl.formatMessage({id: 'SideMenu.systemOperator.transports'})}
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/admin/track-on-map'
        title={intl.formatMessage({id: 'sidemenu.vehicleTrack.title'})}
        fontIcon='bi-sticky'
        icon='/media/icons/duotune/maps/map003.svg'
      />
      <AsideMenuItem
        to='/register'
        title={intl.formatMessage({id: 'SideMenu.systemOperator.createAccount'})}
        fontIcon='bi-sticky'
        icon='/media/icons/duotune/general/gen049.svg'
      />
      <AsideMenuItem
        to='/admin/file-review'
        title={intl.formatMessage({id: 'SideMenu.systemOperator.viewDocuments'})}
        fontIcon='bi-sticky'
        icon='/media/icons/duotune/files/fil008.svg'
      />
      <AsideMenuItem
        to='/admin/companies'
        title={intl.formatMessage({id: 'SideMenu.systemOperator.companies'})}
        fontIcon='bi-sticky'
        icon='/media/icons/duotune/files/fil002.svg'
      />
      <AsideMenuItem
        to='/admin/manage-proposals'
        title={intl.formatMessage({id: 'SideMenu.systemOperator.manageProposal'})}
        fontIcon='bi-sticky'
        icon='/media/icons/duotune/files/fil0026.svg'
      />
    </>
  )
  const CARRIER_OPERATOR = () => (
    <>
      <AsideMenuItem to='#' icon='' title='§ CARRIER OPERATOR §' fontIcon='bi-layers' />
      <AsideMenuItem
        to='/carrier/dashboard'
        icon='/media/icons/duotune/general/gen001.svg'
        title={intl.formatMessage({id: 'SideMenu.carrierOperator.homePage'})}
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItemWithSub
        title={intl.formatMessage({id: 'SideMenu.carrierOperator.freight'})}
        to={
          urlPath &&
          ['/carrier/create-new-advert', '/carrier/create-quick-new-advert'].includes(urlPath)
            ? urlPath
            : ''
        }
        icon='/media/freight.svg'
      >
        <AsideMenuItem
          to='/carrier/create-new-advert'
          icon='/media/icons/duotune/arrows/arr013.svg'
          title={intl.formatMessage({id: 'SideMenu.carrierOperator.newFreight'})}
          fontIcon='bi-layers'
        />
        <AsideMenuItem
          to='/carrier/create-quick-new-advert'
          icon='/media/icons/duotune/general/create_freight_icon.svg'
          title={intl.formatMessage({id: 'Carrier.freight.createQuickFreight'})}
          fontIcon='bi-layers'
        />
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub
        title={intl.formatMessage({id: 'sidemenu.freightOperations.title'})}
        to={
          urlPath && ['/carrier/my-adverts', '/carrier/containers'].includes(urlPath) ? urlPath : ''
        }
        icon='/media/frOp.svg'
      >
        <AsideMenuItem
          to='/carrier/my-adverts'
          icon='/media/icons/duotune/general/gen056.svg'
          title={intl.formatMessage({id: 'SideMenu.carrierOperator.myFreights'})}
          fontIcon='bi-layers'
        />

        <AsideMenuItem
          to='/carrier/containers'
          icon='/media/icons/duotune/ecommerce/ecm009.svg'
          title={intl.formatMessage({id: 'SideMenu.systemOperator.containers'})}
          fontIcon='bi-layers'
        />
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub
        title={intl.formatMessage({id: 'sidemenu.transportation.title'})}
        to={
          urlPath &&
          [
            '/carrier/drivers',
            '/carrier/vehicles',
            '/carrier/trailers',
            '/carrier/transporters',
            '/carrier/addresses',
            '/carrier/preferred-route',
          ].includes(urlPath)
            ? urlPath
            : ''
        }
        icon='/media/tranaportation.svg'
      >
        <AsideMenuItem
          to='/carrier/drivers'
          icon='/media/icons/duotune/communication/com006.svg'
          title={intl.formatMessage({id: 'SideMenu.carrierOperator.drivers'})}
          fontIcon='bi-layers'
        />

        <AsideMenuItem
          to='/carrier/vehicles'
          icon='/media/icons/duotune/ecommerce/ecm006.svg'
          title={intl.formatMessage({id: 'SideMenu.carrierOperator.Vehicles'})}
          fontIcon='bi-layers'
        />

        <AsideMenuItem
          to='/carrier/trailers'
          icon='/media/icons/duotune/graphs/gra011.svg'
          title={intl.formatMessage({id: 'SideMenu.carrierOperator.trailers'})}
          fontIcon='bi-layers'
        />

        <AsideMenuItem
          to='/carrier/transporters'
          icon='/media/icons/duotune/ecommerce/ecm006.svg'
          title={intl.formatMessage({id: 'SideMenu.carrierOperator.transporters'})}
          fontIcon='bi-layers'
        />

        <AsideMenuItem
          to='/carrier/addresses'
          title={intl.formatMessage({id: 'SideMenu.freightOperator.addresses'})}
          fontIcon='bi-sticky'
          icon='/media/icons/duotune/general/gen018.svg'
        />

        <AsideMenuItem
          to='/carrier/preferred-route'
          title={intl.formatMessage({id: 'SideMenu.carrierOperator.preferredRoute'})}
          fontIcon='bi-sticky'
          icon='/media/icons/duotune/maps/map002.svg'
        />
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub
        title={intl.formatMessage({id: 'freight.myListing.group.vehicle'})}
        to={
          urlPath && ['/carrier/my-listings', '/carrier/my-messages'].includes(urlPath)
            ? urlPath
            : ''
        }
        icon='/media/trade.svg'
      >
        <AsideMenuItem
          to='/carrier/my-listings'
          icon='/media/listing.svg'
          title={intl.formatMessage({id: 'freight.myListings.title'})}
          fontIcon='bi-layers'
        />

        <AsideMenuItem
          to='/carrier/my-messages'
          icon='/media/messages.svg'
          title={intl.formatMessage({id: 'freight.myListing.myMessages'})}
          fontIcon='bi-layers'
        />
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub
        title={intl.formatMessage({id: 'sidemenu.company.title'})}
        to={
          urlPath &&
          [
            '/carrier/users',
            '/carrier/bank-account',
            '/carrier/document',
            '/carrier/company-information',
            '/carrier/invoice-address',
          ].includes(urlPath)
            ? urlPath
            : ''
        }
        icon='/media/company.svg'
      >
        <AsideMenuItem
          to='/carrier/users'
          title={intl.formatMessage({id: 'SideMenu.freightOperator.user'})}
          fontIcon='bi-sticky'
          icon='/media/icons/duotune/general/gen049.svg'
        />

        <AsideMenuItem
          to='/carrier/bank-account'
          title={intl.formatMessage({id: 'SideMenu.carrierOperator.bankAccount'})}
          fontIcon='bi-sticky'
          icon='/media/icons/duotune/finance/fin002.svg'
        />

        <AsideMenuItem
          to='/carrier/document'
          icon='/media/icons/duotune/general/document_icon.svg'
          title={intl.formatMessage({id: 'sidemenu.documentAndCert.title'})}
          fontIcon='bi-layers'
        />

        <AsideMenuItem
          to='/carrier/company-information'
          icon='/media/icons/duotune/general/gen019.svg'
          title={intl.formatMessage({id: 'SideMenu.freightOperator.companyInfo'})}
          fontIcon='bi-layers'
        />

        <AsideMenuItem
          to='/carrier/invoice-address'
          title={intl.formatMessage({id: 'SideMenu.freightOperator.billingAddresses'})}
          fontIcon='bi-sticky'
          icon='/media/billing_address.svg'
        />
      </AsideMenuItemWithSub>

      <AsideMenuItem
        to='/carrier/transports'
        icon='/media/transports.svg'
        title={intl.formatMessage({id: 'SideMenu.systemOperator.transports'})}
        fontIcon='bi-layers'
      />

      <AsideMenuItem
        to='/carrier/client'
        icon='/media/icons/duotune/general/client_icon.svg'
        title={intl.formatMessage({id: 'SideMenu.carrierOperator.clients'})}
        fontIcon='bi-layers'
      />

      <AsideMenuItem
        to='/carrier/search-document'
        icon='/media/icons/duotune/general/search_document_icon.svg'
        title={intl.formatMessage({id: 'sidemenu.searchDocAndCert.title'})}
        fontIcon='bi-layers'
      />

      <AsideMenuItem
        to='/carrier/track-on-map'
        title={intl.formatMessage({id: 'sidemenu.vehicleTrack.title'})}
        fontIcon='bi-sticky'
        icon='/media/icons/duotune/maps/map003.svg'
      />

      {/* <AsideMenuItem
        to='/carrier/freight-postings'
        title={intl.formatMessage({id: 'SideMenu.carrierOperator.freightPosts'})}
        fontIcon='bi-sticky'
        icon='/media/icons/duotune/finance/fin002.svg'
      /> */}

      <AsideMenuItem
        to='/carrier/transporter-groups'
        icon='/media/group.svg'
        title={intl.formatMessage({id: 'sidemenu.privateFreightExg.title'})}
        fontIcon='bi-layers'
      />
    </>
  )

  const FORWARDER_OPERATOR = () => (
    <>
      <AsideMenuItem to='#' icon='' title='§ FORWARDER OPERATOR §' fontIcon='bi-layers' />
      <AsideMenuItem
        to='/forwarder/dashboard'
        icon='/media/icons/duotune/general/gen001.svg'
        title={intl.formatMessage({id: 'SideMenu.forwarderOperator.homePage'})}
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItemWithSub
        title={intl.formatMessage({id: 'SideMenu.carrierOperator.freight'})}
        to={
          urlPath &&
          ['/forwarder/create-new-advert', '/forwarder/create-quick-new-advert'].includes(urlPath)
            ? urlPath
            : ''
        }
        icon='/media/freight.svg'
      >
        <AsideMenuItem
          to='/forwarder/create-new-advert'
          icon='/media/icons/duotune/arrows/arr013.svg'
          title={intl.formatMessage({id: 'SideMenu.forwarderOperator.newFreight'})}
          fontIcon='bi-layers'
        />
        <AsideMenuItem
          to='/forwarder/create-quick-new-advert'
          icon='/media/icons/duotune/general/create_freight_icon.svg'
          title={intl.formatMessage({id: 'Carrier.freight.createQuickFreight'})}
          fontIcon='bi-layers'
        />
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub
        title={intl.formatMessage({id: 'sidemenu.freightOperations.title'})}
        to={
          urlPath && ['/forwarder/my-adverts', '/forwarder/containers'].includes(urlPath)
            ? urlPath
            : ''
        }
        icon='/media/frOp.svg'
      >
        <AsideMenuItem
          to='/forwarder/my-adverts'
          icon='/media/icons/duotune/general/gen056.svg'
          title={intl.formatMessage({id: 'SideMenu.forwarderOperator.myFreights'})}
          fontIcon='bi-layers'
        />

        <AsideMenuItem
          to='/forwarder/containers'
          icon='/media/icons/duotune/ecommerce/ecm009.svg'
          title={intl.formatMessage({id: 'SideMenu.forwarderOperator.containers'})}
          fontIcon='bi-layers'
        />
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub
        title={intl.formatMessage({id: 'sidemenu.transportation.title'})}
        to={
          urlPath &&
          [
            '/forwarder/transporters',
            '/forwarder/addresses',
            '/forwarder/preferred-route',
          ].includes(urlPath)
            ? urlPath
            : ''
        }
        icon='/media/tranaportation.svg'
      >
        <AsideMenuItem
          to='/forwarder/transporters'
          icon='/media/icons/duotune/ecommerce/ecm006.svg'
          title={intl.formatMessage({id: 'SideMenu.forwarderOperator.transporters'})}
          fontIcon='bi-layers'
        />

        <AsideMenuItem
          to='/forwarder/addresses'
          title={intl.formatMessage({id: 'SideMenu.forwarderOperator.addresses'})}
          fontIcon='bi-sticky'
          icon='/media/icons/duotune/general/gen018.svg'
        />

        <AsideMenuItem
          to='/forwarder/preferred-route'
          title={intl.formatMessage({id: 'SideMenu.forwarderOperator.preferredRoute'})}
          fontIcon='bi-sticky'
          icon='/media/icons/duotune/maps/map002.svg'
        />
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub
        title={intl.formatMessage({id: 'freight.myListing.group.vehicle'})}
        to={
          urlPath && ['/forwarder/my-listings', '/forwarder/my-messages'].includes(urlPath)
            ? urlPath
            : ''
        }
        icon='/media/trade.svg'
      >
        <AsideMenuItem
          to='/forwarder/my-listings'
          icon='/media/listing.svg'
          title={intl.formatMessage({id: 'freight.myListings.title'})}
          fontIcon='bi-layers'
        />

        <AsideMenuItem
          to='/forwarder/my-messages'
          icon='/media/messages.svg'
          title={intl.formatMessage({id: 'freight.myListing.myMessages'})}
          fontIcon='bi-layers'
        />
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub
        title={intl.formatMessage({id: 'sidemenu.company.title'})}
        to={
          urlPath &&
          [
            '/forwarder/users',
            '/forwarder/bank-account',
            '/forwarder/document',
            '/forwarder/company-information',
            '/forwarder/invoice-address',
          ].includes(urlPath)
            ? urlPath
            : ''
        }
        icon='/media/company.svg'
      >
        <AsideMenuItem
          to='/forwarder/users'
          title={intl.formatMessage({id: 'SideMenu.freightOperator.user'})}
          fontIcon='bi-sticky'
          icon='/media/icons/duotune/general/gen049.svg'
        />

        <AsideMenuItem
          to='/forwarder/bank-account'
          title={intl.formatMessage({id: 'SideMenu.forwarderOperator.bankAccount'})}
          fontIcon='bi-sticky'
          icon='/media/icons/duotune/finance/fin002.svg'
        />

        <AsideMenuItem
          to='/forwarder/document'
          icon='/media/icons/duotune/general/document_icon.svg'
          title={intl.formatMessage({id: 'sidemenu.documentAndCert.title'})}
          fontIcon='bi-layers'
        />

        <AsideMenuItem
          to='/forwarder/company-information'
          icon='/media/icons/duotune/general/gen019.svg'
          title={intl.formatMessage({id: 'SideMenu.forwarderOperator.companyInfo'})}
          fontIcon='bi-layers'
        />

        <AsideMenuItem
          to='/forwarder/invoice-address'
          title={intl.formatMessage({id: 'SideMenu.forwarderOperator.billingAddresses'})}
          fontIcon='bi-sticky'
          icon='/media/billing_address.svg'
        />
      </AsideMenuItemWithSub>

      <AsideMenuItem
        to='/forwarder/transports'
        icon='/media/transports.svg'
        title={intl.formatMessage({id: 'SideMenu.forwarderOperator.transports'})}
        fontIcon='bi-layers'
      />

      <AsideMenuItem
        to='/forwarder/client'
        icon='/media/icons/duotune/general/client_icon.svg'
        title={intl.formatMessage({id: 'SideMenu.forwarderOperator.clients'})}
        fontIcon='bi-layers'
      />

      <AsideMenuItem
        to='/forwarder/search-document'
        icon='/media/icons/duotune/general/search_document_icon.svg'
        title={intl.formatMessage({id: 'sidemenu.searchDocAndCert.title'})}
        fontIcon='bi-layers'
      />

      <AsideMenuItem
        to='/forwarder/track-on-map'
        title={intl.formatMessage({id: 'sidemenu.vehicleTrack.title'})}
        fontIcon='bi-sticky'
        icon='/media/icons/duotune/maps/map003.svg'
      />

      {/* <AsideMenuItem
        to='/forwarder/freight-postings'
        title={intl.formatMessage({id: 'SideMenu.forwarderOperator.freightPosts'})}
        fontIcon='bi-sticky'
        icon='/media/icons/duotune/finance/fin002.svg'
      /> */}

      <AsideMenuItem
        to='/forwarder/transporter-groups'
        icon='/media/group.svg'
        title={intl.formatMessage({id: 'sidemenu.privateFreightExg.title'})}
        fontIcon='bi-layers'
      />
    </>
  )

  const ORGANIZATION_OWNER = () => (
    <>
      <AsideMenuItem to='' icon='' title='------ADMIN-------' fontIcon='bi-layers' />
      <AsideMenuItem
        to='/admin/dashboard'
        icon='/media/icons/duotune/general/gen001.svg'
        title={'Anasayfa'}
        fontIcon='bi-app-indicator'
      />

      <AsideMenuItem
        to='/admin/adverts'
        icon='/media/icons/duotune/communication/com009.svg'
        title={'Yükler'}
        fontIcon='bi-app-indicator'
      />

      <AsideMenuItem
        to='/admin/containers'
        icon='/media/icons/duotune/ecommerce/ecm009.svg'
        title='Konteynerler'
        fontIcon='bi-layers'
      />

      <AsideMenuItem
        to='/admin/transports'
        icon='/media/icons/duotune/ecommerce/ecm006.svg'
        title='Transports'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/admin/track-on-map'
        title='Araçlar Nerede'
        fontIcon='bi-sticky'
        icon='/media/icons/duotune/maps/map003.svg'
      />
      <AsideMenuItem
        to='/register'
        title='Hesap Oluştur'
        fontIcon='bi-sticky'
        icon='/media/icons/duotune/general/gen049.svg'
      />

      <AsideMenuItem
        to='/admin/file-review'
        title='Belgeleri Görüntüle'
        fontIcon='bi-sticky'
        icon='/media/icons/duotune/files/fil008.svg'
      />
      <AsideMenuItem
        to='/admin/companies'
        title='Firmalar'
        fontIcon='bi-sticky'
        icon='/media/icons/duotune/files/fil002.svg'
      />
      <AsideMenuItem to='' icon='' title='-----CARRIER------' fontIcon='bi-layers' />
      <AsideMenuItem
        to='/carrier/dashboard'
        icon='/media/icons/duotune/general/gen001.svg'
        title={'Anasayfa'}
        fontIcon='bi-app-indicator'
      />

      <AsideMenuItem
        to='/carrier/drivers'
        icon='/media/icons/duotune/communication/com006.svg'
        title='Şoförler'
        fontIcon='bi-layers'
      />

      <AsideMenuItem
        to='/carrier/vehicles'
        icon='/media/icons/duotune/ecommerce/ecm006.svg'
        title='Araçlar'
        fontIcon='bi-layers'
      />

      <AsideMenuItem
        to='/carrier/trailers'
        icon='/media/icons/duotune/graphs/gra011.svg'
        title='Dorseler'
        fontIcon='bi-layers'
      />

      <AsideMenuItem
        to='/carrier/preferred-route'
        title='Tercih Edilen Rota'
        fontIcon='bi-sticky'
        icon='/media/icons/duotune/maps/map003.svg'
      />

      <AsideMenuItem
        to='/carrier/track-on-map'
        title='Araçlar Nerede'
        fontIcon='bi-sticky'
        icon='/media/icons/duotune/maps/map002.svg'
      />

      <AsideMenuItem
        to='/carrier/bank-account'
        title='Banka Hesabı'
        fontIcon='bi-sticky'
        icon='/media/icons/duotune/finance/fin002.svg'
      />
      <AsideMenuItem to='' icon='' title='------FREIGHT-------' fontIcon='bi-layers' />
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/general/gen001.svg'
        title={'Anasayfa'}
        fontIcon='bi-app-indicator'
      />

      <AsideMenuItem
        to='/fr-op/create-new-advert'
        icon='/media/icons/duotune/arrows/arr013.svg'
        title='Yeni Yük Oluştur'
        fontIcon='bi-layers'
      />

      <AsideMenuItem
        to='/my-adverts'
        icon='/media/icons/duotune/general/gen056.svg'
        title='Yüklerim'
        fontIcon='bi-layers'
      />

      <AsideMenuItem
        to='/company-information'
        icon='/media/icons/duotune/general/gen019.svg'
        title='Firma Bilgileri'
        fontIcon='bi-layers'
      />

      {/*<AsideMenuItem
                to='/invoices'
                title='Faturalar'
                fontIcon='bi-sticky'
                icon='/media/icons/duotune/general/gen005.svg'
            />*/}

      <AsideMenuItem
        to='/addresses'
        title='Adresler'
        fontIcon='bi-sticky'
        icon='/media/icons/duotune/general/gen018.svg'
      />

      <AsideMenuItem
        to='/invoice-address'
        title='Fatura Adresleri'
        fontIcon='bi-sticky'
        icon='/media/icons/duotune/general/gen018.svg'
      />

      <AsideMenuItem
        to='/track-on-map'
        title='Araçlar Nerede'
        fontIcon='bi-sticky'
        icon='/media/icons/duotune/maps/map003.svg'
      />

      <AsideMenuItem
        to='/users'
        title='Kullanıcılar'
        fontIcon='bi-sticky'
        icon='/media/icons/duotune/general/gen049.svg'
      />
    </>
  )

  return (
    <>
      {role?.includes('SYSTEM_OPERATOR') && SYSTEM_OPERATOR()}
      {role?.includes('CARRIER_OPERATOR') && CARRIER_OPERATOR()}
      {role?.includes('FORWARDER_OPERATOR') && FORWARDER_OPERATOR()}
      {role?.includes('FREIGHT_OPERATOR') && FREIGHT_OPERATOR()}
      {role?.includes('ORGANIZATION_OWNER') && ORGANIZATION_OWNER()}
    </>
  )
}
