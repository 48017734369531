import {apiUrl, globalPageCount} from '../conf'
import axios from 'axios'

export class ContainerService {
  createContainer(description: string, freightId: number, price: number, currency: number) {
    const data: any = JSON.stringify({
      description: description,
      freightId: freightId,
      price: price,
      currency: {
        currencyId: currency,
      },
    })

    const config: any = {
      method: 'POST',
      url: apiUrl + '/container-management/containers/administrator/from/freights',
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    }

    return axios(config)
  }

  createCarrierContainer(description: string, freightId: number, price: number, currency: number) {
    const data: any = JSON.stringify({
      description: description,
      freightId: freightId,
      price: price,
      currency: {
        currencyId: currency,
      },
    })

    const config: any = {
      method: 'POST',
      url: apiUrl + '/container-management/containers/carriers/from/freights',
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    }

    return axios(config)
  }

  getTransporterList(containerId: number) {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        '/transport-management/transports/applications?containerId=' +
        containerId +
        '&pageIndex=0&pageCount=100000',
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  getTransporterFilterList(
    containerId: number,
    distance: any,
    trailerSuitable?: boolean,
    appliedToFreight?: boolean,
    preferredRoute?: boolean,
    filterDetails?: any
  ) {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        `/transporter-management/transporters/administrator/filter/for/container?pageIndex=0&pageCount=10000&containerId=${containerId}&companyName=${
          filterDetails?.company
        }&name=${filterDetails?.name}&surname=${filterDetails?.surName}&vehiclePlate=${
          filterDetails?.vehiclePlate
        }&trailerPlate=${filterDetails?.trailerPlate}&distance=${distance}&trailerSuitable=${
          trailerSuitable ? 'Y' : 'N'
        }&appliedToFreight=${appliedToFreight ? 'Y' : 'N'}&preferredRoute=${
          preferredRoute ? 'Y' : 'N'
        }`,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  senNotificationTotransporterAsAdmin(
    containerId: number,
    distance: any,
    trailerSuitable?: boolean,
    appliedToFreight?: boolean,
    preferredRoute?: boolean
  ) {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        `/transporter-management/transporters/administrator/notifications/for/container?containerId=${containerId}&distance=${distance}&trailerSuitable=${
          trailerSuitable ? 'Y' : 'N'
        }&appliedToFreight=${appliedToFreight ? 'Y' : 'N'}&preferredRoute=${
          preferredRoute ? 'Y' : 'N'
        }`,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  getContainerProposalByUser(pageIndex?: number) {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        `/container-management/containers/proposals/carriers/operators?pageIndex=${
          pageIndex ?? 0
        }&pageCount=${globalPageCount}`,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  getManageProposalByAdmin(pageIndex?: number) {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        `/container-management/containers/proposals/administrator?pageIndex=${
          pageIndex ?? 0
        }&pageCount=${globalPageCount}`,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  getCarrierTransporterFilterList(
    containerId: number,
    distance: any,
    trailerSuitable?: boolean,
    appliedToFreight?: boolean,
    preferredRoute?: boolean,
    filterDetails?: any
  ) {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        `/transporter-management/transporters/administrator/filter/for/container?pageIndex=0&pageCount=10000&containerId=${containerId}&name=${
          filterDetails?.name
        }&surname=${filterDetails?.surName}&vehiclePlate=${
          filterDetails?.vehiclePlate
        }&trailerPlate=${filterDetails?.trailerPlate}&distance=${distance}&trailerSuitable=${
          trailerSuitable ? 'Y' : 'N'
        }&appliedToFreight=${appliedToFreight ? 'Y' : 'N'}&preferredRoute=${
          preferredRoute ? 'Y' : 'N'
        }`,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  sendNotificationTransporterAsCarrier(
    containerId: number,
    distance: any,
    trailerSuitable?: boolean,
    appliedToFreight?: boolean,
    preferredRoute?: boolean
  ) {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        `/transporter-management/transporters/carriers/notifications/for/container?containerId=${containerId}&distance=${distance}&trailerSuitable=${
          trailerSuitable ? 'Y' : 'N'
        }&appliedToFreight=${appliedToFreight ? 'Y' : 'N'}&preferredRoute=${
          preferredRoute ? 'Y' : 'N'
        }`,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  getContainers(data?: {
    containerStatusId?: any
    isPublished?: any
    pageIndex?: any
    fromCountryCode?: any
    fromCityId?: any
    toCountryCode?: any
    toCityId?: any
    freightContentTypeId?: any
  }) {
    data?.containerStatusId === 'all' && (data = {...data, containerStatusId: ''})
    data?.isPublished === '1' && (data = {...data, isPublished: ''})
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        '/container-management/containers/administrator/filter?' +
        (data?.isPublished ? 'isPublished=' + data?.isPublished + '&' : '') +
        `pageIndex=${data?.pageIndex}&fromCountryCode=${data?.fromCountryCode ?? ''}&fromCityId=${
          data?.fromCityId ?? ''
        }&toCountryCode=${data?.toCountryCode ?? ''}&toCityId=${
          data?.toCityId ?? ''
        }&freightContentTypeId=${data?.freightContentTypeId ?? ''}&pageCount=${globalPageCount}${
          data?.containerStatusId && data?.containerStatusId !== 'all'
            ? '&containerStatusId=' + data?.containerStatusId
            : ''
        }`,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  getCarrierContainers(data?: {
    containerStatusId?: any
    isPublished?: any
    pageIndex?: any
    fromCountryCode?: any
    fromCityId?: any
    toCountryCode?: any
    toCityId?: any
    freightContentTypeId?: any
  }) {
    data?.containerStatusId === 'all' && (data = {...data, containerStatusId: ''})
    data?.isPublished === '1' && (data = {...data, isPublished: ''})
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        '/container-management/containers/carriers/filter?' +
        (data?.isPublished ? 'isPublished=' + data?.isPublished + '&' : '') +
        `pageIndex=${data?.pageIndex}&fromCountryCode=${data?.fromCountryCode ?? ''}&fromCityId=${
          data?.fromCityId ?? ''
        }&toCountryCode=${data?.toCountryCode ?? ''}&toCityId=${
          data?.toCityId ?? ''
        }&freightContentTypeId=${data?.freightContentTypeId ?? ''}&pageCount=${globalPageCount}${
          data?.containerStatusId && data?.containerStatusId !== 'all'
            ? '&containerStatusId=' + data?.containerStatusId
            : ''
        }`,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  getContainerStatusList() {
    const config: any = {
      method: 'GET',
      url: apiUrl + '/container-management/containers/statuses',
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  getCarrierContainerStatusList() {
    const config: any = {
      method: 'GET',
      url: apiUrl + '/container-management/containers/statuses',
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  attachFreightToContainer(containerId?: number, freightId?: number) {
    const config: any = {
      method: 'PATCH',
      url: apiUrl + `/container-management/containers/${containerId}/freights/${freightId}/attach`,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  attachFreightToCarrierContainer(containerId?: number, freightId?: number) {
    const config: any = {
      method: 'PATCH',
      url: apiUrl + `/container-management/containers/${containerId}/freights/${freightId}/attach`,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  detachFreightToContainer(containerId?: number, freightId?: number) {
    const config: any = {
      method: 'PATCH',
      url: apiUrl + `/container-management/containers/${containerId}/freights/${freightId}/detach`,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  detachFreightToCarrierContainer(containerId?: number, freightId?: number) {
    const config: any = {
      method: 'PATCH',
      url: apiUrl + `/container-management/containers/${containerId}/freights/${freightId}/detach`,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  setUnsuccessContainer(containerId: number) {
    const data: any = JSON.stringify({
      containerId: containerId,
    })
    const config: any = {
      method: 'PATCH',
      url: apiUrl + '/container-management/containers/unsuccessful',
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    }

    return axios(config)
  }

  setUnsuccessCarrierContainer(containerId: number) {
    const data: any = JSON.stringify({
      containerId: containerId,
    })
    const config: any = {
      method: 'PATCH',
      url: apiUrl + '/container-management/containers/unsuccessful',
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    }

    return axios(config)
  }

  getContainerProposals(containerId: number, pageIndex?: any) {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        `/container-management/containers/${containerId}/proposals?pageIndex=${
          pageIndex ?? 0
        }&pageCount=20`,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  getCarrierContainerProposals(containerId: number, pageIndex?: any) {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        `/container-management/containers/${containerId}/proposals?pageIndex=${
          pageIndex ?? 0
        }&pageCount=20`,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  createProposal(
    price: any,
    currency: any,
    explanation: string,
    containerId: number,
    proposalScopeId: number,
    companyName: string
  ) {
    const data: any = JSON.stringify({
      container: {
        containerId: containerId,
      },
      price: parseInt(price),
      currency: {
        currencyId: parseInt(currency),
      },
      explanation: explanation,
      proposalScope: {
        proposalScopeId: proposalScopeId,
      },
      companyName: companyName,
    })

    const config: any = {
      method: 'POST',
      url: apiUrl + '/container-management/containers/proposals/administrator',
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    }
    return axios(config)
  }

  createCarrierProposal(
    price: any,
    currency: any,
    explanation: string,
    containerId: number,
    proposalScopeId: number,
    companyName: string
  ) {
    const data: any = JSON.stringify({
      container: {
        containerId: containerId,
      },
      price: parseInt(price),
      currency: {
        currencyId: parseInt(currency),
      },
      explanation: explanation,
      proposalScope: {
        proposalScopeId: proposalScopeId,
      },
      companyName: companyName,
    })

    const config: any = {
      method: 'POST',
      url: apiUrl + '/container-management/containers/proposals/carriers',
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    }
    return axios(config)
  }

  createFreightProposal(
    price: any,
    currency: any,
    explanation: string,
    freightId: number,
    companyName: string,
    proposalScopeId: number
  ) {
    const data: any = JSON.stringify({
      freight: {
        freightId: freightId,
      },
      price: parseInt(price),
      currency: {
        currencyId: parseInt(currency),
      },
      explanation: explanation,
      companyName: companyName,
      proposalScope: {
        proposalScopeId: proposalScopeId,
      },
    })

    const config: any = {
      method: 'POST',
      url: apiUrl + '/freight-management/freights/proposals',
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    }
    return axios(config)
  }

  createCarrierFreightProposals(
    price: any,
    currency: any,
    explanation: string,
    freightId: number,
    companyName: string,
    proposalScopeId: number
  ) {
    const data: any = JSON.stringify({
      freight: {
        freightId: freightId,
      },
      price: parseInt(price),
      currency: {
        currencyId: parseInt(currency),
      },
      explanation: explanation,
      companyName: companyName,
      proposalScope: {
        proposalScopeId: proposalScopeId,
      },
    })

    const config: any = {
      method: 'POST',
      url: apiUrl + '/freight-management/freights/proposals',
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    }
    return axios(config)
  }

  createCarrierFreightProposal(price: any, currency: any, explanation: string, freightId: number) {
    const data: any = JSON.stringify({
      freight: {
        freightId: freightId,
      },
      price: parseInt(price),
      currency: {
        currencyId: parseInt(currency),
      },
      explanation: explanation,
    })

    const config: any = {
      method: 'POST',
      url: apiUrl + '/freight-management/freights/proposals',
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    }
    return axios(config)
  }

  deleteContainerProposal(containerProposalId: number) {
    const config: any = {
      method: 'DELETE',
      url: apiUrl + `/container-management/containers/proposals/${containerProposalId}`,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  deleteCarrierContainerProposal(containerProposalId: number) {
    const config: any = {
      method: 'DELETE',
      url: apiUrl + `/container-management/containers/proposals/${containerProposalId}`,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  getSelectedContainerProposal(containerProposalId: number) {
    const config: any = {
      method: 'GET',
      url: apiUrl + `/container-management/containers/proposals/${containerProposalId}`,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  getSelectedCarrierContainerProposal(containerProposalId: number) {
    const config: any = {
      method: 'GET',
      url: apiUrl + `/container-management/containers/proposals/${containerProposalId}`,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  getFreightsInContainer(containerId: number) {
    const config: any = {
      method: 'GET',
      url: apiUrl + `/container-management/containers/${containerId}/freights`,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  getCarrierFreightsInContainer(containerId: number) {
    const config: any = {
      method: 'GET',
      url: apiUrl + `/container-management/containers/${containerId}/freights`,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  updateContainerProposal(data: any) {
    const payLoad: any = {
      ...data,
      currency: {currencyId: Number(data.currency)},
      proposalScope: {proposalScopeId: Number(data.proposalScope)},
    }
    const config: any = {
      method: 'PUT',
      url: apiUrl + `/container-management/containers/proposals/administrator`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(payLoad),
    }

    return axios(config)
  }

  updateCarrierContainerProposal(data: any) {
    const payLoad: any = {
      ...data,
      currency: {currencyId: Number(data.currency)},
      proposalScope: {proposalScopeId: Number(data.proposalScope)},
    }
    const config: any = {
      method: 'PUT',
      url: apiUrl + `/container-management/containers/proposals/carriers`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(payLoad),
    }

    return axios(config)
  }

  getContainerWithId(containerId: number) {
    const config: any = {
      method: 'GET',
      url: apiUrl + '/container-management/containers/' + containerId,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  getCarrierContainerWithId(containerId: number) {
    const config: any = {
      method: 'GET',
      url: apiUrl + '/container-management/containers/' + containerId,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  getProposalScope(containerId?: number, pageIndex?: number) {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        `/container-management/containers/${containerId}/proposals/carriers/operators?pageIndex=${
          pageIndex ?? 0
        }&pageCount=${globalPageCount}`,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  getCarrierProposalScopeList(containerId?: number, pageIndex?: number) {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        `/container-management/containers/${containerId}/proposals?pageIndex=${
          pageIndex ?? 0
        }&pageCount=${globalPageCount}`,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  getFreightProposalScope(freightId: number) {
    const config: any = {
      method: 'GET',
      url: apiUrl + `/freight-management/freights/${freightId}/proposals?pageIndex=0&pageCount=20`,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  getCarrierProposalScope() {
    const config: any = {
      method: 'GET',
      url: apiUrl + '/container-management/proposals/scopes?pageIndex=0&pageCount=20',
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  getFreightExpertCarrierProposalScopeList(containerId?: number, pageIndex?: number) {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        `/container-management/containers/${containerId}/proposals/carriers/operators?pageIndex=${
          pageIndex ?? 0
        }&pageCount=${globalPageCount}`,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  containerPublish(data: any) {
    const config: any = {
      method: 'PATCH',
      url: apiUrl + '/container-management/containers/publish',
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(data),
    }

    return axios(config)
  }

  carrierContainerPublish(data: any) {
    const config: any = {
      method: 'PATCH',
      url: apiUrl + '/container-management/containers/publish',
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(data),
    }

    return axios(config)
  }

  containerUnPublish(containerId: number) {
    const data: any = JSON.stringify({
      containerId: containerId,
    })
    const config: any = {
      method: 'PATCH',
      url: apiUrl + '/container-management/containers/unpublish',
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    }

    return axios(config)
  }

  carrierContainerUnPublish(containerId: number) {
    const data: any = JSON.stringify({
      containerId: containerId,
    })
    const config: any = {
      method: 'PATCH',
      url: apiUrl + '/container-management/containers/unpublish',
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    }

    return axios(config)
  }
}
