import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import clsx from 'clsx'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {PasswordMeterComponent} from '../../../../_metronic/assets/ts/components'
import {AuthService} from '../../../../services/MainServices/AuthService'
import {toast} from 'react-toastify'
import {useHistory} from 'react-router-dom'
import TopNavbar from '../LandingPage/components/Nav/TopNavbar'

import './SetPassword.scss'

const SetPassword = () => {
  const authServ = new AuthService()
  const navigate = useHistory()
  const search = window.location.search
  const params = new URLSearchParams(search)
  const secretKey = params.get('secretKey')
  const [loading, setLoading] = useState(false)
  const intl = useIntl()

  const initialValues = {
    password: '',
    changePassword: '',
  }

  const registrationSchema = Yup.object().shape({
    password: Yup.string()
      .min(6, intl.formatMessage({id: 'global.min6SymbolRequired'}))
      .max(50, intl.formatMessage({id: 'global.max50SymoblRequired'}))
      .required(intl.formatMessage({id: 'global.passwordRequired'})),
    changePassword: Yup.string()
      .required(intl.formatMessage({id: 'global.passwordVerificationRequired'}))
      .when('password', {
        is: (val: string) => !!(val && val.length > 0),
        then: Yup.string().oneOf([Yup.ref('password')], 'Şifreler eşleşmiyor!'),
      }),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setSubmitting(true)
      setLoading(true)
      if (formik.isSubmitting || !formik.isValid /*|| !formik.values.acceptTerms*/) {
        setTimeout(() => {
          setPassword(secretKey ?? '', values.password)
        }, 1000)
      }
    },
  })

  const setPassword = (key: string, password: string) => {
    authServ
      .setNewPassword(key, password)
      .then((resp: any) => {
        setLoading(false)
        toast.success(intl.formatMessage({id: 'Password.toast.update.success'}))
        navigate.push('/auth/login')
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  return (
    <>
      <TopNavbar hideLoginButton />
      <div className={'container d-flex align-items-center justify-content-center h-100'}>
        <div className={'set-password-wrap card p-5'}>
          <h3 className={'text-center mb-7'}>
            {intl.formatMessage({id: 'Password.createNewPwd'})}
          </h3>
          <div className={'row mb-10'}>
            {/* begin::Form group Password */}
            <div className='mb-10 fv-row' data-kt-password-meter='true'>
              <div className='mb-1'>
                <label className='form-label fw-bolder text-dark fs-6'>
                  {intl.formatMessage({id: 'Password.pwd'})}
                </label>
                <div className='position-relative mb-3'>
                  <input
                    type='password'
                    placeholder={intl.formatMessage({id: 'Password.pwd.placeHolder'})}
                    autoComplete='off'
                    {...formik.getFieldProps('password')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {
                        'is-invalid': formik.touched.password && formik.errors.password,
                      },
                      {
                        'is-valid': formik.touched.password && !formik.errors.password,
                      }
                    )}
                  />
                  {formik.touched.password && formik.errors.password && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span className={'text-danger'} role='alert'>
                          {formik.errors.password}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
                {/* begin::Meter */}
                {/*  <div
                                className="d-flex align-items-center mb-3"
                                data-kt-password-meter-control="highlight"
                            >
                                <div
                                    className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"
                                ></div>
                                <div
                                    className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"
                                ></div>
                                <div
                                    className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"
                                ></div>
                                <div
                                    className="flex-grow-1 bg-secondary bg-active-success rounded h-5px"
                                ></div>
                            </div>*/}
                {/* end::Meter */}
              </div>
              {/*  <div className="text-muted">
                            Harf, sayı ve simge içeren 8 veya daha fazla karakter kullanın.
                        </div>*/}
            </div>
            {/* end::Form group */}

            {/* begin::Form group Confirm password */}
            <div className='fv-row'>
              <label className='form-label fw-bolder text-dark fs-6'>
                {intl.formatMessage({id: 'Password.pwdVerification'})}
              </label>
              <input
                type='password'
                placeholder={intl.formatMessage({id: 'Password.pwdVerification.placeHolder'})}
                autoComplete='off'
                {...formik.getFieldProps('changePassword')}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {
                    'is-invalid': formik.touched.changePassword && formik.errors.changePassword,
                  },
                  {
                    'is-valid': formik.touched.changePassword && !formik.errors.changePassword,
                  }
                )}
              />
              {formik.touched.changePassword && formik.errors.changePassword && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className={'text-danger'} role='alert'>
                      {formik.errors.changePassword}
                    </span>
                  </div>
                </div>
              )}
            </div>
            {/* end::Form group */}
          </div>
          <button
            onClick={() => formik.handleSubmit()}
            id='kt_sign_up_submit'
            className='btn btn-lg btn-primary w-100 mb-5'
          >
            {!loading && (
              <span className='indicator-label'>
                {intl.formatMessage({id: 'Password.createPwd'})}
              </span>
            )}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                {intl.formatMessage({id: 'Password.pleaseWait'})}{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </div>
    </>
  )
}

export default SetPassword
