import React from 'react'
import Modal from '../Modal'

import './AdvertisementPopup.scss'

const AdvertisementPopup = ({url, onClose, targetUrl}: any) => {
  return (
    <Modal
      closeBtnSettings={{
        show: true,
        onClick: () => onClose(),
      }}
      containerModalWrap='modal-wrap-content'
      customAll={
        <div
          className={`${targetUrl ? 'cursor-pointer' : ''}`}
          onClick={() => (targetUrl ? window.open(targetUrl, '_blank') : () => {})}
        >
          <img
            loading={'lazy'}
            key={url}
            src={url}
            alt='advertisement'
            className='advertise-img-wrap'
          />
        </div>
      }
    />
  )
}

export default AdvertisementPopup
