import React, {useEffect} from 'react'
import {useIntl} from 'react-intl'

import RightLayoutPopup from '../../../../Components/RightLayoutPopup'

import {getDate} from '../../../../../utils/common'
import useScrollLock from '../../../../../utils/useScrollLock'

const DocumentDetail = ({selectedDocumentDetails, onClose}: any) => {
  const intl = useIntl()
  const {scrollLock, scrollUnlock} = useScrollLock()
  useEffect(() => {
    scrollLock()
  }, [])

  return (
    <RightLayoutPopup
      title={intl.formatMessage({id: 'CreateDocument.show.title'})}
      className='carrier-view-document-wrap'
      isCloseIcon
      closeOnClick={() => onClose()}
    >
      <div className={'detail-modal-scroll-container'}>
        <div className={'mb-10'}>
          <div className={'row'}>
            <div className={'col-md-6 mb-10'}>
              <h6 className={'text-muted  '}>
                {intl.formatMessage({id: 'CreateDocument.create.documentNumber'})}
              </h6>
              <b className={'fs-5'}>{selectedDocumentDetails?.documentNumber}</b>
            </div>
            <div className={'col-md-6 mb-10'}>
              <h6 className={'text-muted  '}>
                {intl.formatMessage({
                  id: 'CreateDocument.create.documentType',
                })}
              </h6>
              <b className={'fs-5'}>{selectedDocumentDetails?.documentType?.description}</b>
            </div>
          </div>

          <div className={'row'}>
            <div className={'col-md-6 mb-10'}>
              <h6 className={'text-muted '}>
                {intl.formatMessage({
                  id: 'CreateDocument.dataTable.issueDate',
                })}
              </h6>
              <b className={'fs-5'}>
                {!!selectedDocumentDetails?.issueDate &&
                  getDate(selectedDocumentDetails?.issueDate)}
              </b>
            </div>

            <div className={'col-md-6 mb-10'}>
              <h6 className={'text-muted  '}>
                {intl.formatMessage({id: 'CreateDocument.dataTable.expireDate'})}
              </h6>
              <b className={'fs-5'}>
                {!!selectedDocumentDetails?.expireDate &&
                  getDate(selectedDocumentDetails?.expireDate)}
              </b>
            </div>
          </div>
        </div>
      </div>

      <div className='text-center row view-details-freight-popup freightPost-popup-container'>
        <div className={'col-md-6'}>
          <button
            onClick={() => {
              onClose()
              scrollUnlock()
            }}
            type='button'
            id='kt_sign_up_submit'
            className='btn btn-lg btn-danger w-100 mb-5 '
          >
            {intl.formatMessage({id: 'Carrier.freightPosting.view.close'})}
          </button>
        </div>
      </div>
    </RightLayoutPopup>
  )
}

export default DocumentDetail
