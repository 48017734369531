import React, {useEffect, useState} from 'react'
import {toast} from 'react-toastify'
import {useIntl} from 'react-intl'

import RightLayoutPopup from '../../../Components/RightLayoutPopup'
import FileDetailModal from './components/FileDetailModal/FileDetailModal'
import DataTableAdvanced from '../../../Components/DataTableAdvanced'
import Loading from '../../../Components/Loading'
import {ShowDetails} from './components/ShowDetails'
import {RejectReview} from './components/FileDetailModal/RejectReview'
import {ListingComponent} from './components/ListingComponent'

import {FileReviewService} from '../../../../services/AdminServices/FileReviewService'

import useScrollLock from '../../../../utils/useScrollLock'

import './FileReview.scss'

const FileReview = () => {
  const intl = useIntl()
  const fileReviewServ = new FileReviewService()
  const [showFile, setShowFile] = useState<boolean>(false)
  const [showReject, setShowReject] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [isNextPage, setIsNextPage] = useState<boolean>(false)
  const [pageMIndex, setPageMIndex] = useState<number>(0)
  const [selectedCat, setSelectedCat] = useState<'driver' | 'vehicle' | 'trailer' | 'listing'>(
    'driver'
  )

  const [driverFiles, setDriverFiles] = useState<any>([])
  const [vehicleFiles, setVehicleFiles] = useState<any>([])
  const [trailerFiles, setTrailerFiles] = useState<any>([])
  const [selectedFile, setSelectedFile] = useState<any>({})

  const {scrollUnlock} = useScrollLock()
  useEffect(() => {
    if (!showFile) scrollUnlock()
  }, [showFile])

  const getDriverFiles = (pageIndex?: number) => {
    fileReviewServ
      .getUserFiles(pageIndex ?? 0)
      .then((resp: any) => {
        setDriverFiles(resp.data)
        setLoading(false)
        setIsNextPage(false)
      })
      .catch((e: any) => {
        setLoading(false)
        setIsNextPage(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const getVehicleFiles = (pageIndex?: number) => {
    fileReviewServ
      .getVehicleFiles(pageIndex ?? 0)
      .then((resp: any) => {
        setVehicleFiles(resp.data)
        setLoading(false)
        setIsNextPage(false)
      })
      .catch((e: any) => {
        setLoading(false)
        setIsNextPage(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const getTrailerFiles = (pageIndex?: number) => {
    fileReviewServ
      .getTrailerFiles(pageIndex ?? 0)
      .then((resp: any) => {
        setTrailerFiles(resp.data)
        setLoading(false)
        setIsNextPage(false)
      })
      .catch((e: any) => {
        setLoading(false)
        setIsNextPage(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const approveDriverFile = (id: string | number) => {
    fileReviewServ
      .approveDriverFile(id)
      .then(() => {
        toast.success(intl.formatMessage({id: 'ADMIN.FILEREVIEW.TOAST.APPROVED'}))
        getLists()
        setShowFile(false)
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const approveVehicleFile = (id: string | number) => {
    fileReviewServ
      .approveVehicleFile(id)
      .then(() => {
        toast.success(intl.formatMessage({id: 'ADMIN.FILEREVIEW.TOAST.APPROVED'}))
        getLists()
        setShowFile(false)
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const onPaginationChange = (page: number) => {
    if (!isNextPage) {
      if (selectedCat === 'driver') {
        getDriverFiles(page)
      } else if (selectedCat === 'vehicle') {
        getVehicleFiles(page)
      } else if (selectedCat === 'trailer') {
        getTrailerFiles(page)
      }
      setPageMIndex(page)
    }
    setIsNextPage(true)
  }

  const approveTrailerFile = (id: string | number) => {
    fileReviewServ
      .approveTrailerFile(id)
      .then(() => {
        toast.success(intl.formatMessage({id: 'ADMIN.FILEREVIEW.TOAST.APPROVED'}))
        getLists()
        setShowFile(false)
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }
  const rejectDriverFile = (id: string | number, reason: string) => {
    fileReviewServ
      .rejectDriverFile(id, reason)
      .then(() => {
        toast.success(intl.formatMessage({id: 'ADMIN.FILEREVIEW.TOAST.REJECTED'}))
        getLists()
        setShowFile(false)
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }
  const rejectVehicleFile = (id: string | number, reason: string) => {
    fileReviewServ
      .rejectVehicleFile(id, reason)
      .then((resp: any) => {
        toast.success(intl.formatMessage({id: 'ADMIN.FILEREVIEW.TOAST.REJECTED'}))
        getLists()
        setShowFile(false)
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }
  const rejectTrailerFile = (id: string | number, reason: string) => {
    fileReviewServ
      .rejectTrailerFile(id, reason)
      .then((resp: any) => {
        toast.success(intl.formatMessage({id: 'ADMIN.FILEREVIEW.TOAST.REJECTED'}))
        getLists()
        setShowFile(false)
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const getLists = () => {
    setLoading(true)
    getDriverFiles()
  }
  useEffect(() => {
    setLoading(true)
    getLists()
  }, [])

  const getList = (cat: string) => {
    switch (cat) {
      case 'driver':
        return driverFiles?.map((item: any) => {
          return {
            id: item?.userFileId,
            title: item?.user?.name + ' ' + item?.user?.surname,
            url: item?.url,
            type: item?.fileType?.description,
            status: item?.fileStatus?.description,
            ext: 'driver',
            fileType: item?.fileName ? item?.fileName.split('.')[1] : '',
          }
        })
      case 'trailer':
        return trailerFiles?.map((item: any) => {
          return {
            id: item?.trailerFileId,
            title: item?.trailer?.company?.name,
            url: item?.url,
            type: item?.fileType?.description,
            status: item?.fileStatus?.description,
            ext: 'trailer',
            plate: item?.trailer?.plate,
            fileType: item?.fileName ? item?.fileName.split('.')[1] : '',
          }
        })
      case 'vehicle':
        return vehicleFiles?.map((item: any) => {
          return {
            id: item?.vehicleFileId,
            title: item?.vehicle?.company?.name,
            url: item?.url,
            type: item?.fileType?.description,
            status: item?.fileStatus?.description,
            ext: 'vehicle',
            plate: item?.vehicle?.plate,
            fileType: item?.fileName ? item?.fileName.split('.')[1] : '',
          }
        })
      default:
        return []
    }
  }

  const getApprove = ({id, ext}: any) => {
    if (ext === 'driver') {
      setLoading(true)
      approveDriverFile(id)
    } else if (ext === 'trailer') {
      setLoading(true)
      approveTrailerFile(id)
    } else if (ext === 'vehicle') {
      setLoading(true)
      approveVehicleFile(id)
    } else return null
  }
  const getReject = ({id, ext, reason}: any) => {
    if (ext === 'driver') {
      setLoading(true)
      rejectDriverFile(id, reason)
    } else if (ext === 'trailer') {
      setLoading(true)
      rejectTrailerFile(id, reason)
    } else if (ext === 'vehicle') {
      setLoading(true)
      rejectVehicleFile(id, reason)
    } else return null
  }

  return (
    <>
      <div className={'w-100'}>
        {loading && <Loading />}
        <div className={'d-flex align-items-center mb-10 '}>
          <div
            className={''}
            style={{marginRight: '15px'}}
            onClick={() => {
              setSelectedCat('driver')
              setPageMIndex(0)
              setLoading(true)
              getDriverFiles(0)
            }}
          >
            <label
              className={`btn btn-outline-success ${
                selectedCat === 'driver' ? 'driver-selected' : ''
              }`}
              htmlFor='driver-out'
            >
              {intl.formatMessage({id: 'ADMIN.FILEREVIEW.DRIVERFILES'})}
            </label>
          </div>
          <div
            className={''}
            style={{marginRight: '15px'}}
            onClick={() => {
              setSelectedCat('trailer')
              setPageMIndex(0)
              setLoading(true)
              getTrailerFiles(0)
            }}
          >
            <label
              className={`btn btn-outline-warning ${
                selectedCat === 'trailer' ? 'trailer-selected' : ''
              }`}
              htmlFor='trailer-out'
            >
              {intl.formatMessage({id: 'ADMIN.FILEREVIEW.TRAILERFILES'})}
            </label>
          </div>
          <div
            className={''}
            style={{marginRight: '15px'}}
            onClick={() => {
              setSelectedCat('vehicle')
              setPageMIndex(0)
              setLoading(true)
              getVehicleFiles(0)
            }}
          >
            <label
              className={`btn btn-outline-danger ${
                selectedCat === 'vehicle' ? 'vehicle-selected' : ''
              }`}
              htmlFor='vehicle-out'
            >
              {intl.formatMessage({id: 'ADMIN.FILEREVIEW.VEHICLEFILES'})}
            </label>
          </div>

          <div
            className={''}
            onClick={() => {
              setSelectedCat('listing')
              setPageMIndex(0)
              setLoading(true)
            }}
          >
            <label
              className={`btn btn-outline-primary ${
                selectedCat === 'listing' ? 'listing-selected' : ''
              }`}
              htmlFor='listing-out'
            >
              {intl.formatMessage({id: 'freight.listing.title'})}
            </label>
          </div>
        </div>
        {!(selectedCat === 'listing') && !getList(selectedCat).length && (
          <div className='alert alert-warning mb-5 fw-bolder' role='alert'>
            {intl.formatMessage({id: 'global.noData'})}
          </div>
        )}
        {selectedCat === 'listing' ? (
          <ListingComponent setLoading={setLoading} />
        ) : (
          <DataTableAdvanced
            columns={[
              {
                Header: 'ID',
                accessor: 'id',
              },
              {
                Header:
                  selectedCat === 'trailer' || selectedCat === 'vehicle'
                    ? intl.formatMessage({id: 'ADMIN.FILEREVIEW.TABLE.COMPANY'})
                    : intl.formatMessage({id: 'ADMIN.FILEREVIEW.TABLE.FULLNAME'}),
                accessor: 'title',
              },
              ...(selectedCat === 'trailer' || selectedCat === 'vehicle'
                ? [
                    {
                      Header: intl.formatMessage({id: 'ADMIN.FILEREVIEW.TABLE.PLATE'}),
                      accessor: 'plate',
                    },
                  ]
                : []),
              {
                Header: intl.formatMessage({id: 'ADMIN.FILEREVIEW.TABLE.FILETYPE'}),
                accessor: 'type',
              },
              {
                Header: 'Dosya Uzantısı',
                accessor: 'ext',
                hidden: true,
              },
              {
                Header: 'Dosya Url',
                accessor: 'url',
                hidden: true,
              },
              {
                Header: 'Status',
                accessor: 'status',
                hidden: true,
              },
              {
                Header: 'File Type',
                accessor: 'fileType',
                hidden: true,
              },
            ]}
            data={getList(selectedCat)?.map((item: any) => {
              return {
                id: item?.id,
                title: item?.title ?? '-',
                type: item?.type ?? '-',
                ext: item?.ext ?? '-',
                url: item?.url ?? '-',
                status: item?.status,
                fileType: item?.fileType,
                ...(item?.plate && {plate: item?.plate}),
              }
            })}
            editableRows={['']}
            showDetailBtn={(row: any) => {
              setSelectedFile(row.values)
              setShowFile(true)
            }}
            advertApprove={(row: any) => {
              getApprove(row.values)
            }}
            advertReject={(row: any) => {
              setSelectedFile(row.values)
              setShowReject(true)
            }}
            setPageMIndex={onPaginationChange}
            pageMIndex={pageMIndex}
          />
        )}
      </div>
      {showFile && <ShowDetails setShowFile={setShowFile} selectedFile={selectedFile} />}

      {showReject && (
        <RejectReview
          selectedFile={selectedFile}
          setRejectReview={setShowReject}
          rejectOnClick={getReject}
        />
      )}
    </>
  )
}

export default FileReview
