/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import {IListWidget6} from './types'
import {toast} from 'react-toastify'
import {useIntl} from 'react-intl'

const ListsWidget = ({data, title, desc, onEdit, onDelete, setPrimary}: IListWidget6) => {
  const intl = useIntl()
  return (
    <div className='card card-xl-stretch mb-5 mb-xl-8'>
      <div className='card-header border-0 d-flex align-items-center justify-content-between'>
        <h3 className='card-title fw-bolder text-dark'>{title}</h3>
        <small className='card-title fs-6 text-muted'>{desc}</small>
      </div>
      <div className='row card-body pt-0 mt-5'>
        {!data?.length && <p>{intl.formatMessage({id: 'ListWidget.noAnyBankAccount'})}</p>}
        {data?.map((item, key: number) => (
          <div key={key} className={'col-xl-4 p-2 mb-4'}>
            <div
              className={
                ' rounded p-5 mb-7 ' +
                (item?.color === 'yellow'
                  ? 'bg-light-warning'
                  : item?.color === 'green'
                  ? 'bg-light-success border border-success '
                  : item?.color === 'red'
                  ? 'bg-light-danger border border-danger '
                  : item?.color === 'blue'
                  ? 'bg-light-primary'
                  : 'bg-light-warning')
              }
            >
              <div className='flex-grow-1 me-2'>
                <div className={'d-flex align-item-center mb-4'}>
                  <span
                    className={
                      'svg-icon me-5 mt-1 ' +
                      (item?.color === 'yellow'
                        ? 'svg-icon-warning'
                        : item?.color === 'green'
                        ? 'svg-icon-success'
                        : item?.color === 'red'
                        ? 'svg-icon-danger'
                        : item?.color === 'blue'
                        ? 'bg-light-info'
                        : 'svg-icon-warning')
                    }
                  >
                    <KTSVG path='/media/icons/duotune/abstract/abs027.svg' className='svg-icon-1' />
                  </span>
                  <span
                    title={item?.name}
                    className='fw-bolder text-gray-800 fs-2 text-truncate'
                    style={{maxWidth: '95%'}}
                  >
                    {item?.name}
                  </span>
                </div>
                <p
                  onClick={item?.onClick}
                  className='text-dark fw-bold fs-5 d-block text-truncate'
                  style={{maxWidth: '95%'}}
                >
                  {item?.iban}
                </p>
              </div>
              <div className={'d-flex align-items-center justify-content-between mb-5'}>
                {item?.swf && (
                  <div className={''} onClick={item?.onClick}>
                    <small className={'text-muted'}>
                      {intl.formatMessage({id: 'ListWidget.swiftCode'})}
                    </small>
                    <p className='text-dark fw-bold d-block text-hover-primary'>{item?.swf}</p>
                  </div>
                )}
                {item?.explanation && (
                  <div className={''} onClick={item?.onClick}>
                    <small className={'text-muted'}>
                      {intl.formatMessage({id: 'ListWidget.exp'})}
                    </small>
                    <p className='text-dark fw-bold d-block text-hover-primary'>
                      {item?.explanation}
                    </p>
                  </div>
                )}
              </div>
              <div className={'row'}>
                {onEdit && (
                  <div className={'col-2'}>
                    <button
                      title={intl.formatMessage({id: 'ListWidget.edit'})}
                      className='btn btn-icon btn-bg-secondary btn-active-color-primary btn-sm me-1 mb-1'
                      onClick={() => onEdit(item)}
                    >
                      <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                    </button>
                  </div>
                )}
                {setPrimary && (
                  <div className={'col-2'}>
                    <button
                      title={intl.formatMessage({id: 'ListWidget.setAsPrimary'})}
                      className='btn btn-icon btn-bg-secondary btn-active-color-warning btn-sm me-1 mb-1'
                      onClick={() =>
                        item?.isActive
                          ? toast.error(intl.formatMessage({id: 'Address.account.primary'}))
                          : setPrimary(item)
                      }
                    >
                      <KTSVG path='/media/icons/duotune/arrows/arr085.svg' className='svg-icon-3' />
                    </button>
                  </div>
                )}
                {onDelete && (
                  <div className={'col-2'}>
                    <button
                      title={intl.formatMessage({id: 'ListWidget.delete'})}
                      className='btn btn-icon btn-bg-secondary btn-active-color-danger btn-sm me-1 mb-1'
                      onClick={() => onDelete(item)}
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen027.svg'
                        className='svg-icon-3'
                      />
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export {ListsWidget}
