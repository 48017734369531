import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import clsx from 'clsx'

import RightLayoutPopup from '../../../../Components/RightLayoutPopup'
import useScrollLock from '../../../../../utils/useScrollLock'
import {exceptThisSymbols} from '../../../../../utils/common'
import Dropbox from '../../../../Components/Dropbox'

import './../Adverts.scss'

const CreateProposal = ({
  currencyList,
  onClose,
  setLoading,
  proposalScopeList,
  handleCreateProposal,
}: any) => {
  const intl = useIntl()
  const [price, setPrice] = useState<any>(null)
  const [currency, setCurrency] = useState<any>('')
  const [descriptionDisabled, setDescriptionDisabled] = useState<boolean>(true)
  const [description, setDescription] = useState<any>('')
  const [proposalScope, setProposalScope] = useState<any>('')
  const {scrollLock, scrollUnlock} = useScrollLock()

  useEffect(() => {
    if (proposalScope) {
      const result = proposalScopeList.find((item: any) => item.proposalScopeId === proposalScope)
      if (Object.keys(result).length > 0 && result?.code === 'OTHER') {
        setDescriptionDisabled(false)
      } else {
        setDescription('')
        setDescriptionDisabled(true)
      }
    }
  }, [proposalScope])

  useEffect(() => {
    scrollLock()
  }, [])

  return (
    <RightLayoutPopup
      title={intl.formatMessage({id: 'Admin.Adverts.createProposalTitle'})}
      className='admin-freight-proposal-create'
      isCloseIcon
      closeOnClick={() => onClose()}
    >
      <div className={'mt-10'}>
        <div className={'row'}>
          <div className={'mb-5'}>
            <div className={'col-md-12 row'}>
              <div className={'col-md-4 mb-5'}>
                <label className={'form-label fw-bolder text-dark fs-6 mb-2'}>
                  {intl.formatMessage({id: 'Carrier.freightPosting.offered.price'})}
                </label>
                <input
                  type='number'
                  id={'value'}
                  step='any'
                  onKeyDown={(e) => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                  placeholder={intl.formatMessage({id: 'Admin.Adverts.value'})}
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                  min={0}
                  className={clsx('form-control', {
                    'price-input-wrap': price,
                  })}
                />
              </div>
              <div className={'col-md-4'}>
                <label className='form-label fw-bolder text-dark'>
                  {intl.formatMessage({id: 'Admin.Adverts.currency'})}
                </label>

                <Dropbox
                  hideArrow
                  customClass={'form-control form-control-lg'}
                  placeholder={intl.formatMessage({id: 'Admin.Adverts.currency'})}
                  customSearchValue={''}
                  customOnSearch={(e: any) => {}}
                  disabledSearch
                  onScroll={() => {}}
                  setScrollCalled={() => {}}
                  currentPageIndex={0}
                  menuList={
                    currencyList &&
                    currencyList.map((item: any) => {
                      return {
                        id: item?.currencyId,
                        title: item?.description,
                      }
                    })
                  }
                  selectedItemData={(item: any) => {
                    if (item?.id) {
                      setCurrency(item?.id)
                    }
                  }}
                />
              </div>
              <div className={'col-md-4 mb-5'}>
                <label className='form-label fw-bolder text-dark fs-6 mb-2'>
                  {intl.formatMessage({id: 'Admin.Adverts.createProposal.proposalScope'})}
                </label>
                <Dropbox
                  hideArrow
                  customClass={'form-select form-control form-control-lg'}
                  placeholder={intl.formatMessage({
                    id: 'Admin.Adverts.createProposal.proposalScope',
                  })}
                  customSearchValue={''}
                  customOnSearch={(e: any) => {}}
                  disabledSearch
                  onScroll={() => {}}
                  setScrollCalled={() => {}}
                  currentPageIndex={0}
                  menuList={
                    proposalScopeList &&
                    proposalScopeList.map((item: any) => {
                      return {
                        id: item?.proposalScopeId,
                        title: item?.description,
                      }
                    })
                  }
                  selectedItemData={(item: any) => {
                    if (item?.id) {
                      setProposalScope(Number(item?.id))
                    }
                  }}
                />
              </div>
            </div>
          </div>
          {!descriptionDisabled && (
            <div>
              <label className='form-label fw-bolder text-dark fs-6 mb-2'>
                {intl.formatMessage({id: 'Admin.Adverts.createProposal.description'})}
              </label>
              <textarea
                rows={6}
                placeholder={intl.formatMessage({
                  id: 'Admin.Adverts.createProposal.description.placeHolder',
                })}
                autoComplete='off'
                className='form-control form-control-lg form-control-solid bg-white'
                value={description}
                onChange={(e: any) => setDescription(e.target.value)}
              />
            </div>
          )}
        </div>

        <div className='text-center mt-5 row freightPost-popup-container'>
          <div className={'col-md-6'}>
            <button
              onClick={() => {
                onClose()
                scrollUnlock()
              }}
              type='button'
              id='kt_sign_up_submit'
              className='btn btn-lg btn-danger w-100 mb-5 '
            >
              {intl.formatMessage({id: 'CreateNewContainerPopup.cancel'})}
            </button>
          </div>
          <div className={'col-md-6'}>
            <button
              className={'btn btn-success w-100'}
              onClick={() => {
                setLoading(true)
                handleCreateProposal({
                  price,
                  currency,
                  explanation: description,
                  proposalScopeId: parseInt(proposalScope),
                })
              }}
              disabled={!price || !currency || !proposalScope}
            >
              {intl.formatMessage({id: 'Admin.Adverts.createProposal.create'})}
            </button>
          </div>
        </div>
      </div>
    </RightLayoutPopup>
  )
}

export default CreateProposal
