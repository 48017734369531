import {apiUrl, globalPageCount} from '../conf'
import axios from 'axios'

export class TrailersService {
  getTrailers(pageIndex?: number, pageCount?: number, searchText?: string) {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        '/trailer-management/trailers?searchText=' +
        (searchText ?? '') +
        '&pageIndex=' +
        (pageIndex ?? 0) +
        '&pageCount=' +
        (pageCount ?? globalPageCount),
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  getPublicTrailerTypes(pageIndex?: number, searchText?: string) {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        '/trailer-management/public/trailers/types?searchText=' +
        (searchText ?? '') +
        '&pageIndex=' +
        (pageIndex ?? 0) +
        '&pageCount=' +
        (globalPageCount ?? 20),
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  getTrailerTypes(pageIndex?: number, pageCount?: number, searchText?: string) {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        '/trailer-management/trailers/types?searchText=' +
        (searchText ?? '') +
        '&pageIndex=' +
        (pageIndex ?? 0) +
        '&pageCount=' +
        (pageCount ?? 10000),
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  getSearchTrailerTypes(pageIndex?: number, searchText?: string) {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        '/trailer-management/trailers/types?searchText=' +
        (searchText ?? '') +
        '&pageIndex=' +
        (pageIndex ?? 0) +
        '&pageCount=' +
        (globalPageCount ?? 20),
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  getFloorTypes(pageIndex?: number, pageCount?: number, searchText?: string) {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        '/trailer-management/trailers/floors/types?searchText=' +
        (searchText ?? '') +
        '&pageIndex=' +
        (pageIndex ?? 0) +
        '&pageCount=' +
        (pageCount ?? 10000),
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  getSearchFloorTypes(pageIndex?: number, searchText?: string) {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        '/trailer-management/trailers/floors/types?searchText=' +
        (searchText ?? '') +
        '&pageIndex=' +
        (pageIndex ?? 0) +
        '&pageCount=' +
        (globalPageCount ?? 20),
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  getPublicFloorTypes(pageIndex?: number, searchText?: string) {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        '/trailer-management/public/trailers/floors/types?searchText=' +
        (searchText ?? '') +
        '&pageIndex=' +
        (pageIndex ?? 0) +
        '&pageCount=' +
        (globalPageCount ?? 20),
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  getSpecifications(
    trailerType: number,
    pageIndex?: number,
    pageCount?: number,
    searchText?: string
  ) {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        '/trailer-management/trailers/specifications?trailerTypeId=' +
        (trailerType ?? 0) +
        '&searchText=' +
        (searchText ?? '') +
        '&pageIndex=' +
        (pageIndex ?? 0) +
        '&pageCount=' +
        (pageCount ?? 10000),
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  getTrailer(driverId: number) {
    const config: any = {
      method: 'GET',
      url: apiUrl + '/trailer-management/trailers/' + driverId,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  getAllMetadata(driverId: number) {
    const config: any = {
      method: 'GET',
      url: apiUrl + '/trailer-management/trailers/' + driverId + '/files',
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  deleteTrailer(driverId: number) {
    const config: any = {
      method: 'DELETE',
      url: apiUrl + '/trailer-management/trailers/' + driverId,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  updateTrailer(
    id: number,
    year: number,
    plate: string,
    /* capacity: number,*/ make: string,
    model: string,
    trailerType: number,
    specificationList: number[],
    floorType: number
  ) {
    const config: any = {
      method: 'PUT',
      url: apiUrl + '/trailer-management/trailers',
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({
        trailerId: id && id,
        year: year && year,
        plate: plate && plate,
        /* capacity: capacity && capacity,*/
        make: make && make,
        model: model && model,
        trailerType: {
          trailerTypeId: trailerType && trailerType,
        },
        specificationList: specificationList?.map((item: number) => ({
          specificationId: item,
        })),
        floorType: {
          floorTypeId: floorType && floorType,
        },
      }),
    }

    return axios(config)
  }

  createTrailer(
    year: number,
    plate: string,
    /* capacity: number,*/ make: string,
    model: string,
    trailerType: number,
    specificationList: number[],
    floorType: number
  ) {
    const config: any = {
      method: 'POST',
      url: apiUrl + '/trailer-management/trailers',
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({
        year: year && year,
        plate: plate && plate,
        /* capacity: capacity && capacity,*/
        make: make && make,
        model: model && model,
        trailerType: {
          trailerTypeId: trailerType && trailerType,
        },
        specificationList: specificationList?.map((item: number) => ({
          specificationId: item,
        })),
        floorType: {
          floorTypeId: floorType && floorType,
        },
      }),
    }

    return axios(config)
  }

  uploadTrailerPlate(id: number, file: any) {
    const data = new FormData()
    data.append('file', file)
    const config: any = {
      method: 'POST',
      url: apiUrl + '/trailer-management/trailers/' + id + '/files/plate/upload',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: data,
    }

    return axios(config)
  }
}
