/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react'

import './AuthPage.scss'

export function AuthPageModal({children}: any) {
  useEffect(() => {
    document.body.classList.add('bg-white')
    return () => {
      document.body.classList.remove('bg-white')
    }
  }, [])

  return (
    <div className='auth-main-wrap' style={{padding: 0}}>
      <div className={'row m-0 p-0 auth-main-wrap1 d-flex justify-content-center'}>
        <div className={'col-xl-12 auth-main-content-wrap'}>
          <div className={'auth-main-inside-wrap'} style={{padding: '0 2.75rem 0.5rem'}}>
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}
