import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'

import {KTSVG} from '../../../../../_metronic/helpers'

import CheckGroup from '../../../../Components/CheckGroup'
import RightLayoutPopup from '../../../../Components/RightLayoutPopup'

import useScrollLock from '../../../../../utils/useScrollLock'

const TrailerDetails = ({
  specificationList,
  floorTypes,
  trailerTypes,
  downloadFiles,
  getSpecifications,
  trailerMetadata,
  selectedFeatures,
  selectedSpecs,
  onClose,
}: any) => {
  const intl = useIntl()
  const {scrollLock, scrollUnlock} = useScrollLock()
  useEffect(() => {
    scrollLock()
  }, [])
  const [specUpdatedSelected, setSpecUpdatedSelected] = useState<any>([])
  const [updatedTrailer, setUpdatedTrailer] = useState<any>({
    id: -1,
    year: '',
    plate: '',
    /*capacity: 0,*/
    make: '',
    model: '',
    trailerType: '',
    specificationList: [],
    floorType: '',
  })

  useEffect(() => {
    if (updatedTrailer?.trailerType?.trailerTypeId) {
      getSpecifications(updatedTrailer.trailerType.trailerTypeId)
    }
  }, [updatedTrailer.trailerType])

  useEffect(() => {
    if (selectedFeatures) {
      setUpdatedTrailer(selectedFeatures)
    }
  }, [selectedFeatures])

  useEffect(() => {
    if (selectedSpecs) {
      setSpecUpdatedSelected(selectedSpecs)
    }
  }, [selectedSpecs])

  return (
    <>
      <RightLayoutPopup
        title={intl.formatMessage({id: 'Carrier.trailer.details.trailerDetails'})}
        className='view-trailer-side-wrap'
        isCloseIcon
        closeOnClick={() => onClose()}
      >
        <div className={''}>
          <div className={'row'}>
            <div className={'col-xl-6 mb-10'}>
              <div className='fv-row mb-10'>
                <h6 className={'text-muted'}>
                  {intl.formatMessage({id: 'Carrier.trailer.details.numberPlate'})}
                </h6>
                <b className={'fs-5'}>{updatedTrailer.plate}</b>
              </div>
              <div className='fv-row mb-10'>
                <h6 className={'text-muted'}>
                  {intl.formatMessage({id: 'Carrier.trailer.details.brand'})}
                </h6>
                <b className={'fs-5'}>{updatedTrailer.make}</b>
              </div>
              <div className='fv-row mb-10'>
                <h6 className={'text-muted'}>
                  {intl.formatMessage({id: 'Carrier.trailer.details.model'})}
                </h6>
                <b className={'fs-5'}>{updatedTrailer.model}</b>
              </div>
              <div className={'fv-row mb-10'}>
                <h6 className={'text-muted'}>
                  {intl.formatMessage({id: 'Carrier.trailer.details.trailerType'})}
                </h6>
                <b className={'fs-5'}>{updatedTrailer.trailerType.description}</b>
              </div>
            </div>
            <div className={'col-xl-6'}>
              <div className={'mb-10'}>
                <h6 className={'text-muted'}>
                  {intl.formatMessage({id: 'Carrier.trailer.details.floorType'})}
                </h6>
                <b className={'fs-5'}>{updatedTrailer.floorType}</b>
              </div>
              <div className={'col-xl-6 mb-10'}>
                <label htmlFor='trailerSpecification' className={'text-muted fs-4 mb-2'}>
                  {intl.formatMessage({id: 'Carrier.trailer.details.trailerCustom'})}
                </label>
                <CheckGroup
                  disabled
                  fullChecked={specUpdatedSelected}
                  onChange={(e: any) => {
                    if (e.target.checked) {
                      setSpecUpdatedSelected((item: any) => [...item, e?.target?.value])
                    } else {
                      setSpecUpdatedSelected((item: any) =>
                        item.filter((itm: any) => itm !== e.target.value)
                      )
                    }
                  }}
                  selectedItems={specUpdatedSelected}
                  data={
                    specificationList &&
                    specificationList?.map((item: any) => {
                      return {
                        id: item?.specificationId,
                        label: item?.description,
                      }
                    })
                  }
                  placeholder={intl.formatMessage({
                    id: 'Carrier.trailer.details.trailerCustom.placeHolder',
                  })}
                />
              </div>
              <div className={' mb-10 px-3'}>
                <h3 className={'text-bold mb-2'}>
                  {intl.formatMessage({id: 'Carrier.trailer.details.document'})}
                </h3>
                {!trailerMetadata?.length && (
                  <p>{intl.formatMessage({id: 'Carrier.trailer.details.noDocumentUploaded'})}</p>
                )}
                {trailerMetadata.map((item: any) => (
                  <div key={item?.trailerFileId} className={'d-flex justify-content-between mb-4'}>
                    <div>
                      <div className='form-check mb-2'>
                        <input
                          disabled
                          className={
                            'form-check-input border border-' +
                            (item?.fileStatus?.fileStatusId !== 4 ? 'danger' : 'success')
                          }
                          type='checkbox'
                          checked={item?.fileStatus?.fileStatusId === 4}
                        />
                        <label className='form-check-label' htmlFor='flexCheckDefault'>
                          {item?.fileStatus?.fileStatusId === 4
                            ? intl.formatMessage({id: 'Carrier.trailer.details.approved'})
                            : intl.formatMessage({id: 'Carrier.trailer.details.notApproved'})}
                        </label>
                      </div>
                      <p className={'mb-3 text-truncate'}>{item?.fileType.description}</p>
                    </div>
                    <button
                      title={intl.formatMessage({id: 'Carrier.trailer.details.download'})}
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 mb-1'
                      onClick={() => {
                        downloadFiles({
                          url: item?.url,
                          code: item?.fileType.code,
                          name: item.fileName,
                        })
                      }}
                    >
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr091.svg'
                        className='svg-icon-3 mr-2 pr-2'
                      />
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className='text-center mt-5 row justify-content-center freightPost-popup-container'>
          <div className={'col-md-6'}>
            <button
              onClick={() => {
                onClose()
                scrollUnlock()
              }}
              type='button'
              id='kt_sign_up_submit'
              className='btn btn-lg btn-danger w-100 mb-5 '
            >
              {intl.formatMessage({id: 'Carrier.freightPosting.view.close'})}
            </button>
          </div>
        </div>
      </RightLayoutPopup>
    </>
  )
}

export default TrailerDetails
