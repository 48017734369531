import axios from 'axios'
import {apiUrl} from '../conf'

export class AuthService {
  login(email: string, password: string) {
    const data: any = JSON.stringify({
      email: email,
      password: password,
    })

    const config: any = {
      method: 'POST',
      url: apiUrl + '/user-management/users/signin/byemail',
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    }

    return axios(config)
  }

  loginByMobile(countryCode: string, mobile: string, password: string) {
    const data: any = JSON.stringify({
      countryCode: countryCode,
      mobile: mobile,
      password: password,
    })

    const config: any = {
      method: 'POST',
      url: apiUrl + '/user-management/users/signin/bymobile',
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    }

    return axios(config)
  }

  register(
    language: string,
    companyName: string,
    companyTypeId: string | number,
    country: string | number,
    firstname: string,
    lastname: string,
    password: string,
    email: string,
    mobile: number | string,
    countryCode: string
  ) {
    const data: any = JSON.stringify({
      language: language,
      country: country,
      channel: 'WEB',
      user: {
        name: firstname,
        surname: lastname,
        password: password,
        email: email,
        mobile: mobile,
        countryCode: countryCode,
      },
      company: {
        name: companyName,
        companyType: {
          companyTypeId: companyTypeId,
        },
      },
    })

    const config: any = {
      method: 'POST',
      url: apiUrl + '/installation-management/account/create',
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    }

    return axios(config)
  }

  sendLinkByEmail(email: string) {
    const data: any = JSON.stringify({
      email: email,
    })

    const config: any = {
      method: 'POST',
      url: apiUrl + '/user-management/users/password/link/byemail',
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    }

    return axios(config)
  }

  sendLinkByPhone(countryCode: string, mobile: string) {
    const data: any = JSON.stringify({
      countryCode: countryCode,
      mobile: mobile,
    })

    const config: any = {
      method: 'POST',
      url: apiUrl + '/user-management/users/password/link/bymobile',
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    }

    return axios(config)
  }

  setNewPassword(key: string, password: string) {
    const data: any = JSON.stringify({
      secretKey: key,
      password: password,
    })

    const config: any = {
      method: 'PATCH',
      url: apiUrl + '/user-management/users/password',
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    }

    return axios(config)
  }

  renewPassword(password: string) {
    const data: any = JSON.stringify({
      newPassword: password,
    })
    const config: any = {
      method: 'PATCH',
      url: apiUrl + '/user-management/users/current/password',
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    }

    return axios(config)
  }
}
