import {apiUrl} from "../conf";
import axios from "axios";

export class HelpService {

    getHelpList() {
        const config: any = {
            method: 'GET',
            url: apiUrl + '/help-management/helps',
            headers: {
                'Content-Type': 'application/json',
            },
        };
        return axios(config);
    }
}