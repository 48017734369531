/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'

import Loading from '../../Components/Loading'
import Dropbox from '../../Components/Dropbox'
import RightLayoutPopup from '../RightLayoutPopup'

import useScrollLock from '../../../utils/useScrollLock'

import './styles.scss'

const AddNewAddressPopup = ({
  onClose,
  onSubmit,
  companyCustomSearch,
  getDriversList,
  setCompanyCustomSearch,
  currentPageIndex,
  setCurrentPageIndex,
  driverList,
  setSelectedDriverType,
  vehicleCustomSearch,
  setSelectedVehicleType,
  vehicleList,
  getCompanyVehiclesList,
  setVehicleCustomSearch,
  setCurrentVehiclePageIndex,
  currentVehiclePageIndex,
  setSelectedTrailerType,
  trailerList,
  currentTrailerPageIndex,
  getTrailersList,
  setTrailerCustomSearch,
  setCurrentTrailerPageIndex,
  trailerCustomSearch,
  selectedTrailerType,
  selectedVehicleType,
  selectedDriverType,
  editTransporter,
  selectedTransporter,
  transporterTypeList,
  getTransporterTypeList,
  setTransporterCustomSearch,
  transporterCustomSearch,
  setCurrentTransporterPageIndex,
  currentTransporterPageIndex,
  setSelectedTransporterType,
  selectedTransporterType,
  setTransporterTypeScrollAPICalled,
  setDriverTypeScrollAPICalled,
  setVehicleTypeScrollAPICalled,
  setTrailerTypeScrollAPICalled,
  transporterTypeScrollAPICalled,
  driverTypeScrollAPICalled,
  vehicleTypeScrollAPICalled,
  trailerTypeScrollAPICalled,
}: any) => {
  const intl = useIntl()
  const [loading, setLoading] = useState<boolean>(false)
  const [trailerRequired, setTrailerRequired] = useState<boolean>(false)

  const {scrollLock, scrollUnlock} = useScrollLock()
  useEffect(() => {
    scrollLock()
  }, [])

  console.info('----', selectedTrailerType, selectedTransporter)

  return (
    <RightLayoutPopup
      title={intl.formatMessage({
        id: editTransporter
          ? 'Carrier.transporters.update.title'
          : 'Carrier.transporters.create.title',
      })}
      className='create-update-new-transport-wrap'
      isCloseIcon
      closeOnClick={() => onClose()}
    >
      {loading && <Loading />}
      <div>
        <div className={'mb-20 mt-10'} key={selectedTransporter}>
          <div className={'row mb-5'}>
            {/* begin::Form group Currency */}
            <div className='col-md-6 mb-5'>
              <label
                htmlFor='driverList'
                className='class="form-label fw-bolder text-dark fs-6 mb-2'
              >
                {intl.formatMessage({id: 'Carrier.transporters.create.transportType'})}
              </label>
              <Dropbox
                hideArrow
                customClass={'form-select transporter-search-overlay'}
                placeholder={intl.formatMessage({
                  id: 'Carrier.transporters.create.transportType.placeHolder',
                })}
                customSearchValue={transporterCustomSearch}
                customOnSearch={(e: any) => {
                  setCurrentTransporterPageIndex(0)
                  setTransporterCustomSearch(e.target.value)
                  getTransporterTypeList(0, e.target.value)
                }}
                onScroll={(val: any) => {
                  setCurrentTransporterPageIndex(val)
                  getTransporterTypeList(val, transporterCustomSearch)
                }}
                setScrollCalled={() => {
                  setTransporterTypeScrollAPICalled(true)
                }}
                scrollAPIResponse={trailerTypeScrollAPICalled}
                currentPageIndex={currentTransporterPageIndex}
                menuList={
                  transporterTypeList &&
                  transporterTypeList
                    .filter(function ({transportTypeId}: any) {
                      // @ts-ignore
                      return !this[transportTypeId] && (this[transportTypeId] = transportTypeId)
                    }, {})
                    ?.map((item: any) => {
                      return {id: item?.transportTypeId, title: item?.description}
                    })
                }
                selectedItemData={(item: any) => {
                  if (item?.id) {
                    setSelectedTransporterType(item?.id)
                  }

                  if (item?.id === '' && item?.title === '') {
                    setSelectedTransporterType('')
                  }
                }}
                defaultClearItem={{
                  id: '',
                  title: '',
                }}
                defaultSelected={{
                  id: selectedTransporter?.transportType?.transportTypeId,
                  title: selectedTransporter?.transportType?.description,
                }}
              />
            </div>

            <div className='col-md-6 mb-5'>
              <label
                htmlFor='driverList'
                className='class="form-label fw-bolder text-dark fs-6 mb-2'
              >
                {intl.formatMessage({id: 'Carrier.transporters.create.driverType'})}
              </label>
              <Dropbox
                hideArrow
                customClass={'form-select transporter-search-overlay'}
                placeholder={intl.formatMessage({
                  id: 'Carrier.transporters.create.driverType.placeHolder',
                })}
                customSearchValue={companyCustomSearch}
                customOnSearch={(e: any) => {
                  setCurrentPageIndex(0)
                  setCompanyCustomSearch(e.target.value)
                  getDriversList(0, e.target.value)
                }}
                onScroll={(val: any) => {
                  setCurrentPageIndex(val)
                  getDriversList(val, companyCustomSearch)
                }}
                setScrollCalled={() => {
                  setDriverTypeScrollAPICalled(true)
                }}
                scrollAPIResponse={driverTypeScrollAPICalled}
                currentPageIndex={currentPageIndex}
                menuList={
                  driverList &&
                  driverList
                    .filter(function ({userId}: any) {
                      // @ts-ignore
                      return !this[userId] && (this[userId] = userId)
                    }, {})
                    ?.map((item: any) => {
                      return {id: item?.userId, title: `${item?.name} ${item?.surname}`}
                    })
                }
                selectedItemData={(item: any) => {
                  if (item?.id) {
                    setSelectedDriverType(item?.id)
                  }

                  if (item?.id === '' && item?.title === '') {
                    setSelectedDriverType('')
                  }
                }}
                defaultClearItem={{
                  id: '',
                  title: '',
                }}
                defaultSelected={{
                  title:
                    selectedTransporter?.driver?.name &&
                    `${selectedTransporter?.driver?.name} ${selectedTransporter?.driver?.surname}`,
                }}
              />
            </div>
            {/* end::Form group */}
          </div>

          <div className={'row mb-5'}>
            <div className='col-md-6 mb-5'>
              <label
                htmlFor={'vehicleList'}
                className='class="form-label fw-bolder text-dark fs-6 mb-2'
              >
                {intl.formatMessage({id: 'Carrier.transporters.create.vehicleType'})}
              </label>
              <Dropbox
                hideArrow
                customClass={'form-select transporter-search-overlay'}
                placeholder={intl.formatMessage({
                  id: 'Carrier.transporters.create.vehicleType.placeHolder',
                })}
                customSearchValue={vehicleCustomSearch}
                customOnSearch={(e: any) => {
                  setCurrentVehiclePageIndex(0)
                  setVehicleCustomSearch(e.target.value)
                  getCompanyVehiclesList(0, e.target.value)
                }}
                onScroll={(val: any) => {
                  setCurrentVehiclePageIndex(val)
                  getCompanyVehiclesList(val, vehicleCustomSearch)
                }}
                setScrollCalled={() => {
                  setVehicleTypeScrollAPICalled(true)
                }}
                scrollAPIResponse={vehicleTypeScrollAPICalled}
                currentPageIndex={currentVehiclePageIndex}
                menuList={
                  vehicleList &&
                  vehicleList
                    .filter(function ({vehicleId}: any) {
                      // @ts-ignore
                      return !this[vehicleId] && (this[vehicleId] = vehicleId)
                    }, {})
                    ?.map((item: any) => {
                      return {
                        id: item?.vehicleId,
                        title: item?.plate,
                        trailerRequired: item?.vehicleType?.trailerRequired,
                      }
                    })
                }
                selectedItemData={(item: any) => {
                  if (item?.id) {
                    if (item?.trailerRequired === 'Y') {
                      setTrailerRequired(true)
                    } else {
                      setTrailerRequired(false)
                    }
                    setSelectedVehicleType(item?.id)
                  }

                  if (item?.id === '' && item?.title === '') {
                    setSelectedVehicleType('')
                  }
                }}
                defaultClearItem={{
                  id: '',
                  title: '',
                }}
                defaultSelected={{title: selectedTransporter?.vehicle?.plate}}
              />
            </div>
            <div className='col-md-6 mb-5'>
              <label
                htmlFor='trailerList'
                className='class="form-label fw-bolder text-dark fs-6 mb-2'
              >
                {intl.formatMessage({id: 'Carrier.transporters.create.trailerType'})}
              </label>
              <Dropbox
                hideArrow
                customClass={'form-select transporter-search-overlay'}
                placeholder={intl.formatMessage({
                  id: 'Carrier.transporters.create.trailerType.placeHolder',
                })}
                customSearchValue={trailerCustomSearch}
                customOnSearch={(e: any) => {
                  setCurrentTrailerPageIndex(0)
                  setTrailerCustomSearch(e.target.value)
                  getTrailersList(0, e.target.value)
                }}
                onScroll={(val: any) => {
                  setCurrentTrailerPageIndex(val)
                  getTrailersList(val, trailerCustomSearch)
                }}
                setScrollCalled={() => {
                  setTrailerTypeScrollAPICalled(true)
                }}
                scrollAPIResponse={trailerTypeScrollAPICalled}
                currentPageIndex={currentTrailerPageIndex}
                menuList={
                  trailerList &&
                  trailerList
                    .filter(function ({trailerId}: any) {
                      // @ts-ignore
                      return !this[trailerId] && (this[trailerId] = trailerId)
                    }, {})
                    ?.map((item: any) => {
                      return {id: item?.trailerId, title: item?.plate}
                    })
                }
                selectedItemData={(item: any) => {
                  if (item?.id) {
                    setSelectedTrailerType(item?.id)
                  }

                  if (item?.id === '' && item?.title === '') {
                    setSelectedTrailerType('')
                  }
                }}
                defaultClearItem={{
                  id: '',
                  title: '',
                }}
                defaultSelected={{title: selectedTransporter?.trailer?.plate}}
              />
            </div>
          </div>
        </div>

        <div className='text-center mt-5 row transporter-popup-container'>
          <div className={'col-md-6'}>
            <button
              onClick={() => {
                onClose()
                scrollUnlock()
              }}
              type='button'
              id='kt_sign_up_submit'
              className='btn btn-lg btn-danger w-100 mb-5 '
            >
              {intl.formatMessage({id: 'CreateNewContainerPopup.cancel'})}
            </button>
          </div>
          <div className={'col-md-6'}>
            <button
              onClick={() => {
                onSubmit({
                  selectedVehicleType: selectedVehicleType
                    ? selectedVehicleType
                    : selectedTransporter?.vehicle?.vehicleId,
                  selectedTrailerType: selectedTrailerType
                    ? selectedTrailerType
                    : selectedTransporter?.trailer?.trailerId,
                  selectedDriverType: selectedDriverType
                    ? selectedDriverType
                    : selectedTransporter?.driver?.userId,
                  selectedTransporterType: selectedTransporterType
                    ? selectedTransporterType
                    : selectedTransporter?.transporterType?.transporterTypeId,
                })
              }}
              disabled={
                editTransporter
                  ? !editTransporter
                  : (trailerRequired && !selectedTrailerType) ||
                    !selectedVehicleType ||
                    !selectedDriverType ||
                    !selectedTransporterType
              }
              type='button'
              id='kt_sign_up_submit'
              className='btn btn-lg btn-primary w-100 mb-5'
            >
              {editTransporter
                ? intl.formatMessage({id: 'global.update'})
                : intl.formatMessage({id: 'Carrier.drivers.create.create'})}
            </button>
          </div>
        </div>
      </div>
    </RightLayoutPopup>
  )
}

export default AddNewAddressPopup
