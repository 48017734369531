import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'

import SelectBox from '../../../../Components/SelectBox/SelectBox'
import RightLayoutPopup from '../../../../Components/RightLayoutPopup'
import Dropbox from '../../../../Components/Dropbox'

import useScrollLock from '../../../../../utils/useScrollLock'

const EditPreferences = ({
  countries,
  fromCities,
  toCities,
  updateRoute,
  updatedRoutes,
  onClose,
  transportTypeList,
  setFromEditCityScrollAPICalled,
  setFromEditCityPageIndex,
  setFromEditCitySearch,
  fromEditCitySearch,
  fromEditCityPageIndex,
  fromEditCityScrollAPICalled,
  setDependentFromCity,
  dependentFromCity,
  getFromSearchCityList,

  setToEditCityScrollAPICalled,
  setToEditCityPageIndex,
  setToEditCitySearch,
  toEditCitySearch,
  toEditCityPageIndex,
  toEditCityScrollAPICalled,
  setDependentToCity,
  dependentToCity,
  getToSearchCityList,
}: any) => {
  const intl = useIntl()
  const {scrollLock} = useScrollLock()
  useEffect(() => {
    scrollLock()
  }, [])

  const [fromCountryCustomSearch, setFromCountryCustomSearch] = useState<any>('')
  const [toCountryCustomSearch, setToCountryCustomSearch] = useState<any>('')

  const [updatedRoute, setUpdatedRoute] = useState<any>({
    id: -1,
    fromCountry: '',
    toCountry: '',
    fromCity: '',
    toCity: '',
    transportTypeId: '',
  })

  useEffect(() => {
    if (updatedRoute.fromCountry) {
      getFromSearchCityList(updatedRoute?.fromCountry, fromEditCityPageIndex, fromEditCitySearch)
    }
  }, [updatedRoute.fromCountry])

  useEffect(() => {
    if (updatedRoute.toCountry) {
      getToSearchCityList(updatedRoute?.toCountry, toEditCityPageIndex, toEditCitySearch)
    }
  }, [updatedRoute.toCountry])

  useEffect(() => {
    if (updatedRoutes) {
      setUpdatedRoute(updatedRoutes)
    }
  }, [updatedRoutes])

  return (
    <>
      <RightLayoutPopup
        title={intl.formatMessage({id: 'Carrier.prefRoute.update.title'})}
        className='edit-preferredRoutes-right-side-wrap'
        isCloseIcon
        closeOnClick={() => onClose()}
      >
        <div className={''}>
          <div className={'row mb-10'}>
            {/*From*/}
            <div className={'col-md-6 mb-5'}>
              <label className='form-label fs-6 fw-bolder text-dark required'>
                {intl.formatMessage({id: 'Carrier.prefRoute.create.fromCountry'})}
              </label>
              <Dropbox
                hideArrow
                key={updatedRoute.fromCountryName}
                id={'country'}
                customClass={'form-select'}
                placeholder={intl.formatMessage({id: 'Carrier.prefRoute.create.selectCountry'})}
                customSearchValue={fromCountryCustomSearch}
                customOnSearch={(e: any) => {
                  setFromCountryCustomSearch(e.target.value)
                }}
                onScroll={() => {}}
                setScrollCalled={() => {}}
                currentPageIndex={0}
                menuList={
                  countries &&
                  countries.map((item: any) => {
                    return {
                      id: item?.code,
                      title: item?.name,
                    }
                  })
                }
                defaultClearItem={{
                  id: '',
                  title: '',
                }}
                defaultSelected={{
                  id: updatedRoute.fromCountry,
                  title: updatedRoute.fromCountryName,
                }}
                selectedItemData={(dataItem: any) => {
                  if (dataItem?.id) {
                    setUpdatedRoute((item: any) => ({
                      ...item,
                      fromCountry: dataItem?.id,
                      fromCity: '',
                      fromCityName: '',
                    }))
                  }
                  if (dataItem?.id === '' && dataItem?.title === '') {
                    setUpdatedRoute((item: any) => ({
                      ...item,
                      fromCity: '',
                      fromCityName: '',
                      fromCountry: '',
                      fromCountryName: '',
                    }))
                  }
                }}
              />
            </div>

            <div className='mb-10 col-md-6'>
              <h6 className={'mb-3'}>
                {intl.formatMessage({id: 'addNewAddressPopup.area2.fromCity'})}
              </h6>
              <div className={''}>
                <Dropbox
                  hideArrow
                  key={updatedRoute.fromCountry}
                  disabled={!updatedRoute.fromCountry}
                  customClass={'form-select'}
                  placeholder={intl.formatMessage({
                    id: 'addNewAddressPopup.area2.city.placeholder',
                  })}
                  customSearchValue={fromEditCitySearch}
                  customOnSearch={(e: any) => {
                    setFromEditCityPageIndex(0)
                    setFromEditCitySearch(e.target.value)
                    getFromSearchCityList(updatedRoute.fromCountry, 0, e.target.value)
                  }}
                  onScroll={(val: any) => {
                    if (updatedRoute.fromCountry) {
                      setFromEditCityPageIndex(val)
                      getFromSearchCityList(updatedRoute.fromCountry, val, fromEditCitySearch)
                    }
                  }}
                  setScrollCalled={() => {
                    setFromEditCityScrollAPICalled(true)
                  }}
                  scrollAPIResponse={fromEditCityScrollAPICalled}
                  currentPageIndex={fromEditCityPageIndex}
                  menuList={
                    fromCities &&
                    fromCities
                      ?.filter(function ({cityId}: any) {
                        // @ts-ignore
                        return !this[cityId] && (this[cityId] = cityId)
                      }, {})
                      ?.map((item: any) => {
                        return {id: item?.cityId, title: item?.name}
                      })
                  }
                  defaultClearItem={{
                    id: '',
                    title: '',
                  }}
                  defaultSelected={{id: updatedRoute.fromCity, title: updatedRoute.fromCityName}}
                  dependentValue={dependentFromCity}
                  selectedItemData={(dataItem: any) => {
                    if (dataItem?.id) {
                      setUpdatedRoute((item: any) => ({
                        ...item,
                        fromCity: dataItem?.id,
                        fromCityName: dataItem?.title,
                      }))
                      setDependentFromCity(false)
                    }

                    if (dataItem?.id === '' && dataItem?.title === '') {
                      setUpdatedRoute((item: any) => ({
                        ...item,
                        fromCity: '',
                        fromCityName: '',
                      }))
                      setDependentFromCity(false)
                    }
                  }}
                />
              </div>
            </div>

            <div className={'col-md-6 mb-5'}>
              <label className='form-label fs-6 fw-bolder text-dark required'>
                {intl.formatMessage({id: 'Carrier.prefRoute.create.toCountry'})}
              </label>
              <Dropbox
                hideArrow
                key={updatedRoute.toCountryName}
                id={'toCountry'}
                customClass={'form-select'}
                placeholder={intl.formatMessage({id: 'Carrier.prefRoute.create.selectCountry'})}
                customSearchValue={toCountryCustomSearch}
                customOnSearch={(e: any) => {
                  setToCountryCustomSearch(e.target.value)
                }}
                onScroll={() => {}}
                setScrollCalled={() => {}}
                currentPageIndex={0}
                menuList={
                  countries &&
                  countries.map((item: any) => {
                    return {
                      id: item?.code,
                      title: item?.name,
                    }
                  })
                }
                defaultClearItem={{
                  id: '',
                  title: '',
                }}
                defaultSelected={{id: updatedRoute.toCountry, title: updatedRoute.toCountryName}}
                selectedItemData={(dataItem: any) => {
                  if (dataItem?.id) {
                    setUpdatedRoute((item: any) => ({
                      ...item,
                      toCity: '',
                      toCityName: '',
                      toCountry: dataItem?.id,
                    }))
                  }
                  if (dataItem?.id === '' && dataItem?.title === '') {
                    setUpdatedRoute((item: any) => ({
                      ...item,
                      toCity: '',
                      toCityName: '',
                      toCountryName: '',
                      toCountry: '',
                    }))
                  }
                }}
              />
            </div>

            <div className='mb-10 col-md-6'>
              <h6 className={'mb-3'}>
                {intl.formatMessage({id: 'addNewAddressPopup.area2.toCity'})}
              </h6>
              <div className={''}>
                <Dropbox
                  hideArrow
                  key={updatedRoute.toCountry}
                  disabled={!updatedRoute.toCountry}
                  customClass={'form-select'}
                  placeholder={intl.formatMessage({
                    id: 'addNewAddressPopup.area2.city.placeholder',
                  })}
                  customSearchValue={toEditCitySearch}
                  customOnSearch={(e: any) => {
                    setToEditCityPageIndex(0)
                    setToEditCitySearch(e.target.value)
                    getToSearchCityList(updatedRoute.toCountry, 0, e.target.value)
                  }}
                  onScroll={(val: any) => {
                    if (updatedRoute.toCountry) {
                      setToEditCityPageIndex(val)
                      getToSearchCityList(updatedRoute.toCountry, val, toEditCitySearch)
                    }
                  }}
                  setScrollCalled={() => {
                    setToEditCityScrollAPICalled(true)
                  }}
                  scrollAPIResponse={toEditCityScrollAPICalled}
                  currentPageIndex={toEditCityPageIndex}
                  dependentValue={dependentToCity}
                  defaultSelected={{id: updatedRoute.toCity, title: updatedRoute.toCityName}}
                  menuList={
                    toCities &&
                    toCities
                      ?.filter(function ({cityId}: any) {
                        // @ts-ignore
                        return !this[cityId] && (this[cityId] = cityId)
                      }, {})
                      ?.map((item: any) => {
                        return {id: item?.cityId, title: item?.name}
                      })
                  }
                  defaultClearItem={{
                    id: '',
                    title: '',
                  }}
                  selectedItemData={(dataItem: any) => {
                    if (dataItem?.id) {
                      setUpdatedRoute((item: any) => ({
                        ...item,
                        toCity: dataItem?.id,
                        toCityName: dataItem?.title,
                      }))
                      setDependentToCity(false)
                    }

                    if (dataItem?.id === '' && dataItem?.title === '') {
                      setUpdatedRoute((item: any) => ({
                        ...item,
                        toCity: '',
                        toCityName: '',
                      }))
                      setDependentToCity(false)
                    }
                  }}
                />
              </div>
            </div>

            <div className={'col-md-6 mb-5'}>
              <label className='form-label fs-6 fw-bolder text-dark required'>
                {intl.formatMessage({id: 'Carrier.transporters.create.transportType'})}
              </label>
              <SelectBox
                placeholder={intl.formatMessage({
                  id: 'Carrier.transporters.create.transportType.placeHolder',
                })}
                onChange={(event: any) => {
                  setUpdatedRoute((item: any) => ({
                    ...item,
                    transportTypeId: event.target.value,
                  }))
                }}
                value={updatedRoute.transportTypeId}
                data={transportTypeList?.map((item: any) => {
                  return {key: item?.transportTypeId, title: item?.description}
                })}
              />
            </div>
          </div>
        </div>

        <div className='text-center mt-5 row freightPost-popup-container'>
          <div className={'col-md-6'}>
            <button
              onClick={onClose}
              type='button'
              id='kt_sign_up_submit'
              className='btn btn-lg btn-danger w-100 mb-5 '
            >
              {intl.formatMessage({id: 'CreateNewContainerPopup.cancel'})}
            </button>
          </div>
          <div className={'col-md-6'}>
            <button
              className={'btn btn-success px-10 m-auto w-100'}
              disabled={
                !updatedRoute.fromCountry /*|| !updatedRoute.fromCity*/ ||
                !updatedRoute.toCountry /*|| !updatedRoute.toCity*/ ||
                !updatedRoute.transportTypeId
              }
              onClick={() => {
                updateRoute(updatedRoute)
              }}
            >
              {intl.formatMessage({id: 'Carrier.prefRoute.update.update'})}
            </button>
          </div>
        </div>
      </RightLayoutPopup>
    </>
  )
}

export default EditPreferences
