import React, {useEffect} from 'react'
import {useIntl} from 'react-intl'

import {KTSVG} from '../../../../../_metronic/helpers'

import RightLayoutPopup from '../../../../Components/RightLayoutPopup'
import DataTableAdvanced from '../../../../Components/DataTableAdvanced'

import useScrollLock from '../../../../../utils/useScrollLock'

const ShowDocument = ({transporterFileList, onClose}: any) => {
  const intl = useIntl()
  const {scrollLock, scrollUnlock} = useScrollLock()
  useEffect(() => {
    scrollLock()
  }, [])

  return (
    <RightLayoutPopup
      title={intl.formatMessage({id: 'CreateDocument.show.title'})}
      className='carrier-transporter-show-document-wrap'
      isCloseIcon
      closeOnClick={() => onClose()}
    >
      {!transporterFileList.length && (
        <div className='alert alert-warning mb-5 fw-bolder' role='alert'>
          {intl.formatMessage({id: 'Carrier.transporters.noData'})}
        </div>
      )}
      <DataTableAdvanced
        columns={[
          {
            Header: 'ID',
            accessor: 'id',
          },
          {
            Header: intl.formatMessage({id: 'Transporter.dataTable.fileName'}),
            accessor: 'fileName',
          },
          {
            Header: intl.formatMessage({id: 'Transporter.dataTable.fileType'}),
            accessor: 'fileType',
          },
          {
            Header: '',
            accessor: 'fileURL',
            isAction: true,
            Cell: ({row, rows, toggleRowExpanded}: any) =>
              !row.canExpand ? (
                <div className='sub-table-column-wrap'>
                  <button
                    title={intl.formatMessage({id: 'Carrier.drivers.details.download'})}
                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 mb-1'
                    onClick={() => {
                      const fileData =
                        row?.values?.fileURL && row?.values?.fileURL.split('/files')[1]
                      if (
                        fileData &&
                        !(fileData.includes('null') || fileData.includes('undefined'))
                      ) {
                        document.location.href = `${row?.values?.fileURL}?download=Y`
                      }
                      return false
                    }}
                  >
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr091.svg'
                      className='svg-icon-3 mr-2 pr-2'
                    />
                  </button>
                </div>
              ) : null,
          },
        ]}
        data={
          transporterFileList &&
          transporterFileList?.map((item: any) => {
            return {
              id: item?.driverFileId || item?.trailerFileId || item?.vehicleFileId,
              fileName: item?.fileName,
              fileType: item?.fileType?.description,
              fileURL: item?.url,
            }
          })
        }
        pageMIndex={0}
      />

      <div className='text-center mt-5 row view-details-freight-popup freightPost-popup-container'>
        <div className={'col-md-6'}>
          <button
            onClick={() => {
              onClose()
              scrollUnlock()
            }}
            type='button'
            id='kt_sign_up_submit'
            className='btn btn-lg btn-danger w-100 mb-5 '
          >
            {intl.formatMessage({id: 'Carrier.freightPosting.view.close'})}
          </button>
        </div>
      </div>
    </RightLayoutPopup>
  )
}

export default ShowDocument
