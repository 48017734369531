import {apiUrl} from "../conf";
import axios from "axios";

export class UserService {

    getUserData() {
        const config: any = {
            method: 'GET',
            url: apiUrl + '/user-management/users/current',
            headers: {
                'Content-Type': 'application/json',
            },
        };

        return axios(config);
    }

    getRole() {
        const config: any = {
            method: 'GET',
            url: apiUrl + '/user-management/users/current/roles',
            headers: {
                'Content-Type': 'application/json',
            },
        };

        return axios(config);
    }

    updateUser(name?: string, surname?: string) {

        const data: any = JSON.stringify({
            name: name,
            surname: surname,
        });

        const config: any = {
            method: 'PUT',
            url: apiUrl + '/user-management/users/current',
            headers: {
                'Content-Type': 'application/json',
            },
            data: data
        };

        return axios(config);
    }

    updateLanguage(lang: string) {

        const data: any = JSON.stringify({
            language: lang,
        });

        const config: any = {
            method: 'PATCH',
            url: apiUrl + '/user-management/users/current/language',
            headers: {
                'Content-Type': 'application/json',
            },
            data: data
        };

        return axios(config);
    }


    uploadProfileImage(image: any) {
        let formData = new FormData();
        formData.append('file', image);

        const config: any = {
            method: 'POST',
            url: apiUrl + '/user-management/users/current/image',
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            data: formData
        };
        return axios(config);
    }

    updatePassword(oldPass: string, newPass: string) {
        const data: any = JSON.stringify({
            oldPassword: oldPass,
            newPassword: newPass,
        });
        const config: any = {
            method: 'PATCH',
            url: apiUrl + '/user-management/users/current/password',
            headers: {
                'Content-Type': 'application/json',
            },
            data: data
        };

        return axios(config);
    }

    updateLang(lang: string,) {
        const data: any = JSON.stringify({
            language: lang,
        });
        const config: any = {
            method: 'PATCH',
            url: apiUrl + '/user-management/users/current/language',
            headers: {
                'Content-Type': 'application/json',
            },
            data: data
        };

        return axios(config);
    }

    saveNotificationPermission(data: any) {
        const config: any = {
            method: 'POST',
            url: apiUrl + '/notification-management/notifications/permissions',
            headers: {
                'Content-Type': 'application/json',
            },
            data: JSON.stringify(data)
        };
        return axios(config);
    }

    getNotificationPermission() {
        const config: any = {
            method: 'GET',
            url: apiUrl + '/notification-management/notifications/permissions',
            headers: {
                'Content-Type': 'application/json',
            },
        };

        return axios(config);
    }


}
