import React, {useState} from 'react'
import {useIntl} from 'react-intl'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useLocation} from 'react-router-dom'
import {useFormik} from 'formik'
import {toast} from 'react-toastify'

import {AuthPage} from '../AuthPage'

import PhoneInp from '../../../Components/PhoneInp/PhoneInp'

import {AuthService} from '../../../../services/MainServices/AuthService'

const initialValues = {
  email: '',
  countryCode: '+90',
  mobile: '',
}

export function ForgotPassword() {
  const {state}: any = useLocation()
  const [loading, setLoading] = useState(false)
  const [activeResetTab, setActiveResetTab] = useState<any>(state?.value || 0)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const authService = new AuthService()
  const intl = useIntl()

  const forgotPasswordSchema = Yup.object().shape(
    {
      countryCode: Yup.string().required(intl.formatMessage({id: 'global.thisFieldRequired'})),
      mobile: Yup.string().when(['email'], {
        is: (email: any) => !email,
        then: Yup.string().required(intl.formatMessage({id: 'global.mandatory'})), // unnecessary
        otherwise: Yup.string(),
      }),

      email: Yup.string().when(['mobile'], {
        is: (mobile: any) => !mobile,
        then: Yup.string()
          .email(intl.formatMessage({id: 'global.emailInvalid'}))
          .min(3, intl.formatMessage({id: 'global.min3SymbolRequired'}))
          .max(50, intl.formatMessage({id: 'global.max50SymoblRequired'}))
          .required(intl.formatMessage({id: 'global.thisFieldRequired'})), // unnecessary
        otherwise: Yup.string(),
      }),
    },
    [['email', 'mobile']]
  )

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setHasErrors(undefined)
      formik.setErrors({})
      formik.setTouched({}, false)
      setTimeout(() => {
        if (activeResetTab === 0) {
          authService
            .sendLinkByPhone(values.countryCode, values.mobile)
            .then((resp: any) => {
              setHasErrors(false)
              setLoading(false)
              toast.success(intl.formatMessage({id: 'Address.account.toast.password.reset'}))
            })
            .catch((e: any) => {
              setLoading(false)
              setHasErrors(true)
              setSubmitting(false)
              setStatus(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
            })

          formik.setFieldValue('countryCode', '+90')
          formik.setFieldValue('mobile', '')
          formik.setFieldValue('email', '')
          formik.setErrors({})
        } else {
          authService
            .sendLinkByEmail(values.email)
            .then((resp: any) => {
              setHasErrors(false)
              setLoading(false)
              toast.success(intl.formatMessage({id: 'Address.account.toast.password.reset'}))
            })
            .catch((e: any) => {
              setLoading(false)
              setHasErrors(true)
              setSubmitting(false)
              setStatus(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
            })
          formik.setFieldValue('countryCode', '+90')
          formik.setFieldValue('mobile', '')
          formik.setFieldValue('email', '')
          formik.setErrors({})
        }
      }, 1000)
    },
  })

  return (
    <AuthPage>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='text-center mb-10'>
          {/* begin::Title */}
          <h1 className='text-dark mb-3'>
            {intl.formatMessage({id: 'FORGOT.PASSWORD.RESET.TITLE'})}
          </h1>
          {/* end::Title */}

          {/* begin::Link */}
          <div className='text-gray-400 fw-bold fs-4'>
            {activeResetTab === 0
              ? intl.formatMessage({id: 'forget.title.subTitle'})
              : intl.formatMessage({id: 'FORGOT.PASSWORD.EMAIL.TITLE'})}
          </div>
          {/* end::Link */}
        </div>

        {/* begin::Title */}
        {hasErrors === true && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>
              {intl.formatMessage({id: 'FORGOT.PASSWORD.UNEXPECTED.ERROR'})}
            </div>
          </div>
        )}

        {hasErrors === false && (
          <div className='mb-10 bg-light-info p-8 rounded'>
            <div className='text-info'>
              {intl.formatMessage({id: 'FORGOT.PASSWORD.EMAIL.SENT'})}
            </div>
          </div>
        )}
        {/* end::Title */}

        {/* begin::Form group */}
        <ul
          className='sidebar-nav nav nav-tabs pt-5 justify-content-left'
          id='kt_sidebar_tabs'
          role='tablist'
        >
          <li className='nav-item tab-wrapper'>
            <a
              onClick={() => {
                setActiveResetTab(0)
              }}
              className={`nav-main-wrap ${
                activeResetTab === 0 ? 'active-tab-wrap' : 'nav-normal-wrap'
              }`}
              id='kt_sidebar_tab_1'
            >
              {intl.formatMessage({id: 'forget.tab.title.mobile'})}
            </a>
          </li>

          <li className='nav-item tab-wrapper'>
            <a
              onClick={() => {
                setActiveResetTab(1)
              }}
              className={`nav-main-wrap ${
                activeResetTab === 1 ? 'active-tab-wrap' : 'nav-normal-wrap'
              }`}
              id='kt_sidebar_tab_2'
            >
              {intl.formatMessage({id: 'forget.tab.title.email'})}
            </a>
          </li>
        </ul>

        {/* begin::Sidebar Content */}
        <div id='kt_sidebar_content' className='py-10 registration-content-tab-wrap'>
          <div
            className='me-lg-n2 pe-lg-2'
            data-kt-scroll='true'
            data-kt-scroll-offset='10px'
            data-kt-scroll-dependencies='#kt_sidebar_tabs, #kt_sidebar_footer'
            data-kt-scroll-wrappers='#kt_sidebar_content'
          >
            <div className='tab-content'>
              <div
                className={clsx('tab-pane', {active: activeResetTab === 0})}
                id='kt_sidebar_tab_pane_1'
                role='tabpanel'
              >
                <div className='row'>
                  <div className={'col-md-4'}>
                    <label htmlFor={'phoneCode'} className='text-muted fs-4 mb-2'>
                      {intl.formatMessage({id: 'Carrier.drivers.create.phoneCode'})}
                    </label>
                    <PhoneInp
                      id={'countryCode'}
                      onSelect={(event: any) => {
                        formik.setFieldValue('countryCode', event.length > 0 ? '+' + event : event)
                      }}
                      className='form-control form-control-lg form-control-solid w-100'
                    />
                    {formik.touched.countryCode && formik.errors.countryCode && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert' style={{color: '#DC3444'}}>
                            {formik.errors.countryCode}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className='fv-row col-md-8 mb-5'>
                    <label className='fs-4 text-muted mb-2'>
                      {intl.formatMessage({id: 'Carrier.drivers.create.phone'})}
                    </label>
                    <input
                      placeholder={intl.formatMessage({
                        id: 'Carrier.drivers.create.phone.placeHolder',
                      })}
                      maxLength={13}
                      className={clsx('form-control form-control-lg form-control-solid')}
                      onChange={(event: any) => {
                        if (event.target.value.match(/^\+?(?:[\d]*)$/)) {
                          formik.setFieldValue('email', '')
                          formik.setFieldValue('mobile', event.target.value)
                        }
                      }}
                      value={formik.values.mobile}
                      type='text'
                      name='mobile'
                      autoComplete='off'
                      required
                    />
                    {formik.touched.mobile && formik.errors.mobile && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert' style={{color: '#DC3444'}}>
                            {formik.errors.mobile}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div
                className={clsx('tab-pane', {active: activeResetTab === 1})}
                id='kt_sidebar_tab_pane_2'
                role='tabpanel'
              >
                <div className='fv-row mb-10'>
                  <label className='form-label fw-bolder text-gray-900 fs-6'>
                    {intl.formatMessage({id: 'LOGIN.EMAIL.TITLE'})}
                  </label>
                  <input
                    type='email'
                    placeholder=''
                    autoComplete='off'
                    {...formik.getFieldProps('email')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {'is-invalid': formik.touched.email && formik.errors.email},
                      {
                        'is-valid': formik.touched.email && !formik.errors.email,
                      }
                    )}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.email}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
          <button
            type='submit'
            disabled={formik.isSubmitting}
            id='kt_password_reset_submit'
            className='btn btn-lg btn-primary fw-bolder me-4'
          >
            {!loading && (
              <span className='indicator-label'>
                {intl.formatMessage({id: 'FORGOT.PASSWORD.SEND'})}
              </span>
            )}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                {intl.formatMessage({id: 'FORGOT.PASSWORD.SEND'})}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          <Link to='/auth/login'>
            <button
              type='button'
              id='kt_login_password_reset_form_cancel_button'
              className='btn btn-lg btn-light-primary fw-bolder'
              disabled={formik.isSubmitting}
            >
              {intl.formatMessage({id: 'REGISTRATION.CANCEL.TITLE'})}
            </button>
          </Link>{' '}
        </div>
        {/* end::Form group */}
      </form>
    </AuthPage>
  )
}
