/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import clsx from 'clsx'

import {PageTitle} from '../../../../_metronic/layout/core'
import {KTSVG} from '../../../../_metronic/helpers'

import StatCard from '../../../Components/StatCard/StatCard'
import Loading from '../../../Components/Loading'
import ShowFreightListPopup from './Popups/ShowFreightListPopup'
import DataTableAdvanced from '../../../Components/DataTableAdvanced'

import {DashboardService} from '../../../../services/CarrierServices/DashboardService'
import {AdvertService} from '../../../../services/FreightOperatorServices/AdvertService'

import getDateTime, {priceConversion} from '../../../../utils/common'

import './DashboardWrapper.scss'

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  const dashServ = new DashboardService()
  const advertServ = new AdvertService()

  const [filter, setFilter] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [showFilterPanel, setShowFilterPanel] = useState<boolean>(false)
  const [showFreightListPopup, setShowFreightListPopup] = useState<boolean>(false)
  const [statistics, setStatistics] = useState<any>([])
  const [freights, setFreights] = useState<any>([])
  const [containerFreightList, setContainerFreightList] = useState<any>([])
  const [pageMIndex, setPageMIndex] = useState<number>(0)
  const [selectedFilterCard, setSelectedFilterCard] = useState<any>(null)

  const [isNextPage, setIsNextPage] = useState<boolean>(false)

  const getStatistics = () => {
    dashServ
      .getStatistics()
      .then((resp: any) => {
        setLoading(false)
        setStatistics(resp?.data)
      })
      .catch((e: any) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const getFreights = (data?: any) => {
    dashServ
      .getFreightList(data)
      .then((resp: any) => {
        setLoading(false)
        setIsNextPage(false)
        setFreights(resp?.data)
      })
      .catch((e: any) => {
        setLoading(false)
        setIsNextPage(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const getFreightInContainerList = (containerId?: number) => {
    dashServ
      .getFreightInContanerList(containerId)
      .then((resp: any) => {
        setLoading(false)
        setContainerFreightList(resp.data)
      })
      .catch((e: any) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const getStat = (id: string | number, data: any) =>
    data &&
    Array.isArray(data) &&
    data.length &&
    data?.find((item: any) => item?.freightStatusId === id)?.count
      ? data?.find((item: any) => item?.freightStatusId === id)?.count
      : 0

  const getStatusId = (id: string | number, data: any) =>
    data &&
    Array.isArray(data) &&
    data.length &&
    data?.find((item: any) => item?.freightStatusId === id)
      ? data?.find((item: any) => item?.freightStatusId === id)?.freightStatusId
      : 0

  const includeCheck = (item: any) =>
    item?.description?.toLowerCase().includes(filter.toLowerCase()) ||
    item?.fromLocation?.country?.name?.toLowerCase().includes(filter.toLowerCase()) ||
    item?.toLocation?.country?.name?.toLowerCase().includes(filter.toLowerCase()) ||
    item?.toLocation?.city?.originalName?.toLowerCase().includes(filter.toLowerCase()) ||
    item?.fromLocation?.city.originalName?.toLowerCase().includes(filter.toLowerCase())

  const onPaginationChange = (page: number) => {
    if (!isNextPage) {
      getFreights({pageIndex: page})
      setPageMIndex(page)
    }
    setIsNextPage(true)
  }

  const handleExternalClick = (id?: any) => {
    advertServ
      .getCarrierAdvertsWithStatus(id)
      .then((resp: any) => {
        const result = resp.data.map((item: any) => {
          return {
            ...item,
            containerId: item?.freightId,
            price: item?.value,
            priceCurrency: item?.valueCurrency,
          }
        })
        setFreights(result)
      })
      .catch((e: any) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
        setLoading(false)
      })
  }

  useEffect(() => {
    setLoading(true)
    getStatistics()
    setLoading(true)
    getFreights()
  }, [])

  return (
    <>
      {loading && <Loading />}
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'DASHBOARD.MAIN.TITLE'})}</PageTitle>
      <div className={'row'}>
        <div
          className={`col-xl-4 col-md-6 mb-10 ${
            selectedFilterCard === 6 ? 'active-danger-block' : ''
          }`}
        >
          <StatCard
            icon={'/media/icons/duotune/arrows/arr031.svg'}
            title={intl.formatMessage({id: 'Carrier.dashboard.freightsMatched'})}
            value={getStat(6, statistics)}
            url={''}
            externalHandleClick={() => {
              if (selectedFilterCard === 6) {
                handleExternalClick()
                setSelectedFilterCard(null)
              } else {
                handleExternalClick(getStatusId(6, statistics))
                setSelectedFilterCard(6)
              }
            }}
            isExternalClick
            cardColor={'danger'}
          />
        </div>
        <div
          className={`col-xl-4 col-md-6 mb-10 ${
            selectedFilterCard === 8 ? 'active-warning-block' : ''
          }`}
        >
          <StatCard
            icon={'/media/icons/duotune/ecommerce/ecm006.svg'}
            title={intl.formatMessage({id: 'Carrier.dashboard.freightsOnMoving'})}
            value={getStat(8, statistics)}
            url={''}
            externalHandleClick={() => {
              if (selectedFilterCard === 8) {
                handleExternalClick()
                setSelectedFilterCard(null)
              } else {
                handleExternalClick(getStatusId(8, statistics))
                setSelectedFilterCard(8)
              }
            }}
            isExternalClick
            cardColor={'warning'}
          />
        </div>
        <div
          className={`col-xl-4 col-md-6 mb-10 ${
            selectedFilterCard === 9 ? 'active-success-block' : ''
          } `}
        >
          <StatCard
            icon={'/media/icons/duotune/arrows/arr012.svg'}
            title={intl.formatMessage({id: 'Carrier.dashboard.delivered'})}
            value={getStat(9, statistics)}
            url={''}
            externalHandleClick={() => {
              if (selectedFilterCard === 9) {
                handleExternalClick()
                setSelectedFilterCard(null)
              } else {
                handleExternalClick(getStatusId(9, statistics))
                setSelectedFilterCard(9)
              }
            }}
            isExternalClick
            cardColor={'success'}
          />
        </div>
      </div>

      <div className='row mb-5'>
        <span className='menu-icon d-flex justify-content-end'>
          <div
            className={`btn btn-icon btn-active-light-primary btn-custom ${
              showFilterPanel ? 'menu-button-active' : ''
            }`}
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='bottom'
            onClick={() => setShowFilterPanel(!showFilterPanel)}
          >
            <KTSVG path='/media/filtering.svg' className='svg-icon-2x' />
          </div>
        </span>
      </div>

      {showFilterPanel && (
        <div className='mb-10 col-md-3 '>
          <h6 className={'mb-3'}>{intl.formatMessage({id: 'Carrier.dashboard.filter'})}</h6>
          <div className={''}>
            <input
              placeholder={intl.formatMessage({id: 'Carrier.dashboard.filter.placeHolder'})}
              className={clsx('form-control form-control-lg')}
              onChange={(event: any) => setFilter(event.target.value)}
              value={filter}
              type='text'
            />
          </div>
        </div>
      )}

      {!freights.length && (
        <div className='alert alert-warning mb-5 fw-bolder' role='alert'>
          {intl.formatMessage({id: 'global.noData'})}
        </div>
      )}

      <div className={'mt-10'}>
        <DataTableAdvanced
          columns={[
            {
              Header: 'ID',
              accessor: 'id',
            },
            {
              Header: intl.formatMessage({id: 'Carrier.dashboard.explanation'}),
              accessor: 'title',
            },
            {
              Header: intl.formatMessage({id: 'Admin.Adverts.table_value'}),
              accessor: 'value',
              cellStyle: {
                textAlign: 'end',
              },
            },
            {
              Header: intl.formatMessage({id: 'Admin.Adverts.table_currency'}),
              accessor: 'currencyCode',
            },
            {
              Header: intl.formatMessage({id: 'Carrier.dashboard.fromWhere'}),
              accessor: 'fromLocation',
            },
            {
              Header: intl.formatMessage({id: 'Carrier.dashboard.toWhere'}),
              accessor: 'toLocation',
            },
            {
              Header: intl.formatMessage({id: 'Carrier.dashboard.departureDate'}),
              accessor: 'plannedDepartureDate',
            },
          ]}
          data={
            freights &&
            freights
              ?.filter((item: any) => includeCheck(item))
              .map((item: any) => ({
                id: item?.containerId,
                title: item?.description,
                value:
                  item?.price && priceConversion(item?.price, item?.priceCurrency?.currencyCode),
                currencyCode: item?.priceCurrency?.currencyCode,
                fromLocation: item?.fromAddress?.city.name + ', ' + item?.fromAddress?.country.name,
                toLocation: item?.toAddress?.city.name + ', ' + item?.toAddress?.country.name,
                plannedDepartureDate:
                  item?.plannedDepartureDate !== null &&
                  getDateTime(item?.plannedDepartureDate, intl.locale),
              }))
          }
          updateMyData={() => {}}
          updateRow={() => {}}
          editableRows={['']}
          // editBtn={(row: any) => {}}
          showDetailBtn={(row: any) => {
            setLoading(true)
            setShowFreightListPopup(true)
            getFreightInContainerList(row?.values?.id)
          }}
          setPageMIndex={onPaginationChange}
          pageMIndex={pageMIndex}
        />
      </div>

      {showFreightListPopup && (
        <ShowFreightListPopup
          containerFreightList={containerFreightList}
          onClose={() => setShowFreightListPopup(false)}
          setLoading={setLoading}
        />
      )}
    </>
  )
}

export {DashboardWrapper}
