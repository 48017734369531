import React, {useEffect} from 'react'
import {useIntl} from 'react-intl'
import clsx from 'clsx'

import RightLayoutPopup from '../../../../Components/RightLayoutPopup'

import useScrollLock from '../../../../../utils/useScrollLock'
import getDateTime from '../../../../../utils/common'

import '../Transports.scss'

const ShowDetails = ({setTransport, transport, onClose}: any) => {
  const intl = useIntl()
  const {scrollLock, scrollUnlock} = useScrollLock()
  useEffect(() => {
    scrollLock()
  }, [])

  return (
    <RightLayoutPopup
      title={intl.formatMessage({id: 'ADMIN.TRANSPORTS.DETAIL.TITLE'})}
      className='carrier-transport-view-wrap'
      isCloseIcon
      closeOnClick={() => onClose()}
    >
      <div className={'row'}>
        <div className={'col-xl-6'}>
          <div className='fv-row mb-10'>
            <input
              disabled
              placeholder={intl.formatMessage({id: 'ADMIN.TRANSPORTS.DETAIL.TRANSPORTNUMBER'})}
              className={clsx('form-control form-control-lg')}
              onChange={(event: any) =>
                setTransport((item: any) => ({
                  ...item,
                  transportNumber: event.target.value,
                }))
              }
              value={transport.transportNumber}
              type='text'
            />
          </div>
          <div className='fv-row mb-10'>
            <label className='form-label fs-6 fw-bolder text-dark'>
              {intl.formatMessage({id: 'ADMIN.TRANSPORTS.DETAIL.STATUS'})}
            </label>
            <input
              disabled
              placeholder={intl.formatMessage({id: 'ADMIN.TRANSPORTS.DETAIL.STATUS'})}
              className={clsx('form-control form-control-lg')}
              onChange={(event: any) =>
                setTransport((item: any) => ({
                  ...item,
                  status: {...item.status, description: event.target.value},
                }))
              }
              value={transport.status.description}
              type='text'
            />
          </div>
          <div className='fv-row mb-10'>
            <label className='form-label fs-6 fw-bolder text-dark'>
              {intl.formatMessage({id: 'ADMIN.TRANSPORTS.DETAIL.OWNERCOMPANY'})}
            </label>
            <input
              disabled
              placeholder={intl.formatMessage({id: 'ADMIN.TRANSPORTS.DETAIL.OWNERCOMPANY'})}
              className={clsx('form-control form-control-lg')}
              onChange={(event: any) =>
                setTransport((item: any) => ({
                  ...item,
                  ownerCompany: event.target.value,
                }))
              }
              value={transport.ownerCompany}
              type='text'
            />
          </div>
          <div className='fv-row mb-10'>
            <label className='form-label fs-6 fw-bolder text-dark'>
              {intl.formatMessage({id: 'ADMIN.TRANSPORTS.DETAIL.CARRIERCOMPANY'})}
            </label>
            <input
              disabled
              placeholder={intl.formatMessage({id: 'ADMIN.TRANSPORTS.DETAIL.CARRIERCOMPANY'})}
              className={clsx('form-control form-control-lg')}
              onChange={(event: any) =>
                setTransport((item: any) => ({
                  ...item,
                  carrierCompany: event.target.value,
                }))
              }
              value={transport.carrierCompany}
              type='text'
            />
          </div>
          <div className='fv-row mb-10'>
            <label className='form-label fs-6 fw-bolder text-dark'>
              {intl.formatMessage({id: 'ADMIN.TRANSPORTS.DETAIL.DRIVER'})}
            </label>
            <input
              disabled
              placeholder={intl.formatMessage({id: 'ADMIN.TRANSPORTS.DETAIL.DRIVER'})}
              className={clsx('form-control form-control-lg')}
              onChange={(event: any) =>
                setTransport((item: any) => ({
                  ...item,
                  driver: event.target.value,
                }))
              }
              value={transport.driver}
              type='text'
            />
          </div>
          <div className='fv-row mb-10'>
            <label className='form-label fs-6 fw-bolder text-dark'>
              {intl.formatMessage({id: 'ADMIN.TRANSPORTS.DETAIL.DRIVERPHONE'})}
            </label>
            <input
              disabled
              placeholder={intl.formatMessage({id: 'ADMIN.TRANSPORTS.DETAIL.DRIVERPHONE'})}
              className={clsx('form-control form-control-lg')}
              onChange={(event: any) =>
                setTransport((item: any) => ({
                  ...item,
                  driverPhone: event.target.value,
                }))
              }
              value={transport.driverPhone}
              type='text'
            />
          </div>
          <div className='fv-row mb-10'>
            <label className='form-label fs-6 fw-bolder text-dark'>
              {intl.formatMessage({id: 'ADMIN.TRANSPORTS.DETAIL.VEHICLEPLATE'})}
            </label>
            <input
              disabled
              placeholder={intl.formatMessage({id: 'ADMIN.TRANSPORTS.DETAIL.VEHICLEPLATE'})}
              className={clsx('form-control form-control-lg')}
              onChange={(event: any) =>
                setTransport((item: any) => ({
                  ...item,
                  vehiclePlate: event.target.value,
                }))
              }
              value={transport.vehiclePlate}
              type='text'
            />
          </div>
          <div className='fv-row mb-10'>
            <label className='form-label fs-6 fw-bolder text-dark'>
              {intl.formatMessage({id: 'ADMIN.TRANSPORTS.DETAIL.TRAILERPLATE'})}
            </label>
            <input
              disabled
              placeholder={intl.formatMessage({id: 'ADMIN.TRANSPORTS.DETAIL.TRAILERPLATE'})}
              className={clsx('form-control form-control-lg')}
              onChange={(event: any) =>
                setTransport((item: any) => ({
                  ...item,
                  trailerPlate: event.target.value,
                }))
              }
              value={transport.trailerPlate}
              type='text'
            />
          </div>
        </div>
        <div className={'col-xl-6 mb-10 px-3'}>
          <div className='fv-row mb-10'>
            <label className='form-label fs-6 fw-bolder text-dark'>
              {intl.formatMessage({id: 'ADMIN.TRANSPORTS.DETAIL.FROMCOUNTRY'})}
            </label>
            <input
              disabled
              placeholder={intl.formatMessage({id: 'ADMIN.TRANSPORTS.DETAIL.FROMCOUNTRY'})}
              className={clsx('form-control form-control-lg')}
              onChange={(event: any) =>
                setTransport((item: any) => ({
                  ...item,
                  fromCountry: event.target.value,
                }))
              }
              value={transport.fromCountry}
              type='text'
            />
          </div>
          <div className='fv-row mb-10'>
            <label className='form-label fs-6 fw-bolder text-dark'>
              {intl.formatMessage({id: 'ADMIN.TRANSPORTS.DETAIL.FROMCITY'})}
            </label>
            <input
              disabled
              placeholder={intl.formatMessage({id: 'ADMIN.TRANSPORTS.DETAIL.FROMCITY'})}
              className={clsx('form-control form-control-lg')}
              onChange={(event: any) =>
                setTransport((item: any) => ({
                  ...item,
                  fromCity: event.target.value,
                }))
              }
              value={transport.fromCity}
              type='text'
            />
          </div>
          <div className='fv-row mb-10'>
            <label className='form-label fs-6 fw-bolder text-dark'>
              {intl.formatMessage({id: 'ADMIN.TRANSPORTS.DETAIL.TOCOUNTRY'})}
            </label>
            <input
              disabled
              placeholder={intl.formatMessage({id: 'ADMIN.TRANSPORTS.DETAIL.TOCOUNTRY'})}
              className={clsx('form-control form-control-lg')}
              onChange={(event: any) =>
                setTransport((item: any) => ({
                  ...item,
                  toCountry: event.target.value,
                }))
              }
              value={transport.toCountry}
              type='text'
            />
          </div>
          <div className='fv-row mb-10'>
            <label className='form-label fs-6 fw-bolder text-dark'>
              {intl.formatMessage({id: 'ADMIN.TRANSPORTS.DETAIL.TOCITY'})}
            </label>
            <input
              disabled
              placeholder={intl.formatMessage({id: 'ADMIN.TRANSPORTS.DETAIL.TOCITY'})}
              className={clsx('form-control form-control-lg')}
              onChange={(event: any) =>
                setTransport((item: any) => ({
                  ...item,
                  toCity: event.target.value,
                }))
              }
              value={transport.toCity}
              type='text'
            />
          </div>
          <div className='fv-row mb-10'>
            <label className='form-label fs-6 fw-bolder text-dark'>
              {intl.formatMessage({id: 'ADMIN.TRANSPORTS.DETAIL.PLANNEDDEPARTUREDATE'})}
            </label>
            <input
              disabled
              placeholder={intl.formatMessage({id: 'ADMIN.TRANSPORTS.DETAIL.PLANNEDDEPARTUREDATE'})}
              className={clsx('form-control form-control-lg')}
              onChange={(event: any) =>
                setTransport((item: any) => ({
                  ...item,
                  plannedDepartureDate: event.target.value,
                }))
              }
              value={
                transport.plannedDepartureDate
                  ? getDateTime(transport.plannedDepartureDate, intl.locale)
                  : ''
              }
              type='text'
            />
          </div>
          <div className='fv-row mb-10'>
            <label className='form-label fs-6 fw-bolder text-dark'>
              {intl.formatMessage({id: 'ADMIN.TRANSPORTS.DETAIL.PLANNEDARRIVALDATE'})}
            </label>
            <input
              disabled
              placeholder={intl.formatMessage({id: 'ADMIN.TRANSPORTS.DETAIL.PLANNEDARRIVALDATE'})}
              className={clsx('form-control form-control-lg')}
              onChange={(event: any) =>
                setTransport((item: any) => ({
                  ...item,
                  plannedArrivalDate: event.target.value,
                }))
              }
              value={
                transport.plannedArrivalDate
                  ? getDateTime(transport.plannedArrivalDate, intl.locale)
                  : ''
              }
              type='text'
            />
          </div>
          <div className='fv-row mb-10'>
            <label className='form-label fs-6 fw-bolder text-dark'>
              {intl.formatMessage({id: 'ADMIN.TRANSPORTS.DETAIL.REALDEPARTUREDATE'})}
            </label>
            <input
              disabled
              placeholder={intl.formatMessage({id: 'ADMIN.TRANSPORTS.DETAIL.REALDEPARTUREDATE'})}
              className={clsx('form-control form-control-lg')}
              onChange={(event: any) =>
                setTransport((item: any) => ({
                  ...item,
                  realDepartureDate: event.target.value,
                }))
              }
              value={transport.realDepartureDate}
              type='text'
            />
          </div>
          <div className='fv-row mb-10'>
            <label className='form-label fs-6 fw-bolder text-dark'>
              {intl.formatMessage({id: 'ADMIN.TRANSPORTS.DETAIL.REALARRIVALDATE'})}
            </label>
            <input
              disabled
              placeholder={intl.formatMessage({id: 'ADMIN.TRANSPORTS.DETAIL.REALARRIVALDATE'})}
              className={clsx('form-control form-control-lg')}
              onChange={(event: any) =>
                setTransport((item: any) => ({
                  ...item,
                  realDepartureDate: event.target.value,
                }))
              }
              value={transport.realDepartureDate}
              type='text'
            />
          </div>
        </div>
      </div>
      <div className='text-center mt-5 row view-details-freight-popup'>
        <div className={'col-md-6'}>
          <button
            onClick={() => {
              onClose()
              scrollUnlock()
            }}
            type='button'
            id='kt_sign_up_submit'
            className='btn btn-lg btn-danger w-100 mb-5 '
          >
            {intl.formatMessage({id: 'Carrier.freightPosting.view.close'})}
          </button>
        </div>
      </div>
    </RightLayoutPopup>
  )
}

export default ShowDetails
