import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import clsx from 'clsx'

import RightLayoutPopup from '../../../../Components/RightLayoutPopup'
import useScrollLock from '../../../../../utils/useScrollLock'
import {exceptThisSymbols} from '../../../../../utils/common'

import './../Adverts.scss'

const EditProposal = ({
  currencyList,
  selectedProposalId,
  selectedEditProposal,
  setShowEditProposalPopup,
  setSelectedEditProposal,
  setLoading,
  handleUpdateProposal,
  proposalScopeList,
}: any) => {
  const intl = useIntl()
  const {scrollLock, scrollUnlock} = useScrollLock()
  useEffect(() => {
    scrollLock()
  }, [])

  return (
    <RightLayoutPopup
      title={intl.formatMessage({id: 'Admin.Adverts.updateProposal.title'})}
      className='admin-edit-freight-proposal-wrap'
      isCloseIcon
      closeOnClick={() => setShowEditProposalPopup()}
    >
      <div className={'mt-10'}>
        <div className={'row'}>
          <div className={'mb-5'}>
            <div className={'col-md-12 row'}>
              <div className={'col-md-4 mb-5'}>
                <label className={'form-label fw-bolder text-dark fs-6 mb-2'}>
                  {intl.formatMessage({id: 'Carrier.freightPosting.offered.price'})}
                </label>
                <input
                  type='number'
                  id={'value'}
                  step='any'
                  onKeyDown={(e) => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                  placeholder={intl.formatMessage({id: 'Admin.Adverts.value'})}
                  value={selectedEditProposal?.price}
                  onChange={(e) =>
                    setSelectedEditProposal((item: any) => ({...item, price: e.target.value}))
                  }
                  min={0}
                  className={clsx('form-control', {
                    'price-input-wrap': selectedEditProposal?.price,
                  })}
                />
              </div>
              <div className={'col-md-4'}>
                <label className={'form-label fw-bolder text-dark fs-6 mb-2'}>
                  {intl.formatMessage({id: 'Admin.Adverts.currency'})}
                </label>
                <select
                  id={'currency'}
                  className='form-select'
                  onChange={(event: any) =>
                    setSelectedEditProposal((item: any) => ({
                      ...item,
                      currency: event.target.value,
                    }))
                  }
                  value={selectedEditProposal?.currency}
                >
                  <option value='' selected disabled>
                    {intl.formatMessage({id: 'Admin.Adverts.currency'})}
                  </option>
                  {currencyList &&
                    currencyList.map((item: any, key: number) => (
                      <option key={key} value={item?.currencyId}>
                        {item?.description}
                      </option>
                    ))}
                </select>
              </div>
              <div className={'col-md-4 mb-5'}>
                <label className='form-label fw-bolder text-dark fs-6 mb-2'>
                  {intl.formatMessage({id: 'Admin.Adverts.createProposal.proposalScope'})}
                </label>
                <select
                  id={'proposalScope'}
                  className='form-select'
                  onChange={(e: any) =>
                    setSelectedEditProposal((item: any) => ({
                      ...item,
                      explanation: Number(e.target.value) !== 4 ? '' : item.explanation,
                      proposalScope: Number(e.target.value),
                    }))
                  }
                  value={selectedEditProposal?.proposalScope}
                >
                  <option value='' selected disabled>
                    {intl.formatMessage({id: 'Admin.Adverts.createProposal.proposalScope'})}
                  </option>
                  {proposalScopeList &&
                    proposalScopeList?.map((item: any, key: number) => (
                      <option key={key} value={item?.proposalScopeId}>
                        {item?.description}
                      </option>
                    ))}
                </select>
              </div>
            </div>
          </div>
          {selectedEditProposal?.proposalScope === 4 && (
            <div>
              <label className='class="form-label fw-bolder text-dark fs-6 mb-2'>
                {intl.formatMessage({id: 'Admin.Adverts.createProposal.description'})}
              </label>
              <textarea
                rows={6}
                placeholder={intl.formatMessage({
                  id: 'Admin.Adverts.createProposal.description.placeHolder',
                })}
                autoComplete='off'
                className='form-control form-control-lg form-control-solid bg-white'
                value={selectedEditProposal?.explanation}
                onChange={(e: any) =>
                  setSelectedEditProposal((item: any) => ({...item, explanation: e.target.value}))
                }
              />
            </div>
          )}
        </div>

        <div className='text-center mt-5 row freightPost-popup-container'>
          <div className={'col-md-6'}>
            <button
              onClick={() => {
                setShowEditProposalPopup(false)
                scrollUnlock()
              }}
              type='button'
              id='kt_sign_up_submit'
              className='btn btn-lg btn-danger w-100 mb-5 '
            >
              {intl.formatMessage({id: 'CreateNewContainerPopup.cancel'})}
            </button>
          </div>
          <div className={'col-md-6'}>
            <button
              className={'btn btn-success w-100'}
              onClick={() => {
                setLoading(true)
                setShowEditProposalPopup(false)
                handleUpdateProposal({
                  ...selectedEditProposal,
                  freightProposalId: selectedProposalId,
                })
              }}
            >
              {intl.formatMessage({id: 'Admin.Adverts.updateProposal.update'})}
            </button>
          </div>
        </div>
      </div>
    </RightLayoutPopup>
  )
}

export default EditProposal
