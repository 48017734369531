import {apiUrl, globalPageCount} from '../conf'
import axios from 'axios'

export class ListingService {
  getListings({
    pageIndex,
    listingTypeId,
    description,
    startPrice,
    endPrice,
    model,
    currencyId,
    brandIdList,
    cityId,
    country,
    startRegistrationYear,
    endRegistrationYear,
    startGwr,
    endGwr,
    startPayload,
    endPayload,
    startMileage,
    endMileage,
    startPowrOutputHp,
    endPowerOutputHp,
    startPowerOutputKwh,
    endPowerOutputKwh,
    cabinTypeIdList,
    axleTypeIdList,
  }: any) {
    const config: any = {
      method: 'get',
      url:
        apiUrl +
        `/listing-management/listings/filter?description=${description ?? ''}&startPrice=${
          startPrice ?? ''
        }&endPrice=${endPrice ?? ''}&model=${model ?? ''}&currencyId=${
          currencyId ?? ''
        }&brandIdList=${brandIdList ?? ''}&cityId=${cityId ?? ''}&country=${
          country ?? ''
        }&startRegistrationYear=${startRegistrationYear ?? ''}&endRegistrationYear=${
          endRegistrationYear ?? ''
        }&startGwr=${startGwr ?? ''}&endGwr=${endGwr ?? ''}&startPayload=${
          startPayload ?? ''
        }&endPayload=${endPayload ?? ''}&startMileage=${startMileage ?? ''}&endMileage=${
          endMileage ?? ''
        }&startPowrOutputHp=${startPowrOutputHp ?? ''}&endPowerOutputHp=${
          endPowerOutputHp ?? ''
        }&startPowerOutputKwh=${startPowerOutputKwh ?? ''}&endPowerOutputKwh=${
          endPowerOutputKwh ?? ''
        }&cabinTypeIdList=${cabinTypeIdList ?? ''}&axleTypeIdList=${
          axleTypeIdList ?? ''
        }&listingTypeId=${
          !listingTypeId || listingTypeId === 'all' ? '' : listingTypeId
        }&pageIndex=${pageIndex ?? 0}&pageCount=${globalPageCount}`,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  getListingsReview({
    pageIndex,
    listingTypeId,
    description,
    startPrice,
    endPrice,
    model,
    currencyId,
    brandIdList,
    cityId,
    country,
    startRegistrationYear,
    endRegistrationYear,
    startGwr,
    endGwr,
    startPayload,
    endPayload,
    startMileage,
    endMileage,
    startPowrOutputHp,
    endPowerOutputHp,
    startPowerOutputKwh,
    endPowerOutputKwh,
    cabinTypeIdList,
    axleTypeIdList,
    listingStatusId,
  }: any) {
    const config: any = {
      method: 'get',
      url:
        apiUrl +
        `/listing-management/listings/filter?description=${description ?? ''}&listingStatusId=${
          listingStatusId ?? 2
        }&startPrice=${startPrice ?? ''}&endPrice=${endPrice ?? ''}&model=${
          model ?? ''
        }&currencyId=${currencyId ?? ''}&brandIdList=${brandIdList ?? ''}&cityId=${
          cityId ?? ''
        }&country=${country ?? ''}&startRegistrationYear=${
          startRegistrationYear ?? ''
        }&endRegistrationYear=${endRegistrationYear ?? ''}&startGwr=${startGwr ?? ''}&endGwr=${
          endGwr ?? ''
        }&startPayload=${startPayload ?? ''}&endPayload=${endPayload ?? ''}&startMileage=${
          startMileage ?? ''
        }&endMileage=${endMileage ?? ''}&startPowrOutputHp=${
          startPowrOutputHp ?? ''
        }&endPowerOutputHp=${endPowerOutputHp ?? ''}&startPowerOutputKwh=${
          startPowerOutputKwh ?? ''
        }&endPowerOutputKwh=${endPowerOutputKwh ?? ''}&cabinTypeIdList=${
          cabinTypeIdList ?? ''
        }&axleTypeIdList=${axleTypeIdList ?? ''}&listingTypeId=${
          !listingTypeId || listingTypeId === 'all' ? '' : listingTypeId
        }&pageIndex=${pageIndex ?? 0}&pageCount=${globalPageCount}`,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  getPublicListings({
    pageIndex,
    listingTypeId,
    description,
    startPrice,
    endPrice,
    model,
    currencyId,
    brandIdList,
    cityId,
    country,
    startRegistrationYear,
    endRegistrationYear,
    startGwr,
    endGwr,
    startPayload,
    endPayload,
    startMileage,
    endMileage,
    startPowrOutputHp,
    endPowerOutputHp,
    startPowerOutputKwh,
    endPowerOutputKwh,
    cabinTypeIdList,
    axleTypeIdList,
  }: any) {
    const config: any = {
      method: 'get',
      url:
        apiUrl +
        `/listing-management/public/listings/filter?description=${description ?? ''}&startPrice=${
          startPrice ?? ''
        }&endPrice=${endPrice ?? ''}&model=${model ?? ''}&currencyId=${
          currencyId ?? ''
        }&brandIdList=${brandIdList ?? ''}&cityId=${cityId ?? ''}&country=${
          country ?? ''
        }&startRegistrationYear=${startRegistrationYear ?? ''}&endRegistrationYear=${
          endRegistrationYear ?? ''
        }&startGwr=${startGwr ?? ''}&endGwr=${endGwr ?? ''}&startPayload=${
          startPayload ?? ''
        }&endPayload=${endPayload ?? ''}&startMileage=${startMileage ?? ''}&endMileage=${
          endMileage ?? ''
        }&startPowrOutputHp=${startPowrOutputHp ?? ''}&endPowerOutputHp=${
          endPowerOutputHp ?? ''
        }&startPowerOutputKwh=${startPowerOutputKwh ?? ''}&endPowerOutputKwh=${
          endPowerOutputKwh ?? ''
        }&cabinTypeIdList=${cabinTypeIdList ?? ''}&axleTypeIdList=${
          axleTypeIdList ?? ''
        }&listingTypeId=${
          !listingTypeId || listingTypeId === 'all' ? '' : listingTypeId
        }&pageIndex=${pageIndex ?? 0}&pageCount=${globalPageCount}`,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  getLandingPublicListings({
    pageIndex,
    listingTypeId,
    description,
    startPrice,
    endPrice,
    model,
    currencyId,
    brandIdList,
    cityId,
    country,
    startRegistrationYear,
    endRegistrationYear,
    startGwr,
    endGwr,
    startPayload,
    endPayload,
    startMileage,
    endMileage,
    startPowrOutputHp,
    endPowerOutputHp,
    startPowerOutputKwh,
    endPowerOutputKwh,
    cabinTypeIdList,
    axleTypeIdList,
  }: any) {
    const config: any = {
      method: 'get',
      url:
        apiUrl +
        `/listing-management/public/listings/filter?description=${description ?? ''}&startPrice=${
          startPrice ?? ''
        }&endPrice=${endPrice ?? ''}&model=${model ?? ''}&currencyId=${
          currencyId ?? ''
        }&brandIdList=${brandIdList ?? ''}&cityId=${cityId ?? ''}&country=${
          country ?? ''
        }&startRegistrationYear=${startRegistrationYear ?? ''}&endRegistrationYear=${
          endRegistrationYear ?? ''
        }&startGwr=${startGwr ?? ''}&endGwr=${endGwr ?? ''}&startPayload=${
          startPayload ?? ''
        }&endPayload=${endPayload ?? ''}&startMileage=${startMileage ?? ''}&endMileage=${
          endMileage ?? ''
        }&startPowrOutputHp=${startPowrOutputHp ?? ''}&endPowerOutputHp=${
          endPowerOutputHp ?? ''
        }&startPowerOutputKwh=${startPowerOutputKwh ?? ''}&endPowerOutputKwh=${
          endPowerOutputKwh ?? ''
        }&cabinTypeIdList=${cabinTypeIdList ?? ''}&axleTypeIdList=${
          axleTypeIdList ?? ''
        }&listingTypeId=${
          !listingTypeId || listingTypeId === 'all' ? '' : listingTypeId
        }&pageIndex=${pageIndex ?? 0}&pageCount=2`,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  getAnnouncementList = () => {
    const config: any = {
      method: 'get',
      url:
        apiUrl +
        '/announcement-management/public/announcements?searchText=&announcementTypeId=1&pageIndex=0&pageCount=1000',
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  getListingType() {
    const config: any = {
      method: 'get',
      url: apiUrl + '/listing-management/listings/types',
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  getPublicListingType() {
    const config: any = {
      method: 'get',
      url: apiUrl + '/listing-management/public/listings/types',
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  getCurrencyList() {
    const config: any = {
      method: 'get',
      url: apiUrl + '/currency-management/currencies',
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  getPublicCurrencyList() {
    const config: any = {
      method: 'get',
      url: apiUrl + '/currency-management/public/currencies',
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  getBrandList() {
    const config: any = {
      method: 'get',
      url: apiUrl + '/brand-management/brands?searchText=&pageIndex=0&pageCount=1000',
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  getPublicBrandList() {
    const config: any = {
      method: 'get',
      url: apiUrl + '/brand-management/public/brands?searchText=&pageIndex=0&pageCount=1000',
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  getCabinTypeList() {
    const config: any = {
      method: 'get',
      url: apiUrl + '/listing-management/listings/cabins/types',
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  getPublicCabinTypeList() {
    const config: any = {
      method: 'get',
      url: apiUrl + '/listing-management/public/listings/cabins/types',
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  getAxleTypeList() {
    const config: any = {
      method: 'get',
      url: apiUrl + '/listing-management/listings/axles/types',
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  getPublicAxleTypeList() {
    const config: any = {
      method: 'get',
      url: apiUrl + '/listing-management/public/listings/axles/types',
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  getTruckDetails(id: any) {
    const config: any = {
      method: 'get',
      url: apiUrl + `/listing-management/listings/trucks/${id}`,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  getPublicDetails(id: any) {
    const config: any = {
      method: 'get',
      url: apiUrl + `/listing-management/public/listings/${id}`,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  getPublicTruckDetails(id: any) {
    const config: any = {
      method: 'get',
      url: apiUrl + `/listing-management/public/listings/trucks/${id}`,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  getTrailerDetails(id: any) {
    const config: any = {
      method: 'get',
      url: apiUrl + `/listing-management/listings/trailers/${id}`,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  getPublicTrailerDetails(id: any) {
    const config: any = {
      method: 'get',
      url: apiUrl + `/listing-management/public/listings/trailers/${id}`,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  getAdvertisementDisplayList({latitude, longitude, advertisementTypeId, cityId, country}: any) {
    const config: any = {
      method: 'get',
      url:
        apiUrl +
        `/advertisement-management/advertisements/displays?latitude=${latitude ?? ''}&longitude=${
          longitude ?? ''
        }&channel=WEB&advertisementTypeId=${advertisementTypeId ?? ''}&cityId=${
          cityId ?? ''
        }&country=${country ?? ''}`,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  getPublicAdvertisementDisplayList({
    latitude,
    longitude,
    advertisementTypeId,
    cityId,
    country,
  }: any) {
    const config: any = {
      method: 'get',
      url:
        apiUrl +
        `/advertisement-management/public/advertisements/displays?latitude=${
          latitude ?? ''
        }&longitude=${longitude ?? ''}&channel=WEB&advertisementTypeId=${
          advertisementTypeId ?? ''
        }&cityId=${cityId ?? ''}&country=${country ?? ''}`,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  getListingFileList(id: any) {
    const config: any = {
      method: 'get',
      url: apiUrl + `/listing-management/listings/${id}/files`,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  getPublicListingFileList(id: any) {
    const config: any = {
      method: 'get',
      url: apiUrl + `/listing-management/public/listings/${id}/files`,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  getMyListings(pageIndex: any) {
    const config: any = {
      method: 'get',
      url:
        apiUrl + `/listing-management/listings?pageIndex=${pageIndex}&pageCount=${globalPageCount}`,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  publishList(id: any, issueDate: any) {
    const data: any = JSON.stringify({
      listingId: id,
      issueDate: issueDate,
    })

    const config: any = {
      method: 'PATCH',
      url: apiUrl + '/listing-management/listings/publish',
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    }

    return axios(config)
  }

  unPublishList(id: any) {
    const config: any = {
      method: 'PATCH',
      url: apiUrl + `/listing-management/listings/${id}/unpublish`,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  createTruckListing(data: any) {
    const config: any = {
      method: 'POST',
      url: apiUrl + '/listing-management/listings/trucks',
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(data),
    }

    return axios(config)
  }

  updateTruckListing(data: any) {
    const config: any = {
      method: 'PUT',
      url: apiUrl + '/listing-management/listings/trucks',
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(data),
    }

    return axios(config)
  }

  createTrailerListing(data: any) {
    const config: any = {
      method: 'POST',
      url: apiUrl + '/listing-management/listings/trailers',
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(data),
    }

    return axios(config)
  }

  updateTrailerListing(data: any) {
    const config: any = {
      method: 'PUT',
      url: apiUrl + '/listing-management/listings/trailers',
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(data),
    }

    return axios(config)
  }

  uploadListingFile({listingId, file}: any) {
    const data = new FormData()
    data.append('file', file)
    const config: any = {
      method: 'POST',
      url: apiUrl + '/listing-management/listings/' + listingId + '/files',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: data,
    }

    return axios(config)
  }

  createContactListing(data: any) {
    const config: any = {
      method: 'POST',
      url: apiUrl + '/listing-management/listings/contacts',
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(data),
    }

    return axios(config)
  }

  updateContactListing(data: any) {
    const config: any = {
      method: 'PUT',
      url: apiUrl + '/listing-management/listings/contacts',
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(data),
    }

    return axios(config)
  }

  deleteFile(listingId: any) {
    const config: any = {
      method: 'DELETE',
      url: apiUrl + `/listing-management/listings/files/${listingId}`,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  deleteListing(listingId: any) {
    const config: any = {
      method: 'DELETE',
      url: apiUrl + `/listing-management/listings/${listingId}`,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  getListingContact(listingId: any) {
    const config: any = {
      method: 'get',
      url: apiUrl + `/listing-management/listings/${listingId}/contacts?pageIndex=0&pageCount=20`,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  approveListing(id: any) {
    const config: any = {
      method: 'PATCH',
      url: apiUrl + '/listing-management/listings/' + id + '/approve',
      headers: {
        'Content-Type': 'application/json',
      },
    }
    return axios(config)
  }

  rejectListing(id: number | string, reason: string) {
    const config: any = {
      method: 'PATCH',
      url: apiUrl + '/listing-management/listings/reject',
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({
        listingId: id,
        rejectReason: reason,
      }),
    }
    return axios(config)
  }

  getPublicListingContact(listingId: any) {
    const config: any = {
      method: 'get',
      url:
        apiUrl +
        `/listing-management/public/listings/${listingId}/contacts?pageIndex=0&pageCount=20`,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  getLisitngMessageListOfCurrentCompany(listingId: any) {
    const config: any = {
      method: 'get',
      url:
        apiUrl +
        `/listing-management/companies/current/listings/${listingId}/threads/messages?pageIndex=0&pageCount=1000`,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  sendMessage(data: any) {
    const config: any = {
      method: 'POST',
      url: apiUrl + '/listing-management/listings/threads/messages',
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(data),
    }

    return axios(config)
  }

  getLisitngThreadList(listingId: any) {
    const config: any = {
      method: 'get',
      url: apiUrl + `/listing-management/listings/${listingId}/threads?pageIndex=0&pageCount=1000`,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  getCompanyThreadList(pageIndex?: any) {
    const config: any = {
      method: 'get',
      url:
        apiUrl +
        `/listing-management/listings/threads?pageIndex=${pageIndex}&pageCount=${globalPageCount}`,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  getThreadMessageList(listingMessageThreadId: any, pageIndex?: any) {
    const config: any = {
      method: 'get',
      url:
        apiUrl +
        `/listing-management/listings/threads/${listingMessageThreadId}/messages?pageIndex=${pageIndex}&pageCount=${globalPageCount}`,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  readMessageList(id: any) {
    const config: any = {
      method: 'PATCH',
      url: apiUrl + '/listing-management/listing/messages/threads/' + id + '/read',
      headers: {
        'Content-Type': 'application/json',
      },
    }
    return axios(config)
  }

  defaultFileList(id: any) {
    const config: any = {
      method: 'PATCH',
      url: apiUrl + '/listing-management/listings/files/' + id + '/default',
      headers: {
        'Content-Type': 'application/json',
      },
    }
    return axios(config)
  }

  deleteContactListing(listingContactId: any) {
    const config: any = {
      method: 'DELETE',
      url: apiUrl + `/listing-management/listings/contacts/${listingContactId}`,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  getPublicContainerDashboard() {
    const config: any = {
      method: 'get',
      url: apiUrl + '/dashboard-management/public/freight/exchange/dashboard',
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }
}
