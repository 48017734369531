import React, {useEffect} from 'react'
import {useHistory} from 'react-router-dom'
import {shallowEqual, useSelector} from 'react-redux'

import {RootState} from '../../../../setup'
import {Registration} from './Registration'

import './AuthRegistration.scss'

export function AuthRegistration() {
  const navigate = useHistory()
  const dashRoute = (roles: any, from: string = '/auth') => {
    if (roles.includes('ORGANIZATION_OWNER')) return navigate.push('/admin/dashboard')
    else if (roles.includes('SYSTEM_OPERATOR')) return navigate.push('/admin/dashboard')
    else if (roles.includes('CARRIER_OPERATOR')) return navigate.push('/carrier/dashboard')
    else if (roles.includes('FREIGHT_OPERATOR')) return navigate.push('/fr-op/dashboard')
    else if (roles.includes('FORWARDER_OPERATOR')) return navigate.push('/forwarder/dashboard')
    else return navigate.push('/')
  }
  const role = useSelector<RootState>(({auth}) => auth.role, shallowEqual)
  const isAuthorized = useSelector<RootState>(({auth}) => auth.user, shallowEqual)

  useEffect(() => {
    if (isAuthorized) {
      dashRoute(role)
    }
  }, [])

  return (
    <div style={{padding: '5px', overflow: 'auto', background: 'white'}}>
      {/* begin::Heading test */}
      <div
        style={{display: 'flex', justifyContent: 'center', cursor: 'pointer'}}
        onClick={() => navigate.push('/')}
      >
        <img
          style={{width: '180', height: '120px'}}
          src='/media/logos/rivalog-logo.png'
          alt='Rivalog Logo'
        />
      </div>
      <div className='registration-content-wrap'>
        <Registration isSignUp />
      </div>
    </div>
  )
}
