/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import clsx from 'clsx'
import {useFormik} from 'formik'
import * as Yup from 'yup'

import Loading from '../../Components/Loading'
import RightLayoutPopup from '../RightLayoutPopup'
import DetailedList from '../DetailedList/DetailedList'
import Dropbox from '../Dropbox'

import useScrollLock from '../../../utils/useScrollLock'
import {exceptThisSymbols} from '../../../utils/common'
import getDateTime from '../../../utils/common'

import './styles.scss'

const AddNewAddressPopup = ({onClose, onSubmit, freightList, currencyList}: any) => {
  const intl = useIntl()
  const [loading, setLoading] = useState<boolean>(false)

  const initialValues = {
    desc: '',
    freight: '',
    price: '',
    currency: '',
  }
  const registrationSchema = Yup.object().shape({
    desc: Yup.string().required(intl.formatMessage({id: 'CreateNewContainerPopup.areaRequired'})),
    freight: Yup.string().required(
      intl.formatMessage({id: 'CreateNewContainerPopup.areaRequired'})
    ),
    price: Yup.string().required(intl.formatMessage({id: 'CreateNewContainerPopup.areaRequired'})),
    currency: Yup.string().required(
      intl.formatMessage({id: 'CreateNewContainerPopup.areaRequired'})
    ),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      onSubmit({
        desc: values?.desc,
        freightId: values?.freight,
        price: values?.price,
        currency: values?.currency,
      }).then((itm: any) => {
        if (itm) {
          formik.setFieldValue('desc', '')
          formik.setFieldValue('freight', '')
          formik.setFieldValue('price', '')
          formik.setFieldValue('currency', '')
        }
      })
    },
  })

  const {scrollLock} = useScrollLock()
  useEffect(() => {
    scrollLock()
  }, [])

  return (
    <RightLayoutPopup
      title={intl.formatMessage({id: 'CreateNewContainerPopup.createContainer'})}
      className='create-new-container-popup-wrap'
      isCloseIcon
      closeOnClick={() => onClose()}
    >
      {loading && <Loading />}
      <div>
        {/** Form Görünümü **/}
        <div className={'mb-20 mt-10'}>
          {/* begin::Form group Desc */}
          <div className=' mb-10'>
            <label className='form-label fw-bolder text-dark fs-6'>
              {intl.formatMessage({id: 'CreateNewContainerPopup.containerDesc'})}
            </label>
            <input
              placeholder={intl.formatMessage({
                id: 'CreateNewContainerPopup.containerDesc.placeholder',
              })}
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('desc')}
              className={clsx(
                'form-control form-control-lg form-control-solid bg-white',
                {
                  'is-invalid': formik.touched.desc && formik.errors.desc,
                },
                {
                  'is-valid': formik.touched.desc && !formik.errors.desc,
                }
              )}
            />
            {formik.touched.desc && formik.errors.desc && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.desc}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          <div className={'row mb-10'}>
            {/* begin::Form group Price */}
            <div className='col-md-6'>
              <label className='form-label fw-bolder text-dark fs-6'>
                {intl.formatMessage({id: 'CreateNewContainerPopup.containerValue'})}
              </label>
              <input
                placeholder={intl.formatMessage({
                  id: 'CreateNewContainerPopup.containerValue.placeholder',
                })}
                type='number'
                min={1}
                onKeyDown={(e) => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                autoComplete='off'
                {...formik.getFieldProps('price')}
                className={clsx(
                  'form-control form-control-lg form-control-solid bg-white',
                  {
                    'is-invalid': formik.touched.price && formik.errors.price,
                  },
                  {
                    'is-valid': formik.touched.price && !formik.errors.price,
                  },
                  {
                    'price-input-wrap': formik.values.price,
                  }
                )}
              />
              {formik.touched.price && formik.errors.price && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.price}</span>
                  </div>
                </div>
              )}
            </div>
            {/* end::Form group */}

            {/* begin::Form group Currency */}
            <div className='col-md-6'>
              <label className='form-label fw-bolder text-dark fs-6'>
                {intl.formatMessage({id: 'CreateNewContainerPopup.currency'})}
              </label>

              <Dropbox
                hideArrow
                customClass={'form-control form-control-lg'}
                placeholder={intl.formatMessage({id: 'Carrier.newContainer.create.currency'})}
                customSearchValue={''}
                customOnSearch={(e: any) => {}}
                disabledSearch
                onScroll={() => {}}
                setScrollCalled={() => {}}
                currentPageIndex={0}
                menuList={
                  currencyList &&
                  currencyList.map((item: any) => {
                    return {
                      id: item?.currencyId,
                      title: item?.description,
                    }
                  })
                }
                selectedItemData={(item: any) => {
                  if (item?.id) {
                    formik.setFieldValue('currency', item?.id)
                  }
                }}
              />
              {formik.touched.currency && formik.errors.currency && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.currency}</span>
                  </div>
                </div>
              )}
            </div>
            {/* end::Form group */}
          </div>

          {/* begin::Form group Freight */}
          <div className=' mb-10'>
            <label htmlFor={'selectList'} className='form-label fw-bolder text-dark fs-6 mb-2'>
              {intl.formatMessage({id: 'CreateNewContainerPopup.freight'})}
            </label>
            <DetailedList
              id={'selectList'}
              selectedItem={(val: any) => {
                formik.setFieldValue('freight', val)
              }}
              data={
                freightList &&
                freightList?.map((item: any) => ({
                  id: item?.freightId,
                  title: item?.freightContentType?.description,
                  subTitle:
                    intl.formatMessage({id: 'CreateNewContainerPopup.companyLabel'}) +
                    item?.company?.name,
                  data: [
                    {
                      title: intl.formatMessage({id: 'CreateNewContainerPopup.fromAddress'}),
                      desc: `${item?.fromAddress?.country?.name} / ${item?.fromAddress?.city?.name}`,
                    },
                    {
                      title: intl.formatMessage({id: 'CreateNewContainerPopup.toAddress'}),
                      desc: `${item?.toAddress?.country?.name} / ${item?.toAddress?.city?.name}`,
                    },
                    {
                      title: intl.formatMessage({id: 'CreateNewContainerPopup.plannedArrivalDate'}),
                      desc: getDateTime(item?.plannedDepartureDate, intl.locale),
                    },
                    // {
                    //   title: intl.formatMessage({
                    //     id: 'CreateNewContainerPopup.plannedDepartureDate',
                    //   }),
                    //   desc: getDateTime(item?.plannedDepartureDate, intl.locale),
                    // },
                  ],
                }))
              }
            />
            {formik.touched.freight && formik.errors.freight && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.freight}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
        </div>

        {/*Add adress button area*/}
        <div className='text-center mt-5 row'>
          <div className={'col-md-6'}>
            <button
              onClick={onClose}
              type='button'
              id='kt_sign_up_submit'
              className='btn btn-lg btn-danger w-100 mb-5 '
            >
              {intl.formatMessage({id: 'CreateNewContainerPopup.cancel'})}
            </button>
          </div>
          <div className={'col-md-6'}>
            <button
              onClick={() => {
                formik.handleSubmit()
              }}
              type='button'
              id='kt_sign_up_submit'
              className='btn btn-lg btn-primary w-100 mb-5'
            >
              {intl.formatMessage({id: 'CreateNewContainerPopup.createContainer2'})}
            </button>
          </div>
        </div>
      </div>
    </RightLayoutPopup>
  )
}

export default AddNewAddressPopup
