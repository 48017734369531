import React, {useEffect} from 'react'
import {useIntl} from 'react-intl'

import RightLayoutPopup from '../../../../Components/RightLayoutPopup'

import useScrollLock from '../../../../../utils/useScrollLock'

const ShowTransporterGroupMember = ({selectedTransporter, onClose}: any) => {
  const intl = useIntl()
  const {scrollLock, scrollUnlock} = useScrollLock()
  useEffect(() => {
    scrollLock()
  }, [])

  return (
    <RightLayoutPopup
      title={intl.formatMessage({id: 'Carrier.transporters.view.title'})}
      className='carrier-transporter-show-wrap'
      isCloseIcon
      closeOnClick={() => onClose()}
    >
      <div className={'detail-modal-scroll-container'}>
        <div className={'mb-10 '}>
          <h4 className={'form-label fw-bolder text-dark fs-3'}>
            {intl.formatMessage({id: 'Admin.Containers.driverPreferences'})}
          </h4>
          <hr />
          <div className={'row'}>
            <div className={'col-md-6 mb-10'}>
              <h6 className={'text-muted  '}>
                {intl.formatMessage({id: 'Carrier.transporters.driverName'})}
              </h6>
              <b className={'fs-5'}>{selectedTransporter?.driver?.name}</b>
            </div>
            <div className={'col-md-6 mb-10'}>
              <h6 className={'text-muted  '}>
                {intl.formatMessage({id: 'Carrier.transporters.driverSurName'})}
              </h6>
              <b className={'fs-5'}>{selectedTransporter?.driver?.surname}</b>
            </div>
            <div className={'col-md-6 mb-10'}>
              <h6 className={'text-muted '}>
                {intl.formatMessage({id: 'REGISTER.FORM.COUNTRYCODE'})}
              </h6>
              <b className={'fs-5'}>{selectedTransporter?.driver?.countryCode}</b>
            </div>
            <div className={'col-md-6 mb-10'}>
              <h6 className={'text-muted '}>{intl.formatMessage({id: 'REGISTER.FORM.PHONE'})}</h6>
              <b className={'fs-5'}>{selectedTransporter?.driver?.mobile}</b>
            </div>
          </div>
        </div>

        <div>
          <h4 className={'form-label fw-bolder text-dark fs-3'}>
            {intl.formatMessage({id: 'Admin.Containers.vehicleInfo'})}
          </h4>
          <hr />
          <div className={'row'}>
            <div className={'col-md-6 mb-10'}>
              <h6 className={'text-muted '}>
                {intl.formatMessage({id: 'Carrier.transporters.vehiclePlate'})}
              </h6>
              <b className={'fs-5'}>{selectedTransporter?.vehicle?.plate}</b>
            </div>
            <div className={'col-md-6 mb-10'}>
              <h6 className={'text-muted  '}>
                {intl.formatMessage({id: 'Carrier.transporters.view.vehicleMake'})}
              </h6>
              <b className={'fs-5'}>{selectedTransporter?.vehicle?.make}</b>
            </div>
            <div className={'col-md-6 mb-10'}>
              <h6 className={'text-muted  '}>
                {intl.formatMessage({id: 'Carrier.vehicles.update.model'})}
              </h6>
              <b className={'fs-5'}>{selectedTransporter?.vehicle?.model}</b>
            </div>
          </div>
        </div>

        <div>
          <h4 className={'form-label fw-bolder text-dark fs-3'}>
            {intl.formatMessage({id: 'Admin.Containers.trailerInfo'})}
          </h4>
          <hr />
          <div className={'row'}>
            <div className={'col-md-6 mb-10'}>
              <h6 className={'text-muted '}>
                {intl.formatMessage({id: 'Carrier.transporters.trailerPlate'})}
              </h6>
              <b className={'fs-5'}>{selectedTransporter?.trailer?.plate}</b>
            </div>
            <div className={'col-md-6 mb-10'}>
              <h6 className={'text-muted '}>
                {intl.formatMessage({id: 'Admin.Containers.specificationList'})}
              </h6>
              {selectedTransporter?.trailer?.specificationList?.map((item: any, i: number) => (
                <p key={i} className={'font-weight-bold '}>
                  <b>{item?.description}</b>
                </p>
              ))}
            </div>
            <div className={'col-md-6 mb-10'}>
              <h6 className={'text-muted '}>
                {intl.formatMessage({id: 'Freight.myAdvert.trailerType'})}
              </h6>
              <b className={'fs-5'}>{selectedTransporter?.trailer?.trailerType?.description}</b>
            </div>
            <div className={'col-md-6 mb-10'}>
              <h6 className={'text-muted '}>
                {intl.formatMessage({id: 'Freight.myAdvert.floorType'})}
              </h6>
              <b className={'fs-5'}>{selectedTransporter?.trailer?.floorType?.description}</b>
            </div>
          </div>
        </div>
      </div>

      <div className='text-center row view-details-freight-popup'>
        <div className={'col-md-6'}>
          <button
            onClick={() => {
              onClose()
              scrollUnlock()
            }}
            type='button'
            id='kt_sign_up_submit'
            className='btn btn-lg btn-danger w-100 mb-5 '
          >
            {intl.formatMessage({id: 'Carrier.freightPosting.view.close'})}
          </button>
        </div>
      </div>
    </RightLayoutPopup>
  )
}

export default ShowTransporterGroupMember
