import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import clsx from 'clsx'

import RightLayoutPopup from '../../../../Components/RightLayoutPopup'
import PhoneInp from '../../../../Components/PhoneInp/PhoneInp'

import useScrollLock from '../../../../../utils/useScrollLock'

const EditClient = ({
  countriesList,
  ClientList,
  setLoading,
  handleUpdateClient,
  onClose,
  selectedClientDetails,
  selectedClientId,
}: any) => {
  const intl = useIntl()

  const [companyName, setCompanyName] = useState<string>('')
  const [companyTitle, setCompanyTitle] = useState<string>(selectedClientDetails?.companyTitle)

  const [name, setName] = useState<string>(selectedClientDetails?.name)
  const [surName, setSurName] = useState<string>(selectedClientDetails?.surname)

  const [email, setEmail] = useState<string>(selectedClientDetails?.email)
  const [web, setWeb] = useState<string>(selectedClientDetails?.web)

  const [taxNumber, setTaxNumber] = useState<string>(selectedClientDetails?.taxNumber)
  const [taxOffice, setTaxOffice] = useState<string>(selectedClientDetails?.taxOffice)
  const [identityNumber, setIdentityNumber] = useState<string>(
    selectedClientDetails?.identityNumber
  )

  const [clientType, setClientType] = useState<any>(selectedClientDetails?.clientType?.clientTypeId)
  const [phoneCode, setPhoneCode] = useState<string>(selectedClientDetails?.countryCode)
  const [mobile, setMobile] = useState<string>(selectedClientDetails?.phone)
  const [country, setCountry] = useState<string>(selectedClientDetails?.country?.code)

  const {scrollLock, scrollUnlock} = useScrollLock()

  useEffect(() => {
    scrollLock()
    if (selectedClientDetails) {
      selectedClientDetails?.clientType?.code === 'CORPORATE' &&
        setCompanyName(selectedClientDetails?.name)
      selectedClientDetails?.clientType?.code === 'CORPORATE' &&
        setCompanyTitle(selectedClientDetails?.title)
      selectedClientDetails?.clientType?.code === 'PERSONAL' && setName(selectedClientDetails?.name)
      selectedClientDetails?.clientType?.code === 'PERSONAL' &&
        setSurName(selectedClientDetails?.surname)
      setEmail(selectedClientDetails?.email)
      setWeb(selectedClientDetails?.web)
      setTaxNumber(selectedClientDetails?.taxNumber)
      setTaxOffice(selectedClientDetails?.taxOffice)
      setIdentityNumber(selectedClientDetails?.identityNumber)
      setClientType(selectedClientDetails?.clientType?.clientTypeId)
      setPhoneCode(selectedClientDetails?.countryCode)
      setMobile(selectedClientDetails?.phone)
      setCountry(selectedClientDetails?.country?.code)
    }
  }, [])

  const handleCreateDisbaled = (clientType: number) => {
    if (!clientType) {
      return true
    } else {
      if (clientType === 1) {
        return (
          !companyName ||
          !companyTitle ||
          !email ||
          !phoneCode ||
          !mobile ||
          !country ||
          !web ||
          !taxNumber ||
          !taxOffice
        )
      } else {
        return (
          !name ||
          !surName ||
          !email ||
          !phoneCode ||
          !mobile ||
          !country ||
          !web ||
          !identityNumber
        )
      }
    }
  }

  return (
    <RightLayoutPopup
      title={intl.formatMessage({id: 'CreateClient.update.title'})}
      className='edit-client-wrap'
      isCloseIcon
      closeOnClick={() => onClose()}
    >
      <div>
        <div className='row'>
          <div className={'col-md-4 mb-10'}>
            <label className='form-label fs-6 fw-bolder text-dark'>
              {intl.formatMessage({id: 'CreateClient.create.clientType'})}
            </label>
            <select
              id={'language'}
              className='form-select'
              onChange={(event: any) => {
                setCompanyName('')
                setCompanyTitle('')
                setIdentityNumber('')
                setName('')
                setSurName('')
                setTaxNumber('')
                setTaxOffice('')
                setClientType(Number(event.target.value))
              }}
              value={clientType}
            >
              <option value='' selected disabled>
                {intl.formatMessage({id: 'CreateClient.create.clientType'})}
              </option>
              {ClientList &&
                ClientList.map((item: any, key: number) => (
                  <option key={key} value={item?.clientTypeId}>
                    {item?.description}
                  </option>
                ))}
            </select>
          </div>

          {clientType === 1 && (
            <>
              <div className='col-md-4 fv-row mb-10'>
                <label className='form-label fs-6 fw-bolder text-dark'>
                  {intl.formatMessage({id: 'CreateClient.create.companyName'})}
                </label>
                <input
                  placeholder={intl.formatMessage({id: 'CreateClient.create.companyName'})}
                  className={clsx('form-control form-control-lg form-control-solid')}
                  onChange={(event: any) => setCompanyName(event.target.value)}
                  value={companyName}
                  type='text'
                  name='name'
                  autoComplete='off'
                />
              </div>

              <div className='col-md-4 fv-row mb-10'>
                <label className='form-label fs-6 fw-bolder text-dark'>
                  {intl.formatMessage({id: 'CreateClient.create.companyTitle'})}
                </label>
                <input
                  placeholder={intl.formatMessage({id: 'CreateClient.create.companyTitle'})}
                  className={clsx('form-control form-control-lg form-control-solid')}
                  onChange={(event: any) => setCompanyTitle(event.target.value)}
                  value={companyTitle}
                  type='text'
                  name='name'
                  autoComplete='off'
                />
              </div>
            </>
          )}

          {clientType === 2 && (
            <>
              <div className='col-md-4 fv-row mb-10'>
                <label className='form-label fs-6 fw-bolder text-dark'>
                  {intl.formatMessage({id: 'CreateClient.create.name.name'})}
                </label>
                <input
                  placeholder={intl.formatMessage({id: 'CreateClient.create.name.name'})}
                  className={clsx('form-control form-control-lg form-control-solid')}
                  onChange={(event: any) => setName(event.target.value)}
                  value={name}
                  type='text'
                  name='name'
                  autoComplete='off'
                />
              </div>

              <div className='col-md-4 fv-row mb-10'>
                <label className='form-label fs-6 fw-bolder text-dark'>
                  {intl.formatMessage({id: 'CreateClient.create.surName'})}
                </label>
                <input
                  placeholder={intl.formatMessage({id: 'CreateClient.create.surName'})}
                  className={clsx('form-control form-control-lg form-control-solid')}
                  onChange={(event: any) => setSurName(event.target.value)}
                  value={surName}
                  type='text'
                  name='name'
                  autoComplete='off'
                />
              </div>
            </>
          )}
        </div>

        <div className='fv-row mb-10'>
          <label className='form-label fs-6 fw-bolder text-dark'>
            {intl.formatMessage({id: 'CreateClient.create.email'})}
          </label>
          <input
            placeholder={intl.formatMessage({id: 'CreateClient.create.email'})}
            className={clsx('form-control form-control-lg form-control-solid')}
            onChange={(event: any) => setEmail(event.target.value)}
            value={email}
            type='text'
            name='name'
            autoComplete='off'
          />
        </div>

        <div className={'row col-md-12 mb-10'}>
          <div className={'col-md-3'}>
            <label
              htmlFor={'phoneCode'}
              className='class="form-label fw-bolder text-dark fs-6 mb-2'
            >
              {intl.formatMessage({id: 'Carrier.drivers.create.phoneCode'})}
            </label>
            <PhoneInp
              id={'phoneCode'}
              defaultValue={selectedClientDetails?.countryCode}
              onSelect={(event: any) => {
                setPhoneCode(event.length > 0 ? '+' + event : event)
              }}
            />
          </div>
          <div className='fv-row col-md-5'>
            <label className='form-label fs-6 fw-bolder text-dark'>
              {intl.formatMessage({id: 'Carrier.drivers.create.phone'})}
            </label>
            <input
              placeholder={intl.formatMessage({id: 'Carrier.drivers.create.phone.placeHolder'})}
              maxLength={13}
              className={clsx('form-control form-control-lg form-control-solid')}
              onChange={(event: any) => {
                if (event.target.value.match(/^\+?(?:[\d]*)$/)) {
                  setMobile(event.target.value)
                }
              }}
              value={mobile}
              type='text'
              name='mobile'
              autoComplete='off'
              required
            />
          </div>
          <div className={'col-md-4'}>
            <label className='form-label fs-6 fw-bolder text-dark'>
              {intl.formatMessage({id: 'CreateClient.create.country'})}
            </label>
            <select
              id={'country'}
              className='form-select'
              onChange={(event: any) => setCountry(event.target.value)}
              value={country}
            >
              <option value='' selected disabled>
                {intl.formatMessage({id: 'CreateClient.create.country'})}
              </option>
              {countriesList &&
                countriesList.map((item: any, key: number) => (
                  <option key={key} value={item?.code}>
                    {item?.name}
                  </option>
                ))}
            </select>
          </div>
        </div>

        <div className='fv-row mb-10'>
          <label className='form-label fs-6 fw-bolder text-dark'>
            {intl.formatMessage({id: 'CreateClient.create.web'})}
          </label>
          <input
            placeholder={intl.formatMessage({id: 'CreateClient.create.web'})}
            className={clsx('form-control form-control-lg form-control-solid')}
            onChange={(event: any) => setWeb(event.target.value)}
            value={web}
            type='text'
            name='name'
            autoComplete='off'
          />
        </div>

        <div className='row'>
          <div className='fv-row mb-10 col-md-4'>
            <label className='form-label fs-6 fw-bolder text-dark'>
              {intl.formatMessage({id: 'CreateClient.create.taxNumber'})}
            </label>
            <input
              placeholder={intl.formatMessage({id: 'CreateClient.create.taxNumber'})}
              className={clsx('form-control form-control-lg form-control-solid')}
              onChange={(event: any) => setTaxNumber(event.target.value)}
              value={taxNumber}
              disabled={clientType === 2 || clientType === ''}
              type='text'
              name='name'
              autoComplete='off'
            />
          </div>

          <div className='fv-row mb-10 col-md-4'>
            <label className='form-label fs-6 fw-bolder text-dark'>
              {intl.formatMessage({id: 'CreateClient.create.taxOffice'})}
            </label>
            <input
              placeholder={intl.formatMessage({id: 'CreateClient.create.taxOffice'})}
              className={clsx('form-control form-control-lg form-control-solid')}
              onChange={(event: any) => setTaxOffice(event.target.value)}
              value={taxOffice}
              disabled={clientType === 2 || clientType === ''}
              type='text'
              name='name'
              autoComplete='off'
            />
          </div>

          <div className='fv-row mb-10 col-md-4'>
            <label className='form-label fs-6 fw-bolder text-dark'>
              {intl.formatMessage({id: 'CreateClient.create.identityNumber'})}
            </label>
            <input
              placeholder={intl.formatMessage({id: 'CreateClient.create.identityNumber'})}
              className={clsx('form-control form-control-lg form-control-solid')}
              onChange={(event: any) => setIdentityNumber(event.target.value)}
              value={identityNumber}
              disabled={clientType === 1 || clientType === ''}
              type='text'
              name='name'
              autoComplete='off'
            />
          </div>
        </div>

        <div className='text-center mt-5 row freightPost-popup-container'>
          <div className={'col-md-6'}>
            <button
              onClick={() => {
                onClose()
                scrollUnlock()
              }}
              type='button'
              id='kt_sign_up_submit'
              className='btn btn-lg btn-danger w-100 mb-5 '
            >
              {intl.formatMessage({id: 'CreateNewContainerPopup.cancel'})}
            </button>
          </div>
          <div className={'col-md-6'}>
            <button
              className={'btn btn-success w-100'}
              onClick={() => {
                setLoading(true)
                handleUpdateClient({
                  name: clientType === 1 ? companyName : name,
                  surname: surName,
                  identityNumber: identityNumber,
                  web: web,
                  mobile: mobile,
                  country: country,
                  countryCode: phoneCode,
                  clientTypeId: clientType,
                  email: email,
                  taxNumber: taxNumber,
                  taxOffice: taxOffice,
                  clientId: selectedClientId,
                  title: companyTitle,
                })
              }}
              disabled={handleCreateDisbaled(clientType)}
            >
              {intl.formatMessage({id: 'global.update'})}
            </button>
          </div>
        </div>
      </div>
    </RightLayoutPopup>
  )
}

export default EditClient
