import React from 'react'
import {useIntl} from 'react-intl'

import RelatedBlogs from '../RelatedBlogs'
// import Seo from '../../utils/seo'
import {toAbsoluteUrl} from '../../_metronic/helpers'

import './FreightExchangeImproveServices.scss'

// const siteNavigationStructuredData: any = {
//   '@context': 'http://schema.org',
//   '@type': 'BlogPosting',
//   name: 'blog post',
//   url: 'https://www.rivalog.com/blog/how-freight-exhcnages-improve-road-transportation-services',
//   publisher: {
//     '@type': 'Organization',
//     name: 'Rivalog',
//     logo: 'https://www.rivalog.com/media/logo.png',
//   },
// }

const FutureTrendeInFullTruckLoadFreight = () => {
  const intl = useIntl()
  const date = new Date()
  return (
    <div style={{overflow: 'auto'}}>
      <div style={{position: 'relative'}}>
        <div
          className='bg-photo-overlay'
          style={{backgroundImage: 'url("/media/futureTrendFullTruckload.jpg")'}}
        ></div>
        {/* <Seo
          data={{
            title: intl.formatMessage({id: 'Seo.freightExchngTrucking.title'}),
            metaDescription: intl.formatMessage({id: 'Seo.freightExchngTrucking.description'}),
            href: 'https://www.rivalog.com/blog/freight-exchange-trucking-streamlining-road-transportation',
          }}
        >
          <script type='application/ld+json'>{JSON.stringify(siteNavigationStructuredData)}</script>
        </Seo> */}
        <div className='page-body'>
          <div className='centered-wrap-main'>
            <header className='centered-top-container animating' role='banner'>
              <div className='centered-top'>
                <div className='blog-name container'>
                  <div className='container section' id='header'>
                    <div className='widget Header' data-version='2' id='Header1'>
                      <div className='header-widget'>
                        <div>
                          <h1>
                            <a href='https://rivalog.blogspot.com/'>Rivalog</a>
                          </h1>
                        </div>
                        <p></p>
                      </div>
                    </div>
                  </div>
                  <nav role='navigation'>
                    <div className='clearboth no-items section' id='page_list_top'></div>
                  </nav>
                </div>
              </div>
            </header>

            <div>
              <main className='centered-bottom' role='main'>
                <div className='main section'>
                  <div className='widget'>
                    <article className='post-outer-container'>
                      <div className='post-outer'>
                        <div className='post'>
                          <h3 className='post-title entry-title'>
                            {intl.formatMessage({
                              id: 'blog.FutureTrendeInFullTruckLoadFreight.title',
                            })}
                          </h3>
                          <div className='post-header'>
                            <span className='byline'>{date.toDateString()}</span>
                          </div>
                          <div className='post-body'>
                            <h2 className='img-road-wrap'>
                              <div className='separator-wrap'>
                                <img
                                  className='improved-img-wrap'
                                  src={toAbsoluteUrl('/media/futureTrendFullTruckload.jpg')}
                                  alt='future-trend'
                                />
                              </div>
                              <br />
                            </h2>

                            <p className='point-content-wrap'>
                              {intl.formatMessage({
                                id: 'blog.FutureTrendeInFullTruckLoadFreight.label',
                              })}{' '}
                              <a href='https://www.rivalog.com/'>
                                <b>
                                  {intl.formatMessage({
                                    id: 'blog.FutureTrendeInFullTruckLoadFreight.label1',
                                  })}
                                </b>
                              </a>{' '}
                              {intl.formatMessage({
                                id: 'blog.FutureTrendeInFullTruckLoadFreight.label2',
                              })}
                            </p>

                            <h2 className='img-road-wrap'>
                              {intl.formatMessage({
                                id: 'blog.FutureTrendeInFullTruckLoadFreight.label3',
                              })}
                            </h2>

                            <p className='point-content-wrap-second'>
                              {intl.formatMessage({
                                id: 'blog.FutureTrendeInFullTruckLoadFreight.label4',
                              })}
                            </p>

                            <h3 className='point-content-wrap point-content-wrap-color'>
                              {intl.formatMessage({
                                id: 'blog.FutureTrendeInFullTruckLoadFreight.label5',
                              })}
                            </h3>

                            <p className='point-content-wrap-second'>
                              {intl.formatMessage({
                                id: 'blog.FutureTrendeInFullTruckLoadFreight.label6',
                              })}
                            </p>

                            <p className='point-content-wrap-second'>
                              {intl.formatMessage({
                                id: 'blog.FutureTrendeInFullTruckLoadFreight.label7',
                              })}{' '}
                              <b>
                                <a href='https://www.rivalog.com/'>
                                  {intl.formatMessage({
                                    id: 'blog.FutureTrendeInFullTruckLoadFreight.label8',
                                  })}
                                </a>
                              </b>{' '}
                              {intl.formatMessage({
                                id: 'blog.FutureTrendeInFullTruckLoadFreight.label9',
                              })}
                            </p>

                            <h3 className='point-content-wrap point-content-wrap-color'>
                              {intl.formatMessage({
                                id: 'blog.FutureTrendeInFullTruckLoadFreight.label10',
                              })}
                            </h3>

                            <p className='point-content-wrap-second'>
                              {intl.formatMessage({
                                id: 'blog.FutureTrendeInFullTruckLoadFreight.label11',
                              })}
                            </p>

                            <p className='point-content-wrap-second'>
                              {intl.formatMessage({
                                id: 'blog.FutureTrendeInFullTruckLoadFreight.label12',
                              })}
                            </p>

                            <h3 className='point-content-wrap point-content-wrap-color'>
                              {intl.formatMessage({
                                id: 'blog.FutureTrendeInFullTruckLoadFreight.label13',
                              })}
                            </h3>

                            <p className='point-content-wrap-second'>
                              {intl.formatMessage({
                                id: 'blog.FutureTrendeInFullTruckLoadFreight.label14',
                              })}
                            </p>

                            <p className='point-content-wrap-second'>
                              {intl.formatMessage({
                                id: 'blog.FutureTrendeInFullTruckLoadFreight.label15',
                              })}
                            </p>

                            <h2 className='img-road-wrap'>
                              {intl.formatMessage({
                                id: 'blog.FutureTrendeInFullTruckLoadFreight.label16',
                              })}
                            </h2>

                            <p className='point-content-wrap-second'>
                              {intl.formatMessage({
                                id: 'blog.FutureTrendeInFullTruckLoadFreight.label17',
                              })}
                            </p>

                            <h3 className='point-content-wrap point-content-wrap-color'>
                              {intl.formatMessage({
                                id: 'blog.FutureTrendeInFullTruckLoadFreight.label18',
                              })}
                            </h3>

                            <p className='point-content-wrap-second'>
                              {intl.formatMessage({
                                id: 'blog.FutureTrendeInFullTruckLoadFreight.label19',
                              })}
                            </p>

                            <p className='point-content-wrap-second'>
                              {intl.formatMessage({
                                id: 'blog.FutureTrendeInFullTruckLoadFreight.label20',
                              })}
                            </p>

                            <h3 className='point-content-wrap point-content-wrap-color'>
                              {intl.formatMessage({
                                id: 'blog.FutureTrendeInFullTruckLoadFreight.label21',
                              })}
                            </h3>

                            <p className='point-content-wrap-second'>
                              {intl.formatMessage({
                                id: 'blog.FutureTrendeInFullTruckLoadFreight.label22',
                              })}
                            </p>

                            <p className='point-content-wrap-second'>
                              {intl.formatMessage({
                                id: 'blog.FutureTrendeInFullTruckLoadFreight.label23',
                              })}
                            </p>

                            <h2 className='img-road-wrap'>
                              {intl.formatMessage({
                                id: 'blog.FutureTrendeInFullTruckLoadFreight.label24',
                              })}
                            </h2>

                            <p className='point-content-wrap-second'>
                              {intl.formatMessage({
                                id: 'blog.FutureTrendeInFullTruckLoadFreight.label25',
                              })}
                            </p>

                            <h3 className='point-content-wrap point-content-wrap-color'>
                              {intl.formatMessage({
                                id: 'blog.FutureTrendeInFullTruckLoadFreight.label26',
                              })}
                            </h3>

                            <p className='point-content-wrap-second'>
                              {intl.formatMessage({
                                id: 'blog.FutureTrendeInFullTruckLoadFreight.label27',
                              })}
                            </p>

                            <p className='point-content-wrap-second'>
                              {intl.formatMessage({
                                id: 'blog.FutureTrendeInFullTruckLoadFreight.label28',
                              })}
                            </p>

                            <h3 className='point-content-wrap point-content-wrap-color'>
                              {intl.formatMessage({
                                id: 'blog.FutureTrendeInFullTruckLoadFreight.label29',
                              })}
                            </h3>

                            <p className='point-content-wrap-second'>
                              {intl.formatMessage({
                                id: 'blog.FutureTrendeInFullTruckLoadFreight.label30',
                              })}
                            </p>

                            <p className='point-content-wrap-second'>
                              {intl.formatMessage({
                                id: 'blog.FutureTrendeInFullTruckLoadFreight.label31',
                              })}
                            </p>

                            <h2 className='img-road-wrap'>
                              {intl.formatMessage({
                                id: 'blog.FutureTrendeInFullTruckLoadFreight.label32',
                              })}
                            </h2>

                            <p className='point-content-wrap-second'>
                              {intl.formatMessage({
                                id: 'blog.FutureTrendeInFullTruckLoadFreight.label33',
                              })}
                            </p>

                            <h3 className='point-content-wrap point-content-wrap-color'>
                              {intl.formatMessage({
                                id: 'blog.FutureTrendeInFullTruckLoadFreight.label34',
                              })}
                            </h3>

                            <p className='point-content-wrap-second'>
                              {intl.formatMessage({
                                id: 'blog.FutureTrendeInFullTruckLoadFreight.label35',
                              })}
                            </p>

                            <p className='point-content-wrap-second'>
                              {intl.formatMessage({
                                id: 'blog.FutureTrendeInFullTruckLoadFreight.label36',
                              })}
                            </p>

                            <h3 className='point-content-wrap point-content-wrap-color'>
                              {intl.formatMessage({
                                id: 'blog.FutureTrendeInFullTruckLoadFreight.label37',
                              })}
                            </h3>

                            <p className='point-content-wrap-second'>
                              {intl.formatMessage({
                                id: 'blog.FutureTrendeInFullTruckLoadFreight.label38',
                              })}
                            </p>

                            <p className='point-content-wrap-second'>
                              {intl.formatMessage({
                                id: 'blog.FutureTrendeInFullTruckLoadFreight.label39',
                              })}
                            </p>

                            <h2 className='img-road-wrap'>
                              {intl.formatMessage({
                                id: 'blog.FutureTrendeInFullTruckLoadFreight.label40',
                              })}
                            </h2>

                            <p className='point-content-wrap-second'>
                              {intl.formatMessage({
                                id: 'blog.FutureTrendeInFullTruckLoadFreight.label41',
                              })}
                            </p>

                            <p className='point-content-wrap-second'>
                              {intl.formatMessage({
                                id: 'blog.FutureTrendeInFullTruckLoadFreight.label42',
                              })}
                            </p>

                            <p className='point-content-wrap-second'>
                              {intl.formatMessage({
                                id: 'blog.FutureTrendeInFullTruckLoadFreight.label43',
                              })}
                              , <b>Rivalog</b>{' '}
                              {intl.formatMessage({
                                id: 'blog.FutureTrendeInFullTruckLoadFreight.label44',
                              })}
                            </p>

                            <p className='point-content-wrap-second'>
                              {intl.formatMessage({
                                id: 'blog.FutureTrendeInFullTruckLoadFreight.label45',
                              })}{' '}
                              <b>
                                <a href='https://www.rivalog.com/'>Rivalog</a>
                              </b>
                            </p>
                          </div>
                        </div>
                      </div>
                    </article>
                  </div>
                </div>
              </main>
            </div>
          </div>
        </div>
      </div>
      <RelatedBlogs url='/blog/future-trend-in-full-truck-load-freight-freight-and-road-transportation' />
    </div>
  )
}

export default FutureTrendeInFullTruckLoadFreight
