import axios from 'axios'
import {AuthModel} from '../models/AuthModel'
import {UserModel} from '../models/UserModel'
import {apiUrl} from "../../../../services/conf";

const API_URL = process.env.REACT_APP_API_URL


export const GET_USER_BY_ACCESSTOKEN_URL = `${apiUrl}/user-management/users/current`
export const LOGIN_URL = `${apiUrl}/login`
export const REGISTER_URL = `${apiUrl}/register`
export const REQUEST_PASSWORD_URL = `${apiUrl}/user-management/users/password/link/byemail`

// Server should return AuthModel
export function login(email: string, password: string, companyId: string) {
    return Promise.resolve(JSON.parse(JSON.stringify({data: {access_token: '123123'}})))
    return axios.get(apiUrl + '', {
        params: {
            email: email,
            password: password,
            companyId: companyId
        },
    })
}

// Server should return AuthModel
export function register(email: string, firstname: string, lastname: string, password: string, password_confirmation: string) {
    return Promise.resolve(JSON.parse(JSON.stringify({data: {access_token: '123123'}})))
    return axios.post<AuthModel>(REGISTER_URL, {
        email,
        first_name: firstname,
        last_name: lastname,
        password,
        password_confirmation,
    })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
    return Promise.resolve(JSON.parse(JSON.stringify({result: true})))
    return axios.get<{ result: boolean }>(REQUEST_PASSWORD_URL, {
        params: {
            email: email,
        },
    })
}

export function getUserByToken() {
 /*   return Promise.resolve(JSON.parse(JSON.stringify({
        data: {
            id: 1,
            username: 'ahmet',
            password: undefined,
            email: 'ahmet@epsilam.com',
            name: "Ahmet",
            surname: "YILDIZ"
        },
    })))*/
    // Authorization head should be fulfilled in interceptor.
    // Check common redux folder => setupAxios
    return axios.get<UserModel>(GET_USER_BY_ACCESSTOKEN_URL)
}
