import React, {useState} from 'react'
import {useIntl} from 'react-intl'
import {format} from 'date-fns'
import Multiselect from 'multiselect-react-dropdown'
import clsx from 'clsx'

import RightLayoutPopup from '../../../../Components/RightLayoutPopup'
import TabManagerComponent from '../../../../Components/TabManager/TabManagerComponent'
import DatePicker from '../../../../Components/DatePicker'
import Dropbox from '../../../../Components/Dropbox'

import {exceptThisSymbols} from '../../../../../utils/common'

const FreightPublishPopup = ({
  onClose,
  publishFreight,
  publishNonFormatDate,
  publishDate,
  setPublishNonFormatDate,
  setPublishDate,
  transporterGroupUserList,
  selectedPublishFreightTransporterGroup,
  setSelectedPublishFreightTransporterGroup,
  selectedFreightId,
  setPublicFreightExchange,
  publicFreightExchange,
  setPrivateFreightExchange,
  privateFreightExchange,
  description,
  setDescription,
  targetCost,
  setTargetCost,
  selectedCurrency,
  selectedCurrencyName,
  currencyList,
  setSelectedCurrency,
  setSelectedCurrencyName,
}: any) => {
  const intl = useIntl()

  const [disabledGroup, setDisabledGroup] = useState<boolean>(true)

  const firstStepContent = () => (
    <div className='row'>
      <div className='col-md-12 mb-5'>
        <label className='form-label fs-6 fw-bolder text-dark'>
          {intl.formatMessage({id: 'Carrier.freightPosting.description'})}
        </label>
        <input
          placeholder={intl.formatMessage({id: 'Carrier.freightPosting.description'})}
          className={'form-control form-control-lg bg-white'}
          onChange={(event: any) => setDescription(event.target.value)}
          value={description}
          type='text'
          autoComplete='off'
        />
      </div>

      <div className='col-md-6 mb-5'>
        <label className='form-label fs-6 fw-bolder text-dark'>
          {intl.formatMessage({id: 'Carrier.freightPosting.price'})}
        </label>
        <input
          type='number'
          id={'targetCost'}
          onKeyDown={(e) => exceptThisSymbols.includes(e.key) && e.preventDefault()}
          placeholder={intl.formatMessage({id: 'Carrier.freightPosting.price'})}
          value={targetCost}
          onChange={(e) => setTargetCost(e.target.value)}
          min={0}
          className={clsx('form-control', {
            'price-input-wrap': targetCost,
          })}
        />
      </div>

      <div className={'col-md-6 mb-5'}>
        <label className={'form-label fw-bolder text-dark fs-6 mb-2'}>
          {intl.formatMessage({id: 'Admin.Adverts.currency'})}
        </label>
        <Dropbox
          hideArrow
          customClass={'form-select'}
          placeholder={intl.formatMessage({id: 'Admin.Adverts.currency'})}
          customSearchValue={''}
          customOnSearch={(e: any) => {}}
          onScroll={() => {}}
          setScrollCalled={() => {}}
          currentPageIndex={0}
          defaultSelected={{
            id: selectedCurrency,
            title: selectedCurrencyName,
          }}
          menuList={
            currencyList &&
            currencyList.map((item: any) => {
              return {
                id: item?.currencyId,
                title: item?.description,
              }
            })
          }
          selectedItemData={(dataItem: any) => {
            if (dataItem?.id) {
              setSelectedCurrency(dataItem?.id)
              setSelectedCurrencyName(dataItem?.title)
            }
          }}
        />
      </div>
    </div>
  )

  const secondStepContent = () => (
    <div className={'row'}>
      <div className={'col-md-6 d-flex align-items-end mb-5'}>
        <div className='form-check d-flex align-items-center'>
          <div>
            <input
              type='checkbox'
              id={'adr1'}
              onChange={() => setPublicFreightExchange(!publicFreightExchange)}
              checked={publicFreightExchange}
              className={'form-check-input'}
            />
          </div>
          <label htmlFor='adr1' className={'text-muted fs-4 ml-3 form-check-label'}>
            {intl.formatMessage({id: 'Carrier.advert.publishToPublishFreight'})}
          </label>
        </div>
      </div>
      <div className={'col-md-6 d-flex align-items-end mb-5'}>
        <div className='form-check d-flex align-items-center'>
          <div>
            <input
              type='checkbox'
              id={'adr1'}
              onChange={() => {
                setPrivateFreightExchange(!privateFreightExchange)
                setDisabledGroup(!disabledGroup)
              }}
              checked={privateFreightExchange}
              className={'form-check-input'}
            />
          </div>
          <label htmlFor='adr1' className={'text-muted fs-4 ml-3 form-check-label'}>
            {intl.formatMessage({id: 'Carrier.advert.publishToPrivateFreight'})}
          </label>
        </div>
      </div>
      <div className='col-md-6 mt-5'>
        <label htmlFor='publishDate' className={'text-muted fs-4 mb-2'}>
          {intl.formatMessage({id: 'Admin.Containers.publishingDate'})}
        </label>
        <DatePicker
          value={publishNonFormatDate}
          onSelect={(data: any) => {
            setPublishDate(format(data, 'yyyy-MM-dd'))
            setPublishNonFormatDate(data)
          }}
          className={'freight-publish-date-wrap'}
          selected={publishDate}
          customDate={publishNonFormatDate}
          disabledDays={new Date()}
          captionLayout={'buttons'}
          menuDefaultOpen
        />
      </div>
      <div className='col-md-6 mt-5'>
        <label htmlFor='publishDate' className={'text-muted fs-4 mb-2'}>
          {intl.formatMessage({id: 'Carrier.transporterGroup.publish.group'})}
        </label>
        <Multiselect
          options={transporterGroupUserList}
          disable={disabledGroup}
          selectedValues={selectedPublishFreightTransporterGroup}
          onSelect={(value: any) => setSelectedPublishFreightTransporterGroup(value)}
          onRemove={(value: any) => setSelectedPublishFreightTransporterGroup(value)}
          displayValue='name'
          style={{
            inputField: {
              minHeight: '30px',
              height: 'auto',
            },
          }}
        />
      </div>
    </div>
  )

  return (
    <RightLayoutPopup
      title={intl.formatMessage({id: 'Carrier.freight.publishFreight'})}
      className='right-layout-show-wrap'
      isCloseIcon
      closeOnClick={() => onClose()}
    >
      <TabManagerComponent
        onSubmit={() => {
          publishFreight({
            freight: {
              freightId: selectedFreightId,
            },
            description: description,
            price: targetCost,
            currency: {
              currencyId: selectedCurrency,
            },
            issueStartDate: !!publishDate
              ? new Date(publishDate + ' 00:00:00').getTime().toString()
              : null,
            publishToPublicFreightExchange: publicFreightExchange ? 'Y' : 'N',
            publishToPrivateFreightExchange: privateFreightExchange ? 'Y' : 'N',
          })
        }}
        showCancelButton
        handleCancel={() => {
          onClose()
        }}
        checkValidation={() => {}}
        submitBtnText={intl.formatMessage({id: 'Admin.Containers.publish'})}
        defaultStep={0}
        data={[
          {
            content: firstStepContent(),
          },
          {
            content: secondStepContent(),
          },
        ]}
      />
    </RightLayoutPopup>
  )
}

export default FreightPublishPopup
