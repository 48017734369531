import React, {useEffect} from 'react'
import {useIntl} from 'react-intl'
import clsx from 'clsx'

import RightLayoutPopup from '../../../../Components/RightLayoutPopup'

import useScrollLock from '../../../../../utils/useScrollLock'

const ShowFile = ({
  driverMetadata,
  setDriverFiles,
  driverFiles,
  setLoading,
  uploadDriverLicense,
  uploadSrcLicense,
  selectedDriver,
  onClose,
}: any) => {
  const intl = useIntl()
  const {scrollLock} = useScrollLock()
  useEffect(() => {
    scrollLock()
  }, [])

  return (
    <RightLayoutPopup
      title={intl.formatMessage({id: 'Carrier.drivers.file.uploadDocument'})}
      className='show-document-right-side-wrap'
      isCloseIcon
      closeOnClick={() => onClose()}
    >
      <div className={''}>
        <div className='fv-row mb-10'>
          <label className='form-label fs-6 fw-bolder text-dark'>
            {intl.formatMessage({id: 'Carrier.drivers.file.driverLicense'})}
          </label>
          <input
            disabled={
              driverMetadata?.find((item: any) => item?.fileType?.code === 'DRIVING_LICENCE')
                ?.fileStatus?.fileStatusId === 4
            }
            placeholder={intl.formatMessage({
              id: 'Carrier.drivers.file.driverLicense.placeHolder',
            })}
            className={clsx('form-control form-control-lg')}
            onChange={(event: any) =>
              setDriverFiles((item: any) => ({
                ...item,
                driverLicense: event.target.files[0],
              }))
            }
            type='file'
            name='Driver License'
          />
          <p className={'text-danger mt-3'}>
            {driverMetadata?.find((item: any) => item?.fileType?.code === 'DRIVING_LICENCE')
              ?.fileStatus?.fileStatusId === 4 &&
              intl.formatMessage({id: 'Carrier.drivers.file.alreadyApproved'})}
          </p>
          <p className={'text-danger mt-3'}>
            {driverMetadata?.find((item: any) => item?.fileType?.code === 'DRIVING_LICENCE')
              ?.fileStatus?.fileStatusId === 1 &&
              intl.formatMessage({id: 'Carrier.drivers.file.approved'})}
          </p>
        </div>

        <div className='fv-row mb-10'>
          <label className='form-label fs-6 fw-bolder text-dark'>
            {intl.formatMessage({id: 'Carrier.drivers.file.srcCertificate'})}
          </label>
          <input
            disabled={
              driverMetadata?.find((item: any) => item?.fileType?.code === 'SRC')?.fileStatus
                ?.fileStatusId === 4
            }
            placeholder={intl.formatMessage({
              id: 'Carrier.drivers.file.srcCertificate.placeHolder',
            })}
            className={clsx('form-control form-control-lg')}
            onChange={(event: any) =>
              setDriverFiles((item: any) => ({
                ...item,
                srcLicense: event.target.files[0],
              }))
            }
            type='file'
          />
          <p className={'text-danger mb-3'}>
            {driverMetadata?.find((item: any) => item?.fileType?.code === 'SRC')?.fileStatus
              ?.fileStatusId === 4 &&
              intl.formatMessage({id: 'Carrier.drivers.file.alreadyApproved'})}
          </p>
          <p className={'text-danger mb-3'}>
            {driverMetadata?.find((item: any) => item?.fileType?.code === 'SRC')?.fileStatus
              ?.fileStatusId === 1 && intl.formatMessage({id: 'Carrier.drivers.file.approved'})}
          </p>
        </div>

        <div className='text-center mt-5 row freightPost-popup-container'>
          <div className={'col-md-6'}>
            <button
              onClick={onClose}
              type='button'
              id='kt_sign_up_submit'
              className='btn btn-lg btn-danger w-100 mb-5 '
            >
              {intl.formatMessage({id: 'CreateNewContainerPopup.cancel'})}
            </button>
          </div>
          <div className={'col-md-6'}>
            <button
              className={'btn btn-info w-100 px-5'}
              onClick={() => {
                driverFiles.driverLicense && setLoading(true)
                driverFiles.driverLicense &&
                  uploadDriverLicense(selectedDriver.id, driverFiles.driverLicense)
                driverFiles.srcLicense && setLoading(true)
                driverFiles.srcLicense &&
                  setTimeout(() => uploadSrcLicense(selectedDriver.id, driverFiles.srcLicense), 150)
              }}
            >
              {intl.formatMessage({id: 'Carrier.drivers.file.uploadDoc'})}
            </button>
          </div>
        </div>
      </div>
    </RightLayoutPopup>
  )
}

export default ShowFile
