/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import {Link} from 'react-router-dom'

import Loading from '../../../Components/Loading'
import Map from '../../../Components/GoogleMap/Map'
import {loadMapApi} from '../../../Components/GoogleMap/utils/GoogleMapsUtils'
import {FilterComponent} from './FilterComponent'
import Seo from '../../../../utils/seo'

import {TransporterService} from '../../../../services/CarrierServices/TransporterService'

import getDateTime, {getMapVehicleIcon} from '../../../../utils/common'

const publicAvailableStructureData: any = {
  '@context': 'http://schema.org',
  '@type': 'WebPage',
  name: 'Vehicles Looking For Freight',
  url: 'https://www.rivalog.com/public/available-vehicles',
  publisher: {
    '@type': 'Organization',
    name: 'Rivalog',
    logo: 'https://www.rivalog.com/media/logo.png',
  },
}

const publicAvailableBreadcrumbStructureData: any = {
  '@context': 'https://schema.org/',
  '@type': 'BreadcrumbList',
  itemListElement: [
    {
      '@type': 'ListItem',
      position: 1,
      name: 'Home',
      item: 'https://www.rivalog.com/',
    },
    {
      '@type': 'ListItem',
      position: 2,
      name: 'Vehicle Buying & Selling',
      item: 'https://www.rivalog.com/public/vehicle-buying-selling',
    },
  ],
}

const PublicAvailableVehicles = () => {
  const intl = useIntl()
  const transporterServ = new TransporterService()
  const [showFilterPanel, setShowFilterPanel] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(false)
  const [scriptLoaded, setScriptLoaded] = useState<boolean>(false)
  const [distanceInKm, setDistanceInKm] = useState<number>(-1)
  const [transporterList, setTransporterList] = useState<any>([])
  const [coordinate, setCoordinate] = useState<any>({
    latitude: '',
    longitude: '',
  })
  const [isCenterCoordinate, setIsCenterCoordinate] = useState<boolean>(true)

  const [selectedTransporterId, setSelectedTransporterId] = useState<any>(null)

  const renderDistanceSentence = () => (
    <div className='distance-info'>
      {intl.formatMessage({id: 'Admin.trackOnMap.renderDistance'}, {value: distanceInKm})}
    </div>
  )

  const getTransporters = (data: any) => {
    transporterServ
      .getPublicCurrentLocationsForTruckExchange(data)
      .then((resp: any) => {
        setLoading(false)
        let uniqueData = transporterList
        if (resp?.data?.length > 0) {
          uniqueData = uniqueData
            .concat(resp.data)
            .filter(
              (value: any, index: any, self: any) =>
                index === self.findIndex((t: any) => t?.driver?.userId === value.driver?.userId)
            )
        }
        setTransporterList(uniqueData)
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const getActualTransporters = (data: any) => {
    transporterServ
      .getPublicCurrentLocationsForTruckExchange(data)
      .then((resp: any) => {
        setLoading(false)
        setTransporterList(resp?.data)
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const getMap = () => {
    scriptLoaded && window.location.reload()
    const googleMapScript = loadMapApi()
    googleMapScript.addEventListener('load', () => setScriptLoaded(true))
  }

  useEffect(() => {
    setLoading(true)
    getTransporters({})
    getMap()
  }, [])

  const handleFilterData = (data: any) => {
    if (!isCenterCoordinate) {
      setIsCenterCoordinate(true)
    }
    getTransporters(data)
  }

  const handleActualFilterData = (data: any) => {
    if (data?.firstCalled) {
      getActualTransporters(data)
      setIsCenterCoordinate(false)
    }
  }

  const getVehicleDisplayIcon = (status: any, id: any) => {
    if (status === 'LOOKING_FOR_FREIGHT') {
      return getMapVehicleIcon[id]
    } else if (status === 'NOT_WORKING') {
      return getMapVehicleIcon[id]
    } else {
      return getMapVehicleIcon[id]
    }
  }

  return (
    <div className='public-vehicle-main-wrap'>
      <Seo
        data={{
          title: intl.formatMessage({id: 'Seo.publicAvailableVehicle.title'}),
          metaDescription: intl.formatMessage({id: 'Seo.publicAvailableVehicle.description'}),
          href: 'https://www.rivalog.com/public/available-vehicles',
        }}
      >
        <script type='application/ld+json'>{JSON.stringify(publicAvailableStructureData)}</script>
        <script type='application/ld+json'>
          {JSON.stringify(publicAvailableBreadcrumbStructureData)}
        </script>
      </Seo>
      <h1 style={{opacity: 0, position: 'absolute'}}>
        {intl.formatMessage({id: 'Seo.publicAvailableVehicle.h1.tag'})}
      </h1>
      {loading && <Loading />}
      <div className='row col-md-2 justify-content-end w-100'>
        <Link
          to='/'
          className='link-primary fs-6 fw-bolder'
          style={{
            display: 'flex',
            justifyContent: 'end',
          }}
        >
          {intl.formatMessage({id: 'freight.public.home'})}
        </Link>
      </div>

      <div style={{display: 'flex', justifyContent: 'center'}} className='mb-5'>
        <h1>{intl.formatMessage({id: 'freight.truckExchange.title'})}</h1>
      </div>
      {/**Map**/}
      <div className='mb-5'>
        <div
          className='row flex-nowrap overflow-auto mt-3'
          style={{
            overflowX: 'auto',
            scrollbarColor: '#d2d6eb transparent',
          }}
        >
          {!transporterList.length && (
            <div className='alert alert-warning mb-5 fw-bolder' role='alert'>
              {intl.formatMessage({id: 'global.noData'})}
            </div>
          )}
          {transporterList?.length > 0 &&
            transporterList.map((item: any) => (
              <div
                className='col-4 mt-2 cursor-pointer'
                style={{
                  borderRadius: '10px',
                  background: 'white',
                  padding: '10px',
                  marginRight: '10px',
                  marginLeft: '5px',
                  maxWidth: '420px',
                }}
                onClick={() => setSelectedTransporterId(item?.driver?.userId)}
              >
                <div className='row'>
                  <div className='col-md-3'>
                    <div>
                      <img
                        src={getVehicleDisplayIcon(
                          item?.transporterStatus?.code,
                          item?.vehicle?.vehicleType?.vehicleTypeId
                        )}
                      />
                    </div>
                    <div>
                      <b className={'fs-7'}>{item?.vehicle?.plate}</b>
                    </div>
                  </div>
                  <div className='col-md-2'>
                    <div>
                      {item?.driver && (
                        <div>
                          <h6 className={'text-muted mb-0'}>
                            {intl.formatMessage({id: 'Carrier.transporters.create.driverType'})}
                          </h6>
                          <b className={'fs-7'}>
                            {item?.driver?.name} {item?.driver?.surname}
                          </b>
                        </div>
                      )}

                      <div className='mt-2'>
                        <h6 className={'text-muted mb-0'}>
                          {intl.formatMessage({id: 'Admin.Adverts.table_company'})}
                        </h6>
                        <b className={'fs-7'}>{item?.transporterCompany?.name}</b>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <div>
                      {item?.trailer?.trailerType?.description && (
                        <div>
                          <h6 className={'text-muted mb-0'}>
                            {intl.formatMessage({id: 'freight.map.type'})}
                          </h6>
                          <b className={'fs-7'}>{item?.trailer?.trailerType?.description}</b>
                        </div>
                      )}

                      {item?.transporterCompany?.countryCode && item?.transporterCompany?.phone && (
                        <div className='mt-2'>
                          <h6 className={'text-muted mb-0'}>
                            {intl.formatMessage({id: 'REGISTER.FORM.PHONE'})}
                          </h6>
                          <b className={'fs-7'}>
                            {item?.transporterCompany?.countryCode &&
                            item?.transporterCompany?.phone
                              ? item?.transporterCompany?.countryCode +
                                item?.transporterCompany?.phone
                              : ''}
                          </b>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div>
                      {item?.trailer?.specificationList && (
                        <div>
                          <h6 className={'text-muted mb-0'}>
                            {intl.formatMessage({id: 'Admin.Adverts.specificationList'})}
                          </h6>
                          {item?.trailer?.specificationList &&
                            item?.trailer?.specificationList?.map((dataItem: any, key: number) => {
                              return (
                                <p
                                  key={key}
                                  className={'fs-7 text-bold'}
                                  style={{marginBottom: '2px'}}
                                >
                                  {dataItem?.description}
                                </p>
                              )
                            })}
                        </div>
                      )}

                      <div className='mt-2'>
                        <h6 className={'text-muted mb-0'}>
                          {intl.formatMessage({id: 'freight.myListing.email'})}
                        </h6>
                        <b className={'fs-7'} style={{wordBreak: 'break-word'}}>
                          {item?.transporterCompany?.email}
                        </b>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>

      <div className='row'>
        <div className={` map-wrapper-content ${showFilterPanel ? 'col-md-9' : 'col-md-11'}`}>
          <Map
            isUnClickable
            fullscreenControl
            mapType={google.maps.MapTypeId.ROADMAP}
            mapTypeControl={true}
            setDistanceInKm={setDistanceInKm}
            location={(item: any) => {}}
            isCenterCoordinate={isCenterCoordinate}
            customLocation={{
              lat: coordinate?.latitude,
              lot: coordinate?.longitude,
            }}
            externalSelectedData={selectedTransporterId}
            setCenterCoordinate={(item: any) => {
              setIsCenterCoordinate(true)
              setCoordinate({
                latitude: item.latitude,
                longitude: item.longitude,
              })
            }}
            customMarkerList={
              transporterList &&
              transporterList?.map(
                ({
                  latitude,
                  longitude,
                  lastSubmissionDate,
                  driver,
                  vehicle,
                  transporterStatus,
                  container,
                  transporterCompany,
                  trailer,
                }: any) => {
                  return {
                    ...(latitude &&
                      longitude && {position: new google.maps.LatLng(latitude, longitude)}),
                    type: 'truck',
                    data: {
                      // title: transporterStatus?.description,
                      status: transporterStatus?.code,
                      date: lastSubmissionDate && getDateTime(lastSubmissionDate, intl.locale),
                      driver: {
                        id: driver?.userId,
                        name: driver?.name,
                        companyName: transporterCompany?.name,
                        email: transporterCompany?.email,
                        phone: transporterCompany?.countryCode + transporterCompany?.phone,
                        surname: driver?.surname,
                        mobile: driver?.countryCode + driver?.mobile,
                      },
                      vehicle: {
                        plate: vehicle?.plate,
                        make: vehicle?.make,
                        vehicleTypeId: vehicle?.vehicleType?.vehicleTypeId,
                      },
                      trailer: {
                        plate: trailer?.plate,
                        type: trailer?.trailerType?.description,
                        specifications: trailer?.specificationList || [],
                      },
                    },
                  }
                }
              )
            }
          />
          {distanceInKm > -1 && renderDistanceSentence()}
        </div>
        <div className={`${showFilterPanel ? 'col-md-3' : 'col-md-1'}`}>
          <FilterComponent
            handleActualFilterData={handleActualFilterData}
            handleFilterData={handleFilterData}
            coordinate={coordinate}
            showFilterPanel={showFilterPanel}
            setShowFilterPanel={setShowFilterPanel}
          />
        </div>
      </div>
    </div>
  )
}

export default PublicAvailableVehicles
