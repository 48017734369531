import {apiUrl, globalPageCount} from "../conf";
import axios from "axios";

export class AddressService {

    getAdress(adressId: number) {
        const config: any = {
            method: 'get',
            url: apiUrl + '/address-management/addresses/' + adressId,
            headers: {
                'Content-Type': 'application/json',
            },
        };

        return axios(config);
    }

    getCarrierAdress(adressId: number) {
        const config: any = {
            method: 'get',
            url: apiUrl + '/address-management/addresses/' + adressId,
            headers: {
                'Content-Type': 'application/json',
            },
        };

        return axios(config);
    }

    /*getAdressTypes() {
        const config: any = {
            method: 'get',
            url: apiUrl + '/address-management/addresses/types?pageIndex=0&pageCount=1000',
            headers: {
                'Content-Type': 'application/json',
            },
        };

        return axios(config);
    }*/

    getAdressList(pageIndex?: number, searchText?: string) {
        const config: any = {
            method: 'get',
            url: apiUrl + `/address-management/companies/addresses?searchText=${searchText ?? ''}&pageIndex=${pageIndex ?? 0}&pageCount=${globalPageCount}`,
            headers: {
                'Content-Type': 'application/json',
            },
        };

        return axios(config);
    }

    getClientsList(pageIndex?: number, searchText?: string) {
        const config: any = {
            method: 'get',
            url: apiUrl + `/client-management/clients?searchText=${searchText ?? ''}&pageIndex=${pageIndex ?? 0}&pageCount=${globalPageCount}`,
            headers: {
                'Content-Type': 'application/json',
            },
        };

        return axios(config);
    }

    getCarrierAdressList(pageIndex?: number, searchText?: string) {
        const config: any = {
            method: 'get',
            url: apiUrl + `/address-management/companies/addresses?searchText=${searchText ?? ''}&pageIndex=${pageIndex ?? 0}&pageCount=${globalPageCount}`,
            headers: {
                'Content-Type': 'application/json',
            },
        };

        return axios(config);
    }


    deleteAdress(adressId: number) {
        const config: any = {
            method: 'DELETE',
            url: apiUrl + '/address-management/addresses/' + adressId,
        };

        return axios(config);
    }

    updateAddress(dat?: { addressId: number, description?: string, countryCode?: string, cityId?: number, district?: string, streetNumber?: string, street?: string, neighborhood?: string, postCode?: string, addressText?: string, latitude: string, longitude: string }) {

        const data: any = JSON.stringify({
            addressId: dat?.addressId,
            description: dat?.description,
            country: dat?.countryCode && {
                code: dat?.countryCode
            },
            city: dat?.cityId && {
                cityId: dat?.cityId
            },
            streetNumber: dat?.streetNumber,
            street: dat?.street,
            neighborhood: dat?.neighborhood,
            district: dat?.district || '',
            postCode: dat?.postCode,
            addressText: dat?.addressText,
            latitude: dat?.latitude,
            longitude: dat?.longitude
        });

        const config: any = {
            method: 'PUT',
            url: apiUrl + '/address-management/addresses',
            headers: {
                'Content-Type': 'application/json',
            },
            data: data
        };

        return axios(config);
    }


    createAddress(desc: string, countryCode: string, cityId: number, district: string,
        neighborhood: string, street: string, streetNumber: string, postCode: string,
        addressText: string, latitude: string, longitude: string) {

        const data: any = JSON.stringify({
            description: desc,
            country: {
                code: countryCode
            },
            city: {
                cityId: cityId
            },
            district: district || '',
            neighborhood: neighborhood,
            postCode: postCode,
            street: street,
            streetNumber: streetNumber,
            addressText: addressText,
            latitude: latitude ? Number(latitude) : null,
            longitude: longitude ? Number(longitude): null
        });

        const config: any = {
            method: 'POST',
            url: apiUrl + '/address-management/addresses',
            headers: {
                'Content-Type': 'application/json',
            },
            data: data
        };

        return axios(config);
    }

    createCarrierAddress(desc: string, countryCode: string, cityId: number, district: string, town: string, postCode: string, addressText: string) {

        const data: any = JSON.stringify({
            description: desc,
            country: {
                code: countryCode
            },
            city: {
                cityId: cityId
            },
            district: district || '',
            town: town,
            postCode: postCode,
            addressText: addressText,
        });

        const config: any = {
            method: 'POST',
            url: apiUrl + '/address-management/addresses',
            headers: {
                'Content-Type': 'application/json',
            },
            data: data
        };

        return axios(config);
    }


}
