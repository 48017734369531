import React from 'react'
import {useIntl} from 'react-intl'

import RelatedBlogs from '../RelatedBlogs'
// import Seo from '../../utils/seo'
import {toAbsoluteUrl} from '../../_metronic/helpers'

import './FreightExchangeImproveServices.scss'

// const siteNavigationStructuredData: any = {
//   '@context': 'http://schema.org',
//   '@type': 'BlogPosting',
//   name: 'blog post',
//   url: 'https://www.rivalog.com/blog/how-freight-exhcnages-improve-road-transportation-services',
//   publisher: {
//     '@type': 'Organization',
//     name: 'Rivalog',
//     logo: 'https://www.rivalog.com/media/logo.png',
//   },
// }

const FullTruckLoadVsLocalTruckLoad = () => {
  const intl = useIntl()
  const date = new Date()
  return (
    <div style={{overflow: 'auto'}}>
      <div style={{position: 'relative'}}>
        <div
          className='bg-photo-overlay'
          style={{backgroundImage: 'url("/media/ftl_ltl.jpg")'}}
        ></div>
        {/* <Seo
          data={{
            title: intl.formatMessage({id: 'Seo.freightExchngTrucking.title'}),
            metaDescription: intl.formatMessage({id: 'Seo.freightExchngTrucking.description'}),
            href: 'https://www.rivalog.com/blog/freight-exchange-trucking-streamlining-road-transportation',
          }}
        >
          <script type='application/ld+json'>{JSON.stringify(siteNavigationStructuredData)}</script>
        </Seo> */}
        <div className='page-body'>
          <div className='centered-wrap-main'>
            <header className='centered-top-container animating' role='banner'>
              <div className='centered-top'>
                <div className='blog-name container'>
                  <div className='container section' id='header'>
                    <div className='widget Header' data-version='2' id='Header1'>
                      <div className='header-widget'>
                        <div>
                          <h1>
                            <a href='https://rivalog.blogspot.com/'>Rivalog</a>
                          </h1>
                        </div>
                        <p></p>
                      </div>
                    </div>
                  </div>
                  <nav role='navigation'>
                    <div className='clearboth no-items section' id='page_list_top'></div>
                  </nav>
                </div>
              </div>
            </header>

            <div>
              <main className='centered-bottom' role='main'>
                <div className='main section'>
                  <div className='widget'>
                    <article className='post-outer-container'>
                      <div className='post-outer'>
                        <div className='post'>
                          <h3 className='post-title entry-title'>
                            {intl.formatMessage({id: 'blog.fullVsLocalTruckLoad.title'})}
                          </h3>
                          <div className='post-header'>
                            <span className='byline'>{date.toDateString()}</span>
                          </div>
                          <div className='post-body'>
                            <h2 className='img-road-wrap'>
                              <div className='separator-wrap'>
                                <img
                                  className='improved-img-wrap'
                                  src={toAbsoluteUrl('/media/ftl_ltl.jpg')}
                                  alt='ftl-ltl'
                                />
                              </div>
                              <br />
                            </h2>

                            <p className='point-content-wrap'>
                              {intl.formatMessage({id: 'blog.fullVsLocalTruckLoad.label'})}{' '}
                              <a href='https://www.rivalog.com/'>
                                <b>
                                  {intl.formatMessage({id: 'blog.fullVsLocalTruckLoad.label1'})}
                                </b>
                              </a>{' '}
                              {intl.formatMessage({id: 'blog.fullVsLocalTruckLoad.label2'})}
                            </p>

                            <h2 className='img-road-wrap'>
                              {intl.formatMessage({id: 'blog.fullVsLocalTruckLoad.label3'})}
                            </h2>

                            <p className='point-content-wrap-second'>
                              {intl.formatMessage({id: 'blog.fullVsLocalTruckLoad.label4'})}
                            </p>

                            <h3 className='point-content-wrap point-content-wrap-color'>
                              {intl.formatMessage({id: 'blog.fullVsLocalTruckLoad.label5'})}
                            </h3>

                            <p className='point-content-wrap-second'>
                              {intl.formatMessage({id: 'blog.fullVsLocalTruckLoad.label6'})}
                            </p>

                            <h3 className='point-content-wrap point-content-wrap-color'>
                              {intl.formatMessage({id: 'blog.fullVsLocalTruckLoad.label7'})}
                            </h3>

                            <p className='point-content-wrap-second'>
                              {intl.formatMessage({id: 'blog.fullVsLocalTruckLoad.label8'})}
                            </p>

                            <h2 className='img-road-wrap'>
                              {intl.formatMessage({id: 'blog.fullVsLocalTruckLoad.label9'})}
                            </h2>

                            <p className='point-content-wrap-second'>
                              {intl.formatMessage({id: 'blog.fullVsLocalTruckLoad.label10'})}
                            </p>

                            <h3 className='point-content-wrap point-content-wrap-color'>
                              {intl.formatMessage({id: 'blog.fullVsLocalTruckLoad.label11'})}
                            </h3>

                            <p className='point-content-wrap-second'>
                              {intl.formatMessage({id: 'blog.fullVsLocalTruckLoad.label12'})}
                            </p>

                            <h3 className='point-content-wrap point-content-wrap-color'>
                              {intl.formatMessage({id: 'blog.fullVsLocalTruckLoad.label13'})}
                            </h3>

                            <p className='point-content-wrap-second'>
                              {intl.formatMessage({id: 'blog.fullVsLocalTruckLoad.label14'})}
                            </p>

                            <h3 className='point-content-wrap point-content-wrap-color'>
                              {intl.formatMessage({id: 'blog.fullVsLocalTruckLoad.label15'})}
                            </h3>

                            <p className='point-content-wrap-second'>
                              {intl.formatMessage({id: 'blog.fullVsLocalTruckLoad.label16'})}
                            </p>

                            <h3 className='point-content-wrap point-content-wrap-color'>
                              {intl.formatMessage({id: 'blog.fullVsLocalTruckLoad.label17'})}
                            </h3>

                            <p className='point-content-wrap-second'>
                              {intl.formatMessage({id: 'blog.fullVsLocalTruckLoad.label18'})}
                            </p>

                            <h2 className='img-road-wrap'>
                              {intl.formatMessage({id: 'blog.fullVsLocalTruckLoad.label19'})}
                            </h2>

                            <p className='point-content-wrap-second'>
                              {intl.formatMessage({id: 'blog.fullVsLocalTruckLoad.label20'})}
                            </p>

                            <h3 className='point-content-wrap point-content-wrap-color'>
                              {intl.formatMessage({id: 'blog.fullVsLocalTruckLoad.label21'})}
                            </h3>

                            <p className='point-content-wrap-second'>
                              {intl.formatMessage({id: 'blog.fullVsLocalTruckLoad.label22'})}
                            </p>

                            <h3 className='point-content-wrap point-content-wrap-color'>
                              {intl.formatMessage({id: 'blog.fullVsLocalTruckLoad.label23'})}
                            </h3>

                            <p className='point-content-wrap-second'>
                              {intl.formatMessage({id: 'blog.fullVsLocalTruckLoad.label24'})}
                            </p>

                            <h3 className='point-content-wrap point-content-wrap-color'>
                              {intl.formatMessage({id: 'blog.fullVsLocalTruckLoad.label25'})}
                            </h3>

                            <p className='point-content-wrap-second'>
                              {intl.formatMessage({id: 'blog.fullVsLocalTruckLoad.label26'})}
                            </p>

                            <h2 className='img-road-wrap'>
                              {intl.formatMessage({id: 'blog.fullVsLocalTruckLoad.label27'})}
                            </h2>

                            <p className='point-content-wrap-second'>
                              {intl.formatMessage({id: 'blog.fullVsLocalTruckLoad.label28'})}
                            </p>

                            <h3 className='point-content-wrap point-content-wrap-color'>
                              {intl.formatMessage({id: 'blog.fullVsLocalTruckLoad.label29'})}
                            </h3>

                            <p className='point-content-wrap-second'>
                              {intl.formatMessage({id: 'blog.fullVsLocalTruckLoad.label30'})}
                            </p>

                            <h3 className='point-content-wrap point-content-wrap-color'>
                              {intl.formatMessage({id: 'blog.fullVsLocalTruckLoad.label31'})}
                            </h3>

                            <p className='point-content-wrap-second'>
                              {intl.formatMessage({id: 'blog.fullVsLocalTruckLoad.label32'})}
                            </p>

                            <h3 className='point-content-wrap point-content-wrap-color'>
                              {intl.formatMessage({id: 'blog.fullVsLocalTruckLoad.label33'})}
                            </h3>

                            <p className='point-content-wrap-second'>
                              {intl.formatMessage({id: 'blog.fullVsLocalTruckLoad.label34'})}
                            </p>

                            <h3 className='point-content-wrap point-content-wrap-color'>
                              {intl.formatMessage({id: 'blog.fullVsLocalTruckLoad.label35'})}
                            </h3>

                            <p className='point-content-wrap-second'>
                              {intl.formatMessage({id: 'blog.fullVsLocalTruckLoad.label36'})}
                            </p>

                            <h3 className='point-content-wrap point-content-wrap-color'>
                              {intl.formatMessage({id: 'blog.fullVsLocalTruckLoad.label37'})}
                            </h3>

                            <p className='point-content-wrap-second'>
                              {intl.formatMessage({id: 'blog.fullVsLocalTruckLoad.label38'})}
                            </p>

                            <h2 className='img-road-wrap'>
                              {intl.formatMessage({id: 'blog.fullVsLocalTruckLoad.label39'})}
                            </h2>

                            <p className='point-content-wrap-second'>
                              {intl.formatMessage({id: 'blog.fullVsLocalTruckLoad.label40'})}{' '}
                              <b>{intl.formatMessage({id: 'blog.fullVsLocalTruckLoad.label41'})}</b>{' '}
                              {''} {intl.formatMessage({id: 'blog.fullVsLocalTruckLoad.label42'})}{' '}
                              <b>{intl.formatMessage({id: 'blog.fullVsLocalTruckLoad.label43'})}</b>{' '}
                              {intl.formatMessage({id: 'blog.fullVsLocalTruckLoad.label44'})}
                            </p>

                            <p className='point-content-wrap-second'>
                              {intl.formatMessage({id: 'blog.fullVsLocalTruckLoad.label45'})}
                            </p>

                            <p className='point-content-wrap-second'>
                              {intl.formatMessage({id: 'blog.fullVsLocalTruckLoad.label46'})}{' '}
                              <b>Rivalog</b>{' '}
                              {intl.formatMessage({id: 'blog.fullVsLocalTruckLoad.label47'})}{' '}
                              <b>
                                <a href='https://www.rivalog.com/'>Rivalog</a>
                              </b>{' '}
                              {intl.formatMessage({id: 'blog.fullVsLocalTruckLoad.label48'})}
                            </p>
                          </div>
                        </div>
                      </div>
                    </article>
                  </div>
                </div>
              </main>
            </div>
          </div>
        </div>
      </div>
      <RelatedBlogs url='/blog/full-truck-load-which-freight-option-is-right-for-your-business' />
    </div>
  )
}

export default FullTruckLoadVsLocalTruckLoad
