import React, {useEffect, useState} from 'react';
import styles from './MapAutoCompleteSelect.module.scss'
import {IMapAutoCompleteSelect} from "./types";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import {useIntl} from "react-intl";

const MapAutoCompleteSelect = ({selectedLoc}: IMapAutoCompleteSelect) => {
    const [loc, setLoc] = useState<any>(null);
    const intl = useIntl();
    useEffect(() => {
        loc && selectedLoc(loc);
    }, [loc])

    return <div className={styles.inp + ' '}>
        <GooglePlacesAutocomplete
            /* apiKey={'AIzaSyBwBeWkZN7cx3cEhJ4IGQxe-qDvm5xcA8A'}*/
            minLengthAutocomplete={3}
            selectProps={{
                loc,
                onChange: setLoc,
                placeholder: intl.formatMessage({id: "MapAutoComplete.search"}),
                inputClassName: styles.selectInp,
                styles: {
                    container: (provided: any) => ({
                        ...provided,
                    }),
                    control: (provided: any) => ({
                        ...provided,
                        border: 'none',
                        height: '44px'
                    }),
                },
            }}
            apiOptions={{language: 'tr', region: 'tr'}}
        />
    </div>;
};

export default MapAutoCompleteSelect;
