import React, {useEffect, useState} from 'react'
import './TabManagerListing.scss'
import {ITabManager} from './types'
import {useIntl} from 'react-intl'

const TabManagerListingComponent = ({
  data,
  onSubmit,
  submitBtnText,
  defaultStep = null,
  checkValidation,
  loading = false,
  showCancelButton = false,
  handleCancel
}: ITabManager) => {
  const intl = useIntl()
  const [step, setStep] = useState<number>(defaultStep || 0)
  const [nextBtnText, setNextBtnText] = useState<string>(intl.formatMessage({ id: 'freight.myListing.createNext'}))

  useEffect(() => {
    if (defaultStep === 0) {
      setStep(defaultStep)
    }
  }, [defaultStep])

  const nextHandle = () => {
    if (data) {
      if (step + 1 < data.length && !data[step].disabled) {
        setStep(step + 1)
      }
      // onSubmit() && onSubmit();
      if (step + 1 === data.length) {
        onSubmit()
      } else {
        // handleNextButtonCalled()
        checkValidation(step)
      }
      if (data.length - (step + 1) === 1 && !data[step].disabled) {
        setNextBtnText(
          submitBtnText ? submitBtnText : intl.formatMessage({id: 'TabManager.preview'})
        )
      }
    }
  }

  const prevHandle = () => {
    setStep(step - 1)
    setNextBtnText(intl.formatMessage({id: 'TabManager.next'}))
  }

  return (
    <div className={'tab-manager-main'}>
      <div className={'tab-manager-title-area'}>
        <p className='form-label fw-bolder text-dark fs-1 mb-10 text-center'>
          {data && data[step]?.title}
        </p>
      </div>

      {data && data[step].content}

      <div className={'tab-manager-btn-area mt-20'}>
        <div>
          <span className={'text-muted'}>
            {data
              ? data?.length - (step + 1) === 0
                ? intl.formatMessage({id: 'TabManager.last'})
                : data?.length - (step + 1)
              : 0}
            {data
              ? data?.length - (step + 1) === 0
                ? intl.formatMessage({id: 'TabManager.step'})
                : intl.formatMessage({id: 'TabManager.stepLeft'})
              : intl.formatMessage({id: 'TabManager.stepLeft'})}
          </span>
        </div>

        {step === 0 && showCancelButton && (
          <div className='text-center'>
            <button
              onClick={() => {
                handleCancel()
              }}
              type='submit'
              id='kt_sign_up_submit'
              className='btn btn-lg btn-danger w-100'
            >
              {intl.formatMessage({id: 'CreateNewContainerPopup.cancel'})}
            </button>
          </div>
        )}

        {step > 0 && (
          <div className='text-center'>
            <button
              onClick={() => {
                handleCancel()
              }}
              type='submit'
              id='kt_sign_up_submit'
              className='btn btn-lg btn-danger w-100'
            >
              {intl.formatMessage({id: 'CreateNewContainerPopup.cancel'})}
            </button>
          </div>
        )}

        <div className='text-center'>
          <button
            onClick={() => {
              nextHandle()
            }}
            type='submit'
            id='kt_sign_up_submit'
            className='btn btn-lg btn-primary w-100 next-btn-disabled'
            disabled={loading || (data && data[step].disabled)}
          >
            {!loading && ((data && data[step]?.nextBtnTitle) || nextBtnText)}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                {nextBtnText}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </div>
    </div>
  )
}

export default TabManagerListingComponent
