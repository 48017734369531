import React, {useEffect} from 'react';
import {ICheckGroup} from "./types";
import styles from './CheckGroup.module.scss'

const CheckGroup = ({data, placeholder, onChange, selectedItems, disabled, fullChecked}: ICheckGroup) => {

    const check = (value: any) => (selectedItems && selectedItems.length) && selectedItems.find((it: any) => it === value);


    return <div className={styles.groupMain}>
        <div className={styles.scrollArea}>
            {data?.map((item: any) =>
                <div key={item?.id} className={"form-check mb-3 " + styles.checkArea}>
                    <input className={"form-check-input " + styles.inp}
                           value={item.id}
                           onChange={onChange}
                           checked={check(item.value) || ((fullChecked && Array.isArray(fullChecked) && fullChecked.length) && fullChecked?.find((it: string | number) => it == item.id))}
                           type="checkbox"
                           id={item.id}
                           disabled={disabled}
                    />
                    <label className={"form-check-label " + styles.label} htmlFor={item.id}>
                        {item?.label}
                    </label>
                </div>)}
            {!data || data.length < 1 && placeholder}
        </div>
    </div>;
};

export default CheckGroup;
