/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import clsx from 'clsx'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {toast} from 'react-toastify'

import {PageTitle} from '../../../../_metronic/layout/core'

import Loading from '../../../Components/Loading'

import {CompanyService} from '../../../../services/FreightOperatorServices/CompanyService'
import {CommonService} from '../../../../services/FreightOperatorServices/CommonService'

const initialValues = {
  name: '',
  web: '',
  phone: '',
  taxNumber: '',
  taxOffice: '',
  billingAddress: '',
}

const registrationSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Firma adı gerekli'),
  web: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Web site gerekli'),
  phone: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Telefon gerekli'),
  taxNumber: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('VAT Numarası gerekli'),
  taxOffice: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Vergi Dairesi gerekli'),
  billingAddress: Yup.string().min(3, 'Minimum 3 symbols').nullable(),
  /*.required('Fatura Adresi gerekli')*/
})

const CompanyInformation = () => {
  const intl = useIntl()
  /*  const intl = useIntl()*/
  const companyServ = new CompanyService()
  const commonServ = new CommonService()

  const [loading, setLoading] = useState<boolean>(false)
  const [companyData, setCompanydata] = useState<any>([])

  const [selectedCountry, setSelectedCountry] = useState<string>('')
  const [changeCheck, setChangeCheck] = useState<boolean>(false)

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      changeCheck && setLoading(true)
      changeCheck &&
        updateCompany({
          web: formik.values.web,
          phone: formik.values.phone,
          taxNumber: formik.values.taxNumber,
          taxOffice: formik.values.taxOffice,
        })
      !changeCheck && toast.info(intl.formatMessage({id: 'Freight.companyInfo.notUpdateInfo'}))
    },
  })

  useEffect(() => {
    const isEqual = (a: any, b: any) => JSON.stringify(a) === JSON.stringify(b)
    let a = [
      companyData.web,
      companyData.taxNumber,
      companyData.taxOffice,
      companyData.name,
      companyData.phone,
    ]
    let b = [
      formik.values.web,
      formik.values.taxNumber,
      formik.values.taxOffice,
      formik.values.name,
      formik.values.phone,
    ]
    setChangeCheck(!isEqual(a, b))
  }, [
    formik.values.web,
    formik.values.taxNumber,
    formik.values.taxOffice,
    formik.values.name,
    formik.values.phone,
  ])

  /** Api Calls Start **/
  const getCompany = () => {
    companyServ
      .getCompany()
      .then((item: any) => {
        setLoading(false)
        setCompanydata(item.data)
        fillInputs(item.data)
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const updateCompany = (data: any) => {
    companyServ
      .updateCompany(data.web, data.phone, data.taxNumber, data.taxOffice)
      .then((item: any) => {
        getCompany()
        setLoading(false)
        toast.success(intl.formatMessage({id: 'User.toast.info.update.success'}), {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }
  /** Api Calls End **/

  const fillInputs = (data: any) => {
    formik.setFieldValue('name', data.name)
    formik.setFieldValue('web', data.web)
    formik.setFieldValue('phone', data.phone)
    formik.setFieldValue('language', data.language)
    formik.setFieldValue('country', data.country)
    formik.setFieldValue('taxNumber', data.taxNumber)
    formik.setFieldValue('taxOffice', data.taxOffice)
    formik.setFieldValue('billingAddress', data.billingAddress)
  }

  useEffect(() => {
    setLoading(true)
    getCompany()
  }, [])

  return (
    <>
      {loading && <Loading />}
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({id: 'Freight.companyInfo.title'})}
      </PageTitle>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_signup_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='d-flex align-items-center mb-10'>
          <div className='border-bottom border-gray-300 mw-50 w-100'></div>
          <span className='fw-bold text-gray-400 fs-7 mx-2'>{formik.values.name}</span>
          <div className='border-bottom border-gray-300 mw-50 w-100'></div>
        </div>

        {formik.status && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        )}

        {/* begin::Form group Name */}
        <div className='col-md-6 mb-5'>
          <label className='class="form-label fw-bolder text-dark fs-6'>
            {intl.formatMessage({id: 'Freight.companyInfo.companyName'})}
          </label>
          <input
            placeholder={intl.formatMessage({id: 'Freight.companyInfo.companyName'})}
            type='text'
            autoComplete='off'
            disabled
            {...formik.getFieldProps('name')}
            className={clsx('form-control form-control-lg ')}
          />
          {formik.touched.name && formik.errors.name && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.name}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}
        <div className={'row mb-5'}>
          {/* begin::Form group Vergi Numarası */}
          <div className='col-md-6 mb-5'>
            <label className='form-label fw-bolder text-dark fs-6'>
              {intl.formatMessage({id: 'Freight.companyInfo.taxNumber'})}
            </label>
            <input
              placeholder={intl.formatMessage({id: 'Freight.companyInfo.taxNumber'})}
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('taxNumber')}
              className={clsx(
                'form-control form-control-lg form-control-solid bg-white',
                {'is-invalid': formik.touched.taxNumber && formik.errors.taxNumber},
                {
                  'is-valid': formik.touched.taxNumber && !formik.errors.taxNumber,
                }
              )}
            />
            {formik.touched.taxNumber && formik.errors.taxNumber && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.taxNumber}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group Vergi Dairesi */}
          <div className='col-md-6 mb-5'>
            <label className='form-label fw-bolder text-dark fs-6'>
              {intl.formatMessage({id: 'Freight.companyInfo.taxOffice'})}
            </label>
            <input
              placeholder={intl.formatMessage({id: 'Freight.companyInfo.taxOffice'})}
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('taxOffice')}
              className={clsx(
                'form-control form-control-lg form-control-solid bg-white',
                {'is-invalid': formik.touched.taxOffice && formik.errors.taxOffice},
                {
                  'is-valid': formik.touched.taxOffice && !formik.errors.taxOffice,
                }
              )}
            />
            {formik.touched.taxOffice && formik.errors.taxOffice && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.taxOffice}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
        </div>
        <div className={'row mb-20'}>
          {/* begin::Form group Web */}
          <div className='col-md-6 '>
            <label className='form-label fw-bolder text-dark fs-6'>
              {intl.formatMessage({id: 'Freight.companyInfo.website'})}
            </label>
            <input
              placeholder={intl.formatMessage({id: 'Freight.companyInfo.website'})}
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('web')}
              className={clsx(
                'form-control form-control-lg form-control-solid bg-white',
                {
                  'is-invalid': formik.touched.web && formik.errors.web,
                },
                {
                  'is-valid': formik.touched.web && !formik.errors.web,
                }
              )}
            />
            {formik.touched.web && formik.errors.web && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.web}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group Telefon */}
          <div className='col-md-6 '>
            <label className='form-label fw-bolder text-dark fs-6'>
              {intl.formatMessage({id: 'Freight.companyInfo.phone'})}
            </label>
            <input
              placeholder={intl.formatMessage({id: 'Freight.companyInfo.phone'})}
              type='tel'
              autoComplete='off'
              {...formik.getFieldProps('phone')}
              className={clsx(
                'form-control form-control-lg form-control-solid bg-white',
                {
                  'is-invalid': formik.touched.phone && formik.errors.phone,
                },
                {
                  'is-valid': formik.touched.phone && !formik.errors.phone,
                }
              )}
            />
            {formik.touched.phone && formik.errors.phone && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.phone}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
        </div>
        {/* begin::Form group */}
        <div className='text-center'>
          <button
            type='submit'
            id='kt_sign_up_submit'
            className='btn btn-lg btn-primary w-100 mb-5'
            /*disabled={formik.isSubmitting || !formik.isValid}*/
          >
            {!loading && (
              <span className='indicator-label'>
                {intl.formatMessage({id: 'Freight.companyInfo.updateInfo'})}
              </span>
            )}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                {intl.formatMessage({id: 'Freight.companyInfo.wait'})}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Form group */}
      </form>
      <hr />
    </>
  )
}

export {CompanyInformation}
