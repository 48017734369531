import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import _ from 'lodash'
import {toast} from 'react-toastify'

import {PageTitle} from '../../../../_metronic/layout/core'
import {KTSVG} from '../../../../_metronic/helpers'

import Loading from '../../../Components/Loading'
import DataTableAdvanced from '../../../Components/DataTableAdvanced'
import Modal from '../../../Components/Modal'

import {TransporterGroupService} from '../../../../services/CarrierServices/TransporterGroupService'

import {CreateTransporterGroup} from './Popups/CreateTransporterGroup'
import {EditTransporterGroupPopup} from './Popups/EditTransporterGroup'

import './TransporterGroup.scss'
import {TransporterGroupManagerList} from './Popups/TransporterGroupManagerList'
import {TransporterGroupMambersList} from './Popups/TransporterGroupMambersList'

const TransporterGroup = () => {
  const intl = useIntl()
  const [loading, setLoading] = useState<boolean>(false)
  const [showFilterPanel, setShowFilterPanel] = useState<boolean>(false)
  const [filter, setFilter] = useState<string>('')
  const [pageMIndex, setPageMIndex] = useState<number>(0)

  const [showCreatePopup, setShowCreatePopup] = useState<boolean>(false)
  const [showEditTransporterGroupPopup, setShowEditTransporterGroupPopup] = useState<boolean>(false)
  const [showManagersPopup, setShowManagersPopup] = useState<boolean>(false)
  const [showMambersPopup, setShowMambersPopup] = useState<boolean>(false)

  /*Service States*/
  const transporterGroupServ = new TransporterGroupService()

  /*List States*/
  const [transporterGroupList, setTransporterGroupList] = useState<any>([])
  const [selectedTransporterGroup, setSlectedTransporterGroup] = useState<any>({})
  const [transporterGroupId, setTransporterGroupId] = useState<any>(null)
  const [seletedTransporterGroupManagers, setSelectedTransporterGroupManagers] = useState<any>([])
  const [seletedTransporterGroupMambers, setSelectedTransporterGroupMambers] = useState<any>([])
  const [isNextPage, setIsNextPage] = useState<boolean>(false)
  const [isTransportGroupManagerNextPage, setIsTransportGroupManagerNextPage] =
    useState<boolean>(false)
  const [isTransportGroupMamberNextPage, setIsTransportGroupMamberNextPage] =
    useState<boolean>(false)

  /*Screens and modals*/
  const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false)

  const onPaginationChange = (page: number) => {
    if (!isNextPage) {
      getTranporterGroupList({
        pageIndex: page,
        searchText: filter,
      })
      setPageMIndex(page)
    }
    setIsNextPage(true)
  }

  const getTranporterGroupList = (data: any) => {
    transporterGroupServ
      .getTransporterGroups(data)
      .then((res: any) => {
        setTransporterGroupList(res.data)
        setIsNextPage(false)
        setLoading(false)
      })
      .catch((e) => {
        setLoading(false)
        setIsNextPage(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const handleCreateTransporterGroup = (description: any, explanation: any) => {
    transporterGroupServ
      .createTransporterGroup(description, explanation)
      .then(() => {
        setLoading(false)
        toast.success(intl.formatMessage({id: 'Carrier.transporterGroup.create.success'}))
        getTranporterGroupList({})
      })
      .catch((e) => {
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const handleUpdateTransporterGroup = (description: any, explanation: any) => {
    transporterGroupServ
      .updateTransporterGroup(transporterGroupId, description, explanation)
      .then(() => {
        setLoading(false)
        toast.success(intl.formatMessage({id: 'Carrier.transporterGroup.update.success'}))
        getTranporterGroupList({pageIndex: pageMIndex, searchText: filter})
      })
      .catch((e) => {
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const handleDeleteTransporterGroup = () => {
    transporterGroupServ
      .deleteTransporterGroup(transporterGroupId)
      .then(() => {
        setLoading(false)
        toast.success(intl.formatMessage({id: 'Carrier.transporterGroup.delete.success'}))
        getTranporterGroupList({pageIndex: pageMIndex, searchText: filter})
      })
      .catch((e) => {
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const handleGetSelectedTransporterGroup = (transporterGroupId: any) => {
    transporterGroupServ
      .getSelectedTransporterGroups(transporterGroupId)
      .then((res: any) => {
        setLoading(false)
        setSlectedTransporterGroup(res.data)
      })
      .catch((e) => {
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const handleGetTransporterMangers = (data: any) => {
    transporterGroupServ
      .getSelectedTransporterGroupManagers(data)
      .then((res: any) => {
        setLoading(false)
        setIsTransportGroupManagerNextPage(false)
        setSelectedTransporterGroupManagers(res.data)
      })
      .catch((e) => {
        setLoading(false)
        setIsTransportGroupManagerNextPage(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const handleGetTransporterMambers = (data: any) => {
    transporterGroupServ
      .getSelectedTransporterGroupMambers(data)
      .then((res: any) => {
        setLoading(false)
        setIsTransportGroupMamberNextPage(false)
        setSelectedTransporterGroupMambers(res.data)
      })
      .catch((e) => {
        setLoading(false)
        setIsTransportGroupMamberNextPage(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  useEffect(() => {
    setLoading(true)
    getTranporterGroupList({pageIndex: pageMIndex})
  }, [])

  const handleFilterSearch = React.useMemo(
    () =>
      _.debounce((_event, searchText) => {
        getTranporterGroupList({pageIndex: 0, searchText})
      }, 1000),
    []
  )

  return (
    <>
      {loading && <Loading />}
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({id: 'Carrier.transporterGroup.title'})}
      </PageTitle>

      <div className={'d-flex align-items-center justify-content-end'}>
        <button
          onClick={() => {
            setShowCreatePopup(true)
          }}
          className='btn btn-sm btn-light-primary mb-5 border border-2 fs-4'
        >
          {intl.formatMessage({id: 'Carrier.transporterGroup.create'})}
        </button>
      </div>

      <div
        className={`row ${
          showFilterPanel ? 'justify-content-between' : 'justify-content-end mb-5'
        } `}
      >
        {showFilterPanel && (
          <div className='mb-7 col-md-3 justify-content-left'>
            <h6 className={'mb-3'}>{intl.formatMessage({id: 'Carrier.dashboard.filter'})}</h6>
            <div className={''}>
              <input
                placeholder={intl.formatMessage({id: 'Carrier.dashboard.filter.placeHolder'})}
                className={'form-control form-control-lg'}
                onChange={(event: any) => {
                  handleFilterSearch(event, event.target.value)
                  setFilter(event.target.value)
                }}
                value={filter}
                type='text'
              />
            </div>
          </div>
        )}

        <div className='col-md-2 d-flex align-items-center justify-content-end'>
          {/* <span className='menu-icon d-flex justify-content-end'> */}
          <div
            className={`btn btn-icon btn-active-light-primary btn-custom ${
              showFilterPanel ? 'menu-button-active' : ''
            }`}
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='bottom'
            onClick={() => setShowFilterPanel(!showFilterPanel)}
          >
            <KTSVG path='/media/filtering.svg' className='svg-icon-2x' />
          </div>
          {/* </span> */}
        </div>
      </div>

      <div>
        {!transporterGroupList.length && (
          <div className='alert alert-warning mb-5 fw-bolder' role='alert'>
            {intl.formatMessage({id: 'global.noData'})}
          </div>
        )}
        <DataTableAdvanced
          columns={[
            {
              Header: 'ID',
              accessor: 'id',
            },
            {
              Header: intl.formatMessage({id: 'Carrier.transporterGroup.table.name'}),
              accessor: 'description',
            },
            {
              Header: intl.formatMessage({id: 'Carrier.transporterGroup.table.explanation'}),
              accessor: 'explanation',
            },
            {
              Header: 'isContainer',
              accessor: 'isContainer',
              hidden: true,
            },
          ]}
          editableRows={['']}
          data={
            transporterGroupList &&
            transporterGroupList.map((item: any) => {
              return {
                id: item?.transporterGroupId,
                description: item?.description ?? '-',
                explanation: item?.explanation ?? '-',
                isContainer: true,
              }
            })
          }
          deleteRow={(row: any) => {
            setTransporterGroupId(row.values.id)
            setShowDeletePopup(true)
          }}
          deleteText={intl.formatMessage({id: 'addressListsWidget.delete'})}
          editBtn={(row: any) => {
            handleGetSelectedTransporterGroup(row?.values?.id)
            setTransporterGroupId(row?.values?.id)
            setLoading(true)
            setShowEditTransporterGroupPopup(true)
          }}
          proposalBtn={(row: any) => {
            setTransporterGroupId(row?.values?.id)
            setLoading(true)
            setShowManagersPopup(true)
            handleGetTransporterMangers({transporterGroupId: row?.values?.id})
          }}
          proposalBtnText={intl.formatMessage({id: 'Carrier.transporterGroup.action.manager'})}
          showFile={(row: any) => {
            setTransporterGroupId(row?.values?.id)
            setLoading(true)
            setShowMambersPopup(true)
            handleGetTransporterMambers({transporterGroupId: row?.values?.id})
          }}
          showFileText={intl.formatMessage({ id: 'Carrier.transporterGroup.action.member'})}
          setPageMIndex={onPaginationChange}
          pageMIndex={pageMIndex}
        />
      </div>

      {showCreatePopup && (
        <CreateTransporterGroup
          onClose={() => setShowCreatePopup(false)}
          setLoading={setLoading}
          createTransporterGroup={(description: any, explanation: any) =>
            handleCreateTransporterGroup(description, explanation)
          }
        />
      )}

      {showEditTransporterGroupPopup && (
        <EditTransporterGroupPopup
          selectedTransporterGroup={selectedTransporterGroup}
          setLoading={setLoading}
          onClose={() => setShowEditTransporterGroupPopup(false)}
          updateTransporterGroup={(description: any, explanation: any) =>
            handleUpdateTransporterGroup(description, explanation)
          }
        />
      )}

      {showManagersPopup && (
        <TransporterGroupManagerList
          onClose={() => setShowManagersPopup(false)}
          transporterGroupId={transporterGroupId}
          transporterGroupManagersList={seletedTransporterGroupManagers}
          handleGetTransporterMangers={handleGetTransporterMangers}
          isTransportGroupManagerNextPage={isTransportGroupManagerNextPage}
          setIsTransportGroupManagerNextPage={setIsTransportGroupManagerNextPage}
          setLoading={setLoading}
        />
      )}

      {showMambersPopup && (
        <TransporterGroupMambersList
          onClose={() => setShowMambersPopup(false)}
          transporterGroupMambersList={seletedTransporterGroupMambers}
          setLoading={setLoading}
          transporterGroupId={transporterGroupId}
          isTransportGroupMamberNextPage={isTransportGroupMamberNextPage}
          setIsTransportGroupMamberNextPage={setIsTransportGroupMamberNextPage}
          handleGetTransporterMambers={handleGetTransporterMambers}
        />
      )}

      {showDeletePopup && (
        <Modal
          closeBtnSettings={{
            show: true,
            onClick: () => setShowDeletePopup(false),
          }}
          customAll={
            <div className={''}>
              <h3 className={'mt-5 mb-15'}>
                {intl.formatMessage({id: 'ADMIN.TRANSPORTS.DELETE.ASK'})}
              </h3>
              <div className={'row'}>
                <div className={'col-md-6'}>
                  <button
                    className={'btn btn-danger w-100'}
                    onClick={() => {
                      setShowDeletePopup(false)
                    }}
                  >
                    {intl.formatMessage({id: 'global.cancel'})}
                  </button>
                </div>
                <div className={'col-md-6'}>
                  <button
                    className={'btn btn-success w-100'}
                    onClick={() => {
                      setLoading(true)
                      setShowDeletePopup(false)
                      handleDeleteTransporterGroup()
                    }}
                  >
                    {intl.formatMessage({id: 'global.approve'})}
                  </button>
                </div>
              </div>
            </div>
          }
        />
      )}
    </>
  )
}
export {TransporterGroup}
