import axios from 'axios'
import {apiUrl, globalPageCount} from '../conf'

export class CompanyService {
  getAllCompanies(
    pageIndex?: number | string,
    searchText?: string,
    noPreferredRoute?: string,
    noVehicle?: string,
    noTrailer?: string,
    noTransporter?: string
  ) {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        '/company-management/companies/features?companyName=' +
        (searchText ?? '') +
        '&noPreferredRoute=' +
        (noPreferredRoute ?? '') +
        '&noVehicle=' +
        (noVehicle ?? '') +
        '&noTrailer=' +
        (noTrailer ?? '') +
        '&noTransporter=' +
        (noTransporter ?? '') +
        '&pageIndex=' +
        (pageIndex ?? 0) +
        '&pageCount=' +
        globalPageCount,
      headers: {
        'Content-Type': 'application/json',
      },
    }
    return axios(config)
  }

  getCompanyDetails(id: number) {
    const config: any = {
      method: 'GET',
      url: apiUrl + '/company-management/companies/' + id,
      headers: {
        'Content-Type': 'application/json',
      },
    }
    return axios(config)
  }

  getCompanyResponsibles(id: number) {
    const config: any = {
      method: 'GET',
      url: apiUrl + '/company-management/companies/' + id + '/responsibles',
      headers: {
        'Content-Type': 'application/json',
      },
    }
    return axios(config)
  }

  getCompanyInvoiceAddress(id: number) {
    const config: any = {
      method: 'GET',
      url: apiUrl + '/company-management/companies/' + id + '/invoiceaddress',
      headers: {
        'Content-Type': 'application/json',
      },
    }
    return axios(config)
  }

  getCompanyStatutes() {
    const config: any = {
      method: 'GET',
      url: apiUrl + '/company-management/companies/statuses',
      headers: {
        'Content-Type': 'application/json',
      },
    }
    return axios(config)
  }

  getPreferredRouteList(companyId?: number, pageIndex?: number) {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        `/preferred-route-management/companies/${companyId}/routes?searchText=&pageIndex=${
          pageIndex ?? 0
        }&pageCount=20`,
      headers: {
        'Content-Type': 'application/json',
      },
    }
    return axios(config)
  }

  getCompanyUserList(companyId?: number, pageIndex?: number) {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        `/user-management/companies/${companyId}/users?searchText=&pageIndex=${
          pageIndex ?? 0
        }&pageCount=20`,
      headers: {
        'Content-Type': 'application/json',
      },
    }
    return axios(config)
  }

  transportTypesList(searchText?: string, pageIndex?: number) {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        `/transport-management/transports/types?searchText=${searchText ?? ''}&pageIndex=${
          pageIndex ?? 0
        }&pageCount=${pageIndex ?? 1000}`,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  getUserDetails(userId?: number) {
    const config: any = {
      method: 'GET',
      url: apiUrl + `/user-management/users/${userId}`,
      headers: {
        'Content-Type': 'application/json',
      },
    }
    return axios(config)
  }

  updateUserDetails(data?: any) {
    const config: any = {
      method: 'PUT',
      url: apiUrl + '/user-management/users',
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    }
    return axios(config)
  }

  updateCompanyStatus(id: number, statusId: number) {
    const config: any = {
      method: 'PATCH',
      url: apiUrl + '/company-management/companies/statuses',
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({
        companyId: id,
        companyStatusId: statusId,
      }),
    }
    return axios(config)
  }
}
