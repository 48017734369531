/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../../_metronic/layout/core'
import clsx from 'clsx'
import {useFormik} from 'formik'
import {PasswordMeterComponent} from '../../../../_metronic/assets/ts/components'
import * as Yup from 'yup'
import Loading from '../../../Components/Loading'
import {UserService} from '../../../../services/MainServices/UserService'
import {toast} from 'react-toastify'

const initialValues = {
  name: '',
  surname: '',
  username: '',
  email: '',
  mobile: '',
  language: '',
  countryCode: '',
  selectedFile: null,
}
const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png']

const UserProfile = () => {
  const intl = useIntl()
  const userServ = new UserService()

  const [loading, setLoading] = useState<boolean>(false)
  const [userData, setUserData] = useState<any>([])

  const [isSMS, setIsSMS] = useState<boolean>(false)
  const [isEmail, setIsEmail] = useState<boolean>(false)
  const [isNotification, setIsNotification] = useState<boolean>(false)

  const registrationSchema = Yup.object().shape({
    name: Yup.string()
      .max(50, intl.formatMessage({id: 'global.max50SymoblRequired'}))
      .required(intl.formatMessage({id: 'global.nameRequired'})),
    surname: Yup.string()
      .max(50, intl.formatMessage({id: 'global.max50SymoblRequired'}))
      .required(intl.formatMessage({id: 'global.lastNameRequired'})),
    username: Yup.string().max(50, intl.formatMessage({id: 'global.max50SymoblRequired'})) /*
            .required('Kullanıcı Adı gerekli'),*/,
    email: Yup.string()
      .email(intl.formatMessage({id: 'global.invalidEmailFormat'}))
      .max(50, intl.formatMessage({id: 'global.max50SymoblRequired'})),
    /*.required('E-posta gerekli'),*/
    mobile: Yup.string().max(50, intl.formatMessage({id: 'global.max50SymoblRequired'})) /*
            .required('Telefon gerekli'),*/,
    language: Yup.string().max(50, intl.formatMessage({id: 'global.max50SymoblRequired'})) /*
            .required('Dil gerekli'),*/,
    countryCode: Yup.string().max(50, intl.formatMessage({id: 'global.max50SymoblRequired'})) /*
            .required('Ülke Kodu gerekli'),*/,
    selectedFile: Yup.mixed()
      .nullable()
      .test(
        'size',
        'Fotoğraf boyutu çok büyük.',
        (value) => !value || (value && value.size <= 1024 * 1024)
      )
      .test(
        'format',
        'Geçersiz format.',
        (value) => !value || (value && SUPPORTED_FORMATS.includes(value.type))
      ),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      updateUser({
        name: formik.values.name,
        surname: formik.values.surname,
        username: formik.values.username,
        email: formik.values.email,
        mobile: formik.values.mobile,
        language: formik.values.language,
        countryCode: formik.values.countryCode,
      })
    },
  })

  /** Api Calls Start **/
  const getUserData = () => {
    userServ
      .getUserData()
      .then((item: any) => {
        setLoading(false)
        setUserData(item.data)
        fillInputs(item.data)
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const updateUser = (data: any) => {
    userServ
      .updateUser(data.name, data.surname)
      .then(() => {
        getUserData()
        toast.success(intl.formatMessage({id: 'User.toast.info.update.success'}), {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const uploadImage = (data: any) => {
    userServ
      .uploadProfileImage(data)
      .then(() => {
        getUserData()
        toast.success(intl.formatMessage({id: 'User.toast.photo.update.success'}), {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  /** Api Calls End **/

  const fillInputs = (data: any) => {
    formik.setValues(data)
  }

  const changeCheck = () =>
    formik.values.name !== userData.name || formik.values.surname !== userData.surname

  useEffect(() => {
    setLoading(true)
  }, [])

  useEffect(() => {
    setLoading(true)
    getUserData()
    getNotificationPermission()
    PasswordMeterComponent.bootstrap()
  }, [])

  const handleCopyText = () => {
    navigator.clipboard.writeText(
      `${
        window.location.hostname === 'localhost'
          ? window.location.origin
          : window.location.hostname.startsWith('www.')
          ? window.location.origin
          : 'www.' + window.location.hostname
      }/public-freight-create?companyCode=${userData?.company?.companyCode}`
    )
    toast.success(intl.formatMessage({id: 'UserProfile.company.freight.copy.success'}))
  }

  const handleGroupInvitationCopyText = () => {
    navigator.clipboard.writeText(
      `${
        window.location.hostname === 'localhost'
          ? window.location.origin
          : window.location.hostname.startsWith('www.')
          ? window.location.origin
          : 'www.' + window.location.hostname
      }/public/invitations/groups?company=${userData?.company?.companyCode}&refererUserCode=${
        userData?.userCode
      }`
    )
    toast.success(intl.formatMessage({id: 'UserProfile.company.freight.copy.success'}))
  }

  const getNotificationPermission = () => {
    userServ
      .getNotificationPermission()
      .then((resp: any) => {
        setIsEmail(resp?.data?.sendEmail === 'Y')
        setIsSMS(resp?.data?.sendSms === 'Y')
        setIsNotification(resp?.data?.sendNotification === 'Y')
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const onHandleChangeNotification = (data: any) => {
    userServ
      .saveNotificationPermission(data)
      .then((resp: any) => {
        setLoading(false)
        setIsEmail(resp.data.sendEmail === 'Y')
        setIsSMS(resp.data.sendSms === 'Y')
        setIsNotification(resp.data.sendNotification === 'Y')
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  return (
    <>
      {loading && <Loading />}
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'UserProfile.userInfo'})}</PageTitle>
      <div>
        <div className={'d-flex align-items-end'}>
          <img
            className={'img-fluid img-thumbnail'}
            style={{width: '256px', height: '256px', marginRight: '10px'}}
            src={userData?.image ?? '/media/avatars/blank.png'}
            alt={userData?.name}
          />
          <img
            className={'img-fluid img-thumbnail'}
            style={{width: '128px', height: '128px', marginRight: '10px'}}
            src={userData?.image ?? '/media/avatars/blank.png'}
            alt={userData?.name}
          />
          <img
            className={'img-fluid img-thumbnail'}
            style={{width: '64px', height: '64px', marginRight: '10px'}}
            src={userData?.image ?? '/media/avatars/blank.png'}
            alt={userData?.name}
          />
        </div>

        <div className={'mb-15 mt-6'}>
          <label htmlFor='formFile' className='form-label'>
            {intl.formatMessage({id: 'UserProfile.userPhoto'})}
          </label>
          <div className={'d-flex align-items-center'}>
            <input
              className='form-control'
              type='file'
              id='formFile'
              onChange={(e: any) => {
                formik.setFieldValue('selectedFile', e.target.files[0])
              }}
            />
            <div className={'ml-5 w-100 px-5'}>
              <button
                type={'button'}
                className={'btn btn-success w-100'}
                onClick={() => {
                  setLoading(true)
                  uploadImage(formik.values.selectedFile)
                }}
              >
                {intl.formatMessage({id: 'UserProfile.load'})}
              </button>
            </div>
          </div>
          {formik.touched.selectedFile && formik.errors.selectedFile && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.selectedFile}</span>
              </div>
            </div>
          )}
        </div>

        <div className={'row mb-10 mt-10'}>
          <div className='col-md-6 mb-5'>
            <label className='class="form-label fw-bolder text-dark fs-6'>
              {intl.formatMessage({id: 'UserProfile.company.freight.url'})}
            </label>
            <input
              placeholder={'URL'}
              type='text'
              autoComplete='off'
              disabled
              value={
                userData?.company?.companyCode
                  ? `${window.location.origin}/public-freight-create?companyCode=${userData?.company?.companyCode}`
                  : ''
              }
              className={'form-control form-control-lg form-control-solid bg-white'}
            />
          </div>
          <div className='col-md-2 d-flex align-items-center mt-1'>
            <button
              type={'button'}
              disabled={!userData?.company?.companyCode}
              className={'btn btn-primary'}
              onClick={() => {
                handleCopyText()
              }}
            >
              {intl.formatMessage({id: 'UserProfile.company.freight.copy'})}
            </button>
          </div>
        </div>

        <div className={'row mb-10 mt-10'}>
          <div className='col-md-6 mb-5'>
            <label className='class="form-label fw-bolder text-dark fs-6'>
              {intl.formatMessage({id: 'UserProfile.company.groupInvitation.url'})}
            </label>
            <input
              placeholder={'URL'}
              type='text'
              autoComplete='off'
              disabled
              value={
                userData?.company?.companyCode
                  ? `${window.location.origin}/public/invitations/groups?company=${userData?.company?.companyCode}&refererUserCode=${userData?.userCode}`
                  : ''
              }
              className={'form-control form-control-lg form-control-solid bg-white'}
            />
          </div>
          <div className='col-md-2 d-flex align-items-center mt-1'>
            <button
              type={'button'}
              disabled={!userData?.company?.companyCode}
              className={'btn btn-primary'}
              onClick={() => {
                handleGroupInvitationCopyText()
              }}
            >
              {intl.formatMessage({id: 'UserProfile.company.freight.copy'})}
            </button>
          </div>
        </div>
      </div>
      <hr />
      <a href='/renew-password' className={'fs-5 mb-2 fw-bold'}>
        {intl.formatMessage({id: 'UserProfile.updatePwd'})} {'>'}
      </a>
      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}
      <div className={'row mb-10 mt-10'}>
        {/* begin::Form group name */}
        <div className='col-md-6 mb-5'>
          <label className='class="form-label fw-bolder text-dark fs-6'>
            {intl.formatMessage({id: 'UserProfile.name'})}
          </label>
          <input
            placeholder={intl.formatMessage({id: 'UserProfile.name.placeHolder'})}
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('name')}
            className={clsx(
              'form-control form-control-lg form-control-solid bg-white',
              {
                'is-invalid': formik.touched.name && formik.errors.name,
              },
              {
                'is-valid': formik.touched.name && !formik.errors.name,
              }
            )}
          />
          {formik.touched.name && formik.errors.name && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.name}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group surname */}
        <div className='col-md-6 mb-5'>
          <label className='class="form-label fw-bolder text-dark fs-6'>
            {intl.formatMessage({id: 'UserProfile.surName'})}
          </label>
          <input
            placeholder={intl.formatMessage({id: 'UserProfile.surName.placeHolder'})}
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('surname')}
            className={clsx(
              'form-control form-control-lg form-control-solid bg-white',
              {
                'is-invalid': formik.touched.surname && formik.errors.surname,
              },
              {
                'is-valid': formik.touched.surname && !formik.errors.surname,
              }
            )}
          />
          {formik.touched.surname && formik.errors.surname && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.surname}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}
      </div>
      {/* begin::Form group */}
      <div className='text-center mb-10'>
        <button
          onClick={() =>
            changeCheck()
              ? formik.handleSubmit()
              : toast.info(intl.formatMessage({id: 'User.toast.info.editDisabled'}))
          }
          id='kt_sign_up_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
        >
          {!loading && (
            <span className='indicator-label'>
              {intl.formatMessage({id: 'UserProfile.updateInfo'})}
            </span>
          )}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              {intl.formatMessage({id: 'UserProfile.pleaseWait'})}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Form group */}
      <hr />
      <div className={'row mb-6'}>
        {/* begin::Form group username */}
        <div className='col-md-6 mb-5'>
          {/* begin::Form group Lastname */}
          <div className='fv-row mb-5'>
            <label className='form-label fw-bolder text-dark fs-6'>
              {intl.formatMessage({id: 'UserProfile.userName'})}
            </label>
            <input
              disabled
              placeholder={intl.formatMessage({id: 'UserProfile.userName.placeHolder'})}
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('username')}
              className={clsx(
                'form-control form-control-lg form-control-solid bg-white',
                {
                  'is-invalid': formik.touched.username && formik.errors.username,
                },
                {
                  'is-valid': formik.touched.username && !formik.errors.username,
                }
              )}
            />
            {formik.touched.username && formik.errors.username && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.username}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
        </div>
        {/* end::Form group */}

        {/* begin::Form group email */}
        <div className='col-md-6 mb-5'>
          <label className='form-label fw-bolder text-dark fs-6'>
            {intl.formatMessage({id: 'UserProfile.email'})}
          </label>
          <input
            disabled
            placeholder={intl.formatMessage({id: 'UserProfile.email.placeHolder'})}
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control form-control-lg form-control-solid bg-white',
              {'is-invalid': formik.touched.email && formik.errors.email},
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}
      </div>

      <div className={'row mb-20'}>
        {/* begin::Form group lang */}
        {/*<div className='col-md-6 mb-5'>
                        <label className='form-label fw-bolder text-dark fs-6'>
                            Dil
                        </label>
                        <input
                            disabled
                            placeholder='Dil'
                            type='text'
                            autoComplete='off'
                            {...formik.getFieldProps('language')}
                            className={clsx(
                                'form-control form-control-lg form-control-solid bg-white',
                                {'is-invalid': formik.touched.language && formik.errors.language},
                                {
                                    'is-valid': formik.touched.language && !formik.errors.language,
                                }
                            )}
                        />
                        {formik.touched.language && formik.errors.language && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{formik.errors.language}</span>
                                </div>
                            </div>
                        )}
                    </div>*/}
        {/* end::Form group */}

        <div className={'col-md-6 row p-0 m-0'}>
          {/* begin::Form group country */}
          <div className='col-md-3 '>
            <label className='form-label fw-bolder text-dark fs-6'>
              {intl.formatMessage({id: 'UserProfile.countryCode'})}
            </label>
            <input
              disabled
              placeholder={intl.formatMessage({id: 'UserProfile.countryCode.placeHolder'})}
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('countryCode')}
              className={clsx(
                'form-control form-control-lg form-control-solid bg-white',
                {'is-invalid': formik.touched.countryCode && formik.errors.countryCode},
                {
                  'is-valid': formik.touched.countryCode && !formik.errors.countryCode,
                }
              )}
            />
            {formik.touched.countryCode && formik.errors.countryCode && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.countryCode}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group phone */}
          <div className='col-md-9 mb-5'>
            <label className='form-label fw-bolder text-dark fs-6'>
              {intl.formatMessage({id: 'UserProfile.telephone'})}
            </label>
            <input
              disabled
              placeholder={intl.formatMessage({id: 'UserProfile.telephone.placeHolder'})}
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('mobile')}
              className={clsx(
                'form-control form-control-lg form-control-solid bg-white',
                {'is-invalid': formik.touched.mobile && formik.errors.mobile},
                {
                  'is-valid': formik.touched.mobile && !formik.errors.mobile,
                }
              )}
            />
            {formik.touched.mobile && formik.errors.mobile && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.mobile}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
        </div>
      </div>

      <hr />

      <div className='row col-md-6 mb-6'>
        <label className='col-md-12 form-label fw-bolder text-dark fs-6'>
          {intl.formatMessage({id: 'Profile.notification.permission.title'})}
        </label>
        <div className='row col-md-12 mb-0'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>
            {intl.formatMessage({id: 'Profile.notification.permission.sms'})}
          </label>

          <div className='col-lg-8 d-flex align-items-center'>
            <div className='form-check form-check-solid form-switch fv-row'>
              <input
                className='form-check-input w-45px h-30px'
                type='checkbox'
                id='sms'
                checked={isSMS}
                onChange={() => {
                  setLoading(true)
                  onHandleChangeNotification({
                    sendEmail: isEmail ? 'Y' : 'N',
                    sendSms: !isSMS ? 'Y' : 'N',
                    sendNotification: isNotification ? 'Y' : 'N',
                  })
                }}
              />
              <label className='form-check-label'></label>
            </div>
          </div>
        </div>

        <div className='row col-md-12 mb-0'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>
            {intl.formatMessage({id: 'Profile.notification.permission.email'})}
          </label>

          <div className='col-lg-8 d-flex align-items-center'>
            <div className='form-check form-check-solid form-switch fv-row'>
              <input
                className='form-check-input w-45px h-30px'
                type='checkbox'
                id='email'
                checked={isEmail}
                onChange={() => {
                  setLoading(true)
                  onHandleChangeNotification({
                    sendEmail: !isEmail ? 'Y' : 'N',
                    sendSms: isSMS ? 'Y' : 'N',
                    sendNotification: isNotification ? 'Y' : 'N',
                  })
                }}
              />
              <label className='form-check-label'></label>
            </div>
          </div>
        </div>

        <div className='row col-md-12 mb-0'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>
            {intl.formatMessage({id: 'Profile.notification.permission.notification'})}
          </label>

          <div className='col-lg-8 d-flex align-items-center'>
            <div className='form-check form-check-solid form-switch fv-row'>
              <input
                className='form-check-input w-45px h-30px'
                type='checkbox'
                id='notification'
                checked={isNotification}
                onChange={() => {
                  setLoading(true)
                  onHandleChangeNotification({
                    sendEmail: isEmail ? 'Y' : 'N',
                    sendSms: isSMS ? 'Y' : 'N',
                    sendNotification: !isNotification ? 'Y' : 'N',
                  })
                }}
              />
              <label className='form-check-label'></label>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {UserProfile}
