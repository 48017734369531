import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import clsx from 'clsx'

import {KTSVG} from '../../../../../_metronic/helpers'

import SelectBox from '../../../../Components/SelectBox/SelectBox'
import RightLayoutPopup from '../../../../Components/RightLayoutPopup'

import useScrollLock from '../../../../../utils/useScrollLock'

const ShowDetails = ({vehicleMetadata, downloadFiles, vehicleTypes, getData, onClose}: any) => {
  const intl = useIntl()
  const {scrollLock, scrollUnlock} = useScrollLock()
  useEffect(() => {
    scrollLock()
  }, [])

  const [vehicleDetail, setVehicleDetail] = useState<any>({
    id: -1,
    year: '',
    plate: '',
    /*capacity: 1,*/
    make: '',
    model: '',
    vehicleType: '',
  })

  useEffect(() => {
    if (getData) {
      setVehicleDetail(getData)
    }
  }, [getData])

  return (
    <>
      <RightLayoutPopup
        title={intl.formatMessage({id: 'Carrier.vehicles.details.vehicleDetails'})}
        className='carrier-view-vehicle-side-wrap'
        isCloseIcon
        closeOnClick={() => onClose()}
      >
        <div className={''}>
          <div className={'row'}>
            <div className={'col-xl-6 mb-10'}>
              <div className='fv-row mb-10'>
                <h6 className={'text-muted'}>
                  {intl.formatMessage({id: 'Carrier.vehicles.details.year'})}
                </h6>
                <b className={'fs-5'}>{vehicleDetail.year}</b>
              </div>
              <div className='fv-row mb-10'>
                <h6 className={'text-muted'}>
                  {intl.formatMessage({id: 'Carrier.vehicles.details.numberPlate'})}
                </h6>
                <b className={'fs-5'}>{vehicleDetail.plate}</b>
              </div>
              <div className='fv-row mb-10'>
                <h6 className={'text-muted'}>
                  {intl.formatMessage({id: 'Carrier.vehicles.details.brand'})}
                </h6>
                <b className={'fs-5'}>{vehicleDetail.make}</b>
              </div>
            </div>
            <div className={'col-xl-6'}>
              <div className='fv-row mb-10'>
                <h6 className={'text-muted'}>
                  {intl.formatMessage({id: 'Carrier.vehicles.details.model'})}
                </h6>
                <b className={'fs-5'}>{vehicleDetail.model}</b>
              </div>
              <div className={'mb-10'}>
                <div className='fv-row mb-10'>
                  <h6 className={'text-muted'}>
                    {intl.formatMessage({id: 'Carrier.vehicles.details.vehicleType'})}
                  </h6>
                  <b className={'fs-5'}>{vehicleDetail.vehicleType}</b>
                </div>
              </div>
              <div className={'mb-10 px-3'}>
                <h3 className={'text-bold mb-2'}>
                  {intl.formatMessage({id: 'Carrier.vehicles.details.document'})}
                </h3>
                {!vehicleMetadata?.length && (
                  <p>{intl.formatMessage({id: 'Carrier.vehicles.details.noDocumentUploaded'})}</p>
                )}
                {vehicleMetadata.map((item: any) => (
                  <div key={item?.userFileId} className={'d-flex justify-content-between mb-4'}>
                    <div>
                      <div className='form-check mb-2'>
                        <input
                          disabled
                          className={
                            'form-check-input border border-' +
                            (item?.fileStatus?.fileStatusId !== 4 ? 'danger' : 'success')
                          }
                          type='checkbox'
                          checked={item?.fileStatus?.fileStatusId === 4}
                        />
                        <label className='form-check-label' htmlFor='flexCheckDefault'>
                          {item?.fileStatus?.fileStatusId === 4
                            ? intl.formatMessage({id: 'Carrier.vehicles.details.approved'})
                            : intl.formatMessage({id: 'Carrier.vehicles.details.notApproved'})}
                        </label>
                      </div>
                      <p className={'mb-3 text-truncate'}>{item?.fileType.description}</p>
                    </div>
                    <button
                      title={intl.formatMessage({id: 'Carrier.vehicles.details.download'})}
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 mb-1'
                      onClick={() => {
                        downloadFiles({
                          url: item?.url,
                          code: item?.fileType.code,
                          name: item.fileName,
                        })
                      }}
                    >
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr091.svg'
                        className='svg-icon-3 mr-2 pr-2'
                      />
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className='text-center mt-5 row view-details-freight-popup freightPost-popup-container'>
          <div className={'col-md-6'}>
            <button
              onClick={() => {
                onClose()
                scrollUnlock()
              }}
              type='button'
              id='kt_sign_up_submit'
              className='btn btn-lg btn-danger w-100 mb-5 '
            >
              {intl.formatMessage({id: 'Carrier.freightPosting.view.close'})}
            </button>
          </div>
        </div>
      </RightLayoutPopup>
    </>
  )
}

export default ShowDetails
