import {apiUrl,globalPageCount} from "../conf";
import axios from "axios";

export class DriversService {

    getDrivers(pageIndex?: number, pageCount?: number, searchText?: string) {
        const config: any = {
            method: 'GET',
            url: apiUrl + '/user-management/users/drivers?searchText=' + (searchText ?? '') + '&pageIndex=' + (pageIndex ?? 0) + '&pageCount=' + (pageCount ?? globalPageCount),
            headers: {
                'Content-Type': 'application/json',
            },
        };

        return axios(config);
    }

    getDriver(driverId: number) {
        const config: any = {
            method: 'GET',
            url: apiUrl + '/user-management/users/' + driverId,
            headers: {
                'Content-Type': 'application/json',
            },
        };

        return axios(config);
    }

    getAllMetadata(driverId: number) {
        const config: any = {
            method: 'GET',
            url: apiUrl + '/user-management/users/' + driverId + '/files',
            headers: {
                'Content-Type': 'application/json',
            },
        };

        return axios(config);
    }

    deleteDriver(driverId: number) {
        const config: any = {
            method: 'DELETE',
            url: apiUrl + '/user-management/users/drivers/' + driverId,
            headers: {
                'Content-Type': 'application/json',
            },
        };

        return axios(config);
    }

    updateDriver(driverId: number, name?: string, surname?: string, mobile?: string, phoneCode?: string) {
        const config: any = {
            method: 'PUT',
            url: apiUrl + '/user-management/users/',
            headers: {
                'Content-Type': 'application/json',
            },
            data: JSON.stringify({
                userId: driverId,
                name: name,
                surname: surname,
                mobile: mobile,
                countryCode: phoneCode,
            })
        };

        return axios(config);
    }

    createDriver(name?: string, surname?: string, mobile?: string, phoneCode?: string, initialPassword?: string) {
        const config: any = {
            method: 'POST',
            url: apiUrl + '/user-management/users/drivers',
            headers: {
                'Content-Type': 'application/json',
            },
            data: JSON.stringify({
                name: name,
                surname: surname,
                mobile: mobile,
                countryCode: phoneCode,
                initialPassword: initialPassword
            })
        };

        return axios(config);
    }

    uploadDriverLicense(userId: number, file: any) {
        const data = new FormData()
        data.append('file', file);
        const config: any = {
            method: 'POST',
            url: apiUrl + '/user-management/users/' + userId + '/files/licence/upload',
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            data: data
        };

        return axios(config);
    }

    uploadSrcLicense(userId: number, file: any) {
        const data = new FormData()
        data.append('file', file);

        const config: any = {
            method: 'POST',
            url: apiUrl + '/user-management/users/' + userId + '/files/src/upload',
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            data: data
        };

        return axios(config);
    }


}
