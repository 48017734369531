/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react'
import {useIntl} from 'react-intl'
import clsx from 'clsx'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import RightLayoutPopup from '../RightLayoutPopup'
import useScrollLock from '../../../utils/useScrollLock'
import Dropbox from '../Dropbox'
import {CreateNewAddress} from '../../pages/FreightOperator/CreateNewAdress'

import './styles.scss'

const AddNewAddressPopup = ({
  type,
  selectedCountry,
  onClose,
  countryList,
  cityList,
  onSubmit,
  title,
}: any) => {
  const intl = useIntl()
  const {scrollLock, scrollUnlock} = useScrollLock()

  useEffect(() => {
    scrollLock()
  }, [])

  const initialValues = {
    title: '',
    country: '',
    city: null,
    district: '',
    neighborhood: '',
    street: '',
    streetNumber: '',
    postCode: '',
    // addressText: '',
  }
  const registrationSchema = Yup.object().shape({
    title: Yup.string().required(intl.formatMessage({id: 'addNewAddressPopup.area1.title'})),
    country: Yup.string().required(intl.formatMessage({id: 'addNewAddressPopup.area1.country'})),
    district: Yup.string(),
    neighborhood: Yup.string(),
    street: Yup.string(),
    streetNumber: Yup.string(),
    postCode: Yup.string().required(intl.formatMessage({id: 'addNewAddressPopup.area1.postCode'})),
    // addressText: Yup.string()
    //     .max(500, intl.formatMessage({id: "addNewAddressPopup.area1.addressText.max"}))
    //     .required(intl.formatMessage({id: "addNewAddressPopup.area1.addressText"})),
    city: Yup.string()
      .nullable()
      .required(intl.formatMessage({id: 'addNewAddressPopup.area1.city'})),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      if (type === 'default') {
        let dat: any = {
          title: values.title && values.title,
          country: values.country && values.country,
          city: values.city ? values.city : 0,
          district: values.district && values.district,
          neighborhood: values.neighborhood && values.neighborhood,
          street: values.street && values.street,
          streetNumber: values.streetNumber && values.streetNumber,
          postCode: values.postCode && values.postCode,
          // addressText: values.addressText && values.addressText,
        }
        onSubmit(dat)
      }
    },
  })

  useEffect(() => {
    formik.values.country && selectedCountry(formik.values.country)
  }, [formik.values.country])

  const OldDesign = () => (
    <>
      {/** Form Görünümü **/}
      <div className={'row'}>
        {/* begin::Form group Title */}
        <div className='col-md-4 mb-10'>
          <label className='form-label fw-bolder text-dark fs-6'>
            {intl.formatMessage({id: 'addNewAddressPopup.area2.title'})}
          </label>
          <input
            placeholder={intl.formatMessage({id: 'addNewAddressPopup.area2.title.placeholder'})}
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('title')}
            className={clsx(
              'form-control form-control-lg form-control-solid bg-white',
              {
                'is-invalid': formik.touched.title && formik.errors.title,
              },
              {
                'is-valid': formik.touched.title && !formik.errors.title,
              }
            )}
          />
          {formik.touched.title && formik.errors.title && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.title}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        <div className={'row col-md-8 mb-10'}>
          {/* begin::Form group Country */}
          <div className='col-md-6 mb-5'>
            <label className='form-label fw-bolder text-dark fs-6'>
              {intl.formatMessage({id: 'addNewAddressPopup.area2.country'})}
            </label>
            <Dropbox
              hideArrow
              customClass={'form-control form-control-lg'}
              placeholder={intl.formatMessage({id: 'addNewAddressPopup.area2.country.placeholder'})}
              customSearchValue={''}
              customOnSearch={(e: any) => {}}
              disabledSearch
              onScroll={() => {}}
              setScrollCalled={() => {}}
              currentPageIndex={0}
              menuList={
                countryList &&
                countryList.map((item: any) => {
                  return {
                    id: item?.code,
                    title: item?.name,
                  }
                })
              }
              selectedItemData={(item: any) => {
                if (item?.id) {
                  formik.setFieldValue('country', item?.id)
                }
              }}
            />

            {formik.touched.country && formik.errors.country && (
              <div className='fv-plugins-message-container mt-2'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.country}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group City */}
          <div className='col-md-6 mb-5'>
            <label className='form-label fw-bolder text-dark fs-6'>
              {intl.formatMessage({id: 'addNewAddressPopup.area2.city'})}
            </label>
            <Dropbox
              hideArrow
              disabled={!formik.values.country}
              customClass={'form-control form-control-lg'}
              placeholder={intl.formatMessage({id: 'addNewAddressPopup.area2.city.placeholder'})}
              customSearchValue={''}
              customOnSearch={(e: any) => {}}
              disabledSearch
              onScroll={() => {}}
              setScrollCalled={() => {}}
              currentPageIndex={0}
              menuList={
                formik.values.country &&
                cityList &&
                cityList?.map((item: any) => {
                  return {
                    id: item?.cityId,
                    title: item?.originalName,
                  }
                })
              }
              selectedItemData={(item: any) => {
                if (item?.id) {
                  formik.setFieldValue('city', item?.id)
                }
              }}
            />
            {formik.touched.city && formik.errors.city && (
              <div className='fv-plugins-message-container mt-2'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.city}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
        </div>
        <div className={'col-md-12 row mb-10'}>
          {/* begin::Form group Street */}
          <div className='col-md-4 mb-5'>
            <label className='form-label fw-bolder text-dark fs-6'>
              {intl.formatMessage({id: 'addNewAddressPopup.area2.district1'})}
            </label>
            <input
              placeholder={intl.formatMessage({
                id: 'addNewAddressPopup.area2.district1.placeholder',
              })}
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('district')}
              className={clsx(
                'form-control form-control-lg form-control-solid bg-white',
                {
                  'is-invalid': formik.touched.district && formik.errors.district,
                },
                {
                  'is-valid': formik.touched.district && !formik.errors.district,
                }
              )}
            />
            {formik.touched.district && formik.errors.district && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.district}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group neighborhood */}
          <div className='col-md-4 mb-5'>
            <label className='form-label fw-bolder text-dark fs-6'>
              {intl.formatMessage({id: 'addNewAddressPopup.area2.neighborhood'})}
            </label>
            <input
              placeholder={intl.formatMessage({
                id: 'addNewAddressPopup.area2.neighborhood.placeholder',
              })}
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('neighborhood')}
              className={clsx(
                'form-control form-control-lg form-control-solid bg-white',
                {
                  'is-invalid': formik.touched.neighborhood && formik.errors.neighborhood,
                },
                {
                  'is-valid': formik.touched.neighborhood && !formik.errors.neighborhood,
                }
              )}
            />
            {formik.touched.neighborhood && formik.errors.neighborhood && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.neighborhood}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group PostCode */}
          <div className='col-md-4 mb-5'>
            <label className='form-label fw-bolder text-dark fs-6'>
              {intl.formatMessage({id: 'addNewAddressPopup.area2.postCode'})}
            </label>
            <input
              placeholder={intl.formatMessage({
                id: 'statusAddNewAddressPopup.area2.postCode.placeHolder',
              })}
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('postCode')}
              className={clsx(
                'form-control form-control-lg form-control-solid bg-white',
                {
                  'is-invalid': formik.touched.postCode && formik.errors.postCode,
                },
                {
                  'is-valid': formik.touched.postCode && !formik.errors.postCode,
                }
              )}
            />
            {formik.touched.postCode && formik.errors.postCode && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.postCode}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group street */}
          <div className='col-md-4 mb-5'>
            <label className='form-label fw-bolder text-dark fs-6'>
              {intl.formatMessage({id: 'addNewAddressPopup.area2.street'})}
            </label>
            <input
              placeholder={intl.formatMessage({id: 'addNewAddressPopup.area2.street.placeholder'})}
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('street')}
              className={clsx(
                'form-control form-control-lg form-control-solid bg-white',
                {
                  'is-invalid': formik.touched.street && formik.errors.street,
                },
                {
                  'is-valid': formik.touched.street && !formik.errors.street,
                }
              )}
            />
            {formik.touched.street && formik.errors.street && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.street}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group streetNumber */}
          <div className='col-md-4 mb-5'>
            <label className='form-label fw-bolder text-dark fs-6'>
              {intl.formatMessage({id: 'addNewAddressPopup.area2.streetNumber'})}
            </label>
            <input
              placeholder={intl.formatMessage({
                id: 'addNewAddressPopup.area2.streetNumber.placeholder',
              })}
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('streetNumber')}
              className={clsx(
                'form-control form-control-lg form-control-solid bg-white',
                {
                  'is-invalid': formik.touched.streetNumber && formik.errors.streetNumber,
                },
                {
                  'is-valid': formik.touched.streetNumber && !formik.errors.streetNumber,
                }
              )}
            />
            {formik.touched.streetNumber && formik.errors.streetNumber && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.streetNumber}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
        </div>
        <div className={'row'}>
          {/* begin::Form group Address */}
          {/* <div className='mb-5'>
                    <label className='class="form-label fw-bolder text-dark fs-6'>
                        {intl.formatMessage({id: "addNewAddressPopup.area2.address1"})}
                    </label>
                    <textarea
                        rows={6}
                        placeholder={intl.formatMessage({id: "addNewAddressPopup.area2.address1.placeholder"})}
                        autoComplete='off'
                        {...formik.getFieldProps('addressText')}
                        className={clsx(
                            'form-control form-control-lg form-control-solid bg-white',
                            {
                                'is-invalid': formik.touched.addressText && formik.errors.addressText,
                            },
                            {
                                'is-valid': formik.touched.addressText && !formik.errors.addressText,
                            },
                        )}
                    />
                    {formik.touched.addressText && formik.errors.addressText && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span role='alert'>{formik.errors.addressText}</span>
                            </div>
                        </div>
                    )}
                </div> */}
          {/* end::Form group */}
        </div>
      </div>
      {/*Add adress button area*/}
      <div className='text-center mt-5 row freightPost-popup-container'>
        <div className={'col-md-6'}>
          <button
            onClick={() => {
              scrollUnlock()
              onClose()
            }}
            type='submit'
            id='kt_sign_up_submit'
            className='btn btn-lg btn-danger w-100 mb-5 '
          >
            {intl.formatMessage({id: 'addNewAddressPopup.area2.cancel'})}
          </button>
        </div>
        <div className={'col-md-6'}>
          <button
            onClick={() => {
              scrollUnlock()
              formik.handleSubmit()
            }}
            type='submit'
            id='kt_sign_up_submit'
            className='btn btn-lg btn-primary w-100 mb-5'
          >
            {intl.formatMessage({id: 'addNewAddressPopup.area2.approve'})}
          </button>
        </div>
      </div>
    </>
  )

  return type === 'default' ? (
    <RightLayoutPopup
      title={title}
      className='add-new-address-default-wrap'
      isCloseIcon
      closeOnClick={() => onClose()}
    >
      {OldDesign()}
    </RightLayoutPopup>
  ) : (
    // <RightLayoutPopup title={title} width={'75%'}>
    <CreateNewAddress
      popupMode
      popupModeOnCancel={() => {
        scrollUnlock()
        onClose()
      }}
      popupModeOnSubmit={() => {
        scrollUnlock()
        onSubmit()
      }}
    />
  )
  // </RightLayoutPopup>
}

export default AddNewAddressPopup
