import {apiUrl} from '../conf'
import axios from 'axios'

export class TransporterService {
  getCurrentLocations({
    name,
    surName,
    vehiclePlate,
    trailerPlate,
    fromCountryCode,
    fromCityId,
    toCountryCode,
    toCityId,
  }: any) {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        `/transporter-management/transporters/tracking/owners?name=${name ?? ''}&surname=${
          surName ?? ''
        }&vehiclePlate=${vehiclePlate ?? ''}&trailerPlate=${trailerPlate ?? ''}&fromCountryCode=${
          fromCountryCode ?? ''
        }&fromCityId=${fromCityId ?? ''}&toCountryCode=${toCountryCode ?? ''}&toCityId=${
          toCityId ?? ''
        }&pageIndex=0&pageCount=1000`,
      headers: {
        'Content-Type': 'application/json',
      },
    }
    return axios(config)
  }
}
