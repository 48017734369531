import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'

import {PageTitle} from '../../../../_metronic/layout/core'

import Loading from '../../../Components/Loading'
import DataTableAdvanced from '../../../Components/DataTableAdvanced'
import Modal from '../../../Components/Modal'
import SelectBox from '../../../Components/SelectBox/SelectBox'
import Dropbox from '../../../Components/Dropbox'
import ShowDetails from './Popups/ShowDetails'

import {AdvertService} from '../../../../services/AdminServices/AdvertService'
import {TransportService} from '../../../../services/AdminServices/TransportService'

import useScrollLock from '../../../../utils/useScrollLock'
import {KTSVG} from '../../../../_metronic/helpers'

import './Transports.scss'

const Transports = () => {
  const intl = useIntl()
  const [loading, setLoading] = useState<boolean>(false)
  const [pageMIndex, setPageMIndex] = useState<number>(0)

  /*Service States*/
  const adminAdvertServ = new AdvertService()
  const adminTransportServ = new TransportService()

  /*List States*/
  const [transportList, setTransportList] = useState<any[]>([])
  const [selectedTransportId, setSelectedTransportId] = useState<number>(-1)
  const [transport, setTransport] = useState<any>({
    transportNumber: '',
    plannedDepartureDate: '',
    plannedArrivalDate: '',
    realDepartureDate: '',
    realArrivalDate: '',
    status: {description: '', transportStatusId: -1},
    fromCountry: '',
    toCountry: '',
    fromCity: '',
    toCity: '',
    carrierCompany: '',
    ownerCompany: '',
    driver: '',
    driverPhone: '',
    vehiclePlate: '',
    trailerPlate: '',
  })

  /*Screens and modals*/
  const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false)
  const [showDetailPopup, setShowDetailPopup] = useState<boolean>(false)

  const {scrollUnlock} = useScrollLock()
  useEffect(() => {
    if (!showDetailPopup && !showDeletePopup) scrollUnlock()
  }, [showDetailPopup, showDeletePopup])

  /*Filter Select States*/
  const [filter, setFilter] = useState<string>('all')
  const [companyFilter, setCompanyFilter] = useState<string>('all')
  const [currentPageIndex, setCurrentPageIndex] = useState<number>(0)
  const [companyCustomSearch, setCompanyCustomSearch] = useState<string>('')

  const [statusList, setStatusList] = useState<any>([])
  const [carrierCompanyList, setCarrierCompanyList] = useState<any>([])

  const [showFilterPanel, setShowFilterPanel] = useState<boolean>(false)
  const [isNextPage, setIsNextPage] = useState<boolean>(false)

  const [companyCustomScrollAPICalled, setCompanyCustomScrollAPICalled] = useState<boolean>(false)

  const onPaginationChange = (page: number) => {
    if (!isNextPage) {
      getTransportList({
        transportStatusId: filter,
        ...(companyFilter && {companyId: companyFilter}),
        companyId: companyFilter,
        ...(filter && {transportStatusId: filter}),
        pageIndex: page,
      })
      setPageMIndex(page)
    }
    setIsNextPage(true)
  }

  const getStatusTypes = () => {
    adminTransportServ
      .getTransportStatuses()
      .then((resp: any) => {
        setStatusList(
          [
            {
              transportStatusId: 'all',
              description: intl.formatMessage({id: 'global.all'}),
            },
          ].concat(resp?.data)
        )
        setLoading(false)
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const getAdvertCarrierCompanies = (pageIndex: number = 0, searchText?: string) => {
    adminAdvertServ
      .getAdvertCarrierCompanies(pageIndex, searchText ?? '')
      .then((resp: any) => {
        setCompanyCustomScrollAPICalled(false)
        if (pageIndex === 0)
          setCarrierCompanyList(
            [
              {
                companyId: 'all',
                name: intl.formatMessage({id: 'global.all'}),
              },
            ].concat(resp?.data?.filter((item: any) => item?.name))
          )
        else setCarrierCompanyList((item: any) => [...item, ...resp?.data])
        setLoading(false)
      })
      .catch((e: any) => {
        setCompanyCustomScrollAPICalled(false)
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  /** Container Api call Start **/
  const getTransportList = (data: {
    pageIndex?: number
    pageCount?: number
    description?: string
    fromCountryCode?: string
    fromCityId?: number
    toCountryCode?: string
    toCityId?: number
    plannedDepartureDate?: string
    plannedArrivalDate?: string
    driverId?: number
    vehicleId?: number
    trailerId?: number
    transportStatusId?: string | number
    companyId?: string | number
  }) => {
    adminTransportServ
      .getTransports(data)
      .then((resp: any) => {
        setTransportList(resp?.data)
        setLoading(false)
        setIsNextPage(false)
      })
      .catch((e) => {
        setLoading(false)
        setIsNextPage(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const deleteTransport = (id: number) =>
    adminTransportServ
      .deleteTransport(id)
      .then((resp) => {
        setLoading(false)
        toast.success(intl.formatMessage({id: 'global.deleteSuccess'}))
      })
      .catch((e) => {
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })

  const getTransport = (id: number) => adminTransportServ.getTransport(id)

  useEffect(() => {
    setLoading(true)
    getStatusTypes()
    setLoading(true)
    getTransportList({})
    setLoading(true)
    getAdvertCarrierCompanies()
  }, [])

  useEffect(() => {
    if (filter) {
      setLoading(true)
      getTransportList({
        transportStatusId: filter,
        ...(companyFilter && {companyId: companyFilter}),
        pageIndex: pageMIndex,
      })
    }
  }, [filter])

  useEffect(() => {
    if (companyFilter) {
      setLoading(true)
      getTransportList({
        companyId: companyFilter,
        ...(filter && {transportStatusId: filter}),
        pageIndex: pageMIndex,
      })
    }
  }, [companyFilter])

  return (
    <>
      {loading && <Loading />}
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({id: 'ADMIN.TRANSPORTS.MAIN.TITLE'})}
      </PageTitle>

      <div className='row mb-5'>
        <span className='menu-icon d-flex justify-content-end'>
          <div
            className={`btn btn-icon btn-active-light-primary btn-custom ${
              showFilterPanel ? 'menu-button-active' : ''
            }`}
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='bottom'
            onClick={() => setShowFilterPanel(!showFilterPanel)}
          >
            <KTSVG path='/media/filtering.svg' className='svg-icon-2x' />
          </div>
        </span>
      </div>

      <div>
        {showFilterPanel && (
          <div className={'d-flex align-items-center '}>
            <div className='mb-10 col-md-3 ' style={{marginRight: '15px'}}>
              <h6 className={'mb-3'}>
                {intl.formatMessage({id: 'ADMIN.TRANSPORTS.STATUSFILTER'})}
              </h6>
              <div className={''}>
                <Dropbox
                  hideArrow
                  customClass={'form-select'}
                  placeholder={intl.formatMessage({id: 'ADMIN.TRANSPORTS.SELECTFILTER'})}
                  customSearchValue={''}
                  customOnSearch={(e: any) => {}}
                  disabledSearch
                  onScroll={() => {}}
                  setScrollCalled={() => {}}
                  currentPageIndex={0}
                  menuList={
                    statusList &&
                    statusList.map((item: any) => {
                      return {
                        id: item?.transportStatusId,
                        title: item?.description,
                      }
                    })
                  }
                  selectedItemData={(item: any) => {
                    if (item?.id) {
                      setFilter(item?.id)
                      setLoading(true)
                    }
                  }}
                />
              </div>
            </div>
            <div className='mb-10 col-md-3 '>
              <h6 className={'mb-3'}>
                {intl.formatMessage({id: 'ADMIN.TRANSPORTS.COMPANYFILTER'})}
              </h6>
              <div className={''}>
                <Dropbox
                  hideArrow
                  customClass={'form-select'}
                  placeholder={intl.formatMessage({id: 'ADMIN.TRANSPORTS.SELECTFILTER'})}
                  customSearchValue={companyCustomSearch}
                  customOnSearch={(e: any) => {
                    setCurrentPageIndex(0)
                    setCompanyCustomSearch(e.target.value)
                    getAdvertCarrierCompanies(0, e.target.value)
                  }}
                  onScroll={(val: any) => {
                    setCurrentPageIndex(val)
                    getAdvertCarrierCompanies(val, companyCustomSearch)
                  }}
                  setScrollCalled={() => {
                    setCompanyCustomScrollAPICalled(true)
                  }}
                  scrollAPIResponse={companyCustomScrollAPICalled}
                  currentPageIndex={currentPageIndex}
                  menuList={
                    carrierCompanyList &&
                    carrierCompanyList
                      .filter(function ({companyId}: any) {
                        // @ts-ignore
                        return !this[companyId] && (this[companyId] = companyId)
                      }, {})
                      .map((item: any) => {
                        return {id: item?.companyId, title: item?.name}
                      })
                  }
                  defaultClearItem={{
                    id: 'all',
                    title: intl.formatMessage({id: 'Admin.Adverts.all'}),
                  }}
                  defaultSelected={{id: companyFilter}}
                  selectedItemData={(item: any) => {
                    if (item?.id) {
                      setCompanyFilter(item?.id)
                      setLoading(true)
                      getTransportList({companyId: item?.id, transportStatusId: filter})
                    }
                  }}
                />
              </div>
            </div>
          </div>
        )}
        {!transportList.length && (
          <div className='alert alert-warning mb-5 fw-bolder' role='alert'>
            {intl.formatMessage({id: 'global.noData'})}
          </div>
        )}
        {
          <DataTableAdvanced
            columns={[
              {
                Header: 'ID',
                accessor: 'id',
              },
              {
                Header: intl.formatMessage({id: 'ADMIN.TRANSPORTS.TABLE.TRANSPORTNUMBER'}),
                accessor: 'transportNumber',
              },
              {
                Header: intl.formatMessage({id: 'ADMIN.TRANSPORTS.TABLE.COMPANY'}),
                accessor: 'carrierCompany',
              },
              {
                Header: intl.formatMessage({id: 'ADMIN.TRANSPORTS.TABLE.FROM'}),
                accessor: 'fromLocation',
              },
              {
                Header: intl.formatMessage({id: 'ADMIN.TRANSPORTS.TABLE.TO'}),
                accessor: 'toLocation',
              },
              {
                Header: intl.formatMessage({id: 'ADMIN.TRANSPORTS.TABLE.STATUS'}),
                accessor: 'status',
              },
              {
                Header: intl.formatMessage({id: 'ADMIN.TRANSPORTS.TABLE.WARNING'}),
                accessor: 'warning',
              },
            ]}
            editableRows={['']}
            data={
              transportList &&
              transportList.map((item: any) => {
                return {
                  id: item?.transportId,
                  transportNumber: item?.transportNumber ?? '-',
                  carrierCompany: item?.transporter?.company?.name ?? '-',
                  fromLocation:
                    item?.container?.fromAddress?.city?.name +
                    ' / ' +
                    item?.container?.fromAddress?.country?.name,
                  toLocation:
                    item?.container?.toAddress?.city?.name +
                    ' / ' +
                    item?.container?.toAddress?.country?.name,
                  status: item?.transportStatus?.description ?? '-',
                  warning: item?.warningMessage ?? '-',
                }
              })
            }
            deleteRow={(row: any) => {
              setSelectedTransportId(row.values.id)
              setShowDeletePopup(true)
            }}
            deleteText={intl.formatMessage({id: 'Admin.transport.table.deleteText'})}
            showDetailBtn={(row: any) => {
              setLoading(true)
              setSelectedTransportId(row.values.id)
              getTransport(row.values.id)
                .then((resp: any) => {
                  let dat = resp.data
                  setTransport({
                    transportNumber: dat?.transportNumber,
                    plannedDepartureDate: dat?.container?.plannedDepartureDate,
                    plannedArrivalDate: dat?.container?.plannedArrivalDate,
                    realDepartureDate: dat?.departureDate,
                    realArrivalDate: dat?.arrivalDate,
                    status: dat?.transportStatus,
                    fromCountry: dat?.container?.fromAddress?.country?.name,
                    toCountry: dat?.container?.toAddress?.country?.name,
                    fromCity: dat?.container?.fromAddress?.city?.name,
                    toCity: dat?.container?.toAddress?.city?.name,
                    carrierCompany: dat?.transporter?.company?.name,
                    ownerCompany: dat?.container?.description,
                    driver: `${dat?.transporter?.driver?.name} ${dat?.transporter?.driver?.surname}`,
                    driverPhone: `${dat?.transporter?.driver?.countryCode}${dat?.transporter?.driver?.mobile}`,
                    vehiclePlate: dat?.transporter?.vehicle?.plate,
                    trailerPlate: dat?.transporter?.trailer?.plate,
                  })
                  setLoading(false)
                  setShowDetailPopup(true)
                })
                .catch((e) => {
                  toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
                })
            }}
            skipPageReset={() => {}}
            setPageMIndex={onPaginationChange}
            pageMIndex={pageMIndex}
          />
        }
      </div>

      {/*DETAIL POPUP*/}
      {showDetailPopup && (
        <ShowDetails
          setTransport={setTransport}
          transport={transport}
          onClose={() => setShowDetailPopup(false)}
        />
      )}

      {showDeletePopup && (
        <Modal
          closeBtnSettings={{
            show: true,
            onClick: () => setShowDeletePopup(false),
          }}
          customAll={
            <div className={''}>
              <h3 className={'mt-5 mb-15'}>
                {intl.formatMessage({id: 'ADMIN.TRANSPORTS.DELETE.ASK'})}
              </h3>
              <div className={'row'}>
                <div className={'col-md-6'}>
                  <button
                    className={'btn btn-danger w-100'}
                    onClick={() => {
                      setShowDeletePopup(false)
                    }}
                  >
                    {intl.formatMessage({id: 'global.cancel'})}
                  </button>
                </div>
                <div className={'col-md-6'}>
                  <button
                    className={'btn btn-success w-100'}
                    onClick={() => {
                      setLoading(true)
                      setShowDeletePopup(false)
                      deleteTransport(selectedTransportId)
                    }}
                  >
                    {intl.formatMessage({id: 'global.approve'})}
                  </button>
                </div>
              </div>
            </div>
          }
        />
      )}
    </>
  )
}
export {Transports}
