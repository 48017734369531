import React, {useState} from 'react';
import './DetailedList.scss'
import {IDetailedList} from "./types";
import DetailedListItem from "../DetailedListItem/DetailedListItem";
import {useIntl} from "react-intl";

const DetailedList = ({data, selectedItem, id}: IDetailedList) => {
    const intl = useIntl();
    const [activeItem, setActiveItem] = useState<number>(-1);
    return <div className={'detailed-list-main'} id={id}>
        {data ? data?.map((item: any) => <div className={'item'} key={item?.id}>
            <DetailedListItem
                data={item?.data}
                title={item?.title}
                subTitle={item?.subTitle}
                stickColor={item?.stickColor}
                active={item?.id === activeItem}
                onClick={() => {
                    setActiveItem(item?.id)
                    selectedItem && selectedItem(item?.id);
                }}
            />
        </div>) : <p>{intl.formatMessage({id: "DetailedList.noAnyData"})}</p>}
    </div>;
};

export default DetailedList;
