import React, {useState} from 'react'
import {useIntl} from 'react-intl'
import Multiselect from 'multiselect-react-dropdown'
import {format} from 'date-fns'
import clsx from 'clsx'

import TabManagerComponent from '../../../../Components/TabManager/TabManagerComponent'
import DataTableAdvanced from '../../../../Components/DataTableAdvanced'
import RightLayoutPopup from '../../../../Components/RightLayoutPopup'
import Dropbox from '../../../../Components/Dropbox'
import DatePicker from '../../../../Components/DatePicker'

import {exceptThisSymbols} from '../../../../../utils/common'

const PublishPartialFreightsList = ({
  onClose,
  advertDetailedList,
  selectedFreightDetails,
  transportTypeList,
  freightTypes,
  freightContentTypeList,
  currencyList,
  fromCountryList,
  fromCityList,
  toCountryList,
  toCityList,
  transporterGroupUserList,
  ...restProps
}: any) => {
  const intl = useIntl()

  const [disabledGroup, setDisabledGroup] = useState<boolean>(true)

  const firstStepContent = () => (
    <div className='row mb-10' key={selectedFreightDetails?.freightId}>
      <div className='col-md-4 mb-5'>
        <h6 className={'mb-3'}>{intl.formatMessage({id: 'Freight.myAdvert.transportType'})}</h6>
        <div className={''}>
          <Dropbox
            key={restProps.transportTypeId}
            hideArrow
            customClass={'form-select'}
            placeholder={intl.formatMessage({id: 'Freight.myAdvert.transportType'})}
            customSearchValue={restProps.transportTypeSearch}
            customOnSearch={(e: any) => {
              restProps.setTransportTypePageIndex(0)
              restProps.setTransportTypeSearch(e.target.value)
            }}
            onScroll={(val: any) => {
              restProps.setTransportTypePageIndex(val)
            }}
            defaultSelected={{
              id: restProps?.transportTypeId,
              title: restProps?.transportTypeName,
            }}
            defaultClearItem={{
              id: '',
              title: '',
            }}
            currentPageIndex={restProps.transportTypePageIndex}
            menuList={
              transportTypeList &&
              transportTypeList
                ?.filter(function ({transportTypeId}: any) {
                  // @ts-ignore
                  return !this[transportTypeId] && (this[transportTypeId] = transportTypeId)
                }, {})
                ?.map((item: any) => {
                  return {id: item?.transportTypeId, title: item?.description}
                })
            }
            selectedItemData={(item: any) => {
              if (item?.id) {
                restProps.setTransportTypeId(item?.id)
                restProps.setTransportTypeName(item?.title)
              }

              if (item?.id === '' && item?.title === '') {
                restProps.setTransportTypeId('')
                restProps.setTransportTypeName('')
              }
            }}
          />
        </div>
      </div>
      <div className='col-md-4 mb-5'>
        <h6 className={'mb-3'}>
          {intl.formatMessage({id: 'Freight.createAdvert.freightContentType'})}
        </h6>
        <div className={''}>
          <Dropbox
            key={restProps.freightContentTypeId}
            hideArrow
            customClass={'form-select'}
            placeholder={intl.formatMessage({id: 'Freight.createAdvert.freightContentType'})}
            customSearchValue={restProps.freightContentTypeSearch}
            customOnSearch={(e: any) => {
              restProps.setFreightContentTypePageIndex(0)
              restProps.setFreightContentTypeSearch(e.target.value)
            }}
            onScroll={(val: any) => {
              restProps.setFreightContentTypePageIndex(val)
            }}
            currentPageIndex={restProps.freightContentTypePageIndex}
            defaultSelected={{
              id: restProps?.freightContentTypeId,
              title: restProps?.freightContentTypeName,
            }}
            menuList={
              freightContentTypeList &&
              freightContentTypeList
                ?.filter(function ({freightContentTypeId}: any) {
                  return (
                    // @ts-ignore
                    !this[freightContentTypeId] &&
                    // @ts-ignore
                    (this[freightContentTypeId] = freightContentTypeId)
                  )
                }, {})
                ?.map((item: any) => {
                  return {id: item?.freightContentTypeId, title: item?.description}
                })
            }
            defaultClearItem={{
              id: '',
              title: '',
            }}
            selectedItemData={(item: any) => {
              if (item?.id) {
                restProps.setFreightContentTypeId(item?.id)
                restProps.setFreightContentTypeName(item?.title)
              }

              if (item?.id === '' && item?.title === '') {
                restProps.setFreightContentTypeId('')
                restProps.setFreightContentTypeName('')
              }
            }}
          />
        </div>
      </div>

      <div className='col-md-4 mb-5'>
        <label className='form-label fs-6 fw-bolder text-dark'>
          {intl.formatMessage({id: 'Carrier.freightPosting.description'})}
        </label>
        <input
          placeholder={intl.formatMessage({id: 'Carrier.freightPosting.description'})}
          className={'form-control form-control-lg bg-white'}
          onChange={(event: any) => restProps.setDescription(event.target.value)}
          value={restProps?.description}
          type='text'
          autoComplete='off'
        />
      </div>
      <div className='mb-5 col-md-3'>
        <h6 className={'mb-3'}>
          {intl.formatMessage({id: 'addNewAddressPopup.area2.fromCountry'})}
        </h6>
        <div className={''}>
          <Dropbox
            key={restProps?.fromCountryId}
            hideArrow
            customClass={'form-select'}
            placeholder={intl.formatMessage({
              id: 'addNewAddressPopup.area2.country.placeholder',
            })}
            customSearchValue={restProps.fromCountrySearch}
            customOnSearch={(e: any) => {
              restProps.setFromCountrySearch(e.target.value)
            }}
            setScrollCalled={() => {}}
            onScroll={() => {}}
            currentPageIndex={restProps.fromCountryPageIndex}
            menuList={
              fromCountryList &&
              fromCountryList
                ?.filter(function ({code}: any) {
                  // @ts-ignore
                  return !this[code] && (this[code] = code)
                }, {})
                ?.map((item: any) => {
                  return {id: item?.code, title: item?.name}
                })
            }
            defaultSelected={{
              id: restProps?.fromCountryId,
              title: restProps?.fromCountryName,
            }}
            defaultClearItem={{
              id: 'all',
              title: intl.formatMessage({id: 'Admin.Adverts.all'}),
            }}
            selectedItemData={(item: any) => {
              if (item?.id) {
                restProps.setFromCountryId(item?.id)
                restProps.setFromCountryName(item?.title)
                restProps.setFromCityId('')
                restProps.setFromCityName('')
                restProps.setDependentFromCity(true)
              }
            }}
          />
        </div>
      </div>
      <div className='mb-5 col-md-3'>
        <h6 className={'mb-3'}>{intl.formatMessage({id: 'addNewAddressPopup.area2.fromCity'})}</h6>
        <div className={''}>
          <Dropbox
            key={restProps?.fromCityId}
            hideArrow
            disabled={!restProps.fromCountryId || restProps.fromCountryId === 'all'}
            customClass={'form-select'}
            placeholder={intl.formatMessage({id: 'addNewAddressPopup.area2.city.placeholder'})}
            customSearchValue={restProps.fromCitySearch}
            customOnSearch={(e: any) => {
              restProps.setFromCitySearch(e.target.value)
            }}
            setScrollCalled={() => {}}
            onScroll={() => {}}
            currentPageIndex={restProps.fromCityPageIndex}
            defaultSelected={{
              id: restProps?.fromCityId,
              title: restProps?.fromCityName,
            }}
            menuList={
              fromCityList &&
              fromCityList
                ?.filter(function ({cityId}: any) {
                  // @ts-ignore
                  return !this[cityId] && (this[cityId] = cityId)
                }, {})
                ?.map((item: any) => {
                  return {id: item?.cityId, title: item?.name}
                })
            }
            defaultClearItem={{
              id: 'all',
              title: intl.formatMessage({id: 'Admin.Adverts.all'}),
            }}
            dependentValue={restProps.dependentFromCity}
            selectedItemData={(item: any) => {
              if (item?.id) {
                restProps.setFromCityId(item?.id)
                restProps.setFromCityName(item?.title)
                restProps.setDependentFromCity(false)
              }
            }}
          />
        </div>
      </div>
      <div className='mb-5 col-md-3'>
        <h6 className={'mb-3'}>{intl.formatMessage({id: 'addNewAddressPopup.area2.toCountry'})}</h6>
        <div className={''}>
          <Dropbox
            key={restProps?.toCountryId}
            hideArrow
            customClass={'form-select'}
            placeholder={intl.formatMessage({
              id: 'addNewAddressPopup.area2.country.placeholder',
            })}
            customSearchValue={restProps.toCountrySearch}
            customOnSearch={(e: any) => {
              restProps.setToCountrySearch(e.target.value)
            }}
            setScrollCalled={() => {}}
            onScroll={() => {}}
            defaultSelected={{
              id: restProps?.toCountryId,
              title: restProps?.toCountryName,
            }}
            defaultClearItem={{
              id: 'all',
              title: intl.formatMessage({id: 'Admin.Adverts.all'}),
            }}
            currentPageIndex={restProps.toCountryPageIndex}
            menuList={
              toCountryList &&
              toCountryList
                ?.filter(function ({code}: any) {
                  // @ts-ignore
                  return !this[code] && (this[code] = code)
                }, {})
                ?.map((item: any) => {
                  return {id: item?.code, title: item?.name}
                })
            }
            selectedItemData={(item: any) => {
              if (item?.id) {
                restProps.setToCountryId(item?.id)
                restProps.setToCountryName(item?.title)
                restProps.setDependentToCity(true)
                restProps.setToCityId('')
                restProps.setToCityName('')
              }
            }}
          />
        </div>
      </div>
      <div className='mb-5 col-md-3'>
        <h6 className={'mb-3'}>{intl.formatMessage({id: 'addNewAddressPopup.area2.toCity'})}</h6>
        <div className={''}>
          <Dropbox
            key={restProps?.toCityId}
            hideArrow
            disabled={!restProps.toCountryId || restProps.toCountryId === 'all'}
            customClass={'form-select'}
            placeholder={intl.formatMessage({id: 'addNewAddressPopup.area2.city.placeholder'})}
            customSearchValue={restProps.toCitySearch}
            customOnSearch={(e: any) => {
              restProps.setToCitySearch(e.target.value)
            }}
            setScrollCalled={() => {}}
            onScroll={() => {}}
            currentPageIndex={restProps.toCityPageIndex}
            dependentValue={restProps.dependentToCity}
            defaultSelected={{
              id: restProps?.toCityId,
              title: restProps?.toCityName,
            }}
            menuList={
              toCityList &&
              toCityList
                ?.filter(function ({cityId}: any) {
                  // @ts-ignore
                  return !this[cityId] && (this[cityId] = cityId)
                }, {})
                ?.map((item: any) => {
                  return {id: item?.cityId, title: item?.name}
                })
            }
            selectedItemData={(item: any) => {
              if (item?.id) {
                restProps.setToCityId(item?.id)
                restProps.setToCityName(item?.title)
                restProps.setDependentToCity(false)
              }
            }}
          />
        </div>
      </div>
      <div className='col-md-4 mb-5'>
        <label className='form-label fs-6 fw-bolder text-dark'>
          {intl.formatMessage({id: 'Carrier.freightPosting.price'})}
        </label>
        <input
          type='number'
          id={'targetCost'}
          onKeyDown={(e) => exceptThisSymbols.includes(e.key) && e.preventDefault()}
          placeholder={intl.formatMessage({id: 'Carrier.freightPosting.price'})}
          value={restProps.targetCost}
          onChange={(e) => restProps.setTargetCost(e.target.value)}
          min={0}
          className={clsx('form-control', {
            'price-input-wrap': restProps.targetCost,
          })}
        />
      </div>

      <div className={'col-md-4 mb-5'}>
        <label className={'form-label fw-bolder text-dark fs-6 mb-2'}>
          {intl.formatMessage({id: 'Admin.Adverts.currency'})}
        </label>
        <Dropbox
          hideArrow
          customClass={'form-select'}
          placeholder={intl.formatMessage({id: 'Admin.Adverts.currency'})}
          customSearchValue={''}
          customOnSearch={(e: any) => {}}
          onScroll={() => {}}
          setScrollCalled={() => {}}
          currentPageIndex={0}
          defaultSelected={{
            id: restProps?.selectedCurrency,
            title: restProps?.selectedCurrencyName,
          }}
          defaultClearItem={{
            id: 'all',
            title: intl.formatMessage({id: 'Admin.Adverts.all'}),
          }}
          menuList={
            currencyList &&
            currencyList.map((item: any) => {
              return {
                id: item?.currencyId,
                title: item?.description,
              }
            })
          }
          selectedItemData={(dataItem: any) => {
            if (dataItem?.id) {
              restProps.setSelectedCurrency(dataItem?.id)
              restProps.setSelectedCurrencyName(dataItem?.title)
            }
          }}
        />
      </div>

      <div className='mb-5 col-md-4'>
        <label className='form-label fs-6 fw-bolder text-dark'>
          {intl.formatMessage({id: 'Carrier.advert.partialFreight.plannedDepartureDate'})}
        </label>
        <DatePicker
          value={restProps.plannedDepartureDate}
          onSelect={(data: any) => {
            restProps.setPlannedDepartureDate(data)
            restProps.setLoadPlannedDepartureDate(format(data, 'yyyy-MM-dd'))
          }}
          selected={!!restProps.loadPannedDepartureDate}
          customDate={restProps.plannedDepartureDate}
          disabledDays={new Date()}
        />
      </div>
      <DataTableAdvanced
        columns={[
          {
            Header: 'ID',
            accessor: 'id',
          },
          {
            Header: '',
            accessor: 'removeFreightList',
            isAction: true,
            Cell: ({row}: any) => (
              <button
                className={'btn btn-primary'}
                onClick={() => {
                  restProps.setSelectedPublishFreightList(
                    advertDetailedList.filter(
                      (dataItem: any) => dataItem?.freightId !== row?.values?.id
                    )
                  )
                  restProps.setSelectedPartialPublicFreightList(row?.values?.id)

                  restProps.getAdvertList(row?.values?.id)
                }}
              >
                {intl.formatMessage({id: 'Carrier.freight.partialFreight.remove'})}
              </button>
            ),
          },
          {
            Header: intl.formatMessage({id: 'Freight.createAdvert.freightContentType'}),
            accessor: 'freightContentType',
          },
          {
            Header: intl.formatMessage({id: 'Freight.createAdvert.preview.freightType'}),
            accessor: 'freightType',
          },
          {
            Header: intl.formatMessage({id: 'Admin.Adverts.table_company'}),
            accessor: 'company',
          },
          {
            Header: intl.formatMessage({id: 'Freight.myAdvert.from'}),
            accessor: 'fromLocation',
          },
          {
            Header: intl.formatMessage({id: 'Freight.myAdvert.to'}),
            accessor: 'toLocation',
          },
          {
            Header: intl.formatMessage({id: 'Admin.Adverts.table_weight'}),
            accessor: 'weight',
          },
        ]}
        data={
          advertDetailedList &&
          advertDetailedList.map((item: any) => {
            return {
              id: item?.freightId,
              freightContentType: item?.freightContentType?.description,
              freightType: item?.freightType?.description,
              company: item?.company?.name,
              fromLocation:
                item?.fromAddress?.city?.name + ' / ' + item?.fromAddress?.country?.name,
              toLocation: item?.toAddress?.city?.name + ' / ' + item?.toAddress?.country?.name,
              weight: item?.weight,
              removeFreightList: item,
            }
          })
        }
        pageMIndex={0}
      />
    </div>
  )

  const secondStepContent = () => (
    <div className='row mb-10'>
      <div className={'col-md-6 d-flex align-items-end mb-5'}>
        <div className='form-check d-flex align-items-center'>
          <div>
            <input
              type='checkbox'
              id={'adr1'}
              onChange={() => restProps.setPublicFreightExchange(!restProps.publicFreightExchange)}
              checked={restProps.publicFreightExchange}
              className={'form-check-input'}
            />
          </div>
          <label htmlFor='adr1' className={'text-muted fs-4 ml-3 form-check-label'}>
            {intl.formatMessage({id: 'Carrier.advert.publishToPublishFreight'})}
          </label>
        </div>
      </div>
      <div className={'col-md-6 d-flex align-items-end mb-5'}>
        <div className='form-check d-flex align-items-center'>
          <div>
            <input
              type='checkbox'
              id={'adr1'}
              onChange={() => {
                restProps.setPrivateFreightExchange(!restProps.privateFreightExchange)
                setDisabledGroup(!disabledGroup)
              }}
              checked={restProps.privateFreightExchange}
              className={'form-check-input'}
            />
          </div>
          <label htmlFor='adr1' className={'text-muted fs-4 ml-3 form-check-label'}>
            {intl.formatMessage({id: 'Carrier.advert.publishToPrivateFreight'})}
          </label>
        </div>
      </div>
      <div className='col-md-6 mb-5'>
        <label htmlFor='publishDate' className={'form-label fw-bolder text-dark fs-6 mb-2'}>
          {intl.formatMessage({id: 'Carrier.transporterGroup.publish.group'})}
        </label>
        <Multiselect
          options={transporterGroupUserList}
          selectedValues={restProps.selectedTransporterGroup}
          onSelect={(value: any) => restProps.setSelectedTransporterGroup(value)}
          onRemove={(value: any) => restProps.setSelectedTransporterGroup(value)}
          displayValue='name'
          disable={disabledGroup}
          className='publish-group-wrap'
          style={{
            inputField: {
              minHeight: '27px',
              height: 'auto',
            },
          }}
        />
      </div>
      <div className='mb-5 col-md-6'>
        <label className='form-label fs-6 fw-bolder text-dark'>
          {intl.formatMessage({id: 'CreateDocument.dataTable.issueDate'})}
        </label>
        <DatePicker
          value={restProps.issueDate}
          onSelect={(data: any) => {
            restProps.setIssueDate(data)
            restProps.setLoadIssueDate(format(data, 'yyyy-MM-dd'))
          }}
          selected={!!restProps.loadIssueDate}
          customDate={restProps.issueDate}
          disabledDays={new Date()}
        />
      </div>
    </div>
  )

  return (
    <RightLayoutPopup
      title={intl.formatMessage({id: 'Carrier.advert.partialFreight'})}
      className={`right-layout-sub-show-wrap ${
        advertDetailedList.length < 1 ? 'next-btn-publish-freight-wrap' : ''
      }`}
      mainClassName='right-layout-main-show-wrap'
      isCloseIcon
      closeOnClick={() => onClose()}
    >
      <TabManagerComponent
        onSubmit={() => restProps.createAndPublishPartialContainer()}
        showCancelButton
        handleCancel={() => {
          onClose()
        }}
        checkValidation={() => {}}
        submitBtnText={intl.formatMessage({id: 'Carrier.drivers.create.create'})}
        defaultStep={0}
        data={[
          {
            disabled: advertDetailedList.length < 1,
            content: firstStepContent(),
          },
          {
            content: secondStepContent(),
          },
        ]}
      />
    </RightLayoutPopup>
  )
}

export default PublishPartialFreightsList
