import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'

import RightLayoutPopup from '../../../../Components/RightLayoutPopup'
import PhoneInp from '../../../../Components/PhoneInp/PhoneInp'

import useScrollLock from '../../../../../utils/useScrollLock'
import {generatePassword} from '../../../../../utils/common'

const EditUser = ({
  setLoading,
  selectedUserData,
  onClose,
  updateUserDetails,
  selectedUserId,
}: any) => {
  const intl = useIntl()
  const {scrollLock, scrollUnlock} = useScrollLock()

  const [name, setName] = useState<string>('')
  const [surName, setSurName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [phoneCode, setPhoneCode] = useState<string>('')
  const [mobile, setMobile] = useState<string>('')
  const [password, setPassword] = useState<string>('')

  useEffect(() => {
    scrollLock()
    if (selectedUserData) {
      setName(selectedUserData.name || '')
      setSurName(selectedUserData.surname || '')
      setEmail(selectedUserData.email || '')
      setPhoneCode(selectedUserData.countryCode || '')
      setMobile(selectedUserData.mobile || '')
    }
  }, [])

  return (
    <RightLayoutPopup
      title={intl.formatMessage({id: 'Admin.company.user.update.title'})}
      className='admin-details-company-wrap'
      isCloseIcon
      closeOnClick={() => onClose()}
    >
      <div className='row'>
        <div className='col-md-6 fv-row mb-10'>
          <label className='form-label fs-6 fw-bolder text-dark'>
            {intl.formatMessage({id: 'Freight.users.name'})}
          </label>
          <input
            placeholder={'Name'}
            className={'form-control form-control-lg form-control-solid'}
            onChange={(event: any) => setName(event.target.value)}
            value={name}
            type='text'
            name='name'
            autoComplete='off'
          />
        </div>
        <div className='col-md-6 fv-row mb-10'>
          <label className='form-label fs-6 fw-bolder text-dark'>
            {intl.formatMessage({id: 'Freight.users.surName'})}
          </label>
          <input
            placeholder={'SurName'}
            className={'form-control form-control-lg form-control-solid'}
            onChange={(event: any) => setSurName(event.target.value)}
            value={surName}
            type='text'
            name='name'
            autoComplete='off'
          />
        </div>
      </div>

      <div className='row'>
        <div className={'row col-md-6 mb-10'}>
          <div className={'col-md-4'}>
            <label
              htmlFor={'phoneCode'}
              className='class="form-label fw-bolder text-dark fs-6 mb-2'
            >
              {intl.formatMessage({id: 'Carrier.drivers.create.phoneCode'})}
            </label>
            <PhoneInp
              id={'phoneCode'}
              defaultValue={phoneCode}
              onSelect={(event: any) => {
                setPhoneCode(event.length > 0 ? '+' + event : event)
              }}
            />
          </div>
          <div className='fv-row col-md-8'>
            <label className='form-label fs-6 fw-bolder text-dark'>
              {intl.formatMessage({id: 'Carrier.drivers.create.phone'})}
            </label>
            <input
              placeholder={intl.formatMessage({id: 'Carrier.drivers.create.phone.placeHolder'})}
              maxLength={13}
              className={'form-control form-control-lg form-control-solid'}
              onChange={(event: any) => {
                if (event.target.value.match(/^\+?(?:[\d]*)$/)) {
                  setMobile(event.target.value)
                }
              }}
              value={mobile}
              type='text'
              name='mobile'
              autoComplete='off'
              required
            />
          </div>
        </div>

        <div className='col-md-6 fv-row mb-10'>
          <label className='form-label fs-6 fw-bolder text-dark'>
            {intl.formatMessage({id: 'CreateClient.create.email'})}
          </label>
          <input
            placeholder={intl.formatMessage({id: 'CreateClient.create.email'})}
            className={'form-control form-control-lg form-control-solid'}
            onChange={(event: any) => setEmail(event.target.value)}
            value={email}
            type='text'
            name='name'
            autoComplete='off'
          />
        </div>
      </div>

      <div className='row'>
        <div className='col-md-6 mb-10'>
          <label className='form-label fs-6 fw-bolder text-dark'>
            {intl.formatMessage({id: 'Admin.company.user.defaultPassword'})}
          </label>
          <input
            placeholder={intl.formatMessage({id: 'Admin.company.user.defaultPassword'})}
            className={'form-control form-control-lg form-control-solid'}
            onChange={(event: any) => setPassword(event.target.value)}
            value={password}
            type='text'
            name='password'
            autoComplete='off'
          />
        </div>
        <div className='col-md-3 mb-2 d-flex align-items-center'>
          <button
            onClick={() => {
              setPassword(generatePassword())
            }}
            type='button'
            className='btn btn-lg btn-primary'
          >
            {intl.formatMessage({id: 'Admin.company.user.generatePassword'})}
          </button>
        </div>
      </div>
      <div className='text-center mt-5 row freightPost-popup-container'>
        <div className={'col-md-6'}>
          <button
            onClick={() => {
              onClose()
              scrollUnlock()
            }}
            type='button'
            id='kt_sign_up_submit'
            className='btn btn-lg btn-danger w-100 mb-5 '
          >
            {intl.formatMessage({id: 'CreateNewContainerPopup.cancel'})}
          </button>
        </div>
        <div className={'col-md-6'}>
          <button
            className={'btn btn-success w-100'}
            onClick={() => {
              setLoading(true)
              updateUserDetails({
                userId: selectedUserId,
                name,
                surname: surName,
                countryCode: phoneCode,
                mobile,
                email,
                initialPassword: password,
              })
              onClose()
            }}
          >
            {intl.formatMessage({id: 'global.update'})}
          </button>
        </div>
      </div>
    </RightLayoutPopup>
  )
}

export default EditUser
