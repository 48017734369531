import React, {createContext, useContext, useState, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {useLocation} from 'react-router'
import {shallowEqual, useSelector} from 'react-redux'
import {toast} from 'react-toastify'
import {RootState} from '../../../setup'
import {DefaultLayoutConfig} from './DefaultLayoutConfig'
import {
  getEmptyCssClasses,
  getEmptyCSSVariables,
  getEmptyHTMLAttributes,
  LayoutSetup,
} from './LayoutSetup'
import {
  ILayout,
  ILayoutCSSVariables,
  ILayoutCSSClasses,
  ILayoutHTMLAttributes,
} from './LayoutModels'
import {ListingService} from '../../../services/FreightOperatorServices/ListingService'
import AdvertisementPopup from '../../../app/Components/AdvertisementPopup/AdvertisementPopup'
import Modal from '../../../app/Components/Modal'
export interface LayoutContextModel {
  config: ILayout
  classes: ILayoutCSSClasses
  attributes: ILayoutHTMLAttributes
  cssVariables: ILayoutCSSVariables
  setLayout: (config: LayoutSetup) => void
}

const LayoutContext = createContext<LayoutContextModel>({
  config: DefaultLayoutConfig,
  classes: getEmptyCssClasses(),
  attributes: getEmptyHTMLAttributes(),
  cssVariables: getEmptyCSSVariables(),
  setLayout: (config: LayoutSetup) => {},
})

const enableSplashScreen = () => {
  const splashScreen = document.getElementById('splash-screen')
  if (splashScreen) {
    splashScreen.style.setProperty('display', 'flex')
  }
}

const disableSplashScreen = () => {
  const splashScreen = document.getElementById('splash-screen')
  if (splashScreen) {
    splashScreen.style.setProperty('display', 'none')
  }
}

const excludeAdvertisement: any = ['/set-password']

const LayoutProvider: React.FC = ({children}) => {
  const intl = useIntl()
  const location = useLocation()
  const isAuthorized = useSelector<RootState>(({auth}) => auth.user, shallowEqual)
  const [showAdvertisement, setShowAdvertisement] = useState<boolean>(true)
  const [config, setConfig] = useState(LayoutSetup.config)
  const [classes, setClasses] = useState(LayoutSetup.classes)
  const [attributes, setAttributes] = useState(LayoutSetup.attributes)
  const [cssVariables, setCSSVariables] = useState(LayoutSetup.cssVariables)
  const setLayout = (_themeConfig: Partial<ILayout>) => {
    enableSplashScreen()
    const bodyClasses = Array.from(document.body.classList)
    bodyClasses.forEach((cl) => document.body.classList.remove(cl))
    LayoutSetup.updatePartialConfig(_themeConfig)
    setConfig(Object.assign({}, LayoutSetup.config))
    setClasses(LayoutSetup.classes)
    setAttributes(LayoutSetup.attributes)
    setCSSVariables(LayoutSetup.cssVariables)
    setTimeout(() => {
      disableSplashScreen()
    }, 500)
  }
  const value: LayoutContextModel = {
    config,
    classes,
    attributes,
    cssVariables,
    setLayout,
  }

  const listingServ = new ListingService()

  const [advertisementListData, setAdevrtisementListData] = useState<any>([])

  const [showBanner, setShowBanner] = useState(false)
  const [isAndroid, setIsAndroid] = useState(false)

  const advertisementList = (isAuthorized?: boolean) => {
    if (isAuthorized) {
      listingServ
        .getAdvertisementDisplayList({advertisementTypeId: '1'})
        .then((resp: any) => {
          setAdevrtisementListData(resp?.data)
        })
        .catch((e) => {
          toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
        })
    } else {
      listingServ
        .getPublicAdvertisementDisplayList({advertisementTypeId: '1'})
        .then((resp: any) => {
          setAdevrtisementListData(resp?.data)
        })
        .catch((e) => {
          toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
        })
    }
  }

  useEffect(() => {
    advertisementList(!!isAuthorized)
    disableSplashScreen()
  }, [])

  const isMobile = () => {
    //@ts-ignore
    const userAgent = navigator.userAgent || navigator.vendor || window.opera
    if (/iPad|iPhone|iPod/.test(userAgent) || /android/.test(userAgent.toLowerCase())) {
      return true
    }
    return false
  }

  const openAppOrRedirect = (appLink: any, fallbackLink: any) => {
    window.location.href = appLink

    // Use a timeout to determine if the app is installed
    const start = Date.now()
    setTimeout(() => {
      if (Date.now() - start < 3000) {
        // If the page didn't navigate, redirect to the store
        window.location.href = fallbackLink
      }
    }, 1000)
  }

  useEffect(() => {
    if (isMobile()) {
      //@ts-ignore
      const userAgent = navigator.userAgent || navigator.vendor || window.opera
      setShowBanner(true)
      if (/iPad|iPhone|iPod/.test(userAgent)) {
        setIsAndroid(false)
      } else if (/android/.test(userAgent.toLowerCase())) {
        setIsAndroid(true)
      }
    }
  }, [])

  const handleBannerClick = () => {
    // Define the custom URL schemes and fallback links
    const iosAppLink = 'rivalog://home' // Replace with your custom iOS URL scheme
    const androidAppLink = 'intent://home#Intent;scheme=rivalog;package=com.iwa.logistics;end' // Replace with your custom Android intent URL
    const iosFallbackLink = 'https://apps.apple.com/us/app/rivalog/id1613475053'
    const androidFallbackLink = 'https://play.google.com/store/apps/details?id=com.iwa.logistics'

    // Detect device type and set the appropriate app and fallback links
    //@ts-ignore
    const userAgent = navigator.userAgent || navigator.vendor || window.opera
    let appLink = ''
    let fallbackLink = ''

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      appLink = iosAppLink
      fallbackLink = iosFallbackLink
    } else if (/android/.test(userAgent.toLowerCase())) {
      appLink = androidAppLink
      fallbackLink = androidFallbackLink
    }

    setShowBanner(false)

    openAppOrRedirect(appLink, fallbackLink)
  }

  return (
    <LayoutContext.Provider value={value}>
      <>
        {advertisementListData?.length > 0 &&
          showAdvertisement &&
          !excludeAdvertisement.includes(location?.pathname) && (
            <AdvertisementPopup
              url={advertisementListData[0]?.defaultImage}
              targetUrl={advertisementListData[0]?.targetUrl}
              onClose={() => setShowAdvertisement(false)}
            />
          )}

        {showBanner && (
          <Modal
            closeBtnSettings={{
              show: true,
              onClick: () => setShowBanner(false),
            }}
            customAll={
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                onClick={handleBannerClick}
              >
                <div style={{fontSize: '16px', fontWeight: 'bold', marginBottom: '5px'}}>
                  {intl.formatMessage({id: 'mobile.redirection.app'})}
                </div>
                <img
                  src={isAndroid ? '/media/google-play.png' : '/media/app-store.png'}
                  alt='App Icon'
                  className='app-icon'
                  style={{width: '100%', maxWidth: '100px'}}
                />
              </div>
            }
          />
        )}

        {children}
      </>
    </LayoutContext.Provider>
  )
}

export {LayoutContext, LayoutProvider}

export function useLayout() {
  return useContext(LayoutContext)
}
