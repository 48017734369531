import React, {useState, useEffect} from 'react'
import {useIntl} from 'react-intl'
import clsx from 'clsx'

import Dropbox from '../../../../Components/Dropbox'
import RightLayoutPopup from '../../../../Components/RightLayoutPopup'

import useScrollLock from '../../../../../utils/useScrollLock'

const CreateVehicle = ({createVehicle, vehicleTypes, onClose}: any) => {
  const intl = useIntl()
  const {scrollLock} = useScrollLock()
  useEffect(() => {
    scrollLock()
  }, [])

  const [newVehicle, setNewVehicle] = useState<any>({
    year: '',
    plate: '',
    /* capacity: 1,*/
    make: '',
    model: '',
    vehicleType: '',
  })

  return (
    <>
      <RightLayoutPopup
        title={intl.formatMessage({id: 'Carrier.vehicles.create.title'})}
        className='carrier-create-vehicle-side-wrap'
        isCloseIcon
        closeOnClick={() => onClose()}
      >
        <div className={''}>
          <div className={'row'}>
            <div className='fv-row col-md-6 mb-10'>
              <label className='form-label fs-6 fw-bolder text-dark '>
                {intl.formatMessage({id: 'Carrier.vehicles.create.year'})}
              </label>
              <input
                placeholder={intl.formatMessage({id: 'Carrier.vehicles.create.year.placeHolder'})}
                className={clsx('form-control form-control-lg form-control-solid')}
                onChange={(event: any) =>
                  setNewVehicle((item: any) => ({
                    ...item,
                    year: event.target.value,
                  }))
                }
                value={newVehicle.year}
                type='text'
                name='year'
                autoComplete='off'
              />
            </div>
            <div className='fv-row col-md-6 mb-10'>
              <label className='form-label fs-6 fw-bolder text-dark required'>
                {intl.formatMessage({id: 'Carrier.vehicles.create.numberPlate'})}
              </label>
              <input
                placeholder={intl.formatMessage({
                  id: 'Carrier.vehicles.create.numberPlate.placeHolder',
                })}
                className={clsx('form-control form-control-lg form-control-solid')}
                onChange={(event: any) =>
                  setNewVehicle((item: any) => ({
                    ...item,
                    plate: event.target.value,
                  }))
                }
                value={newVehicle.plate}
                type='text'
                name='plate'
                autoComplete='off'
              />
            </div>
            <div className='fv-row col-md-6 mb-10'>
              <label className='form-label fs-6 fw-bolder text-dark'>
                {intl.formatMessage({id: 'Carrier.vehicles.create.brand'})}
              </label>
              <input
                placeholder={intl.formatMessage({id: 'Carrier.vehicles.create.brand.placeHolder'})}
                className={clsx('form-control form-control-lg form-control-solid')}
                onChange={(event: any) =>
                  setNewVehicle((item: any) => ({
                    ...item,
                    make: event.target.value,
                  }))
                }
                value={newVehicle.make}
                type='text'
                name='make'
                autoComplete='off'
              />
            </div>
            <div className='fv-row col-md-6 mb-10'>
              <label className='form-label fs-6 fw-bolder text-dark'>
                {intl.formatMessage({id: 'Carrier.vehicles.create.model'})}
              </label>
              <input
                placeholder={intl.formatMessage({id: 'Carrier.vehicles.create.model.placeHolder'})}
                className={clsx('form-control form-control-lg form-control-solid')}
                onChange={(event: any) =>
                  setNewVehicle((item: any) => ({
                    ...item,
                    model: event.target.value,
                  }))
                }
                value={newVehicle.model}
                type='text'
                name='model'
                autoComplete='off'
              />
            </div>
            <div className={'fv-row col-md-6 mb-10'}>
              <label className='form-label fs-6 fw-bolder text-dark required'>
                {intl.formatMessage({id: 'Carrier.vehicles.create.vehicleType'})}
              </label>
              <Dropbox
                hideArrow
                id={'vehicleType'}
                customClass={'form-select'}
                placeholder={intl.formatMessage({
                  id: 'Carrier.vehicles.create.vehicleType.placeHolder',
                })}
                customSearchValue={''}
                customOnSearch={(e: any) => {}}
                disabledSearch
                onScroll={() => {}}
                setScrollCalled={() => {}}
                currentPageIndex={0}
                menuList={
                  vehicleTypes &&
                  vehicleTypes.map((item: any) => {
                    return {
                      id: item?.vehicleTypeId,
                      title: item?.description,
                    }
                  })
                }
                selectedItemData={(dataItem: any) => {
                  if (dataItem?.id) {
                    setNewVehicle((item: any) => ({
                      ...item,
                      vehicleType: parseInt(dataItem?.id),
                    }))
                  }
                }}
              />
            </div>
          </div>
        </div>
        <div className='text-center mt-5 row freightPost-popup-container'>
          <div className={'col-md-6'}>
            <button
              onClick={onClose}
              type='button'
              id='kt_sign_up_submit'
              className='btn btn-lg btn-danger w-100 mb-5 '
            >
              {intl.formatMessage({id: 'CreateNewContainerPopup.cancel'})}
            </button>
          </div>
          <div className={'col-md-6'}>
            <button
              className={'btn btn-success px-10 w-100'}
              disabled={!newVehicle.plate /*|| !newVehicle.capacity*/ || !newVehicle.vehicleType}
              onClick={(dat: any) => {
                createVehicle(newVehicle)
                setTimeout(
                  () =>
                    setNewVehicle({
                      year: '',
                      plate: '',
                      /*capacity: 1,*/
                      make: '',
                      model: '',
                      vehicleType: 0,
                    }),
                  200
                )
              }}
            >
              {intl.formatMessage({id: 'Carrier.vehicles.create.create'})}
            </button>
          </div>
        </div>
      </RightLayoutPopup>
    </>
  )
}

export default CreateVehicle
