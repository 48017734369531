import {apiUrl} from '../conf'
import axios from 'axios'

export class PreferredRouteService {
  getRoutes(searchText?: string, pageIndex?: number, pageCount?: number) {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        '/preferred-route-management/routes?searchText=' +
        (searchText ?? '') +
        '&pageIndex=' +
        (pageIndex ?? 0) +
        '&pageCount=' +
        (pageCount ?? 10000),
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  getCities(country: string, pageIndex?: number, pageCount?: number, searchText?: string) {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        '/address-management/countries/' +
        country +
        '/cities?searchText=' +
        (searchText ?? '') +
        '&pageIndex=' +
        (pageIndex ?? 0) +
        '&pageCount=' +
        (pageCount ?? 10000),
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  getCountries(pageIndex?: number, pageCount?: number, searchText?: string) {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        '/address-management/countries?searchText=' +
        (searchText ?? '') +
        '&pageIndex=' +
        (pageIndex ?? 0) +
        '&pageCount=' +
        (pageCount ?? 10000),
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  transportTypesList(searchText?: string, pageIndex?: number) {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        `/transport-management/transports/types?searchText=${searchText ?? ''}&pageIndex=${
          pageIndex ?? 0
        }&pageCount=${pageIndex ?? 1000}`,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  activateTheRoute(id: number) {
    const config: any = {
      method: 'PATCH',
      url: apiUrl + '/preferred-route-management/routes/activate/' + id,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  deactivateTheRoute(id: number) {
    const config: any = {
      method: 'PATCH',
      url: apiUrl + '/preferred-route-management/routes/deactivate/' + id,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  deleteRoute(id: number) {
    const config: any = {
      method: 'DELETE',
      url: apiUrl + '/preferred-route-management/routes/' + id,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  updateRoute(
    id: number,
    fromCountry?: string,
    toCountry?: string,
    fromCity?: number,
    toCity?: number,
    transportTypeId?: string
  ) {
    const config: any = {
      method: 'PUT',
      url: apiUrl + '/preferred-route-management/routes/',
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({
        preferredRouteId: id,
        fromCountry: {
          code: fromCountry || '',
        },
        fromCity: {
          cityId: fromCity || '',
        },
        toCountry: {
          code: toCountry || '',
        },
        toCity: {
          cityId: toCity || '',
        },
        transportType: {
          transportTypeId: transportTypeId,
        },
      }),
    }

    return axios(config)
  }

  createRoute(
    fromCountry?: string,
    toCountry?: string,
    fromCity?: number,
    toCity?: number,
    transportTypeId?: string
  ) {
    const config: any = {
      method: 'POST',
      url: apiUrl + '/preferred-route-management/routes/',
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({
        fromCountry: {
          code: fromCountry || '',
        },
        fromCity: {
          cityId: fromCity || '',
        },
        toCountry: {
          code: toCountry || '',
        },
        toCity: {
          cityId: toCity || '',
        },
        transportType: {
          transportTypeId: transportTypeId,
        },
      }),
    }

    return axios(config)
  }

  createPreferredRoute({
    companyId,
    fromCountry,
    toCountry,
    fromCity,
    toCity,
    transportTypeId,
  }: any) {
    const config: any = {
      method: 'POST',
      url: apiUrl + `/preferred-route-management/companies/${companyId}/routes`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({
        fromCountry: {
          code: fromCountry || '',
        },
        fromCity: {
          cityId: fromCity || '',
        },
        toCountry: {
          code: toCountry || '',
        },
        toCity: {
          cityId: toCity || '',
        },
        transportType: {
          transportTypeId: transportTypeId,
        },
      }),
    }

    return axios(config)
  }
}
