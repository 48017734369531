import {apiUrl, globalPageCount} from '../conf'
import axios from 'axios'

export class ClientService {
  getClientList({searchText, pageIndex}: any) {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        `/client-management/clients?searchText=${searchText ?? ''}&pageIndex=${
          pageIndex ?? 0
        }&pageCount=${globalPageCount}`,
      headers: {
        'Content-Type': 'application/json',
      },
    }
    return axios(config)
  }

  getClientTypeList() {
    const config: any = {
      method: 'GET',
      url: apiUrl + `/client-management/clients/types`,
      headers: {
        'Content-Type': 'application/json',
      },
    }
    return axios(config)
  }

  getClientDetailById(clientId?: number) {
    const config: any = {
      method: 'GET',
      url: apiUrl + `/client-management/clients/${clientId}`,
      headers: {
        'Content-Type': 'application/json',
      },
    }
    return axios(config)
  }

  deleteClient(clientId: number) {
    const config: any = {
      method: 'DELETE',
      url: apiUrl + `/client-management/clients/${clientId}`,
      headers: {
        'Content-Type': 'application/json',
      },
    }
    return axios(config)
  }

  createClient({
    name,
    surname,
    identityNumber,
    web,
    countryCode,
    language,
    mobile,
    country,
    clientTypeId,
    email,
    taxNumber,
    taxOffice,
    title,
  }: any) {
    const payLoad = JSON.stringify({
      name: name,
      surname: surname,
      identityNumber: identityNumber,
      web: web,
      phone: mobile,
      language: language,
      country: {
        code: country,
      },
      countryCode: countryCode,
      clientType: {
        clientTypeId: clientTypeId,
      },
      email: email,
      taxNumber: taxNumber,
      taxOffice: taxOffice,
      title: title,
    })

    const config: any = {
      method: 'POST',
      url: apiUrl + '/client-management/clients',
      headers: {
        'Content-Type': 'application/json',
      },
      data: payLoad,
    }

    return axios(config)
  }

  updateClient({
    clientId,
    name,
    surname,
    identityNumber,
    web,
    countryCode,
    language,
    mobile,
    country,
    clientTypeId,
    email,
    taxNumber,
    taxOffice,
    title,
  }: any) {
    const payLoad = JSON.stringify({
      clientId: clientId,
      name: name,
      surname: surname,
      identityNumber: identityNumber,
      web: web,
      phone: mobile,
      language: language,
      country: {
        code: country,
      },
      countryCode: countryCode,
      clientType: {
        clientTypeId: clientTypeId,
      },
      email: email,
      taxNumber: taxNumber,
      taxOffice: taxOffice,
      title: title,
    })

    const config: any = {
      method: 'PUT',
      url: apiUrl + '/client-management/clients',
      headers: {
        'Content-Type': 'application/json',
      },
      data: payLoad,
    }

    return axios(config)
  }
}
