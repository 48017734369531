import {apiUrl, globalPageCount} from '../conf'
import axios from 'axios'

export class DashboardService {
  getStatistics() {
    const config: any = {
      method: 'GET',
      url: apiUrl + '/dashboard-management/portal/items',
      headers: {
        'Content-Type': 'application/json',
      },
    }
    return axios(config)
  }

  getFreightList(data?: any) {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        `/container-management/containers/carriers/posts?pageIndex=${
          data?.pageIndex ?? 0
        }&pageCount=${globalPageCount}` +
        (data?.fromCountryCode ? '&fromCountryCode=' + data?.fromCountryCode : '') +
        (data?.toCountryCode ? '&toCountryCode=' + data?.toCountryCode : '') +
        (data?.fromCityId ? '&fromCityId=' + data?.fromCityId : '') +
        (data?.toCityId ? '&toCityId=' + data?.toCityId : ''),
      headers: {
        'Content-Type': 'application/json',
      },
    }
    return axios(config)
  }

  getFreightInContanerList(containerId?: number) {
    const config: any = {
      method: 'GET',
      url: apiUrl + `/container-management/containers/${containerId}/freights`,
      headers: {
        'Content-Type': 'application/json',
      },
    }
    return axios(config)
  }

  getFreightDetailById(freightId?: number) {
    const config: any = {
      method: 'GET',
      url: apiUrl + `/freight-management/freights/${freightId}`,
      headers: {
        'Content-Type': 'application/json',
      },
    }
    return axios(config)
  }
}
