import React, {useState, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import MultiRangeSlider from 'multi-range-slider-react'
import Multiselect from 'multiselect-react-dropdown'
import _ from 'lodash'

import Dropbox from '../../../../Components/Dropbox'

import {ListingService} from '../../../../../services/FreightOperatorServices/ListingService'
import {CommonService} from '../../../../../services/FreightOperatorServices/CommonService'

const TrailerFilter = ({hadleTrailerFilterData}: any) => {
  const intl = useIntl()
  const listingServ = new ListingService()
  const commonServ = new CommonService()

  const [description, setDescription] = useState<any>('')
  const [actualDescription, setActualDescription] = useState<any>('')
  const [model, setModel] = useState<any>('')

  const [minYearValue, set_minYearValue] = useState(1980)
  const [maxYearValue, set_maxYearValue] = useState(new Date().getFullYear() - 30)
  const [currencyList, setCurrencyList] = useState<any>([])
  const [selectedCurrencyId, setSelectedCurrencyId] = useState<any>('')

  const [brandList, setBrandList] = useState<any>([])
  const [selectedBrandList, setSelectedBrandList] = useState<any>([])

  const [selectedFromFilterCountry, setSelectedFromFilterCountry] = useState<any>('')

  const [fromCountrySearch, setFromCountrySearch] = useState<string>('')
  const [fromCountryPageIndex, setFromCountryPageIndex] = useState<number>(0)
  const [fromCountryId, setFromCountryId] = useState<string>('')

  const [dependentFromCity, setDependentFromCity] = useState<boolean>(false)

  const [fromCitySearch, setFromCitySearch] = useState<string>('')
  const [fromCityPageIndex, setFromCityPageIndex] = useState<number>(0)
  const [fromCityId, setFromCityId] = useState<string>('all')

  const [fromCityList, setFromCityList] = useState<any>([])
  const [fromCountryList, setFromCountryList] = useState<any>([])

  const [fromCountryScrollAPICalled, setFromCountryScrollAPICalled] = useState<boolean>(false)
  const [fromCityScrollAPICalled, setFromCityScrollAPICalled] = useState<boolean>(false)

  const [minPrice, setMinPrice] = useState<number>(225)
  const [maxPrice, setMaxPrice] = useState<number>(725)

  const [minGWRKg, setMinGWRKg] = useState<number>(225)
  const [maxGWRKg, setMaxGWRKg] = useState<number>(725)

  const [minPayload, setMinPayload] = useState<number>(225)
  const [maxPayload, setMaxPayload] = useState<number>(725)

  const handleDescriptionSearch = React.useMemo(
    () =>
      _.debounce((value) => {
        setActualDescription(value)
      }, 1000),
    []
  )

  const handlePriceInput = React.useMemo(
    () =>
      _.debounce((e) => {
        setMinPrice(e.minValue)
        setMaxPrice(e.maxValue)
      }, 1000),
    []
  )

  const handleGWRKgInput = React.useMemo(
    () =>
      _.debounce((e) => {
        setMinGWRKg(e.minValue)
        setMaxGWRKg(e.maxValue)
      }, 1000),
    []
  )

  const handlePayloadInput = React.useMemo(
    () =>
      _.debounce((e) => {
        setMinPayload(e.minValue)
        setMaxPayload(e.maxValue)
      }, 1000),
    []
  )

  const handleYearInput = React.useMemo(
    () =>
      _.debounce((e) => {
        set_minYearValue(e.minValue)
        set_maxYearValue(e.maxValue)
      }, 1000),
    []
  )

  const getCurrencyList = () => {
    listingServ
      .getCurrencyList()
      .then((resp: any) => {
        setCurrencyList(resp?.data)
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const getBrandList = () => {
    listingServ
      .getBrandList()
      .then((resp: any) => {
        setBrandList(resp?.data)
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const getFromCountryList = (pageIndex: number = 0, searchText?: string) => {
    commonServ
      .countrySearchList(searchText ?? '', pageIndex ?? 0)
      .then((resp: any) => {
        setFromCountryScrollAPICalled(false)
        if (pageIndex === 0)
          setFromCountryList(
            [
              {
                code: 'all',
                name: intl.formatMessage({id: 'Admin.Adverts.all'}),
              },
            ].concat(resp?.data?.filter((item: any) => item?.name))
          )
        else setFromCountryList((item: any) => [...item, ...resp?.data])
      })
      .catch((e: any) => {
        setFromCountryScrollAPICalled(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const getFromSearchCityList = (
    countryCode: string,
    pageIndex: number = 0,
    searchText?: string
  ) => {
    commonServ
      .citySearchList(countryCode, searchText ?? '', pageIndex ?? 0)
      .then((resp: any) => {
        setFromCityScrollAPICalled(false)
        if (pageIndex === 0)
          setFromCityList(
            [
              {
                cityId: 'all',
                name: intl.formatMessage({id: 'Admin.Adverts.all'}),
              },
            ].concat(resp?.data?.filter((item: any) => item?.name))
          )
        else setFromCityList((item: any) => [...item, ...resp?.data])
      })
      .catch((e: any) => {
        setFromCityScrollAPICalled(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  useEffect(() => {
    hadleTrailerFilterData({
      description: description,
      startPrice: minPrice,
      endPrice: maxPrice,
      model: model,
      currencyId: selectedCurrencyId,
      country: fromCountryId === 'all' ? '' : fromCountryId,
      cityId: fromCityId === 'all' ? '' : fromCityId,
      brandIdList: selectedBrandList.map((item: any) => item?.brandId).toString(),
      startGwr: minGWRKg,
      endGwr: maxGWRKg,
      startRegistrationYear: minYearValue,
      endRegistrationYear: maxYearValue,
      startPayload: minPayload,
      endPayload: maxPayload,
    })
  }, [
    actualDescription,
    minPrice,
    maxPrice,
    model,
    selectedCurrencyId,
    fromCountryId,
    fromCityId,
    selectedBrandList,
    minGWRKg,
    maxGWRKg,
    minYearValue,
    maxYearValue,
    minPayload,
    maxPayload,
  ])

  useEffect(() => {
    getCurrencyList()
    getBrandList()
    getFromCountryList()
  }, [])

  useEffect(() => {
    fromCountryId && getFromSearchCityList(fromCountryId, 0)
  }, [fromCountryId])

  return (
    <>
      <div className='row'>
        <div className='mb-5 col-md-4'>
          <label className='fs-4 text-muted'>
            {intl.formatMessage({id: 'freight.listing.description'})}
          </label>
          <input
            placeholder={'Description'}
            className={'form-control form-control-lg form-control-solid bg-white'}
            onChange={(event: any) => {
              setDescription(event.target.value)
              handleDescriptionSearch(event.target.value)
            }}
            value={description}
            type='text'
            name='description'
            autoComplete='off'
          />
        </div>

        <div className='mb-5 col-md-4'>
          <label className='fs-4 text-muted'>
            {intl.formatMessage({id: 'freight.listing.price'})}
          </label>
          <MultiRangeSlider
            min={0}
            max={1000}
            step={1}
            ruler={false}
            className='multi-range-wrap'
            style={{border: 'none', boxShadow: 'none'}}
            barInnerColor='#5e72e4'
            barLeftColor='#ddd'
            barRightColor='#ddd'
            minValue={minPrice}
            maxValue={maxPrice}
            onInput={(e) => {
              handlePriceInput(e)
            }}
          />
        </div>

        <div className='mb-5 col-md-4'>
          <label className='fs-4 text-muted'>
            {intl.formatMessage({id: 'freight.listing.model'})}
          </label>
          <input
            placeholder={'model'}
            className={'form-control form-control-lg form-control-solid bg-white'}
            onChange={(event: any) => {
              setModel(event.target.value)
            }}
            value={model}
            type='text'
            name='model'
            autoComplete='off'
          />
        </div>

        <div className={'col-md-4 mb-5'}>
          <label className={'form-label fw-bolder text-dark fs-6 mb-2'}>
            {intl.formatMessage({id: 'Admin.Adverts.currency'})}
          </label>
          <Dropbox
            hideArrow
            id={'currency'}
            customClass={'form-select'}
            placeholder={intl.formatMessage({
              id: 'Admin.Adverts.currency',
            })}
            customSearchValue={''}
            customOnSearch={(e: any) => {}}
            disabledSearch
            onScroll={() => {}}
            setScrollCalled={() => {}}
            currentPageIndex={0}
            menuList={
              currencyList &&
              currencyList.map((item: any) => {
                return {
                  id: item?.currencyId,
                  title: item?.description,
                }
              })
            }
            selectedItemData={(dataItem: any) => {
              if (dataItem?.id) {
                setSelectedCurrencyId(Number(dataItem?.id))
              }
            }}
          />
        </div>

        <div className='col-md-4 mb-5'>
          <label htmlFor='publishDate' className={'text-muted fs-4'}>
            {intl.formatMessage({id: 'freight.listing.brand'})}
          </label>
          <Multiselect
            options={brandList}
            className='brand-list-wrap'
            selectedValues={selectedBrandList}
            onSelect={(value: any) => setSelectedBrandList(value)}
            onRemove={(value: any) => setSelectedBrandList(value)}
            displayValue='name'
            style={{
              inputField: {
                minHeight: '30px',
                height: 'auto',
              },
            }}
          />
        </div>

        <div className='mb-5 col-md-4'>
          <h6 className={'mb-3'}>{intl.formatMessage({id: 'freight.listing.country'})}</h6>
          <div className={''}>
            <Dropbox
              key={selectedFromFilterCountry}
              hideArrow
              customClass={'form-select'}
              placeholder={intl.formatMessage({
                id: 'freight.listing.country',
              })}
              customSearchValue={fromCountrySearch}
              customOnSearch={(e: any) => {
                setFromCountryPageIndex(0)
                setFromCountrySearch(e.target.value)
                getFromCountryList(0, e.target.value)
              }}
              onScroll={(val: any) => {
                setFromCountryPageIndex(val)
                getFromCountryList(val, fromCountrySearch)
              }}
              setScrollCalled={() => {
                setFromCountryScrollAPICalled(true)
              }}
              scrollAPIResponse={fromCountryScrollAPICalled}
              currentPageIndex={fromCountryPageIndex}
              menuList={
                fromCountryList &&
                fromCountryList
                  ?.filter(function ({code}: any) {
                    // @ts-ignore
                    return !this[code] && (this[code] = code)
                  }, {})
                  ?.map((item: any) => {
                    return {id: item?.code, title: item?.name}
                  })
              }
              defaultClearItem={{
                id: 'all',
                title: intl.formatMessage({id: 'Admin.Adverts.all'}),
              }}
              defaultSelected={{id: fromCountryId, title: selectedFromFilterCountry}}
              selectedItemData={(item: any) => {
                if (item?.id) {
                  setFromCountryId(item?.id)
                  setSelectedFromFilterCountry(item?.title)
                  setDependentFromCity(true)
                  setFromCityId('')
                }
              }}
            />
          </div>
        </div>

        <div className='mb-5 col-md-4'>
          <h6 className={'mb-3'}>{intl.formatMessage({id: 'freight.listing.city'})}</h6>
          <div className={''}>
            <Dropbox
              hideArrow
              disabled={!fromCountryId || fromCountryId === 'all'}
              customClass={'form-select'}
              placeholder={intl.formatMessage({id: 'freight.listing.city'})}
              customSearchValue={fromCitySearch}
              customOnSearch={(e: any) => {
                setFromCityPageIndex(0)
                setFromCitySearch(e.target.value)
                getFromSearchCityList(fromCountryId, 0, e.target.value)
              }}
              onScroll={(val: any) => {
                if (fromCountryId) {
                  setFromCityPageIndex(val)
                  getFromSearchCityList(fromCountryId, val, fromCountrySearch)
                }
              }}
              setScrollCalled={() => {
                setFromCityScrollAPICalled(true)
              }}
              scrollAPIResponse={fromCityScrollAPICalled}
              currentPageIndex={fromCityPageIndex}
              menuList={
                fromCityList &&
                fromCityList
                  ?.filter(function ({cityId}: any) {
                    // @ts-ignore
                    return !this[cityId] && (this[cityId] = cityId)
                  }, {})
                  ?.map((item: any) => {
                    return {id: item?.cityId, title: item?.name}
                  })
              }
              defaultClearItem={{
                id: 'all',
                title: intl.formatMessage({id: 'Admin.Adverts.all'}),
              }}
              defaultSelected={{id: fromCityId}}
              dependentValue={dependentFromCity}
              selectedItemData={(item: any) => {
                if (item?.id) {
                  setFromCityId(item?.id)
                  setDependentFromCity(false)
                }
              }}
            />
          </div>
        </div>

        <div className='mb-5 col-md-4'>
          <label className='fs-4 text-muted'>
            {intl.formatMessage({id: 'freight.listing.gwr'})}
          </label>
          <MultiRangeSlider
            min={0}
            max={1000}
            step={1}
            ruler={false}
            className='multi-range-wrap'
            style={{border: 'none', boxShadow: 'none'}}
            barInnerColor='#5e72e4'
            barLeftColor='#ddd'
            barRightColor='#ddd'
            minValue={minGWRKg}
            maxValue={maxGWRKg}
            onInput={(e) => {
              handleGWRKgInput(e)
            }}
          />
        </div>

        <div className='mb-5 col-md-4'>
          <label className='fs-4 text-muted'>
            {intl.formatMessage({id: 'freight.listing.year'})}
          </label>
          <MultiRangeSlider
            min={1960}
            max={new Date().getFullYear()}
            step={1}
            ruler={false}
            className='multi-range-wrap'
            style={{border: 'none', boxShadow: 'none'}}
            barInnerColor='#5e72e4'
            barLeftColor='#ddd'
            barRightColor='#ddd'
            minValue={minYearValue}
            maxValue={maxYearValue}
            onInput={(e) => {
              handleYearInput(e)
            }}
          />
        </div>

        <div className='mb-5 col-md-4'>
          <label className='fs-4 text-muted'>
            {intl.formatMessage({id: 'freight.listing.payload'})}
          </label>
          <MultiRangeSlider
            min={0}
            max={1000}
            step={1}
            ruler={false}
            className='multi-range-wrap'
            style={{border: 'none', boxShadow: 'none'}}
            barInnerColor='#5e72e4'
            barLeftColor='#ddd'
            barRightColor='#ddd'
            minValue={minPayload}
            maxValue={maxPayload}
            onInput={(e) => {
              handlePayloadInput(e)
            }}
          />
        </div>
      </div>
    </>
  )
}

export {TrailerFilter}
