import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'

import {PageTitle} from '../../../../_metronic/layout/core'
import {KTSVG} from '../../../../_metronic/helpers'

import Loading from '../../../Components/Loading'
import Modal from '../../../Components/Modal'
import {RouteWidget} from '../../../Components/RouteWidget/RouteWidget'
import CreateRoute from './Popups/CreateRoute'
import EditPreferences from './Popups/EditPreferences'

import useScrollLock from '../../../../utils/useScrollLock'

import {PreferredRouteService} from '../../../../services/CarrierServices/PreferredRouteService'
import {DriversService} from '../../../../services/CarrierServices/DriversService'
import {CommonService} from '../../../../services/FreightOperatorServices/CommonService'

import './PreferredRoute.scss'

const PreferredRoute = () => {
  const intl = useIntl()

  const driverServ = new DriversService()
  const routeServ = new PreferredRouteService()
  const commonServ = new CommonService()

  const [loading, setLoading] = useState<boolean>(false)

  /*List States*/
  const [drivers, setDrivers] = useState<any[]>([])
  const [accountList, setRouteList] = useState<any[]>([])
  const [countries, setCountries] = useState<any[]>([])
  const [fromCities, setFromCities] = useState<any[]>([])
  const [toCities, setToCities] = useState<any[]>([])
  const [transportTypeList, setTransportTypeList] = useState<any[]>([])

  const [fromCountryCustomSearch, setFromCountryCustomSearch] = useState<string>('')
  const [fromCitySearch, setFromCitySearch] = useState<string>('')
  const [fromCityPageIndex, setFromCityPageIndex] = useState<number>(0)
  const [fromCityScrollAPICalled, setFromCityScrollAPICalled] = useState<boolean>(false)

  const [fromEditCitySearch, setFromEditCitySearch] = useState<string>('')
  const [fromEditCityPageIndex, setFromEditCityPageIndex] = useState<number>(0)
  const [fromEditCityScrollAPICalled, setFromEditCityScrollAPICalled] = useState<boolean>(false)

  const [dependentFromCity, setDependentFromCity] = useState<boolean>(false)
  const [dependentToCity, setDependentToCity] = useState<boolean>(false)

  const [toCountryCustomSearch, setToCountryCustomSearch] = useState<string>('')
  const [toCitySearch, setToCitySearch] = useState<string>('')
  const [toCityPageIndex, setToCityPageIndex] = useState<number>(0)
  const [toCityScrollAPICalled, setToCityScrollAPICalled] = useState<boolean>(false)

  const [toEditCitySearch, setToEditCitySearch] = useState<string>('')
  const [toEditCityPageIndex, setToEditCityPageIndex] = useState<number>(0)
  const [toEditCityScrollAPICalled, setToEditCityScrollAPICalled] = useState<boolean>(false)

  /*Screens and modals*/
  const [selectedRoute, setSelectedRoute] = useState<any>(null)
  const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false)
  const [showCreatePopup, setShowCreatePopup] = useState<boolean>(false)
  const [showUpdatePopup, setShowUpdatePopup] = useState<boolean>(false)

  const {scrollUnlock} = useScrollLock()
  useEffect(() => {
    if (!showDeletePopup && !showCreatePopup && !showUpdatePopup) {
      scrollUnlock()
    }
  }, [showDeletePopup, showCreatePopup, showUpdatePopup])

  /*Popup States*/
  const [updatedRoute, setUpdatedRoute] = useState<any>({
    id: -1,
    fromCountry: '',
    toCountry: '',
    fromCity: '',
    toCity: '',
  })

  /**
   *! API CALL START
   **/
  const getRoutesList = () => {
    routeServ
      .getRoutes()
      .then((resp: any) => {
        setRouteList(resp?.data)
        setLoading(false)
      })
      .catch((e: any) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
        setLoading(false)
      })
  }

  const getDrivers = (pageIndex?: number, searchText?: string) => {
    driverServ
      .getDrivers(pageIndex ?? 0, 20, searchText ?? '')
      .then((resp: any) => {
        if (pageIndex === 0) setDrivers(resp?.data)
        else setDrivers((item: any) => [...item, ...resp.data])
        setLoading(false)
      })
      .catch((e: any) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
        setLoading(false)
      })
  }

  const getTransportTypeList = () => {
    routeServ
      .transportTypesList()
      .then((resp: any) => {
        setTransportTypeList(resp?.data)
      })
      .catch((e: any) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
        setLoading(false)
      })
  }

  const getCountries = () => {
    routeServ
      .getCountries()
      .then((resp: any) => {
        setCountries(resp?.data)
        setLoading(false)
      })
      .catch((e: any) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
        setLoading(false)
      })
  }

  const getFromSearchCityList = (
    countryCode: string,
    pageIndex: number = 0,
    searchText?: string
  ) => {
    commonServ
      .citySearchList(countryCode, searchText ?? '', pageIndex ?? 0)
      .then((resp: any) => {
        setFromEditCityScrollAPICalled(false)
        setFromCityScrollAPICalled(false)
        if (pageIndex === 0) setFromCities(resp?.data)
        else setFromCities((item: any) => [...item, ...resp?.data])

        setLoading(false)
      })
      .catch((e: any) => {
        setFromEditCityScrollAPICalled(false)
        setFromCityScrollAPICalled(false)
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const getToSearchCityList = (countryCode: string, pageIndex: number = 0, searchText?: string) => {
    commonServ
      .citySearchList(countryCode, searchText ?? '', pageIndex ?? 0)
      .then((resp: any) => {
        setToEditCityScrollAPICalled(false)
        setToCityScrollAPICalled(false)
        if (pageIndex === 0) setToCities(resp?.data)
        else setToCities((item: any) => [...item, ...resp?.data])

        setLoading(false)
      })
      .catch((e: any) => {
        setToEditCityScrollAPICalled(false)
        setToCityScrollAPICalled(false)
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const activateTheRoute = (id: number) => {
    routeServ
      .activateTheRoute(id)
      .then((resp: any) => {
        getRoutesList()
        toast.success(intl.formatMessage({id: 'Carrier.prefRoute.routeActive'}))
      })
      .catch((e: any) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
        setLoading(false)
      })
  }
  const deactivateTheRoute = (id: number) => {
    routeServ
      .deactivateTheRoute(id)
      .then((resp: any) => {
        getRoutesList()
        toast.success(intl.formatMessage({id: 'Carrier.prefRoute.routeDeactivated'}))
      })
      .catch((e: any) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
        setLoading(false)
      })
  }

  const deleteRoute = (id: number) => {
    routeServ
      .deleteRoute(id)
      .then((resp: any) => {
        toast.success(intl.formatMessage({id: 'Carrier.prefRoute.routeDeleteSuccess'}))
        getRoutesList()
        setShowDeletePopup(false)
      })
      .catch((e: any) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
        setLoading(false)
      })
  }

  const updateRoute = (data: {
    id: number
    fromCountry?: string
    toCountry?: string
    fromCity?: number
    toCity?: number
    transportTypeId?: string
  }) => {
    routeServ
      .updateRoute(
        data.id,
        data.fromCountry,
        data.toCountry,
        data.fromCity,
        data.toCity,
        data.transportTypeId
      )
      .then(() => {
        toast.success(intl.formatMessage({id: 'Carrier.prefRoute.routeUpdateSuccess'}))
        getRoutesList()
        setShowUpdatePopup(false)
      })
      .catch((e: any) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
        setLoading(false)
      })
  }

  const createRoute = (data: {
    fromCountry?: string
    toCountry?: string
    fromCity?: number
    toCity?: number
    transportTypeId?: string
  }) => {
    routeServ
      .createRoute(
        data.fromCountry,
        data.toCountry,
        data.fromCity,
        data.toCity,
        data.transportTypeId
      )
      .then(() => {
        toast.success(intl.formatMessage({id: 'Carrier.prefRoute.routeCreateSuccess'}))
        getRoutesList()
        setShowCreatePopup(false)
      })
      .catch((e: any) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
        setLoading(false)
      })
  }
  /**
   *! API CALL END
   **/

  useEffect(() => {
    setLoading(true)
    getRoutesList()
    setLoading(true)
    getCountries()
    setLoading(true)
    getDrivers()
    getTransportTypeList()
  }, [])

  useEffect(() => {
    if (updatedRoute.fromCountry) {
      setLoading(true)
      getFromSearchCityList(updatedRoute.fromCountry, fromCityPageIndex, fromCitySearch)
    }
  }, [updatedRoute.fromCountry])

  useEffect(() => {
    if (updatedRoute.toCountry) {
      setLoading(true)
      getToSearchCityList(updatedRoute.toCountry, toCityPageIndex, toCitySearch)
    }
  }, [updatedRoute.toCountry])

  return (
    <>
      {loading && <Loading />}
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'Carrier.prefRoute.title'})}</PageTitle>
      <div>
        <div className={'d-flex align-items-center justify-content-end'}>
          <button
            onClick={() => {
              setShowCreatePopup(true)
            }}
            className='btn btn-sm btn-light-primary mb-5 border border-2 fs-4'
          >
            <KTSVG path='media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
            {intl.formatMessage({id: 'Carrier.prefRoute.addNewRoute'})}
          </button>
        </div>
        <div className=''>
          <RouteWidget
            title={intl.formatMessage({id: 'Carrier.prefRoute.route'})}
            desc={intl.formatMessage({id: 'Carrier.prefRoute.routeDesc'})}
            data={
              accountList &&
              accountList?.map((item: any) => ({
                id: item?.preferredRouteId,
                fromCountry: item?.fromCountry?.name,
                fromCountryCode: item?.fromCountry?.code,

                toCountry: item?.toCountry?.name,
                toCountryCode: item?.toCountry?.code,

                fromCity: item?.fromCity?.name,
                fromCityId: item?.fromCity?.cityId,

                toCity: item?.toCity?.name,
                toCityId: item?.toCity?.cityId,

                color: item?.active && item?.active === 'Y' ? 'green' : 'red',
                isActive: item?.active === 'Y',
                transportType: item?.transportType,
              }))
            }
            onEdit={(data: any) => {
              setSelectedRoute(data)
              setUpdatedRoute((item: any) => ({
                ...item,
                id: data.id,
                fromCountry: data.fromCountryCode,
                fromCountryName: data.fromCountry,
                toCountry: data.toCountryCode,
                toCountryName: data.toCountry,
                fromCity: data.fromCityId,
                fromCityName: data.fromCity,
                toCity: data.toCityId,
                toCityName: data.toCity,
                transportTypeId: data?.transportType?.transportTypeId || '',
              }))
              setShowUpdatePopup(true)
            }}
            onDelete={(data: any) => {
              setSelectedRoute(data)
              setShowDeletePopup(true)
            }}
            onActivate={(data: any) => {
              setLoading(true)
              activateTheRoute(data.id)
            }}
            onDeactivate={(data: any) => {
              setLoading(true)
              deactivateTheRoute(data.id)
            }}
          />
        </div>
      </div>

      {/*DELETE POPUP*/}
      {showDeletePopup && (
        <Modal
          closeBtnSettings={{
            show: true,
            onClick: () => setShowDeletePopup(false),
          }}
          customAll={
            <div className={''}>
              <h3 className={'mt-5 mb-15'}>
                {intl.formatMessage({id: 'Carrier.prefRoute.delete.title'})}
              </h3>
              <div className={'row'}>
                <div className={'col-md-6'}>
                  <button
                    className={'btn btn-danger w-100'}
                    onClick={() => {
                      setShowDeletePopup(false)
                    }}
                  >
                    {intl.formatMessage({id: 'Carrier.prefRoute.delete.cancel'})}
                  </button>
                </div>
                <div className={'col-md-6'}>
                  <button
                    className={'btn btn-success w-100'}
                    onClick={() => {
                      setLoading(true)
                      deleteRoute(selectedRoute.id)
                    }}
                  >
                    {intl.formatMessage({id: 'Carrier.prefRoute.delete.approve'})}
                  </button>
                </div>
              </div>
            </div>
          }
        />
      )}

      {/*CREATE POPUP*/}
      {showCreatePopup && (
        <CreateRoute
          countries={countries}
          fromCities={fromCities}
          fromCountryCustomSearch={fromCountryCustomSearch}
          setFromCountryCustomSearch={setFromCountryCustomSearch}
          toCountryCustomSearch={toCountryCustomSearch}
          setToCountryCustomSearch={setToCountryCustomSearch}
          fromCitySearch={fromCitySearch}
          setFromCityPageIndex={setFromCityPageIndex}
          setFromCitySearch={setFromCitySearch}
          setFromCityScrollAPICalled={setFromCityScrollAPICalled}
          fromCityScrollAPICalled={fromCityScrollAPICalled}
          fromCityPageIndex={fromCityPageIndex}
          getFromSearchCityList={getFromSearchCityList}
          toCities={toCities}
          createRoute={(dat: any) => {
            setLoading(true)
            createRoute(dat)
          }}
          onClose={() => setShowCreatePopup(false)}
          transportTypeList={transportTypeList}
          toCitySearch={toCitySearch}
          setToCityPageIndex={setToCityPageIndex}
          setToCitySearch={setToCitySearch}
          setToCityScrollAPICalled={setToCityScrollAPICalled}
          toCityScrollAPICalled={toCityScrollAPICalled}
          toCityPageIndex={toCityPageIndex}
          getToSearchCityList={getToSearchCityList}
        />
      )}

      {/*UPDATE POPUP*/}
      {showUpdatePopup && (
        <EditPreferences
          transportTypeList={transportTypeList}
          countries={countries}
          fromCities={fromCities}
          toCities={toCities}
          updatedRoutes={updatedRoute}
          updateRoute={(dat: any) => {
            setLoading(true)
            updateRoute(dat)
          }}
          onClose={() => setShowUpdatePopup(false)}
          setFromEditCityScrollAPICalled={setFromEditCityScrollAPICalled}
          setFromEditCityPageIndex={setFromEditCityPageIndex}
          setFromEditCitySearch={setFromEditCitySearch}
          fromEditCitySearch={fromEditCitySearch}
          fromEditCityPageIndex={fromEditCityPageIndex}
          fromEditCityScrollAPICalled={fromEditCityScrollAPICalled}
          setDependentFromCity={setDependentFromCity}
          dependentFromCity={dependentFromCity}
          getFromSearchCityList={getFromSearchCityList}
          setToEditCityScrollAPICalled={setToEditCityScrollAPICalled}
          setToEditCityPageIndex={setToEditCityPageIndex}
          setToEditCitySearch={setToEditCitySearch}
          toEditCitySearch={toEditCitySearch}
          toEditCityPageIndex={toEditCityPageIndex}
          toEditCityScrollAPICalled={toEditCityScrollAPICalled}
          setDependentToCity={setDependentToCity}
          dependentToCity={dependentToCity}
          getToSearchCityList={getToSearchCityList}
        />
      )}
    </>
  )
}
export {PreferredRoute}
