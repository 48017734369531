import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'

import {PageTitle} from '../../../../_metronic/layout/core'
import {KTSVG} from '../../../../_metronic/helpers'
import {getDate} from '../../../../utils/common'

import Dropbox from '../../../Components/Dropbox'
import DataTableAdvanced from '../../../Components/DataTableAdvanced'
import Loading from '../../../Components/Loading'
import ShowDetails from './Popup/ShowDetails'

import {DocumentService} from '../../../../services/CarrierServices/DocumentService'

import './SearchDocument.scss'

const SearchDocument = () => {
  const intl = useIntl()

  const [loading, setLoading] = useState<boolean>(false)
  const [pageMIndex, setPageMIndex] = useState<number>(0)
  const [showCompanyDetailPopup, setShowCompanyDetailPopup] = useState<boolean>(false)
  const [documentList, setDocumentList] = useState<any>([])
  const [companyDetails, setCompanyDetails] = useState<any>({})

  const [companyList, setCompanyList] = useState<any[]>([])
  const [selectedCompany, setSelectedCompany] = useState<any>(null)
  const [currentCompanyPageIndex, setCurrentCompanyPageIndex] = useState<number>(0)
  const [companyCustomSearch, setCompanyCustomSearch] = useState<string>('')

  const [documentTypeList, setDocumentTypeList] = useState<any[]>([])
  const [selectedDocumentType, setSelectedDocumentType] = useState<any>(null)
  const [currentDocumentTypePageIndex, setCurrentDocumentTypePageIndex] = useState<number>(0)
  const [documentTypeCustomSearch, setDocumentTypeCustomSearch] = useState<string>('')

  const [countryList, setCountryList] = useState<any[]>([])
  const [selectedCountry, setSelectedCountry] = useState<any>(null)
  const [currentCountryPageIndex, setCurrentCountryPageIndex] = useState<number>(0)
  const [countryCustomSearch, setCountryCustomSearch] = useState<string>('')

  const [showFilterPanel, setShowFilterPanel] = useState<boolean>(false)

  const [companyNameScrollAPICalled, setCompanyNameScrollAPICalled] = useState<boolean>(false)
  const [documentTypeScrollAPICalled, setDocumentTypeScrollAPICalled] = useState<boolean>(false)
  const [countryListScrollAPICalled, setCountryListScrollAPICalled] = useState<boolean>(false)

  const [isNextPage, setIsNextPage] = useState<boolean>(false)

  const documentServ = new DocumentService()

  const getFilterDocuments = (data: any) => {
    documentServ
      .getFilterDocument({
        ...(data?.companyId && {companyId: data?.companyId}),
        ...(data?.documentTypeId && {documentTypeId: data?.documentTypeId}),
        ...(data?.country && {country: data?.country}),
        ...{pageIndex: data?.pageMIndex ?? pageMIndex},
      })
      .then((resp: any) => {
        setLoading(false)
        setIsNextPage(false)
        setDocumentList(resp?.data)
      })
      .catch((e: any) => {
        setLoading(false)
        setIsNextPage(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const handleGetCompanyDetails = (companyId?: number) => {
    documentServ
      .getCompanyDetails(companyId)
      .then((resp: any) => {
        setLoading(false)
        setCompanyDetails(resp?.data)
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const onPaginationChange = (page: number) => {
    if (!isNextPage) {
      getFilterDocuments({pageIndex: pageMIndex})
      setPageMIndex(page)
    }
    setIsNextPage(true)
  }

  const handleGetCompanyList = (pageIndex?: any, searchText?: any) => {
    documentServ
      .getCompanyList(searchText, pageIndex)
      .then((resp: any) => {
        setCompanyNameScrollAPICalled(false)
        setLoading(false)
        if (pageIndex === 0)
          setCompanyList(
            [
              {
                companyId: 'all',
                name: intl.formatMessage({id: 'global.all'}),
              },
            ].concat(resp?.data.filter((item: any) => item.companyId))
          )
        else setCompanyList((item: any) => [...item, ...resp?.data])
      })
      .catch((e: any) => {
        setCompanyNameScrollAPICalled(false)
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const handleGetDocumentTypeList = (pageIndex?: any, searchText?: any) => {
    documentServ
      .getDocumentTypesList(searchText, pageIndex)
      .then((resp: any) => {
        setLoading(false)
        setDocumentTypeScrollAPICalled(false)
        if (pageIndex === 0)
          setDocumentTypeList(
            [
              {
                documentTypeId: 'all',
                description: intl.formatMessage({id: 'global.all'}),
              },
            ].concat(resp?.data.filter((item: any) => item.documentTypeId))
          )
        else setDocumentTypeList((item: any) => [...item, ...resp?.data])
      })
      .catch((e: any) => {
        setDocumentTypeScrollAPICalled(false)
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const handleGetCountryList = (pageIndex?: any, searchText?: any) => {
    documentServ
      .getCountryList(searchText, pageIndex)
      .then((resp: any) => {
        setCountryListScrollAPICalled(false)
        setLoading(false)
        if (pageIndex === 0)
          setCountryList(
            [
              {
                code: 'all',
                name: intl.formatMessage({id: 'global.all'}),
              },
            ].concat(resp?.data.filter((item: any) => item.code))
          )
        else setCountryList((item: any) => [...item, ...resp?.data])
      })
      .catch((e: any) => {
        setCountryListScrollAPICalled(false)
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  useEffect(() => {
    setLoading(true)
    getFilterDocuments({pageIndex: pageMIndex})
    handleGetCountryList(0, countryCustomSearch)
    handleGetCompanyList(0, companyCustomSearch)
    handleGetDocumentTypeList(0, documentTypeCustomSearch)
  }, [])

  return (
    <>
      {loading && <Loading />}

      <div className='row mb-5'>
        <span className='menu-icon d-flex justify-content-end'>
          <div
            className={`btn btn-icon btn-active-light-primary btn-custom ${
              showFilterPanel ? 'menu-button-active' : ''
            }`}
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='bottom'
            onClick={() => setShowFilterPanel(!showFilterPanel)}
          >
            <KTSVG path='/media/filtering.svg' className='svg-icon-2x' />
          </div>
        </span>
      </div>

      {showFilterPanel && (
        <div className='row'>
          <div className={'col-md-3 mb-10'}>
            <label htmlFor='companyName' className={'text-muted fs-4 mb-2'}>
              {intl.formatMessage({id: 'Carrier.searchDocument.filter.companyName'})}
            </label>
            <Dropbox
              id={'companyName'}
              hideArrow
              customClass={'form-select transporter-search-overlay'}
              placeholder={intl.formatMessage({
                id: 'Carrier.searchDocument.filter.companyName.placeHolder',
              })}
              customSearchValue={companyCustomSearch}
              customOnSearch={(e: any) => {
                setCurrentCompanyPageIndex(0)
                setCompanyCustomSearch(e.target.value)
                handleGetCompanyList(0, e.target.value)
              }}
              onScroll={(val: any) => {
                setCurrentCompanyPageIndex(val)
                handleGetCompanyList(val, companyCustomSearch)
              }}
              setScrollCalled={() => {
                setCompanyNameScrollAPICalled(true)
              }}
              scrollAPIResponse={companyNameScrollAPICalled}
              currentPageIndex={currentCompanyPageIndex}
              menuList={
                companyList &&
                companyList
                  .filter(function ({companyId}) {
                    // @ts-ignore
                    return !this[companyId] && (this[companyId] = companyId)
                  }, {})
                  .map((item: any) => {
                    return {id: item?.companyId, title: item?.name}
                  })
              }
              defaultClearItem={{
                id: 'all',
                title: intl.formatMessage({id: 'Admin.Adverts.all'}),
              }}
              defaultSelected={{id: selectedCompany}}
              selectedItemData={(item: any) => {
                if (item?.id) {
                  setLoading(true)
                  setSelectedCompany(item?.id)
                  getFilterDocuments({
                    ...(selectedDocumentType && {documentTypeId: selectedDocumentType}),
                    ...(selectedCountry && {country: selectedCountry}),
                    companyId: item?.id,
                  })
                }
              }}
            />
          </div>

          <div className={'col-md-3 mb-10'}>
            <label htmlFor='documentType' className={'text-muted fs-4 mb-2'}>
              {intl.formatMessage({id: 'Carrier.searchDocument.filter.documentType'})}
            </label>
            <Dropbox
              id={'documentType'}
              hideArrow
              customClass={'form-select transporter-search-overlay'}
              placeholder={intl.formatMessage({
                id: 'Carrier.searchDocument.filter.documentType.placeHolder',
              })}
              customSearchValue={documentTypeCustomSearch}
              customOnSearch={(e: any) => {
                setCurrentDocumentTypePageIndex(0)
                setDocumentTypeCustomSearch(e.target.value)
                handleGetDocumentTypeList(0, e.target.value)
              }}
              onScroll={(val: any) => {
                setCurrentDocumentTypePageIndex(val)
                handleGetDocumentTypeList(val, documentTypeCustomSearch)
              }}
              setScrollCalled={() => {
                setDocumentTypeScrollAPICalled(true)
              }}
              scrollAPIResponse={documentTypeScrollAPICalled}
              currentPageIndex={currentDocumentTypePageIndex}
              menuList={
                documentTypeList &&
                documentTypeList
                  .filter(function ({documentTypeId}) {
                    // @ts-ignore
                    return !this[documentTypeId] && (this[documentTypeId] = documentTypeId)
                  }, {})
                  .map((item: any) => {
                    return {id: item?.documentTypeId, title: item?.description}
                  })
              }
              defaultClearItem={{
                id: 'all',
                title: intl.formatMessage({id: 'Admin.Adverts.all'}),
              }}
              defaultSelected={{id: selectedDocumentType}}
              selectedItemData={(item: any) => {
                if (item?.id) {
                  setLoading(true)
                  setSelectedDocumentType(item?.id)
                  getFilterDocuments({
                    ...(selectedCompany && {companyId: selectedCompany}),
                    ...(selectedCountry && {country: selectedCountry}),
                    documentTypeId: item?.id,
                  })
                }
              }}
            />
          </div>

          <div className={'col-md-3 mb-10'}>
            <label htmlFor='country' className={'text-muted fs-4 mb-2'}>
              {intl.formatMessage({id: 'Carrier.searchDocument.filter.country'})}
            </label>
            <Dropbox
              id={'country'}
              hideArrow
              customClass={'form-select transporter-search-overlay'}
              placeholder={intl.formatMessage({
                id: 'Carrier.searchDocument.filter.country.placeHolder',
              })}
              customSearchValue={countryCustomSearch}
              customOnSearch={(e: any) => {
                setCurrentCountryPageIndex(0)
                setCountryCustomSearch(e.target.value)
                handleGetCountryList(0, e.target.value)
              }}
              onScroll={(val: any) => {
                setCurrentCountryPageIndex(val)
                handleGetCountryList(val, countryCustomSearch)
              }}
              setScrollCalled={() => {
                setCountryListScrollAPICalled(true)
              }}
              scrollAPIResponse={countryListScrollAPICalled}
              currentPageIndex={currentCountryPageIndex}
              menuList={
                countryList &&
                countryList
                  .filter(function ({code}) {
                    // @ts-ignore
                    return !this[code] && (this[code] = code)
                  }, {})
                  .map((item: any) => {
                    return {id: item?.code, title: item?.name}
                  })
              }
              defaultClearItem={{
                id: 'all',
                title: intl.formatMessage({id: 'Admin.Adverts.all'}),
              }}
              defaultSelected={{id: selectedCountry}}
              selectedItemData={(item: any) => {
                if (item?.id) {
                  setLoading(true)
                  setSelectedCountry(item?.id)
                  getFilterDocuments({
                    ...(selectedCompany && {companyId: selectedCompany}),
                    ...(selectedDocumentType && {documentTypeId: selectedDocumentType}),
                    country: item?.id,
                  })
                }
              }}
            />
          </div>
        </div>
      )}
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({id: 'SideMenu.carrierOperator.searchDocuments'})}
      </PageTitle>

      {!documentList.length && (
        <div className='alert alert-warning mb-5 fw-bolder' role='alert'>
          {intl.formatMessage({id: 'global.noData'})}
        </div>
      )}
      <DataTableAdvanced
        columns={[
          {
            Header: 'ID',
            accessor: 'id',
          },
          {
            Header: intl.formatMessage({id: 'CreateDocument.dataTable.docType'}),
            accessor: 'documentType',
          },
          {
            Header: intl.formatMessage({id: 'Carrier.searchDocument.dataTable.documentName'}),
            accessor: 'description',
          },
          {
            Header: intl.formatMessage({id: 'CreateDocument.dataTable.docNumber'}),
            accessor: 'documentNumber',
          },
          {
            Header: intl.formatMessage({id: 'Carrier.searchDocument.dataTable.companyName'}),
            accessor: 'companyName',
          },
          {
            Header: intl.formatMessage({id: 'Carrier.searchDocument.dataTable.country'}),
            accessor: 'country',
          },
          {
            Header: intl.formatMessage({id: 'CreateDocument.dataTable.issueDate'}),
            accessor: 'issueDate',
          },
          {
            Header: intl.formatMessage({id: 'CreateDocument.dataTable.expireDate'}),
            accessor: 'expireDate',
          },
          {
            Header: '',
            accessor: 'item',
            hidden: true,
          },
          {
            Header: '',
            accessor: 'fileURL',
            isAction: true,
            Cell: ({row, rows, toggleRowExpanded}: any) =>
              !row.canExpand &&
              row?.values?.fileURL.split('/files')[1] &&
              !(
                row?.values?.fileURL.split('/files')[1].includes('null') ||
                row?.values?.fileURL.split('/files')[1].includes('undefined')
              ) ? (
                <div className='sub-table-column-wrap'>
                  <button
                    title={intl.formatMessage({id: 'Carrier.drivers.details.download'})}
                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 mb-1'
                    onClick={() => {
                      document.location.href = `${row?.values?.fileURL}?download=Y`
                      return false
                    }}
                  >
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr091.svg'
                      className='svg-icon-3 mr-2 pr-2'
                    />
                  </button>
                </div>
              ) : null,
          },
        ]}
        data={
          documentList &&
          documentList?.map((item: any) => {
            return {
              id: item?.documentId,
              documentType: item?.documentType?.description,
              documentNumber: item?.documentNumber,
              companyName: item?.company?.name,
              description: item?.description,
              country: item?.company?.country?.name,
              issueDate: !!item?.issueDate && getDate(item?.issueDate),
              expireDate: !!item?.expireDate && getDate(item?.expireDate),
              fileURL: item?.fileUrl,
              item: item,
            }
          })
        }
        showDetailBtn={(row: any) => {
          setLoading(true)
          setShowCompanyDetailPopup(true)
          handleGetCompanyDetails(Number(row?.values?.item?.company?.companyId))
        }}
        showDetailBtnText={intl.formatMessage({
          id: 'Carrier.searchDocument.dataTable.showCompanyDetailsText',
        })}
        editableRows={['']}
        setPageMIndex={onPaginationChange}
        pageMIndex={pageMIndex}
      />

      {showCompanyDetailPopup && (
        <ShowDetails
          companyDetails={companyDetails}
          onClose={() => {
            setCompanyDetails({})
            setShowCompanyDetailPopup(false)
          }}
        />
      )}
    </>
  )
}

export {SearchDocument}
