import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import _ from 'lodash'
import clsx from 'clsx'
import {toast} from 'react-toastify'

import {PageTitle} from '../../../../_metronic/layout/core'
import {KTSVG} from '../../../../_metronic/helpers'

import Loading from '../../../Components/Loading'
import DataTableAdvanced from '../../../Components/DataTableAdvanced'
import Modal from '../../../Components/Modal'
import EditAddresses from './Popups/EditAddresses'
import AddNewAddressPopup from '../../../Components/AddNewAddressPopup'

import {AddressService} from '../../../../services/FreightOperatorServices/AddressService'
import {CommonService} from '../../../../services/FreightOperatorServices/CommonService'

import useScrollLock from '../../../../utils/useScrollLock'

import './Adresses.scss'

const Addresses = () => {
  const intl = useIntl()
  const commonServ = new CommonService()
  const addressServ = new AddressService()
  const [loading, setLoading] = useState<boolean>(false)
  const [pageMIndex, setPageMIndex] = useState<number>(0)
  const [filter, setFilter] = useState<any>('')

  /*Country and City Lists*/
  const [countries, setCountries] = useState([])
  const [cities, setCities] = useState([])

  /*List States*/
  const [addressList, setAdressList] = useState([])

  /*Screens and modals*/
  const [selectedAddress, setSelectedAddress] = useState<any>(null)
  const [showCreatePopup, setShowCreatePopup] = useState<boolean>(false)
  const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false)
  const [showUpdatePopup, setShowUpdatePopup] = useState<boolean>(false)

  const [showFilterPanel, setShowFilterPanel] = useState<boolean>(false)

  const [isNextPage, setIsNextPage] = useState<boolean>(false)

  const {scrollUnlock} = useScrollLock()
  useEffect(() => {
    if (!showDeletePopup && !showUpdatePopup) scrollUnlock()
  }, [showDeletePopup, showUpdatePopup])

  /*Popup States*/
  const [latLong, setLatLong] = useState<any>({})
  const [updatedAddress, setUpdatedAddress] = useState<any>({
    addressId: -1,
    description: '',
    country: '',
    city: '',
    district: '',
    streetNumber: '',
    street: '',
    neighborhood: '',
    postCode: '',
    addressText: '',
    latitude: '',
    longitude: '',
  })

  /**
   *! API CALL START
   **/
  /** Address api call Start **/
  const getAddressList = (pageIndex?: number, searchText?: string) => {
    addressServ
      .getAdressList(pageIndex, searchText)
      .then((resp: any) => {
        setAdressList(resp?.data)
        setLoading(false)
        setIsNextPage(false)
      })
      .catch((e: any) => {
        setLoading(false)
        setIsNextPage(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }
  /** Address api call End **/

  /** Address get one api call Start **/
  const getAddress = (id: number) => {
    return addressServ.getAdress(id)
  }
  /** Address get one api call End **/

  /** Update Address api call Start **/
  const updateAddress = ({
    addressId,
    description,
    country,
    city,
    district,
    streetNumber,
    street,
    neighborhood,
    postCode,
    addressText,
    latitude,
    longitude,
  }: any) => {
    addressServ
      .updateAddress({
        addressId: addressId,
        description: description,
        countryCode: country,
        cityId: city,
        district: district,
        streetNumber: streetNumber,
        street: street,
        neighborhood: neighborhood,
        postCode: postCode,
        addressText: addressText,
        latitude: latitude,
        longitude: longitude,
      })
      .then(() => {
        getAddressList(pageMIndex)
        setLoading(false)
        setShowUpdatePopup(false)
        setLatLong({})
        toast.success(intl.formatMessage({id: 'Freight.address.update.success'}), {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }
  /** Update Address api call End **/

  /** Delete Address api call Start **/
  const deleteAddress = (addressId: number) => {
    addressServ
      .deleteAdress(addressId)
      .then(() => {
        setLoading(false)
        getAddressList(pageMIndex)
        setShowDeletePopup(false)
        toast.success(intl.formatMessage({id: 'Freight.address.delete.success'}), {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }
  /** Delete Address api call End **/

  /** Country and City List api call Start **/
  const getCountryList = () => {
    commonServ.countryList().then((resp) => {
      setCountries(resp.data)
      setLoading(false)
    })
  }

  const getCityList = (countryCode: string) => {
    commonServ.cityList(countryCode).then((resp) => {
      setCities(resp.data)
      setLoading(false)
    })
  }
  /** Country and City List api call End **/

  useEffect(() => {
    if (updatedAddress?.country) {
      setLoading(true)
      getCityList(updatedAddress?.country)
    }
  }, [updatedAddress?.country])

  useEffect(() => {
    setLoading(true)
    getAddressList(pageMIndex)
    setLoading(true)
    getCountryList()
  }, [])

  const onPaginationChange = (page: number) => {
    if (!isNextPage) {
      getAddressList(page)
      setPageMIndex(page)
    }
    setIsNextPage(true)
  }

  const handleFilterSearch = React.useMemo(
    () =>
      _.debounce((_event, searchText) => {
        getAddressList(0, searchText)
      }, 1000),
    []
  )

  return (
    <>
      {loading && <Loading />}
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'Freight.addresses.title'})}</PageTitle>
      <div className={'d-flex align-items-center justify-content-end'}>
        <button
          onClick={() => setShowCreatePopup(true)}
          className='btn btn-sm btn-light-primary mb-5 border border-2 fs-4'
        >
          {intl.formatMessage({id: 'Freight.addresses.createNewAddress'})}
        </button>
      </div>
      <div>
        {!addressList.length && (
          <div className='alert alert-warning mb-5 fw-bolder' role='alert'>
            {intl.formatMessage({id: 'Freight.addresses.noData'})}
          </div>
        )}
        <div
          className={`row ${
            showFilterPanel ? 'justify-content-between' : 'justify-content-end mb-5'
          } `}
        >
          {showFilterPanel && (
            <div className='mb-7 col-md-3 justify-content-left'>
              <h6 className={'mb-3'}>{intl.formatMessage({id: 'Carrier.dashboard.filter'})}</h6>
              <div className={''}>
                <input
                  placeholder={intl.formatMessage({id: 'Carrier.dashboard.filter.placeHolder'})}
                  className={clsx('form-control form-control-lg')}
                  onChange={(event: any) => {
                    handleFilterSearch(event, event.target.value)
                    setFilter(event.target.value)
                  }}
                  value={filter}
                  type='text'
                />
              </div>
            </div>
          )}

          <div className='col-md-2 d-flex align-items-center justify-content-end'>
            {/* <span className='menu-icon d-flex justify-content-end'> */}
            <div
              className={`btn btn-icon btn-active-light-primary btn-custom ${
                showFilterPanel ? 'menu-button-active' : ''
              }`}
              data-kt-menu-attach='parent'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='bottom'
              onClick={() => setShowFilterPanel(!showFilterPanel)}
            >
              <KTSVG path='/media/filtering.svg' className='svg-icon-2x' />
            </div>
            {/* </span> */}
          </div>
        </div>
        <DataTableAdvanced
          columns={[
            {
              Header: 'ID',
              accessor: 'id',
            },
            {
              Header: intl.formatMessage({id: 'Freight.addresses.explanation'}),
              accessor: 'desc',
            },
            {
              Header: intl.formatMessage({id: 'Freight.addresses.country'}),
              accessor: 'country',
            },
            {
              Header: intl.formatMessage({id: 'Freight.addresses.city'}),
              accessor: 'city',
            },
            {
              Header: intl.formatMessage({id: 'Freight.addresses.district'}),
              accessor: 'town',
            },
            {
              Header: intl.formatMessage({id: 'Freight.addresses.dis'}),
              accessor: 'district',
            },
            {
              Header: intl.formatMessage({id: 'Freight.addresses.postCode'}),
              accessor: 'postCode',
            },
            {
              Header: intl.formatMessage({id: 'Freight.addresses.fullAddress'}),
              accessor: 'addressText',
            },
          ]}
          data={
            addressList &&
            addressList?.map((item: any) => {
              return {
                id: item?.addressId,
                desc: item?.description,
                country: item?.country?.name,
                city: item?.city?.name,
                town: item?.town,
                district: item?.district,
                postCode: item?.postCode,
                addressText: item?.addressText,
              }
            })
          }
          editBtn={(row: any) => {
            setLoading(true)
            setSelectedAddress(row.values)
            getAddress(row.values.id)
              .then((resp: any) => {
                setLoading(false)
                setUpdatedAddress((item: any) => ({
                  ...item,
                  addressId: resp?.data?.addressId,
                  description: resp?.data?.description,
                  country: resp?.data?.country?.code,
                  city: resp?.data?.city?.cityId,
                  district: resp?.data?.district,
                  postCode: resp?.data?.postCode,
                  addressText: resp?.data?.addressText,
                  neighborhood: resp?.data?.neighborhood,
                  streetNumber: resp?.data?.streetNumber,
                  street: resp?.data?.street,
                  longitude: resp?.data?.city?.longitude,
                  latitude: resp?.data?.city?.latitude,
                }))
                setShowUpdatePopup(true)
              })
              .catch((e) => {
                toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
                setLoading(false)
              })
          }}
          deleteRow={(row: any) => {
            setSelectedAddress(row.values)
            setShowDeletePopup(true)
          }}
          deleteText={intl.formatMessage({id: 'Freight.addresses.tabel.deleteText'})}
          editableRows={['']}
          setPageMIndex={onPaginationChange}
          pageMIndex={pageMIndex}
        />
      </div>

      {showCreatePopup && (
        <AddNewAddressPopup
          onClose={() => setShowCreatePopup(false)}
          onSubmit={() => {
            getAddressList(pageMIndex)
            setShowCreatePopup(false)
          }}
        />
      )}
      {/*DELETE POPUP*/}
      {showDeletePopup && (
        <Modal
          closeBtnSettings={{
            show: true,
            onClick: () => setShowDeletePopup(false),
          }}
          customAll={
            <div className={''}>
              <h3 className={'mt-5 mb-15'}>
                {intl.formatMessage({id: 'Freight.addresses.delete.title'})}
              </h3>
              <div className={'row'}>
                <div className={'col-md-6'}>
                  <button
                    className={'btn btn-danger w-100'}
                    onClick={() => {
                      setShowDeletePopup(false)
                    }}
                  >
                    {intl.formatMessage({id: 'Freight.addresses.delete.cancel'})}
                  </button>
                </div>
                <div className={'col-md-6'}>
                  <button
                    className={'btn btn-success w-100'}
                    onClick={() => {
                      setLoading(true)
                      deleteAddress(selectedAddress.id)
                    }}
                  >
                    {intl.formatMessage({id: 'Freight.addresses.delete.approve'})}
                  </button>
                </div>
              </div>
            </div>
          }
        />
      )}

      {/*UPDATE POPUP*/}
      {showUpdatePopup && (
        <EditAddresses
          updateAddress={updateAddress}
          setLoading={setLoading}
          cities={cities}
          getCityList={getCityList}
          countries={countries}
          updatedAddress={updatedAddress}
          setUpdatedAddress={setUpdatedAddress}
          latLong={latLong}
          setLatLong={setLatLong}
          onClose={() => setShowUpdatePopup(false)}
        />
      )}
    </>
  )
}
export {Addresses}
