import React, {Suspense, lazy} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '../../_metronic/partials'
import {DashboardWrapper} from '../pages/FreightOperator/Dashboard/DashboardWrapper'
import {DashboardWrapper as DashboardAdmin} from '../pages/Admin/Dashboard/DashboardWrapper'
import {DashboardWrapper as DashboardCarrier} from '../pages/Carrier/Dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {CompanyInformation} from '../pages/FreightOperator/CompanyInformation'
import {CreateNewAdvert} from '../pages/FreightOperator/CreateNewAdvert'
import {CreateQuickFreight} from '../pages/FreightOperator/CreateQuickFreight'
import {CreateCarrierQuickFreight} from '../pages/Carrier/CreateCarrierQuickFreight'
import {CarrierCreateNewAdvert} from '../pages/Carrier/CreateNewAdvert'
import {MyAdverts} from '../pages/FreightOperator/MyAdverts'
import {CarrierMyAdverts} from '../pages/Carrier/MyAdverts'
import {Users} from '../pages/FreightOperator/Users'
import {CreateNewUser} from '../pages/FreightOperator/CreateNewUser'
import {UserProfile} from '../pages/DefaultPages/UserProfile'
import {Adverts} from '../pages/Admin/Adverts'
import {Containers} from '../pages/Admin/Containers'
import {CarrierContainers} from '../pages/Carrier/Containers'
import {Transports} from '../pages/Admin/Transports'
import {CarrierTransports} from '../pages/Carrier/Transports'
import {TransporterGroup} from '../pages/Carrier/TransporterGroup'
import {shallowEqual, useSelector} from 'react-redux'
import {Drivers} from '../pages/Carrier/Drivers/Drivers'
import {RootState} from '../../setup'
import {Vehicles} from '../pages/Carrier/Vehicles/Vehicles'
import {Trailers} from '../pages/Carrier/Trailers/Trailers'
import {Transporters} from '../pages/Carrier/Transporters/Transporters'
import {Client} from '../pages/Carrier/Client/Client'
import {Document} from '../pages/Carrier/Document/Document'
import {SearchDocument} from '../pages/Carrier/SearchDocument/SearchDocument'
import {BankAccount} from '../pages/Carrier/BankAccount/BankAccount'
import {FreightPostings} from '../pages/Carrier/FreightPostings/FreightPostings'
import ManageProposals from '../pages/Admin/ManageProposals/ManageProposals'
import {PreferredRoute} from '../pages/Carrier/PreferredRoute/PreferredRoute'
import TrackOnMapFreight from '../pages/FreightOperator/TrackOnMap'
import TrackOnMapAdmin from '../pages/Admin/TrackOnMap'
import TrackOnMapCarrier from '../pages/Carrier/TrackOnMap'
import RegisterUsers from '../pages/Admin/RegisterUsers/RegisterUsers'
import {InvoiceAddress} from '../pages/FreightOperator/InvoiceAddress/InvoiceAddress'
import FileReview from '../pages/Admin/FileReview/FileReview'
import RenewPassword from '../pages/DefaultPages/RenewPassword/RenewPassword'
import {Addresses} from '../pages/FreightOperator/Addresses/Addresses'
import {CreateNewAddress} from '../pages/FreightOperator/CreateNewAdress'
import {VehicleBuyingSelling} from '../pages/FreightOperator/VehicleBuyingSelling/VehicleBuyingSelling'
import {MyListings} from '../pages/FreightOperator/MyListings/MyListings'
import {MyMessages} from '../pages/FreightOperator/MyMessages/MyMessages'
import TruckExchange from '../pages/Carrier/TruckExchange'
// import {ErrorsPage} from '../modules/errors/ErrorsPage'
import Companies from '../pages/Admin/Companies/Companies'

export function PrivateRoutes() {
  const BuilderPageWrapper = lazy(
    () => import('../pages/FreightOperator/layout-builder/BuilderPageWrapper')
  )
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))

  const role = useSelector<RootState>(({auth}) => auth.role, shallowEqual)
  const FREIGHT_OPERATOR = () => (
    <>
      <Route path='/fr-op/dashboard' component={DashboardWrapper} />
      <Route path='/fr-op/company-information' component={CompanyInformation} />
      <Route path='/fr-op/users/' component={Users} />
      <Route path='/fr-op/create-new-user/' component={CreateNewUser} />
      <Route path='/fr-op/addresses' component={Addresses} />
      <Route path='/fr-op/create-new-address' component={CreateNewAddress} />
      <Route path='/fr-op/my-adverts' component={MyAdverts} />
      <Route path='/fr-op/create-new-advert' component={CreateNewAdvert} />
      <Route path='/fr-op/create-quick-new-advert' component={CreateQuickFreight} />
      <Route path='/fr-op/track-on-map' component={TrackOnMapFreight} />
      <Route path='/fr-op/invoice-address' component={InvoiceAddress} />
      <Route path='/fr-op/client' component={Client} />
      <Route path='/fr-op/vehicle-buying-selling' component={VehicleBuyingSelling} />
      <Route path='/fr-op/my-listings' component={MyListings} />
      <Route path='/fr-op/my-messages' component={MyMessages} />
    </>
  )

  const SYSTEM_OPERATOR = () => (
    <>
      <Route path='/admin/dashboard' component={DashboardAdmin} />
      <Route path='/admin/adverts' component={Adverts} />
      <Route path='/admin/containers' component={Containers} />
      <Route path='/admin/transports' component={Transports} />
      <Route path='/admin/track-on-map' component={TrackOnMapAdmin} />
      <Route path='/admin/file-review' component={FileReview} />
      <Route path='/admin/companies' component={Companies} />
      <Route path='/auth/registration' component={RegisterUsers} />
      <Route path='/register' component={RegisterUsers} />
      <Route path='/admin/freight-postings' component={FreightPostings} />
      <Route path='/admin/manage-proposals' component={ManageProposals} />
      <Route path='/admin/available-vehicles' component={TruckExchange} />
    </>
  )

  const CARRIER_OPERATOR = () => (
    <>
      <Route path='/carrier/dashboard' component={DashboardCarrier} />
      <Route path='/carrier/create-new-advert' component={CarrierCreateNewAdvert} />
      <Route path='/carrier/create-quick-new-advert' component={CreateCarrierQuickFreight} />
      <Route path='/carrier/my-adverts' component={CarrierMyAdverts} />
      <Route path='/carrier/containers' component={CarrierContainers} />
      <Route path='/carrier/drivers' component={Drivers} />
      <Route path='/carrier/vehicles' component={Vehicles} />
      <Route path='/carrier/trailers' component={Trailers} />
      <Route path='/carrier/bank-account' component={BankAccount} />
      <Route path='/carrier/users/' component={Users} />
      <Route path='/carrier/create-new-user/' component={CreateNewUser} />
      <Route path='/carrier/addresses' component={Addresses} />
      <Route path='/carrier/invoice-address' component={InvoiceAddress} />
      <Route path='/carrier/freight-postings' component={FreightPostings} />
      <Route path='/carrier/preferred-route' component={PreferredRoute} />
      <Route path='/carrier/track-on-map' component={TrackOnMapCarrier} />
      <Route path='/carrier/transports' component={CarrierTransports} />
      <Route path='/carrier/transporters' component={Transporters} />
      <Route path='/carrier/transporter-groups' component={TransporterGroup} />
      <Route path='/carrier/client' component={Client} />
      <Route path='/carrier/document' component={Document} />
      <Route path='/carrier/search-document' component={SearchDocument} />
      <Route path='/carrier/company-information' component={CompanyInformation} />
      <Route path='/carrier/vehicle-buying-selling' component={VehicleBuyingSelling} />
      <Route path='/carrier/my-listings' component={MyListings} />
      <Route path='/carrier/my-messages' component={MyMessages} />
      <Route path='/carrier/available-vehicles' component={TruckExchange} />
    </>
  )

  const FORWARDER_OPERATOR = () => (
    <>
      <Route path='/forwarder/dashboard' component={DashboardCarrier} />
      <Route path='/forwarder/create-new-advert' component={CarrierCreateNewAdvert} />
      <Route path='/forwarder/create-quick-new-advert' component={CreateCarrierQuickFreight} />
      <Route path='/forwarder/my-adverts' component={CarrierMyAdverts} />
      <Route path='/forwarder/containers' component={CarrierContainers} />
      <Route path='/forwarder/bank-account' component={BankAccount} />
      <Route path='/forwarder/users/' component={Users} />
      <Route path='/forwarder/create-new-user/' component={CreateNewUser} />
      <Route path='/forwarder/addresses' component={Addresses} />
      <Route path='/forwarder/invoice-address' component={InvoiceAddress} />
      <Route path='/forwarder/freight-postings' component={FreightPostings} />
      <Route path='/forwarder/preferred-route' component={PreferredRoute} />
      <Route path='/forwarder/track-on-map' component={TrackOnMapCarrier} />
      <Route path='/forwarder/transports' component={CarrierTransports} />
      <Route path='/forwarder/transporters' component={Transporters} />
      <Route path='/forwarder/transporter-groups' component={TransporterGroup} />
      <Route path='/forwarder/client' component={Client} />
      <Route path='/forwarder/document' component={Document} />
      <Route path='/forwarder/search-document' component={SearchDocument} />
      <Route path='/forwarder/company-information' component={CompanyInformation} />
      <Route path='/forwarder/vehicle-buying-selling' component={VehicleBuyingSelling} />
      <Route path='/forwarder/my-listings' component={MyListings} />
      <Route path='/forwarder/my-messages' component={MyMessages} />
      <Route path='/forwarder/available-vehicles' component={TruckExchange} />
    </>
  )

  const ORGANIZATION_OWNER = () => (
    <>
      <Route path='/fr-op/dashboard' component={DashboardWrapper} />
      <Route path='/fr-op/company-information' component={CompanyInformation} />
      <Route path='/fr-op/users/' component={Users} />
      <Route path='/fr-op/create-new-user/' component={CreateNewUser} />
      <Route path='/fr-op/addresses' component={Addresses} />
      <Route path='/fr-op/create-new-address' component={CreateNewAddress} />
      <Route path='/fr-op/my-adverts' component={MyAdverts} />
      <Route path='/fr-op/create-new-advert' component={CreateNewAdvert} />
      <Route path='/fr-op/track-on-map' component={TrackOnMapFreight} />
      <Route path='/fr-op/invoice-address' component={InvoiceAddress} />

      <Route path='/admin/dashboard' component={DashboardAdmin} />
      <Route path='/admin/adverts' component={Adverts} />
      <Route path='/admin/containers' component={Containers} />
      <Route path='/admin/transports' component={Transports} />
      <Route path='/admin/track-on-map' component={TrackOnMapAdmin} />
      <Route path='/admin/file-review' component={FileReview} />
      <Route path='/auth/registration' component={RegisterUsers} />
      <Route path='/register' component={RegisterUsers} />
      <Route path='/admin/companies' component={Companies} />

      <Route path='/carrier/dashboard' component={DashboardCarrier} />
      <Route path='/carrier/drivers' component={Drivers} />
      <Route path='/carrier/vehicles' component={Vehicles} />
      <Route path='/carrier/trailers' component={Trailers} />
      <Route path='/carrier/bank-account' component={BankAccount} />
      <Route path='/carrier/preferred-route' component={PreferredRoute} />
      <Route path='/carrier/track-on-map' component={TrackOnMapCarrier} />
    </>
  )

  const roleRoute = (role: any) => (
    <>
      {role.includes('SYSTEM_OPERATOR') && SYSTEM_OPERATOR()}
      {role.includes('CARRIER_OPERATOR') && CARRIER_OPERATOR()}
      {role.includes('FORWARDER_OPERATOR') && FORWARDER_OPERATOR()}
      {role.includes('FREIGHT_OPERATOR') && FREIGHT_OPERATOR()}
      {role.includes('ORGANIZATION_OWNER') && ORGANIZATION_OWNER()}
    </>
  )

  const dashRoute = (roles: any, from: string = '/auth') => {
    if (roles.includes('ORGANIZATION_OWNER')) return <Redirect from={from} to='/admin/dashboard' />
    else if (roles.includes('SYSTEM_OPERATOR'))
      return <Redirect from={from} to='/admin/dashboard' />
    else if (roles.includes('CARRIER_OPERATOR'))
      return <Redirect from={from} to='/carrier/dashboard' />
    else if (roles.includes('FREIGHT_OPERATOR'))
      return <Redirect from={from} to='/fr-op/dashboard' />
    else if (roles.includes('FORWARDER_OPERATOR'))
      return <Redirect from={from} to='/forwarder/dashboard' />
    else return <Redirect from={from} to='/' />
  }

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        {dashRoute(role, '/auth')}

        {/*Metronic Routes*/}
        <Route path='/builder' component={BuilderPageWrapper} />
        <Route path='/crafted/pages/profile' component={ProfilePage} />
        <Route path='/crafted/pages/wizards' component={WizardsPage} />
        <Route path='/crafted/widgets' component={WidgetsPage} />
        <Route path='/crafted/account' component={AccountPage} />
        <Route path='/apps/chat' component={ChatPage} />
        <Route path='/menu-test' component={MenuTestPage} />

        <Route path='/renew-password' component={RenewPassword} />
        <Route path='/user-profile/' component={UserProfile} />

        {roleRoute(role ?? [])}

        <Redirect to={'/error/404'} />
      </Switch>
    </Suspense>
  )
}
