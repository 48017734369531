import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {put, takeLatest} from 'redux-saga/effects'
import {UserModel} from '../models/UserModel'
import {getUserByToken} from './AuthCRUD'

export interface ActionWithPayload<T> extends Action {
    payload?: T
}

export const actionTypes = {
    Login: '[Login] Action',
    Logout: '[Logout] Action',
    Register: '[Register] Action',
    UserRequested: '[Request User] Action',
    UserLoaded: '[Load User] auth API',
    SetUser: '[Set User] Action',
    UserRole: '[User Role] Action',
    ForceToChangePassword: '[Password Renew] Action',
}

const initialAuthState: IAuthState = {
    user: undefined,
    accessToken: undefined,
    role: undefined,
    forceToChangePassword: undefined,
}

export interface IAuthState {
    user?: UserModel;
    role?: any[];
    forceToChangePassword?: string;
    accessToken?: string;
}

export const reducer = persistReducer(
    {
        storage,
        key: 'rivalog-persist',
        whitelist: ['user', 'accessToken', 'role', 'forceToChangePassword']
    },
    (state: IAuthState = initialAuthState, action: ActionWithPayload<IAuthState>) => {
        switch (action.type) {
            case actionTypes.Login: {
                const accessToken = action.payload?.accessToken
                return {accessToken, user: undefined, role: undefined, forceToChangePassword: undefined}
            }

            case actionTypes.Register: {
                const accessToken = action.payload?.accessToken
                return {accessToken, user: undefined}
            }

            case actionTypes.Logout: {
                return initialAuthState
            }

            case actionTypes.UserRequested: {
                return {...state, user: undefined}
            }

            case actionTypes.UserLoaded: {
                const user = action.payload?.user
                return {...state, user}
            }

            case actionTypes.SetUser: {
                const user = action.payload?.user
                return {...state, user}
            }

            case actionTypes.UserRole: {
                const role = action.payload?.role
                return {...state, role}
            }
            case actionTypes.ForceToChangePassword: {
                const forceToChangePassword = action.payload?.forceToChangePassword
                return {...state, forceToChangePassword}
            }
            default:
                return state
        }
    }
)

export const actions = {
    login: (accessToken: string) => ({type: actionTypes.Login, payload: {accessToken}}),
    register: (accessToken: string) => ({
        type: actionTypes.Register,
        payload: {accessToken},
    }),
    logout: () => ({type: actionTypes.Logout}),
    requestUser: () => ({
        type: actionTypes.UserRequested,
    }),
    role: (role: any) => ({
        type: actionTypes.UserRole,
        payload: {role}
    }),
    forceToChangePassword: (forceToChangePassword: string) => ({
        type: actionTypes.ForceToChangePassword,
        payload: {forceToChangePassword}
    }),
    fulfillUser: (user: UserModel) => ({type: actionTypes.UserLoaded, payload: {user}}),
    setUser: (user: UserModel) => ({type: actionTypes.SetUser, payload: {user}}),
}

export function* saga() {
    yield takeLatest(actionTypes.Login, function* loginSaga() {
        yield put(actions.requestUser())
    })

    yield takeLatest(actionTypes.Register, function* registerSaga() {
        yield put(actions.requestUser())
    })

    yield takeLatest(actionTypes.UserRequested, function* userRequested() {
        const {data: user} = yield getUserByToken()
        yield put(actions.fulfillUser(user))
    })
}
