import React, {useState} from 'react'
import {useIntl} from 'react-intl'

import RightLayoutPopup from '../../../../../Components/RightLayoutPopup'

const RejectReview = ({selectedFile, rejectOnClick, setRejectReview}: any) => {
  const intl = useIntl()
  const [rejectText, setRejectText] = useState<string>('')

  return (
    <RightLayoutPopup
      title={intl.formatMessage({id: 'DataTableAdvanced.buttons.rejectApprove'})}
      className='file-review-view-wrap'
      isCloseIcon
      closeOnClick={() => setRejectReview(false)}
    >
      <div className={'d-flex align-items-center justify-content-center my-3 p-2'}>
        <input
          type='text'
          style={{marginRight: '15px'}}
          className={'form-control'}
          value={rejectText}
          placeholder={intl.formatMessage({id: 'ADMIN.FILEREVIEW.REJECTREASON'})}
          onChange={(e: any) => setRejectText(e.target.value)}
        />
        <button
          className={'btn btn-danger mr-3'}
          disabled={!rejectText}
          onClick={() => {
            setRejectReview(false)
            rejectOnClick({id: selectedFile?.id, ext: selectedFile?.ext, reason: rejectText})
          }}
          style={{marginRight: '15px'}}
        >
          {intl.formatMessage({id: 'DataTableAdvanced.buttons.rejectApprove'})}
        </button>
      </div>

      <div className='text-center mt-5 row view-details-freight-popup freightPost-popup-container'>
        <div className={'col-md-6 advert-details-modal-button-close'}>
          <button
            onClick={() => {
              setRejectReview(false)
            }}
            type='button'
            id='kt_sign_up_submit'
            className='btn btn-lg btn-danger w-100 mb-5 '
          >
            {intl.formatMessage({id: 'Carrier.freightPosting.view.close'})}
          </button>
        </div>
      </div>
    </RightLayoutPopup>
  )
}

export {RejectReview}
