import React from 'react'
import styles from './StatCard.module.scss'
import {IStatCard} from './types'
import {KTSVG} from '../../../_metronic/helpers'
import {useHistory} from 'react-router-dom'

const StatCard = ({
  url,
  title,
  value,
  icon,
  cardColor = 'success',
  externalHandleClick,
  isExternalClick,
  description = '',
}: IStatCard) => {
  const history = useHistory()
  return (
    <div
      className={
        `col px-6 py-10 rounded-2 d-flex flex-wrap align-items-center ${
          description ? 'justify-content-start' : 'justify-content-between'
        } ${isExternalClick ? 'cursor-pointer' : ''} bg-light-${cardColor} ` + styles.cardMain
      }
      onClick={() => {
        url && history.push(url)
        !url && isExternalClick && externalHandleClick()
      }}
    >
      <div>
        {icon && <KTSVG path={icon} className={'svg-icon-3x d-block my-2 svg-icon-' + cardColor} />}
        <p className={'fw-bold fs-6 mt-5 text-' + cardColor}>{title}</p>
      </div>
      {description && (
        <p className={'fw-bold description-card-wrap fs-6 mt-5 text-' + cardColor}>{description}</p>
      )}
      <h1 className={'m-0 fw-bold text-' + cardColor} style={{fontSize: '55px'}}>
        {value}
      </h1>
    </div>
  )
}

export default StatCard
