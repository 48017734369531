import React, {useEffect} from 'react'
import {useIntl} from 'react-intl'

import RightLayoutPopup from '../../../../Components/RightLayoutPopup'

import useScrollLock from '../../../../../utils/useScrollLock'
import getDateTime from '../../../../../utils/common'

import './../Adverts.scss'

const ShowDetails = ({currentData, onClose}: any) => {
  const intl = useIntl()
  const {scrollLock} = useScrollLock()
  useEffect(() => {
    scrollLock()
  }, [])

  return (
    <>
      <RightLayoutPopup
        title={intl.formatMessage({id: 'Admin.Adverts.detailsPopupTitle'})}
        className='freight-details-wrap'
        isCloseIcon
        closeOnClick={() => onClose()}
      >
        <div>
          {/** Form Görünümü **/}
          <div className={'mb-20 mt-10'}>
            <div className={'detail-modal-scroll-container'}>
              {/*Araç Seçenekleri -1-*/}
              <div className={'mb-10 '}>
                <h4 className={'form-label fw-bolder text-dark fs-3'}>
                  {intl.formatMessage({id: 'Admin.Adverts.Edit.Area-1-title'})}
                </h4>
                <hr />
                <div className={'row'}>
                  <div className={'col-md-6 mb-7'}>
                    <h6 className={'text-muted  '}>
                      {intl.formatMessage({id: 'Admin.Adverts.description'})}
                    </h6>
                    <b className={'fs-5'}>{currentData?.description}</b>
                  </div>
                  <div className={'col-md-6 mb-7'}>
                    <h6 className={'text-muted '}>
                      {intl.formatMessage({id: 'Admin.Adverts.trailerType'})}
                    </h6>
                    <b className={'fs-5'}>{currentData?.trailerType?.description}</b>
                  </div>
                  <div className={'col-md-6 mb-7'}>
                    <h6 className={'text-muted '}>
                      {intl.formatMessage({id: 'Admin.Adverts.floorType'})}
                    </h6>
                    <b className={'fs-5'}>{currentData?.floorType?.description}</b>
                  </div>

                  <div className={'col-md-6 mb-7'}>
                    <h6 className={'text-muted '}>
                      {intl.formatMessage({id: 'Admin.Adverts.specificationList'})}
                    </h6>
                    {currentData?.specificationList?.map((item: any, i: number) => (
                      <p key={i} className={'font-weight-bold text-bold fs-5'}>
                        {item?.description}
                      </p>
                    ))}
                  </div>

                  <div className={'col-md-6 mb-7'}>
                    <h6 className={'text-muted '}>
                      {intl.formatMessage({id: 'freight.details.content'})}
                    </h6>
                    <b className={'fs-5'}>{currentData?.freightContentType?.description}</b>
                  </div>
                </div>
              </div>

              {/*Tarih ve Zaman -2-*/}
              <div className={'mb-10'}>
                <h4 className={'form-label fw-bolder text-dark fs-3'}>
                  {intl.formatMessage({id: 'Admin.Adverts.Edit.Area-2-title'})}
                </h4>
                <hr />

                <div className={'row m-auto'}>
                  <div className={'col-md-6 mb-7'}>
                    <h6 className={'text-muted fs-6 '}>
                      {intl.formatMessage({id: 'Admin.Adverts.plannedDepartureDate'})}
                    </h6>
                    <b className={'fs-5'}>
                      {getDateTime(currentData?.plannedDepartureDate, intl.locale)}
                    </b>
                  </div>
                  {/* <div className={'col-md-6 mb-7'}>
                    <h6 className={'text-muted fs-6'}>
                      {intl.formatMessage({id: 'Admin.Adverts.plannedDepartureTime'})}
                    </h6>
                    <b className={'fs-5'}>{getTime(currentData?.plannedDepartureDate)}</b>
                  </div> */}
                  <div className={'col-md-6 mb-7'}>
                    <h6 className={'text-muted fs-6'}>
                      {intl.formatMessage({id: 'Freight.createAdvert.preview.plannedArrivalDate'})}
                    </h6>
                    <b className={'fs-5'}>
                      {getDateTime(currentData?.plannedArrivalDate, intl.locale)}
                    </b>
                  </div>
                  {/* <div className={'col-md-6 mb-7'}>
                    <h6 className={'text-muted fs-6'}>
                      {intl.formatMessage({id: 'Admin.Adverts.plannedArrivalTime'})}
                    </h6>
                    <b className={'fs-5'}>{getTime(currentData?.plannedArrivalDate)}</b>
                  </div> */}
                </div>
              </div>

              <div className='mb-10'>
                <div className='row d-flex justify-content-between'>
                  <h4 className={'form-label col-md-6 fw-bolder text-dark fs-3'}>
                    {intl.formatMessage({id: 'Carrier.freight.details.consignor'})}
                  </h4>

                  <h4 className={'form-label col-md-6 fw-bolder text-dark fs-3'}>
                    {intl.formatMessage({id: 'Carrier.freight.details.consignee'})}
                  </h4>
                </div>
                <hr />

                <div className={'row m-auto'}>
                  <div className='col-md-6'>
                    <div className='row'>
                      <div className={'col-md-6 mb-7'}>
                        <h6 className={'text-muted'}>
                          {intl.formatMessage({id: 'Carrier.freight.details.name'})}
                        </h6>
                        <b className={'fs-5'}>{currentData?.consignor?.name}</b>
                      </div>

                      <div className={'col-md-6 mb-7'}>
                        <h6 className={'text-muted'}>
                          {intl.formatMessage({id: 'Carrier.freight.details.surName'})}
                        </h6>
                        <b className={'fs-5'}>{currentData?.consignor?.surname}</b>
                      </div>

                      <div className={'col-md-6 mb-7'}>
                        <h6 className={'text-muted '}>
                          {intl.formatMessage({id: 'Carrier.freight.details.countryCode'})}
                        </h6>
                        <b className={'fs-5'}>{currentData?.consignor?.country?.phoneCode}</b>
                      </div>

                      <div className={'col-md-6 mb-7'}>
                        <h6 className={'text-muted  '}>
                          {intl.formatMessage({id: 'Carrier.freight.details.phone'})}
                        </h6>
                        <b className={'fs-5'}>{currentData?.consignor?.phone}</b>
                      </div>

                      <div className={'col-md-12 mb-7'}>
                        <h6 className={'text-muted'}>
                          {intl.formatMessage({id: 'Carrier.freight.details.email'})}
                        </h6>
                        <b className={'fs-5'}>{currentData?.consignor?.email}</b>
                      </div>
                    </div>
                  </div>

                  <div className='col-md-6'>
                    <div className='row'>
                      <div className={'col-md-6 mb-7'}>
                        <h6 className={'text-muted'}>
                          {intl.formatMessage({id: 'Carrier.freight.details.name'})}
                        </h6>
                        <b className={'fs-5'}>{currentData?.consignee?.name}</b>
                      </div>

                      <div className={'col-md-6 mb-7'}>
                        <h6 className={'text-muted'}>
                          {intl.formatMessage({id: 'Carrier.freight.details.surName'})}
                        </h6>
                        <b className={'fs-5'}>{currentData?.consignee?.surname}</b>
                      </div>

                      <div className={'col-md-6 mb-7'}>
                        <h6 className={'text-muted'}>
                          {intl.formatMessage({id: 'Carrier.freight.details.countryCode'})}
                        </h6>
                        <b className={'fs-5'}>{currentData?.consignee?.country?.phoneCode}</b>
                      </div>

                      <div className={'col-md-6 mb-7'}>
                        <h6 className={'text-muted '}>
                          {intl.formatMessage({id: 'Carrier.freight.details.phone'})}
                        </h6>
                        <b className={'fs-5'}>{currentData?.consignee?.phone}</b>
                      </div>

                      <div className={'col-md-12 mb-7'}>
                        <h6 className={'text-muted'}>
                          {intl.formatMessage({id: 'Carrier.freight.details.email'})}
                        </h6>
                        <b className={'fs-5'}>{currentData?.consignee?.email}</b>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/*Adres Tercihleri -3-*/}
              <div className={'mb-10'}>
                <h4 className={'form-label fw-bolder text-dark fs-3  '}>
                  {intl.formatMessage({id: 'Admin.Adverts.Edit.Area-3-title'})}
                </h4>
                <hr />
                <div className={'row m-auto'}>
                  <div className={'col-md-6 mb-7'}>
                    <h6 className={'text-muted  '}>
                      {intl.formatMessage({id: 'Admin.Adverts.fromLocation'})}
                    </h6>
                    <b className={'fs-5'}>{currentData?.fromAddress?.addressText}</b>
                  </div>
                  <div className={'col-md-6 mb-7'}>
                    <h6 className={'text-muted '}>
                      {intl.formatMessage({id: 'Admin.Adverts.toLocation'})}
                    </h6>
                    <b className={'fs-5'}>{currentData?.toAddress?.addressText}</b>
                  </div>
                </div>

                <div className={'row'} style={{margin: 0}}>
                  {currentData?.fromAddress && (
                    <div className={'col-md-6 '} style={{paddingLeft: 0}}>
                      <div className={'row border border-2 rounded py-4'} style={{margin: 0}}>
                        <div className={'col-md-4 mb-5 '}>
                          <p className={'mb-0 text-muted'}>
                            {intl.formatMessage({id: 'Freight.createAdvert.departure.explanation'})}
                          </p>
                          <b>{currentData?.fromAddress?.description}</b>
                        </div>
                        <div className={'col-md-4 mb-5 '}>
                          <p className={'mb-0 text-muted'}>
                            {intl.formatMessage({id: 'Freight.createAdvert.departure.country'})}
                          </p>
                          <b>{currentData?.fromAddress?.country?.name}</b>
                        </div>
                        <div className={'col-md-4 mb-5 '}>
                          <p className={'mb-0 text-muted'}>
                            {intl.formatMessage({id: 'Freight.createAdvert.departure.city'})}
                          </p>
                          <b>{currentData?.fromAddress?.city?.name}</b>
                        </div>
                        <div className={'col-md-4 mb-5 '}>
                          <p className={'mb-0 text-muted'}>
                            {intl.formatMessage({id: 'Freight.createAdvert.departure.town'})}
                          </p>
                          <b>{currentData?.fromAddress?.town}</b>
                        </div>
                        <div className={'col-md-4 mb-5 '}>
                          <p className={'mb-0 text-muted'}>
                            {intl.formatMessage({id: 'Freight.createAdvert.departure.district'})}
                          </p>
                          <b>{currentData?.fromAddress?.district}</b>
                        </div>
                        <div className={'col-md-4 mb-5 '}>
                          <p className={'mb-0 text-muted'}>
                            {intl.formatMessage({id: 'Freight.createAdvert.departure.postCode'})}
                          </p>
                          <b>{currentData?.fromAddress?.postCode}</b>
                        </div>
                      </div>
                    </div>
                  )}
                  {currentData?.toAddress && (
                    <div className={'col-md-6 '} style={{paddingRight: 0}}>
                      <div className={'row border border-2 rounded py-4'} style={{margin: 0}}>
                        <div className={'col-md-4 mb-5 '}>
                          <p className={'mb-0 text-muted'}>
                            {intl.formatMessage({id: 'Freight.createAdvert.arrival.explanation'})}
                          </p>
                          <b>{currentData?.toAddress?.description}</b>
                        </div>
                        <div className={'col-md-4 mb-5 '}>
                          <p className={'mb-0 text-muted'}>
                            {intl.formatMessage({id: 'Freight.createAdvert.arrival.country'})}
                          </p>
                          <b>{currentData?.toAddress?.country?.name}</b>
                        </div>
                        <div className={'col-md-4 mb-5 '}>
                          <p className={'mb-0 text-muted'}>
                            {intl.formatMessage({id: 'Freight.createAdvert.arrival.city'})}
                          </p>
                          <b>{currentData?.toAddress?.city?.name}</b>
                        </div>
                        <div className={'col-md-4 mb-5 '}>
                          <p className={'mb-0 text-muted'}>
                            {intl.formatMessage({id: 'Freight.createAdvert.arrival.district'})}
                          </p>
                          <b>{currentData?.toAddress?.town}</b>
                        </div>
                        <div className={'col-md-4 mb-5 '}>
                          <p className={'mb-0 text-muted'}>
                            {intl.formatMessage({id: 'Freight.createAdvert.arrival.dis'})}
                          </p>
                          <b>{currentData?.toAddress?.district}</b>
                        </div>
                        <div className={'col-md-4 mb-5 '}>
                          <p className={'mb-0 text-muted'}>
                            {intl.formatMessage({id: 'Freight.createAdvert.arrival.postCode'})}
                          </p>
                          <b>{currentData?.toAddress?.postCode}</b>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {/* mal Bilgileri -4*/}
              <div className={''}>
                <h4 className={'form-label fw-bolder text-dark fs-3'}>
                  {intl.formatMessage({id: 'Admin.Adverts.Edit.Area-4-title'})}
                </h4>
                <hr />
                <div className={'row m-auto'}>
                  <div className={'col-md-4 mb-7'}>
                    <h6 className={'text-muted  '}>
                      {intl.formatMessage({id: 'Admin.Adverts.value'})}
                    </h6>
                    <b className={'fs-5'}>{currentData?.value}</b>
                  </div>
                  <div className={'col-md-4 mb-7'}>
                    <h6 className={'text-muted '}>
                      {intl.formatMessage({id: 'Admin.Adverts.currency'})}
                    </h6>
                    <b className={'fs-5'}>{currentData?.valueCurrency?.description}</b>
                  </div>
                  <div className={'col-md-4 mb-7'}>
                    <h6 className={'text-muted '}>
                      {intl.formatMessage({id: 'Admin.Adverts.freightType'})}
                    </h6>
                    <b className={'fs-5'}>{currentData?.freightType?.description}</b>
                  </div>
                  <div className={'col-md-4 mb-7'}>
                    <h6 className={'text-muted '}>
                      {intl.formatMessage({id: 'Admin.Adverts.packageType'})}
                    </h6>
                    <b className={'fs-5'}>{currentData?.freightPackageType?.description}</b>
                  </div>
                  <div className={'col-md-4 mb-7'}>
                    <h6 className={'text-muted '}>
                      {intl.formatMessage({id: 'Admin.Adverts.loadingType'})}
                    </h6>
                    <b className={'fs-5'}>{currentData?.freightLoadingType?.description}</b>
                  </div>
                  <div className={'col-md-4 mb-7'}>
                    <h6 className={'text-muted text-bold'}>
                      {intl.formatMessage({id: 'Admin.Adverts.gtip'})}
                    </h6>
                    <p className={'fs-5 mb-0'}>
                      <b>{currentData?.harmonizedSystemCode?.code}</b>
                    </p>
                    <b className={'fs-5'}>{currentData?.harmonizedSystemCode?.description}</b>
                  </div>
                  <div className={'col-md-4 mb-7'}>
                    <h6 className={'text-muted '}>
                      {intl.formatMessage({id: 'Admin.Adverts.length'})}
                    </h6>
                    <b className={'fs-5'}>{currentData?.length}</b>
                  </div>
                  <div className={'col-md-4 mb-7'}>
                    <h6 className={'text-muted '}>
                      {intl.formatMessage({id: 'Admin.Adverts.width'})}
                    </h6>
                    <b className={'fs-5'}>{currentData?.width}</b>
                  </div>
                  <div className={'col-md-4 mb-7'}>
                    <h6 className={'text-muted '}>
                      {intl.formatMessage({id: 'Admin.Adverts.height'})}
                    </h6>
                    <b className={'fs-5'}>{currentData?.height}</b>
                  </div>
                  <div className={'col-md-4 mb-7'}>
                    <h6 className={'text-muted '}>
                      {intl.formatMessage({id: 'Admin.Adverts.grossKg'})}
                    </h6>
                    <b className={'fs-5'}>{currentData?.weight}</b>
                  </div>
                  <div className={'col-md-4 mb-7'}>
                    <h6 className={'text-muted '}>
                      {intl.formatMessage({id: 'Admin.Adverts.desi'})}
                    </h6>
                    <b className={'fs-5'}>{currentData?.desi}</b>
                  </div>
                  <div className={'col-md-4 mb-7'}>
                    <h6 className={'text-muted '}>
                      {intl.formatMessage({id: 'Admin.Adverts.ldm'})}
                    </h6>
                    <b className={'fs-5'}>{currentData?.ldm}</b>
                  </div>

                  <div className={'col-md-4 mb-7'}>
                    <h6 className={'text-muted '}>
                      {intl.formatMessage({id: 'Admin.Adverts.adr1'})}
                    </h6>
                    <b className={'fs-5'}>
                      {currentData?.flammable === 'Y'
                        ? intl.formatMessage({id: 'Freight.myAdvert.yes'})
                        : intl.formatMessage({id: 'Freight.myAdvert.no'})}
                    </b>
                  </div>
                  <div className={'col-md-4 mb-7'}>
                    <h6 className={'text-muted '}>
                      {intl.formatMessage({id: 'Admin.Adverts.stackable'})}
                    </h6>
                    <b className={'fs-5'}>
                      {currentData?.stackable === 'Y'
                        ? intl.formatMessage({id: 'Freight.myAdvert.yes'})
                        : intl.formatMessage({id: 'Freight.myAdvert.no'})}
                    </b>
                  </div>
                </div>
                <hr />
              </div>
            </div>
          </div>

          {/*Add adress button area*/}
          <div className='text-center mt-5 row advert-details-modal-button-close'>
            <div className={'col-md-6'}>
              <button
                onClick={onClose}
                type='button'
                id='kt_sign_up_submit'
                className='btn btn-lg btn-danger w-100 mb-5 '
              >
                {intl.formatMessage({id: 'Carrier.freightPosting.view.close'})}
              </button>
            </div>
          </div>
        </div>
      </RightLayoutPopup>
    </>
  )
}

export default ShowDetails
