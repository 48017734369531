import React, {useEffect, useRef, useState} from 'react'
import {IDropbox} from './types'
import styles from './Dropbox.module.scss'
import useOuterClick from '../../../utils/useOuterClick'
import LoadAnim from '../LoadAnim/LoadAnim'
import {useIntl} from 'react-intl'

const Dropbox = ({
  id,
  menuList = [],
  placeholder,
  selectedItemData,
  customSelectedData,
  style,
  customClass,
  customStyle,
  disabled,
  hideArrow,
  customOnSearch,
  customSearchValue,
  currentPageIndex,
  onScroll,
  defaultSelected,
  dependentValue,
  disabledSearch,
  isTemplate,
  dropBoxClass,
  menuClassWrap,
  setScrollCalled,
  scrollAPIResponse,
  defaultClearItem,
}: IDropbox) => {
  const [show, setShow] = useState<boolean>(false)
  const [load, setLoad] = useState<boolean>(true)
  const [blur, setBlur] = useState<boolean>(false)
  const [selected, setSelected] = useState<any>({})
  const [defaultSelectedItem, setDefaultSelectedItem] = useState<any>({})
  const [searchText, setSearchText] = useState<string>('')
  const [counter, setCounter] = useState<number>(0)
  const intl = useIntl()
  const divRef: any = useRef(null)

  useEffect(() => {
    if (customOnSearch && divRef.current) {
      setLoad(false)
      const handleScroll = () => {
        if (
          divRef.current.scrollTop + divRef.current.clientHeight * 2 >=
            divRef.current.scrollHeight &&
          !scrollAPIResponse
        ) {
          setScrollCalled(true)
          setLoad(true)
          setCounter(counter + 1)
          onScroll(currentPageIndex + 1)
        }
      }
      divRef.current.addEventListener('scroll', handleScroll)
      return () => divRef.current && divRef.current.removeEventListener('scroll', handleScroll)
    }
  }, [counter, scrollAPIResponse])

  const innerRef = useOuterClick((ev: any) => {
    setShow(false)
  })

  useEffect(() => {
    if (dependentValue) {
      setSelected({id: '', title: ''})
      selectedItemData({id: '', title: ''})
    }
  }, [dependentValue])

  useEffect(() => {
    customSelectedData &&
      Object.keys(customSelectedData).length > 0 &&
      setSelected(customSelectedData)
  }, [])

  useEffect(() => {
    if (defaultSelected && Object.keys(defaultSelected).length > 0) {
      setSelected(defaultSelected)
    }
  }, [])

  useEffect(() => {
    if (selected && typeof selected !== 'string' && Object.keys(selected).length > 0) {
      selectedItemData && selectedItemData(selected)
    } else {
      if (selected && typeof selected === 'string') {
        selectedItemData(selected)
        setSelected(selected)
      } else if (typeof selected === 'object') {
        selectedItemData(defaultSelected)
        setSelected(defaultSelected)
      }
    }
  }, [selected])

  useEffect(() => {
    if (blur) {
      if (
        !selected &&
        defaultSelected &&
        Object.keys(defaultSelected).length > 0 &&
        defaultSelected?.id
      ) {
        selectedItemData(defaultClearItem)
        setSelected(defaultClearItem)
      }
      setBlur(false)
    }
  }, [blur])

  const handleOnBlur = (value: boolean) => {
    setBlur(value)
  }

  return (
    <div className={`${styles.dropMain} ${dropBoxClass}`} style={style} ref={innerRef}>
      {disabledSearch ? (
        <button
          id={id}
          style={{textAlign: 'left', ...(customStyle && {...customStyle})}}
          disabled={disabled}
          className={styles.input + ' ' + customClass}
          onClick={() => {
            setShow(!show)
          }}
        >
          {selected?.title || placeholder}
        </button>
      ) : (
        <input
          id={id}
          style={{textAlign: 'left', ...(customStyle && {...customStyle})}}
          className={styles.input + ' ' + customClass}
          onClick={() => {
            setShow(!show)
          }}
          onChange={(e: any) => {
            customOnSearch(e)
            setSelected(e.target.value)
            setSearchText(e.target.value)
            divRef && (divRef.current.scrollTop = 0)
          }}
          onBlur={() => {
            setTimeout(() => {
              handleOnBlur(true)
            }, 200)
          }}
          disabled={disabled}
          placeholder={placeholder}
          value={selected?.title}
        />
      )}
      {!hideArrow && (
        <span className={styles.arrow + ' ' + (show && styles.arrowUp)}>
          <img
            className={styles.img}
            src={'/media/icons/duotune/arrows/arr072.svg'}
            alt={'Down Arrow'}
          />
        </span>
      )}
      <div
        className={`${styles.menu} ${menuClassWrap}`}
        ref={divRef}
        style={{display: show ? 'block' : 'none'}}
      >
        {customOnSearch ? null : (
          // <input type="text"
          //                          placeholder={placeholder}
          //                          value={customSearchValue}
          //                          onChange={(e: any) => {
          //                              customOnSearch(e);
          //                              divRef && (divRef.current.scrollTop = 0);
          //                          }}
          //                          className={'form-control mt-2'}
          //                          style={{width: '90%', margin: "auto", position: "sticky", top: 0}}
          // />
          <input
            type='text'
            placeholder={placeholder}
            value={searchText}
            onChange={(e: any) => {
              setSearchText(e.target.value)
            }}
            className={'form-control mt-2'}
            style={{width: '90%', margin: 'auto', position: 'sticky', top: 0}}
          />
        )}
        <ul key={menuList.length}>
          {menuList &&
            menuList
              ?.filter((it: any) =>
                it?.title && !isTemplate
                  ? it?.title?.toLowerCase()?.includes(searchText?.toLowerCase())
                  : it
              )
              ?.map((item: any, key: number) => {
                return (
                  <li
                    key={item.id}
                    onClick={() => {
                      setSelected(
                        !isTemplate || disabledSearch
                          ? item
                          : {
                              id: item?.id,
                              title: item?.title?.props?.children.find(
                                (dataItem: any) => typeof dataItem === 'string'
                              ),
                            }
                      )
                      setDefaultSelectedItem(
                        !isTemplate || disabledSearch
                          ? item
                          : {
                              id: item?.id,
                              title: item?.title?.props?.children.find(
                                (dataItem: any) => typeof dataItem === 'string'
                              ),
                            }
                      )
                      setShow(false)
                    }}
                  >
                    {item.title}
                  </li>
                )
              })}
          {customOnSearch && load ? (
            <div className={'d-flex align-items-center justify-content-center '}>
              <LoadAnim />
            </div>
          ) : (
            customOnSearch && (
              <p className={'text-muted text-center'}>
                {intl.formatMessage({id: 'Dropbox.noMoreData'})}
              </p>
            )
          )}
        </ul>
      </div>
    </div>
  )
}

export default Dropbox

/* Data Örneği
   {
   id:1,
   title:Merhaba,
   }
*/
