import React, {useEffect} from 'react'
import {useIntl} from 'react-intl'
import clsx from 'clsx'

import RightLayoutPopup from '../../../../Components/RightLayoutPopup'
import PhoneInp from '../../../../Components/PhoneInp/PhoneInp'

import useScrollLock from '../../../../../utils/useScrollLock'

const EditDriver = ({setUpdatedDriver, updatedDriver, setLoading, updateDriver, onClose}: any) => {
  const intl = useIntl()
  const {scrollLock} = useScrollLock()
  useEffect(() => {
    scrollLock()
  }, [])

  return (
    <RightLayoutPopup
      title={intl.formatMessage({id: 'Carrier.drivers.update.updateDrver'})}
      className='edit-driver-right-side-wrap'
      isCloseIcon
      closeOnClick={() => onClose()}
    >
      <div className={''}>
        <div className='fv-row mb-10'>
          <label className='form-label fs-6 fw-bolder text-dark'>
            {intl.formatMessage({id: 'Carrier.drivers.update.name'})}
          </label>
          <input
            placeholder={intl.formatMessage({
              id: 'Carrier.drivers.update.name.placeHolder',
            })}
            className={clsx('form-control form-control-lg form-control-solid')}
            onChange={(event: any) =>
              setUpdatedDriver((item: any) => ({
                ...item,
                name: event.target.value,
              }))
            }
            value={updatedDriver.name}
            type='text'
            name='name'
            autoComplete='off'
          />
        </div>
        <div className='fv-row mb-10'>
          <label className='form-label fs-6 fw-bolder text-dark'>
            {intl.formatMessage({id: 'Carrier.drivers.update.lastName'})}
          </label>
          <input
            placeholder={intl.formatMessage({
              id: 'Carrier.drivers.update.lastName.placeHolder',
            })}
            className={clsx('form-control form-control-lg form-control-solid')}
            onChange={(event: any) =>
              setUpdatedDriver((item: any) => ({
                ...item,
                surname: event.target.value,
              }))
            }
            value={updatedDriver.surname}
            type='text'
            name='surname'
            autoComplete='off'
          />
        </div>
        <div className={'row col-md-8'}>
          <div className={'col-md-4 mt-5'}>
            <label htmlFor={'phoneCode'} className='form-label fs-6 fw-bolder text-dark mb-2'>
              {intl.formatMessage({id: 'Carrier.drivers.update.phoneCode'})}
            </label>
            <PhoneInp
              id={'phoneCode'}
              defaultValue={updatedDriver?.phoneCode}
              onSelect={(event: any) =>
                setUpdatedDriver((item: any) => ({
                  ...item,
                  phoneCode: event.length > 0 ? '+' + event : event,
                }))
              }
            />
          </div>
          <div className='fv-row col-md-8 mt-5'>
            <label className='form-label fs-6 fw-bolder text-dark'>
              {intl.formatMessage({id: 'Carrier.drivers.update.phone'})}
            </label>
            <input
              placeholder={intl.formatMessage({
                id: 'Carrier.drivers.update.phone.placeHolder',
              })}
              maxLength={13}
              className={clsx('form-control form-control-lg form-control-solid')}
              onChange={(event: any) => {
                if (event.target.value.match(/^\+?(?:[\d]*)$/)) {
                  setUpdatedDriver((item: any) => ({
                    ...item,
                    mobile: event.target.value,
                  }))
                }
              }}
              value={updatedDriver.mobile}
              type='text'
              name='mobile'
              autoComplete='off'
              required
            />
          </div>
        </div>
      </div>

      <div className='text-center mt-5 row freightPost-popup-container'>
        <div className={'col-md-6'}>
          <button
            onClick={onClose}
            type='button'
            id='kt_sign_up_submit'
            className='btn btn-lg btn-danger w-100 mb-5 '
          >
            {intl.formatMessage({id: 'CreateNewContainerPopup.cancel'})}
          </button>
        </div>
        <div className={'col-md-6'}>
          <button
            className={'btn btn-success px-10 m-auto w-100'}
            disabled={
              !updatedDriver.name ||
              !updatedDriver.surname ||
              !updatedDriver.mobile ||
              !updatedDriver.phoneCode
            }
            onClick={() => {
              setLoading(true)
              updateDriver(updatedDriver)
            }}
          >
            {intl.formatMessage({id: 'Carrier.drivers.update.update'})}
          </button>
        </div>
      </div>
    </RightLayoutPopup>
  )
}

export default EditDriver
