import React from 'react'
import {Registration} from '../../auth/components/Registration'

const RegisterUsers = () => {
  return (
    <div className={'card p-10'}>
      <Registration hideLogin />
    </div>
  )
}

export default RegisterUsers
