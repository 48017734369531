import React, {useEffect, useRef, useState, memo} from 'react'
import {getMapVehicleIcon} from '../../../../utils/common'
import './Map.scss'
import {useIntl} from 'react-intl'

interface IMap {
  mapType: google.maps.MapTypeId
  mapTypeControl?: boolean
  setDistanceInKm: React.Dispatch<React.SetStateAction<number>>
  location?: any
  customLocation?: any
  height?: string
  width?: string
  customMarkerList?: any
  isUnClickable?: boolean
  fullscreenControl?: boolean
  searchLat?: any
  searchLong?: any
  searchLabel?: any
  isCenterCoordinate?: any
  setCenterCoordinate?: any
  externalSelectedData?: any
}

interface IMarker {
  address: string
  latitude: number
  longitude: number
}

type GoogleLatLng = google.maps.LatLng
type GoogleMap = google.maps.Map
type GoogleMarker = google.maps.Marker
type GooglePolyline = google.maps.Polyline

const Map: React.FC<IMap> = ({
  mapType,
  mapTypeControl = false,
  setDistanceInKm,
  location,
  customLocation,
  height,
  width,
  customMarkerList,
  isUnClickable,
  fullscreenControl,
  searchLong,
  searchLat,
  searchLabel,
  isCenterCoordinate = false,
  setCenterCoordinate,
  externalSelectedData,
}: any) => {
  const intl = useIntl()
  const ref = useRef<HTMLDivElement>(null)
  const [map, setMap] = useState<GoogleMap>()
  const [marker, setMarker] = useState<IMarker>()
  const [homeMarker, setHomeMarker] = useState<GoogleMarker>()
  const [googleMarkers, setGoogleMarkers] = useState<GoogleMarker[]>([])
  const [selectedAddress, setSelectedAddress] = useState<string>('')
  const [tarnsporterData, setTransporterData] = useState<any>([])
  /*    const [listenerIdArray, setListenerIdArray] = useState<any[]>([]);
        const [LastLineHook, setLastLineHook] = useState<GooglePolyline>();*/

  const startMap = (): void => {
    if (!map) {
      defaultMapStart()
    } else {
      const homeLocation = new google.maps.LatLng(
        customLocation?.lat ? customLocation.lat : 38.96272541161338,
        customLocation?.lot ? customLocation.lot : 35.49645569209267
      )
      setHomeMarker(addHomeMarker(homeLocation))
    }
  }

  useEffect(() => {
    if (isCenterCoordinate && map) {
      google.maps.event.addListener(map, 'dragend', function () {
        setCenterCoordinate({latitude: map?.getCenter()?.lat(), longitude: map?.getCenter()?.lng()})
      })
    }
  }, [map])

  useEffect(startMap, [map])
  useEffect(() => {
    if (!map) {
      defaultMapStart()
    }
  }, [customMarkerList, map])

  const showInfoCard = (text: string, marker: any) => {
    const contentString = '<div>' + text + '<div/>'

    let infowindow = new google.maps.InfoWindow({
      content: contentString,
    })
    return infowindow.open(map, marker)
  }

  const setExternalCustomMarker = (extData: any, marker: any) => {
    const infoWindow = new google.maps.InfoWindow()
    infoWindow.setContent(
      "<div style = 'padding: 5px;'><b style='margin-bottom: 15px;'>" +
        (extData?.data?.title ? extData?.data?.title : '') +
        '</b><hr>' +
        "<ul style='list-style: none; padding-left: 5px'>" +
        (extData?.data?.driver?.companyName
          ? '<li>' +
            intl.formatMessage({id: 'Map.companyName'}) +
            extData?.data?.driver?.companyName +
            '</li>'
          : '') +
        (extData?.data?.driver?.email
          ? '<li>' + intl.formatMessage({id: 'Map.email'}) + extData?.data?.driver?.email + '</li>'
          : '') +
        (extData?.data?.driver?.phone
          ? '<li>' +
            intl.formatMessage({id: 'Map.companyPhone'}) +
            extData?.data?.driver?.phone +
            '</li>'
          : '') +
        (extData?.data?.date
          ? '<li>' + intl.formatMessage({id: 'Map.lastUpdate'}) + extData?.data?.date + '</li>'
          : '') +
        (extData?.data?.driver?.name || extData?.data?.driver?.mobile ? '<hr>' : '') +
        (extData?.data?.driver?.name || extData?.data?.driver?.mobile
          ? "<li style='margin-bottom: 5px;'>" +
            intl.formatMessage({id: 'Map.driverTitle'}) +
            '</li>'
          : '') +
        (extData?.data?.driver?.name
          ? '<li>' +
            intl.formatMessage({id: 'Map.driverName'}) +
            extData?.data?.driver?.name +
            ' ' +
            extData?.data?.driver?.surname +
            '</li>'
          : '') +
        (extData?.data?.driver?.mobile
          ? '<li>' +
            intl.formatMessage({id: 'Map.driverPhone'}) +
            extData?.data?.driver?.mobile +
            '</li>'
          : '') +
        '<hr>' +
        "<li style='margin-bottom: 5px;'>" +
        intl.formatMessage({id: 'Map.vehicleTitle'}) +
        '</li>' +
        (extData?.data?.vehicle?.plate
          ? '<li>' +
            intl.formatMessage({id: 'Map.vehiclePlate'}) +
            extData?.data?.vehicle?.plate +
            '</li>'
          : '') +
        (extData?.data?.vehicle?.make
          ? '<li>' +
            intl.formatMessage({id: 'Map.vehicleBrand'}) +
            extData?.data?.vehicle?.make +
            '</li>'
          : '') +
        (extData?.data?.trailer?.plate
          ? '<li>' +
            intl.formatMessage({id: 'Map.trailerPlate'}) +
            extData?.data?.trailer?.plate +
            '</li>'
          : '') +
        (extData?.data?.trailer?.type
          ? '<li>' +
            intl.formatMessage({id: 'Admin.Adverts.trailerType'}) +
            ':  ' +
            extData?.data?.trailer?.type +
            '</li>'
          : '') +
        '<hr>' +
        "<li style='margin-bottom: 5px; text-transform: uppercase;'>" +
        intl.formatMessage({id: 'Admin.Adverts.specificationList'}) +
        '</li>' +
        extData?.data?.trailer?.specifications
          ?.map((item: any) => '<li>' + item?.description + '</li>')
          .join("<br style='display: none;'>") +
        '</ul></div>'
    )
    infoWindow.open(map, marker)
    map?.panTo(extData?.position)
  }

  useEffect(() => {
    if (externalSelectedData) {
      const resultData = customMarkerList?.find(
        (item: any) => externalSelectedData === item?.data?.driver?.id
      )
      const findData = googleMarkers.find(
        (item: any) =>
          item?.position.lat() === resultData?.position?.lat() &&
          item?.position.lng() === resultData?.position?.lng()
      )
      setExternalCustomMarker(resultData, findData)
    }
  }, [externalSelectedData])

  /*Custom Marker List Add*/
  const setCustomMarkers = () => {
    if (customMarkerList && Array.isArray(customMarkerList) && customMarkerList.length) {
      if (!isCenterCoordinate) DeleteMarkers()
      const resultData = customMarkerList?.map((item: any) => item?.data?.driver?.id)

      const infoWindow = new google.maps.InfoWindow()
      for (const item of customMarkerList) {
        if (isCenterCoordinate) {
          if (!tarnsporterData.includes(item?.data?.driver?.id)) {
            let marker: GoogleMarker
            marker = new google.maps.Marker({
              position: item?.position,
              clickable: true,
              animation: google.maps.Animation.DROP,
              icon:
                item.type === 'truck'
                  ? item?.data?.status === 'LOOKING_FOR_FREIGHT'
                    ? item?.data?.vehicle?.vehicleTypeId
                      ? getMapVehicleIcon[item?.data?.vehicle?.vehicleTypeId]
                      : null
                    : item?.data?.status === 'NOT_WORKING'
                    ? item?.data?.vehicle?.vehicleTypeId
                      ? getMapVehicleIcon[item?.data?.vehicle?.vehicleTypeId + 'a']
                      : null
                    : item?.data?.vehicle?.vehicleTypeId
                    ? getMapVehicleIcon[item?.data?.vehicle?.vehicleTypeId + 'b']
                    : null
                  : null,
              map: map,
            })
            ;(function (marker, data) {
              google.maps.event.addListener(marker, 'click', function () {
                infoWindow.setContent(
                  "<div style = 'padding: 5px;'><b style='margin-bottom: 15px;'>" +
                    (data?.title ? data?.title : '') +
                    '</b><hr>' +
                    "<ul style='list-style: none; padding-left: 5px'>" +
                    (data?.driver?.companyName
                      ? '<li>' +
                        intl.formatMessage({id: 'Map.companyName'}) +
                        data?.driver?.companyName +
                        '</li>'
                      : '') +
                    (data?.driver?.email
                      ? '<li>' +
                        intl.formatMessage({id: 'Map.email'}) +
                        data?.driver?.email +
                        '</li>'
                      : '') +
                    (data?.driver?.phone
                      ? '<li>' +
                        intl.formatMessage({id: 'Map.companyPhone'}) +
                        data?.driver?.phone +
                        '</li>'
                      : '') +
                    (data?.date
                      ? '<li>' + intl.formatMessage({id: 'Map.lastUpdate'}) + data?.date + '</li>'
                      : '') +
                    (data?.driver?.name || data?.driver?.mobile ? '<hr>' : '') +
                    (data?.driver?.name || data?.driver?.mobile
                      ? "<li style='margin-bottom: 5px;'>" +
                        intl.formatMessage({id: 'Map.driverTitle'}) +
                        '</li>'
                      : '') +
                    (data?.driver?.name
                      ? '<li>' +
                        intl.formatMessage({id: 'Map.driverName'}) +
                        data?.driver?.name +
                        ' ' +
                        data?.driver?.surname +
                        '</li>'
                      : '') +
                    (data?.driver?.mobile
                      ? '<li>' +
                        intl.formatMessage({id: 'Map.driverPhone'}) +
                        data?.driver?.mobile +
                        '</li>'
                      : '') +
                    '<hr>' +
                    "<li style='margin-bottom: 5px;'>" +
                    intl.formatMessage({id: 'Map.vehicleTitle'}) +
                    '</li>' +
                    (data?.vehicle?.plate
                      ? '<li>' +
                        intl.formatMessage({id: 'Map.vehiclePlate'}) +
                        data?.vehicle?.plate +
                        '</li>'
                      : '') +
                    (data?.vehicle?.make
                      ? '<li>' +
                        intl.formatMessage({id: 'Map.vehicleBrand'}) +
                        data?.vehicle?.make +
                        '</li>'
                      : '') +
                    (data?.trailer?.plate
                      ? '<li>' +
                        intl.formatMessage({id: 'Map.trailerPlate'}) +
                        data?.trailer?.plate +
                        '</li>'
                      : '') +
                    (data?.trailer?.type
                      ? '<li>' +
                        intl.formatMessage({id: 'Admin.Adverts.trailerType'}) +
                        ':  ' +
                        data?.trailer?.type +
                        '</li>'
                      : '') +
                    '<hr>' +
                    "<li style='margin-bottom: 5px; text-transform: uppercase;'>" +
                    intl.formatMessage({id: 'Admin.Adverts.specificationList'}) +
                    '</li>' +
                    data?.trailer?.specifications
                      ?.map((item: any) => '<li>' + item?.description + '</li>')
                      .join("<br style='display: none;'>") +
                    '</ul></div>'
                )
                infoWindow.open(map, marker)
                map?.panTo(item.position)
                // map?.setZoom(14)
              })
            })(marker, item.data)
            setGoogleMarkers((val: any) => [...val, marker])
          }
        } else {
          let marker: GoogleMarker
          marker = new google.maps.Marker({
            position: item?.position,
            clickable: true,
            animation: google.maps.Animation.DROP,
            icon:
              item.type === 'truck'
                ? item?.data?.status === 'LOOKING_FOR_FREIGHT'
                  ? item?.data?.vehicle?.vehicleTypeId
                    ? getMapVehicleIcon[item?.data?.vehicle?.vehicleTypeId]
                    : null
                  : item?.data?.status === 'NOT_WORKING'
                  ? item?.data?.vehicle?.vehicleTypeId
                    ? getMapVehicleIcon[item?.data?.vehicle?.vehicleTypeId + 'a']
                    : null
                  : item?.data?.vehicle?.vehicleTypeId
                  ? getMapVehicleIcon[item?.data?.vehicle?.vehicleTypeId + 'b']
                  : null
                : null,
            map: map,
          })
          ;(function (marker, data) {
            google.maps.event.addListener(marker, 'click', function () {
              infoWindow.setContent(
                "<div style = 'padding: 5px;'><b style='margin-bottom: 15px;'>" +
                  (data?.title ? data?.title : '') +
                  '</b><hr>' +
                  "<ul style='list-style: none; padding-left: 5px'>" +
                  (data?.driver?.companyName
                    ? '<li>' +
                      intl.formatMessage({id: 'Map.companyName'}) +
                      data?.driver?.companyName +
                      '</li>'
                    : '') +
                  (data?.driver?.email
                    ? '<li>' + intl.formatMessage({id: 'Map.email'}) + data?.driver?.email + '</li>'
                    : '') +
                  (data?.driver?.phone
                    ? '<li>' +
                      intl.formatMessage({id: 'Map.companyPhone'}) +
                      data?.driver?.phone +
                      '</li>'
                    : '') +
                  (data?.date
                    ? '<li>' + intl.formatMessage({id: 'Map.lastUpdate'}) + data?.date + '</li>'
                    : '') +
                  (data?.driver?.name || data?.driver?.mobile ? '<hr>' : '') +
                  (data?.driver?.name || data?.driver?.mobile
                    ? "<li style='margin-bottom: 5px;'>" +
                      intl.formatMessage({id: 'Map.driverTitle'}) +
                      '</li>'
                    : '') +
                  (data?.driver?.name
                    ? '<li>' +
                      intl.formatMessage({id: 'Map.driverName'}) +
                      data?.driver?.name +
                      ' ' +
                      data?.driver?.surname +
                      '</li>'
                    : '') +
                  (data?.driver?.mobile
                    ? '<li>' +
                      intl.formatMessage({id: 'Map.driverPhone'}) +
                      data?.driver?.mobile +
                      '</li>'
                    : '') +
                  '<hr>' +
                  "<li style='margin-bottom: 5px;'>" +
                  intl.formatMessage({id: 'Map.vehicleTitle'}) +
                  '</li>' +
                  (data?.vehicle?.plate
                    ? '<li>' +
                      intl.formatMessage({id: 'Map.vehiclePlate'}) +
                      data?.vehicle?.plate +
                      '</li>'
                    : '') +
                  (data?.vehicle?.make
                    ? '<li>' +
                      intl.formatMessage({id: 'Map.vehicleBrand'}) +
                      data?.vehicle?.make +
                      '</li>'
                    : '') +
                  (data?.trailer?.plate
                    ? '<li>' +
                      intl.formatMessage({id: 'Map.trailerPlate'}) +
                      data?.trailer?.plate +
                      '</li>'
                    : '') +
                  (data?.trailer?.type
                    ? '<li>' +
                      intl.formatMessage({id: 'Admin.Adverts.trailerType'}) +
                      ':  ' +
                      data?.trailer?.type +
                      '</li>'
                    : '') +
                  '<hr>' +
                  "<li style='margin-bottom: 5px; text-transform: uppercase;'>" +
                  intl.formatMessage({id: 'Admin.Adverts.specificationList'}) +
                  '</li>' +
                  (data?.trailer?.specifications?.length > 0
                    ? data?.trailer?.specifications
                        ?.map((item: any) => '<li>' + item?.description + '</li>')
                        .join("<br style='display: none;'>")
                    : '') +
                  '</ul></div>'
              )
              infoWindow.open(map, marker)
              map?.panTo(item.position)
              // map?.setZoom(14)
            })
          })(marker, item.data)
          setGoogleMarkers((val: any) => [...val, marker])
        }
      }
      setTransporterData(resultData)
    } else {
      DeleteMarkers()
    }
  }
  const defaultMapStart = (): void => {
    const defaultAddress = new google.maps.LatLng(
      customLocation?.lat ? customLocation.lat : 38.962725411613374,
      customLocation?.lot ? customLocation.lot : 35.49645569209267
    )
    initMap(6, defaultAddress)
  }

  const initEventListener = (): void => {
    map &&
      !isUnClickable &&
      google.maps.event.addListener(map, 'click', function (e: any) {
        coordinateToAddress(e.latLng)
      })
  }

  useEffect(initEventListener, [map])

  const coordinateToAddress = async (coordinate: GoogleLatLng) => {
    const geocoder = new google.maps.Geocoder()
    await geocoder.geocode({location: coordinate}, function (results: any, status: any) {
      if (status === 'OK') {
        setSelectedAddress(results[0]?.formatted_address)
        setMarker({
          address: results[0]?.formatted_address,
          latitude: coordinate.lat(),
          longitude: coordinate.lng(),
        })
        location({
          address: results[0]?.formatted_address,
          latitude: coordinate.lat(),
          longitude: coordinate.lng(),
          allData: results,
        })
      }
    })
  }

  useEffect(() => {
    if (marker) {
      addMarker(new google.maps.LatLng(marker.latitude, marker.longitude))
    }
  }, [marker])

  useEffect(() => {
    if (searchLat && searchLong && searchLabel !== null) {
      addSearchMarker(new google.maps.LatLng(searchLat, searchLong))
    }
  }, [searchLong, searchLat, searchLabel])

  const DeleteMarkers = () => {
    //Loop through all the markers and remove
    for (let i = 0; i < googleMarkers.length; i++) {
      googleMarkers[i].setMap(null)
    }
    setGoogleMarkers([])
  }

  const addMarker = (location: GoogleLatLng): void => {
    const marker: GoogleMarker = new google.maps.Marker({
      position: location,
      map: map,
      /*icon: getIconAttributes('#000000')*/
    })

    DeleteMarkers()
    setGoogleMarkers([marker])

    const contentString = '<div>' + selectedAddress && selectedAddress + '<div/>'

    let infowindow = new google.maps.InfoWindow({
      content: contentString,
    })

    setTimeout(() => infowindow.open(map, marker), 200)

    /* const listenerId = marker.addListener('click', () => {
             const homePos = homeMarker?.getPosition();
             const markerPos = marker.getPosition();
             if (homePos && markerPos) {
                 const distanceInMeters = google.maps.geometry.spherical.computeDistanceBetween(homePos, markerPos);
                 setDistanceInKm(Math.round(distanceInMeters / 1000));

                 if (LastLineHook) {
                     LastLineHook.setMap(null);
                 }

                 const line = new google.maps.Polyline({
                     path: [
                         {lat: homePos.lat(), lng: homePos.lng()},
                         {lat: markerPos.lat(), lng: markerPos.lng()},
                     ],
                     icons: [
                         {
                             icon: {
                                 path: google.maps.SymbolPath.FORWARD_OPEN_ARROW
                             },
                             offset: "100%"
                         }
                     ],
                     map: map,
                 });

                 setLastLineHook(line);
             }
         });
         setListenerIdArray(listenerIdArray => [...listenerIdArray, listenerId]);*/
  }

  const addSearchMarker = (location: GoogleLatLng): void => {
    const marker: GoogleMarker = new google.maps.Marker({
      position: location,
      map: map,
      /*icon: getIconAttributes('#000000')*/
    })

    DeleteMarkers()
    setGoogleMarkers([marker])

    const contentString = '<div>' + searchLabel && searchLabel + '<div/>'

    let infowindow = new google.maps.InfoWindow({
      content: contentString,
    })

    setTimeout(() => infowindow.open(map, marker), 200)
  }

  /* useEffect(() => {
         listenerIdArray.forEach((listenerId) => {
             google.maps.event.removeListener(listenerId);
         });

         setListenerIdArray([]);
         setGoogleMarkers([]);
         googleMarkers.forEach((googleMarker) => {
             const markerPosition = googleMarker.getPosition();
             if (markerPosition) {
                 addMarker(markerPosition);
             }
         });
     }, [LastLineHook]);*/

  const addHomeMarker = (location: GoogleLatLng): GoogleMarker => {
    const homeMarkerConst: GoogleMarker = new google.maps.Marker({
      position: location,
      map: map,
      icon: {
        url: window.location.origin + '/assets/images/homeAddressMarker.png',
      },
    })

    homeMarkerConst.addListener('click', () => {
      map?.panTo(location)
      map?.setZoom(6)
    })

    return homeMarkerConst
  }

  /*const getIconAttributes = (iconColor: string) => {
        return {
            path: 'M11.0639 15.3003L26.3642 2.47559e-05L41.6646 15.3003L26.3638 51.3639L11.0639 15.3003 M22,17.5a4.5,4.5 0 1,0 9,0a4.5,4.5 0 1,0 -9,0Z',
            fillColor: iconColor,
            fillOpacity: 0.8,
            strokeColor: 'pink',
            strokeWeight: 2,
            anchor: new google.maps.Point(30, 50)
            /!*path: "M-20,0a20,20 0 1,0 40,0a20,20 0 1,0 -40,0",
            fillColor: '#FF0000',
            fillOpacity: .6,
            anchor: new google.maps.Point(0, 0),
            strokeWeight: 0,
            scale: 1*!/
        };
    };*/

  useEffect(() => {
    setCustomMarkers()
  }, [customMarkerList])

  const initMap = (zoomLevel: number, address: GoogleLatLng): void => {
    if (ref.current) {
      setMap(
        new google.maps.Map(ref.current, {
          zoom: zoomLevel,
          center: address,
          mapTypeControl: mapTypeControl,
          streetViewControl: false,
          rotateControl: false,
          scaleControl: true,
          fullscreenControl: fullscreenControl,
          panControl: false,
          zoomControl: true,
          gestureHandling: 'cooperative',
          mapTypeId: mapType,
          draggableCursor: 'grab',
        })
      )
    }
  }

  return (
    <>
      <div
        style={{height: height && height, width: width && width}}
        ref={ref}
        className='map-container__map'
      ></div>
    </>
  )
}

export default memo(Map)
