import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'

import Loading from '../../../Components/Loading'
import {MyMessageWidgetComponent} from '../../../Components/MyMessageWidgetComponent/MyMessageWidgetComponent'
import {MessageChatBox} from './Components/MessageChatBox'

import {ListingService} from '../../../../services/FreightOperatorServices/ListingService'

import {PageTitle} from '../../../../_metronic/layout/core'

const MyMessages = () => {
  const intl = useIntl()

  const [loading, setLoading] = useState<boolean>(false)
  const [pageIndex, setPageIndex] = useState<number>(0)

  const [showMessagePanel, setShowMessagePanel] = useState<boolean>(false)

  const [myMessageList, setMyMessageList] = useState<any>([])
  const [messageThreadList, setMessageThreadList] = useState<any>([])

  const [listingId, setListingId] = useState<any>(null)
  const [listingMessageThreadId, setListingMesageThreadId] = useState<any>(null)

  const listingServ = new ListingService()

  const getMyMessages = (pageIndex: any) => {
    listingServ
      .getCompanyThreadList(pageIndex)
      .then((resp: any) => {
        setLoading(false)
        setMyMessageList(resp?.data)
      })
      .catch((e) => {
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const getThreadMessageList = (listingMessageThreadId: any, pageIndex: any) => {
    listingServ
      .getThreadMessageList(listingMessageThreadId, pageIndex)
      .then((resp: any) => {
        setLoading(false)
        setMessageThreadList(resp?.data)
      })
      .catch((e) => {
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const onPaginationChange = (page: number) => {
    getMyMessages(page)
    setPageIndex(page)
  }

  const handleSendMessage = (data: any) => {
    listingServ
      .sendMessage(data)
      .then((resp: any) => {
        getThreadMessageList(listingMessageThreadId, pageIndex)
      })
      .catch((e) => {
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const readMessgeThread = (id: any) => {
    listingServ
      .readMessageList(id)
      .then((resp: any) => {})
      .catch((e) => {
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  useEffect(() => {
    setLoading(true)
    getMyMessages(pageIndex)
  }, [])

  return (
    <>
      {loading && <Loading />}
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({id: 'freight.myListing.myMessages'})}
      </PageTitle>

      <MyMessageWidgetComponent
        data={myMessageList}
        setPageMIndex={onPaginationChange}
        pageMIndex={pageIndex}
        handleShowButton={(id: any, listingId: any) => {
          setShowMessagePanel(true)
          setListingId(listingId)
          setListingMesageThreadId(id)
          getThreadMessageList(id, pageIndex)
        }}
        handleMessageRead={(id: any) => readMessgeThread(id)}
      />

      {showMessagePanel && (
        <MessageChatBox
          onClose={() => setShowMessagePanel(false)}
          data={messageThreadList}
          sendMessage={(value: any) => {
            handleSendMessage({message: value, listing: {listingId: listingId}})
          }}
          setLoading={setLoading}
        />
      )}
    </>
  )
}

export {MyMessages}
