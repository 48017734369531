import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'

import RightLayoutPopup from '../../../../Components/RightLayoutPopup'
import DataTableAdvanced from '../../../../Components/DataTableAdvanced'
import Modal from '../../../../Components/Modal'
import useScrollLock from '../../../../../utils/useScrollLock'
import {CreateTransporterGroupManager} from './CreateTransporterGroupManager'

import {TransporterGroupService} from '../../../../../services/CarrierServices/TransporterGroupService'

const TransporterGroupManagerList = ({
  onClose,
  transporterGroupManagersList,
  transporterGroupId,
  handleGetTransporterMangers,
  setLoading,
  isTransportGroupManagerNextPage,
  setIsTransportGroupManagerNextPage,
}: any) => {
  const intl = useIntl()
  const {scrollLock, scrollUnlock} = useScrollLock()

  const transporterGroupServ = new TransporterGroupService()

  const [transporterManagerId, setTransporterManagerId] = useState<any>('')
  const [pageMIndex, setPageMIndex] = useState<number>(0)

  const [showCreateTranporterManager, setShowCreateTranporterManager] = useState<boolean>(false)
  const [showDeleteTransporterManager, setShowDeleteTransporterManager] = useState<boolean>(false)

  useEffect(() => {
    scrollLock()
  }, [])

  const handleCreateTransporterGroupManager = (userId: any) => {
    transporterGroupServ
      .createTransporterGroupManager(transporterGroupId, userId)
      .then(() => {
        setLoading(false)
        toast.success(intl.formatMessage({id: 'Carrier.transporterGroup.manager.create.success'}))
        handleGetTransporterMangers({transporterGroupId: transporterGroupId, pageIndex: pageMIndex})
      })
      .catch((e) => {
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const handleDeleteTransporterGroupManager = () => {
    transporterGroupServ
      .deleteTransporterGroupManager(transporterManagerId)
      .then(() => {
        setLoading(false)
        toast.success(intl.formatMessage({id: 'Carrier.transporterGroup.manager.delete.success'}))
        handleGetTransporterMangers({transporterGroupId: transporterGroupId, pageIndex: pageMIndex})
      })
      .catch((e) => {
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const onPaginationChange = (page: number) => {
    if (!isTransportGroupManagerNextPage) {
      handleGetTransporterMangers({
        pageIndex: page,
      })
      setPageMIndex(page)
    }
    setIsTransportGroupManagerNextPage(true)
  }

  return (
    <RightLayoutPopup
      className='carrier-transporter-group-wrap'
      isCloseIcon
      title={intl.formatMessage({id: 'Carrier.transporterGroup.manager.list.title'})}
      closeOnClick={() => onClose()}
    >
      <div className={'d-flex align-items-center justify-content-end'}>
        <button
          onClick={() => {
            setShowCreateTranporterManager(true)
          }}
          className='btn btn-sm btn-light-primary mb-5 border border-2 fs-4'
        >
          {intl.formatMessage({id: 'Carrier.transporterGroup.manager.list.create.title'})}
        </button>
      </div>

      <div>
        {!transporterGroupManagersList.length && (
          <div className='alert alert-warning mb-5 fw-bolder' role='alert'>
            {intl.formatMessage({id: 'global.noData'})}
          </div>
        )}
        <DataTableAdvanced
          columns={[
            {
              Header: 'ID',
              accessor: 'id',
            },
            {
              Header: intl.formatMessage({id: 'Carrier.transporterGroup.manager.list.table.name'}),
              accessor: 'name',
            },
            {
              Header: intl.formatMessage({
                id: 'Carrier.transporterGroup.manager.list.table.surName',
              }),
              accessor: 'surname',
            },
            {
              Header: intl.formatMessage({id: 'Carrier.transporterGroup.manager.list.table.email'}),
              accessor: 'email',
            },
            {
              Header: intl.formatMessage({
                id: 'Carrier.transporterGroup.manager.list.table.countryCode',
              }),
              accessor: 'countryCode',
            },
            {
              Header: intl.formatMessage({
                id: 'Carrier.transporterGroup.manager.list.table.mobile',
              }),
              accessor: 'mobile',
            },
          ]}
          editableRows={['']}
          data={
            transporterGroupManagersList &&
            transporterGroupManagersList.map((item: any) => {
              return {
                id: item?.transporterGroupManagerId,
                name: item?.user?.name ?? '-',
                surname: item?.user?.surname ?? '-',
                email: item?.user?.email ?? '-',
                countryCode: item?.user?.countryCode ?? '-',
                mobile: item?.user?.mobile ?? '-',
              }
            })
          }
          deleteText={intl.formatMessage({id: 'addressListsWidget.delete'})}
          deleteRow={(row: any) => {
            setTransporterManagerId(row?.values?.id)
            setShowDeleteTransporterManager(true)
          }}
          setPageMIndex={onPaginationChange}
          pageMIndex={pageMIndex}
        />
      </div>

      {showCreateTranporterManager && (
        <CreateTransporterGroupManager
          onClose={() => setShowCreateTranporterManager(false)}
          createTransporterGroupManager={(userId: any) =>
            handleCreateTransporterGroupManager(userId)
          }
          setLoading={setLoading}
        />
      )}

      {showDeleteTransporterManager && (
        <Modal
          closeBtnSettings={{
            show: true,
            onClick: () => setShowDeleteTransporterManager(false),
          }}
          customAll={
            <div className={''}>
              <h3 className={'mt-5 mb-15'}>
                {intl.formatMessage({id: 'ADMIN.TRANSPORTS.DELETE.ASK'})}
              </h3>
              <div className={'row'}>
                <div className={'col-md-6'}>
                  <button
                    className={'btn btn-danger w-100'}
                    onClick={() => {
                      setShowDeleteTransporterManager(false)
                    }}
                  >
                    {intl.formatMessage({id: 'global.cancel'})}
                  </button>
                </div>
                <div className={'col-md-6'}>
                  <button
                    className={'btn btn-success w-100'}
                    onClick={() => {
                      setLoading(true)
                      setShowDeleteTransporterManager(false)
                      handleDeleteTransporterGroupManager()
                    }}
                  >
                    {intl.formatMessage({id: 'global.approve'})}
                  </button>
                </div>
              </div>
            </div>
          }
        />
      )}

      <div className='text-center mt-5 row view-details-freight-popup freightPost-popup-container'>
        <div className={'col-md-6'}>
          <button
            onClick={() => {
              onClose()
              scrollUnlock()
            }}
            type='button'
            id='kt_sign_up_submit'
            className='btn btn-lg btn-danger w-100 mb-5 '
          >
            {intl.formatMessage({id: 'Carrier.freightPosting.view.close'})}
          </button>
        </div>
      </div>
    </RightLayoutPopup>
  )
}

export {TransporterGroupManagerList}
