/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react'
import {useIntl} from 'react-intl'
import {MenuComponent} from '../../../assets/ts/components'
import './ActionDropDown.scss'

const ActionDropDown = ({
  mainWrapClass,
  childrenWrapClass,
  children,
  menuTitle,
  isShowArrow = true,
}: any) => {
  const intl = useIntl()
  useEffect(() => {
    MenuComponent.createInstances('[data-kt-menu="true"]')
  }, [])
  return (
    <div className='action-menu-wrap'>
      <a
        href='#'
        className={`btn btn-sm btn-light btn-flex btn-center btn-active-light-primary menu-link align-items-center custom-menu-arrow ${mainWrapClass}`}
        data-kt-menu-trigger='click'
        data-kt-menu-attach='parent'
        data-kt-menu-placement='bottom'
      >
        <span className='menu-title font-action-title'>
          {menuTitle || intl.formatMessage({id: 'MENU.ACTIONS'})}
        </span>
        {isShowArrow && <span className='menu-arrow-custom'></span>}
      </a>

      <div
        className={`menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 py-4 ${
          childrenWrapClass ?? 'w-125px'
        }`}
        data-kt-menu='true'
      >
        {children}
      </div>
    </div>
  )
}

export default ActionDropDown
