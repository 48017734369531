import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import useScrollLock from '../../../../utils/useScrollLock'
import RightLayoutPopup from '../../../../app/Components/RightLayoutPopup'
import DataTableAdvanced from '../../../../app/Components/DataTableAdvanced'
import VideoPopup from '../../../../app/Components/VideoPopup/VideoPopup'
import './ShowHelpSidePanel.scss'

const ShowHelpSidePanel = ({onClose, helpList}: any) => {
  const intl = useIntl()
  const [showVideoPopup, setShowVideoPopup] = useState<boolean>(false)
  const [selectedHelp, setSelectedHelp] = useState<any>({})
  const {scrollLock, scrollUnlock} = useScrollLock()
  useEffect(() => {
    scrollLock()
  }, [])

  return (
    <RightLayoutPopup
      className='show-help-side-panel-wrap'
      title={''}
      width={'30%'}
      isCloseIcon
      closeOnClick={() => onClose()}
    >
      {!helpList.length && (
        <div className='alert alert-warning mb-5 fw-bolder' role='alert'>
          {intl.formatMessage({id: 'global.noData'})}
        </div>
      )}
      <DataTableAdvanced
        columns={[
          {
            Header: 'ID',
            accessor: 'id',
          },
          {
            Header: intl.formatMessage({id: 'Header.help.sidePanel.title'}),
            accessor: 'description',
          },
          {
            Header: 'url',
            accessor: 'url',
            hidden: true,
          },
        ]}
        data={
          helpList &&
          helpList?.map((item: any) => {
            return {
              id: item?.helpId,
              description: item?.description,
              url: item?.url,
            }
          })
        }
        showPaginaton={false}
        onRowClick={(item: any) => {
          setSelectedHelp(item?.values)
          setShowVideoPopup(true)
        }}
      />
      {showVideoPopup && (
        <VideoPopup
          url={selectedHelp.url}
          onClose={() => {
            setShowVideoPopup(false)
            scrollLock()
          }}
        />
      )}
      <div className='text-center mt-5 row view-details-freight-popup button-wrap freightPost-popup-container'>
        <div className={'col-md-6'}>
          <button
            onClick={() => {
              onClose()
              scrollUnlock()
            }}
            type='button'
            id='kt_sign_up_submit'
            className='btn btn-lg btn-danger w-100 mb-5 '
          >
            {intl.formatMessage({id: 'Carrier.freightPosting.view.close'})}
          </button>
        </div>
      </div>
    </RightLayoutPopup>
  )
}

export default ShowHelpSidePanel
