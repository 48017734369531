import clsx from 'clsx'
import React, {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {HeaderUserMenu} from '../../../partials'
import HeaderNotificationsMenu from '../../../partials/layout/header-menus/HeaderNotificationsMenu'
import Loading from '../../../../app/Components/Loading'
import Modal from '../../../../app/Components/Modal'
import {toast} from 'react-toastify'
import {UserModel} from '../../../../app/pages/auth/models/UserModel'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../../setup'
import {getFirebaseToken, onForegroundMessage} from '../../../../services/Firebase/firebaseconfig'
import {NotificationService} from '../../../../services/Firebase/NotificationService'
import {HelpService} from '../../../../services/Firebase/HelpService'
import ShowHelpSidePanel from './ShowHelpSidePanel'

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px',
  toolbarButtonIconSizeClass = 'svg-icon-1'

const Topbar: FC = () => {
  const intl = useIntl()
  const notifiServ = new NotificationService()
  const helpServ = new HelpService()
  const [notifiData, setNotifiData] = useState<any>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [showDetailModal, setShowDetailModal] = useState<boolean>(false)
  const [selectedNotifiData, setSelectedNotifiData] = useState<any>([])
  const [helpList, setHelpList] = useState<any>([])
  const [showHelpPanel, setShowHelpPanel] = useState<boolean>(false)
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  const getNotifiData = () => {
    notifiServ
      .getNotifications()
      .then((resp: any) => {
        setNotifiData(resp.data)
        setLoading(false)
      })
      .catch((e: any) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
        setLoading(false)
      })
  }

  const saveFirebaseToken = (token: string) => {
    notifiServ
      .saveToken(token)
      .then(() => {
        getNotifiData()
      })
      .catch((e: any) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
        setLoading(false)
      })
  }

  const setNotifiReaded = (notifiId: number) => {
    notifiServ
      .notifiMarkAsRead(notifiId)
      .then(() => {
        setLoading(false)
      })
      .catch((e: any) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
        setLoading(false)
      })
  }

  const isIOS = () => {
    const userAgent = window.navigator.userAgent.toLowerCase()
    const safari = /safari/.test(userAgent)
    const ios = /iphone|ipod|ipad/.test(userAgent)
    const instInApp = window.navigator.userAgent.includes('Instagram')

    return safari || ios || instInApp
  }

  useEffect(() => {
    if (!isIOS()) {
      if (Notification.permission === 'granted') {
        setLoading(true)
        getNotifiData()
      }
    }
  }, [])

  /*
    !FIREBASE SETTINGS START
    */

  const [showNotificationBanner, setShowNotificationBanner] = useState(
    !isIOS() && Notification.permission === 'default'
  )

  const handleGetFirebaseToken = () => {
    getFirebaseToken()
      .then((firebaseToken: any) => {
        console.log('Firebase token: ', firebaseToken)
        if (firebaseToken) {
          setLoading(true)
          saveFirebaseToken(firebaseToken)
          setShowNotificationBanner(false)
          toast.success(intl.formatMessage({id: 'Notification.activated.success'}))
        }
      })
      .catch((err: any) =>
        console.error('An error occurred while retrieving firebase token. ', err)
      )
  }

  useEffect(() => {
    onForegroundMessage()
      .then((payload) => {
        console.log('Received foreground message: ', payload)
        const {
          notification: {title, body},
        }: any = payload
        toast(
          <div>
            <b>{title}</b>
            <small className={'text-muted'}>{body}</small>
          </div>,
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          }
        )
      })
      .catch((err) => console.log('An error occurred while retrieving foreground message. ', err))
  }, [])

  const showHelpSidePanel = () => {
    helpServ
      .getHelpList()
      .then((resp: any) => {
        setHelpList(resp.data)
        setLoading(false)
      })
      .catch((e: any) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
        setLoading(false)
      })
  }
  /*
      !FIREBASE SETTINGS END
      */

  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      {loading && <Loading />}
      {/* Search */}
      {/* <div className={clsx('d-flex align-items-stretch', toolbarButtonMarginClass)}>
        <Search />
      </div>
       Activities
      <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
         begin::Drawer toggle
        <div
          className={clsx('btn btn-icon btn-active-light-primary btn-custom', toolbarButtonHeightClass)}
          id='kt_activities_toggle'
        >
          <KTSVG
            path='/media/icons/duotune/general/gen032.svg'
            className={toolbarButtonIconSizeClass}
          />
        </div>
         end::Drawer toggle
      </div>
       Quick links
      <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
         begin::Menu wrapper
        <div
          className={clsx('btn btn-icon btn-active-light-primary btn-custom', toolbarButtonHeightClass)}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          <KTSVG
            path='/media/icons/duotune/general/gen025.svg'
            className={toolbarButtonIconSizeClass}
          />
        </div>
        <QuickLinks />
         end::Menu wrapper
      </div>

       CHAT
      <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
         begin::Menu wrapper
        <div
          className={clsx(
            'btn btn-icon btn-active-light-primary btn-custom',
            toolbarButtonHeightClass
          )}
          id='kt_drawer_chat_toggle'
        >
          <KTSVG
            path='/media/icons/duotune/communication/com012.svg'
            className={toolbarButtonIconSizeClass}
          />

          <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink'></span>
        </div>
         end::Menu wrapper
      </div>*/}

      {/* NOTIFICATIONS*/}
      <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
        {/*begin::Menu- wrapper*/}
        {showNotificationBanner && (
          <div className='notification-banner'>
            <span>{intl.formatMessage({id: 'Header.menu.notification'})} </span>
            <button className='link-primary border-0 bg-0' onClick={handleGetFirebaseToken}>
              {intl.formatMessage({id: 'Header.menu.notification1'})}
            </button>
          </div>
        )}
        <div
          className={clsx(
            'btn btn-icon btn-active-light-primary btn-custom',
            toolbarButtonHeightClass
          )}
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
          onClick={() => {
            setLoading(true)
            setShowHelpPanel(true)
            showHelpSidePanel()
          }}
        >
          <KTSVG
            path='/media/icons/duotune/general/help_icon.svg'
            className={toolbarButtonIconSizeClass}
          />
        </div>

        <div
          className={clsx(
            'btn btn-icon btn-active-light-primary btn-custom',
            toolbarButtonHeightClass
          )}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          <KTSVG
            path='/media/icons/duotune/general/gen007.svg'
            className={toolbarButtonIconSizeClass}
          />
        </div>
        <HeaderNotificationsMenu
          data={notifiData}
          selectedNotifi={(item: any) => {
            setLoading(true)
            setNotifiReaded(item.notificationId)
            setSelectedNotifiData(item)
            setShowDetailModal(true)
          }}
        />
        {/* end::Menu wrapper*/}
      </div>

      {/* begin::User */}
      <div
        className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
        id='kt_header_user_menu_toggle'
      >
        {/* begin::Toggle */}
        <div
          className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          <img src={toAbsoluteUrl(user?.image ?? '/media/avatars/blank.png')} alt='user' />
        </div>
        <HeaderUserMenu />
        {/* end::Toggle */}
      </div>
      {/* end::User */}

      {/* begin::Aside Toggler */}
      {/*{config.header.left === 'menu' && (
                <div className='d-flex align-items-center d-lg-none ms-2 me-n3' title='Show header menu'>
                    <div
                        className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
                        id='kt_header_menu_mobile_toggle'
                    >
                        <KTSVG path='/media/icons/duotune/text/txt001.svg' className='svg-icon-1'/>
                    </div>
                </div>
            )}*/}

      {showDetailModal && (
        <Modal
          closeBtnSettings={{
            show: true,
            onClick: () => setShowDetailModal(false),
          }}
          mainTitle={'Bildirim Detayı'}
          /*width={'75%'}
                    height={'75%'}*/
          customAll={
            <div className={'p-5'} style={{textAlign: 'left', fontFamily: ''}}>
              <div className={'mb-5'}>
                <div className={'text-black fs-4 fw-bold'}>{selectedNotifiData?.title}</div>
                <small className={'text-muted'}>
                  {new Date(selectedNotifiData?.createDate).toLocaleString()}
                </small>
              </div>
              <div className={'text-start'}>{selectedNotifiData?.body}</div>
            </div>
          }
        />
      )}

      {showHelpPanel && (
        <ShowHelpSidePanel onClose={() => setShowHelpPanel(false)} helpList={helpList} />
      )}
    </div>
  )
}

export {Topbar}
