import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'

import Loading from '../../../Components/Loading'
import {TrailerDetails} from './Components/TrailerDetails'
import {TruckDetails} from './Components/TruckDetails'
import {MyListWidgetComponent} from '../../../Components/MyListWidgetComponent/MyListWidgetComponent'
import Modal from '../../../Components/Modal'

import {ListingService} from '../../../../services/FreightOperatorServices/ListingService'

import {PageTitle} from '../../../../_metronic/layout/core'
import ActionDropDown from '../../../../_metronic/partials/content/dropdown/ActionDropdown'

import {CreateTruck} from './Components/CreateTruck'
import {CreateTrailer} from './Components/CreateTrailer'

import {EditTruck} from './Components/EditTruck'
import {EditTrailer} from './Components/EditTrailer'

import './MyListings.scss'

const MyListings = () => {
  const intl = useIntl()

  const [loading, setLoading] = useState<boolean>(false)
  const [pageIndex, setPageIndex] = useState<number>(0)
  const [showTruckDetails, setShowTruckDetail] = useState<boolean>(false)
  const [showTrailerDetails, setShowTrailerDetail] = useState<boolean>(false)

  const [showCreateTruck, setShowCreateTruck] = useState<boolean>(false)
  const [showCreateTrailer, setShowCreateTrailer] = useState<boolean>(false)

  const [showEditTruck, setShowEditTruck] = useState<boolean>(false)
  const [showEditTrailer, setShowEditTrailer] = useState<boolean>(false)

  const [myListings, setMyListings] = useState<any>([])
  const [selectedListTruckDetails, setSelectedListTruckDetails] = useState<any>({})
  const [selectedListTrailerDetails, setSelectedListTrailerDetails] = useState<any>({})

  const [showDeleteListing, setShowDeleteLisitng] = useState<boolean>(false)

  const [selectedListImageList, setSelectedListImageList] = useState<any>([])

  const [selectedListingId, setSelectedListingId] = useState<any>(null)

  const [selectedContactList, setSelectedContactList] = useState<any>([])

  const listingServ = new ListingService()

  const getMyListings = (pageIndex: any) => {
    listingServ
      .getMyListings(pageIndex)
      .then((resp: any) => {
        setLoading(false)
        setMyListings(resp?.data)
      })
      .catch((e) => {
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const handlePublishList = (id: any, issueDate: any) => {
    listingServ
      .publishList(id, issueDate)
      .then(() => {
        getMyListings(pageIndex)
      })
      .catch((e) => {
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const handleUnPublishList = (id: any) => {
    listingServ
      .unPublishList(id)
      .then(() => {
        getMyListings(pageIndex)
      })
      .catch((e) => {
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const getTruckDetails = (itemId: any) => {
    listingServ
      .getTruckDetails(itemId)
      .then((resp: any) => {
        setLoading(false)
        setSelectedListTruckDetails(resp?.data)
      })
      .catch((e) => {
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const getTrailerDetails = (itemId: any) => {
    listingServ
      .getTrailerDetails(itemId)
      .then((resp: any) => {
        setLoading(false)
        setSelectedListTrailerDetails(resp?.data)
      })
      .catch((e) => {
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const getListingFile = (itemId: any) => {
    listingServ
      .getListingFileList(itemId)
      .then((resp: any) => {
        setLoading(false)
        setSelectedListImageList(resp?.data)
      })
      .catch((e) => {
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const getListingContactDetails = (itemId: any) => {
    listingServ
      .getListingContact(itemId)
      .then((resp: any) => {
        setSelectedContactList(resp?.data)
      })
      .catch((e) => {
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const handleDeleteListing = (itemId: any) => {
    listingServ
      .deleteListing(itemId)
      .then((resp: any) => {
        setLoading(false)
        getMyListings(pageIndex)
        toast.success(intl.formatMessage({id: 'freight.myListing.delete.success'}))
      })
      .catch((e) => {
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const onPaginationChange = (page: number) => {
    getMyListings(page)
    setPageIndex(page)
  }

  useEffect(() => {
    setLoading(true)
    getMyListings(pageIndex)
  }, [])

  return (
    <>
      {loading && <Loading />}
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'freight.myListings.title'})}</PageTitle>

      <div className='row mb-5 justify-content-end'>
        <div className='d-flex justify-content-end'>
          <ActionDropDown menuTitle={intl.formatMessage({id: 'freight.myListing.create'})}>
            <div className='menu-item px-3'>
              <button
                onClick={() => setShowCreateTruck(true)}
                className='btn btn-sm btn-light-primary mb-2 border border-2 w-100'
              >
                {intl.formatMessage({id: 'freight.myListings.truck'})}
              </button>

              <button
                onClick={() => setShowCreateTrailer(true)}
                className='btn btn-sm btn-light-primary mb-2 border border-2 w-100'
              >
                {intl.formatMessage({id: 'freight.myListings.trailer'})}
              </button>
            </div>
          </ActionDropDown>
        </div>
      </div>

      <MyListWidgetComponent
        data={myListings}
        setPageMIndex={onPaginationChange}
        pageMIndex={pageIndex}
        handleShowButton={(type: any, id: any) => {
          if (type === 'TRUCK') {
            setLoading(true)
            setShowTruckDetail(true)
            getTruckDetails(id)
            getListingFile(id)
          }
          if (type === 'TRAILER') {
            setLoading(true)
            setShowTrailerDetail(true)
            getTrailerDetails(id)
            getListingFile(id)
          }
        }}
        handlePublishList={(id: any, issueDate: any) => {
          setLoading(true)
          handlePublishList(id, issueDate)
        }}
        handleUnPublishList={(id: any) => {
          setLoading(true)
          handleUnPublishList(id)
        }}
        actionContent={(type: any, id: any) => (
          <ActionDropDown menuTitle={intl.formatMessage({id: 'freight.myListing.actions'})}>
            <div className='menu-item px-3'>
              <button
                onClick={() => {
                  if (type === 'TRUCK') {
                    setLoading(true)
                    setShowTruckDetail(true)
                    getTruckDetails(id)
                    getListingFile(id)
                    getListingContactDetails(id)
                  }
                  if (type === 'TRAILER') {
                    setLoading(true)
                    setShowTrailerDetail(true)
                    getTrailerDetails(id)
                    getListingFile(id)
                    getListingContactDetails(id)
                  }
                }}
                className='btn btn-sm btn-light-primary mb-2 border border-2 w-100'
              >
                {intl.formatMessage({id: 'freight.listing.show'})}
              </button>

              <button
                onClick={() => {
                  if (type === 'TRUCK') {
                    setLoading(true)
                    setShowEditTruck(true)
                    getTruckDetails(id)
                    getListingFile(id)
                    getListingContactDetails(id)
                  }
                  if (type === 'TRAILER') {
                    setLoading(true)
                    setShowEditTrailer(true)
                    getTrailerDetails(id)
                    getListingFile(id)
                    getListingContactDetails(id)
                  }
                }}
                className='btn btn-sm btn-light-primary mb-2 border border-2 w-100'
              >
                {intl.formatMessage({id: 'freight.myListing.edit'})}
              </button>

              <button
                onClick={() => {
                  setSelectedListingId(id)
                  setShowDeleteLisitng(true)
                }}
                className='btn btn-sm btn-light-primary mb-2 border border-2 w-100'
              >
                {intl.formatMessage({id: 'freight.myListing.delete'})}
              </button>
            </div>
          </ActionDropDown>
        )}
      />

      {/*DELETE POPUP*/}
      {showDeleteListing && (
        <Modal
          closeBtnSettings={{
            show: true,
            onClick: () => setShowDeleteLisitng(false),
          }}
          customAll={
            <div className={''}>
              <h3 className={'mt-5 mb-15'}>
                {intl.formatMessage({id: 'freight.myListing.delete.title'})}
              </h3>
              <div className={'row'}>
                <div className={'col-md-6'}>
                  <button
                    className={'btn btn-danger w-100'}
                    onClick={() => {
                      setShowDeleteLisitng(false)
                    }}
                  >
                    {intl.formatMessage({id: 'Carrier.freightPosting.delete.cancel'})}
                  </button>
                </div>
                <div className={'col-md-6'}>
                  <button
                    className={'btn btn-success w-100'}
                    onClick={() => {
                      setLoading(true)
                      setShowDeleteLisitng(false)
                      handleDeleteListing(selectedListingId)
                    }}
                  >
                    {intl.formatMessage({id: 'Carrier.freightPosting.delete.approve'})}
                  </button>
                </div>
              </div>
            </div>
          }
        />
      )}

      {showTrailerDetails && (
        <TrailerDetails
          trailerDetails={selectedListTrailerDetails}
          onClose={() => setShowTrailerDetail(false)}
          selectedListImageList={selectedListImageList}
          contactList={selectedContactList}
          setLoading={setLoading}
        />
      )}

      {showTruckDetails && (
        <TruckDetails
          truckDetails={selectedListTruckDetails}
          onClose={() => setShowTruckDetail(false)}
          selectedListImageList={selectedListImageList}
          contactList={selectedContactList}
          setLoading={setLoading}
        />
      )}

      {showCreateTruck && (
        <CreateTruck
          onClose={() => {
            getMyListings(pageIndex)
            setShowCreateTruck(false)
          }}
          setLoading={setLoading}
        />
      )}

      {showCreateTrailer && (
        <CreateTrailer
          onClose={() => {
            getMyListings(pageIndex)
            setShowCreateTrailer(false)
          }}
          setLoading={setLoading}
        />
      )}

      {showEditTruck && Object.keys(selectedListTruckDetails).length > 0 && (
        <EditTruck
          onClose={() => {
            getMyListings(pageIndex)
            setShowEditTruck(false)
          }}
          setLoading={setLoading}
          listingDetails={selectedListTruckDetails}
          imageList={selectedListImageList}
          contactList={selectedContactList}
        />
      )}

      {showEditTrailer && Object.keys(selectedListTrailerDetails).length > 0 && (
        <EditTrailer
          onClose={() => {
            getMyListings(pageIndex)
            setShowEditTrailer(false)
          }}
          setLoading={setLoading}
          listingDetails={selectedListTrailerDetails}
          imageList={selectedListImageList}
          contactList={selectedContactList}
        />
      )}
    </>
  )
}

export {MyListings}
