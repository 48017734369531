/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react'

/*
import {Redirect, Route, Switch} from 'react-router-dom'
import {Registration} from './components/Registration'
import {ForgotPassword} from './components/ForgotPassword'
import {Login} from './components/Login'
import {toAbsoluteUrl} from '../../../_metronic/helpers'*/

import './AuthPage.scss'

export function AuthPage({children}: any) {
  useEffect(() => {
    document.body.classList.add('bg-white')
    return () => {
      document.body.classList.remove('bg-white')
    }
  }, [])

  return (
    <div className='auth-main-wrap' style={{padding: 0}}>
      <div className={'row m-0 p-0 auth-main-wrap1'}>
        <div
          className={'col-xl-6 m-auto auth-main-content-wrap'}
          style={{padding: '0 3.75rem 0.75rem'}}
        >
          <div
            className={'w-lg-500px m-auto auth-main-inside-wrap'}
            style={{padding: '0 2.75rem 2.75rem'}}
          >
            {children}
          </div>
        </div>
        <div
          className={'col-xl-6 m-0 p-0 login-img-wrap'}
          style={{background: '#FFFFFF', minHeight: '100vh', position: 'relative'}}
        >
          <img className={'img-fluid h-100 w-100'} src='/media/multi-transport.jpg' alt='bg-auth' />
          {/*<div className="d-flex flex-column  w-xl-600px scroll-y">
                        <div className="d-flex flex-row-fluid flex-column text-center p-10 pt-lg-20">

                            <h1 className="fw-bolder fs-2qx pb-5 pb-md-10"
                                style={{color: '#986923', marginTop: 90}}>Yeni Nesil Lojistik Altyapısı</h1>
                            <p className="fw-bold fs-2" style={{color: '#986923'}}>Yük Taşıyıcılarla Şirketleri Bir
                                Araya Getiriyoruz</p>
                        </div>
                    </div>*/}
        </div>
      </div>
    </div>
  )
}
