import React from 'react'
import {useIntl} from 'react-intl'

import './AboutUs.scss'

const AboutInfo = () => {
  const intl = useIntl()

  return (
    <div id='credit' className={'db-body-area home-credit-wrap home-credit-wrapActive'}>
      <div className='d-flex about-us-wrap'>
        <div className='side-separator' style={{position: 'relative'}}>
          <h2 style={{lineHeight: '1.5'}}>{intl.formatMessage({id: 'About.info.first'})}</h2>
        </div>
        <div className='image-wrapper'>
          <img src='/media/about1.png' alt='' />
        </div>
      </div>
      <div className='d-flex about-us-wrap' style={{padding: '50px 100px'}}>
        <div className='image-wrapper'>
          <img src='/media/about2.png' alt='' />
        </div>
        <div className='side-separator right-panel-image'>
          <h2 style={{lineHeight: '1.5'}}>
            {intl.formatMessage({id: 'About.info.second'})}
            <li>{intl.formatMessage({id: 'About.info.second.list1'})}</li>
            <li>{intl.formatMessage({id: 'About.info.second.list2'})}</li>
            <li>{intl.formatMessage({id: 'About.info.second.list3'})}</li>
            <li>{intl.formatMessage({id: 'About.info.second.list4'})}</li>
            {intl.formatMessage({id: 'About.info.third'})}
          </h2>
        </div>
      </div>
      <div
        className='home-credit-container d-flex justify-content-center'
        style={{marginTop: '30px'}}
      >
        <div className='home-credit-screenSecondWrap'>
          <img src='/media/credit_card.png' alt='' className='w-100' />
        </div>
      </div>
    </div>
  )
}

export {AboutInfo}
