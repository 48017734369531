/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useIntl} from "react-intl";
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import './HeaderNotificationsMenu.scss'

interface ITypes {
    data?: any;
    selectedNotifi?: any;
}

const HeaderNotificationsMenu = ({data, selectedNotifi}: ITypes) => {
  const intl = useIntl();

  return (<div
    className='menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px'
    data-kt-menu='true'
>
    <div
        className='d-flex flex-column bgi-no-repeat rounded-top'
        style={{backgroundImage: `url('${toAbsoluteUrl('/media/misc/pattern-1.jpg')}')`}}
    >
        <h3 className='text-white fw-bold px-9 mt-10 mb-6'>
            {data?.length === 0 ? intl.formatMessage({id: "HeaderNotificationMenu.noNotification"}) : intl.formatMessage({id: "HeaderNotificationMenu.notification"}) } <span
            className='fs-8 opacity-75 ps-3'>{data?.length === 0 ? '' : data.length + ' Bildirim'}</span>
        </h3>

        <ul className='nav nav-line-tabs nav-line-tabs-2x nav-stretch fw-bold px-9'>
            {/*<li className='nav-item'>
      <a
        className='nav-link text-white opacity-75 opacity-state-100 pb-4'
        data-bs-toggle='tab'
        href='#kt_topbar_notifications_1'
      >
        Alerts
      </a>
    </li>*/}

            {/*<li className='nav-item'>
      <a
        className='nav-link text-white opacity-75 opacity-state-100 pb-4 active'
        data-bs-toggle='tab'
        href='#kt_topbar_notifications_2'
      >
        Updates
      </a>
    </li>

    <li className='nav-item'>
      <a
        className='nav-link text-white opacity-75 opacity-state-100 pb-4'
        data-bs-toggle='tab'
        href='#kt_topbar_notifications_3'
      >
        Logs
      </a>
    </li>*/}
        </ul>
    </div>

    <div className='tab-content'>
        {/* <div className='tab-pane fade' id='kt_topbar_notifications_1' role='tabpanel'>
    <div className='scroll-y mh-325px my-5 px-8'>
      {defaultAlerts.map((alert, index) => (
        <div key={`alert${index}`} className='d-flex flex-stack py-4'>
          <div className='d-flex align-items-center'>
            <div className='symbol symbol-35px me-4'>
              <span className={clsx('symbol-label', `bg-light-${alert.state}`)}>
                {' '}
                <KTSVG
                  path={`/media/${alert.icon}`}
                  className={`svg-icon-2 svg-icon-${alert.state}`}
                />
              </span>
            </div>

            <div className='mb-0 me-2'>
              <a href='#' className='fs-6 text-gray-800 text-hover-primary fw-bolder'>
                {alert.title}
              </a>
              <div className='text-gray-400 fs-7'>{alert.description}</div>
            </div>
          </div>

          <span className='badge badge-light fs-8'>{alert.time}</span>
        </div>
      ))}
    </div>

    <div className='py-3 text-center border-top'>
      <Link
        to='/crafted/pages/profile'
        className='btn btn-color-gray-600 btn-active-color-primary'
      >
       Hepsini Görüntüle <KTSVG path='/media/icons/duotune/arrows/arr064.svg' className='svg-icon-5' />
      </Link>
    </div>
  </div>*/}

        <div className='tab-pane fade show active' id='kt_topbar_notifications_2' role='tabpanel'>
            <div className='scroll-y mh-325px my-5 px-8'>
                {data?.map((item: any, i: number) => (
                    <div style={{cursor: 'pointer'}} key={i}
                         className='d-flex flex-stack py-4 notifi-container-item-main' onClick={() => {
                        selectedNotifi(item);
                    }}>
                        <div className='d-flex align-items-center'>
                            <div className='symbol symbol-35px me-4'>
                                <span className={clsx('symbol-label', `bg-light-primary`)}>
                                 <KTSVG
                                     path={'/media/icons/duotune/maps/map001.svg'}
                                     className={'svg-icon-2 svg-icon-primary' /*passive-danger-warning-success-info*/}
                                 />
                                 </span>
                            </div>

                            <div className='mb-0 me-2'>
                                <span className='fs-6 text-gray-800 fw-bolder'>{item?.title}</span>
                                <div className='text-gray-400 fs-7 text-truncate'>{item?.body}</div>
                            </div>
                        </div>

                        {/*<span className='badge badge-light fs-8'>{alert.time}</span>*/}
                    </div>
                ))}
            </div>

            {/*{data.length !== 0 && <div className='py-3 text-center border-top'>
                <Link
                    to='/notifications'
                    className='btn btn-color-gray-600 btn-active-color-primary'
                >
                    Hepsini Görüntüle <KTSVG path='/media/icons/duotune/arrows/arr064.svg' className='svg-icon-5'/>
                </Link>
            </div>}*/}
        </div>

        {/*<div className='tab-pane fade' id='kt_topbar_notifications_3' role='tabpanel'>
    <div className='scroll-y mh-325px my-5 px-8'>
      {defaultLogs.map((log, index) => (
        <div key={`log${index}`} className='d-flex flex-stack py-4'>
          <div className='d-flex align-items-center me-2'>
            <span className={clsx('w-70px badge', `badge-light-${log.state}`, 'me-4')}>
              {log.code}
            </span>

            <a href='#' className='text-gray-800 text-hover-primary fw-bold'>
              {log.message}
            </a>

            <span className='badge badge-light fs-8'>{log.time}</span>
          </div>
        </div>
      ))}
    </div>
    <div className='py-3 text-center border-top'>
      <Link
        to='/crafted/pages/profile'
        className='btn btn-color-gray-600 btn-active-color-primary'
      >
        Hepsini Görüntüle <KTSVG path='/media/icons/duotune/arrows/arr064.svg' className='svg-icon-5' />
      </Link>
    </div>
  </div>*/}
    </div>


</div>)
}

export default HeaderNotificationsMenu;
