import {apiUrl} from "../conf";
import axios from "axios";

export class InvoicesService {

    getAddresses(pageIndex?: number, pageCount?: number, searchText?: string) {
        const config: any = {
            method: 'GET',
            url: apiUrl + '/address-management/addresses/invoices?searchText=' + (searchText ?? '') + '&pageIndex=' + (pageIndex ?? 0) + '&pageCount=' + (pageCount ?? 10000),
            headers: {
                'Content-Type': 'application/json',
            },
        };

        return axios(config);
    }

    getAddress(id: number) {
        const config: any = {
            method: 'GET',
            url: apiUrl + '/address-management/addresses/invoices/' + id,
            headers: {
                'Content-Type': 'application/json',
            },
        };

        return axios(config);
    }

    deleteAddress(id: number) {
        const config: any = {
            method: 'DELETE',
            url: apiUrl + '/address-management/addresses/invoices/' + id,
            headers: {
                'Content-Type': 'application/json',
            },
        };

        return axios(config);
    }

    setPrimary(id: number) {
        const config: any = {
            method: 'PATCH',
            url: apiUrl + '/address-management/addresses/invoices/' + id + '/primary',
            headers: {
                'Content-Type': 'application/json',
            },
        };

        return axios(config);
    }

    updateAddress(addressId: number | string, description?: string, country?: string | number, city?: string | number, district?: string, neighborhood?: string, street?: string, streetNumber?: string, postCode?: string | number) {
        const config: any = {
            method: 'PUT',
            url: apiUrl + '/address-management/addresses/invoices',
            headers: {
                'Content-Type': 'application/json',
            },
            data: JSON.stringify({
                invoiceAddressId: addressId,
                description: description,
                country: country && {
                    code: country
                },
                city: city && {
                    cityId: city
                },
                district: district,
                neighborhood: neighborhood,
                street: street,
                streetNumber: streetNumber,
                postCode: postCode,
                // addressText: addressText,
            })
        };

        return axios(config);
    }

    createAddress(title?: string, country?: string | number, city?: string | number, district?: string, neighborhood?: string, street?: string, streetNumber?: string, postCode?: string | number) {
        const config: any = {
            method: 'POST',
            url: apiUrl + '/address-management/addresses/invoices',
            headers: {
                'Content-Type': 'application/json',
            },
            data: JSON.stringify({
                description: title,
                country: country && {
                    code: country
                },
                city: city && {
                    cityId: city
                },
                district: district,
                neighborhood: neighborhood,
                street: street,
                streetNumber: streetNumber,
                postCode: postCode
            })
        };

        return axios(config);
    }

}
