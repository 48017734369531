import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import clsx from 'clsx'

import SelectBox from '../../../../Components/SelectBox/SelectBox'
import RightLayoutPopup from '../../../../Components/RightLayoutPopup'

import useScrollLock from '../../../../../utils/useScrollLock'

const EditPreferences = ({vehicleTypes, updateVehicle, updatedVehicles, onClose}: any) => {
  const intl = useIntl()
  const {scrollLock} = useScrollLock()
  useEffect(() => {
    scrollLock()
  }, [])

  const [updatedVehicle, setUpdatedVehicle] = useState<any>({
    id: -1,
    year: '',
    plate: '',
    /*capacity: 1,*/
    make: '',
    model: '',
    vehicleType: '',
  })

  useEffect(() => {
    if (updatedVehicles) {
      setUpdatedVehicle(updatedVehicles)
    }
  }, [updatedVehicles])

  return (
    <>
      <RightLayoutPopup
        title={intl.formatMessage({id: 'Carrier.vehicles.update.updateInfo'})}
        className='carrier-edit-vehicle-side-wrap'
        isCloseIcon
        closeOnClick={() => onClose()}
      >
        <div className={''}>
          <div className={'row'}>
            <div className='col-xl-6 fv-row mb-10'>
              <label className='form-label fs-6 fw-bolder text-dark'>
                {intl.formatMessage({id: 'Carrier.vehicles.update.year'})}
              </label>
              <input
                placeholder={intl.formatMessage({id: 'Carrier.vehicles.update.year.placeHolder'})}
                className={clsx('form-control form-control-lg form-control-solid')}
                onChange={(event: any) =>
                  setUpdatedVehicle((item: any) => ({
                    ...item,
                    year: event.target.value,
                  }))
                }
                value={updatedVehicle.year}
                type='text'
                name='year'
                autoComplete='off'
              />
            </div>
            <div className='col-xl-6 fv-row mb-10'>
              <label className='form-label fs-6 fw-bolder text-dark required'>
                {intl.formatMessage({id: 'Carrier.vehicles.update.numberPlate'})}
              </label>
              <input
                placeholder={intl.formatMessage({
                  id: 'Carrier.vehicles.update.numberPlate.placeHolder',
                })}
                className={clsx('form-control form-control-lg form-control-solid')}
                onChange={(event: any) =>
                  setUpdatedVehicle((item: any) => ({
                    ...item,
                    plate: event.target.value,
                  }))
                }
                value={updatedVehicle.plate}
                type='text'
                name='plate'
                autoComplete='off'
              />
            </div>
            <div className='col-xl-6 fv-row mb-10'>
              <label className='form-label fs-6 fw-bolder text-dark'>
                {intl.formatMessage({id: 'Carrier.vehicles.update.brand'})}
              </label>
              <input
                placeholder={intl.formatMessage({id: 'Carrier.vehicles.update.brand.placeHolder'})}
                className={clsx('form-control form-control-lg form-control-solid')}
                onChange={(event: any) =>
                  setUpdatedVehicle((item: any) => ({
                    ...item,
                    make: event.target.value,
                  }))
                }
                value={updatedVehicle.make}
                type='text'
                name='make'
                autoComplete='off'
              />
            </div>
            <div className='col-xl-6 fv-row mb-10'>
              <label className='form-label fs-6 fw-bolder text-dark'>
                {intl.formatMessage({id: 'Carrier.vehicles.update.model'})}
              </label>
              <input
                placeholder={intl.formatMessage({id: 'Carrier.vehicles.update.model.placeHolder'})}
                className={clsx('form-control form-control-lg form-control-solid')}
                onChange={(event: any) =>
                  setUpdatedVehicle((item: any) => ({
                    ...item,
                    model: event.target.value,
                  }))
                }
                value={updatedVehicle.model}
                type='text'
                name='model'
                autoComplete='off'
              />
            </div>
            <div className={'col-xl-6 '}>
              <label className='form-label fs-6 fw-bolder text-dark required'>
                {intl.formatMessage({id: 'Carrier.vehicles.update.vehicleType'})}
              </label>
              <SelectBox
                placeholder={intl.formatMessage({
                  id: 'Carrier.vehicles.update.vehicleType.placeHolder',
                })}
                onChange={(event: any) =>
                  setUpdatedVehicle((item: any) => ({
                    ...item,
                    vehicleType: parseInt(event.target.value),
                  }))
                }
                value={updatedVehicle?.vehicleType}
                data={vehicleTypes?.map((item: any) => {
                  return {key: item.vehicleTypeId, title: item.description}
                })}
              />
            </div>
          </div>
        </div>

        <div className='text-center mt-5 row freightPost-popup-container'>
          <div className={'col-md-6'}>
            <button
              onClick={onClose}
              type='button'
              id='kt_sign_up_submit'
              className='btn btn-lg btn-danger w-100 mb-5 '
            >
              {intl.formatMessage({id: 'CreateNewContainerPopup.cancel'})}
            </button>
          </div>
          <div className={'col-md-6'}>
            <button
              className={'btn btn-success px-10 m-auto w-100'}
              disabled={
                !updatedVehicle.plate /*|| !updatedVehicle.capacity*/ || !updatedVehicle.vehicleType
              }
              onClick={() => {
                updateVehicle(updatedVehicle)
                setTimeout(
                  () =>
                    setUpdatedVehicle({
                      id: -1,
                      year: '',
                      plate: '',
                      /*capacity: 1,*/
                      make: '',
                      model: '',
                      vehicleType: '',
                    }),
                  100
                )
              }}
            >
              {intl.formatMessage({id: 'Carrier.vehicles.update.updateInformation'})}
            </button>
          </div>
        </div>
      </RightLayoutPopup>
    </>
  )
}

export default EditPreferences
