import React, {useEffect} from 'react'
import {useIntl} from 'react-intl'

import SelectBox from '../../../../Components/SelectBox/SelectBox'

import useScrollLock from '../../../../../utils/useScrollLock'

const EditCompanies = ({
  setFilter,
  filter,
  companyStatuses,
  setLoading,
  updateCompanyStatus,
  selectedCompanyId,
  onClose,
}: any) => {
  const intl = useIntl()
  const {scrollLock, scrollUnlock} = useScrollLock()
  useEffect(() => {
    scrollLock()
  }, [])

  return (
    <>
      <div className={'mb-10'}>
        <label className='form-label fs-6 fw-bolder text-dark'>
          {intl.formatMessage({id: 'Admin.Companies.Edit-title'})}
        </label>
        <SelectBox
          placeholder={intl.formatMessage({id: 'Admin.Companies.Edit-title'})}
          onChange={(e: any) => {
            setFilter(e.target.value)
          }}
          value={filter}
          data={companyStatuses?.map((item: any) => {
            return {key: item?.companyStatusId, title: item?.description}
          })}
        />
      </div>
      <div className='text-center mt-5 row freightPost-popup-container'>
        <div className={'col-md-6'}>
          <button
            onClick={() => {
              onClose()
              scrollUnlock()
            }}
            type='button'
            id='kt_sign_up_submit'
            className='btn btn-lg btn-danger w-100 mb-5 '
          >
            {intl.formatMessage({id: 'CreateNewContainerPopup.cancel'})}
          </button>
        </div>
        <div className={'col-md-6'}>
          <button
            className={'btn btn-success w-100'}
            onClick={() => {
              setLoading(true)
              updateCompanyStatus(selectedCompanyId, Number(filter))
              onClose()
            }}
          >
            {intl.formatMessage({id: 'global.update'})}
          </button>
        </div>
      </div>
    </>
  )
}

export default EditCompanies
