import React, {useState} from 'react';
import './DayPicker.scss'
import {IDayPicker} from "./types";
import {DayPicker} from 'react-day-picker';
import 'react-day-picker/dist/style.css';

import tr from 'date-fns/locale/tr';
import en from 'date-fns/locale/en-US';
import useOuterClick from "../../../utils/useOuterClick";
import {useIntl} from "react-intl";

const DatePicker = ({
                        onSelect,
                        value,
                        customDate,
                        selected,
                        disabledDays,
                        disabled,
                        menuDefaultOpen,
                        captionLayout,
                        className
                    }: IDayPicker) => {
    const intl = useIntl();
    const [open, setOpen] = useState<boolean>(menuDefaultOpen ?? false);
    const ref = useOuterClick(() => {
        setOpen(false)
    });
    const today = new Date();

    let footer = <button className={'btn btn-success w-100'}
                         disabled={disabled}
                         onClick={() => {
                             setOpen(false);
                         }}>{intl.formatMessage({id: "DatePicker.approve"})}</button>;
    /*if (selected) {
        footer = <p>Şu tarihi seçtiniz {format(selected, 'PP')}.</p>;
    }*/

    const getCustomDate = (date: any) => {
        const opts: any = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'};
        return new Date(date).toLocaleDateString(intl.locale, opts);
    }

    return <div className={`day-picker-main-area ${className}`} ref={ref}>
        {(!captionLayout || captionLayout === 'dropdown') &&
            <button className={'select-input-btn form-control fs-4'} onClick={() => setOpen(!open)}>
                <span>{selected ? getCustomDate(customDate) : intl.formatMessage({id: "DatePicker.selectDate"})}</span>
                <img className={'select-date-icon'}
                     src="/media/icons/duotune/general/gen014.svg"
                     alt="date_icon"/>
            </button>}
        {((open && (!captionLayout || captionLayout === 'dropdown')) || captionLayout === 'buttons') &&
            <div className={captionLayout !== 'buttons' ? 'select-menu-area' : 'd-flex justify-content-center'}
                 style={{backgroundColor: '#ffffff', borderRadius: '10px',}}>
                <DayPicker
                    locale={intl.locale === 'tr' ? tr : en}
                    mode="single"
                    selected={value}
                    onSelect={onSelect}
                    footer={captionLayout === 'buttons' ? <></> : footer}
                    captionLayout={captionLayout ?? 'dropdown'}
                    disabled={disabled ? true : {before: disabledDays ? disabledDays : today}}
                />
            </div>}
    </div>;
};

export default DatePicker;

