import {apiUrl, globalPageCount} from "../conf";
import axios from "axios";

export class TransportService {

    getTransports(data: {
        pageIndex?: number,
        pageCount?: number,
        description?: string,
        fromCountryCode?: string,
        fromCityId?: number,
        toCountryCode?: string,
        toCityId?: number,
        plannedDepartureDate?: string,
        plannedArrivalDate?: string,
        driverId?: number,
        vehicleId?: number,
        trailerId?: number,
        transportStatusId?: string | number,
        companyId?: string | number,
    }) {
        const config: any = {
            method: 'GET',
            url: `${apiUrl}/transport-management/transports/administrator/filter?description=${data?.description ?? ''}&pageIndex=${data?.pageIndex ?? 0}&pageCount=${globalPageCount}&transportStatusId=${(data?.transportStatusId && data?.transportStatusId !== 'all') ? data.transportStatusId : ''}&companyId=${(data.companyId && data.companyId !== 'all') ? data.companyId : ''}`,

            /*apiUrl + '/transport-management/transports/filter?description=' + (data?.description ?? '') + '&pageIndex=' + (data?.pageIndex ?? 0) + ('&pageCount=' + globalPageCount) + '&fromCountryCode=' + (data.fromCountryCode ?? '') + '&fromCityId=' + (data.fromCityId ?? '') + '&toCountryCode=' + (data.toCountryCode ?? '') + '&toCityId=' + (data.toCityId ?? '') + '&plannedDepartureDate=' + (data.plannedDepartureDate ?? '') + '&plannedArrivalDate=' + (data.plannedArrivalDate ?? '') + '&driverId=' + (data.driverId ?? '') + '&vehicleId=' + (data.vehicleId ?? '') + '&trailerId=' + (data.trailerId ?? '') + '&transportStatusId=' + ((data?.transportStatusId && data?.transportStatusId !== 'all') ? data.transportStatusId : '') + '&companyId=' + ((data.companyId && data.companyId !== 'all') ? data.companyId : ''),*/
            headers: {
                'Content-Type': 'application/json',
            },
        };

        return axios(config);
    }

    getCarrierTransports(data: {
        pageIndex?: number,
        pageCount?: number,
        description?: string,
        fromCountryCode?: string,
        fromCityId?: number,
        toCountryCode?: string,
        toCityId?: number,
        plannedDepartureDate?: string,
        plannedArrivalDate?: string,
        driverId?: number,
        vehicleId?: number,
        trailerId?: number,
        transportStatusId?: string | number,
        companyId?: string | number,
    }) {
        const config: any = {
            method: 'GET',
            url: `${apiUrl}/transport-management/transports/carriers/filter?description=${data?.description ?? ''}&pageIndex=${data?.pageIndex ?? 0}&pageCount=${globalPageCount}&transportStatusId=${(data?.transportStatusId && data?.transportStatusId !== 'all') ? data.transportStatusId : ''}&companyId=${(data.companyId && data.companyId !== 'all') ? data.companyId : ''}`,

            /*apiUrl + '/transport-management/transports/filter?description=' + (data?.description ?? '') + '&pageIndex=' + (data?.pageIndex ?? 0) + ('&pageCount=' + globalPageCount) + '&fromCountryCode=' + (data.fromCountryCode ?? '') + '&fromCityId=' + (data.fromCityId ?? '') + '&toCountryCode=' + (data.toCountryCode ?? '') + '&toCityId=' + (data.toCityId ?? '') + '&plannedDepartureDate=' + (data.plannedDepartureDate ?? '') + '&plannedArrivalDate=' + (data.plannedArrivalDate ?? '') + '&driverId=' + (data.driverId ?? '') + '&vehicleId=' + (data.vehicleId ?? '') + '&trailerId=' + (data.trailerId ?? '') + '&transportStatusId=' + ((data?.transportStatusId && data?.transportStatusId !== 'all') ? data.transportStatusId : '') + '&companyId=' + ((data.companyId && data.companyId !== 'all') ? data.companyId : ''),*/
            headers: {
                'Content-Type': 'application/json',
            },
        };

        return axios(config);
    }

    getTransportStatuses() {
        const config: any = {
            method: 'GET',
            url: apiUrl + '/transport-management/transports/statuses',
            headers: {
                'Content-Type': 'application/json',
            },
        };
        return axios(config);
    }

    getCarrierTransportStatuses() {
        const config: any = {
            method: 'GET',
            url: apiUrl + '/transport-management/transports/statuses',
            headers: {
                'Content-Type': 'application/json',
            },
        };
        return axios(config);
    }

    getTransport(id: number) {
        const config: any = {
            method: 'GET',
            url: apiUrl + '/transport-management/transports/' + id,
            headers: {
                'Content-Type': 'application/json',
            },
        };

        return axios(config);
    }

    getCarrierTransport(id: number) {
        const config: any = {
            method: 'GET',
            url: apiUrl + '/transport-management/transports/' + id,
            headers: {
                'Content-Type': 'application/json',
            },
        };

        return axios(config);
    }

    createTransport(
        transporterId: number,
        containerId: number,
    ) {

        const data: any = JSON.stringify({
            transporterId: transporterId,
            containerId: containerId,
        });

        const config: any = {
            method: 'POST',
            url: apiUrl + '/transport-management/transports/administrator',
            headers: {
                'Content-Type': 'application/json',
            },
            data: data
        };

        return axios(config);
    }

    createCarrierTransport(
        transporterId: number,
        containerId: number,
    ) {

        const data: any = JSON.stringify({
            transporterId: transporterId,
            containerId: containerId,
        });

        const config: any = {
            method: 'POST',
            url: apiUrl + '/transport-management/transports/carriers',
            headers: {
                'Content-Type': 'application/json',
            },
            data: data
        };

        return axios(config);
    }

    deleteTransport(transporterId: number) {
        const config: any = {
            method: 'DELETE',
            url: apiUrl + '/transport-management/transports/' + transporterId,
            headers: {
                'Content-Type': 'application/json',
            },
        };

        return axios(config);
    }

    deleteCarrierTransport(transporterId: number) {
        const config: any = {
            method: 'DELETE',
            url: apiUrl + '/transport-management/transports/' + transporterId,
            headers: {
                'Content-Type': 'application/json',
            },
        };

        return axios(config);
    }


}
