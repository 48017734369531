import React from 'react';
import {ITimePickerNew} from './types';
import './TimePickerNew.scss';
import TimePicker from 'react-time-picker'

const MyTimePicker = ({onChange, value, min, max, disabled}: ITimePickerNew) => {
    return (
        /*<div className={'time-picker-new-main'}>
            <input className={'time-picker-input form-control fs-4'}
                   type={'time'}
                   min={min}
                   max={max}
                   onChange={(e: any) => onChange(e)}
                   value={value}
                   disabled={disabled}/>
        </div>*/
        <div className={'my-time-picker-main'}>
            <TimePicker
                onChange={onChange}
                value={value}
                disableClock
                className={'time-picker-input form-control fs-4 border-0'}
                minTime={min}
                maxTime={max}
                disabled={disabled}
            />
        </div>
    );
};

export default MyTimePicker;
