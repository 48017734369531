/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {geocodeByPlaceId} from 'react-google-places-autocomplete'
import {useFormik} from 'formik'
import * as Yup from 'yup'

import {PageTitle} from '../../../../../_metronic/layout/core'

import Loading from '../../../../Components/Loading'
import Map from '../../../../Components/GoogleMap/Map'
import {loadMapApi} from '../../../../Components/GoogleMap/utils/GoogleMapsUtils'
import MapAutoCompleteSelect from '../../../../Components/MapAutoCompleteSelect'
import RightLayoutPopup from '../../../../Components/RightLayoutPopup'

import {CommonService} from '../../../../../services/FreightOperatorServices/CommonService'

const CreateMapAddress = ({setFillupAddress, handleClose}: any) => {
  const intl = useIntl()
  const commonServ = new CommonService()
  const isEmpty = (obj: any) => !Object.keys(obj).length

  const [loading, setLoading] = useState<boolean>(false)

  const [searchLong, setSearchLong] = useState<any>(null)
  const [searchLat, setSearchLat] = useState<any>(null)
  const [searchLabel, setSearchLabel] = useState<any>(null)

  /*List States*/
  const [countryList, setCountryList] = useState<any>([])
  const [cityList, setCityList] = useState([])
  const [distanceInKm, setDistanceInKm] = useState<number>(-1)

  const initialValues = {
    country: '',
    city: null,
    district: '',
    neighborhood: '',
    postCode: '',
    street: '',
    streetNumber: '',
    latitude: '',
    longitude: '',
  }
  const registrationSchema = Yup.object().shape({
    country: Yup.string(),
    city: Yup.string().nullable(),
    district: Yup.string(),
    neighborhood: Yup.string(),
    street: Yup.string(),
    streetNumber: Yup.string(),
    postCode: Yup.string(),
    latitude: Yup.string(),
    longitude: Yup.string(),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      //   setLoading(true)
      createNewAddress(
        formik.values.country,
        formik.values.city ?? -1,
        formik.values.district,
        formik.values.neighborhood,
        formik.values.street,
        formik.values.streetNumber,
        formik.values.postCode,
        formik.values.latitude,
        formik.values.longitude
      )
    },
  })

  const inputActions = (action: 'clear' | 'fill', data?: any) => {
    if (action === 'clear') {
      formik.setFieldValue('postCode', '')
      formik.setFieldValue('district', '')
      formik.setFieldValue('neighborhood', '')
      formik.setFieldValue('street', '')
      formik.setFieldValue('streetNumber', '')
      formik.setFieldValue('country', '')
      formik.setFieldValue('city', null)
      formik.setFieldValue('latitude', '')
      formik.setFieldValue('longitude', '')
    } else if (data) {
      data?.postCode && formik.setFieldValue('postCode', data?.postCode)
      data?.district && formik.setFieldValue('district', data?.district)
      data?.neighborhood && formik.setFieldValue('neighborhood', data?.neighborhood)
      data?.street && formik.setFieldValue('street', data?.street)
      data?.streetNumber && formik.setFieldValue('streetNumber', data?.streetNumber)
      data?.country && formik.setFieldValue('country', data?.country)
      data?.city && formik.setFieldValue('city', data?.city)
      data?.latitude && formik.setFieldValue('latitude', data?.latitude)
      data?.longitude && formik.setFieldValue('longitude', data?.longitude)
    }
  }

  /** Create New Address api call Start **/
  const createNewAddress = (
    countryCode: string,
    cityId: number,
    district: string,
    neighborhood: string,
    street: string,
    streetNumber: string,
    postCode: string,
    latitude: string,
    longitude: string
  ) => {
    setFillupAddress({
      countryCode,
      cityId,
      district,
      neighborhood,
      street,
      streetNumber,
      postCode,
      latitude,
      longitude,
    })
    handleClose()
  }
  /** Create New Address api call End **/

  const [scriptLoaded, setScriptLoaded] = useState(false)

  const searchSelectedControl = async (addressData: any) => {
    !isEmpty(addressData) &&
      inputActions('fill', {
        district: addressData?.district,
        neighborhood: addressData?.neighborhood,
        country: addressData?.country,
        street: addressData?.street,
        streetNumber: addressData?.streetNumber,
        latitude: addressData?.latitude,
        longitude: addressData?.longitude,
      })
    const resp = await commonServ.cityPublicList(addressData?.country)
    setCityList(resp.data)
    setLoading(false)
    let eq = resp?.data?.find(
      (item: any) => addressData?.city?.toLowerCase() === item?.originalName?.toLowerCase()
    )
    await formik.setFieldValue('city', eq ? eq?.cityId : null)
  }

  useEffect(() => {
    scriptLoaded && window.location.reload()

    const googleMapScript = loadMapApi()
    googleMapScript.addEventListener('load', () => setScriptLoaded(true))
  }, [])

  return (
    <>
      <RightLayoutPopup className='create-map-public-wrap'>
        {loading && <Loading />}
        <PageTitle breadcrumbs={[]}>
          {intl.formatMessage({id: 'Freight.addresses.create.addAddress'})}
        </PageTitle>
        <div>
          {/*Search Input*/}
          <div className={'mb-5 mt-5'}>
            <label className='class="form-label fw-bolder text-dark fs-6'>
              {intl.formatMessage({id: 'Freight.addresses.create.searchAddress'})}
            </label>
            <MapAutoCompleteSelect
              selectedLoc={async (item: any) => {
                let locationData = item?.value?.terms?.sort((a: any, b: any) => b.offset - a.offset)

                let con: any = countryList?.find(
                  (item: any) => locationData[0]?.value === item.name
                )
                const [place] = await geocodeByPlaceId(item?.value?.place_id)
                let addressData = {
                  country: con?.code,
                  city: item?.value?.terms[1]?.value,
                  district: item?.value?.terms[3]?.value,
                  neighborhood: item?.value?.terms[2]?.value,
                  addressText: item?.value?.description,
                }
                setSearchLong(place.geometry.location.lng())
                setSearchLat(place.geometry.location.lat())
                setSearchLabel(item?.label)
                searchSelectedControl(addressData)
              }}
            />
          </div>

          {/**Map**/}
          <div className='map-container'>
            <Map
              mapType={google.maps.MapTypeId.ROADMAP}
              mapTypeControl={true}
              setDistanceInKm={setDistanceInKm}
              location={(item: any) => {
                // const address_text_index = item?.allData[0]?.address_components.findIndex((item: any) => item.types.includes('administrative_area_level_2'));

                // const address_text_data = item?.allData[0]?.address_components.map((item: any, index: any) => {
                //     if(index < address_text_index) {
                //         return item?.short_name
                //     }
                // }).filter((itemData: any) => itemData).reverse();
                let addressData = {
                  country: item?.allData[0]?.address_components.find((item: any) =>
                    item?.types?.includes('country')
                  )?.short_name,
                  city:
                    item?.allData[0]?.address_components.find((item: any) =>
                      item?.types?.includes('locality')
                    )?.long_name ||
                    item?.allData[0]?.address_components.find((item: any) =>
                      item?.types?.includes('administrative_area_level_1')
                    )?.short_name,
                  postCode: item?.allData[0]?.address_components.find((item: any) =>
                    item?.types?.includes('postal_code')
                  )?.short_name,
                  district: item?.allData[0]?.address_components.find((item: any) =>
                    item?.types?.includes('administrative_area_level_2')
                  )?.short_name,
                  neighborhood: item?.allData[0]?.address_components.find((item: any) =>
                    item?.types?.includes('administrative_area_level_4')
                  )?.short_name,
                  street: item?.allData[0]?.address_components.find((item: any) =>
                    item?.types?.includes('route')
                  )?.short_name,
                  streetNumber: item?.allData[0]?.address_components.find((item: any) =>
                    item?.types?.includes('street_number')
                  )?.short_name,
                  addressText: item?.allData[0]?.formatted_address,
                  latitude: item?.allData[0]?.geometry?.location?.lat(),
                  longitude: item?.allData[0]?.geometry?.location?.lng(),
                }
                searchSelectedControl(addressData)
              }}
              searchLabel={searchLabel}
              searchLat={searchLat}
              searchLong={searchLong}
            />
          </div>
          <hr />

          <div className='text-center mt-5 row'>
            <div className={'col-md-6'}>
              <button
                onClick={() => handleClose()}
                type='button'
                id='kt_sign_up_submit'
                className='btn btn-lg btn-danger w-100 mb-5'
              >
                {intl.formatMessage({id: 'addNewAddressPopup.area2.cancel'})}
              </button>
            </div>
            <div className={'col-md-6'}>
              <button
                onClick={() => {
                  formik.handleSubmit()
                }}
                type='submit'
                id='kt_sign_up_submit'
                className='btn btn-lg btn-primary w-100 mb-5'
              >
                {intl.formatMessage({id: 'addNewAddressPopup.area2.approve'})}
              </button>
            </div>
          </div>
        </div>
      </RightLayoutPopup>
    </>
  )
}

export {CreateMapAddress}
