import React, {useState, useCallback, useEffect} from 'react'
import {useTable, usePagination, useExpanded} from 'react-table'
import {useIntl} from 'react-intl'

import ActionDropDown from '../../../_metronic/partials/content/dropdown/ActionDropdown'

import TableLoader from '../../../app/Components/TableLoader'

import {ReactComponent as NextActive} from './assets/next-active.svg'
import {ReactComponent as NextInActive} from './assets/next-inactive.svg'

import {ReactComponent as NextMostActive} from './assets/next-most-active.svg'
import {ReactComponent as NextMostInActive} from './assets/next-most-inactive.svg'
import {ReactComponent as BackMostActive} from './assets/back-most-active.svg'
import {ReactComponent as BackMostInActive} from './assets/back-most-inactive.svg'
import './styles.scss'

// Create an editable cell renderer
const EditableCell = ({
  value: initialValue,
  row: {index},
  column: {id},
  updateMyData, // This is a custom function that we supplied to our table instance
}: any) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue)

  const onChange = (e: any) => {
    setValue(e.target.value)
  }

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    updateMyData(index, id, value)
  }

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  return <input value={value} onChange={onChange} onBlur={onBlur} className={'form-control'} />
}

// Set our editable cell renderer as the default Cell renderer
const defaultColumn = {
  Cell: EditableCell,
}

const Row = ({
  row,
  editableRows,
  updateRow,
  deleteRow,
  deleteText,
  createBtn,
  proposalBtn,
  proposalBtnText,
  attachmentFrieghtBtn,
  detachmentBtn,
  unsuccessBtn,
  editBtn,
  selectedRowData,
  showDetailBtn,
  showDetailBtnText,
  showMapBtn,
  sendToApproveBtn,
  advertApprove,
  advertReject,
  showFile,
  showFileText,
  containerMatch,
  containerPublish,
  containerUnPublish,
  hiddenButtons,
  deleteRowSecondIcon,
  deleteRowSecondIconText,
  onRowClick,
  subTableComponentData,
  selectedSubTableId,
  ChildSubTableComponent,
  subTableLoading,
  rowProps = () => {},
}: any) => {
  const intl = useIntl()
  const [edit, setEdit] = useState<boolean>(false)
  const today: any = new Date()
  const getColor = (val: any) => {
    if (val && val !== '-') {
      let myDate = new Date(val).getTime()
      return myDate <= today.getTime() ? '#FF8787' : '#BCE29E'
    } else return '#F8C4B4'
  }
  const [activeRow, setActiveRow] = useState<number>(-1)
  return (
    <React.Fragment>
      <tr
        {...row.getRowProps()}
        id={onRowClick ? 'transport-anchor-element-' + row?.values.id : ''}
        onClick={() => {
          onRowClick && onRowClick(row)
          setActiveRow(row.values.id)
        }}
        className={selectedRowData === row.id ? 'selected-list-item-row' : ''}
        style={{backgroundColor: row.values.id === activeRow ? 'rgba(241,241,241,0.34)' : ''}}
        {...rowProps(row)}
      >
        {row.cells.map((cell: any) => {
          return (
            cell.column.id !== 'id' &&
            cell.column.id !== 'expander' &&
            !cell.column?.hidden && (
              <td
                {...cell.getCellProps()}
                style={{
                  cursor: onRowClick ? 'pointer' : '',
                  whiteSpace: 'pre-wrap',
                  ...(cell.column.id === 'issueStartDate' && {
                    backgroundColor: getColor(cell.value),
                  }),
                  ...cell.column.cellStyle,
                }}
              >
                {(edit && editableRows.includes(cell.column.id)) || cell.column.isAction
                  ? cell.render('Cell')
                  : cell.value}
              </td>
            )
          )
        })}
        {editableRows?.length > 0 && (
          <td role='cell' className='action-td'>
            {!edit ? (
              <>
                <ActionDropDown>
                  <div className='menu-item px-3'>
                    {/*Create Btn*/}
                    {createBtn && !hiddenButtons?.createBtn && (
                      <button
                        title={intl.formatMessage({id: 'DataTableAdvanced.buttons.create'})}
                        className='btn btn-icon btn-light btn-bg-light btn-active-color-primary btn-sm me-1 mb-2 w-100'
                        onClick={() => {
                          createBtn && createBtn(row)
                        }}
                      >
                        {/* <KTSVG path='/media/icons/duotune/art/art011.svg' className='svg-icon-3' /> */}
                        <span>{intl.formatMessage({id: 'DataTableAdvanced.buttons.create'})}</span>
                      </button>
                    )}

                    {/*Edit Btn*/}
                    {editBtn && !hiddenButtons?.editBtn && (
                      <button
                        title={intl.formatMessage({id: 'DataTableAdvanced.buttons.edit'})}
                        className='btn btn-icon btn-light btn-bg-light btn-active-color-primary btn-sm me-1 mb-2 w-100'
                        onClick={() => {
                          !editBtn && setEdit(true)
                          /*updateRow && updateRow(row);*/
                          editBtn && editBtn(row)
                        }}
                      >
                        {/* <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' /> */}
                        <span>{intl.formatMessage({id: 'DataTableAdvanced.buttons.edit'})}</span>
                      </button>
                    )}

                    {/*Delete Btn*/}
                    {deleteRow && !hiddenButtons?.deleteRow && (
                      <button
                        title={
                          deleteText || intl.formatMessage({id: 'DataTableAdvanced.buttons.delete'})
                        }
                        className='btn btn-icon btn-light btn-bg-light btn-active-color-primary btn-sm me-1 mb-2 w-100'
                        onClick={() => {
                          deleteRow && deleteRow(row)
                        }}
                      >
                        {/* <KTSVG
                    path={
                      row.values.isFreightPostings
                        ? '/media/icons/duotune/art/art012.svg'
                        : '/media/icons/duotune/general/gen027.svg'
                    }
                    className='svg-icon-3'
                  /> */}
                        <span>
                          {deleteText ||
                            intl.formatMessage({id: 'DataTableAdvanced.buttons.delete'})}
                        </span>
                      </button>
                    )}

                    {/*Delete Btn*/}
                    {deleteRowSecondIcon && !hiddenButtons?.deleteRowSecondIcon && (
                      <button
                        title={
                          deleteRowSecondIconText ||
                          intl.formatMessage({id: 'DataTableAdvanced.buttons.delete'})
                        }
                        className='btn btn-icon btn-light btn-bg-light btn-active-color-primary btn-sm me-1 mb-2 w-100'
                        onClick={() => {
                          deleteRowSecondIcon && deleteRowSecondIcon(row)
                        }}
                      >
                        {/* <KTSVG path='/media/icons/duotune/arrows/arr015.svg' className='svg-icon-3' /> */}
                        <span>
                          {deleteRowSecondIconText ||
                            intl.formatMessage({id: 'DataTableAdvanced.buttons.delete'})}
                        </span>
                      </button>
                    )}

                    {/*Show Details btn*/}
                    {showDetailBtn && !hiddenButtons?.showDetailBtn && (
                      <button
                        title={
                          showDetailBtnText ||
                          intl.formatMessage({id: 'DataTableAdvanced.buttons.showDetails'})
                        }
                        className='btn btn-icon btn-light btn-bg-light btn-active-color-primary btn-sm me-1 mb-2 w-100'
                        onClick={() => {
                          showDetailBtn && showDetailBtn(row)
                        }}
                      >
                        {/* <span className='menu-title font-action-title'><KTSVG path='/media/icons/duotune/general/gen004.svg' className='svg-icon-3' /></span> */}

                        <span>
                          {showDetailBtnText ||
                            intl.formatMessage({id: 'DataTableAdvanced.buttons.showDetails'})}
                        </span>
                      </button>
                    )}

                    {/*Send to Approve btn*/}
                    {sendToApproveBtn && !hiddenButtons?.sendToApproveBtn && (
                      <button
                        title={intl.formatMessage({id: 'DataTableAdvanced.buttons.sendToApprove'})}
                        className='btn btn-icon btn-light btn-bg-light btn-active-color-primary btn-sm me-1 mb-2 w-100'
                        onClick={() => {
                          sendToApproveBtn && sendToApproveBtn(row)
                        }}
                      >
                        {/* <KTSVG path='/media/icons/duotune/general/gen016.svg' className='svg-icon-3' /> */}
                        <span>
                          {intl.formatMessage({id: 'DataTableAdvanced.buttons.sendToApprove'})}
                        </span>
                      </button>
                    )}

                    {/*Send Documents btn
                            {sendDocs && <button
                                title={intl.formatMessage({id: "DataTableAdvanced.buttons.sendDocuments"})}
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 mb-2'
                                onClick={() => sendDocs && sendDocs(row)}
                            >
                                <KTSVG path='/media/icons/duotune/files/fil005.svg'
                                       className='svg-icon-2'/>
                            </button>
                            }*/}

                    {/*Map btn*/}
                    {showMapBtn && !hiddenButtons?.showMapBtn && (
                      <button
                        title={intl.formatMessage({id: 'DataTableAdvanced.buttons.showOnMap'})}
                        className='btn btn-icon btn-light btn-bg-light btn-active-color-primary btn-sm me-1 mb-2 w-100'
                        onClick={() => {
                          showMapBtn && showMapBtn(row)
                        }}
                      >
                        {/* <img src='/media/svg/external/map.svg' height={20} width={20} alt='map' /> */}
                        <span>
                          {intl.formatMessage({id: 'DataTableAdvanced.buttons.showOnMap'})}
                        </span>
                      </button>
                    )}

                    {advertApprove && !hiddenButtons?.advertApprove && (
                      <button
                        title={intl.formatMessage({id: 'DataTableAdvanced.buttons.loadApprove'})}
                        className='btn btn-icon btn-light btn-bg-light btn-active-color-primary btn-sm me-1 mb-2 w-100'
                        onClick={() => {
                          advertApprove(row)
                        }}
                      >
                        {/* <KTSVG path='/media/icons/duotune/arrows/arr012.svg' className='svg-icon-1' /> */}
                        <span>
                          {intl.formatMessage({id: 'DataTableAdvanced.buttons.loadApprove'})}
                        </span>
                      </button>
                    )}

                    {advertReject && !hiddenButtons?.advertReject && (
                      <button
                        title={intl.formatMessage({id: 'DataTableAdvanced.buttons.rejectApprove'})}
                        className='btn btn-icon btn-light btn-bg-light btn-active-color-primary btn-sm me-1 mb-2 w-100'
                        onClick={() => {
                          advertReject(row)
                        }}
                      >
                        {/* <KTSVG path='/media/icons/duotune/arrows/arr015.svg' className='svg-icon-3' /> */}
                        <span>
                          {intl.formatMessage({id: 'DataTableAdvanced.buttons.rejectApprove'})}
                        </span>
                      </button>
                    )}

                    {showFile && !hiddenButtons?.showFile && (
                      <button
                        title={
                          showFileText ||
                          intl.formatMessage({id: 'DataTableAdvanced.buttons.showFile'})
                        }
                        className='btn btn-icon btn-light btn-bg-light btn-active-color-primary btn-sm me-1 mb-2 w-100'
                        onClick={() => {
                          showFile && showFile(row)
                        }}
                      >
                        {/* <KTSVG path='/media/icons/duotune/files/fil003.svg' className='svg-icon-3' /> */}
                        <span>
                          {showFileText ||
                            intl.formatMessage({id: 'DataTableAdvanced.buttons.showFile'})}
                        </span>
                      </button>
                    )}

                    {containerMatch && !hiddenButtons?.containerMatch && (
                      <button
                        title={intl.formatMessage({id: 'DataTableAdvanced.buttons.match'})}
                        className='btn btn-icon btn-light btn-bg-light btn-active-color-primary btn-sm me-1 mb-2 w-100'
                        onClick={() => {
                          containerMatch && containerMatch(row)
                        }}
                      >
                        {/* <KTSVG path='/media/icons/duotune/arrows/arr031.svg' className='svg-icon-3' /> */}
                        <span>{intl.formatMessage({id: 'DataTableAdvanced.buttons.match'})}</span>
                      </button>
                    )}

                    {containerPublish && !hiddenButtons?.containerPublish && (
                      <button
                        title={intl.formatMessage({id: 'DataTableAdvanced.buttons.publish'})}
                        className='btn btn-icon btn-light btn-bg-light btn-active-color-primary btn-sm me-1 mb-2 w-100'
                        onClick={() => {
                          containerPublish && containerPublish(row)
                        }}
                      >
                        {/* <KTSVG path='/media/icons/duotune/general/gen016.svg' className='svg-icon-3' /> */}
                        <span>{intl.formatMessage({id: 'DataTableAdvanced.buttons.publish'})}</span>
                      </button>
                    )}

                    {containerUnPublish && !hiddenButtons?.containerUnPublish && (
                      <button
                        title={intl.formatMessage({id: 'DataTableAdvanced.buttons.unPublish'})}
                        className='btn btn-icon btn-light btn-bg-light btn-active-color-primary btn-sm me-1 mb-2 w-100'
                        onClick={() => {
                          containerUnPublish && containerUnPublish(row)
                        }}
                      >
                        {/* <KTSVG path='/media/icons/duotune/abstract/abs012.svg' className='svg-icon-3' /> */}
                        <span>
                          {intl.formatMessage({id: 'DataTableAdvanced.buttons.unPublish'})}
                        </span>
                      </button>
                    )}

                    {/*Proposal Btn*/}
                    {proposalBtn && !hiddenButtons?.proposalBtn && (
                      <button
                        title={
                          proposalBtnText ||
                          intl.formatMessage({id: 'DataTableAdvanced.buttons.proposal'})
                        }
                        className='btn btn-icon btn-light btn-bg-light btn-active-color-primary btn-sm me-1 mb-2 w-100'
                        onClick={() => {
                          proposalBtn && proposalBtn(row)
                        }}
                      >
                        {/* <KTSVG path='/media/icons/duotune/art/art013.svg' className='svg-icon-3' /> */}
                        <span>
                          {proposalBtnText ||
                            intl.formatMessage({id: 'DataTableAdvanced.buttons.proposal'})}
                        </span>
                      </button>
                    )}

                    {/*Attachment Freight Btn*/}
                    {attachmentFrieghtBtn && !hiddenButtons?.attachmentFrieghtBtn && (
                      <button
                        title={intl.formatMessage({
                          id: 'DataTableAdvanced.buttons.attachmentFreight',
                        })}
                        className='btn btn-icon btn-light btn-bg-light btn-active-color-primary btn-sm me-1 mb-2 w-100'
                        onClick={() => {
                          attachmentFrieghtBtn && attachmentFrieghtBtn(row)
                        }}
                      >
                        {/* <KTSVG path='/media/icons/duotune/art/art013.svg' className='svg-icon-3' /> */}
                        <span>
                          {intl.formatMessage({id: 'DataTableAdvanced.buttons.attachmentFreight'})}
                        </span>
                      </button>
                    )}

                    {/*detachmentBtn Freight Btn*/}
                    {detachmentBtn && !hiddenButtons?.detachmentBtn && (
                      <button
                        title={intl.formatMessage({id: 'DataTableAdvanced.buttons.detachFreight'})}
                        className='btn btn-icon btn-light btn-bg-light btn-active-color-primary btn-sm me-1 mb-2 w-100'
                        onClick={() => {
                          detachmentBtn && detachmentBtn(row)
                        }}
                      >
                        {/* <KTSVG path='/media/icons/duotune/art/art013.svg' className='svg-icon-3' /> */}
                        <span>
                          {intl.formatMessage({id: 'DataTableAdvanced.buttons.detachFreight'})}
                        </span>
                      </button>
                    )}

                    {unsuccessBtn && !hiddenButtons?.unsuccessBtn && (
                      <button
                        title={intl.formatMessage({id: 'DataTableAdvanced.buttons.successful'})}
                        className='btn btn-icon btn-light btn-bg-light btn-active-color-primary btn-sm me-1 mb-2 w-100'
                        onClick={() => {
                          unsuccessBtn && unsuccessBtn(row)
                        }}
                      >
                        {/* <KTSVG path='/media/icons/duotune/art/art014.svg' className='svg-icon-3' /> */}
                        <span>
                          {intl.formatMessage({id: 'DataTableAdvanced.buttons.successful'})}
                        </span>
                      </button>
                    )}
                  </div>
                </ActionDropDown>
              </>
            ) : (
              /*Save Btn*/
              <button
                title={intl.formatMessage({id: 'DataTableAdvanced.buttons.saveChanges'})}
                className='btn btn-icon btn-light btn-bg-light btn-active-color-primary btn-sm me-1 save-btn mb-2 w-100'
                onClick={() => {
                  setEdit(false)
                  updateRow && updateRow(row)
                }}
              >
                {/* <KTSVG path='/media/icons/duotune/general/gen037.svg' className='svg-icon-3' /> */}
                <span>{intl.formatMessage({id: 'DataTableAdvanced.buttons.saveChanges'})}</span>
              </button>
            )}
          </td>
        )}

        {row.cells.map((cell: any) => {
          return (
            cell.column.id !== 'id' &&
            cell.column.id === 'expander' &&
            !cell.column?.hidden && (
              <td
                {...cell.getCellProps()}
                style={{
                  cursor: onRowClick ? 'pointer' : '',
                  whiteSpace: 'pre-wrap',
                  ...(cell.column.id === 'issueStartDate' && {
                    backgroundColor: getColor(cell.value),
                  }),
                  ...cell.column.cellStyle,
                }}
              >
                {(edit && editableRows.includes(cell.column.id)) || cell.column.isAction
                  ? cell.render('Cell')
                  : cell.value}
              </td>
            )
          )
        })}
      </tr>
      {row?.id === selectedSubTableId && (
        <tr {...row.getRowProps()}>
          <td colSpan={row.cells.length}>
            {subTableLoading ? (
              <TableLoader />
            ) : subTableComponentData.length > 0 ? (
              ChildSubTableComponent
            ) : (
              <div className='sub-table-no-data'>
                {intl.formatMessage({id: 'DataTableAdvanced.subTable.noData'})}
              </div>
            )}
          </td>
        </tr>
      )}
    </React.Fragment>
  )
}

// Be sure to pass our updateMyData and the skipPageReset option
function DataTableAdvanced({
  columns,
  data,
  updateMyData,
  skipPageReset,
  editableRows,
  createBtn,
  proposalBtn,
  proposalBtnText,
  attachmentFrieghtBtn,
  detachmentBtn,
  unsuccessBtn,
  updateRow,
  deleteRow,
  deleteText,
  editBtn,
  showDetailBtn,
  showDetailBtnText,
  showMapBtn,
  sendToApproveBtn,
  sendDocs,
  advertApprove,
  advertReject,
  showFile,
  showFileText,
  containerMatch,
  containerPublish,
  containerUnPublish,
  hiddenButtons,
  selectedRowData,
  deleteRowSecondIcon,
  deleteRowSecondIconText,
  onRowClick,
  pageMIndex,
  setPageMIndex,
  showPaginaton = true,
  subTableComponentData,
  selectedSubTableId,
  ChildSubTableComponent,
  subTableLoading,
  isSubTable,
  subTablecolumnStyle,
  rowProps,
  subHeaderStyles,
  advertisementListData = [],
}: {
  columns: any[]
  data: any[]
  updateMyData?: any
  skipPageReset?: any
  editableRows?: any[]
  createBtn?: any
  proposalBtn?: any
  proposalBtnText?: any
  attachmentFrieghtBtn?: any
  detachmentBtn?: any
  unsuccessBtn?: any
  updateRow?: any
  deleteRow?: any
  deleteText?: any
  editBtn?: any
  showDetailBtn?: any
  showDetailBtnText?: any
  showMapBtn?: any
  sendToApproveBtn?: any
  sendDocs?: any
  adminAdvertButtons?: {isHide?: boolean; content?: any; rowData?: any}[]
  selectedRowData?: any
  advertApprove?: any
  advertReject?: any
  showFile?: any
  showFileText?: any
  containerMatch?: any
  containerPublish?: any
  containerUnPublish?: any
  hiddenButtons?: any
  deleteRowSecondIcon?: any
  deleteRowSecondIconText?: any
  onRowClick?: any
  pageMIndex?: any
  setPageMIndex?: any
  showPaginaton?: any
  subTableComponentData?: any
  selectedSubTableId?: any
  ChildSubTableComponent?: any
  subTableLoading?: any
  isSubTable?: any
  subTablecolumnStyle?: any
  rowProps?: any
  subHeaderStyles?: any
  advertisementListData?: any
}) {
  // For this example, we're using pagination to illustrate how to stop
  // the current page from resetting when our data changes
  // Otherwise, nothing is different here.
  const intl = useIntl()
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
  }: // state: {pageIndex, pageSize},
  any = useTable(
    {
      columns,
      data,
      // @ts-ignore
      initialState: {pageSize: 20},
      defaultColumn,
      // @ts-ignore
      autoResetPage: /* !skipPageReset*/ false,
      updateMyData,
    },
    useExpanded,
    usePagination
  )

  const handleKeyDown = useCallback(
    (e) => {
      const {key} = e
      switch (key) {
        case 'ArrowRight':
          // Move one col right, add one
          if (!(page.length < 20)) {
            setPageMIndex(pageMIndex + 1)
          }
          break
        case 'ArrowLeft':
          // Move one col left, subtract one
          if (pageMIndex !== 0) {
            setPageMIndex(pageMIndex - 1)
          }
          break
        default:
          break
      }
    },
    [page, pageMIndex]
  )

  // Add listeners on mount, remove on unmount
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [handleKeyDown])
  // Render the UI for your table
  return (
    <>
      <div className={'table-container'}>
        <table {...getTableProps()} className={'table-advanced'}>
          <thead>
            {headerGroups.map((headerGroup: any) => (
              <tr {...headerGroup.getHeaderGroupProps()} {...subHeaderStyles}>
                {headerGroup.headers.map((column: any) => {
                  return (
                    column.id !== 'id' &&
                    !column?.hidden && (
                      <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                    )
                  )
                })}
                {editableRows && editableRows.length > 0 && (
                  <th style={{width: 100, ...(isSubTable ? {...subTablecolumnStyle} : '')}} />
                )}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row: any, index: any) => {
              prepareRow(row)
              let hiddenButtonField: any = hiddenButtons

              if (proposalBtn && !row?.values?.isContainer) {
                hiddenButtonField = {
                  ...hiddenButtons,
                  proposalBtn:
                    row?.values?.statusCode !== 'APPROVED' &&
                    row?.values?.statusCode !== 'CONTAINER_ASSIGNED',
                }
              }

              let advertisementData: any = {}
              if (advertisementListData?.length > 0) {
                advertisementData = advertisementListData?.find(
                  (dataItem: any) => dataItem?.index === pageMIndex * 20 + index
                )
              }

              return (
                <>
                  {advertisementData && Object.keys(advertisementData)?.length > 0 && (
                    <tr>
                      <td colSpan={15}>
                        <div className={''} key={advertisementData?.advertisementId}>
                          <div
                            className='img-advert-table-content-wrap'
                            style={{cursor: advertisementData?.targetUrl ? 'pointer' : 'auto'}}
                            onClick={() =>
                              advertisementData?.targetUrl
                                ? window.open(advertisementData?.targetUrl, '_blank')
                                : () => {}
                            }
                          >
                            <img
                              src={advertisementData?.defaultImage}
                              alt='adevrtisement'
                              className='img-advert-table-wrap'
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}
                  <Row
                    row={row}
                    key={row.id}
                    editableRows={editableRows}
                    updateRow={updateRow}
                    deleteRow={deleteRow}
                    deleteText={deleteText}
                    selectedRowData={selectedRowData}
                    deleteRowSecondIcon={deleteRowSecondIcon}
                    deleteRowSecondIconText={deleteRowSecondIconText}
                    sendDocs={sendDocs}
                    onRowClick={onRowClick}
                    createBtn={createBtn}
                    proposalBtn={proposalBtn}
                    proposalBtnText={proposalBtnText}
                    attachmentFrieghtBtn={attachmentFrieghtBtn}
                    detachmentBtn={detachmentBtn}
                    unsuccessBtn={unsuccessBtn}
                    editBtn={editBtn}
                    showDetailBtn={showDetailBtn}
                    showDetailBtnText={showDetailBtnText}
                    showMapBtn={showMapBtn}
                    sendToApproveBtn={sendToApproveBtn}
                    containerMatch={containerMatch}
                    advertApprove={advertApprove}
                    advertReject={advertReject}
                    showFile={showFile}
                    showFileText={showFileText}
                    containerPublish={containerPublish}
                    containerUnPublish={containerUnPublish}
                    hiddenButtons={{
                      ...hiddenButtonField,
                      ...(row?.values?.hiddenButtons ? row?.values?.hiddenButtons : ''),
                    }}
                    subTableComponentData={subTableComponentData}
                    selectedSubTableId={selectedSubTableId}
                    ChildSubTableComponent={ChildSubTableComponent}
                    subTableLoading={subTableLoading}
                    rowProps={rowProps}
                    advertisementData={advertisementData}
                  />
                </>
              )
            })}
          </tbody>
        </table>
      </div>
      {showPaginaton && (
        <div className='pagination'>
          {/*Left - Page of*/}
          {/* <span className={'page-of'}>
          {intl.formatMessage({id: 'DataTableAdvanced.pagination.page'})}
          <strong style={{marginLeft: '10px'}}>
            {pageMIndex + 1}/{pageMIndex + 1}
          </strong>
        </span> */}
          {/*Middle - arrows and go to */}
          <div className={'pagination-buttons'} /*style={{marginLeft: '20px'}} */>
            {/* <button
                        className={'back-most pag-btn'}
                        onClick={() => gotoPage(0)}
                        disabled={!canPreviousPage}
                    >
                        {canPreviousPage ? <BackMostActive/> : <BackMostInActive/>}
                    </button> */}
            <button
              className={'back pag-btn'}
              onClick={() => setPageMIndex(pageMIndex - 1)}
              disabled={pageMIndex === 0}
            >
              <span className={'previous-arrow'}>
                {!(pageMIndex === 0) ? <NextActive /> : <NextInActive />}
              </span>
            </button>
            <button
              className={'next pag-btn'}
              onClick={() => setPageMIndex(pageMIndex + 1)}
              disabled={page.length < 20}
            >
              {!(page.length < 20) ? <NextActive /> : <NextInActive />}
            </button>

            {/* <button
                        className={'next-most pag-btn'}
                        onClick={() => gotoPage(pageCount - 1)}
                        disabled={!canNextPage}
                    >
                        {canNextPage ? <NextMostActive/> : <NextMostInActive/>}
                    </button> */}
          </div>
          {/*Go to*/}
          {/* <span className={'go-to-page'} style={{marginLeft: '20px'}}>
                      {intl.formatMessage({id: "DataTableAdvanced.pagination.goToPage"})}
                    <input
                        className={'go-to-textbox form-control '}
                        type="number"
                        max={pageOptions.length}
                        min={1}
                        value={pageIndex + 1}
                        onChange={e => {
                            const page = e.target.value ? Number(e.target.value) - 1 : 0;
                            gotoPage(page);
                        }}
                        style={{width: '80px'}}
                    />
                </span> */}

          {/*Right - show */}
          {/* <span className={'show-on-page'} style={{marginLeft: '20px'}}>
                    {intl.formatMessage({id: "DataTableAdvanced.pagination.showOnPage"})}
                    <select
                        className={'form-select select-inp'}
                        value={pageSize}
                        onChange={e => {
                            setPageSize(Number(e.target.value));
                        }}
                        style={{width: '80px'}}
                    >
                     {[10, 20, 30, 40, 50].map(pageSize => (
                         <option key={pageSize} value={pageSize}>
                             {pageSize}
                         </option>
                     ))}
                    </select>
                    {intl.formatMessage({id: "DataTableAdvanced.pagination.showData"})}
                     </span> */}
        </div>
      )}
    </>
  )
}

export default DataTableAdvanced
