import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import clsx from 'clsx'

import RightLayoutPopup from '../../../../Components/RightLayoutPopup'
import Dropbox from '../../../../Components/Dropbox'
import PhoneInp from '../../../../Components/PhoneInp/PhoneInp'

import useScrollLock from '../../../../../utils/useScrollLock'

const CreateClient = ({
  countriesList,
  ClientList,
  setLoading,
  handleCreateClient,
  onClose,
}: any) => {
  const intl = useIntl()

  const [companyName, setCompanyName] = useState<string>('')
  const [companyTitle, setCompanyTitle] = useState<string>('')

  const [name, setName] = useState<string>('')
  const [surName, setSurName] = useState<string>('')

  const [email, setEmail] = useState<string>('')
  const [web, setWeb] = useState<string>('')

  const [taxNumber, setTaxNumber] = useState<string>('')
  const [taxOffice, setTaxOffice] = useState<string>('')
  const [identityNumber, setIdentityNumber] = useState<string>('')

  const [clientType, setClientType] = useState<any>('')
  const [phoneCode, setPhoneCode] = useState<string>('+90')
  const [mobile, setMobile] = useState<string>('')

  const [countrySearch, setCountrySearch] = useState<string>('')
  const [country, setCountry] = useState<string>('')

  const [countryScrollAPICalled, setCountryScrollAPICalled] = useState<boolean>(false)

  const {scrollLock, scrollUnlock} = useScrollLock()

  useEffect(() => {
    scrollLock()
  }, [])

  const handleCreateDisbaled = (clientType: number) => {
    if (!clientType) {
      return true
    } else {
      if (clientType === 1) {
        return (
          !companyName ||
          !companyTitle ||
          !email ||
          !phoneCode ||
          !mobile ||
          !country ||
          !web ||
          !taxNumber ||
          !taxOffice
        )
      } else {
        return (
          !name ||
          !surName ||
          !email ||
          !phoneCode ||
          !mobile ||
          !country ||
          !web ||
          !identityNumber
        )
      }
    }
  }

  return (
    <RightLayoutPopup
      title={intl.formatMessage({id: 'CreateClient.create.title'})}
      className='create-client-wrap'
      isCloseIcon
      closeOnClick={() => onClose()}
    >
      <div>
        <div className='row'>
          <div className={'col-md-4 mb-10'}>
            <label className='form-label fs-6 fw-bolder text-dark'>
              {intl.formatMessage({id: 'CreateClient.create.clientType'})}
            </label>

            <Dropbox
              hideArrow
              customClass={'form-select form-control form-control-lg'}
              placeholder={intl.formatMessage({id: 'CreateClient.create.clientType'})}
              customSearchValue={''}
              customOnSearch={(e: any) => {}}
              disabledSearch
              onScroll={() => {}}
              setScrollCalled={() => {}}
              currentPageIndex={0}
              menuList={
                ClientList &&
                ClientList.map((item: any) => {
                  return {
                    id: item?.clientTypeId,
                    title: item?.description,
                  }
                })
              }
              selectedItemData={(item: any) => {
                if (item?.id) {
                  setCompanyName('')
                  setCompanyTitle('')
                  setIdentityNumber('')
                  setName('')
                  setSurName('')
                  setTaxNumber('')
                  setTaxOffice('')
                  setClientType(Number(item?.id))
                }
              }}
            />
          </div>

          {clientType === 1 && (
            <>
              <div className='col-md-4 fv-row mb-10'>
                <label className='form-label fs-6 fw-bolder text-dark'>
                  {intl.formatMessage({id: 'CreateClient.create.companyName'})}
                </label>
                <input
                  placeholder={intl.formatMessage({id: 'CreateClient.create.companyName'})}
                  className={clsx('form-control form-control-lg form-control-solid')}
                  onChange={(event: any) => setCompanyName(event.target.value)}
                  value={companyName}
                  type='text'
                  name='name'
                  autoComplete='off'
                />
              </div>

              <div className='col-md-4 fv-row mb-10'>
                <label className='form-label fs-6 fw-bolder text-dark'>
                  {intl.formatMessage({id: 'CreateClient.create.companyTitle'})}
                </label>
                <input
                  placeholder={intl.formatMessage({id: 'CreateClient.create.companyTitle'})}
                  className={clsx('form-control form-control-lg form-control-solid')}
                  onChange={(event: any) => setCompanyTitle(event.target.value)}
                  value={companyTitle}
                  type='text'
                  name='name'
                  autoComplete='off'
                />
              </div>
            </>
          )}

          {clientType === 2 && (
            <>
              <div className='col-md-4 fv-row mb-10'>
                <label className='form-label fs-6 fw-bolder text-dark'>
                  {intl.formatMessage({id: 'CreateClient.create.name.name'})}
                </label>
                <input
                  placeholder={intl.formatMessage({id: 'CreateClient.create.name.name'})}
                  className={clsx('form-control form-control-lg form-control-solid')}
                  onChange={(event: any) => setName(event.target.value)}
                  value={name}
                  type='text'
                  name='name'
                  autoComplete='off'
                />
              </div>

              <div className='col-md-4 fv-row mb-10'>
                <label className='form-label fs-6 fw-bolder text-dark'>
                  {intl.formatMessage({id: 'CreateClient.create.surName'})}
                </label>
                <input
                  placeholder={intl.formatMessage({id: 'CreateClient.create.surName'})}
                  className={clsx('form-control form-control-lg form-control-solid')}
                  onChange={(event: any) => setSurName(event.target.value)}
                  value={surName}
                  type='text'
                  name='name'
                  autoComplete='off'
                />
              </div>
            </>
          )}
        </div>

        <div className='fv-row mb-10'>
          <label className='form-label fs-6 fw-bolder text-dark'>
            {intl.formatMessage({id: 'CreateClient.create.email'})}
          </label>
          <input
            placeholder={intl.formatMessage({id: 'CreateClient.create.email'})}
            className={clsx('form-control form-control-lg form-control-solid')}
            onChange={(event: any) => setEmail(event.target.value)}
            value={email}
            type='text'
            name='name'
            autoComplete='off'
          />
        </div>

        <div className={'row col-md-12 mb-10'}>
          <div className={'col-md-3'}>
            <label
              htmlFor={'phoneCode'}
              className='class="form-label fw-bolder text-dark fs-6 mb-2'
            >
              {intl.formatMessage({id: 'Carrier.drivers.create.phoneCode'})}
            </label>
            <PhoneInp
              id={'phoneCode'}
              onSelect={(event: any) => {
                setPhoneCode(event.length > 0 ? '+' + event : event)
              }}
            />
          </div>
          <div className='fv-row col-md-5'>
            <label className='form-label fs-6 fw-bolder text-dark'>
              {intl.formatMessage({id: 'Carrier.drivers.create.phone'})}
            </label>
            <input
              placeholder={intl.formatMessage({id: 'Carrier.drivers.create.phone.placeHolder'})}
              maxLength={13}
              className={clsx('form-control form-control-lg form-control-solid')}
              onChange={(event: any) => {
                if (event.target.value.match(/^\+?(?:[\d]*)$/)) {
                  setMobile(event.target.value)
                }
              }}
              value={mobile}
              type='text'
              name='mobile'
              autoComplete='off'
              required
            />
          </div>
          <div className={'col-md-4'}>
            <label className='form-label fs-6 fw-bolder text-dark'>
              {intl.formatMessage({id: 'CreateClient.create.country'})}
            </label>
            <Dropbox
              hideArrow
              customClass={'form-select form-control form-control-lg'}
              placeholder={intl.formatMessage({id: 'CreateClient.create.country'})}
              customSearchValue={countrySearch}
              customOnSearch={(e: any) => {
                setCountrySearch(e.target.value)
              }}
              onScroll={(val: any) => {}}
              setScrollCalled={() => {
                setCountryScrollAPICalled(true)
              }}
              scrollAPIResponse={countryScrollAPICalled}
              currentPageIndex={0}
              menuList={
                countriesList &&
                countriesList.map((item: any) => {
                  return {
                    id: item?.code,
                    title: item?.name,
                  }
                })
              }
              defaultSelected={{id: country}}
              defaultClearItem={{
                id: '',
                title: '',
              }}
              selectedItemData={(item: any) => {
                if (item?.id) {
                  setCountry(item?.id)
                }

                if (item?.id === '' && item?.title === '') {
                  setCountry('')
                }
              }}
            />
          </div>
        </div>

        <div className='fv-row mb-10'>
          <label className='form-label fs-6 fw-bolder text-dark'>
            {intl.formatMessage({id: 'CreateClient.create.web'})}
          </label>
          <input
            placeholder={intl.formatMessage({id: 'CreateClient.create.web'})}
            className={clsx('form-control form-control-lg form-control-solid')}
            onChange={(event: any) => setWeb(event.target.value)}
            value={web}
            type='text'
            name='name'
            autoComplete='off'
          />
        </div>

        <div className='row'>
          <div className='fv-row mb-10 col-md-4'>
            <label className='form-label fs-6 fw-bolder text-dark'>
              {intl.formatMessage({id: 'CreateClient.create.taxNumber'})}
            </label>
            <input
              placeholder={intl.formatMessage({id: 'CreateClient.create.taxNumber'})}
              className={clsx('form-control form-control-lg form-control-solid')}
              onChange={(event: any) => setTaxNumber(event.target.value)}
              value={taxNumber}
              disabled={clientType === 2 || clientType === ''}
              type='text'
              name='name'
              autoComplete='off'
            />
          </div>

          <div className='fv-row mb-10 col-md-4'>
            <label className='form-label fs-6 fw-bolder text-dark'>
              {intl.formatMessage({id: 'CreateClient.create.taxOffice'})}
            </label>
            <input
              placeholder={intl.formatMessage({id: 'CreateClient.create.taxOffice'})}
              className={clsx('form-control form-control-lg form-control-solid')}
              onChange={(event: any) => setTaxOffice(event.target.value)}
              value={taxOffice}
              disabled={clientType === 2 || clientType === ''}
              type='text'
              name='name'
              autoComplete='off'
            />
          </div>

          <div className='fv-row mb-10 col-md-4'>
            <label className='form-label fs-6 fw-bolder text-dark'>
              {intl.formatMessage({id: 'CreateClient.create.identityNumber'})}
            </label>
            <input
              placeholder={intl.formatMessage({id: 'CreateClient.create.identityNumber'})}
              className={clsx('form-control form-control-lg form-control-solid')}
              onChange={(event: any) => setIdentityNumber(event.target.value)}
              value={identityNumber}
              disabled={clientType === 1 || clientType === ''}
              type='text'
              name='name'
              autoComplete='off'
            />
          </div>
        </div>

        <div className='text-center mt-5 row freightPost-popup-container'>
          <div className={'col-md-6'}>
            <button
              onClick={() => {
                onClose()
                scrollUnlock()
              }}
              type='button'
              id='kt_sign_up_submit'
              className='btn btn-lg btn-danger w-100 mb-5 '
            >
              {intl.formatMessage({id: 'CreateNewContainerPopup.cancel'})}
            </button>
          </div>
          <div className={'col-md-6'}>
            <button
              className={'btn btn-success w-100'}
              onClick={() => {
                setLoading(true)
                handleCreateClient({
                  name: clientType === 1 ? companyName : name,
                  surname: clientType === 1 ? '' : surName,
                  identityNumber: clientType === 1 ? '' : identityNumber,
                  web: web,
                  title: clientType === 2 ? '' : companyTitle,
                  mobile: mobile,
                  country: country,
                  countryCode: phoneCode,
                  clientTypeId: clientType,
                  email: email,
                  taxNumber: clientType === 2 ? '' : taxNumber,
                  taxOffice: clientType === 2 ? '' : taxOffice,
                })
              }}
              disabled={handleCreateDisbaled(clientType)}
            >
              {intl.formatMessage({id: 'Admin.Adverts.createProposal.create'})}
            </button>
          </div>
        </div>
      </div>
    </RightLayoutPopup>
  )
}

export default CreateClient
