import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'

import Modal from '../../../../Components/Modal'
import RightLayoutPopup from '../../../../Components/RightLayoutPopup'
import DataTableAdvanced from '../../../../Components/DataTableAdvanced'
import EditFreightProposal from './EditFreightProposal'

import {ContainerService} from '../../../../../services/AdminServices/ContainerService'

import useScrollLock from '../../../../../utils/useScrollLock'

const ShowFreightProposalTable = ({
  selectedProposalTableList,
  handleUpdateProposal,
  setSelectedEditProposal,
  selectedEditProposal,
  deleteFreigthProposal,
  setLoading,
  onClose,
  currencyList,
  getSelectedFreightProposal,
  freightProposalPageMIndex,
  setFreightProposalPageMIndex,
}: any) => {
  const intl = useIntl()
  const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false)
  const [showEditProposalPopup, setShowEditProposalPopup] = useState<boolean>(false)
  const [selectedProposalId, setSelectedProposalId] = useState<number>(0)
  const [editFreightProposalScopeList, setEditFreightProposalScopeList] = useState<any[]>([])
  const {scrollLock, scrollUnlock} = useScrollLock()

  const containerServ = new ContainerService()

  useEffect(() => {
    scrollLock()
  }, [])

  const getFreightProposalScopeList = () => {
    containerServ
      .getCarrierProposalScope()
      .then((resp: any) => {
        setEditFreightProposalScopeList(resp?.data)
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
        setLoading(false)
      })
  }

  return (
    <RightLayoutPopup
      title={intl.formatMessage({id: 'Admin.Adverts.proposal.title'})}
      className='carrier-container-freight-proposal-table-wrap'
      isCloseIcon
      closeOnClick={() => onClose()}
    >
      {!selectedProposalTableList.length && (
        <div className='alert alert-warning mb-5 fw-bolder' role='alert'>
          {intl.formatMessage({id: 'global.noData'})}
        </div>
      )}
      <DataTableAdvanced
        columns={[
          {
            Header: 'ID',
            accessor: 'id',
          },
          {
            Header: intl.formatMessage({id: 'Carrier.freightPosting.offered.price'}),
            accessor: 'price',
            cellStyle: {
              textAlign: 'end',
              width: '16%',
            },
          },
          {
            Header: intl.formatMessage({id: 'Carrier.freightPosting.currencyCode'}),
            accessor: 'currency',
          },
          {
            Header: intl.formatMessage({id: 'Carrier.freightPosting.companyName'}),
            accessor: 'companyName',
          },
          {
            Header: intl.formatMessage({id: 'Carrier.dashboard.explanation'}),
            accessor: 'explanation',
          },
        ]}
        data={selectedProposalTableList?.map((item: any) => {
          return {
            id: item?.freightProposalId,
            price: item?.price ?? '-',
            currency: item?.currency?.currencyCode ?? '-',
            companyName: item?.company?.name || item?.companyName,
            explanation: item?.explanation ?? '-',
          }
        })}
        editableRows={['']}
        editBtn={(row: any) => {
          setShowEditProposalPopup(true)
          setSelectedProposalId(row?.values?.id)
          getSelectedFreightProposal(row?.values?.id)
          getFreightProposalScopeList()
        }}
        deleteText={intl.formatMessage({id: 'global.table.deleteText'})}
        deleteRow={(row: any) => {
          setShowDeletePopup(true)
          setSelectedProposalId(row?.values?.id)
        }}
        setPageMIndex={setFreightProposalPageMIndex}
        pageMIndex={freightProposalPageMIndex}
      />

      {/*DELETE POPUP*/}
      {showDeletePopup && (
        <Modal
          closeBtnSettings={{
            show: true,
            onClick: () => setShowDeletePopup(false),
          }}
          customAll={
            <div className={''}>
              <h3 className={'mt-5 mb-15'}>
                {intl.formatMessage({id: 'Carrier.drivers.delete.confirmDelete'})}
              </h3>
              <div className={'row'}>
                <div className={'col-md-6'}>
                  <button
                    className={'btn btn-danger w-100'}
                    onClick={() => {
                      setShowDeletePopup(false)
                    }}
                  >
                    {intl.formatMessage({id: 'Carrier.drivers.delete.cancel'})}
                  </button>
                </div>
                <div className={'col-md-6'}>
                  <button
                    className={'btn btn-success w-100'}
                    onClick={() => {
                      setLoading(true)
                      setShowDeletePopup(false)
                      deleteFreigthProposal(selectedProposalId)
                    }}
                  >
                    {intl.formatMessage({id: 'Carrier.drivers.delete.approve'})}
                  </button>
                </div>
              </div>
            </div>
          }
        />
      )}

      {showEditProposalPopup && (
        <EditFreightProposal
          setLoading={setLoading}
          setShowEditProposalPopup={setShowEditProposalPopup}
          selectedProposalId={selectedProposalId}
          currencyList={currencyList}
          handleUpdateProposal={handleUpdateProposal}
          setSelectedEditProposal={setSelectedEditProposal}
          selectedEditProposal={selectedEditProposal}
          proposalScopeList={editFreightProposalScopeList}
        />
      )}

      <div className='text-center mt-5 row view-details-freight-popup'>
        <div className={'col-md-6'}>
          <button
            onClick={() => {
              onClose()
              scrollUnlock()
            }}
            type='button'
            id='kt_sign_up_submit'
            className='btn btn-lg btn-danger w-100 mb-5 '
          >
            {intl.formatMessage({id: 'Carrier.freightPosting.view.close'})}
          </button>
        </div>
      </div>
    </RightLayoutPopup>
  )
}

export default ShowFreightProposalTable
