import {apiUrl, globalPageCount} from "../conf";
import axios from "axios";

export class UserManagementService {
    getUserList(pageIndex?: number) {
        const config: any = {
            method: 'GET',
            url: apiUrl + `/user-management/users?searchText=&pageIndex=${pageIndex}&pageCount=${globalPageCount}`,
            headers: {
                'Content-Type': 'application/json',
            },
        };

        return axios(config);
    }

    getRoles() {
        const config: any = {
            method: 'GET',
            url: apiUrl + '/user-management/roles?searchText=&pageIndex=0&pageCount=1000',
            headers: {
                'Content-Type': 'application/json',
            },
        };

        return axios(config);
    }


    getUser(userId: number) {
        const config: any = {
            method: 'GET',
            url: apiUrl + '/user-management/users/' + userId,
            headers: {
                'Content-Type': 'application/json',
            },
        };

        return axios(config);
    }


    deleteUser(userId: number) {
        const config: any = {
            method: 'DELETE',
            url: apiUrl + '/user-management/users/' + userId,
            headers: {
                'Content-Type': 'application/json',
            },
        };

        return axios(config);
    }

    createUser(name: string, surname: string, username: string, email: string, countryCode: string, mobile: string, initialPassword: string) {

        const data: any = JSON.stringify({
            name: name,
            surname: surname,
            username: username,
            email: email,
            countryCode: countryCode,
            mobile: mobile,
            initialPassword: initialPassword
        });

        const config: any = {
            method: 'POST',
            url: apiUrl + '/user-management/users',
            headers: {
                'Content-Type': 'application/json',
            },
            data: data
        };

        return axios(config);
    }


    updateUser(userId: number, name?: string, surname?: string, email?: string, countryCode?: string, mobile?: string) {

        const data: any = JSON.stringify({
            userId: userId,
            name: name,
            surname: surname,
            email: email,
            countryCode: countryCode,
            mobile: mobile,
        });

        const config: any = {
            method: 'PUT',
            url: apiUrl + '/user-management/users',
            headers: {
                'Content-Type': 'application/json',
            },
            data: data
        };

        return axios(config);
    }

}
