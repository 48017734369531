import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'

import DataTableAdvanced from '../../../../Components/DataTableAdvanced'
import NumberSlider from '../../../../Components/NumberSlider/NumberSlider'
import RightLayoutPopup from '../../../../Components/RightLayoutPopup'
import ShowTransporter from '../../../Carrier/Transporters/Popups/ShowTransporter'

import {KTSVG} from '../../../../../_metronic/helpers'

import {TransporterService} from '../../../../../services/CarrierServices/TransporterService'

import useScrollLock from '../../../../../utils/useScrollLock'

import '../Containers.scss'

const CreateTransport = ({
  setTransportChecks,
  transportChecks,
  numberSliderValue,
  setNumberSliderValue,
  setLoading,
  getTransporterList,
  senNotificationTotransporterAsAdmin,
  selectedContainer,
  transporterList,
  setSelectedTransporter,
  setTransportTooltipShow,
  transportTooltipShow,
  setShowCreateTransportPopup,
  matchContainer,
  selectedTransporter,
  selectedContainerId,
  pageTransportMIndex,
  setSelectedMatchContianer,
  selectedMatchContianer,
  setSearchByDistance,
  searchByDistance,
  filterDetails,
  setFilterDetails,
}: any) => {
  const intl = useIntl()
  const transportServ = new TransporterService()
  const [showTransporterDetailPopup, setShowTransporterDetailPopup] = useState<boolean>(false)
  const [transporterDetail, setShowTransporterDetail] = useState<any>({})
  const [enableNotification, setEnableNotification] = useState<boolean>(false)
  const [showFilterPanel, setShowFilterPanel] = useState<boolean>(false)
  const {scrollLock, scrollUnlock} = useScrollLock()
  useEffect(() => {
    scrollLock()
  }, [])

  const getTransporterDetail = (transporterId: number) => {
    transportServ
      .getTransporterById(transporterId)
      .then((resp: any) => {
        setShowTransporterDetail(resp?.data)
        setLoading(false)
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  return (
    <RightLayoutPopup
      title={intl.formatMessage({id: 'Admin.Containers.matchContainer'})}
      className='admin-create-transport-wrap'
      isCloseIcon
      closeOnClick={() => setShowCreateTransportPopup(false)}
    >
      <div className={'my-5'}>
        <div className={'container'}>
          {!showFilterPanel && (
            <span className={`menu-icon d-flex justify-content-end`}>
              <div
                className={`btn btn-icon btn-active-light-primary btn-custom`}
                data-kt-menu-attach='parent'
                data-kt-menu-placement='bottom-end'
                data-kt-menu-flip='bottom'
                onClick={() => setShowFilterPanel(!showFilterPanel)}
              >
                <KTSVG path='/media/filtering.svg' className='svg-icon-2x' />
              </div>
            </span>
          )}
          {showFilterPanel && (
            <div className={'row'}>
              <div className='mb-10 col-md-2 filter-match-search-wrap'>
                <h6 className={'mb-3'}>
                  {intl.formatMessage({id: 'Admin.Adverts.table_company'})}
                </h6>
                <div className={''}>
                  <input
                    placeholder={intl.formatMessage({
                      id: 'Admin.Adverts.table_company',
                    })}
                    className={'form-control form-control-lg'}
                    onChange={(event: any) => {
                      setFilterDetails((item: any) => ({
                        ...item,
                        company: event.target.value,
                      }))
                    }}
                    value={filterDetails?.company}
                    type='text'
                  />
                </div>
              </div>
              <div className='mb-10 col-md-2 filter-match-search-wrap'>
                <h6 className={'mb-3'}>
                  {intl.formatMessage({id: 'Carrier.container.transporter.filter.name'})}
                </h6>
                <div className={''}>
                  <input
                    placeholder={intl.formatMessage({
                      id: 'Carrier.container.transporter.filter.name',
                    })}
                    className={'form-control form-control-lg'}
                    onChange={(event: any) => {
                      setFilterDetails((item: any) => ({
                        ...item,
                        name: event.target.value,
                      }))
                    }}
                    value={filterDetails?.name}
                    type='text'
                  />
                </div>
              </div>
              <div className='mb-10 col-md-2 filter-match-search-wrap'>
                <h6 className={'mb-3'}>
                  {intl.formatMessage({id: 'Carrier.container.transporter.filter.surName'})}
                </h6>
                <div className={''}>
                  <input
                    placeholder={intl.formatMessage({
                      id: 'Carrier.container.transporter.filter.surName',
                    })}
                    className={'form-control form-control-lg'}
                    onChange={(event: any) => {
                      setFilterDetails((item: any) => ({
                        ...item,
                        surName: event.target.value,
                      }))
                    }}
                    value={filterDetails?.surName}
                    type='text'
                  />
                </div>
              </div>
              <div className='mb-10 col-md-2 filter-match-search-wrap'>
                <h6 className={'mb-3'}>
                  {intl.formatMessage({id: 'Carrier.container.transporter.filter.vehiclePlate'})}
                </h6>
                <div className={''}>
                  <input
                    placeholder={intl.formatMessage({
                      id: 'Carrier.container.transporter.filter.vehiclePlate',
                    })}
                    className={'form-control form-control-lg'}
                    onChange={(event: any) => {
                      setFilterDetails((item: any) => ({
                        ...item,
                        vehiclePlate: event.target.value,
                      }))
                    }}
                    value={filterDetails?.vehiclePlate}
                    type='text'
                  />
                </div>
              </div>
              <div className='mb-10 col-md-2 filter-match-search-wrap'>
                <h6 className={'mb-3'}>
                  {intl.formatMessage({id: 'Carrier.container.transporter.filter.trailerPlate'})}
                </h6>
                <div className={''}>
                  <input
                    placeholder={intl.formatMessage({
                      id: 'Carrier.container.transporter.filter.trailerPlate',
                    })}
                    className={'form-control form-control-lg'}
                    onChange={(event: any) => {
                      setFilterDetails((item: any) => ({
                        ...item,
                        trailerPlate: event.target.value,
                      }))
                    }}
                    value={filterDetails?.trailerPlate}
                    type='text'
                  />
                </div>
              </div>
              <div className={'col-md-4'}>
                <div className='form-check pt-5 mb-3'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    onChange={() => {
                      setTransportChecks((item: any) => ({
                        ...item,
                        preferredRoute: !transportChecks.preferredRoute,
                      }))
                    }}
                    checked={transportChecks.preferredRoute}
                    id='preferredRoute'
                  />
                  <label className='form-check-label' htmlFor='preferredRoute'>
                    {intl.formatMessage({id: 'Admin.Containers.preferredRoute'})}
                  </label>
                </div>
                <div className='form-check pt-5 mb-3'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    onChange={() => {
                      setTransportChecks((item: any) => ({
                        ...item,
                        conformingToSpecification: !transportChecks.conformingToSpecification,
                      }))
                    }}
                    checked={transportChecks.conformingToSpecification}
                    id='conformingToSpecification'
                  />
                  <label className='form-check-label' htmlFor='conformingToSpecification'>
                    {intl.formatMessage({id: 'Admin.Containers.suitableForContainer'})}
                  </label>
                </div>
              </div>
              <div className={'col-md-7'} style={{paddingLeft: 0, paddingRight: 0}}>
                <div className={' d-flex align-items-center col-md-12'}>
                  <div className='form-check pt-5 mb-3' style={{marginRight: '20px'}}>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      onChange={() => {
                        setSearchByDistance(!searchByDistance)
                      }}
                      checked={searchByDistance}
                      id='appliedToFreight'
                    />
                    <label className='form-check-label' htmlFor='appliedToFreight'>
                      {intl.formatMessage({id: 'Admin.Containers.searchByDistance'})}
                    </label>
                  </div>
                  <div
                    className={'position-relative pt-6 mb-3'}
                    style={{minWidth: '150px', width: '60%'}}
                  >
                    <span className={'position-absolute top-0 start-0'}>0</span>
                    <span className={'position-absolute top-0 start-50 fw-bold'}>
                      {numberSliderValue}
                    </span>
                    <span className={'position-absolute top-0 end-0'}>1000</span>
                    <NumberSlider
                      min={0}
                      disabled={!searchByDistance}
                      max={1000}
                      defaultValue={100}
                      value={(val: number) => {
                        setNumberSliderValue(val)
                      }}
                    />
                  </div>
                </div>
                <div className={'d-flex col-md-12'}>
                  <div className='form-check pt-5 mb-3' style={{marginRight: '20px'}}>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      onChange={() => {
                        setTransportChecks((item: any) => ({
                          ...item,
                          appliedToFreight: !transportChecks.appliedToFreight,
                        }))
                      }}
                      checked={transportChecks.appliedToFreight}
                      id='appliedToFreight'
                    />
                    <label className='form-check-label' htmlFor='appliedToFreight'>
                      {intl.formatMessage({id: 'Admin.Containers.applicantsForFreight'})}
                    </label>
                  </div>
                  <div style={{marginRight: '5px'}}>
                    <button
                      style={{padding: '13px 13px'}}
                      className={'btn btn-success w-100'}
                      onClick={() => {
                        setLoading(true)
                        getTransporterList(selectedContainerId)
                        setEnableNotification(true)
                      }}
                    >
                      {intl.formatMessage({id: 'Admin.Containers.applyFilter'})}
                    </button>
                  </div>
                  {/* <button
                    style={{width: '34%', padding: '0px'}}
                    className={'notification-btn-wrap btn btn-success'}
                    onClick={() => {
                      setLoading(true)
                      senNotificationTotransporterAsAdmin(selectedContainerId)
                    }}
                    disabled={!enableNotification}
                  >
                    {intl.formatMessage({id: 'Admin.Containers.sendNotification'})}
                  </button> */}
                </div>
              </div>
              <div className='col-md-1'>
                <span
                  className={`${
                    showFilterPanel ? 'mb-5' : ''
                  } menu-icon d-flex justify-content-end`}
                >
                  <div
                    className={`btn btn-icon btn-active-light-primary btn-custom ${
                      showFilterPanel ? 'menu-button-active' : ''
                    }`}
                    data-kt-menu-attach='parent'
                    data-kt-menu-placement='bottom-end'
                    data-kt-menu-flip='bottom'
                    onClick={() => setShowFilterPanel(!showFilterPanel)}
                  >
                    <KTSVG path='/media/filtering.svg' className='svg-icon-2x' />
                  </div>
                </span>
              </div>
            </div>
          )}
        </div>
        <div className=' mb-10 transporter-select-area transporter-table-height'>
          {!transporterList.length && <p>{intl.formatMessage({id: 'global.noData'})}</p>}
          <DataTableAdvanced
            columns={[
              {
                Header: 'ID',
                accessor: 'id',
              },
              {
                Header: intl.formatMessage({id: 'Admin.Containers.companyName'}),
                accessor: 'companyName',
              },

              {
                Header: intl.formatMessage({id: 'Admin.Containers.driverName'}),
                accessor: 'driverName',
              },
              {
                Header: intl.formatMessage({id: 'Admin.Containers.phoneNumber'}),
                accessor: 'phone',
              },
              {
                Header: intl.formatMessage({id: 'Carrier.transporters.vehiclePlate'}),
                accessor: 'vehiclePlate',
              },
              {
                Header: intl.formatMessage({id: 'Carrier.transporters.trailerPlate'}),
                accessor: 'trailerPlate',
              },
              {
                Header: intl.formatMessage({id: 'Admin.Containers.plate'}),
                accessor: 'plate',
                hidden: true,
              },
              {
                Header: intl.formatMessage({id: 'Admin.Containers.floorType'}),
                accessor: 'floorTpe',
                hidden: true,
              },
              {
                Header: intl.formatMessage({id: 'Admin.Containers.trailerType'}),
                accessor: 'trailerType',
                hidden: true,
              },
              {
                Header: intl.formatMessage({id: 'Admin.Containers.specificationList'}),
                accessor: 'specificationList',
                hidden: true,
              },
            ]}
            editableRows={['']}
            data={
              transporterList &&
              transporterList.map((item: any) => {
                return {
                  id: item?.transporterId,
                  driverName: `${item?.driver?.name} ${item?.driver?.surname}`,
                  companyName: item?.company?.name,
                  phone: `${item?.driver?.countryCode} ${item?.driver?.mobile}`,
                  plate: item?.trailer?.plate,
                  vehiclePlate: item?.vehicle?.plate,
                  trailerPlate: item?.trailer?.plate,
                  trailerType: item?.trailer?.trailerType,
                  floorType: item?.trailer?.floorType,
                  specificationList: item?.trailer?.specificationList?.map(
                    (item: any) => item?.description + ', '
                  ),
                }
              })
            }
            selectedRowData={selectedMatchContianer}
            onRowClick={(val: any) => {
              setSelectedTransporter(val?.values)
              setSelectedMatchContianer(val?.id)
              // setTransportTooltipShow(!transportTooltipShow)
            }}
            showDetailBtn={(row: any) => {
              setLoading(true)
              setShowTransporterDetailPopup(true)
              getTransporterDetail(row?.values?.id)
            }}
            // setPageMIndex={onPaginationChange}
            pageMIndex={pageTransportMIndex}
          />

          {showTransporterDetailPopup && (
            <ShowTransporter
              selectedTransporter={transporterDetail}
              onClose={() => setShowTransporterDetailPopup(false)}
            />
          )}
        </div>
        <div className={'row'}>
          <div className={'col-md-6 mb-5'}>
            <button
              className={'btn btn-danger w-100'}
              onClick={() => {
                setShowCreateTransportPopup(false)
                setTransportTooltipShow(false)
                setSelectedMatchContianer(null)
                scrollUnlock()
              }}
            >
              {intl.formatMessage({id: 'global.cancel'})}
            </button>
          </div>
          <div className={'col-md-6 mb-5'}>
            <button
              onClick={() => {
                setLoading(true)
                matchContainer(parseInt(selectedTransporter.id), selectedContainerId)
                setShowCreateTransportPopup(false)
                scrollUnlock()
              }}
              disabled={!selectedTransporter}
              className={'btn btn-success w-100'}
            >
              {intl.formatMessage({id: 'CreateMatchContainerPopup.AssignContainer'})}
            </button>
          </div>
        </div>
      </div>
    </RightLayoutPopup>
  )
}

export default CreateTransport
