import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'

import {PageTitle} from '../../../../_metronic/layout/core'
import {KTSVG} from '../../../../_metronic/helpers'
import {getDate} from '../../../../utils/common'

import CreateDocument from './popups/CreateDocument'
import EditDocument from './popups/EditDocument'
import DocumentDetail from './popups/DocumentDetail'
import UploadDocument from './popups/UploadDocument'
import Loading from '../../../Components/Loading'
import DataTableAdvanced from '../../../Components/DataTableAdvanced'
import Modal from '../../../Components/Modal'

import {DocumentService} from '../../../../services/CarrierServices/DocumentService'

import './Document.scss'

const Document = () => {
  const intl = useIntl()

  const [pageIndex, setPageIndex] = useState<number>(0)

  const [loading, setLoading] = useState<boolean>(false)
  const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false)
  const [createDocumentPopup, showCreateDocumentPopup] = useState<boolean>(false)
  const [editDocumentPopup, showEditDocumentPopup] = useState<boolean>(false)
  const [showDocumentDetailPopup, setShowDocumentDetailPopup] = useState<boolean>(false)
  const [showDocumentFilePopup, setShowDocumentFilePopup] = useState<boolean>(false)

  const [documentList, setDocumentList] = useState<any>([])
  const [selectedDocumentId, setSelectedDocumentId] = useState<any>(null)
  const [documentTypeList, setDocumentTypeList] = useState<any>([])
  const [selectedDocumentDetails, setSelectedDocumentDetails] = useState<any>({})

  const [isNextPage, setIsNextPage] = useState<boolean>(false)

  const documentServ = new DocumentService()

  const getDocumentList = (pageIndex: number) => {
    documentServ
      .getDocumentList({pageIndex: pageIndex})
      .then((res: any) => {
        setLoading(false)
        setIsNextPage(false)
        setDocumentList(res?.data)
      })
      .catch((e: any) => {
        setLoading(false)
        setIsNextPage(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const handleCreateDocument = (data: any) => {
    documentServ
      .createDocument(data)
      .then((res: any) => {
        setLoading(false)
        getDocumentList(pageIndex)
        toast.success(intl.formatMessage({id: 'CreateDocument.create.success'}))
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }
  const handleDeleteDocument = () => {
    documentServ
      .deleteDocument(selectedDocumentId)
      .then((res: any) => {
        setLoading(false)
        getDocumentList(pageIndex)
        toast.success(intl.formatMessage({id: 'CreateDocument.delete.success'}))
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const handleUpdateDocument = (data: any) => {
    documentServ
      .updateDocument({...data, documentId: selectedDocumentId})
      .then((res: any) => {
        setLoading(false)
        getDocumentList(pageIndex)
        setSelectedDocumentDetails({})
        toast.success(intl.formatMessage({id: 'CreateDocument.update.success'}))
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const handleUploadFile = (file: any) => {
    documentServ
      .uploadDocument(selectedDocumentId, file)
      .then((res: any) => {
        setLoading(false)
        getDocumentList(pageIndex)
        toast.success(intl.formatMessage({id: 'CreateDocument.file.upload.success'}))
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const getDocumentTypeList = () => {
    documentServ
      .getDocumentTypeList({})
      .then((res: any) => {
        setLoading(false)
        setDocumentTypeList(res.data)
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const handleGetDocumentDetails = (documentId?: number) => {
    documentServ
      .getDocumentDetailById(documentId)
      .then((res: any) => {
        setLoading(false)
        setSelectedDocumentDetails(res.data)
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  useEffect(() => {
    setLoading(true)
    getDocumentList(pageIndex)
    getDocumentTypeList()
  }, [])

  const handlePagination = (page: number) => {
    if (!isNextPage) {
      setPageIndex(page)
      getDocumentList(page)
    }
    setIsNextPage(true)
  }

  return (
    <>
      {loading && <Loading />}
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({id: 'SideMenu.carrierOperator.documents'})}
      </PageTitle>

      <div className={'d-flex align-items-center justify-content-end'}>
        <button
          onClick={() => showCreateDocumentPopup(true)}
          className='btn btn-sm btn-light-primary mb-5 border border-2 fs-4'
        >
          <KTSVG path='media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
          {intl.formatMessage({id: 'CreateDocument.createDocument.create'})}
        </button>
      </div>

      {!documentList.length && (
        <div className='alert alert-warning mb-5 fw-bolder' role='alert'>
          {intl.formatMessage({id: 'Carrier.transporters.noData'})}
        </div>
      )}

      <DataTableAdvanced
        columns={[
          {
            Header: 'ID',
            accessor: 'id',
          },
          {
            Header: intl.formatMessage({id: 'Carrier.freightPosting.description'}),
            accessor: 'description',
          },
          {
            Header: intl.formatMessage({id: 'CreateDocument.dataTable.docType'}),
            accessor: 'documentType',
          },
          {
            Header: intl.formatMessage({id: 'CreateDocument.dataTable.docNumber'}),
            accessor: 'documentNumber',
          },
          {
            Header: intl.formatMessage({id: 'CreateDocument.dataTable.issueDate'}),
            accessor: 'issueDate',
          },
          {
            Header: intl.formatMessage({id: 'CreateDocument.dataTable.expireDate'}),
            accessor: 'expireDate',
          },
          {
            Header: '',
            accessor: 'fileURL',
            isAction: true,
            Cell: ({row, rows, toggleRowExpanded}: any) =>
              !row.canExpand &&
              row?.values?.fileURL.split('/files')[1] &&
              !(
                row?.values?.fileURL.split('/files')[1].includes('null') ||
                row?.values?.fileURL.split('/files')[1].includes('undefined')
              ) ? (
                <div className='sub-table-column-wrap'>
                  <button
                    title={intl.formatMessage({id: 'Carrier.drivers.details.download'})}
                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 mb-1'
                    onClick={() => {
                      document.location.href = `${row?.values?.fileURL}?download=Y`
                      return false
                    }}
                  >
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr091.svg'
                      className='svg-icon-3 mr-2 pr-2'
                    />
                  </button>
                </div>
              ) : null,
          },
        ]}
        data={
          documentList &&
          documentList?.map((item: any) => {
            return {
              id: item?.documentId,
              description: item?.description,
              documentType: item?.documentType?.description,
              documentNumber: item?.documentNumber,
              issueDate: !!item?.issueDate && getDate(item?.issueDate),
              expireDate: !!item?.expireDate && getDate(item?.expireDate),
              fileURL: item?.fileUrl,
            }
          })
        }
        showDetailBtn={(row: any) => {
          setLoading(true)
          setShowDocumentDetailPopup(true)
          setSelectedDocumentId(Number(row.values.id))
          handleGetDocumentDetails(Number(row.values.id))
        }}
        showFile={(row: any) => {
          setSelectedDocumentId(Number(row.values.id))
          setShowDocumentFilePopup(true)
        }}
        showFileText={intl.formatMessage({id: 'CreateDocument.dataTable.action.uploadFile'})}
        editBtn={(row: any) => {
          setLoading(true)
          showEditDocumentPopup(true)
          setSelectedDocumentId(Number(row.values.id))
          handleGetDocumentDetails(Number(row.values.id))
        }}
        deleteRow={(row: any) => {
          setSelectedDocumentId(Number(row.values.id))
          setShowDeletePopup(true)
        }}
        deleteText={intl.formatMessage({id: 'CreateDocument.delete.row.title'})}
        editableRows={['']}
        setPageMIndex={handlePagination}
        pageMIndex={pageIndex}
      />

      {showDocumentDetailPopup && (
        <DocumentDetail
          selectedDocumentDetails={selectedDocumentDetails}
          onClose={() => setShowDocumentDetailPopup(false)}
        />
      )}
      {createDocumentPopup && (
        <CreateDocument
          documentTypeList={documentTypeList}
          setLoading={setLoading}
          onClose={() => showCreateDocumentPopup(false)}
          handleCreateDocument={(data: any) => {
            handleCreateDocument(data)
            showCreateDocumentPopup(false)
          }}
        />
      )}

      {showDocumentFilePopup && (
        <UploadDocument
          onClose={() => setShowDocumentFilePopup(false)}
          handleUploadDocument={(file: any) => {
            setShowDocumentFilePopup(false)
            handleUploadFile(file)
          }}
          setLoading={setLoading}
        />
      )}

      {editDocumentPopup && Object.keys(selectedDocumentDetails).length > 0 && (
        <EditDocument
          documentTypeList={documentTypeList}
          selectedDocumentDetails={selectedDocumentDetails}
          setLoading={setLoading}
          onClose={() => {
            setSelectedDocumentDetails({})
            showEditDocumentPopup(false)
          }}
          handleUpdateDocument={(data: any) => {
            handleUpdateDocument(data)
            showEditDocumentPopup(false)
          }}
        />
      )}
      {/*DELETE POPUP*/}
      {showDeletePopup && (
        <Modal
          closeBtnSettings={{
            show: true,
            onClick: () => setShowDeletePopup(false),
          }}
          customAll={
            <div className={''}>
              <h3 className={'mt-5 mb-15'}>
                {intl.formatMessage({id: 'CreateDocument.delete.modal.title'})}
              </h3>
              <div className={'row'}>
                <div className={'col-md-6'}>
                  <button
                    className={'btn btn-danger w-100'}
                    onClick={() => {
                      setShowDeletePopup(false)
                    }}
                  >
                    {intl.formatMessage({id: 'Carrier.freightPosting.delete.cancel'})}
                  </button>
                </div>
                <div className={'col-md-6'}>
                  <button
                    className={'btn btn-success w-100'}
                    onClick={() => {
                      setLoading(true)
                      setShowDeletePopup(false)
                      handleDeleteDocument()
                    }}
                  >
                    {intl.formatMessage({id: 'Carrier.freightPosting.delete.approve'})}
                  </button>
                </div>
              </div>
            </div>
          }
        />
      )}
    </>
  )
}

export {Document}
