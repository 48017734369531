import axios from 'axios'
import {apiUrl, globalPageCount} from '../conf'

export class CommonService {
  getNotifications() {
    const config: any = {
      method: 'GET',
      url: apiUrl + '/notification-management/notifications',
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  setNotificationRead(notifiId: number) {
    const config: any = {
      method: 'PATCH',
      url: apiUrl + '/notification-management/notifications/' + notifiId + '/read',
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  countryList() {
    const config: any = {
      method: 'GET',
      url: apiUrl + '/address-management/countries?searchText=&pageIndex=0&pageCount=1000',
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  countryPublicList() {
    const config: any = {
      method: 'GET',
      url: apiUrl + '/address-management/public/countries?searchText=&pageIndex=0&pageCount=1000',
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  countrySearchList(searchText?: any, pageIndex?: number) {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        '/address-management/countries?searchText=' +
        (searchText && searchText !== 'all' ? searchText : '') +
        ('&pageIndex=' + pageIndex ?? 0) +
        ('&pageCount=' + globalPageCount),
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  countryAllSearchList(searchText?: any, pageIndex?: number) {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        '/address-management/countries?searchText=' +
        (searchText && searchText !== 'all' ? searchText : '') +
        ('&pageIndex=' + pageIndex ?? 0) +
        '&pageCount=1000',
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  publicCountrySearchList(searchText?: any, pageIndex?: number) {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        '/address-management/public/countries?searchText=' +
        (searchText && searchText !== 'all' ? searchText : '') +
        ('&pageIndex=' + pageIndex ?? 0) +
        ('&pageCount=' + globalPageCount),
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  citySearchList(countryCode: string, searchText?: any, pageIndex?: number) {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        '/address-management/countries/' +
        countryCode +
        '/cities?searchText=' +
        (searchText && searchText !== 'all' ? searchText : '') +
        ('&pageIndex=' + pageIndex ?? 0) +
        ('&pageCount=' + globalPageCount),
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  cityAllSearchList(countryCode: string, searchText?: any, pageIndex?: number) {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        '/address-management/countries/' +
        countryCode +
        '/cities?searchText=' +
        (searchText && searchText !== 'all' ? searchText : '') +
        ('&pageIndex=' + pageIndex ?? 0) +
        '&pageCount=1000',
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  publicCitySearchList(countryCode: string, searchText?: any, pageIndex?: number) {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        '/address-management/public/countries/' +
        countryCode +
        '/cities?searchText=' +
        (searchText && searchText !== 'all' ? searchText : '') +
        ('&pageIndex=' + pageIndex ?? 0) +
        ('&pageCount=' + globalPageCount),
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  transportTypeList(searchText?: any, pageIndex?: number) {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        '/transport-management/transports/types?searchText=' +
        (searchText && searchText !== 'all' ? searchText : '') +
        ('&pageIndex=' + pageIndex ?? 0) +
        ('&pageCount=' + globalPageCount),
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  publicTransportTypeList(searchText?: any, pageIndex?: number) {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        '/transport-management/public/transports/types?searchText=' +
        (searchText && searchText !== 'all' ? searchText : '') +
        ('&pageIndex=' + pageIndex ?? 0) +
        ('&pageCount=' + globalPageCount),
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  freightContentTypeList(searchText?: any, pageIndex?: number) {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        '/freight-management/freights/contents/types?searchText=' +
        (searchText && searchText !== 'all' ? searchText : '') +
        ('&pageIndex=' + pageIndex ?? 0) +
        ('&pageCount=' + globalPageCount),
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  allFreightContentTypeList(searchText?: any, pageIndex?: number) {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        '/freight-management/freights/contents/types?searchText=' +
        (searchText && searchText !== 'all' ? searchText : '') +
        ('&pageIndex=' + pageIndex ?? 0) +
        '&pageCount=1000',
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  carrierCountryList() {
    const config: any = {
      method: 'GET',
      url: apiUrl + '/address-management/countries?searchText=&pageIndex=0&pageCount=1000',
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  cityList(countryCode: string) {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        '/address-management/countries/' +
        countryCode +
        '/cities?searchText=&pageIndex=0&pageCount=5000',
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  cityPublicList(countryCode: string) {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        '/address-management/public/countries/' +
        countryCode +
        '/cities?searchText=&pageIndex=0&pageCount=5000',
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  carrierCityList(countryCode: string) {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        '/address-management/countries/' +
        countryCode +
        '/cities?searchText=&pageIndex=0&pageCount=5000',
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  publicCountryList() {
    const config: any = {
      method: 'GET',
      url: apiUrl + '/country-management/countries',
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  languageList() {
    const config: any = {
      method: 'GET',
      url: apiUrl + '/language-management/languages?searchText=&pageIndex=0&pageCount=100',
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  getCompanyTypes() {
    const config: any = {
      method: 'GET',
      url: apiUrl + '/company-management/companies/types',
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }
}
