import React, {useState} from 'react'
import {useIntl} from 'react-intl'
import Multiselect from 'multiselect-react-dropdown'
import {format} from 'date-fns'

import RightLayoutPopup from '../../../../Components/RightLayoutPopup'
import DatePicker from '../../../../Components/DatePicker'

const PubllishContainer = ({
  onClose,
  publicFreightExchange,
  setPublicFreightExchange,
  setPrivateFreightExchange,
  privateFreightExchange,
  publishNonFormatDate,
  setPublishDate,
  setPublishNonFormatDate,
  publishDate,
  setSelectedTransporterGroup,
  selectedTransporterGroup,
  transporterGroupUserList,
  selectedContainerId,
  publishContainer,
}: any) => {
  const intl = useIntl()
  const [disabledGroup, setDisabledGroup] = useState<boolean>(true)

  return (
    <RightLayoutPopup
      title={intl.formatMessage({id: 'Admin.container.publish.title'})}
      className='right-layout-show-wrap'
      isCloseIcon
      closeOnClick={() => onClose()}
    >
      <div className='row'>
        <div className={'col-md-6 d-flex align-items-end mb-5'}>
          <div className='form-check d-flex align-items-center'>
            <div>
              <input
                type='checkbox'
                id={'adr1'}
                onChange={() => setPublicFreightExchange(!publicFreightExchange)}
                checked={publicFreightExchange}
                className={'form-check-input'}
              />
            </div>
            <label htmlFor='adr1' className={'text-muted fs-4 ml-3 form-check-label'}>
              {intl.formatMessage({id: 'Carrier.advert.publishToPublishFreight'})}
            </label>
          </div>
        </div>
        <div className={'col-md-6 d-flex align-items-end mb-5'}>
          <div className='form-check d-flex align-items-center'>
            <div>
              <input
                type='checkbox'
                id={'adr1'}
                onChange={() => {
                  setPrivateFreightExchange(!privateFreightExchange)
                  setDisabledGroup(!disabledGroup)
                }}
                checked={privateFreightExchange}
                className={'form-check-input'}
              />
            </div>
            <label htmlFor='adr1' className={'text-muted fs-4 ml-3 form-check-label'}>
              {intl.formatMessage({id: 'Carrier.advert.publishToPrivateFreight'})}
            </label>
          </div>
        </div>

        <div className='col-md-6'>
          <label htmlFor='publishDate' className={'text-muted fs-4 mb-2'}>
            {intl.formatMessage({id: 'Admin.Containers.publishingDate'})}
          </label>
          <DatePicker
            value={publishNonFormatDate}
            onSelect={(data: any) => {
              setPublishDate(format(data, 'yyyy-MM-dd'))
              setPublishNonFormatDate(data)
            }}
            // className={'freight-publish-date-wrap'}
            selected={!!publishDate}
            customDate={publishNonFormatDate}
            disabledDays={new Date()}
            captionLayout={'buttons'}
            menuDefaultOpen
          />
        </div>
        <div className='col-md-6'>
          <label htmlFor='publishDate' className={'text-muted fs-4 mb-2'}>
            {intl.formatMessage({id: 'Carrier.transporterGroup.publish.group'})}
          </label>
          <Multiselect
            options={transporterGroupUserList}
            selectedValues={selectedTransporterGroup}
            onSelect={(value: any) => setSelectedTransporterGroup(value)}
            onRemove={(value: any) => setSelectedTransporterGroup(value)}
            displayValue='name'
            disable={disabledGroup}
            style={{
              inputField: {
                minHeight: '30px',
                height: 'auto',
              },
            }}
          />
        </div>
      </div>

      <div className='text-center mt-5 row freightPost-popup-container'>
        <div className={'col-md-6'}>
          <button
            onClick={onClose}
            type='button'
            id='kt_sign_up_submit'
            className='btn btn-lg btn-danger w-100 mb-5 '
          >
            {intl.formatMessage({id: 'CreateNewContainerPopup.cancel'})}
          </button>
        </div>
        <div className={'col-md-6'}>
          <button
            className={'btn btn-success px-10 m-auto w-100'}
            disabled={!publishDate}
            onClick={() => {
              publishContainer({
                issueStartDate: !!publishDate
                  ? new Date(publishDate + ' 00:00:00').getTime().toString()
                  : null,
                container: {
                  containerId: selectedContainerId,
                },
                publishToPublicFreightExchange: publicFreightExchange ? 'Y' : 'N',
                publishToPrivateFreightExchange: privateFreightExchange ? 'Y' : 'N',
                transporterGroupList: selectedTransporterGroup.map((item: any) => ({
                  transporterGroupId: item.id,
                })),
              })
            }}
          >
            {intl.formatMessage({id: 'Admin.Containers.publish'})}
          </button>
        </div>
      </div>
    </RightLayoutPopup>
  )
}

export default PubllishContainer
