import React, {useState} from 'react';
import {ISelectBox} from "./types";
import styles from './SelectBox.module.scss'

const SelectBox = ({data, onChange, value, placeholder, defaultSelectedItem, disabled, className}: ISelectBox) => {
    const [stat, setStat] = useState<boolean>(false);

    const handleChange = (e: any) => {
        onChange(e);
        setStat(!stat);
    }

    return <div className={styles.selectMain}>
        <select
            className={'form-select ' + (className ?? '')}
            onChange={handleChange}
            value={defaultSelectedItem ? data?.find((item: any) => item?.key === defaultSelectedItem?.key) : value}
            disabled={disabled}
        >
            <option value="" disabled>{placeholder}</option>
            {data?.map((item: any) => <option
                key={item.key}
                value={item.key}>
                {item?.title}
            </option>)}
        </select>
        {/*<img onClick={handleClick}
             className={(!arr ? styles.arrDown : styles.arrUp) + ' ' + (disabled && styles.disabled)}
             src="/media/custom/arrow-down.png"
             alt="arrow"
        />*/}
    </div>;
};

export default SelectBox;
