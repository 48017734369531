import React, {Suspense, useEffect, useState} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {BrowserRouter} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {RootState} from '../setup'
import AuthInit from './pages/auth/redux/AuthInit'
import {Routes} from './routing/Routes'
import 'bootstrap'
import {ToastContainer} from 'react-toastify'
import './global.scss'
import 'react-toastify/dist/ReactToastify.css'

type Props = {
  basename: string
}

const useScript = (url: any, widgetCode: any) => {
  const isAuthorized: any = useSelector<RootState>(({auth}) => auth.user, shallowEqual)

  useEffect(() => {
    const script = document.createElement('script')
    script.setAttribute('type', 'text/javascript')

    let code =
      isAuthorized && Object.keys(isAuthorized).length > 0
        ? `var $zoho=$zoho || {};$zoho.salesiq = $zoho.salesiq || {widgetcode: "${widgetCode}", values:{},ready:function(){
        $zoho.salesiq.visitor.name('${
          isAuthorized?.name ? isAuthorized?.name + ' ' + isAuthorized?.surname : ''
        }');
        $zoho.salesiq.visitor.contactnumber('${
          isAuthorized?.mobile ? isAuthorized?.countryCode + isAuthorized?.mobile : ''
        }');
        $zoho.salesiq.visitor.uniqueuserid('${isAuthorized?.userId || ''}');
        $zoho.salesiq.visitor.email('${isAuthorized?.email || ''}');
    }};var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;s.src="${url}";t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t);d.innerHTML = "<div id='zsiqwidget'></div>";`
        : `var $zoho=$zoho || {};$zoho.salesiq = $zoho.salesiq || {widgetcode: "${widgetCode}", values:{},ready:function(){}};var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;s.src="${url}";t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t);d.innerHTML = "<div id='zsiqwidget'></div>";`

    script.appendChild(document.createTextNode(code))
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [url])
}

const App: React.FC<Props> = ({basename}) => {

  useEffect(() => {
    // Redirect logic
    if (process.env.NODE_ENV === 'production') {
      if (!window.location.host.startsWith('www')) {
        window.location.replace(
          `${window.location.protocol}//www.${window.location.host}${window.location.pathname}${window.location.search}`
        )
      }
    }
  }, [])


  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <ToastContainer closeButton={true} position='top-right' />
      <ToastContainer />
      <BrowserRouter basename={basename}>
        <I18nProvider>
          <LayoutProvider>
            <AuthInit>
              {useScript(
                'https://salesiq.zohopublic.eu/widget',
                'siq54ce853db113e5b643b022976d5c61931caad283a316ef4e9867c8327b294a9d'
              )}
              <Routes />
            </AuthInit>
          </LayoutProvider>
        </I18nProvider>
      </BrowserRouter>
    </Suspense>
  )
}

export {App}
