import {apiUrl, globalPageCount} from "../conf";
import axios from "axios";

export class AdvertService {

    getStatusTypes() {
        const config: any = {
            method: 'GET',
            url: apiUrl + '/freight-management/freights/statuses?searchText=&pageIndex=0&pageCount=2000',
            headers: {
                'Content-Type': 'application/json',
            },
        };

        return axios(config);
    }

    getCarrierStatusTypes() {
        const config: any = {
            method: 'GET',
            url: apiUrl + '/freight-management/freights/statuses?searchText=&pageIndex=0&pageCount=2000',
            headers: {
                'Content-Type': 'application/json',
            },
        };

        return axios(config);
    }

    approveAdvert(advertId: number) {
        const config: any = {
            method: 'PATCH',
            url: apiUrl + '/freight-management/freights/' + advertId + '/approve',
            headers: {
                'Content-Type': 'application/json',
            },
        };

        return axios(config);
    }

    rejectAdvert(advertId: number, desc: string) {
        const data: any = JSON.stringify({
            freightId: advertId,
            rejectReason: desc,
        });
        const config: any = {
            method: 'PATCH',
            url: apiUrl + '/freight-management/freights/reject',
            headers: {
                'Content-Type': 'application/json',
            },
            data: data
        };

        return axios(config);
    }

    /** Owner Companies List Start **/
    getAdvertOwnerCompanies(searchText?: any, pageIndex?: number, pageCount?: number) {
        const config: any = {
            method: 'GET',
            url: apiUrl + '/company-management/companies/owners?searchText=' + ((searchText && searchText !== 'all') ? searchText : '') + ('&pageIndex=' + pageIndex ?? 0) + ('&pageCount=' + globalPageCount),
            headers: {
                'Content-Type': 'application/json',
            },
        };
        return axios(config);
    }

    /** Owner Companies List End **/

    /** Owner Carrier List Start **/
    getAdvertCarrierCompanies(pageIndex?: number, searchText?: string) {
        const config: any = {
            method: 'GET',
            url: apiUrl + '/company-management/companies/carriers?searchText=' + ((searchText && searchText !== 'all') ? searchText : '') + ('&pageIndex=' + pageIndex ?? 0) + ('&pageCount=' + globalPageCount),
            headers: {
                'Content-Type': 'application/json',
            },
        };
        return axios(config);
    }

    /** Owner Carrier List End **/
}
