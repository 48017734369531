const scrollLock = () => {
    const scrollBarCompensation = window.innerWidth - document.body.offsetWidth;
    document.body.style.overflow = 'hidden';
    document.body.style.paddingRight = `${scrollBarCompensation}px`;
}

export const scrollUnlock = () => {
    document.body.style.overflow = 'auto';
    document.body.style.paddingRight = `0px`;
}

const useScrollLock = () => ({scrollLock, scrollUnlock});

export default useScrollLock;
