import React, { lazy } from 'react'
import {useIntl} from 'react-intl'
// import StatCard from '../../../../Components/StatCard/StatCard'

const StatCard = lazy(() => import('../../../../Components/StatCard/StatCard'))

export const CardComponent = ({
  dashboardDetails,
  handleExternalFilterOpen,
  handleExternalFilterData,
  handleExternalCountryName,
  isFilter,
}: any) => {
  const intl = useIntl()
  return (
    <div className='row dashboard-card-wrap'>
      <div className='col-xl-3 col-md-6 mb-1 stat-card-wrap'>
        <StatCard
          title={intl.formatMessage({id: 'homePage.dashboard.card.label'})}
          value={dashboardDetails?.activeContainerCount || '0'}
          url={''}
          cardColor={'danger'}
          isExternalClick
          externalHandleClick={() => {
            if (!isFilter) {
              handleExternalFilterOpen()
            }

            handleExternalFilterData({})
            handleExternalCountryName()
          }}
        />
      </div>
      <div className='col-xl-3 col-md-6 mb-1 stat-card-wrap domestic-card-wrap'>
        <StatCard
          title={
            dashboardDetails?.country?.code
              ? intl.formatMessage(
                  {id: 'homePage.dashboard.card1.label'},
                  {value: dashboardDetails?.country?.translatedName}
                )
              : intl.formatMessage({id: 'homePage.dashboard.card.notFound'})
          }
          value={
            dashboardDetails?.country?.code
              ? dashboardDetails?.activeContainerCountFromCountry || '0'
              : ''
          }
          isExternalClick
          externalHandleClick={() => {
            if (!isFilter) {
              handleExternalFilterOpen()
            }
            handleExternalFilterData({
              fromCountryCode: dashboardDetails?.country?.code,
              toCountryCode: null,
            })
            handleExternalCountryName({
              selectedFromFilterCountry: dashboardDetails?.country?.name,
              selectedToFilterCountry: null,
            })
          }}
          url={''}
          cardColor={'warning'}
        />
      </div>
      <div className='col-xl-3 col-md-6 mb-1 stat-card-wrap domestic-card-wrap'>
        <StatCard
          title={
            dashboardDetails?.country?.code
              ? intl.formatMessage(
                  {id: 'homePage.dashboard.card2.label'},
                  {value: dashboardDetails?.country?.translatedName}
                )
              : intl.formatMessage({id: 'homePage.dashboard.card.notFound'})
          }
          value={
            dashboardDetails?.country?.code
              ? dashboardDetails?.activeContainerCountToCountry || '0'
              : ''
          }
          isExternalClick
          externalHandleClick={() => {
            if (!isFilter) {
              handleExternalFilterOpen()
            }
            handleExternalFilterData({
              toCountryCode: dashboardDetails?.country?.code,
              fromCountryCode: null,
            })
            handleExternalCountryName({
              selectedToFilterCountry: dashboardDetails?.country?.name,
              selectedFromFilterCountry: null,
            })
          }}
          url={''}
          cardColor={'success'}
        />
      </div>
      <div className='col-xl-3 col-md-6 mb-1 stat-card-wrap domestic-card-wrap'>
        <StatCard
          title={
            dashboardDetails?.country?.code
              ? intl.formatMessage(
                  {id: 'homePage.dashboard.card4.label'},
                  {value: dashboardDetails?.country?.translatedName}
                )
              : intl.formatMessage({id: 'homePage.dashboard.card.notFound'})
          }
          value={
            dashboardDetails?.country?.code
              ? dashboardDetails?.activeDomesticContainerCount || '0'
              : ''
          }
          isExternalClick
          externalHandleClick={() => {
            if (!isFilter) {
              handleExternalFilterOpen()
            }
            handleExternalFilterData({
              toCountryCode: dashboardDetails?.country?.code,
              fromCountryCode: dashboardDetails?.country?.code,
            })
            handleExternalCountryName({
              selectedToFilterCountry: dashboardDetails?.country?.name,
              selectedFromFilterCountry: dashboardDetails?.country?.name,
            })
          }}
          url={''}
          cardColor={'primary'}
        />
      </div>
      <div className='col-xl-3 col-md-6 mb-1 stat-card-wrap'>
        <StatCard
          icon={'/media/icons/duotune/arrows/mouse-pointer-solid.svg'}
          description={intl.formatMessage({id: 'homePage.dashboard.card3.label'})}
          value={''}
          url={''}
          isExternalClick
          externalHandleClick={() => {
            if (!isFilter) {
              handleExternalFilterOpen()
            }
          }}
          cardColor={'info'}
        />
      </div>
    </div>
  )
}

export default CardComponent
