import React, { useState } from 'react'
import Modal from '../Modal'
import { getYoutubeVId } from "../../../utils/common";

const VideoPopup = ({url, onClose}: any) => {
  return (
    <Modal
      closeBtnSettings={{
        show: true,
        onClick: () => onClose(),
      }}
      customAll={
        <div>
          <iframe
            className='video'
            width="420" 
            height="315"
            title='Youtube player'
            sandbox='allow-same-origin allow-forms allow-popups allow-scripts allow-presentation'
            src={`https://youtube.com/embed/${getYoutubeVId(url)}?autoplay=1`}
            allowFullScreen={true}
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          />
        </div>
      }
    />
  )
}

export default VideoPopup
