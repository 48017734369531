import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import clsx from 'clsx'

import Dropbox from '../../../../Components/Dropbox'
import CheckGroup from '../../../../Components/CheckGroup'
import RightLayoutPopup from '../../../../Components/RightLayoutPopup'

import useScrollLock from '../../../../../utils/useScrollLock'

const CreateTrailer = ({
  createTrailer,
  specificationList,
  trailerTypes,
  floorTypes,
  getSpecifications,
  onClose,
}: any) => {
  const intl = useIntl()
  const {scrollLock} = useScrollLock()
  useEffect(() => {
    scrollLock()
  }, [])

  const [specSelected, setSpecSelected] = useState<any>([])
  const [newTrailer, setNewTrailer] = useState<any>({
    year: '',
    plate: '',
    /*capacity: 0,*/
    make: '',
    model: '',
    trailerType: '',
    floorType: '',
    specificationList: [],
  })

  useEffect(() => {
    if (newTrailer.trailerType) {
      getSpecifications(newTrailer.trailerType)
    }
  }, [newTrailer.trailerType])

  useEffect(() => {
    if (specSelected) {
      setNewTrailer((item: any) => ({
        ...item,
        specificationList: specSelected,
      }))
    }
  }, [specSelected])

  return (
    <>
      <RightLayoutPopup
        title={intl.formatMessage({id: 'Carrier.trailer.create.createTrailer'})}
        className='create-trailer-side-wrap'
        isCloseIcon
        closeOnClick={() => onClose()}
      >
        <div className={''}>
          <div className={'row mb-10'}>
            <div className='col-xl-6 fv-row mb-10'>
              <label className='form-label fs-6 fw-bolder text-dark'>
                {intl.formatMessage({id: 'Carrier.trailer.create.year'})}
              </label>
              <input
                placeholder={intl.formatMessage({id: 'Carrier.trailer.create.year.placeHolder'})}
                className={clsx('form-control form-control-lg form-control-solid')}
                onChange={(event: any) =>
                  setNewTrailer((item: any) => ({
                    ...item,
                    year: event.target.value,
                  }))
                }
                value={newTrailer.year}
                type='text'
                autoComplete='off'
              />
            </div>
            <div className='col-xl-6 fv-row mb-10'>
              <label className='form-label fs-6 fw-bolder text-dark required'>
                {intl.formatMessage({id: 'Carrier.trailer.create.numberPlate'})}
              </label>
              <input
                placeholder={intl.formatMessage({
                  id: 'Carrier.trailer.create.numberPlate.placeHolder',
                })}
                className={clsx('form-control form-control-lg form-control-solid')}
                onChange={(event: any) =>
                  setNewTrailer((item: any) => ({
                    ...item,
                    plate: event.target.value,
                  }))
                }
                value={newTrailer.plate}
                type='text'
                autoComplete='off'
              />
            </div>
            <div className='col-xl-6 fv-row mb-10'>
              <label className='form-label fs-6 fw-bolder text-dark'>
                {intl.formatMessage({id: 'Carrier.trailer.create.brand'})}
              </label>
              <input
                placeholder={intl.formatMessage({id: 'Carrier.trailer.create.brand.placeHolder'})}
                className={clsx('form-control form-control-lg form-control-solid')}
                onChange={(event: any) =>
                  setNewTrailer((item: any) => ({
                    ...item,
                    make: event.target.value,
                  }))
                }
                value={newTrailer.make}
                type='text'
                autoComplete='off'
              />
            </div>
            <div className='col-xl-6 fv-row mb-10'>
              <label className='form-label fs-6 fw-bolder text-dark'>
                {intl.formatMessage({id: 'Carrier.trailer.create.model'})}
              </label>
              <input
                placeholder={intl.formatMessage({id: 'Carrier.trailer.create.model.placeHolder'})}
                className={clsx('form-control form-control-lg form-control-solid')}
                onChange={(event: any) =>
                  setNewTrailer((item: any) => ({
                    ...item,
                    model: event.target.value,
                  }))
                }
                value={newTrailer.model}
                type='text'
                autoComplete='off'
              />
            </div>
            <div className={'col-xl-6 mb-10'}>
              <label className='form-label fs-6 fw-bolder text-dark required'>
                {intl.formatMessage({id: 'Carrier.trailer.create.trailerType'})}
              </label>
              <Dropbox
                hideArrow
                id={'trailer'}
                customClass={'form-select'}
                placeholder={intl.formatMessage({
                  id: 'Carrier.trailer.create.trailerType.placeHolder',
                })}
                customSearchValue={''}
                customOnSearch={(e: any) => {}}
                disabledSearch
                onScroll={() => {}}
                setScrollCalled={() => {}}
                currentPageIndex={0}
                menuList={
                  trailerTypes &&
                  trailerTypes.map((item: any) => {
                    return {
                      id: item?.trailerTypeId,
                      title: item?.description,
                    }
                  })
                }
                selectedItemData={(dataItem: any) => {
                  if (dataItem?.id) {
                    setNewTrailer((item: any) => ({
                      ...item,
                      trailerType: parseInt(dataItem?.id),
                    }))
                  }
                }}
              />
            </div>
            <div className={'col-xl-6 mb-10'}>
              <label className='form-label fs-6 fw-bolder text-dark required'>
                {intl.formatMessage({id: 'Carrier.trailer.create.floorType'})}
              </label>
              <Dropbox
                hideArrow
                id={'floorType'}
                customClass={'form-select'}
                placeholder={intl.formatMessage({
                  id: 'Carrier.trailer.create.floorType.placeHolder',
                })}
                customSearchValue={''}
                customOnSearch={(e: any) => {}}
                disabledSearch
                onScroll={() => {}}
                setScrollCalled={() => {}}
                currentPageIndex={0}
                menuList={
                  floorTypes &&
                  floorTypes.map((item: any) => {
                    return {
                      id: item?.floorTypeId,
                      title: item?.description,
                    }
                  })
                }
                selectedItemData={(dataItem: any) => {
                  if (dataItem?.id) {
                    setNewTrailer((item: any) => ({
                      ...item,
                      floorType: parseInt(dataItem?.id),
                    }))
                  }
                }}
              />
            </div>
            <div className={'col-xl-6 mb-5'}>
              <label htmlFor='trailerSpecification' className={'text-muted fs-4 mb-2 required'}>
                {intl.formatMessage({id: 'Carrier.trailer.create.trailerCustom'})}
              </label>
              <CheckGroup
                onChange={(e: any) => {
                  if (e.target.checked) {
                    setSpecSelected((item: any) => [...item, e?.target?.value])
                  } else {
                    setSpecSelected(specSelected.filter((itm: any) => itm !== e.target.value))
                  }
                }}
                selectedItems={specSelected}
                data={
                  specificationList &&
                  specificationList?.map((item: any) => {
                    return {
                      id: item?.specificationId,
                      label: item?.description,
                    }
                  })
                }
                placeholder={intl.formatMessage({
                  id: 'Carrier.trailer.create.trailerCustom.placeHolder',
                })}
              />
            </div>
          </div>
        </div>

        <div className='text-center mt-5 row freightPost-popup-container'>
          <div className={'col-md-6'}>
            <button
              onClick={onClose}
              type='button'
              id='kt_sign_up_submit'
              className='btn btn-lg btn-danger w-100 mb-5 '
            >
              {intl.formatMessage({id: 'CreateNewContainerPopup.cancel'})}
            </button>
          </div>
          <div className={'col-md-6'}>
            <button
              className={'btn btn-success px-10 m-auto w-100'}
              disabled={
                !newTrailer.plate ||
                /*!newTrailer.capacity ||*/ !newTrailer.floorType ||
                !newTrailer.trailerType ||
                (specSelected && Array.isArray(specSelected) && !specSelected.length)
              }
              onClick={() => {
                createTrailer(newTrailer)
              }}
            >
              {intl.formatMessage({id: 'Carrier.trailer.create.create'})}
            </button>
          </div>
        </div>
      </RightLayoutPopup>
    </>
  )
}

export default CreateTrailer
