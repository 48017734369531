import React, {useEffect} from 'react'
import {useIntl} from 'react-intl'
import clsx from 'clsx'

import RightLayoutPopup from '../../../../Components/RightLayoutPopup'
import PhoneInp from '../../../../Components/PhoneInp/PhoneInp'

import useScrollLock from '../../../../../utils/useScrollLock'

const CreateDriver = ({setNewDriver, newDriver, setLoading, createDriver, onClose}: any) => {
  const intl = useIntl()
  const {scrollLock} = useScrollLock()
  useEffect(() => {
    scrollLock()
  }, [])

  return (
    <RightLayoutPopup
      title={intl.formatMessage({id: 'Carrier.drivers.create.temporaryPassCreate'})}
      className='create-driver-right-side-wrap'
      isCloseIcon
      closeOnClick={() => onClose()}
    >
      <div className={''}>
        <div className='fv-row mb-10'>
          <label className='form-label fs-6 fw-bolder text-dark'>
            {intl.formatMessage({id: 'Carrier.drivers.create.name'})}
          </label>
          <input
            placeholder={intl.formatMessage({id: 'Carrier.drivers.create.name.placeHolder'})}
            className={clsx('form-control form-control-lg form-control-solid')}
            onChange={(event: any) =>
              setNewDriver((item: any) => ({
                ...item,
                name: event.target.value,
              }))
            }
            value={newDriver.name}
            type='text'
            name='name'
            autoComplete='off'
          />
        </div>
        <div className='fv-row mb-10'>
          <label className='form-label fs-6 fw-bolder text-dark'>
            {intl.formatMessage({id: 'Carrier.drivers.create.lastName'})}
          </label>
          <input
            placeholder={intl.formatMessage({id: 'Carrier.drivers.create.lastName.placeHolder'})}
            className={clsx('form-control form-control-lg form-control-solid')}
            onChange={(event: any) =>
              setNewDriver((item: any) => ({
                ...item,
                surname: event.target.value,
              }))
            }
            value={newDriver.surname}
            type='text'
            name='surname'
            autoComplete='off'
          />
        </div>
        <div className={'row col-md-8'}>
          <div className={'col-md-4 mt-5'}>
            <label
              htmlFor={'phoneCode'}
              className='class="form-label fw-bolder text-dark fs-6 mb-2'
            >
              {intl.formatMessage({id: 'Carrier.drivers.create.phoneCode'})}
            </label>
            <PhoneInp
              id={'phoneCode'}
              onSelect={(event: any) =>
                setNewDriver((item: any) => ({
                  ...item,
                  phoneCode: event.length > 0 ? '+' + event : event,
                }))
              }
            />
          </div>
          <div className='fv-row col-md-8 mt-5'>
            <label className='form-label fs-6 fw-bolder text-dark'>
              {intl.formatMessage({id: 'Carrier.drivers.create.phone'})}
            </label>
            <input
              placeholder={intl.formatMessage({id: 'Carrier.drivers.create.phone.placeHolder'})}
              maxLength={13}
              className={clsx('form-control form-control-lg form-control-solid')}
              onChange={(event: any) => {
                if (event.target.value.match(/^\+?(?:[\d]*)$/)) {
                  setNewDriver((item: any) => ({
                    ...item,
                    mobile: event.target.value,
                  }))
                }
              }}
              value={newDriver.mobile}
              type='text'
              name='mobile'
              autoComplete='off'
              required
            />
          </div>
        </div>
        {/*<div className='fv-row mb-10'>
                                    <label className='form-label fs-6 fw-bolder text-dark'>
                                        Varsayılan Şifre
                                    </label>
                                    <input
                                        placeholder='Varsayılan Şifre'
                                        className={clsx(
                                            'form-control form-control-lg form-control-solid',
                                        )}
                                        onChange={(event: any) => setNewDriver((item: any) => ({
                                            ...item,
                                            initialPassword: event.target.value
                                        }))}
                                        value={newDriver.initialPassword}
                                        type='text'
                                        name='initialPassword'
                                        autoComplete='off'
                                    />
                                </div>*/}
      </div>
      <div className='text-center mt-5 row freightPost-popup-container'>
        <div className={'col-md-6'}>
          <button
            onClick={onClose}
            type='button'
            id='kt_sign_up_submit'
            className='btn btn-lg btn-danger w-100 mb-5 '
          >
            {intl.formatMessage({id: 'CreateNewContainerPopup.cancel'})}
          </button>
        </div>
        <div className={'col-md-6'}>
          <button
            className={'btn btn-success px-10 m-auto w-100'}
            disabled={
              !newDriver.name || !newDriver.surname || !newDriver.mobile || !newDriver.phoneCode
            }
            type={'button'}
            onClick={() => {
              setLoading(true)
              createDriver(newDriver)
            }}
          >
            {intl.formatMessage({id: 'Carrier.drivers.create.create'})}
          </button>
        </div>
      </div>
    </RightLayoutPopup>
  )
}

export default CreateDriver
