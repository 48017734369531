import {initializeApp} from 'firebase/app'
import {getToken, getMessaging, onMessage} from 'firebase/messaging'

const firebaseConfig = {
  apiKey: 'AIzaSyCEPZGZh_1akymzMbYZVzLsoGQHgjwUlYk',
  authDomain: 'rivalog-86f1a.firebaseapp.com',
  projectId: 'rivalog-86f1a',
  storageBucket: 'rivalog-86f1a.appspot.com',
  messagingSenderId: '641555704078',
  appId: '1:641555704078:web:f806bac7451dbcfcd8cf5f',
  measurementId: 'G-F9ZNB2DPH4',
}

const app = initializeApp(firebaseConfig)
const messaging = getMessaging(app)

export const getOrRegisterServiceWorker = () => {
  if ('serviceWorker' in navigator) {
    return window.navigator.serviceWorker
      .getRegistration('/firebase-push-notification-scope')
      .then((serviceWorker) => {
        if (serviceWorker) return serviceWorker
        return window.navigator.serviceWorker.register('/firebase-messaging-sw.js', {
          scope: '/firebase-push-notification-scope',
        })
      })
  }
  throw new Error('The browser doesn`t support service worker.')
}

// {vapidKey: process.env.REACT_APP_VAPID_KEY, serviceWorkerRegistration}
export const getFirebaseToken = () =>
  getOrRegisterServiceWorker().then((serviceWorkerRegistration) =>
    getToken(messaging, {
      vapidKey:
        'BEyClgt3fFhNzotuygz0nZxEOq0uOBL268LBcoOwU9rWS5nkY_OnPyBJcWsHbkWBIIwC0TI4HoN9HmvIIQJaJTg',
        serviceWorkerRegistration
    })
  )

export const onForegroundMessage = () =>
  new Promise((resolve) => onMessage(messaging, (payload) => resolve(payload)))
