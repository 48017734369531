import {apiUrl, globalPageCount} from '../conf'
import axios from 'axios'

export class FreightPostingsService {
  getFreightPostings(
    pageIndex?: number,
    description?: string,
    fromCountryCode?: string,
    fromCityId?: string,
    toCountryCode?: string,
    toCityId?: string,
    transportTypeId?: string,
    plannedDepartureDate?: string,
    plannedArrivalDate?: string,
    driverId?: string,
    vehicleId?: string,
    trailerId?: string,
    transportStatusId?: string,
    trailerTypeId?: string,
    valueCurrencyId?: string,
    floorTypeId?: string,
    companyId?: string,
    freightLoadingTypeId?: string,
    freightPackageTypeId?: string,
    harmonizedSystemCode?: string,
    specificationIdList?: string,
    isPublished?: string,
    pageCount?: number
  ) {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        '/container-management/containers/posts?description=' +
        (description ?? '') +
        '&fromCountryCode=' +
        (fromCountryCode ?? '') +
        '&fromCityId=' +
        (fromCityId ?? '') +
        '&toCountryCode=' +
        (toCountryCode ?? '') +
        '&toCityId=' +
        (toCityId ?? '') +
        '&plannedDepartureDate=' +
        (plannedDepartureDate ?? '') +
        '&plannedArrivalDate=' +
        (plannedArrivalDate ?? '') +
        '&driverId=' +
        (driverId ?? '') +
        '&vehicleId=' +
        (vehicleId ?? '') +
        '&trailerId=' +
        (trailerId ?? '') +
        '&transportStatusId=' +
        (transportStatusId ?? '') +
        '&trailerTypeId=' +
        (trailerTypeId ?? '') +
        '&valueCurrencyId=' +
        (valueCurrencyId ?? '') +
        '&floorTypeId=' +
        (floorTypeId ?? '') +
        '&companyId=' +
        (companyId ?? '') +
        '&freightLoadingTypeId=' +
        (freightLoadingTypeId ?? '') +
        '&freightPackageTypeId=' +
        (freightPackageTypeId ?? '') +
        '&transportTypeId=' +
        (transportTypeId ?? '') +
        '&harmonizedSystemCode=' +
        (harmonizedSystemCode ?? '') +
        '&specificationIdList=' +
        (specificationIdList ?? '') +
        '&isPublished=' +
        (isPublished ?? 'Y') +
        '&pageIndex=' +
        (pageIndex ?? 0) +
        '&pageCount=' +
        (pageCount ?? 20),
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  getPublicFreightPostings(
    pageIndex?: number,
    description?: string,
    fromCountryCode?: string,
    fromCityId?: string,
    toCountryCode?: string,
    toCityId?: string,
    transportTypeId?: string,
    vehicleTypeId?: string,
    plannedDepartureDate?: string,
    plannedArrivalDate?: string,
    driverId?: string,
    vehicleId?: string,
    trailerId?: string,
    transportStatusId?: string,
    trailerTypeId?: string,
    valueCurrencyId?: string,
    floorTypeId?: string,
    companyId?: string,
    freightLoadingTypeId?: string,
    freightPackageTypeId?: string,
    harmonizedSystemCode?: string,
    specificationIdList?: string,
    isPublished?: string,
    pageCount?: number
  ) {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        '/container-management/public/containers/posts?description=' +
        (description ?? '') +
        '&fromCountryCode=' +
        (fromCountryCode ?? '') +
        '&fromCityId=' +
        (fromCityId ?? '') +
        '&toCountryCode=' +
        (toCountryCode ?? '') +
        '&toCityId=' +
        (toCityId ?? '') +
        '&plannedDepartureDate=' +
        (plannedDepartureDate ?? '') +
        '&plannedArrivalDate=' +
        (plannedArrivalDate ?? '') +
        '&driverId=' +
        (driverId ?? '') +
        '&vehicleId=' +
        (vehicleId ?? '') +
        '&trailerId=' +
        (trailerId ?? '') +
        '&transportStatusId=' +
        (transportStatusId ?? '') +
        '&trailerTypeId=' +
        (trailerTypeId ?? '') +
        '&valueCurrencyId=' +
        (valueCurrencyId ?? '') +
        '&floorTypeId=' +
        (floorTypeId ?? '') +
        '&companyId=' +
        (companyId ?? '') +
        '&freightLoadingTypeId=' +
        (freightLoadingTypeId ?? '') +
        '&freightPackageTypeId=' +
        (freightPackageTypeId ?? '') +
        '&transportTypeId=' +
        (transportTypeId ?? '') +
        '&vehicleTypeId=' +
        (vehicleTypeId ?? '') +
        '&harmonizedSystemCode=' +
        (harmonizedSystemCode ?? '') +
        '&specificationIdList=' +
        (specificationIdList ?? '') +
        '&isPublished=' +
        (isPublished ?? 'Y') +
        '&pageIndex=' +
        (pageIndex ?? 0) +
        '&pageCount=' +
        (pageCount ?? 20),
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  getLandingPublicFreightPostings({
    pageIndex,
    description,
    fromCountryCode,
    fromCityId,
    toCountryCode,
    toCityId,
    transportTypeId,
    plannedDepartureDate,
    plannedArrivalDate,
    driverId,
    vehicleId,
    trailerId,
    transportStatusId,
    vehicleTypeId,
    trailerTypeId,
    valueCurrencyId,
    floorTypeId,
    companyId,
    freightLoadingTypeId,
    freightPackageTypeId,
    harmonizedSystemCode,
    specificationIdList,
    isPublished,
    freightTypeId,
  }: any) {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        '/container-management/public/containers/posts?description=' +
        (description ?? '') +
        '&fromCountryCode=' +
        (fromCountryCode ?? '') +
        '&fromCityId=' +
        (fromCityId ?? '') +
        '&toCountryCode=' +
        (toCountryCode ?? '') +
        '&toCityId=' +
        (toCityId ?? '') +
        '&plannedDepartureDate=' +
        (plannedDepartureDate ?? '') +
        '&plannedArrivalDate=' +
        (plannedArrivalDate ?? '') +
        '&driverId=' +
        (driverId ?? '') +
        '&vehicleId=' +
        (vehicleId ?? '') +
        '&trailerId=' +
        (trailerId ?? '') +
        '&transportStatusId=' +
        (transportStatusId ?? '') +
        '&trailerTypeId=' +
        (trailerTypeId ?? '') +
        '&vehicleTypeId=' +
        (vehicleTypeId ?? '') +
        '&freightTypeId=' +
        (freightTypeId ?? '') +
        '&valueCurrencyId=' +
        (valueCurrencyId ?? '') +
        '&floorTypeId=' +
        (floorTypeId ?? '') +
        '&companyId=' +
        (companyId ?? '') +
        '&freightLoadingTypeId=' +
        (freightLoadingTypeId ?? '') +
        '&freightPackageTypeId=' +
        (freightPackageTypeId ?? '') +
        '&transportTypeId=' +
        (transportTypeId ?? '') +
        '&harmonizedSystemCode=' +
        (harmonizedSystemCode ?? '') +
        '&specificationIdList=' +
        (specificationIdList ?? '') +
        '&isPublished=' +
        (isPublished ?? 'Y') +
        '&pageIndex=' +
        (pageIndex ?? 0) +
        '&pageCount=10',
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  getPublicFreightPostingsByContainerId(pageIndex?: number, containerId?: any) {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        `/container-management/public/containers/${containerId}/freights?description=` +
        (pageIndex ?? 0) +
        '&pageCount=20',
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  getPublicContainerDetails(containerId?: number) {
    const config: any = {
      method: 'GET',
      url: apiUrl + `/container-management/public/containers/${containerId}`,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  getTransporterList(pageIndex?: number) {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        '/transporter-management/transporters/filter?driverId=' +
        '&name=' +
        '&surname=' +
        '&vehicleId=' +
        '&vehiclePlate=' +
        '&trailerId=' +
        '&trailerPlate=' +
        '&companyId=' +
        '&pageIndex=' +
        (pageIndex ?? 0) +
        '&pageCount=1000',
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  getSelectedTransporterList(containerId?: number) {
    const config: any = {
      method: 'GET',
      url: apiUrl + `/container-management/containers/${containerId}/freights`,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  getProposalList(containerId?: number, pageIndex?: number) {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        `/container-management/containers/${containerId}/proposals/carriers/operators?pageIndex=${
          pageIndex ?? 0
        }&pageCount=${globalPageCount}`,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  getFreightDetailById(freightId?: number) {
    const config: any = {
      method: 'GET',
      url: apiUrl + `/freight-management/freights/${freightId}`,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  createTransportApplication(
    containerId?: any,
    transporterId?: number,
    price?: any,
    currency?: any,
    proposalScope?: any,
    description?: any
  ) {
    const config: any = {
      method: 'POST',
      url: apiUrl + '/container-management/containers/proposals',
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({
        container: {
          containerId: Number(containerId),
        },
        transporter: {
          transporterId: transporterId ? Number(transporterId) : '',
        },
        price: Number(price),
        currency: {
          currencyId: Number(currency),
        },
        explanation: description,
        proposalScope: {
          proposalScopeId: Number(proposalScope),
        },
      }),
    }

    return axios(config)
  }

  deleteTransportApplication(containerId?: number) {
    const config: any = {
      method: 'DELETE',
      url: apiUrl + '/transport-management/transports/applications',
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({
        containerId: Number(containerId),
      }),
    }

    return axios(config)
  }
}
