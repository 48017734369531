import React, {useEffect} from 'react'
import {useIntl} from 'react-intl'

import RightLayoutPopup from '../../../../Components/RightLayoutPopup'

import useScrollLock from '../../../../../utils/useScrollLock'

const ShowProposalDetail = ({proposalDetails, onClose}: any) => {
  const intl = useIntl()
  const {scrollLock, scrollUnlock} = useScrollLock()
  useEffect(() => {
    scrollLock()
  }, [])

  return (
    <RightLayoutPopup
      title={intl.formatMessage({id: 'freightPosting.proposalList.Show.title'})}
      className='carrier-show-freight-exchange-wrap'
      isCloseIcon
      closeOnClick={() => onClose()}
    >
      <div className={'detail-modal-scroll-container'}>
        <div className={'mb-10'}>
          <div className={'row'}>
            <div className={'col-md-6 mb-10'}>
              <h6 className={'text-muted  '}>
                {intl.formatMessage({id: 'freightPosting.proposalList.explanation'})}
              </h6>
              <b className={'fs-5'}>{proposalDetails?.explanation}</b>
            </div>
            <div className={'col-md-6 mb-10'}>
              <h6 className={'text-muted  '}>
                {intl.formatMessage({
                  id: 'freightPosting.proposalList.proposalScope',
                })}
              </h6>
              <b className={'fs-5'}>{proposalDetails?.proposalScope?.description}</b>
            </div>
          </div>

          <div className={'row'}>
            <div className={'col-md-6 mb-10'}>
              <h6 className={'text-muted '}>
                {intl.formatMessage({
                  id: 'freightPosting.proposalList.price',
                })}
              </h6>
              <b className={'fs-5'}>{proposalDetails?.price}</b>
            </div>

            <div className={'col-md-6 mb-10'}>
              <h6 className={'text-muted  '}>
                {intl.formatMessage({id: 'freightPosting.proposalList.currency'})}
              </h6>
              <b className={'fs-5'}>{proposalDetails?.currency?.description}</b>
            </div>
          </div>
        </div>
      </div>

      <div className='text-center row view-details-freight-popup freightPost-popup-container'>
        <div className={'col-md-6'}>
          <button
            onClick={() => {
              onClose()
              scrollUnlock()
            }}
            type='button'
            id='kt_sign_up_submit'
            className='btn btn-lg btn-danger w-100 mb-5 '
          >
            {intl.formatMessage({id: 'Carrier.freightPosting.view.close'})}
          </button>
        </div>
      </div>
    </RightLayoutPopup>
  )
}

export default ShowProposalDetail
