import React from 'react'
import {useIntl} from 'react-intl'

import {Header} from '../NewHomePage/Header/Header'
import {FooterContent} from '../NewHomePage/FooterContent/FooterContent'
// import Seo from '../../../../utils/seo'
// import {toAbsoluteUrl} from '../../../../_metronic/helpers'

import '../NewHomePage/NewHomePage.scss'
import './Privacy.scss'

// const siteNavigationStructuredData: any = {
//   '@context': 'http://schema.org',
//   '@type': 'BlogPosting',
//   name: 'blog post',
//   url: 'https://www.rivalog.com/blog/speed-and-efficiency-advantages-of-air',
//   publisher: {
//     '@type': 'Organization',
//     name: 'Rivalog',
//     logo: 'https://www.rivalog.com/media/logo.png',
//   },
// }

const Privacy = () => {
  const intl = useIntl()

  return (
    <div className='main-content-home-wrap'>
      <Header />
      <div>
        <div className='bg-main-content'>
          <h1>{intl.formatMessage({id: 'privacyPolicy.title'})}</h1>
        </div>

        <div className='privacy-boy-wrap'>
          <div className='privacy-body-content'>
            <p>
              {intl.formatMessage({id: 'privacyPolicy.label'})}{' '}
              <a href='www.rivalog.com'>Rivalog</a>{' '}
              {intl.formatMessage({id: 'privacyPolicy.label2'})}
            </p>
            <p>
              <b>{intl.formatMessage({id: 'privacyPolicy.label3'})}</b>
            </p>

            <h2 className='privacy-content-first'>
              {intl.formatMessage({id: 'privacyPolicy.label4'})}
            </h2>
            <p style={{textAlign: 'left'}}>{intl.formatMessage({id: 'privacyPolicy.label5'})}</p>

            <h3 className='privacy-point-content-wrap privacy-point-content-wrap-color'>
              {intl.formatMessage({id: 'privacyPolicy.label6'})}
            </h3>

            <p className='privacy-point-content-wrap-second'>
              {intl.formatMessage({id: 'privacyPolicy.label7'})}
            </p>

            <ul className='privacy-footer-quick-links' style={{marginTop: '10px '}}>
              <li>{intl.formatMessage({id: 'privacyPolicy.label8'})}</li>
              <li>{intl.formatMessage({id: 'privacyPolicy.label9'})}</li>
              <li>{intl.formatMessage({id: 'privacyPolicy.label10'})}</li>
              <li>{intl.formatMessage({id: 'privacyPolicy.label11'})}</li>
              <li>{intl.formatMessage({id: 'privacyPolicy.label12'})}</li>
            </ul>

            <h3 className='privacy-point-content-wrap privacy-point-content-wrap-color'>
              {intl.formatMessage({id: 'privacyPolicy.label13'})}
            </h3>

            <p className='privacy-point-content-wrap-second'>
              {intl.formatMessage({id: 'privacyPolicy.label14'})}
            </p>

            <h3 className='privacy-point-content-wrap privacy-point-content-wrap-color'>
              {intl.formatMessage({id: 'privacyPolicy.label15'})}
            </h3>

            <p className='privacy-point-content-wrap-second'>
              {intl.formatMessage({id: 'privacyPolicy.label16'})}
            </p>

            <h3 className='privacy-point-content-wrap privacy-point-content-wrap-color'>
              {intl.formatMessage({id: 'privacyPolicy.label17'})}
            </h3>

            <p className='privacy-point-content-wrap-second'>
              {intl.formatMessage({id: 'privacyPolicy.label18'})}
            </p>

            <h3 className='privacy-point-content-wrap privacy-point-content-wrap-color'>
              {intl.formatMessage({id: 'privacyPolicy.label19'})}
            </h3>

            <p className='privacy-point-content-wrap-second'>
              {intl.formatMessage({id: 'privacyPolicy.label20'})}
            </p>

            <ul className='privacy-footer-quick-links' style={{marginTop: '10px '}}>
              <li>{intl.formatMessage({id: 'privacyPolicy.label21'})}</li>
              <li>{intl.formatMessage({id: 'privacyPolicy.label22'})}</li>
              <li>{intl.formatMessage({id: 'privacyPolicy.label23'})}</li>
            </ul>

            <p className='privacy-point-content-wrap-second'>
              {intl.formatMessage({id: 'privacyPolicy.label24'})}
            </p>

            <h2 className='privacy-content-first'>
              {intl.formatMessage({id: 'privacyPolicy.label25'})}
            </h2>

            <p className='privacy-point-content-wrap-second'>
              {intl.formatMessage({id: 'privacyPolicy.label26'})}
            </p>

            <h3 className='privacy-point-content-wrap privacy-point-content-wrap-color'>
              {intl.formatMessage({id: 'privacyPolicy.label27'})}
            </h3>

            <p className='privacy-point-content-wrap-second'>
              {intl.formatMessage({id: 'privacyPolicy.label28'})}
            </p>

            <p className='privacy-point-content-wrap-second'>
              {intl.formatMessage({id: 'privacyPolicy.label29'})}
            </p>

            <ul className='privacy-footer-quick-links' style={{marginTop: '10px '}}>
              <li>{intl.formatMessage({id: 'privacyPolicy.label30'})}</li>
              <li>{intl.formatMessage({id: 'privacyPolicy.label31'})}</li>
              <li>{intl.formatMessage({id: 'privacyPolicy.label32'})}</li>
            </ul>

            <h3 className='privacy-point-content-wrap privacy-point-content-wrap-color'>
              {intl.formatMessage({id: 'privacyPolicy.label33'})}
            </h3>

            <h4 className='privacy-point-content-wrap privacy-sub-point-color'>
              {intl.formatMessage({id: 'privacyPolicy.label34'})}
            </h4>

            <p className='privacy-point-content-wrap-second'>
              {intl.formatMessage({id: 'privacyPolicy.label35'})}
            </p>

            <p style={{textAlign: 'left'}}>{intl.formatMessage({id: 'privacyPolicy.label36'})}</p>

            <p style={{textAlign: 'left'}}>{intl.formatMessage({id: 'privacyPolicy.label37'})}</p>

            <h4 className='privacy-point-content-wrap privacy-sub-point-color'>
              {intl.formatMessage({id: 'privacyPolicy.label38'})}
            </h4>

            <p className='privacy-point-content-wrap-second'>
              {intl.formatMessage({id: 'privacyPolicy.label39'})}
            </p>

            <p style={{textAlign: 'left'}}>{intl.formatMessage({id: 'privacyPolicy.label40'})}</p>

            <p style={{textAlign: 'left'}}>{intl.formatMessage({id: 'privacyPolicy.label41'})}</p>

            <p style={{textAlign: 'left'}}>{intl.formatMessage({id: 'privacyPolicy.label42'})}</p>

            <h3 className='privacy-point-content-wrap privacy-point-content-wrap-color'>
              {intl.formatMessage({id: 'privacyPolicy.label43'})}
            </h3>

            <h4 className='privacy-point-content-wrap privacy-sub-point-color'>
              {intl.formatMessage({id: 'privacyPolicy.label44'})}
            </h4>

            <p className='privacy-point-content-wrap-second'>
              {intl.formatMessage({id: 'privacyPolicy.label45'})}
            </p>

            <h4 className='privacy-point-content-wrap privacy-sub-point-color'>
              {intl.formatMessage({id: 'privacyPolicy.label46'})}
            </h4>

            <p className='privacy-point-content-wrap-second'>
              {intl.formatMessage({id: 'privacyPolicy.label47'})}
            </p>

            <h4 className='privacy-point-content-wrap privacy-sub-point-color'>
              {intl.formatMessage({id: 'privacyPolicy.label48'})}
            </h4>

            <p className='privacy-point-content-wrap-second'>
              {intl.formatMessage({id: 'privacyPolicy.label49'})}
            </p>

            <h2 className='privacy-content-first'>
              {intl.formatMessage({id: 'privacyPolicy.label50'})}
            </h2>

            <p className='privacy-point-content-wrap-second'>
              {intl.formatMessage({id: 'privacyPolicy.label51'})}
            </p>

            <h2 className='privacy-content-first'>
              {intl.formatMessage({id: 'privacyPolicy.label52'})}
            </h2>

            <p className='privacy-point-content-wrap-second'>
              {intl.formatMessage({id: 'privacyPolicy.label53'})}
            </p>

            <p className='privacy-point-content-wrap-second'>
              {intl.formatMessage({id: 'privacyPolicy.label54'})}{' '}
              <a href='www.rivalog.com'>{intl.formatMessage({id: 'privacyPolicy.label55'})}</a>{' '}
              {intl.formatMessage({id: 'privacyPolicy.label56'})}
            </p>
          </div>
        </div>
      </div>
      <FooterContent />
    </div>
  )
}

export default Privacy
