/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {useDispatch} from 'react-redux'
import {useIntl} from 'react-intl'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useHistory} from 'react-router-dom'
import {useFormik} from 'formik'

import * as auth from '../redux/AuthRedux'
import {AuthPageModal} from '../AuthPageModal'

import {setLanguage} from '../../../../_metronic/i18n/Metronici18n'

import PhoneInp from '../../../Components/PhoneInp/PhoneInp'
import FinancialAdvisor from './FinancialAdvisor'
import Loading from '../../../Components/Loading'
import Modal from '../../../Components/Modal'

import {AuthService} from '../../../../services/MainServices/AuthService'
import {UserService} from '../../../../services/MainServices/UserService'

const initialValues = {
  email: '', //unikworksystems@gmail.com
  password: '', //12345
  companyId: '',
  countryCode: '+90',
  mobile: '',
}

const getMe = () => {
  const userServ = new UserService()
  return userServ.getUserData()
}

const roleByCompanyType: any = {
  FREIGHT_CARRIER: 'CARRIER_OPERATOR',
  FREIGHT_OWNER: 'FREIGHT_OPERATOR',
  ADMINISTRATOR: 'SYSTEM_OPERATOR',
  FORWARDER: 'FORWARDER_OPERATOR',
}

const setRole = (resp: any) => {
  const owner = resp?.data?.roles?.find((item: any) => item?.code === 'ORGANIZATION_OWNER')
  if (owner) {
    const rol_operator = roleByCompanyType[resp?.data?.company?.companyType?.code]
    return resp?.data?.roles
      ?.filter((item: any) => item?.code === rol_operator)
      .map((item: any) => item?.code)
  }
  /* return owner ? owner?.code : roles[0]?.code;*/
  return resp?.data?.roles
    .filter((item: any) => item?.code !== 'ORGANIZATION_OWNER')
    .map((item: any) => item?.code)
}

const LoginModal = ({onCloseModal}: any) => {
  const intl = useIntl()
  const navigate = useHistory()
  const [loading, setLoading] = useState(false)
  const [modalLoading, setModalLoading] = useState(false)
  const [activeLoginTab, setActiveLoginTab] = useState(1)
  const [showAdvisor, setShowAdvisor] = useState<boolean>(false)
  const dispatch = useDispatch()
  const authserv = new AuthService()

  const loginSchema = Yup.object().shape(
    {
      email: Yup.string().when(['mobile'], {
        is: (mobile: any) => !mobile,
        then: Yup.string()
          .min(3, intl.formatMessage({id: 'global.min3SymbolRequired'}))
          .max(50, intl.formatMessage({id: 'global.max50SymoblRequired'}))
          .required(intl.formatMessage({id: 'global.thisFieldRequired'})), // unnecessary
        otherwise: Yup.string(),
      }),
      mobile: Yup.string().when(['email'], {
        is: (email: any) => !email,
        then: Yup.string().required(intl.formatMessage({id: 'global.mandatory'})), // unnecessary
        otherwise: Yup.string(),
      }),

      countryCode: Yup.string().required(intl.formatMessage({id: 'global.thisFieldRequired'})),

      // email: Yup.string()
      //   .min(3, intl.formatMessage({id: 'global.min3SymbolRequired'}))
      //   .max(50, intl.formatMessage({id: 'global.max50SymoblRequired'}))
      //   .required(intl.formatMessage({id: 'global.thisFieldRequired'})),
      password: Yup.string()
        .min(3, intl.formatMessage({id: 'global.min3SymbolRequired'}))
        .max(50, intl.formatMessage({id: 'global.max50SymoblRequired'}))
        .required(intl.formatMessage({id: 'global.thisFieldRequired'})),
      companyId: Yup.string()
        .min(1, intl.formatMessage({id: 'global.min3SymbolRequired'}))
        .max(50, intl.formatMessage({id: 'global.max50SymoblRequired'})),
    },
    [['email', 'mobile']]
  )

  const saveInfos = async (resp: any) => {
    dispatch(auth.actions.login(resp.data.userToken.token))
    dispatch(auth.actions.forceToChangePassword(resp?.data?.forceToChangePassword))
    return dispatch(auth.actions.role(setRole(resp)))
  }

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setTimeout(() => {
        if (activeLoginTab === 0) {
          authserv
            .loginByMobile(values.countryCode, values.mobile, values.password)
            .then((resp: any) => {
              saveInfos(resp).then(() => {
                getMe().then((me: any) => {
                  dispatch(auth.actions.fulfillUser(me.data))
                  setLanguage(me.data.language)
                  resp?.data?.forceToChangePassword === 'Y' && navigate.push('/renew-password')
                })
              })
            })
            .catch((e: any) => {
              setLoading(false)
              setSubmitting(false)
              setStatus(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
            })
        } else {
          authserv
            .login(values.email, values.password)
            .then((resp: any) => {
              saveInfos(resp).then(() => {
                getMe().then((me: any) => {
                  dispatch(auth.actions.fulfillUser(me.data))
                  setLanguage(me.data.language)
                  resp?.data?.forceToChangePassword === 'Y' && navigate.push('/renew-password')
                })
              })
            })
            .catch((e: any) => {
              setLoading(false)
              setSubmitting(false)
              setStatus(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
            })
        }
      }, 1000)
    },
  })

  return (
    <Modal
      closeBtnSettings={{
        show: true,
        onClick: () => onCloseModal(),
      }}
      containerModalWrap={'login-modal-wrap'}
      customAll={
        <AuthPageModal>
          <form
            className='form'
            onSubmit={formik.handleSubmit}
            noValidate
            id='kt_login_signin_form'
          >
            {modalLoading && <Loading />}
            {/* begin::Heading test */}
            <div
              style={{display: 'flex', justifyContent: 'center', cursor: 'pointer'}}
              onClick={() => navigate.push('/')}
            >
              <img
                style={{width: '90px', height: '70px'}}
                src='/media/logos/rivalog-logo.png'
                alt='Rivalog Logo'
              />
            </div>
            <div className='text-center mb-5'>
              <h1 className='text-dark mb-3'>{intl.formatMessage({id: 'LOGIN.WELCOME.TITLE'})}</h1>
            </div>
            {/* begin::Heading */}

            {formik.status ? (
              <div className='mb-lg-15 alert alert-danger'>
                <div className='alert-text font-weight-bold'>{formik.status}</div>
              </div>
            ) : (
              <div className='bg-light-info p-4 rounded'>
                <div className='text-info'>
                  {intl.formatMessage({id: 'LOGIN.REGISTERED.ACCOUNT.TITLE'})}
                </div>
              </div>
            )}

            {showAdvisor && (
              <FinancialAdvisor
                loading={modalLoading}
                setLoading={setModalLoading}
                onClose={() => setShowAdvisor(false)}
              />
            )}

            <ul
              className='sidebar-nav nav nav-tabs pt-5 justify-content-left'
              id='kt_sidebar_tabs'
              role='tablist'
            >
              <li className='nav-item tab-wrapper mobile-tab-wrapper'>
                <a
                  onClick={() => {
                    setActiveLoginTab(0)
                  }}
                  className={`nav-main-wrap ${
                    activeLoginTab === 0 ? 'active-tab-wrap' : 'nav-normal-wrap'
                  }`}
                  id='kt_sidebar_tab_1'
                >
                  {intl.formatMessage({id: 'Login.tab.title.mobile'})}
                </a>
              </li>

              <li className='nav-item tab-wrapper mobile-tab-wrapper'>
                <a
                  onClick={() => {
                    setActiveLoginTab(1)
                  }}
                  className={`nav-main-wrap ${
                    activeLoginTab === 1 ? 'active-tab-wrap' : 'nav-normal-wrap'
                  }`}
                  id='kt_sidebar_tab_2'
                >
                  {intl.formatMessage({id: 'Login.tab.title.email'})}
                </a>
              </li>
            </ul>

            {/* begin::Sidebar Content */}
            <div id='kt_sidebar_content' className='py-5 registration-content-tab-wrap'>
              <div
                className='me-lg-n2 pe-lg-2'
                data-kt-scroll='true'
                data-kt-scroll-offset='10px'
                data-kt-scroll-dependencies='#kt_sidebar_tabs, #kt_sidebar_footer'
                data-kt-scroll-wrappers='#kt_sidebar_content'
              >
                <div className='tab-content'>
                  <div
                    className={clsx('tab-pane', {active: activeLoginTab === 0})}
                    id='kt_sidebar_tab_pane_1'
                    role='tabpanel'
                  >
                    <div className='row'>
                      <div className={'col-md-4'}>
                        <label htmlFor={'phoneCode'} className='text-muted fs-4 mb-2'>
                          {intl.formatMessage({id: 'Carrier.drivers.create.phoneCode'})}
                        </label>
                        <PhoneInp
                          id={'countryCode'}
                          onSelect={(event: any) => {
                            formik.setFieldValue(
                              'countryCode',
                              event.length > 0 ? '+' + event : event
                            )
                          }}
                          className='form-control form-control-lg form-control-solid w-100'
                        />
                        {formik.touched.countryCode && formik.errors.countryCode && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert' style={{color: '#DC3444'}}>
                                {formik.errors.countryCode}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className='fv-row col-md-8 mb-5'>
                        <label className='fs-4 text-muted mb-2'>
                          {intl.formatMessage({id: 'Carrier.drivers.create.phone'})}
                        </label>
                        <input
                          placeholder={intl.formatMessage({
                            id: 'Carrier.drivers.create.phone.placeHolder',
                          })}
                          maxLength={13}
                          className={clsx('form-control form-control-lg form-control-solid')}
                          onChange={(event: any) => {
                            if (event.target.value.match(/^\+?(?:[\d]*)$/)) {
                              formik.setFieldValue('email', '')
                              formik.setFieldValue('mobile', event.target.value)
                            }
                          }}
                          value={formik.values.mobile}
                          type='text'
                          name='mobile'
                          autoComplete='off'
                          required
                        />
                        {formik.touched.mobile && formik.errors.mobile && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert' style={{color: '#DC3444'}}>
                                {formik.errors.mobile}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div
                    className={clsx('tab-pane', {active: activeLoginTab === 1})}
                    id='kt_sidebar_tab_pane_2'
                    role='tabpanel'
                  >
                    {/* begin::Form group */}
                    <div className='fv-row mb-5'>
                      <label className='form-label fs-6 fw-bolder text-dark'>
                        {intl.formatMessage({id: 'Login.tab.email.email'})}
                      </label>
                      <input
                        placeholder={intl.formatMessage({id: 'Login.tab.email.email'})}
                        {...formik.getFieldProps('email')}
                        className={clsx(
                          'form-control form-control-lg form-control-solid',
                          {'is-invalid': formik.touched.email && formik.errors.email},
                          {
                            'is-valid': formik.touched.email && !formik.errors.email,
                          }
                        )}
                        onChange={(event: any) => {
                          formik.setFieldValue('mobile', '')
                          formik.setFieldValue('email', event.target.value)
                        }}
                        type='text'
                        name='email'
                        autoComplete='off'
                      />
                      {formik.touched.email && formik.errors.email && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.email}</span>
                          </div>
                        </div>
                      )}
                    </div>
                    {/* end::Form group */}
                  </div>
                </div>
              </div>
            </div>

            {/* begin::Form group */}
            <div className='fv-row'>
              <div className='d-flex justify-content-between mt-n5'>
                <div className='d-flex flex-stack mb-2'>
                  {/* begin::Label */}
                  <label className='form-label fw-bolder text-dark fs-6 mb-0'>
                    {intl.formatMessage({id: 'LOGIN.PASSWORD.TITLE'})}
                  </label>
                  {/* end::Label */}
                </div>
              </div>
              <input
                placeholder={intl.formatMessage({id: 'LOGIN.PASSWORD.TITLE'})}
                type='password'
                autoComplete='off'
                {...formik.getFieldProps('password')}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {
                    'is-invalid': formik.touched.password && formik.errors.password,
                  },
                  {
                    'is-valid': formik.touched.password && !formik.errors.password,
                  }
                )}
              />
              {formik.touched.password && formik.errors.password && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.password}</span>
                  </div>
                </div>
              )}
              {/* begin::Link */}
              <Link
                to={{pathname: '/auth/forgot-password', state: {value: activeLoginTab}}}
                className='link-primary fs-6 fw-bolder'
                style={{
                  marginLeft: '5px',
                  display: 'flex',
                  justifyContent: 'end',
                  marginTop: '10px',
                }}
              >
                {intl.formatMessage({id: 'LOGIN.FORGET.PASSWORD.TITLE'})}
              </Link>
              {/* end::Link */}
            </div>
            {/* end::Form group */}

            {/* begin::Action */}
            <div className='text-center'>
              <button
                type='submit'
                id='kt_sign_in_submit'
                className='btn btn-lg btn-primary w-100 mb-5'
                disabled={formik.isSubmitting}
              >
                {!loading && (
                  <span className='indicator-label'>{intl.formatMessage({id: 'LOGIN.TITLE'})}</span>
                )}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    {intl.formatMessage({id: 'LOGIN.WAITING.TITLE'})}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
              {/* begin::Link */}
              <button
                type='button'
                className='btn btn-lg btn-primary w-100 mb-5'
                onClick={() => setShowAdvisor(true)}
              >
                {intl.formatMessage({id: 'financialAdv.title'})}
              </button>

              <span>{intl.formatMessage({id: 'LOGIN.ACCOUNT.DONTHAVE'})}</span>
              <Link
                to='/auth/registration'
                className='link-primary fs-6 fw-bolder'
                style={{
                  marginLeft: '5px',
                }}
              >
                {intl.formatMessage({id: 'LOGIN.ACCOUNT.REGISTER'})}
              </Link>
            </div>
            {/* end::Action */}
          </form>
        </AuthPageModal>
      }
    />
  )
}

export default LoginModal
