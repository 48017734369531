import {apiUrl} from '../conf'
import axios from 'axios'

export class BankAccountsService {
  getAccounts(pageIndex?: number, pageCount?: number, searchText?: string) {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        '/bank-account-management/accounts?searchText=' +
        (searchText ?? '') +
        '&pageIndex=' +
        (pageIndex ?? 0) +
        '&pageCount=' +
        (pageCount ?? 10000),
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  getAccount(id: number) {
    const config: any = {
      method: 'GET',
      url: apiUrl + '/bank-account-management/accounts/' + id,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  deleteAccount(id: number) {
    const config: any = {
      method: 'DELETE',
      url: apiUrl + '/bank-account-management/accounts/' + id,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  setPrimary(id: number) {
    const config: any = {
      method: 'PATCH',
      url: apiUrl + '/bank-account-management/accounts/' + id + '/primary',
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  updateAccount(
    bankAccountId?: number,
    explanation?: string,
    swiftCode?: number,
    accountHolder?: string,
    iban?: string
  ) {
    const config: any = {
      method: 'PUT',
      url: apiUrl + '/bank-account-management/accounts',
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({
        bankAccountId: bankAccountId && bankAccountId,
        explanation: explanation && explanation,
        swiftCode: swiftCode && swiftCode,
        accountHolder: accountHolder && accountHolder,
        iban: iban && iban,
      }),
    }

    return axios(config)
  }

  createAccount(swiftCode?: number, accountHolder?: string, iban?: string) {
    const config: any = {
      method: 'POST',
      url: apiUrl + '/bank-account-management/accounts',
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({
        swiftCode: swiftCode && swiftCode,
        accountHolder: accountHolder && accountHolder,
        iban: iban && iban,
      }),
    }

    return axios(config)
  }
}
