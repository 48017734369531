import React from 'react'
import {FooterContent} from '../NewHomePage/FooterContent/FooterContent'
import {Header} from '../NewHomePage/Header/Header'
import {AboutInfo} from './AboutInfo'
import '../NewLandingPage/index.css'

import './../NewHomePage/NewHomePage.scss'

const AboutUs = () => {
  return (
    <div className='scroll-wrapper about-us-wrap-content main-content-home-wrap'>
      <Header />
      <AboutInfo />
      <FooterContent />
    </div>
  )
}

export default AboutUs
