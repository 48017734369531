import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import _ from 'lodash'

import {KTSVG} from '../../../../_metronic/helpers'

import Dropbox from '../../../Components/Dropbox'

import {CommonService} from '../../../../services/FreightOperatorServices/CommonService'

const FilterComponent = ({handleFilterData, hideCheckBoxFilter = false}: any) => {
  const intl = useIntl()
  const commonServ = new CommonService()

  const [showFilterPanel, setShowFilterPanel] = useState<boolean>(false)

  const [fromCityList, setFromCityList] = useState<any>([])
  const [toCityList, setToCityList] = useState<any>([])
  const [fromCountryList, setFromCountryList] = useState<any>([])
  const [toCountryList, setToCountryList] = useState<any>([])

  const [selectedFromFilterCountry, setSelectedFromFilterCountry] = useState<any>('')
  const [selectedToFilterCountry, setSelectedToFilterCountry] = useState<any>('')

  const [fromCountrySearch, setFromCountrySearch] = useState<string>('')
  const [fromCountryPageIndex, setFromCountryPageIndex] = useState<number>(0)
  const [fromCountryId, setFromCountryId] = useState<string>('')

  const [fromCitySearch, setFromCitySearch] = useState<string>('')
  const [fromCityPageIndex, setFromCityPageIndex] = useState<number>(0)
  const [fromCityId, setFromCityId] = useState<string>('')

  const [toCountrySearch, setToCountrySearch] = useState<string>('')
  const [toCountryPageIndex, setToCountryPageIndex] = useState<number>(0)
  const [toCountryId, setToCountryId] = useState<string>('')

  const [toCitySearch, setToCitySearch] = useState<string>('')
  const [toCityPageIndex, setToCityPageIndex] = useState<number>(0)
  const [toCityId, setToCityId] = useState<string>('')

  const [dependentFromCity, setDependentFromCity] = useState<boolean>(false)
  const [dependentToCity, setDependentToCity] = useState<boolean>(false)

  const [name, setName] = useState<any>('')
  const [actualName, setActualName] = useState<any>('')
  const [surName, setSurName] = useState<any>('')
  const [actualSurName, setActualSurName] = useState<any>('')
  const [vehiclePlate, setVehiclePlate] = useState<any>('')
  const [actualVehiclePlate, setActualVehiclePlate] = useState<any>('')
  const [trailerPlate, setTrailerPlate] = useState<any>('')
  const [actualTrailerPlate, setActualTrailerPlate] = useState<any>('')
  const [checkListData, setCheckListData] = useState<any>([
    'LOOKING_FOR_FREIGHT',
    'NOT_WORKING',
    'FREIGHT_CARRYING',
  ])

  const [fromCountryScrollAPICalled, setFromCountryScrollAPICalled] = useState<boolean>(false)
  const [toCountryScrollAPICalled, setToCountryScrollAPICalled] = useState<boolean>(false)
  const [fromCityScrollAPICalled, setFromCityScrollAPICalled] = useState<boolean>(false)
  const [toCityScrollAPICalled, setToCityScrollAPICalled] = useState<boolean>(false)

  useEffect(() => {
    getFromCountryList()
    getToCountryList()
  }, [])

  const handleFilterChecklist = (value?: any) => {
    if (checkListData.includes(value)) {
      setCheckListData(checkListData.filter((item: any) => item !== value))
    } else {
      const result = [...checkListData, value]
      setCheckListData(result)
    }
  }

  const handleCheckFilter = (value: any) => {
    if (checkListData.includes(value)) {
      return true
    }
    return false
  }

  useEffect(() => {
    fromCountryId && getFromSearchCityList(fromCountryId, 0)
  }, [fromCountryId])

  useEffect(() => {
    toCountryId && getToSearchCityList(toCountryId, 0)
  }, [toCountryId])

  const getFromCountryList = (pageIndex: number = 0, searchText?: string) => {
    commonServ
      .countrySearchList(searchText ?? '', pageIndex ?? 0)
      .then((resp: any) => {
        setFromCountryScrollAPICalled(false)
        if (pageIndex === 0) setFromCountryList(resp?.data?.filter((item: any) => item?.name))
        else setFromCountryList((item: any) => [...item, ...resp?.data])
        // setLoading(false)
      })
      .catch((e: any) => {
        setFromCountryScrollAPICalled(false)
        // setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const getFromSearchCityList = (
    countryCode: string,
    pageIndex: number = 0,
    searchText?: string
  ) => {
    commonServ
      .citySearchList(countryCode, searchText ?? '', pageIndex ?? 0)
      .then((resp: any) => {
        setFromCityScrollAPICalled(false)
        if (pageIndex === 0) setFromCityList(resp?.data?.filter((item: any) => item?.name))
        else setFromCityList((item: any) => [...item, ...resp?.data])
        // setLoading(false)
      })
      .catch((e: any) => {
        setFromCityScrollAPICalled(false)
        // setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const getToCountryList = (pageIndex: number = 0, searchText?: string) => {
    commonServ
      .countrySearchList(searchText ?? '', pageIndex ?? 0)
      .then((resp: any) => {
        setToCountryScrollAPICalled(false)
        if (pageIndex === 0) setToCountryList(resp?.data?.filter((item: any) => item?.name))
        else setToCountryList((item: any) => [...item, ...resp?.data])
        // setLoading(false)
      })
      .catch((e: any) => {
        setToCountryScrollAPICalled(false)
        // setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const getToSearchCityList = (countryCode: string, pageIndex: number = 0, searchText?: any) => {
    commonServ
      .citySearchList(countryCode, searchText ?? '', pageIndex ?? 0)
      .then((resp: any) => {
        setToCityScrollAPICalled(false)
        if (pageIndex === 0) setToCityList(resp?.data?.filter((item: any) => item?.name))
        else setToCityList((item: any) => [...item, ...resp?.data])
        // setLoading(false)
      })
      .catch((e: any) => {
        setToCityScrollAPICalled(false)
        // setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const filterData = () => {
    handleFilterData({
      name: actualName,
      surName: actualSurName,
      vehiclePlate,
      trailerPlate,
      fromCountryCode: fromCountryId === 'all' ? null : fromCountryId,
      fromCityId: fromCityId === 'all' ? null : fromCityId,
      toCountryCode: toCountryId === 'all' ? null : toCountryId,
      toCityId: toCityId === 'all' ? null : toCityId,
      transporterStatusCodeList: checkListData.toString(),
    })
  }

  useEffect(() => {
    filterData()
  }, [
    actualName,
    actualSurName,
    actualVehiclePlate,
    actualTrailerPlate,
    fromCountryId,
    fromCityId,
    toCountryId,
    toCityId,
    checkListData,
  ])

  const handleNameFilterSearch = React.useMemo(
    () =>
      _.debounce((value) => {
        setActualName(value)
      }, 1000),
    []
  )

  const handleSurNameFilterSearch = React.useMemo(
    () =>
      _.debounce((value) => {
        setActualSurName(value)
      }, 1000),
    []
  )

  const handleVehiclePlateFilterSearch = React.useMemo(
    () =>
      _.debounce((value) => {
        setActualVehiclePlate(value)
      }, 1000),
    []
  )

  const handleTrailerPlateFilterSearch = React.useMemo(
    () =>
      _.debounce((value) => {
        setActualTrailerPlate(value)
      }, 1000),
    []
  )

  return (
    <>
      <span className={`menu-icon d-flex justify-content-end`}>
        <div
          className={`btn btn-icon btn-active-light-primary btn-custom ${
            showFilterPanel ? 'menu-button-active' : ''
          }`}
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
          onClick={() => setShowFilterPanel(!showFilterPanel)}
        >
          <KTSVG path='/media/filtering.svg' className='svg-icon-2x' />
        </div>
      </span>
      {showFilterPanel && (
        <>
          <div className={'row'}>
            <div className='mb-10 col-md-3 '>
              <h6 className={'mb-3'}>
                {intl.formatMessage({id: 'Carrier.container.transporter.filter.name'})}
              </h6>
              <div className={''}>
                <input
                  placeholder={intl.formatMessage({
                    id: 'Carrier.container.transporter.filter.name',
                  })}
                  className={'form-control form-control-lg'}
                  onChange={(event: any) => {
                    setName(event.target.value)
                    handleNameFilterSearch(event.target.value)
                  }}
                  value={name}
                  type='text'
                />
              </div>
            </div>
            <div className='mb-10 col-md-3 '>
              <h6 className={'mb-3'}>
                {intl.formatMessage({id: 'Carrier.container.transporter.filter.surName'})}
              </h6>
              <div className={''}>
                <input
                  placeholder={intl.formatMessage({
                    id: 'Carrier.container.transporter.filter.surName',
                  })}
                  className={'form-control form-control-lg'}
                  onChange={(event: any) => {
                    setSurName(event.target.value)
                    handleSurNameFilterSearch(event.target.value)
                  }}
                  value={surName}
                  type='text'
                />
              </div>
            </div>
            <div className='mb-10 col-md-3 '>
              <h6 className={'mb-3'}>
                {intl.formatMessage({id: 'Carrier.container.transporter.filter.vehiclePlate'})}
              </h6>
              <div className={''}>
                <input
                  placeholder={intl.formatMessage({
                    id: 'Carrier.container.transporter.filter.vehiclePlate',
                  })}
                  className={'form-control form-control-lg'}
                  onChange={(event: any) => {
                    setVehiclePlate(event.target.value)
                    handleVehiclePlateFilterSearch(event.target.value)
                  }}
                  value={vehiclePlate}
                  type='text'
                />
              </div>
            </div>
            <div className='mb-10 col-md-3 '>
              <h6 className={'mb-3'}>
                {intl.formatMessage({id: 'Carrier.container.transporter.filter.trailerPlate'})}
              </h6>
              <div className={''}>
                <input
                  placeholder={intl.formatMessage({
                    id: 'Carrier.container.transporter.filter.trailerPlate',
                  })}
                  className={'form-control form-control-lg'}
                  onChange={(event: any) => {
                    setTrailerPlate(event.target.value)
                    handleTrailerPlateFilterSearch(event.target.value)
                  }}
                  value={trailerPlate}
                  type='text'
                />
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='mb-10 col-md-3'>
              <h6 className={'mb-3'}>
                {intl.formatMessage({id: 'addNewAddressPopup.area2.fromCountry'})}
              </h6>
              <div className={''}>
                <Dropbox
                  key={selectedFromFilterCountry}
                  hideArrow
                  customClass={'form-select'}
                  placeholder={intl.formatMessage({
                    id: 'addNewAddressPopup.area2.country.placeholder',
                  })}
                  customSearchValue={fromCountrySearch}
                  customOnSearch={(e: any) => {
                    setFromCountryPageIndex(0)
                    setFromCountrySearch(e.target.value)
                    getFromCountryList(0, e.target.value)
                  }}
                  onScroll={(val: any) => {
                    setFromCountryPageIndex(val)
                    getFromCountryList(val, fromCountrySearch)
                  }}
                  setScrollCalled={() => {
                    setFromCountryScrollAPICalled(true)
                  }}
                  scrollAPIResponse={fromCountryScrollAPICalled}
                  currentPageIndex={fromCountryPageIndex}
                  menuList={
                    fromCountryList &&
                    fromCountryList
                      ?.filter(function ({code}: any) {
                        // @ts-ignore
                        return !this[code] && (this[code] = code)
                      }, {})
                      ?.map((item: any) => {
                        return {id: item?.code, title: item?.name}
                      })
                  }
                  defaultSelected={{id: fromCountryId, title: selectedFromFilterCountry}}
                  defaultClearItem={{
                    id: 'all',
                    title: intl.formatMessage({id: 'Admin.Adverts.all'}),
                  }}
                  selectedItemData={(item: any) => {
                    if (item?.id) {
                      setFromCountryId(item?.id)
                      setSelectedFromFilterCountry(item?.title)
                      setDependentFromCity(true)
                      setFromCityId('')
                    }
                  }}
                />
              </div>
            </div>

            <div className='mb-10 col-md-3'>
              <h6 className={'mb-3'}>
                {intl.formatMessage({id: 'addNewAddressPopup.area2.fromCity'})}
              </h6>
              <div className={''}>
                <Dropbox
                  hideArrow
                  disabled={!fromCountryId}
                  customClass={'form-select'}
                  placeholder={intl.formatMessage({
                    id: 'addNewAddressPopup.area2.city.placeholder',
                  })}
                  customSearchValue={fromCitySearch}
                  customOnSearch={(e: any) => {
                    setFromCityPageIndex(0)
                    setFromCitySearch(e.target.value)
                    getFromSearchCityList(fromCountryId, 0, e.target.value)
                  }}
                  onScroll={(val: any) => {
                    if (fromCountryId) {
                      setFromCityPageIndex(val)
                      getFromSearchCityList(fromCountryId, val, fromCountrySearch)
                    }
                  }}
                  setScrollCalled={() => {
                    setFromCityScrollAPICalled(true)
                  }}
                  scrollAPIResponse={fromCityScrollAPICalled}
                  currentPageIndex={fromCityPageIndex}
                  menuList={
                    fromCityList &&
                    fromCityList
                      ?.filter(function ({cityId}: any) {
                        // @ts-ignore
                        return !this[cityId] && (this[cityId] = cityId)
                      }, {})
                      ?.map((item: any) => {
                        return {id: item?.cityId, title: item?.name}
                      })
                  }
                  dependentValue={dependentFromCity}
                  defaultClearItem={{
                    id: 'all',
                    title: intl.formatMessage({id: 'Admin.Adverts.all'}),
                  }}
                  defaultSelected={{id: fromCityId}}
                  selectedItemData={(item: any) => {
                    if (item?.id) {
                      setFromCityId(item?.id)
                      setDependentFromCity(false)
                    }
                  }}
                />
              </div>
            </div>

            <div className='mb-10 col-md-3'>
              <h6 className={'mb-3'}>
                {intl.formatMessage({id: 'addNewAddressPopup.area2.toCountry'})}
              </h6>
              <div className={''}>
                <Dropbox
                  key={selectedToFilterCountry}
                  hideArrow
                  customClass={'form-select'}
                  placeholder={intl.formatMessage({
                    id: 'addNewAddressPopup.area2.country.placeholder',
                  })}
                  customSearchValue={toCountrySearch}
                  customOnSearch={(e: any) => {
                    setToCountryPageIndex(0)
                    setToCountrySearch(e.target.value)
                    getToCountryList(0, e.target.value)
                  }}
                  onScroll={(val: any) => {
                    setToCountryPageIndex(val)
                    getToCountryList(val, toCountrySearch)
                  }}
                  setScrollCalled={() => {
                    setToCountryScrollAPICalled(true)
                  }}
                  scrollAPIResponse={toCountryScrollAPICalled}
                  defaultSelected={{id: toCountryId, title: selectedToFilterCountry}}
                  currentPageIndex={toCountryPageIndex}
                  menuList={
                    toCountryList &&
                    toCountryList
                      ?.filter(function ({code}: any) {
                        // @ts-ignore
                        return !this[code] && (this[code] = code)
                      }, {})
                      ?.map((item: any) => {
                        return {id: item?.code, title: item?.name}
                      })
                  }
                  defaultClearItem={{
                    id: 'all',
                    title: intl.formatMessage({id: 'Admin.Adverts.all'}),
                  }}
                  selectedItemData={(item: any) => {
                    if (item?.id) {
                      setToCountryId(item?.id)
                      setSelectedToFilterCountry(item?.title)
                      setDependentToCity(true)
                      setToCityId('')
                    }
                  }}
                />
              </div>
            </div>

            <div className='mb-10 col-md-3'>
              <h6 className={'mb-3'}>
                {intl.formatMessage({id: 'addNewAddressPopup.area2.toCity'})}
              </h6>
              <div className={''}>
                <Dropbox
                  hideArrow
                  disabled={!toCountryId}
                  customClass={'form-select'}
                  placeholder={intl.formatMessage({
                    id: 'addNewAddressPopup.area2.city.placeholder',
                  })}
                  customSearchValue={toCitySearch}
                  customOnSearch={(e: any) => {
                    setToCityPageIndex(0)
                    setToCitySearch(e.target.value)
                    getToSearchCityList(toCountryId, 0, e.target.value)
                  }}
                  onScroll={(val: any) => {
                    if (toCountryId) {
                      setToCityPageIndex(val)
                      getToSearchCityList(toCountryId, val, fromCountrySearch)
                    }
                  }}
                  setScrollCalled={() => {
                    setToCityScrollAPICalled(true)
                  }}
                  scrollAPIResponse={toCityScrollAPICalled}
                  currentPageIndex={toCityPageIndex}
                  dependentValue={dependentToCity}
                  menuList={
                    toCityList &&
                    toCityList
                      ?.filter(function ({cityId}: any) {
                        // @ts-ignore
                        return !this[cityId] && (this[cityId] = cityId)
                      }, {})
                      ?.map((item: any) => {
                        return {id: item?.cityId, title: item?.name}
                      })
                  }
                  defaultClearItem={{
                    id: 'all',
                    title: intl.formatMessage({id: 'Admin.Adverts.all'}),
                  }}
                  defaultSelected={{id: toCityId}}
                  selectedItemData={(item: any) => {
                    if (item?.id) {
                      setToCityId(item?.id)
                      setDependentToCity(false)
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </>
      )}

      {!hideCheckBoxFilter && (
        <div className={'d-flex align-items-center'}>
          <div className='form-check ' style={{marginRight: '15px'}}>
            <input
              className='form-check-input'
              type='checkbox'
              id='chk1'
              onChange={() => handleFilterChecklist('LOOKING_FOR_FREIGHT')}
              checked={handleCheckFilter('LOOKING_FOR_FREIGHT')}
            />
            <label className='form-check-label' htmlFor='chk1'>
              {intl.formatMessage({id: 'carrier.trackOnMap.lookingFreight'})}
            </label>
          </div>
          <div className='form-check ' style={{marginRight: '15px'}}>
            <input
              className='form-check-input'
              type='checkbox'
              id='chk2'
              onChange={() => handleFilterChecklist('FREIGHT_CARRYING')}
              checked={handleCheckFilter('FREIGHT_CARRYING')}
            />
            <label className='form-check-label' htmlFor='chk2'>
              {intl.formatMessage({id: 'carrier.trackOnMap.freightCarrying'})}
            </label>
          </div>
          <div className='form-check'>
            <input
              className='form-check-input'
              type='checkbox'
              id='chk3'
              onChange={() => handleFilterChecklist('NOT_WORKING')}
              checked={handleCheckFilter('NOT_WORKING')}
            />
            <label className='form-check-label' htmlFor='chk3'>
              {intl.formatMessage({id: 'carrier.trackOnMap.notWorking'})}
            </label>
          </div>
        </div>
      )}
    </>
  )
}

export {FilterComponent}
