import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useLocation} from 'react-router-dom'
import {toast} from 'react-toastify'

import {PageTitle} from '../../../../_metronic/layout/core'

import Loading from '../../../Components/Loading'
import DataTableAdvanced from '../../../Components/DataTableAdvanced'
import Modal from '../../../Components/Modal'
import EditPreferences from './Popups/EditPreferences'
import ShowProposalTable from './Popups/ShowProposalTable'
import CreateProposal from './Popups/CreateProposal'
import ShowDetails from './Popups/ShowDetails'
import Dropbox from '../../../Components/Dropbox'
import FreightPublishPopup from './Popups/FreightPublishPopup'
import PublishPartialFreightsList from './Popups/PublishPartialFreightsList'

import {AdvertService} from '../../../../services/FreightOperatorServices/AdvertService'
import {AddressService} from '../../../../services/FreightOperatorServices/AddressService'
import {AdvertService as AdminAdvertService} from '../../../../services/AdminServices/AdvertService'
import {CommonService} from '../../../../services/FreightOperatorServices/CommonService'
import {ContainerService} from '../../../../services/AdminServices/ContainerService'
import {TransporterGroupService} from '../../../../services/CarrierServices/TransporterGroupService'

import useScrollLock from '../../../../utils/useScrollLock'
import getDateTime, {priceConversion, getFreightIcon} from '../../../../utils/common'
import {KTSVG} from '../../../../_metronic/helpers'

import './Adverts.scss'

const Adverts = () => {
  const useQuery = () => new URLSearchParams(useLocation().search)
  let query = useQuery()
  useEffect(() => {
    if (query?.get('filter'))
      switch (query?.get('filter')) {
        case 'waiting':
          return setFilter('1')
        case 'cancel':
          return setFilter('2')
        case 'reject':
          return setFilter('3')
        case 'approved':
          return setFilter('5')
        case 'ready-container':
          return setFilter('6')
        case 'ready-carrier':
          return setFilter('7')
        case 'onroad':
          return setFilter('8')
        case 'deliver':
          return setFilter('9')
        case 'draft':
          return setFilter('10')
      }
  }, [])

  const intl = useIntl()
  const [loading, setLoading] = useState<boolean>(false)

  /*Service States*/
  const advertServ = new AdvertService()
  const commonServ = new CommonService()
  const adminAdvertServ = new AdminAdvertService()
  const addressServ = new AddressService()
  const containerServ = new ContainerService()
  const transporterGroupServ = new TransporterGroupService()

  const [currentPageIndex, setCurrentPageIndex] = useState<number>(0)
  const [companyCustomSearch, setCompanyCustomSearch] = useState<string>('')
  const [pageMIndex, setPageMIndex] = useState<number>(0)
  const [proposalListPageMIndex, setProposalListPageMIndex] = useState<number>(0)

  /*Transport States Start*/
  const [transportTypes, setTransportTypes] = useState([])
  /*Transport States End*/

  /*List States*/
  const [proposalScopeList, setProposalScopeList] = useState<any[]>([])
  const [advertList, setAdvertList] = useState<any[]>([])
  const [selectedProposalTableList, setSelectedProposalTableList] = useState<any[]>([])
  const [selectedEditProposal, setSelectedEditProposal] = useState<any>({
    price: '',
    currency: '',
    explanation: '',
  })
  const [advertDetailedList, setAdvertDetailedList] = useState<any>({})
  const [selectedFreightId, setSelectedFreightId] = useState<number>(-1)

  /*Advert Approve and Reject states Start*/
  const [rejectText, setRejectText] = useState<string>('')
  const [showAdvertApprovePopup, setShowAdvertApprovePopup] = useState<boolean>(false)
  const [showAdvertRejectPopup, setShowAdvertRejectPopup] = useState<boolean>(false)
  /*Advert Approve and Reject states End*/

  /*Screens and modals*/
  const [showDetailsScreen, setShowDetailsScreen] = useState<boolean>(false)
  const [showUpdateScreen, setShowUpdateScreen] = useState<boolean>(false)
  const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false)
  const [showProposalPopup, setShowProposalPopup] = useState<boolean>(false)
  const [showProposalTablePopup, setShowProposalTablePopup] = useState<boolean>(false)

  const [showFilterPanel, setShowFilterPanel] = useState<boolean>(false)
  const [showPublishFreight, setShowPublishFreight] = useState<boolean>(false)

  const [searchCompanyScrollAPICalled, setSearchCompanyScrollAPICalled] = useState<boolean>(false)

  const [publishDate, setPublishDate] = useState<any>(null)
  const [publishNonFormatDate, setPublishNonFormatDate] = useState<any>('')
  const [transporterGroupUserList, setTransporterGroupUserList] = useState<any>([])
  const [selectedPublishFreightTransporterGroup, setSelectedPublishFreightTransporterGroup] =
    useState<any>([])
  const [description, setDescription] = useState<any>('')
  const [publicFreightExchange, setPublicFreightExchange] = useState<boolean>(false)
  const [privateFreightExchange, setPrivateFreightExchange] = useState<boolean>(false)
  const [selectedTransporterGroup, setSelectedTransporterGroup] = useState<any>([])
  const [targetCost, setTargetCost] = useState<any>('')
  const [selectedCurrency, setSelectedCurrency] = useState<any>('')
  const [selectedCurrencyName, setSelectedCurrencyName] = useState<any>('')

  const [showPartialFreightList, setShowPartialFreightList] = useState<boolean>(false)
  const [selectedPublishFreightList, setSelectedPublishFreightList] = useState<any>([])
  const [selectedPartialPublicFreightList, setSelectedPartialPublicFreightList] = useState<any>([])
  const [selectedFreightDetails, setSelectedFreightDetails] = useState<any>({})

  const [transportTypeSearch, setTransportTypeSearch] = useState<string>('')
  const [transportTypePageIndex, setTransportTypePageIndex] = useState<number>(0)
  const [transportTypeId, setTransportTypeId] = useState<string>('all')
  const [transportTypeName, setTransportTypeName] = useState<string>('')

  const [allTransportTypes, setAllTransportTypes] = useState<any>([])
  const [allFreightTypes, setAllFreightTypes] = useState<any>([])
  const [allFreightContentTypes, setAllFreightContentTypes] = useState<any>([])

  const [freightContentTypeSearch, setFreightContentTypeSearch] = useState<string>('')
  const [freightContentTypePageIndex, setFreightContentTypePageIndex] = useState<number>(0)
  const [freightContentTypeId, setFreightContentTypeId] = useState<string>('all')
  const [freightContentTypeName, setFreightContentTypeName] = useState<string>('')

  const [fromCityList, setFromCityList] = useState<any>([])
  const [toCityList, setToCityList] = useState<any>([])
  const [fromCountryList, setFromCountryList] = useState<any>([])
  const [toCountryList, setToCountryList] = useState<any>([])

  const [fromCountrySearch, setFromCountrySearch] = useState<string>('')
  const [fromCountryId, setFromCountryId] = useState<string>('')
  const [fromCountryName, setFromCountryName] = useState<string>('')

  const [fromCitySearch, setFromCitySearch] = useState<string>('')
  const [fromCityId, setFromCityId] = useState<string>('all')
  const [fromCityName, setFromCityName] = useState<string>('')

  const [toCountrySearch, setToCountrySearch] = useState<string>('')
  const [toCountryId, setToCountryId] = useState<string>('')
  const [toCountryName, setToCountryName] = useState<string>('')

  const [toCitySearch, setToCitySearch] = useState<string>('')
  const [toCityId, setToCityId] = useState<string>('all')
  const [toCityName, setToCityName] = useState<string>('')

  const [selectedFreightType, setSelectedFreightType] = useState<any>('')
  const [issueDate, setIssueDate] = useState<any>(null)
  const [loadIssueDate, setLoadIssueDate] = useState<any>('')
  const [plannedDepartureDate, setPlannedDepartureDate] = useState<any>(null)
  const [loadPannedDepartureDate, setLoadPlannedDepartureDate] = useState<any>('')
  const [dependentFromCity, setDependentFromCity] = useState<boolean>(false)
  const [dependentToCity, setDependentToCity] = useState<boolean>(false)

  const {scrollUnlock} = useScrollLock()
  useEffect(() => {
    if (
      !showDetailsScreen &&
      !showUpdateScreen &&
      !showDeletePopup &&
      !showAdvertApprovePopup &&
      !showAdvertRejectPopup
    )
      scrollUnlock()
  }, [
    showDetailsScreen,
    showUpdateScreen,
    showDeletePopup,
    showAdvertApprovePopup,
    showAdvertRejectPopup,
  ])

  /*Trailer States Start*/
  const [trailerTypes, setTrailerTypes] = useState([])
  const [floorTypes, setFloorTypes] = useState([])
  const [trailerSpecifications, setTrailerSpecifications] = useState([])
  /*Trailer States End*/

  /*Freight States Start*/
  const [freightTypes, setFreightTypes] = useState([])
  const [freightLoadTypes, setFreightLoadTypes] = useState([])
  const [freightPackageTypes, setFreightPackageTypes] = useState([])
  /*Freight States End*/

  /*Other Lists Start*/
  const [currencyList, setCurrencyList] = useState([])
  const [gtipList, setGtipList] = useState([])
  const [countries, setCountries] = useState([])
  const [cities, setCities] = useState([])
  const [addressList, setAdressList] = useState<any>([])

  const [filter, setFilter] = useState<string>('all')
  const [filterCounter, setFilterCounter] = useState<number>(0)
  const [companyFilter, setCompanyFilter] = useState<string>('all')
  const [companyFilterCounter, setCompanyFilterCounter] = useState<number>(0)
  const [statusList, setStatusList] = useState<any>([])
  const [ownerCompanyList, setOwnerCompanyList] = useState<any>([])

  const [isNextPage, setIsNextPage] = useState<boolean>(false)
  const [isNextProposalPage, setIsNextProposalPage] = useState<boolean>(false)

  /*Other Lists End*/

  const resetField = () => {
    setSelectedFreightDetails({})
    setSelectedFreightType('')
    setDescription('')
    setTargetCost('')
    setTransportTypeName('')
    setTransportTypeId('')
    setFreightContentTypeName('')
    setFreightContentTypeId('')
    setSelectedCurrencyName('')
    setSelectedCurrency('')
    setFromCountryName('')
    setFromCountryId('')
    setFromCityName('')
    setFromCityId('')
    setToCountryName('')
    setToCountryId('')
    setToCityName('')
    setToCityId('')
    setPublicFreightExchange(false)
    setPrivateFreightExchange(false)
    setSelectedTransporterGroup([])
    setSelectedPublishFreightList([])
    setSelectedPublishFreightTransporterGroup([])
    setPublishNonFormatDate('')
    setPublishDate(null)
    setIssueDate(null)
    setLoadIssueDate('')
    setPlannedDepartureDate(null)
    setLoadPlannedDepartureDate('')
    setDescription('')
    setTargetCost('')
    setSelectedCurrencyName('')
    setSelectedCurrency('')
    setPublicFreightExchange(false)
    setPrivateFreightExchange(false)
    setSelectedTransporterGroup([])
    setSelectedPublishFreightTransporterGroup([])
    setPublishNonFormatDate('')
    setPublishDate(null)
  }

  const getAllTransportTypes = () => {
    advertServ
      .carrierAllTransportTypesList()
      .then((resp) => {
        setAllTransportTypes(resp?.data)
      })
      .catch((e: any) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
        setLoading(false)
      })
  }

  const getFromCountryList = (pageIndex: number = 0, searchText?: string) => {
    commonServ
      .countryAllSearchList(searchText ?? '', pageIndex ?? 0)
      .then((resp: any) => {
        if (pageIndex === 0)
          setFromCountryList(
            [
              {
                code: 'all',
                name: intl.formatMessage({id: 'Admin.Adverts.all'}),
              },
            ].concat(resp?.data?.filter((item: any) => item?.name))
          )
        else setFromCountryList((item: any) => [...item, ...resp?.data])
        setLoading(false)
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const getFromSearchCityList = (
    countryCode: string,
    pageIndex: number = 0,
    searchText?: string
  ) => {
    commonServ
      .cityAllSearchList(countryCode, searchText ?? '', pageIndex ?? 0)
      .then((resp: any) => {
        if (pageIndex === 0)
          setFromCityList(
            [
              {
                cityId: 'all',
                name: intl.formatMessage({id: 'Admin.Adverts.all'}),
              },
            ].concat(resp?.data?.filter((item: any) => item?.name))
          )
        else setFromCityList((item: any) => [...item, ...resp?.data])
        setLoading(false)
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const getToCountryList = (pageIndex: number = 0, searchText?: string) => {
    commonServ
      .countryAllSearchList(searchText ?? '', pageIndex ?? 0)
      .then((resp: any) => {
        if (pageIndex === 0)
          setToCountryList(
            [
              {
                code: 'all',
                name: intl.formatMessage({id: 'Admin.Adverts.all'}),
              },
            ].concat(resp?.data?.filter((item: any) => item?.name))
          )
        else setToCountryList((item: any) => [...item, ...resp?.data])
        setLoading(false)
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const getToSearchCityList = (countryCode: string, pageIndex: number = 0, searchText?: any) => {
    commonServ
      .cityAllSearchList(countryCode, searchText ?? '', pageIndex ?? 0)
      .then((resp: any) => {
        if (pageIndex === 0)
          setToCityList(
            [
              {
                cityId: 'all',
                name: intl.formatMessage({id: 'Admin.Adverts.all'}),
              },
            ].concat(resp?.data?.filter((item: any) => item?.name))
          )
        else setToCityList((item: any) => [...item, ...resp?.data])
        setLoading(false)
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  useEffect(() => {
    fromCountryId && getFromSearchCityList(fromCountryId, 0)
  }, [fromCountryId])

  useEffect(() => {
    toCountryId && getToSearchCityList(toCountryId, 0)
  }, [toCountryId])

  const createAndPublishPartialContainer = (data: any) => {
    advertServ
      .createAndPublishPartialContainer(data)
      .then(() => {
        toast.success(
          intl.formatMessage({id: 'Carrier.advert.publishToPrivateFreight.create.success'})
        )
        resetField()
        setShowPartialFreightList(false)
        setLoading(false)
        getAdvertList({freightStatusId: filter, pageMIndex})
      })
      .catch((e: any) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
        setLoading(false)
        setShowPartialFreightList(false)
      })
  }

  const getSelectedFreightDetailed = (freightId: number) => {
    advertServ
      .getCarrierAdvertWithId(freightId)
      .then((resp) => {
        setSelectedFreightDetails(resp?.data)
        setDescription(resp?.data?.description)
        setTargetCost(resp?.data?.targetCost)
        setTransportTypeName(resp?.data?.transportType?.description)
        setTransportTypeId(resp?.data?.transportType?.transportTypeId)
        setFreightContentTypeName(resp?.data?.freightContentType?.description)
        setFreightContentTypeId(resp?.data?.freightContentType?.freightContentTypeId)
        setSelectedCurrencyName(resp?.data?.targetCostCurrency?.description)
        setSelectedCurrency(resp?.data?.targetCostCurrency?.currencyCode)
        setFromCountryName(resp?.data?.fromAddress?.country?.name)
        setFromCountryId(resp?.data?.fromAddress?.country?.code)
        setFromCityName(resp?.data?.fromAddress?.city?.name)
        setFromCityId(resp?.data?.fromAddress?.city?.cityId)
        setToCountryName(resp?.data?.toAddress?.country?.name)
        setToCountryId(resp?.data?.toAddress?.country?.code)
        setToCityName(resp?.data?.toAddress?.city?.name)
        setToCityId(resp?.data?.toAddress?.city?.cityId)
        setLoading(false)
      })
      .catch((e: any) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
        setLoading(false)
      })
  }

  const handlePublishFreight = (data: any) => {
    advertServ
      .createAndPublishFromFullTruckFreight(data)
      .then(() => {
        toast.success(intl.formatMessage({id: 'Carrier.freight.publishFreight.publish.success'}))
        resetField()
        setShowPublishFreight(false)
        setLoading(false)
        getAdvertList({freightStatusId: filter, pageMIndex})
      })
      .catch((e: any) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
        setLoading(false)
        setShowPublishFreight(false)
      })
  }

  const onPaginationChange = (page: number) => {
    if (!isNextPage) {
      getAdvertList({
        freightStatusId: filter,
        ...(companyFilter && {companyId: companyFilter}),
        pageMIndex: page,
      })
      setPageMIndex(page)
    }
    setIsNextPage(true)
  }

  const getAllFreightContentTypeList = (pageIndex: number = 0, searchText?: any) => {
    commonServ
      .allFreightContentTypeList(searchText ?? '', pageIndex ?? 0)
      .then((resp: any) => {
        setAllFreightContentTypes(resp?.data)
        setLoading(false)
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const hadnleGetTransporterGroupList = () => {
    transporterGroupServ
      .getTransporterGroupListCurrentUser()
      .then((res: any) => {
        setTransporterGroupUserList(
          res?.data.map((item: any) => ({id: item.transporterGroupId, name: item.description}))
        )
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
        setLoading(false)
      })
  }

  const getProposalScopeList = () => {
    containerServ
      .getCarrierProposalScope()
      .then((resp: any) => {
        setProposalScopeList(resp?.data)
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
        setLoading(false)
      })
  }

  /** Advert Api call Start **/
  const getAdvertList = (data?: any) => {
    advertServ
      .getAdverts({
        ...(data?.freightStatusId && {freightStatusId: data?.freightStatusId}),
        ...(data?.companyId && {companyId: data?.companyId}),
        ...{pageIndex: data?.pageMIndex ?? pageMIndex},
        willBeExcludedFreightIdList: data?.willBeExcludedFreightIdList?.toString(),
        transportTypeId: data?.transportTypeId,
      })
      .then((resp: any) => {
        setAdvertList(resp?.data)
        setLoading(false)
        setIsNextPage(false)
      })
      .catch((e: any) => {
        setLoading(false)
        setIsNextPage(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const getAdvertDetailedList = (freightId: number) => {
    advertServ
      .getAdvertWithId(freightId)
      .then((resp: any) => {
        setAdvertDetailedList(resp?.data)
        setLoading(false)
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const deleteAdvert = (advertId: number) => {
    advertServ
      .deleteAdvert(advertId)
      .then((resp: any) => {
        setLoading(false)
        getAdvertList({freightStatusId: filter})
        toast.success(intl.formatMessage({id: 'Admin.Adverts.deleteMessage'}), {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  /** Address api call Start **/
  const getAddressList = () => {
    addressServ
      .getAdressList()
      .then((resp) => {
        setAdressList(resp?.data)
      })
      .catch((e: any) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
        setLoading(false)
      })
  }

  const updateAdvert = (data: any) => {
    const fromAddressData = {
      latitude: data.fromLatitude,
      longitude: data.fromLongitude,
      neighborhood: data.fromNeighborhood,
      postCode: data.fromPostCode,
      street: data.fromStreet,
      streetNumber: data.fromStreetNumber,
      city: {
        cityId: parseInt(data.fromCity),
      },
      country: {
        code: data.fromCountry,
      },
    }

    const toAddressData = {
      latitude: data.toLatitude,
      longitude: data.toLongitude,
      neighborhood: data.toNeighborhood,
      postCode: data.toPostCode,
      street: data.toStreet,
      streetNumber: data.toStreetNumber,
      city: {
        cityId: parseInt(data.toCity),
      },
      country: {
        code: data.toCountry,
      },
    }

    let consignor = null
    let consignee = null

    if (
      data.consignorName ||
      data.consignorSurName ||
      data.consignorPhone ||
      data.consignorEmail ||
      data.consignorTaxNumber ||
      data.consignorTaxOffice ||
      data.consignorReferenceClientId
    ) {
      consignor = {
        name: data?.consignorName || null,
        surname: data?.consignorSurName || null,
        countryCode: data?.consignorPhone ? data.consignorCountryCode : null,
        phone: data?.consignorPhone || null,
        email: data?.consignorEmail || null,
        taxNumber: data?.consignorTaxNumber || null,
        taxOffice: data?.consignorTaxOffice || null,
        referenceClientId: data?.consignorReferenceClientId || null,
      }
    }

    if (
      data.consigneeName ||
      data.consigneeSurName ||
      data.consigneePhone ||
      data.consigneeEmail ||
      data.consigneeTaxNumber ||
      data.consigneeTaxOffice ||
      data.consigneeReferenceClientId
    ) {
      consignee = {
        name: data?.consigneeName || null,
        surname: data?.consigneeSurName || null,
        countryCode: data?.consigneePhone ? data.consigneeCountryCode : null,
        phone: data?.consigneePhone || null,
        email: data?.consigneeEmail || null,
        taxNumber: data?.consigneeTaxNumber || null,
        taxOffice: data?.consigneeTaxOffice || null,
        referenceClientId: data?.consigneeReferenceClientId || null,
      }
    }

    advertServ
      .updateAdvert(
        data.freightId,
        data.title,
        data.value,
        data.weight,
        data.width,
        data.height,
        data.freightLenght,
        data.desi,
        data.ldm,
        data.adr1,
        data.stackable,
        parseFloat(data.currency),
        new Date(data?.loadDate + ' ' + data?.loadTime.replace(/(AM|PM)/, ''))
          ?.getTime()
          ?.toString(),
        new Date(data?.destDate + ' ' + data?.destTime.replace(/(AM|PM)/, ''))
          ?.getTime()
          ?.toString(),
        parseInt(data.loadAddress),
        parseInt(data.destinationAddress),
        parseInt(data.trailerType),
        parseInt(data.floorType),
        data.trailerSpecification,
        parseInt(data.transportType),
        parseInt(data.freightType),
        parseInt(data.freightLoadType),
        parseInt(data.freightPackageType),
        data.harmonizedSystemCode,
        parseInt(data.freightContentTypeId),
        fromAddressData,
        toAddressData,
        consignor,
        consignee
      )
      .then((resp: any) => {
        setLoading(false)
        getAdvertList({
          freightStatusId: filter,
          ...(companyFilter && {companyId: companyFilter}),
          pageMIndex: pageMIndex,
        })
        toast.success(intl.formatMessage({id: 'Admin.Adverts.updateMessage'}), {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }
  /** Advert Api call End **/

  /** Trailer api call Start **/
  const getTrailerTypes = () => {
    advertServ
      .trailerTypes()
      .then((resp: any) => {
        setTrailerTypes(resp?.data)
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const getFloorTypes = () => {
    advertServ
      .floorTypes()
      .then((resp: any) => {
        setFloorTypes(resp?.data)
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  /** Transport api call Start **/
  const getTransportTypes = () => {
    advertServ
      .carrierTransportTypesList()
      .then((resp) => {
        setTransportTypes(resp?.data)
      })
      .catch((e: any) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
        setLoading(false)
      })
  }
  /** Transport api call End **/

  const getTrailerSpecifications = (trailerId: number) => {
    advertServ
      .trailerSpecifications(trailerId)
      .then((resp: any) => {
        setTrailerSpecifications(resp?.data)
        setLoading(false)
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }
  /** Trailer api call End **/

  /** Freights api call Start **/
  const getFreightTypes = () => {
    advertServ
      .freightTypes()
      .then((resp: any) => {
        setFreightTypes(resp?.data)
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }
  const getFreightLoadTypes = () => {
    advertServ
      .freightLoadingTypes()
      .then((resp: any) => {
        setFreightLoadTypes(resp?.data)
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }
  const getFreightPackageTypes = () => {
    advertServ
      .freightPackageTypes()
      .then((resp: any) => {
        setFreightPackageTypes(resp?.data)
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }
  /** Freights api call End **/

  /** Currencies api call Start **/
  const getCurrencyList = () => {
    advertServ
      .currencyList()
      .then((resp: any) => {
        setCurrencyList(resp?.data)
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }
  /** Currencies api call End **/

  /** Gtip api call Start **/
  const getGtip = (searchText?: string, pageCount: number = 20) => {
    advertServ
      .gtipList(searchText ?? '', pageCount)
      .then((resp: any) => {
        setGtipList(resp?.data)
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }
  /** Gtip api call End **/

  /** Country and City List api call Start **/
  const getCountryList = () => {
    commonServ
      .countryList()
      .then((resp: any) => {
        setCountries(resp.data)
        setLoading(false)
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }
  /** Country and City List api call End **/

  const getCityList = (countryCode: string) => {
    commonServ
      .cityList(countryCode)
      .then((resp: any) => {
        setCities(resp.data)
        setLoading(false)
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const approveAdvert = (freightId: number) => {
    adminAdvertServ
      .approveAdvert(freightId)
      .then((resp: any) => {
        toast.success(intl.formatMessage({id: 'Admin.Adverts.approveMessage'}), {
          position: toast.POSITION.TOP_RIGHT,
        })
        getAdvertList({freightStatusId: filter})
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const rejectAdvert = (freightId: number, desc: string) => {
    adminAdvertServ
      .rejectAdvert(freightId, desc)
      .then((resp: any) => {
        toast.warning(intl.formatMessage({id: 'Admin.Adverts.rejectMessage'}), {
          position: toast.POSITION.TOP_RIGHT,
        })
        getAdvertList({freightStatusId: filter})
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const getStatusTypes = () => {
    adminAdvertServ
      .getStatusTypes()
      .then((resp: any) => {
        setStatusList(
          [
            {
              freightStatusId: 'all',
              description: intl.formatMessage({id: 'Admin.Adverts.all'}),
            },
          ].concat(resp?.data)
        )
        setLoading(false)
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const getAdvertOwnerCompanies = (pageIndex: number = 0, searchText?: string) => {
    adminAdvertServ
      .getAdvertOwnerCompanies(searchText ?? '', pageIndex ?? 0)
      .then((resp: any) => {
        setSearchCompanyScrollAPICalled(false)
        if (pageIndex === 0)
          setOwnerCompanyList(
            [
              {
                companyId: 'all',
                name: intl.formatMessage({id: 'Admin.Adverts.all'}),
              },
            ].concat(resp?.data?.filter((item: any) => item?.name))
          )
        else setOwnerCompanyList((item: any) => [...item, ...resp?.data])
        setLoading(false)
      })
      .catch((e: any) => {
        setSearchCompanyScrollAPICalled(false)
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const createProposal = (
    price: number,
    currency: number,
    explanation: string,
    proposalScopeId: number
  ) => {
    advertServ
      .createProposal(price, currency, explanation, selectedFreightId, proposalScopeId)
      .then(() => {
        setShowProposalPopup(false)
        getAdvertList({freightStatusId: filter})
        toast.success(intl.formatMessage({id: 'Admin.Adverts.createProposal.create.success'}))
      })
      .catch((e: any) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
        setLoading(false)
      })
  }

  const getProposalTableList = (freightId: number, page: number) => {
    advertServ
      .getFreightProposalList(freightId, page)
      .then((resp: any) => {
        setLoading(false)
        setIsNextProposalPage(false)
        setSelectedProposalTableList(resp.data)
      })
      .catch((e: any) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
        setLoading(false)
        setIsNextProposalPage(false)
      })
  }

  const handleDeleteFreightProposal = (freightProposalId: number) => {
    advertServ
      .deleteFreigthProposal(freightProposalId)
      .then(() => {
        toast.success(intl.formatMessage({id: 'Admin.Adverts.createProposal.delete.success'}))
        setLoading(false)
        getProposalTableList(selectedFreightId, proposalListPageMIndex)
        getAdvertList({freightStatusId: filter})
      })
      .catch((e: any) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
        setLoading(false)
      })
  }

  const handleGetSelectedFreightProposal = (freightProposalId: number) => {
    advertServ
      .getSelectedFreightProposal(freightProposalId)
      .then((resp: any) => {
        setLoading(false)
        setSelectedEditProposal((item: any) => ({
          ...item,
          price: resp?.data?.price,
          currency: resp?.data?.currency?.currencyId,
          proposalScope: resp?.data?.proposalScope?.proposalScopeId,
          explanation: resp?.data?.explanation,
        }))
      })
      .catch((e: any) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
        setLoading(false)
      })
  }

  const handleUpdateProposal = (data: any) => {
    advertServ
      .updateFreightProposal(data)
      .then(() => {
        toast.success(intl.formatMessage({id: 'Admin.Adverts.updateProposal.success'}))
        setLoading(false)
        getProposalTableList(selectedFreightId, proposalListPageMIndex)
      })
      .catch((e: any) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
        setLoading(false)
      })
  }

  const getApiCalls = () => {
    setLoading(true)
    getTrailerTypes()
    setLoading(true)
    getFloorTypes()
    setLoading(true)
    getTransportTypes()
    setLoading(true)
    getFreightTypes()
    setLoading(true)
    getAddressList()
    setLoading(true)
    getFreightLoadTypes()
    setLoading(true)
    getFreightPackageTypes()
    setLoading(true)
    getCurrencyList()
    setLoading(true)
    getGtip()
    hadnleGetTransporterGroupList()
    getFromCountryList()
    getToCountryList()
    getAllTransportTypes()
    getAllFreightContentTypeList()
    setLoading(true)
    getCountryList()
    setLoading(true)
    getStatusTypes()
    setLoading(true)
    getAdvertOwnerCompanies()
    getProposalScopeList()
  }

  const handleProposalPaginationChange = (page: number) => {
    if (!isNextProposalPage) {
      getProposalTableList(selectedFreightId, page)
      setProposalListPageMIndex(page)
    }
    setIsNextProposalPage(true)
  }

  useEffect(() => {
    getApiCalls()
    setFilterCounter(1)
  }, [])

  useEffect(() => {
    if ((filter && filterCounter > 0) || query?.get('filter') === null) {
      setLoading(true)
      getAdvertList({freightStatusId: filter, ...(companyFilter && {companyId: companyFilter})})
    }
  }, [filter])

  useEffect(() => {
    if (companyFilter && companyFilterCounter > 0) {
      setLoading(true)
      getAdvertList({companyId: companyFilter, ...(filter && {freightStatusId: filter})})
    }
    setCompanyFilterCounter(1)
  }, [companyFilter])

  return (
    <>
      {loading && <Loading />}
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'Freight.myAdvert.title'})}</PageTitle>
      <div className='row mb-5'>
        {!showPartialFreightList && !showFilterPanel && (
          <div className='col-md-3 justify-content-end mb-3'>
            <button
              className={'btn btn-primary'}
              onClick={() => {
                setShowPartialFreightList(true)
              }}
            >
              {intl.formatMessage({id: 'Carrier.freight.partialFreight.publish'})}
            </button>
          </div>
        )}
        <span
          className={`menu-icon d-flex justify-content-end ${!showFilterPanel ? 'col-md-9' : ''}`}
        >
          <div
            className={`btn btn-icon btn-active-light-primary btn-custom ${
              showFilterPanel ? 'menu-button-active' : ''
            }`}
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='bottom'
            onClick={() => setShowFilterPanel(!showFilterPanel)}
          >
            <KTSVG path='/media/filtering.svg' className='svg-icon-2x' />
          </div>
        </span>
      </div>

      {showFilterPanel && (
        <div className={'d-flex align-items-center'}>
          <div className='mb-10 col-md-3 ' style={{marginRight: '15px'}}>
            <h6 className={'mb-3'}>{intl.formatMessage({id: 'Admin.Adverts.filterByStatus'})}</h6>
            <div className={''}>
              <Dropbox
                hideArrow
                customClass={'form-select'}
                placeholder={intl.formatMessage({id: 'Admin.Containers.statusFilterPlaceholder'})}
                customSearchValue={''}
                customOnSearch={(e: any) => {}}
                disabledSearch
                onScroll={() => {}}
                setScrollCalled={() => {}}
                currentPageIndex={0}
                menuList={
                  statusList &&
                  statusList.map((item: any) => {
                    return {
                      id: item?.freightStatusId,
                      title: item?.description,
                    }
                  })
                }
                selectedItemData={(item: any) => {
                  if (item?.id) {
                    setFilter(item?.id)
                  }
                }}
              />
            </div>
          </div>
          <div className='mb-10 col-md-3'>
            <h6 className={'mb-3'}>{intl.formatMessage({id: 'Admin.Adverts.filterByCompany'})}</h6>
            <div className={''}>
              <Dropbox
                hideArrow
                customClass={'form-select'}
                placeholder={intl.formatMessage({id: 'Admin.Adverts.searchCompany'})}
                customSearchValue={companyCustomSearch}
                customOnSearch={(e: any) => {
                  setCurrentPageIndex(0)
                  setCompanyCustomSearch(e.target.value)
                  getAdvertOwnerCompanies(0, e.target.value)
                }}
                onScroll={(val: any) => {
                  setCurrentPageIndex(val)
                  getAdvertOwnerCompanies(val, companyCustomSearch)
                }}
                setScrollCalled={() => {
                  setSearchCompanyScrollAPICalled(true)
                }}
                scrollAPIResponse={searchCompanyScrollAPICalled}
                currentPageIndex={currentPageIndex}
                menuList={
                  ownerCompanyList &&
                  ownerCompanyList
                    .filter(function ({companyId}: any) {
                      // @ts-ignore
                      return !this[companyId] && (this[companyId] = companyId)
                    }, {})
                    ?.map((item: any) => {
                      return {id: item?.companyId, title: item?.name}
                    })
                }
                defaultSelected={{id: companyFilter}}
                defaultClearItem={{
                  id: 'all',
                  title: intl.formatMessage({id: 'Admin.Adverts.all'}),
                }}
                selectedItemData={(item: any) => {
                  if (item?.id) {
                    setCompanyFilter(item?.id)
                  }
                }}
              />
            </div>
          </div>
          {!showPartialFreightList && (
            <div className='d-flex justify-content-end w-100 mb-3'>
              <button
                className={'btn btn-primary'}
                onClick={() => {
                  setShowPartialFreightList(true)
                }}
              >
                {intl.formatMessage({id: 'Carrier.freight.partialFreight.publish'})}
              </button>
            </div>
          )}
        </div>
      )}
      <div>
        {!advertList.length && (
          <div className='alert alert-warning mb-5 fw-bolder' role='alert'>
            {intl.formatMessage({id: 'Admin.Adverts.noAnyData'})}
          </div>
        )}
        <DataTableAdvanced
          columns={[
            {
              Header: 'ID',
              accessor: 'id',
            },
            {
              Header: '',
              accessor: 'freightIcon',
              isAction: true,
              Cell: ({row}: any) => (
                <>
                  {getFreightIcon[row?.values?.freightIcon] && (
                    <img
                      src={getFreightIcon[row?.values?.freightIcon]}
                      className='containerIconWrap'
                      alt='frieghtIcon'
                    />
                  )}
                </>
              ),
            },
            {
              Header: '',
              accessor: 'publishFreightList',
              isAction: true,
              hidden: showPartialFreightList ? false : true,
              Cell: ({row}: any) => (
                <button
                  className={'btn btn-primary'}
                  onClick={() => {
                    setLoading(true)
                    setSelectedPublishFreightList((publishItem: any) =>
                      publishItem.concat(row?.values?.publishFreightList)
                    )
                    getAdvertList({
                      freightStatusId: filter,
                      pageMIndex,
                      willBeExcludedFreightIdList: selectedPartialPublicFreightList.concat(
                        row?.values?.publishFreightList?.freightId
                      ),
                    })
                    setSelectedPartialPublicFreightList(
                      selectedPartialPublicFreightList.concat(
                        row?.values?.publishFreightList?.freightId
                      )
                    )
                    getSelectedFreightDetailed(row?.values?.id?.split('.')[0])
                  }}
                >
                  {intl.formatMessage({id: 'Carrier.freight.partialFreight.add'})}
                </button>
              ),
            },
            {
              Header: intl.formatMessage({id: 'Carrier.myAdvert.table.description'}),
              accessor: 'title',
            },
            {
              Header: intl.formatMessage({id: 'Admin.Adverts.table_company'}),
              accessor: 'company',
            },
            {
              Header: intl.formatMessage({id: 'Carrier.myAdvert.table.targetCost'}),
              accessor: 'value',
              cellStyle: {
                textAlign: 'end',
              },
            },
            {
              Header: intl.formatMessage({id: 'Admin.Adverts.table_currency'}),
              accessor: 'currencyCode',
            },
            {
              Header: intl.formatMessage({id: 'Admin.Adverts.table_from'}),
              accessor: 'fromLocation',
            },
            {
              Header: intl.formatMessage({id: 'Admin.Adverts.table_to'}),
              accessor: 'toLocation',
            },
            {
              Header: intl.formatMessage({id: 'Admin.Adverts.table_departureDate'}),
              accessor: 'departureDate',
            },
            {
              Header: intl.formatMessage({id: 'Admin.Adverts.table_arriveDate'}),
              accessor: 'arriveDate',
            },
            {
              Header: intl.formatMessage({id: 'Admin.Adverts.table_status'}),
              accessor: 'status',
            },
            ,
            {
              Header: 'statusCode',
              accessor: 'statusCode',
              hidden: true,
            },
            {
              Header: 'hiddenButtons',
              accessor: 'hiddenButtons',
              hidden: true,
            },
            {
              Header: intl.formatMessage({id: 'Admin.Adverts.table_proposal_count'}),
              accessor: 'proposalCount',
              isAction: true,
              Cell: ({row}: any) => (
                <div
                  className={
                    row?.values?.proposalCount !== '-' && row?.values?.proposalCount !== 0
                      ? 'proposal-wrap'
                      : ''
                  }
                  onClick={() => {
                    if (row?.values?.proposalCount !== '-' && row?.values?.proposalCount !== 0) {
                      setLoading(true)
                      const num = row?.values?.id?.split('.')[0]
                      getProposalTableList(parseInt(num), proposalListPageMIndex)
                      setSelectedFreightId(parseInt(num))
                      setShowProposalTablePopup(true)
                    }
                  }}
                >
                  {row?.values?.proposalCount}
                </div>
              ),
            },
          ]}
          data={
            advertList &&
            advertList.map((item: any) => {
              return {
                id: item?.freightId + '.' + item?.freightStatus?.freightStatusId,
                title: item?.freightContentType?.description,
                company: item?.company?.name ?? '-',
                value:
                  item?.targetCost &&
                  priceConversion(item?.targetCost, item?.targetCostCurrency?.currencyCode),
                currencyCode: item?.targetCostCurrency?.currencyCode,
                freightIcon: item?.freightType?.freightTypeId,
                fromLocation:
                  item?.fromAddress?.city?.name + ' / ' + item?.fromAddress?.country?.name,
                toLocation: item?.toAddress?.city?.name + ' / ' + item?.toAddress?.country?.name,
                departureDate:
                  item?.plannedDepartureDate !== null &&
                  getDateTime(item?.plannedDepartureDate, intl.locale),
                arriveDate:
                  item?.plannedArrivalDate !== null &&
                  getDateTime(item?.plannedArrivalDate, intl.locale),
                status: item?.freightStatus?.description ?? '-',
                statusCode: item?.freightStatus?.code ?? '-',
                publishFreightList: item,
                hiddenButtons: {
                  editBtn:
                    item?.freightStatus?.freightStatusId !== 3 &&
                    item?.freightStatus?.freightStatusId !== 10,
                  advertApprove: item?.freightStatus?.freightStatusId !== 1,
                  advertReject: item?.freightStatus?.freightStatusId !== 1,
                },
                proposalCount: item?.proposalCount ?? '-',
              }
            })
          }
          editableRows={['']}
          editBtn={(row: any) => {
            let num = row?.values?.id?.split('.')[0]
            setLoading(true)
            getAdvertDetailedList(parseInt(num))
            setSelectedFreightId(parseInt(num))
            setShowUpdateScreen(true)
          }}
          showDetailBtn={(row: any) => {
            let num = row?.values?.id?.split('.')[0]
            setLoading(true)
            getAdvertDetailedList(parseInt(num))
            setSelectedFreightId(parseInt(num))
            setShowDetailsScreen(true)
          }}
          proposalBtn={(row: any) => {
            let num = row?.values?.id?.split('.')[0]
            setSelectedFreightId(parseInt(num))
            setShowProposalPopup(true)
          }}
          advertApprove={(row: any) => {
            let num = row?.values?.id?.split('.')[0]
            setSelectedFreightId(parseInt(num))
            setShowAdvertApprovePopup(true)
          }}
          advertReject={(row: any) => {
            let num = row?.values?.id?.split('.')[0]
            setSelectedFreightId(parseInt(num))
            setShowAdvertRejectPopup(true)
          }}
          hiddenButtons={{
            editBtn: filter !== '3' && filter !== '10',
            advertApprove: filter !== '1',
            advertReject: filter !== '1',
          }}
          showFile={(row: any) => {
            let num = row?.values?.id?.split('.')[0]
            setSelectedFreightId(parseInt(num))
            setShowPublishFreight(true)
          }}
          showFileText={intl.formatMessage({id: 'DataTableAdvanced.buttons.publish'})}
          setPageMIndex={onPaginationChange}
          pageMIndex={pageMIndex}
        />
      </div>

      {showPublishFreight && (
        <FreightPublishPopup
          publishDate={publishDate}
          publishNonFormatDate={publishNonFormatDate}
          setPublishNonFormatDate={setPublishNonFormatDate}
          setPublishDate={setPublishDate}
          transporterGroupUserList={transporterGroupUserList}
          selectedPublishFreightTransporterGroup={selectedPublishFreightTransporterGroup}
          setSelectedPublishFreightTransporterGroup={setSelectedPublishFreightTransporterGroup}
          selectedFreightId={selectedFreightId}
          onClose={() => {
            resetField()
            setShowPublishFreight(false)
          }}
          setPublicFreightExchange={setPublicFreightExchange}
          publicFreightExchange={publicFreightExchange}
          setPrivateFreightExchange={setPrivateFreightExchange}
          privateFreightExchange={privateFreightExchange}
          publishFreight={(data: any) => {
            setLoading(true)
            handlePublishFreight({
              ...data,
              transporterGroupList: selectedTransporterGroup.map((item: any) => ({
                transporterGroupId: item.id,
              })),
            })
          }}
          setDescription={setDescription}
          description={description}
          targetCost={targetCost}
          setTargetCost={setTargetCost}
          selectedCurrency={selectedCurrency}
          selectedCurrencyName={selectedCurrencyName}
          currencyList={currencyList}
          setSelectedCurrency={setSelectedCurrency}
          setSelectedCurrencyName={setSelectedCurrencyName}
        />
      )}

      {showPartialFreightList && (
        <PublishPartialFreightsList
          advertDetailedList={selectedPublishFreightList}
          onClose={() => {
            resetField()
            getAdvertList({freightStatusId: filter, pageMIndex})
            setShowPartialFreightList(false)
          }}
          selectedFreightDetails={selectedFreightDetails}
          transportTypeList={allTransportTypes}
          freightTypes={allFreightTypes}
          freightContentTypeList={allFreightContentTypes}
          transporterGroupUserList={transporterGroupUserList}
          currencyList={currencyList}
          fromCountryList={fromCountryList}
          toCountryList={toCountryList}
          fromCityList={fromCityList}
          toCityList={toCityList}
          fromCountryId={fromCountryId}
          toCountryId={toCountryId}
          setFromCountryId={setFromCountryId}
          setFromCountryName={setFromCountryName}
          fromCountryName={fromCountryName}
          setToCountryId={setToCountryId}
          setToCountryName={setToCountryName}
          toCountryName={toCountryName}
          setFromCityId={setFromCityId}
          fromCityId={fromCityId}
          fromCityName={fromCityName}
          setFromCityName={setFromCityName}
          setToCityId={setToCityId}
          toCityId={toCityId}
          setToCityName={setToCityName}
          toCityName={toCityName}
          transportTypeSearch={transportTypeSearch}
          setTransportTypeSearch={setTransportTypeSearch}
          transportTypePageIndex={transportTypePageIndex}
          setTransportTypePageIndex={setTransportTypePageIndex}
          transportTypeId={transportTypeId}
          setTransportTypeName={setTransportTypeName}
          transportTypeName={transportTypeName}
          setTransportTypeId={(itemId: any) => {
            setTransportTypeId(itemId)
            getAdvertList({
              freightStatusId: filter,
              pageMIndex,
              willBeExcludedFreightIdList: selectedPartialPublicFreightList,
              transportTypeId: itemId,
              freightTypeId: selectedFreightType,
            })
          }}
          selectedFreightType={selectedFreightType}
          setSelectedFreightType={(itemId: any) => {
            setSelectedFreightType(itemId)
            getAdvertList({
              freightStatusId: filter,
              pageMIndex,
              willBeExcludedFreightIdList: selectedPartialPublicFreightList,
              transportTypeId,
              freightTypeId: itemId,
            })
          }}
          freightContentTypeSearch={freightContentTypeSearch}
          setFreightContentTypePageIndex={setFreightContentTypePageIndex}
          setFreightContentTypeSearch={setFreightContentTypeSearch}
          freightContentTypePageIndex={freightContentTypePageIndex}
          setFreightContentTypeId={setFreightContentTypeId}
          freightContentTypeId={freightContentTypeId}
          freightContentTypeName={freightContentTypeName}
          setFreightContentTypeName={setFreightContentTypeName}
          setDescription={setDescription}
          description={description}
          setPrivateFreightExchange={setPrivateFreightExchange}
          privateFreightExchange={privateFreightExchange}
          publicFreightExchange={publicFreightExchange}
          setPublicFreightExchange={setPublicFreightExchange}
          selectedTransporterGroup={selectedTransporterGroup}
          setSelectedTransporterGroup={setSelectedTransporterGroup}
          targetCost={targetCost}
          setTargetCost={setTargetCost}
          setSelectedCurrency={setSelectedCurrency}
          selectedCurrency={selectedCurrency}
          selectedCurrencyName={selectedCurrencyName}
          setSelectedCurrencyName={setSelectedCurrencyName}
          setLoadIssueDate={setLoadIssueDate}
          loadIssueDate={loadIssueDate}
          setIssueDate={setIssueDate}
          issueDate={issueDate}
          plannedDepartureDate={plannedDepartureDate}
          setLoadPlannedDepartureDate={setLoadPlannedDepartureDate}
          setPlannedDepartureDate={setPlannedDepartureDate}
          loadPannedDepartureDate={loadPannedDepartureDate}
          fromCountrySearch={fromCountrySearch}
          setFromCountrySearch={setFromCountrySearch}
          fromCitySearch={fromCitySearch}
          toCountrySearch={toCountrySearch}
          toCitySearch={toCitySearch}
          setFromCitySearch={setFromCitySearch}
          setToCountrySearch={setToCountrySearch}
          setToCitySearch={setToCitySearch}
          setDependentToCity={setDependentToCity}
          dependentToCity={dependentToCity}
          dependentFromCity={dependentFromCity}
          setDependentFromCity={setDependentFromCity}
          setSelectedPublishFreightList={setSelectedPublishFreightList}
          setSelectedPartialPublicFreightList={(selectedId: any) =>
            setSelectedPartialPublicFreightList(
              selectedPartialPublicFreightList.filter((dataItem: any) => dataItem !== selectedId)
            )
          }
          getAdvertList={(selectedId: any) =>
            getAdvertList({
              freightStatusId: filter,
              pageMIndex,
              willBeExcludedFreightIdList: selectedPartialPublicFreightList.filter(
                (dataItem: any) => dataItem !== selectedId
              ),
            })
          }
          createAndPublishPartialContainer={() => {
            setLoading(true)
            createAndPublishPartialContainer({
              transportType: {
                transportTypeId: transportTypeId,
              },
              freightType: {
                freightTypeId: 2,
              },
              freightContentType: {
                freightContentTypeId: freightContentTypeId,
              },
              description: description,
              freightList: selectedPartialPublicFreightList.map((item: any) => ({freightId: item})),
              publishToPublicFreightExchange: publicFreightExchange ? 'Y' : 'N',
              publishToPrivateFreightExchange: privateFreightExchange ? 'Y' : 'N',
              transporterGroupList: selectedTransporterGroup.map((item: any) => ({
                transporterGroupId: item.id,
              })),
              fromLocation: {
                country: {
                  code: fromCountryId === 'all' ? '' : null,
                },
                city: {
                  cityId: fromCityId === 'all' ? '' : null,
                },
              },
              toLocation: {
                country: {
                  code: toCountryId === 'all' ? '' : null,
                },
                city: {
                  cityId: toCityId === 'all' ? '' : null,
                },
              },
              price: targetCost,
              priceCurrency: {
                currencyId: selectedCurrency,
              },
              issueStartDate: !!issueDate ? Math.round(new Date(issueDate).getTime() / 1000) : null,
              plannedDepartureDate: !!plannedDepartureDate
                ? Math.round(new Date(plannedDepartureDate).getTime() / 1000)
                : null,
            })
          }}
        />
      )}

      {showDeletePopup && (
        <Modal
          closeBtnSettings={{
            show: true,
            onClick: () => setShowDeletePopup(false),
          }}
          customAll={
            <div className={''}>
              <h3 className={'mt-5 mb-15'}>{intl.formatMessage({id: 'Admin.Adverts.isDelete'})}</h3>
              <div className={'row'}>
                <div className={'col-md-6'}>
                  <button
                    className={'btn btn-danger w-100'}
                    onClick={() => {
                      setShowDeletePopup(false)
                    }}
                  >
                    {intl.formatMessage({id: 'Admin.Adverts.cancel'})}
                  </button>
                </div>
                <div
                  className={'col-md-6'}
                  onClick={() => {
                    setLoading(true)
                    deleteAdvert(selectedFreightId)
                    setShowDeletePopup(false)
                    setLoading(true)
                    getAdvertList()
                  }}
                >
                  <button className={'btn btn-success w-100'}>
                    {intl.formatMessage({id: 'Admin.Adverts.approve'})}
                  </button>
                </div>
              </div>
            </div>
          }
        />
      )}

      {showAdvertApprovePopup && (
        <Modal
          closeBtnSettings={{
            show: true,
            onClick: () => setShowAdvertApprovePopup(false),
          }}
          customAll={
            <div className={''}>
              <h3 className={'mt-5 mb-15'}>
                {intl.formatMessage({id: 'Admin.Adverts.isApprove'})}
              </h3>
              <div className={'row'}>
                <div className={'col-md-6'}>
                  <button
                    className={'btn btn-danger w-100'}
                    onClick={() => {
                      setShowAdvertApprovePopup(false)
                    }}
                  >
                    {intl.formatMessage({id: 'Admin.Adverts.cancel'})}
                  </button>
                </div>
                <div
                  className={'col-md-6'}
                  onClick={() => {
                    setLoading(true)
                    approveAdvert(selectedFreightId)
                    setShowAdvertApprovePopup(false)
                  }}
                >
                  <button className={'btn btn-success w-100'}>
                    {intl.formatMessage({id: 'Admin.Adverts.approve'})}
                  </button>
                </div>
              </div>
            </div>
          }
        />
      )}

      {showAdvertRejectPopup && (
        <Modal
          closeBtnSettings={{
            show: true,
            onClick: () => setShowAdvertRejectPopup(false),
          }}
          customAll={
            <div className={''}>
              <h3 className={'mt-5 mb-5'}>{intl.formatMessage({id: 'Admin.Adverts.isReject'})}</h3>
              <div className={'mb-10'}>
                <label htmlFor='rejText' className={'text-muted'}>
                  {intl.formatMessage({id: 'Admin.Adverts.rejectReason'})}
                </label>
                <textarea
                  onChange={(e: any) => setRejectText(e.target.value)}
                  value={rejectText}
                  id={'rejText'}
                  className={'form-control'}
                  placeholder={intl.formatMessage({id: 'Admin.Adverts.rejectReason'})}
                  rows={7}
                  style={{resize: 'none'}}
                ></textarea>
              </div>
              <div className={'row'}>
                <div className={'col-md-6'}>
                  <button
                    className={'btn btn-danger w-100'}
                    onClick={() => {
                      setShowAdvertRejectPopup(false)
                    }}
                  >
                    {intl.formatMessage({id: 'Admin.Adverts.cancel'})}
                  </button>
                </div>
                <div
                  className={'col-md-6'}
                  onClick={() => {
                    setLoading(true)
                    rejectAdvert(selectedFreightId, rejectText)
                    setShowAdvertRejectPopup(false)
                  }}
                >
                  <button disabled={!rejectText} className={'btn btn-success w-100'}>
                    {intl.formatMessage({id: 'Admin.Adverts.approve'})}
                  </button>
                </div>
              </div>
            </div>
          }
        />
      )}

      {showDetailsScreen && (
        <ShowDetails currentData={advertDetailedList} onClose={() => setShowDetailsScreen(false)} />
      )}

      {showUpdateScreen && (
        <EditPreferences
          currentData={advertDetailedList}
          editedData={(data: any) => {
            data && updateAdvert(data)
            data && setShowUpdateScreen(false)
          }}
          selectedTrailerType={(val: any) => {
            val && getTrailerSpecifications(val)
          }}
          trailerTypes={trailerTypes}
          trailerSpecifications={trailerSpecifications}
          floorTypes={floorTypes}
          countries={countries}
          cities={cities}
          addressList={addressList}
          currencyList={currencyList}
          transportTypes={transportTypes}
          freightTypes={freightTypes}
          freightPackageTypes={freightPackageTypes}
          freightLoadTypes={freightLoadTypes}
          gtipList={gtipList}
          setLoading={setLoading}
          onClose={() => setShowUpdateScreen(false)}
        />
      )}

      {showProposalPopup && (
        <CreateProposal
          currencyList={currencyList}
          proposalScopeList={proposalScopeList}
          onClose={() => setShowProposalPopup(false)}
          setLoading={setLoading}
          handleCreateProposal={(data: any) => {
            createProposal(data.price, data.currency, data.explanation, data.proposalScopeId)
          }}
        />
      )}

      {showProposalTablePopup && (
        <ShowProposalTable
          currencyList={currencyList}
          proposalScopeList={proposalScopeList}
          getSelectedFreightProposal={(freightProposalId: any) =>
            handleGetSelectedFreightProposal(freightProposalId)
          }
          selectedProposalTableList={selectedProposalTableList}
          setLoading={setLoading}
          onClose={() => setShowProposalTablePopup(false)}
          deleteFreigthProposal={(freightProposalId: any) =>
            handleDeleteFreightProposal(freightProposalId)
          }
          selectedEditProposal={selectedEditProposal}
          setSelectedEditProposal={setSelectedEditProposal}
          handleUpdateProposal={handleUpdateProposal}
          proposalListPageMIndex={proposalListPageMIndex}
          onPaginationChange={handleProposalPaginationChange}
        />
      )}
    </>
  )
}
export {Adverts}
