import React from 'react'
import {useIntl} from 'react-intl'

import RelatedBlogs from '../RelatedBlogs'

import Seo from '../../utils/seo'
import {toAbsoluteUrl} from '../../_metronic/helpers'

import './FreightExchangeImproveServices.scss'

const siteNavigationStructuredData: any = {
  '@context': 'http://schema.org',
  '@type': 'BlogPosting',
  name: 'blog post',
  url: 'https://www.rivalog.com/blog/speed-and-efficiency-advantages-of-air',
  publisher: {
    '@type': 'Organization',
    name: 'Rivalog',
    logo: 'https://www.rivalog.com/media/logo.png',
  },
}

const SpeedEfficiencyair = () => {
  const intl = useIntl()
  const date = new Date()
  return (
    <div style={{overflow: 'auto'}}>
      <div style={{position: 'relative'}}>
        <div
          className='bg-photo-overlay'
          style={{backgroundImage: 'url("/media/air_freight_shipping.png")'}}
        ></div>
        <Seo
          data={{
            title: intl.formatMessage({id: 'Seo.airFrightShipping.title'}),
            metaDescription: intl.formatMessage({id: 'Seo.airFreightShipping.description'}),
            href: 'https://www.rivalog.com/blog/speed-and-efficiency-advantages-of-air',
          }}
        >
          <script type='application/ld+json'>{JSON.stringify(siteNavigationStructuredData)}</script>
        </Seo>
        <div className='page-body'>
          <div className='centered-wrap-main'>
            <header className='centered-top-container animating' role='banner'>
              <div className='centered-top'>
                <div className='blog-name container'>
                  <div className='container section' id='header'>
                    <div className='widget Header' data-version='2' id='Header1'>
                      <div className='header-widget'>
                        <div>
                          <h1>
                            <a href='https://rivalog.blogspot.com/'>Rivalog</a>
                          </h1>
                        </div>
                        <p></p>
                      </div>
                    </div>
                  </div>
                  <nav role='navigation'>
                    <div className='clearboth no-items section' id='page_list_top'></div>
                  </nav>
                </div>
              </div>
            </header>

            <div>
              <main className='centered-bottom' role='main'>
                <div className='main section'>
                  <div className='widget'>
                    <article className='post-outer-container'>
                      <div className='post-outer'>
                        <div className='post'>
                          <h3 className='post-title entry-title'>
                            {intl.formatMessage({id: 'blog.speedEfficAir.title'})}
                          </h3>
                          <div className='post-header'>
                            <span className='byline'>{date.toDateString()}</span>
                          </div>
                          <div className='post-body'>
                            <p style={{textAlign: 'left'}}>
                              {intl.formatMessage({id: 'blog.speedEfficAir.label'})}{' '}
                              <a href='https://www.rivalog.com/'>
                                <b>{intl.formatMessage({id: 'blog.speedEfficAir.label1'})}</b>
                              </a>{' '}
                              {intl.formatMessage({id: 'blog.speedEfficAir.label2'})}
                            </p>

                            <p className='point-content-wrap'>
                              {intl.formatMessage({id: 'blog.speedEfficAir.label3'})}
                            </p>

                            <h2 className='img-road-wrap'>
                              <div className='separator-wrap'>
                                <img
                                  className='improved-img-wrap'
                                  src={toAbsoluteUrl('/media/air_freight_shipping.png')}
                                  alt='air freight shipping'
                                />
                              </div>
                              <br />
                              {intl.formatMessage({id: 'blog.speedEfficAir.label4'})}
                            </h2>

                            <p style={{textAlign: 'left'}}>
                              {intl.formatMessage({id: 'blog.speedEfficAir.label5'})}
                            </p>

                            <p className='point-content-wrap'>
                              {intl.formatMessage({id: 'blog.speedEfficAir.label6'})}
                            </p>

                            <p className='point-content-wrap'>
                              {intl.formatMessage({id: 'blog.speedEfficAir.label7'})}
                            </p>

                            <h2 className='img-road-wrap'>
                              {intl.formatMessage({id: 'blog.speedEfficAir.label8'})}
                            </h2>

                            <p style={{textAlign: 'left'}}>
                              {intl.formatMessage({id: 'blog.speedEfficAir.label9'})}
                            </p>

                            <p className='point-content-wrap'>
                              {intl.formatMessage({id: 'blog.speedEfficAir.label10'})}
                            </p>

                            <p className='point-content-wrap'>
                              {intl.formatMessage({id: 'blog.speedEfficAir.label11'})}
                            </p>

                            <h2 className='img-road-wrap'>
                              {intl.formatMessage({id: 'blog.speedEfficAir.label12'})}
                            </h2>

                            <p style={{textAlign: 'left'}}>
                              {intl.formatMessage({id: 'blog.speedEfficAir.label13'})}
                            </p>

                            <p className='point-content-wrap'>
                              {intl.formatMessage({id: 'blog.speedEfficAir.label14'})}
                            </p>

                            <p className='point-content-wrap'>
                              {intl.formatMessage({id: 'blog.speedEfficAir.label15'})}
                            </p>

                            <h2 className='img-road-wrap'>
                              {intl.formatMessage({id: 'blog.speedEfficAir.label16'})}
                            </h2>

                            <p style={{textAlign: 'left'}}>
                              {intl.formatMessage({id: 'blog.speedEfficAir.label17'})}
                            </p>

                            <p className='point-content-wrap'>
                              {intl.formatMessage({id: 'blog.speedEfficAir.label18'})}
                            </p>

                            <p className='point-content-wrap'>
                              {intl.formatMessage({id: 'blog.speedEfficAir.label19'})}
                            </p>

                            <h2 className='img-road-wrap'>
                              {intl.formatMessage({id: 'blog.speedEfficAir.label20'})}
                            </h2>

                            <p style={{textAlign: 'left'}}>
                              {intl.formatMessage({id: 'blog.speedEfficAir.label21'})}
                            </p>

                            <p className='point-content-wrap'>
                              {intl.formatMessage({id: 'blog.speedEfficAir.label22'})}
                            </p>

                            <p className='point-content-wrap'>
                              {intl.formatMessage({id: 'blog.speedEfficAir.label23'})}
                            </p>
                          </div>
                        </div>
                      </div>
                    </article>
                  </div>
                </div>
              </main>
            </div>
          </div>
        </div>
      </div>

      <RelatedBlogs url='/blog/speed-and-efficiency-advantages-of-air' />
    </div>
  )
}

export default SpeedEfficiencyair
