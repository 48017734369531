import React, {useEffect} from 'react'
import {useIntl} from 'react-intl'

import {KTSVG} from '../../../../../_metronic/helpers'

import RightLayoutPopup from '../../../../Components/RightLayoutPopup'

import useScrollLock from '../../../../../utils/useScrollLock'

const ShowdriverDetail = ({
  setUpdatedDriver,
  updatedDriver,
  driverMetadata,
  setLoading,
  downloadFiles,
  onClose,
}: any) => {
  const intl = useIntl()
  const {scrollLock} = useScrollLock()
  useEffect(() => {
    scrollLock()
  }, [])

  return (
    <RightLayoutPopup
      title={intl.formatMessage({id: 'Carrier.drivers.details.driverDetails'})}
      className='show-driver-right-side-wrap'
      isCloseIcon
      closeOnClick={() => onClose()}
    >
      <div className={'row'}>
        <div className={'col-xl-6'}>
          <div className='fv-row mb-10'>
            <h6 className={'text-muted'}>
              {intl.formatMessage({id: 'Carrier.drivers.details.name'})}
            </h6>
            <b className={'fs-5'}>{updatedDriver.name}</b>
          </div>
          <div className='fv-row mb-10'>
            <h6 className={'text-muted'}>
              {intl.formatMessage({id: 'Carrier.drivers.details.lastName'})}
            </h6>
            <b className={'fs-5'}>{updatedDriver.surname}</b>
          </div>
          <div className={'row'}>
            <div className={'col-md-4'}>
              <h6 className={'text-muted'}>
                {intl.formatMessage({id: 'Carrier.drivers.details.phoneCode'})}
              </h6>
              <b className={'fs-5'}>{updatedDriver.phoneCode}</b>
            </div>
            <div className='fv-row col-md-8'>
              <h6 className={'text-muted'}>
                {intl.formatMessage({id: 'Carrier.drivers.details.phone'})}
              </h6>
              <b className={'fs-5'}>{updatedDriver.mobile.replace(updatedDriver?.phoneCode, '')}</b>
            </div>
          </div>
        </div>
        <div className={'col-xl-6 mb-10 px-3'}>
          <h3 className={'text-bold mb-5'}>
            {intl.formatMessage({id: 'Carrier.drivers.details.document'})}
          </h3>
          {!driverMetadata?.length && (
            <p>{intl.formatMessage({id: 'Carrier.drivers.details.noDocumentUpload'})}</p>
          )}
          {driverMetadata.map((item: any) => (
            <div key={item?.userFileId} className={'d-flex justify-content-between mb-4'}>
              <div>
                <div className='form-check mb-2'>
                  <input
                    disabled
                    className={
                      'form-check-input border border-' +
                      (item?.fileStatus?.fileStatusId !== 4 ? 'danger' : 'success')
                    }
                    type='checkbox'
                    checked={item?.fileStatus?.fileStatusId === 4}
                  />
                  <label className='form-check-label' htmlFor='flexCheckDefault'>
                    {item?.fileStatus?.fileStatusId === 4
                      ? intl.formatMessage({id: 'Carrier.drivers.details.approved'})
                      : intl.formatMessage({id: 'Carrier.drivers.details.notApproved'})}
                  </label>
                </div>
                <p className={'mb-3 text-truncate'}>{item?.fileType.description}</p>
              </div>
              <button
                title={intl.formatMessage({id: 'Carrier.drivers.details.download'})}
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 mb-1'
                onClick={() => {
                  setLoading(true)
                  downloadFiles(item?.url, item?.fileType.code, item.fileName)
                }}
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr091.svg'
                  className='svg-icon-3 mr-2 pr-2'
                />
              </button>
            </div>
          ))}
        </div>
      </div>
      <div className='text-center mt-5 row view-details-freight-popup freightPost-popup-container'>
        <div className={'col-md-6'}>
          <button
            onClick={onClose}
            type='button'
            id='kt_sign_up_submit'
            className='btn btn-lg btn-danger w-100 mb-5 '
          >
            {intl.formatMessage({id: 'Carrier.freightPosting.view.close'})}
          </button>
        </div>
      </div>
    </RightLayoutPopup>
  )
}

export default ShowdriverDetail
