/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {toast} from 'react-toastify'
import {useIntl} from 'react-intl'

import * as auth from '../redux/AuthRedux'

import {PasswordMeterComponent} from '../../../../_metronic/assets/ts/components'

import TabManagerComponent from '../../../Components/TabManager/TabManagerComponent'
import Loading from '../../../Components/Loading'
import SelectBox from '../../../Components/SelectBox/SelectBox'
import Modal from '../../../Components/Modal'
import PhoneInp from '../../../Components/PhoneInp/PhoneInp'

import useScrollLock from '../../../../utils/useScrollLock'
import {exceptThisSymbols} from '../../../../utils/common'

import {AuthService} from '../../../../services/MainServices/AuthService'
import {CommonService} from '../../../../services/FreightOperatorServices/CommonService'

import CompanyTypeSelector from '../../Admin/RegisterUsers/Popups/CompanyTypeSelector'

export function Registration({
  hideLogin,
  isSignUp = false,
}: {
  hideLogin?: boolean
  isSignUp?: boolean
}) {
  const intl = useIntl()
  const history = useHistory()
  const authService = new AuthService()
  const commonServ = new CommonService()
  const [showSelectorPopup, setShowSelectorPopup] = useState(false)
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const [countries, setCountries] = useState<any>([])
  const [companyTypes, setCompanyTypes] = useState<any>([])
  const [languages, setLanguages] = useState<any>([])
  const [defaultStep, setDefaultStep] = useState<any>(null)
  const [viewPassword, setViewPassword] = useState<boolean>(false)

  const {scrollUnlock} = useScrollLock()
  useEffect(() => {
    if (!showSelectorPopup) scrollUnlock()
  }, [showSelectorPopup])

  const initialValues = {
    firstname: '',
    lastname: '',
    // username: '',
    email: '',
    password: '',
    changePassword: '',
    /* acceptTerms: false,*/
    companyName: '',
    companyTypeId: '',
    country: '',
    language: '',
    mobile: '',
    countryCode: '+90',
  }

  const registrationSchema = Yup.object().shape({
    // username: Yup.string(),
    mobile: Yup.string().required(intl.formatMessage({id: 'REGISTER.MANDATORYWARNING'})),
    countryCode: Yup.string().required(intl.formatMessage({id: 'REGISTER.MANDATORYWARNING'})),
    email: Yup.string().notRequired(),
    firstname: Yup.string()
      .min(2, intl.formatMessage({id: 'REGISTER.MANDATORYMINIMUM'}, {value: '2'}))
      .max(50, intl.formatMessage({id: 'REGISTER.MANDATORYMAXIMUM'}, {value: '50'}))
      .required(intl.formatMessage({id: 'REGISTER.MANDATORYWARNING'})),
    lastname: Yup.string()
      .min(2, intl.formatMessage({id: 'REGISTER.MANDATORYMINIMUM'}, {value: '2'}))
      .max(50, intl.formatMessage({id: 'REGISTER.MANDATORYMAXIMUM'}, {value: '50'}))
      .required(intl.formatMessage({id: 'REGISTER.MANDATORYWARNING'})),
    password: Yup.string()
      .min(6, intl.formatMessage({id: 'REGISTER.MANDATORYMINIMUM'}, {value: '6'}))
      .max(50, intl.formatMessage({id: 'REGISTER.MANDATORYMAXIMUM'}, {value: '50'}))
      .required(intl.formatMessage({id: 'REGISTER.MANDATORYWARNING'})),
    changePassword: Yup.string()
      .required(intl.formatMessage({id: 'REGISTER.MANDATORYWARNING'}))
      .when('password', {
        is: (val: string) => !!(val && val.length > 0),
        then: Yup.string().oneOf(
          [Yup.ref('password')],
          intl.formatMessage({id: 'REGISTER.MANDATORYREPASSWORD'})
        ),
      }),

    companyName: Yup.string()
      .min(2, intl.formatMessage({id: 'REGISTER.MANDATORYMINIMUM'}, {value: '2'}))
      .required(intl.formatMessage({id: 'REGISTER.MANDATORYWARNING'})),

    companyTypeId: Yup.string().required(intl.formatMessage({id: 'REGISTER.MANDATORYWARNING'})),

    country: Yup.string().required(intl.formatMessage({id: 'REGISTER.MANDATORYWARNING'})),

    language: Yup.string().required(intl.formatMessage({id: 'REGISTER.MANDATORYWARNING'})),

    /*acceptTerms: Yup.bool().required('Şartlar ve koşulları kabul etmelisiniz.'),*/
  })

  const registrationTitle: any = {
    FREIGHT_CARRIER: intl.formatMessage({id: 'REGISTER.Freight.carrier'}),
    FREIGHT_OWNER: intl.formatMessage({id: 'REGISTER.Freight.owner'}),
    FORWARDER: intl.formatMessage({id: 'REGISTER.forwarder'}),
  }
  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      if (formik.isSubmitting || !formik.isValid /*|| !formik.values.acceptTerms*/) {
        setTimeout(() => {
          authService
            .register(
              values.language,
              values.companyName,
              values.companyTypeId,
              values.country,
              values.firstname,
              values.lastname,
              values.password,
              values.email,
              values.mobile.toString(),
              values.countryCode
            )
            .then((resp) => {
              !hideLogin && dispatch(auth.actions.login(resp.data.token))
              toast.success(intl.formatMessage({id: 'REGISTER.TOAST.CREATED'}))
              setLoading(false)
              hideLogin && clearInputs()
              setDefaultStep(0)
              if (isSignUp) {
                history.push('/auth/login')
              }
            })
            .catch((e: any) => {
              setLoading(false)
              setSubmitting(false)
              setStatus(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
              toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
            })
        }, 1000)
      }
    },
  })

  const clearInputs = () => {
    formik.setValues(initialValues)
    formik.setErrors({})
    formik.setTouched({}, false)
  }

  const getCountryList = () => {
    commonServ
      .publicCountryList()
      .then((resp: any) => {
        setCountries(resp.data)
        setLoading(false)
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  // const sendEmailByLink = (email: string) => {
  //   authService
  //     .sendLinkByEmail(email)
  //     .then((resp: any) => {
  //       setLoading(false)
  //       toast.success(intl.formatMessage({id: 'REGISTER.TOAST.MAILSENDED'}))
  //     })
  //     .catch((e: any) => {
  //       setLoading(false)
  //       toast.error(e.response.data.message + ' | ' + e.response.data.code)
  //     })
  // }

  const getCompanyTypes = () => {
    commonServ
      .getCompanyTypes()
      .then((resp: any) => {
        const result = resp.data.filter((item: any) => !(item.code === 'PERSONAL' || item.code === 'SUPPLIER'))
        setCompanyTypes(result)
        setLoading(false)
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const getLanguageList = () => {
    commonServ
      .languageList()
      .then((resp: any) => {
        setLanguages(resp.data)
        setLoading(false)
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const companyDisableCheck = () => !formik.values.companyTypeId

  // const formContentDisbleCheck = () =>
  //   !formik.values.firstname ||
  //   !formik.values.lastname ||
  //   !formik.values.email ||
  //   !formik.values.companyName ||
  //   !formik.values.password ||
  //   !formik.values.changePassword ||
  //   !formik.values.country ||
  //   !formik.values.mobile ||
  //   !formik.values.language ||
  //   !formik.values.countryCode

  useEffect(() => {
    setLoading(true)
    getCountryList()
    setLoading(true)
    getLanguageList()
    setLoading(true)
    getCompanyTypes()
    PasswordMeterComponent.bootstrap()
  }, [])

  const companyTypeSelector = () => (
    <>
      <CompanyTypeSelector
        companyTypeList={
          companyTypes &&
          companyTypes?.map((item: any) => ({
            key: item?.companyTypeId,
            title: item?.description,
            code: item?.code,
            icon:
              item?.companyTypeId === 1
                ? 'truck'
                : item?.companyTypeId === 2
                ? 'container'
                : 'default',
          }))
        }
        companyType={(val: any) => {
          val && formik.setFieldValue('companyTypeId', val)
          val && setShowSelectorPopup(false)
        }}
        selectedCompanyType={formik.values.companyTypeId}
      />
      {formik.touched.companyTypeId && formik.errors.companyTypeId && (
        <div className='fv-plugins-message-container' style={{marginLeft: '20px'}}>
          <div className='fv-help-block'>
            <span role='alert'>{formik.errors.companyTypeId}</span>
          </div>
        </div>
      )}
    </>
  )

  const toggleViewPassword = () => {
    setViewPassword(!viewPassword)
  }

  const fromContent = () => (
    <>
      {/* begin::Form group */}
      <div className={`row fv-row ${isSignUp ? 'mb-5' : 'mb-7'}`}>
        <div className={`col-md-6 ${isSignUp ? 'mb-5' : 'mb-7'}`}>
          <label className='class="form-label fw-bolder text-dark fs-6'>
            {intl.formatMessage({id: 'REGISTER.FORM.NAME'})}
          </label>
          <input
            placeholder={intl.formatMessage({id: 'REGISTER.FORM.NAME'})}
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('firstname')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.firstname && formik.errors.firstname,
              },
              {
                'is-valid': formik.touched.firstname && !formik.errors.firstname,
              }
            )}
          />
          {formik.touched.firstname && formik.errors.firstname && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.firstname}</span>
              </div>
            </div>
          )}
        </div>
        <div className={`col-md-6 ${isSignUp ? 'mb-5' : 'mb-7'}`}>
          <label className='class="form-label fw-bolder text-dark fs-6'>
            {intl.formatMessage({id: 'REGISTER.FORM.SURNAME'})}
          </label>
          <input
            placeholder={intl.formatMessage({id: 'REGISTER.FORM.SURNAME'})}
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('lastname')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.lastname && formik.errors.lastname,
              },
              {
                'is-valid': formik.touched.lastname && !formik.errors.lastname,
              }
            )}
          />
          {formik.touched.lastname && formik.errors.lastname && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.lastname}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className={`row fv-row ${isSignUp ? 'mb-5' : 'mb-7'}`}>
        <div className='col-md-6'>
          <label className='form-label fw-bolder text-dark fs-6'>
            {intl.formatMessage({id: 'REGISTER.FORM.COMPANY'})}
          </label>
          <input
            placeholder={intl.formatMessage({id: 'REGISTER.FORM.COMPANY'})}
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('companyName')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.companyName && formik.errors.companyName,
              },
              {
                'is-valid': formik.touched.companyName && !formik.errors.companyName,
              }
            )}
          />
          {formik.touched.companyName && formik.errors.companyName && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.companyName}</span>
              </div>
            </div>
          )}
        </div>
        <div className={`col-md-6 ${isSignUp ? 'mb-5' : 'mb-7'}`}>
          <label className='form-label fw-bolder text-dark fs-6'>
            {intl.formatMessage({id: 'REGISTER.FORM.EMAIL'})}
          </label>
          <input
            placeholder={intl.formatMessage({id: 'REGISTER.FORM.EMAIL'})}
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {'is-invalid': formik.touched.email && formik.errors.email},
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className={'row'}>
        {/* begin::Form group Password */}
        <div
          className={`${isSignUp ? 'mb-5' : 'mb-6'} fv-row col-md-6`}
          data-kt-password-meter='true'
        >
          <div className='mb-1'>
            <label className='form-label fw-bolder text-dark fs-6'>
              {intl.formatMessage({id: 'REGISTER.FORM.PASSWORD'})}
            </label>
            <div className='position-relative mb-3'>
              <div className='input-group'>
                <input
                  type={viewPassword ? 'text' : 'password'}
                  placeholder={intl.formatMessage({id: 'REGISTER.FORM.PASSWORD'})}
                  autoComplete='off'
                  {...formik.getFieldProps('password')}
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    {
                      'is-invalid': formik.touched.password && formik.errors.password,
                    },
                    {
                      'is-valid': formik.touched.password && !formik.errors.password,
                    }
                  )}
                />
                <div className='input-group-btn'>
                  <button
                    className='btn border-primary eye-border-wrap'
                    onClick={() => toggleViewPassword()}
                  >
                    {viewPassword ? (
                      <i className='bi bi-eye eye-wrap' />
                    ) : (
                      <i className='bi bi-eye-slash eye-wrap' />
                    )}
                  </button>
                </div>
              </div>
              {formik.touched.password && formik.errors.password && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.password}</span>
                  </div>
                </div>
              )}
            </div>
            {/* begin::Meter */}
            {/*<div
                                className="d-flex align-items-center mb-3"
                                data-kt-password-meter-control="highlight"
                            >
                                <div
                                    className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"
                                ></div>
                                <div
                                    className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"
                                ></div>
                                <div
                                    className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"
                                ></div>
                                <div
                                    className="flex-grow-1 bg-secondary bg-active-success rounded h-5px"
                                ></div>
                            </div>*/}
            {/* end::Meter */}
          </div>
          {/*<div className="text-muted">
                            Harf, sayı ve simge içeren 8 veya daha fazla karakter kullanın.
                        </div>*/}
        </div>
        {/* end::Form group */}

        {/* begin::Form group Confirm password */}
        <div className={`fv-row ${isSignUp ? 'mb-5' : 'mb-7'} col-md-6`}>
          <label className='form-label fw-bolder text-dark fs-6'>
            {intl.formatMessage({id: 'REGISTER.FORM.REPASSWORD'})}
          </label>
          <input
            type='password'
            placeholder={intl.formatMessage({id: 'REGISTER.FORM.REPASSWORD'})}
            autoComplete='off'
            {...formik.getFieldProps('changePassword')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.changePassword && formik.errors.changePassword,
              },
              {
                'is-valid': formik.touched.changePassword && !formik.errors.changePassword,
              }
            )}
          />
          {formik.touched.changePassword && formik.errors.changePassword && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.changePassword}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}
      </div>
      <div className={'row'}>
        <div className={`${isSignUp ? 'mb-5' : 'mb-7'} col-md-6`}>
          <label className='form-label fw-bolder text-dark fs-6'>
            {intl.formatMessage({id: 'REGISTER.FORM.COUNTRY'})}
          </label>
          <select
            placeholder={intl.formatMessage({id: 'REGISTER.FORM.COUNTRY'})}
            {...formik.getFieldProps('country')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.country && formik.errors.country,
              },
              {
                'is-valid': formik.touched.country && !formik.errors.country,
              }
            )}
          >
            <option value='' selected disabled>
              {intl.formatMessage({id: 'REGISTER.FORM.COUNTRY'})}
            </option>
            {countries &&
              countries?.map((item: any, key: number) => (
                <option key={key} value={item?.code}>
                  {item?.translatedName}
                </option>
              ))}
          </select>
          {formik.touched.country && formik.errors.country && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.country}</span>
              </div>
            </div>
          )}
        </div>

        <div className={`${isSignUp ? 'mb-5' : 'mb-7'} col-md-6`}>
          <label className='form-label fw-bolder text-dark fs-6'>
            {intl.formatMessage({id: 'REGISTER.FORM.LANGUAGE'})}
          </label>
          <SelectBox
            placeholder={intl.formatMessage({id: 'REGISTER.FORM.LANGUAGE'})}
            onChange={(event: any) => formik.setFieldValue('language', event.target.value)}
            value={formik.values.language}
            data={
              languages &&
              languages?.map((item: any) => {
                return {key: item?.code, title: item?.name}
              })
            }
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.language && formik.errors.language,
              },
              {
                'is-valid': formik.touched.language && !formik.errors.language,
              }
            )}
          />
          {formik.touched.language && formik.errors.language && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.language}</span>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className={`row ${isSignUp ? 'mb-5' : 'mb-7'}`}>
        <div className={`fv-row ${isSignUp ? 'mb-5' : 'mb-7'} col-md-3 phone-Inp-mobile-wrap`}>
          <label className='form-label fw-bolder text-dark fs-6'>
            {intl.formatMessage({id: 'REGISTER.FORM.COUNTRYCODE'})}
          </label>
          <PhoneInp
            isDisbaledInput
            className='phoneInp-wrap form-control form-control-lg form-control-solid'
            onChangeCountry={false}
            onClickSelect={(val: string | number) => {
              val && formik.setFieldValue('countryCode', '+' + val)
            }}
            countryCodeEditable={false}
          />
          {formik.touched.countryCode && formik.errors.countryCode && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.countryCode}</span>
              </div>
            </div>
          )}
        </div>
        <div
          className={`fv-row ${isSignUp ? 'mb-5' : 'mb-7'} col-md-6 mobile-Inp-mobile-wrap`}
          style={{marginLeft: '-50px'}}
        >
          <label className='form-label fw-bolder text-dark fs-6'>
            {intl.formatMessage({id: 'REGISTER.FORM.PHONE'})}
          </label>
          <input
            placeholder='(555)-555-55-55'
            type='number'
            autoComplete='off'
            {...formik.getFieldProps('mobile')}
            onKeyDown={(e) => exceptThisSymbols.includes(e.key) && e.preventDefault()}
            min={0}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.mobile && formik.errors.mobile,
              },
              {
                'is-valid': formik.touched.mobile && !formik.errors.mobile,
              }
            )}
          />
          {formik.touched.mobile && formik.errors.mobile && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.mobile}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* end::Form group */}
    </>
  )

  return (
    <>
      {/* <form
                className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                noValidate
                id='kt_login_signup_form'
                onSubmit={formik.handleSubmit}
            > */}
      {loading && <Loading />}
      {/* begin::Heading */}
      <div className='text-center'>
        {/* begin::Title */}
        <h1 className='text-dark mb-3'>
          {intl.formatMessage({id: 'REGISTER.FORM.CREATENEWACCOUNT'})}
        </h1>

        {
          <h3>
            {
              registrationTitle[
                companyTypes?.find(
                  (item: any) => item?.companyTypeId === formik?.values.companyTypeId
                )?.code
              ]
            }
          </h3>
        }

        {/* end::Title */}

        {/* begin::Link */}
        {!hideLogin && (
          <div className='text-gray-400 fw-bold fs-4'>
            {intl.formatMessage({id: 'REGISTER.FORM.ALREADYHAVE'})}
            <br />
            <Link to='/auth/login' className='link-primary fw-bolder' style={{marginLeft: '5px'}}>
              {intl.formatMessage({id: 'REGISTER.FORM.LOGIN'})}
            </Link>
          </div>
        )}
        {/* end::Link */}
      </div>
      {/* end::Heading */}

      {/*   begin::Action
            <button type='button' className='btn btn-light-primary fw-bolder w-100 mb-10'>
                <img
                    alt='Logo'
                    src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
                    className='h-20px me-3'
                />
                <FormattedMessage
                    id={'REGISTRATION.WITH.GOOGLE.TITLE'}
                    defaultMessage={'Google İle Giriş Yap'}
                />
            </button>
             end::Action

            <div className='d-flex align-items-center mb-10'>
                <div className='border-bottom border-gray-300 mw-50 w-100'></div>
                <span className='fw-bold text-gray-400 fs-7 mx-2'>Veya</span>
                <div className='border-bottom border-gray-300 mw-50 w-100'></div>
            </div>*/}

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      <TabManagerComponent
        onSubmit={() => {
          formik.handleSubmit()
        }}
        checkValidation={(value: any) => {
          if (value === 0) {
            if (!formik?.values?.companyTypeId) {
              formik.setErrors({
                companyTypeId: intl.formatMessage({id: 'REGISTER.MANDATORYWARNING'}),
              })
              formik.setTouched({companyTypeId: true})
            }
          }
        }}
        loading={loading}
        defaultStep={defaultStep}
        submitBtnText={intl.formatMessage({id: 'REGISTER.FORM.REGISTERBTN'})}
        data={[
          {
            disabled: companyDisableCheck(),
            content: companyTypeSelector(),
          },
          {
            // disabled: formContentDisbleCheck(),
            content: fromContent(),
          },
        ]}
      />

      {/* begin::Form group */}
      {/*<div className='fv-row mb-10'>
                <div className='form-check form-check-custom form-check-solid'>
                    <input
                        className='form-check-input'
                        type='checkbox'
                        id='kt_login_toc_agree'
                        {...formik.getFieldProps('acceptTerms')}
                    />
                    <label
                        className='form-check-label fw-bold text-gray-700 fs-6'
                        htmlFor='kt_login_toc_agree'
                    >
                        <Link to='/auth/terms' className='ms-1 link-primary'>
                            <FormattedMessage
                                id={'Şartlar Ve Koşullar'}
                                defaultMessage={'Şartlar Ve Koşullar'}
                            />
                        </Link>
                        'ı kabul ediyorum.
                    </label>
                    {formik.touched.acceptTerms && formik.errors.acceptTerms && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span role='alert'>{formik.errors.acceptTerms}</span>
                            </div>
                        </div>
                    )}
                </div>
            </div>*/}
      {/* end::Form group */}

      {/* begin::Form group */}
      {/* <div className='text-center'> */}
      {/* <button
                        type='submit'
                        id='kt_sign_up_submit'
                        className='btn btn-lg btn-primary w-100 mb-7'
                    >
                        {!loading && <span
                            className='indicator-label'>{intl.formatMessage({id: "REGISTER.FORM.REGISTERBTN"})}</span>}
                        {loading && (
                            <span className='indicator-progress' style={{display: 'block'}}>
                          {intl.formatMessage({id: "REGISTER.FORM.PLEASEWAIT"})}
                                {' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                        )}
                    </button> */}
      {/*<Link to='/auth/login'>
                    <button
                        type='button'
                        id='kt_login_signup_form_cancel_button'
                        className='btn btn-lg btn-light-primary w-100 mb-7'
                    >
                       İptal Et
                    </button>
                </Link>*/}
      {/* </div> */}
      {/* end::Form group */}
      {/* </form> */}
      {showSelectorPopup && (
        <Modal
          containerScopeStyle={{zIndex: 50}}
          width={'500px'}
          closeBtnSettings={{
            show: false,
            onClick: () => setShowSelectorPopup(false),
          }}
          customAll={
            <CompanyTypeSelector
              companyTypeList={
                companyTypes &&
                companyTypes?.map((item: any) => ({
                  key: item?.companyTypeId,
                  title: item?.description,
                  code: item?.code,
                  icon:
                    item?.companyTypeId === 1
                      ? 'truck'
                      : item?.companyTypeId === 2
                      ? 'container'
                      : 'default',
                }))
              }
              companyType={(val: any) => {
                val && formik.setFieldValue('companyTypeId', val)
                val && setShowSelectorPopup(false)
              }}
            />
          }
        />
      )}
    </>
  )
}
