import React from 'react'
import clsx from 'clsx'

import {KTSVG} from '../../../_metronic/helpers'
import {IRightLayoutPopup} from './type'

import './RightLayoutPopup.scss'

const toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px'
const RightLayoutPopup = ({
  children,
  title,
  width,
  className,
  mainClassName,
  isCloseIcon,
  closeOnClick,
}: IRightLayoutPopup) => {
  return (
    <div className={`right-layout-popup-layer ${mainClassName}`}>
      <div className={`right-layout-popup-main ${className}`} style={{width: width ?? ''}}>
        <div className={`row mt-5 mb-5 ${isCloseIcon ? '' : 'justify-content-center'}`}>
          {isCloseIcon && (
            <div className='col-md-3 menu-icon d-flex justify-content-start'>
              <div
                className={clsx(
                  'btn btn-icon btn-active-light-primary btn-custom',
                  toolbarButtonHeightClass
                )}
                data-kt-menu-attach='parent'
                data-kt-menu-placement='bottom-end'
                data-kt-menu-flip='bottom'
                onClick={() => closeOnClick()}
              >
                <KTSVG path='/media/close_icon.svg' className='svg-icon-3x' />
              </div>
            </div>
          )}
          <div className={'popup-title col-md-6'}>{title}</div>
        </div>
        {children}
      </div>
    </div>
  )
}

export default RightLayoutPopup
