/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {useIntl} from 'react-intl'

import {ReactComponent as NextActive} from '../DataTableAdvanced/assets/next-active.svg'
import {ReactComponent as NextInActive} from '../DataTableAdvanced/assets/next-inactive.svg'

import {priceConversion} from '../../../utils/common'

import './MyListWidgetComponent.scss'

const MyListWidgetComponent = ({
  data,
  setPageMIndex,
  pageMIndex = 0,
  handlePublishList,
  handleUnPublishList,
  actionContent = null,
}: any) => {
  const [showToolTipIdList, setShowToolTipIdList] = useState<any>([])
  const intl = useIntl()

  return (
    <>
      <div className='card card-xl-stretch mb-5 mb-xl-8'>
        {!data?.length && (
          <div className='alert alert-warning mb-5 fw-bolder' role='alert'>
            {intl.formatMessage({id: 'Carrier.transporters.noData'})}
          </div>
        )}
        <div className='row card-body pt-0 p-0 mt-5'>
          {data.map((item: any) => (
            <div
              className={'col-xl-12 p-2 mb-2'}
              style={{position: 'relative'}}
              key={item?.listingTypeId}
            >
              <div className='border-bottom p-4'>
                <div className='row'>
                  <div className='col-md-2 mobile-image-wrap'>
                    <div
                      className='d-flex align-items-center justify-content-center'
                      style={{height: '139px'}}
                    >
                      <img
                        className={'success-image img-fluid m-auto'}
                        src={item?.defaultImage}
                        alt='success'
                        style={{maxHeight: '139px'}}
                      />
                    </div>
                  </div>
                  <div className='row col-md-10'>
                    <div
                      className='row col-md-6 mobile-content-wrap'
                      style={{height: 'fit-content'}}
                    >
                      <div
                        className='col-md-4 mb-2 inside-label-content-wrap'
                        style={{height: 'fit-content'}}
                      >
                        <small className={'mb-1 posting-font-wrap'}>
                          {intl.formatMessage({id: 'freight.myListing.postingNumber'})}
                        </small>
                        <p className='text-dark fw-bolder d-block text-hover-primary mb-1'>
                          {item?.listingId}
                        </p>
                      </div>

                      <div
                        className='col-md-4 mb-2 inside-label-content-wrap'
                        style={{height: 'fit-content'}}
                      >
                        <small className={'text-muted'}>
                          {intl.formatMessage({id: 'freight.listing.brand'})}
                        </small>
                        <p className='text-dark fw-bold d-block text-hover-primary mb-1'>
                          {item?.brand?.name}
                        </p>
                      </div>

                      <div
                        className='col-md-4 mb-2 inside-label-content-wrap'
                        style={{height: 'fit-content'}}
                      >
                        <small className={'text-muted'}>
                          {intl.formatMessage({id: 'freight.listing.model'})}
                        </small>
                        <p className='text-dark fw-bold d-block text-hover-primary mb-1'>
                          {item?.model}
                        </p>
                      </div>
                      <div
                        className='col-md-4 mb-2 inside-label-content-wrap'
                        style={{height: 'fit-content'}}
                      >
                        <small className={'text-muted'}>
                          {intl.formatMessage({id: 'freight.listing.city'})}
                        </small>
                        <p className='text-dark fw-bold d-block text-hover-primary mb-1'>
                          {item?.city?.name}
                        </p>
                      </div>

                      <div
                        className='col-md-4 mb-2 inside-label-content-wrap'
                        style={{height: 'fit-content'}}
                      >
                        <small className={'text-muted'}>
                          {intl.formatMessage({id: 'freight.listing.country'})}
                        </small>
                        <p className='text-dark fw-bold d-block text-hover-primary mb-1'>
                          {item?.country?.name}
                        </p>
                      </div>

                      <div
                        className='col-md-3 mb-2 inside-label-content-wrap'
                        style={{height: 'fit-content'}}
                      >
                        <small className={'text-muted'}>
                          {intl.formatMessage({id: 'Admin.Adverts.table_status'})}
                        </small>
                        <p className='text-dark fw-bold d-block text-hover-primary mb-1'>
                          {item?.listingStatus?.description}
                        </p>
                      </div>

                      <div className='d-flex col-md-4 mb-2'>
                        <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid'>
                          <div className='d-flex align-items-center'>
                            <input
                              className='form-check-input w-45px h-30px input-checkbox-wrap'
                              type='checkbox'
                              onChange={() => {
                                if (item?.issueDate === null) {
                                  handlePublishList(
                                    item?.listingId,
                                    Math.round(new Date().getTime() / 1000)
                                  )
                                } else {
                                  handleUnPublishList(item?.listingId)
                                }
                              }}
                              name='status'
                              defaultChecked={item?.issueDate === null ? false : true}
                            />
                            <label className='form-check-label ml-1'>
                              <b
                                className={`fs-5 ${
                                  item?.issueDate === null ? '' : 'check-input-label-warp'
                                }`}
                              >
                                {item?.issueDate === null
                                  ? intl.formatMessage({id: 'freight.myListings.pause'})
                                  : intl.formatMessage({id: 'freight.myListings.published'})}
                              </b>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='col-md-4'>
                      {item?.description?.length > 210 ? (
                        <span>
                          {!showToolTipIdList?.includes(item?.listingId) ? (
                            <p className='text-muted detail-font-wrap' style={{fontWeight: 500}}>
                              {item?.description}...
                              <span
                                style={{cursor: 'pointer', color: '#0000EE'}}
                                onClick={() =>
                                  setShowToolTipIdList([...showToolTipIdList, item?.listingId])
                                }
                              >
                                {'  '}
                                {intl.formatMessage({id: 'freight.listing.showMore'})}
                              </span>
                            </p>
                          ) : (
                            <p className='text-muted detail-font-wrap' style={{fontWeight: 500}}>
                              {item?.description}
                              <span
                                onClick={() => {
                                  setShowToolTipIdList(
                                    showToolTipIdList.filter(
                                      (dataItem: any) => dataItem !== item?.listingId
                                    )
                                  )
                                }}
                                style={{cursor: 'pointer', color: '#0000EE'}}
                              >
                                {'  '}
                                {intl.formatMessage({id: 'freight.listing.showLess'})}
                              </span>
                            </p>
                          )}
                        </span>
                      ) : (
                        <p className='text-muted detail-font-wrap' style={{fontWeight: 500}}>
                          {item?.description}
                        </p>
                      )}
                    </div>

                    <div className='col-md-2 mt-7'>
                      <div className='cursor-pointer'>
                        {actionContent && actionContent(item?.listingType?.code, item?.listingId)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='overlay-text-new'>
                <span className='main-price-wrap'>
                  {priceConversion(item?.price, item?.currency?.currencyCode)}{' '}
                  {item?.currency?.currencyCode}
                </span>
              </div>
            </div>
          ))}
        </div>

        <div className='pagination'>
          <div className={'pagination-buttons'}>
            <button
              className={'back pag-btn'}
              onClick={() => setPageMIndex(pageMIndex - 1)}
              disabled={pageMIndex === 0}
            >
              <span className={'previous-arrow'}>
                {!(pageMIndex === 0) ? <NextActive /> : <NextInActive />}
              </span>
            </button>
            <button
              className={'next pag-btn'}
              onClick={() => setPageMIndex(pageMIndex + 1)}
              disabled={data.length < 20}
            >
              {!(data.length < 20) ? <NextActive /> : <NextInActive />}
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export {MyListWidgetComponent}
