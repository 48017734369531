import {apiUrl} from "../conf";
import axios from "axios";

export class NotificationService {

    saveToken(token: string) {
        const config: any = {
            method: 'POST',
            url: apiUrl + '/notification-management/notifications/tokens',
            headers: {
                'Content-Type': 'application/json',
            },
            data: JSON.stringify({
                token: token,
                channel: 'WEB'
            })
        };
        return axios(config);
    }

    getNotifications() {
        const config: any = {
            method: 'GET',
            url: apiUrl + '/notification-management/notifications',
            headers: {
                'Content-Type': 'application/json',
            },
        };
        return axios(config);
    }

    notifiMarkAsRead(id: number | string) {
        const config: any = {
            method: 'PATCH',
            url: apiUrl + '/notification-management/notifications/' + id + '/read',
            headers: {
                'Content-Type': 'application/json',
            },
        };
        return axios(config);
    }
}
