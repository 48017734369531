import React, {useEffect} from 'react'
import {Header} from '../../DefaultPages/NewHomePage/Header/Header'

import './forwardPage.scss'
import '../../../pages/DefaultPages/NewHomePage/NewHomePage.scss'

const ForwardPage = () => {
  const getOS = () => {
    const userAgent = window.navigator.userAgent
    // @ts-ignore
    const platform = window.navigator?.userAgentData?.platform || window.navigator.platform
    const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K']
    const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE']
    const iosPlatforms = ['iPhone', 'iPad', 'iPod']
    let os = null

    if (macosPlatforms.indexOf(platform) !== -1) {
      os = 'Mac OS'
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = 'iOS'
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = 'Windows'
    } else if (/Android/.test(userAgent)) {
      os = 'Android'
    } else if (/Linux/.test(platform)) {
      os = 'Linux'
    }

    return os
  }

  useEffect(() => {
    if (getOS() === 'iOS') {
      window.location.href = 'https://apps.apple.com/US/app/rivalog/id1613475053'
    } else if (getOS() === 'Android') {
      window.location.href = 'https://play.google.com/store/apps/details?id=com.iwa.logistics'
    }
  }, [])

  return (
    <>
      <div className='main-content-home-wrap'>
        <Header />
      </div>
      <div className='forward-page-wrap'>
        <div className='forward-preview-wrap'>
          <a
            rel='noreferrer'
            href='https://play.google.com/store/apps/details?id=com.iwa.logistics'
            target='_blank'
          >
            <img src='/media/google-play.png' style={{height: '60px'}} alt='google-play' />
          </a>
          <a
            rel='noreferrer'
            href='https://apps.apple.com/us/app/rivalog/id1613475053'
            target='_blank'
          >
            <img src='/media/app-store.svg' style={{height: '60px'}} alt='app-store' />
          </a>
        </div>
      </div>
    </>
  )
}

export {ForwardPage}
