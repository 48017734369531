import {apiUrl, globalPageCount} from '../conf'
import axios from 'axios'

export class AdvertService {
  /** Advert Create Start **/
  createAdvert(
    description: string,
    value: string,
    weight: string,
    width: string,
    height: string,
    freightLength: string,
    desi: string,
    ldm: string,
    flammable: boolean,
    stackable: boolean,
    currency: number,
    plannedDepartureDate: string,
    plannedArrivalDate: string,
    fromAddress: number,
    toAddress: number,
    trailerType: number,
    floorType: number,
    freightSpecificationList: string[],
    freightType: number,
    transportType: number,
    note: string,
    freightContentTypes: number,
    freightLoadingType: number,
    freightPackageType: number,
    harmonizedSystemCode: string,
    distanceMatrixId: number,
    fromAddressData: any,
    toAddressData: any,
    targetCost: string,
    targetCostCurrency: string,
    consignor: any,
    consignee: any
  ) {
    const data: any = JSON.stringify({
      description: description,
      value: parseInt(value),
      weight: parseInt(weight),
      width: parseInt(width),
      height: parseInt(height),
      length: parseInt(freightLength),
      targetCost: parseInt(targetCost),
      targetCostCurrency: {
        currencyId: parseInt(targetCostCurrency),
      },
      ...(desi && {
        desi: parseInt(desi),
      }),
      ...(ldm && {
        ldm: parseInt(ldm),
      }),
      flammable: !flammable ? 'N' : 'Y',
      stackable: !stackable ? 'N' : 'Y',
      valueCurrency: {
        currencyId: currency,
      },
      plannedDepartureDate: parseFloat(plannedDepartureDate),
      plannedArrivalDate: parseFloat(plannedArrivalDate),
      fromAddress: {
        addressId: fromAddress,
        ...fromAddressData,
      },
      toAddress: {
        addressId: toAddress,
        ...toAddressData,
      },
      trailerType: {
        trailerTypeId: trailerType,
      },
      floorType: {
        floorTypeId: floorType,
      },
      specificationList: freightSpecificationList.map((item) => {
        return {specificationId: parseInt(item)}
      }),
      freightType: {
        freightTypeId: freightType,
      },
      transportType: {
        transportTypeId: transportType,
      },
      note: note,
      freightContentType: {
        freightContentTypeId: freightContentTypes,
      },
      freightLoadingType: {
        freightLoadingTypeId: freightLoadingType,
      },
      freightPackageType: {
        freightPackageTypeId: freightPackageType,
      },
      harmonizedSystemCode: {
        code: harmonizedSystemCode,
      },
      consignor: consignor,
      consignee: consignee,
      ...(distanceMatrixId &&
        distanceMatrixId !== -1 && {
          distanceMatrix: {
            distanceMatrixId: distanceMatrixId,
          },
        }),
    })

    const config: any = {
      method: 'POST',
      url: apiUrl + '/freight-management/freights/owners',
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    }

    return axios(config)
  }

  // createQuick advert

  createQuickAdvert(
    plannedDepartureDate: string,
    plannedArrivalDate: string,
    fromAddress: number,
    toAddress: number,
    freightType: number,
    transportType: number,
    freightContentTypes: number,
    distanceMatrixId: number,
    fromAddressData: any,
    toAddressData: any,
    targetCost: string,
    targetCostCurrency: string,
    weight: string,
    consignor: any,
    consignee: any
  ) {
    const data: any = JSON.stringify({
      plannedDepartureDate: parseFloat(plannedDepartureDate),
      plannedArrivalDate: parseFloat(plannedArrivalDate),
      weight: parseInt(weight),
      targetCost: parseInt(targetCost),
      targetCostCurrency: {
        currencyId: parseInt(targetCostCurrency),
      },
      fromAddress: {
        addressId: fromAddress,
        ...fromAddressData,
      },
      toAddress: {
        addressId: toAddress,
        ...toAddressData,
      },
      freightType: {
        freightTypeId: freightType,
      },
      transportType: {
        transportTypeId: transportType,
      },
      freightContentType: {
        freightContentTypeId: freightContentTypes,
      },
      consignor: consignor,
      consignee: consignee,
      ...(distanceMatrixId &&
        distanceMatrixId !== -1 && {
          distanceMatrix: {
            distanceMatrixId: distanceMatrixId,
          },
        }),
    })

    const config: any = {
      method: 'POST',
      url: apiUrl + '/freight-management/freights/owners',
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    }

    return axios(config)
  }

  // createQuick advert

  createPublicAdvert(
    plannedDepartureDate: string,
    plannedArrivalDate: string,
    freightType: number,
    transportType: number,
    freightContentTypes: number,
    distanceMatrixId: number,
    fromAddressData: any,
    toAddressData: any,
    targetCost: string,
    targetCostCurrency: string,
    weight: string,
    name: string,
    surname: string,
    mobile: string,
    countryCode: string,
    email: string,
    companyCode: any
  ) {
    const data: any = JSON.stringify({
      plannedDepartureDate: parseFloat(plannedDepartureDate),
      plannedArrivalDate: parseFloat(plannedArrivalDate),
      weight: parseInt(weight),
      targetCost: parseInt(targetCost),
      targetCostCurrency: {
        currencyId: parseInt(targetCostCurrency),
      },
      fromAddress: {
        ...fromAddressData,
      },
      toAddress: {
        ...toAddressData,
      },
      freightType: {
        freightTypeId: freightType,
      },
      transportType: {
        transportTypeId: transportType,
      },
      freightContentType: {
        freightContentTypeId: freightContentTypes,
      },
      consignor: {
        name: name,
        surname: surname,
        phone: mobile,
        countryCode: countryCode,
        email: email,
      },
      company: {
        companyCode: companyCode,
      },
      ...(distanceMatrixId &&
        distanceMatrixId !== -1 && {
          distanceMatrix: {
            distanceMatrixId: distanceMatrixId,
          },
        }),
    })

    const config: any = {
      method: 'POST',
      url: apiUrl + '/freight-management/public/freights',
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    }

    return axios(config)
  }

  // createQuick advert

  createCarrierQuickAdvert(
    plannedDepartureDate: string,
    plannedArrivalDate: string,
    fromAddress: number,
    toAddress: number,
    freightType: number,
    transportType: number,
    freightContentTypes: number,
    distanceMatrixId: number,
    fromAddressData: any,
    toAddressData: any,
    targetCost: string,
    targetCostCurrency: string,
    weight: string,
    consignor: any,
    consignee: any
  ) {
    const data: any = JSON.stringify({
      plannedDepartureDate: parseFloat(plannedDepartureDate),
      plannedArrivalDate: parseFloat(plannedArrivalDate),
      weight: parseInt(weight),
      targetCost: parseInt(targetCost),
      targetCostCurrency: {
        currencyId: parseInt(targetCostCurrency),
      },
      fromAddress: {
        addressId: fromAddress,
        ...fromAddressData,
      },
      toAddress: {
        addressId: toAddress,
        ...toAddressData,
      },
      freightType: {
        freightTypeId: freightType,
      },
      transportType: {
        transportTypeId: transportType,
      },
      freightContentType: {
        freightContentTypeId: freightContentTypes,
      },
      consignor: consignor,
      consignee: consignee,
      ...(distanceMatrixId &&
        distanceMatrixId !== -1 && {
          distanceMatrix: {
            distanceMatrixId: distanceMatrixId,
          },
        }),
    })

    const config: any = {
      method: 'POST',
      url: apiUrl + '/freight-management/freights/carriers',
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    }

    return axios(config)
  }

  createCarrierAdvert(
    description: string,
    value: string,
    weight: string,
    width: string,
    height: string,
    freightLength: string,
    desi: string,
    ldm: string,
    flammable: boolean,
    stackable: boolean,
    currency: number,
    plannedDepartureDate: string,
    plannedArrivalDate: string,
    fromAddress: number,
    toAddress: number,
    trailerType: number,
    floorType: number,
    freightSpecificationList: string[],
    freightType: number,
    transportType: number,
    note: string,
    freightContentTypes: number,
    freightLoadingType: number,
    freightPackageType: number,
    harmonizedSystemCode: string,
    distanceMatrixId: number,
    fromAddressData: any,
    toAddressData: any,
    targetCost: string,
    targetCostCurrency: string,
    consignor: any,
    consignee: any
  ) {
    const data: any = JSON.stringify({
      description: description,
      value: parseInt(value),
      weight: parseInt(weight),
      width: parseInt(width),
      height: parseInt(height),
      length: parseInt(freightLength),
      targetCost: parseInt(targetCost),
      targetCostCurrency: {
        currencyId: parseInt(targetCostCurrency),
      },
      ...(desi && {
        desi: parseInt(desi),
      }),
      ...(ldm && {
        ldm: parseInt(ldm),
      }),
      flammable: !flammable ? 'N' : 'Y',
      stackable: !stackable ? 'N' : 'Y',
      valueCurrency: {
        currencyId: currency,
      },
      plannedDepartureDate: parseFloat(plannedDepartureDate),
      plannedArrivalDate: parseFloat(plannedArrivalDate),
      fromAddress: {
        addressId: fromAddress,
        ...fromAddressData,
      },
      toAddress: {
        addressId: toAddress,
        ...toAddressData,
      },
      trailerType: {
        trailerTypeId: trailerType,
      },
      floorType: {
        floorTypeId: floorType,
      },
      specificationList: freightSpecificationList.map((item) => {
        return {specificationId: parseInt(item)}
      }),
      freightType: {
        freightTypeId: freightType,
      },
      transportType: {
        transportTypeId: transportType,
      },
      note: note,
      freightContentType: {
        freightContentTypeId: freightContentTypes,
      },
      freightLoadingType: {
        freightLoadingTypeId: freightLoadingType,
      },
      freightPackageType: {
        freightPackageTypeId: freightPackageType,
      },
      harmonizedSystemCode: {
        code: harmonizedSystemCode,
      },
      consignor: consignor,
      consignee: consignee,
      ...(distanceMatrixId &&
        distanceMatrixId !== -1 && {
          distanceMatrix: {
            distanceMatrixId: distanceMatrixId,
          },
        }),
    })

    const config: any = {
      method: 'POST',
      url: apiUrl + '/freight-management/freights/carriers',
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    }

    return axios(config)
  }

  createAndPublishPartialContainer(data: any) {
    const config: any = {
      method: 'POST',
      url: apiUrl + '/container-management/containers/publish/from/partial/freights',
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(data),
    }

    return axios(config)
  }

  createAndPublishFromFullTruckFreight(data: any) {
    const config: any = {
      method: 'POST',
      url: apiUrl + '/container-management/containers/publish/from/full/truck/freights',
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(data),
    }

    return axios(config)
  }

  /** Advert Update Start **/
  updateAdvert(
    freightId: number,
    description: string,
    value: string,
    weight: string,
    width: string,
    height: string,
    freightLenght: string,
    desi: string,
    ldm: string,
    flammable: boolean,
    stackable: boolean,
    currency: number,
    plannedDepartureDate: string,
    plannedArrivalDate: string,
    fromAddress: number,
    toAddress: number,
    trailerType: number,
    floorType: number,
    freightSpecificationList: string[],
    transportType: number,
    freightType: number,
    freightLoadingType: number,
    freightPackageType: number,
    harmonizedSystemCode: string,
    freightContentTypeId: number,
    fromAddressData: any,
    toAddressData: any,
    consignor: any,
    consignee: any
  ) {
    const data: any = JSON.stringify({
      freightId: freightId,
      description: description,
      value: parseInt(value),
      weight: parseInt(weight),
      width: parseInt(width),
      height: parseInt(height),
      length: parseInt(freightLenght),
      ...(desi && {
        desi: parseInt(desi),
      }),
      ...(ldm && {
        ldm: parseInt(ldm),
      }),
      flammable: !flammable ? 'N' : 'Y',
      stackable: !stackable ? 'N' : 'Y',
      valueCurrency: {
        currencyId: currency,
      },
      plannedDepartureDate: parseFloat(plannedDepartureDate),
      plannedArrivalDate: parseFloat(plannedArrivalDate),
      fromAddress: {
        addressId: fromAddress,
        ...fromAddressData,
      },
      toAddress: {
        addressId: toAddress,
        ...toAddressData,
      },
      trailerType: {
        trailerTypeId: trailerType,
      },
      transportType: {
        transportTypeId: transportType,
      },
      floorType: {
        floorTypeId: floorType,
      },
      specificationList: freightSpecificationList.map((item: string) => {
        return {specificationId: parseInt(item)}
      }),
      freightType: {
        freightTypeId: freightType,
      },
      freightLoadingType: {
        freightLoadingTypeId: freightLoadingType,
      },
      freightPackageType: {
        freightPackageTypeId: freightPackageType,
      },
      harmonizedSystemCode: {
        code: harmonizedSystemCode,
      },
      freightContentType: {
        freightContentTypeId: freightContentTypeId,
      },
      consignor: consignor,
      consignee: consignee,
    })

    const config: any = {
      method: 'PUT',
      url: apiUrl + '/freight-management/freights/owners',
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    }

    return axios(config)
  }

  updateAdvertFreight(
    freightId: number,
    description: string,
    value: string,
    weight: string,
    width: string,
    height: string,
    freightLenght: string,
    desi: string,
    ldm: string,
    flammable: boolean,
    stackable: boolean,
    currency: number,
    plannedDepartureDate: string,
    plannedArrivalDate: string,
    fromAddress: number,
    toAddress: number,
    trailerType: number,
    floorType: number,
    freightSpecificationList: string[],
    transportType: number,
    freightType: number,
    freightLoadingType: number,
    freightPackageType: number,
    harmonizedSystemCode: string,
    freightContentTypeId: number,
    fromAddressData: any,
    toAddressData: any,
    consignor: any,
    consignee: any
  ) {
    const data: any = JSON.stringify({
      freightId: freightId,
      description: description,
      value: parseInt(value),
      weight: parseInt(weight),
      width: parseInt(width),
      height: parseInt(height),
      length: parseInt(freightLenght),
      ...(desi && {
        desi: parseInt(desi),
      }),
      ...(ldm && {
        ldm: parseInt(ldm),
      }),
      flammable: !flammable ? 'N' : 'Y',
      stackable: !stackable ? 'N' : 'Y',
      valueCurrency: {
        currencyId: currency,
      },
      plannedDepartureDate: parseFloat(plannedDepartureDate),
      plannedArrivalDate: parseFloat(plannedArrivalDate),
      fromAddress: {
        addressId: fromAddress,
        ...fromAddressData,
      },
      toAddress: {
        addressId: toAddress,
        ...toAddressData,
      },
      trailerType: {
        trailerTypeId: trailerType,
      },
      transportType: {
        transportTypeId: transportType,
      },
      floorType: {
        floorTypeId: floorType,
      },
      specificationList: freightSpecificationList.map((item: string) => {
        return {specificationId: parseInt(item)}
      }),
      freightType: {
        freightTypeId: freightType,
      },
      freightLoadingType: {
        freightLoadingTypeId: freightLoadingType,
      },
      freightPackageType: {
        freightPackageTypeId: freightPackageType,
      },
      harmonizedSystemCode: {
        code: harmonizedSystemCode,
      },
      freightContentType: {
        freightContentTypeId: freightContentTypeId,
      },
      consignor: consignor,
      consignee: consignee,
    })

    const config: any = {
      method: 'PUT',
      url: apiUrl + '/freight-management/freights/owners',
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    }

    return axios(config)
  }

  updateCarrierAdvert(
    freightId: number,
    description: string,
    value: string,
    weight: string,
    width: string,
    height: string,
    freightLenght: string,
    desi: string,
    ldm: string,
    flammable: boolean,
    stackable: boolean,
    currency: number,
    plannedDepartureDate: string,
    plannedArrivalDate: string,
    fromAddress: number,
    toAddress: number,
    trailerType: number,
    floorType: number,
    freightSpecificationList: string[],
    transportType: number,
    freightType: number,
    freightLoadingType: number,
    freightPackageType: number,
    harmonizedSystemCode: string,
    freightContentTypeId: number,
    fromAddressData: any,
    toAddressData: any,
    consignor: any,
    consignee: any
  ) {
    const data: any = JSON.stringify({
      freightId: freightId,
      description: description,
      value: parseInt(value),
      weight: parseInt(weight),
      width: parseInt(width),
      height: parseInt(height),
      length: parseInt(freightLenght),
      ...(desi && {
        desi: parseInt(desi),
      }),
      ...(ldm && {
        ldm: parseInt(ldm),
      }),
      flammable: !flammable ? 'N' : 'Y',
      stackable: !stackable ? 'N' : 'Y',
      valueCurrency: {
        currencyId: currency,
      },
      plannedDepartureDate: parseFloat(plannedDepartureDate),
      plannedArrivalDate: parseFloat(plannedArrivalDate),
      fromAddress: {
        addressId: fromAddress,
        ...fromAddressData,
      },
      toAddress: {
        addressId: toAddress,
        ...toAddressData,
      },
      trailerType: {
        trailerTypeId: trailerType,
      },
      floorType: {
        floorTypeId: floorType,
      },
      specificationList: freightSpecificationList.map((item: string) => {
        return {specificationId: parseInt(item)}
      }),
      transportType: {
        transportTypeId: transportType,
      },
      freightType: {
        freightTypeId: freightType,
      },
      freightLoadingType: {
        freightLoadingTypeId: freightLoadingType,
      },
      freightPackageType: {
        freightPackageTypeId: freightPackageType,
      },
      harmonizedSystemCode: {
        code: harmonizedSystemCode,
      },
      freightContentType: {
        freightContentTypeId: freightContentTypeId,
      },
      consignor: consignor,
      consignee: consignee,
    })

    const config: any = {
      method: 'PUT',
      url: apiUrl + '/freight-management/freights/carriers',
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    }

    return axios(config)
  }

  deleteAdvert(advertId: number) {
    const config: any = {
      method: 'DELETE',
      url: apiUrl + '/freight-management/freights/' + advertId,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  deleteCarrierAdvert(advertId: number) {
    const config: any = {
      method: 'DELETE',
      url: apiUrl + '/freight-management/freights/' + advertId,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  sendAdvertToApproval(advertId: number) {
    const config: any = {
      method: 'PATCH',
      url: apiUrl + '/freight-management/freights/' + advertId + '/approval/send/',
      headers: {
        'Content-Type': 'application/json',
      },
    }
    return axios(config)
  }

  sendCarrierAdvertToApproval(advertId: number) {
    const config: any = {
      method: 'PATCH',
      url: apiUrl + '/freight-management/freights/' + advertId + '/approval/send/',
      headers: {
        'Content-Type': 'application/json',
      },
    }
    return axios(config)
  }

  getAdverts(data?: {
    freightStatusId?: any
    companyId?: any
    pageIndex?: any
    willBeExcludedFreightIdList?: any
    transportTypeId?: any
  }) {
    data?.freightStatusId === 'all' && (data = {...data, freightStatusId: ''})
    data?.transportTypeId === 'all' && (data = {...data, transportTypeId: ''})
    const config: any = {
      method: 'GET',
      url: `${apiUrl}/freight-management/freights/administrator/filter?pageIndex=${
        data?.pageIndex ?? 0
      }&pageCount=${globalPageCount}${
        data?.freightStatusId && data?.freightStatusId !== 'all'
          ? '&freightStatusId=' + data?.freightStatusId
          : ''
      }${
        data?.companyId && data?.companyId !== 'all' ? '&companyId=' + data?.companyId : ''
      }&willBeExcludedFreightIdList=${data?.willBeExcludedFreightIdList ?? ''}&transportTypeId=${
        data?.transportTypeId ?? ''
      }`,
      headers: {
        'Content-Type': 'application/json',
      },
    }
    return axios(config)
  }

  getFreightAdverts(data?: {freightStatusId?: any; companyId?: any; pageIndex?: any}) {
    data?.freightStatusId === 'all' && (data = {...data, freightStatusId: ''})
    const config: any = {
      method: 'GET',
      url: `${apiUrl}/freight-management/freights/owners/filter?pageIndex=${
        data?.pageIndex ?? 0
      }&pageCount=${globalPageCount}${
        data?.freightStatusId && data?.freightStatusId !== 'all'
          ? '&freightStatusId=' + data?.freightStatusId
          : ''
      }${data?.companyId && data?.companyId !== 'all' ? '&companyId=' + data?.companyId : ''}`,
      headers: {
        'Content-Type': 'application/json',
      },
    }
    return axios(config)
  }

  getCarrierAdverts(data?: {
    freightStatusId?: any
    companyId?: any
    pageIndex?: any
    willBeExcludedFreightIdList?: any
    transportTypeId?: any
    freightTypeId?: any
  }) {
    data?.freightStatusId === 'all' && (data = {...data, freightStatusId: ''})
    data?.transportTypeId === 'all' && (data = {...data, transportTypeId: ''})
    data?.freightTypeId === 'all' && (data = {...data, freightTypeId: ''})
    const config: any = {
      method: 'GET',
      url: `${apiUrl}/freight-management/freights/carriers/filter?pageIndex=${
        data?.pageIndex ?? 0
      }&pageCount=${globalPageCount}${
        data?.freightStatusId && data?.freightStatusId !== 'all'
          ? '&freightStatusId=' + data?.freightStatusId
          : ''
      }${
        data?.companyId && data?.companyId !== 'all' ? '&companyId=' + data?.companyId : ''
      }&willBeExcludedFreightIdList=${data?.willBeExcludedFreightIdList ?? ''}&transportTypeId=${
        data?.transportTypeId ?? ''
      }&freightTypeId=${data?.freightTypeId ?? ''}`,
      headers: {
        'Content-Type': 'application/json',
      },
    }
    return axios(config)
  }

  createProposal(
    price: any,
    currency: any,
    explanation: string,
    freightId: number,
    proposalScopeId: number
  ) {
    const data: any = JSON.stringify({
      freight: {
        freightId: freightId,
      },
      price: parseInt(price),
      currency: {
        currencyId: parseInt(currency),
      },
      proposalScope: {
        proposalScopeId: proposalScopeId,
      },
      explanation: explanation,
    })

    const config: any = {
      method: 'POST',
      url: apiUrl + '/freight-management/freights/proposals',
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    }
    return axios(config)
  }

  getFreightProposalList(freightId: number, pageIndex?: any) {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        `/freight-management/freights/${freightId}/proposals?pageIndex=${
          pageIndex ?? 0
        }&pageCount=20`,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  getCarrierFreightProposalList(freightId: number, pageIndex?: any) {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        `/freight-management/freights/${freightId}/proposals?pageIndex=${
          pageIndex ?? 0
        }&pageCount=20`,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  deleteFreigthProposal(freightProposalId: number) {
    const config: any = {
      method: 'DELETE',
      url: apiUrl + `/freight-management/freights/proposals/${freightProposalId}`,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  deleteCarrierFreigthProposal(freightProposalId: number) {
    const config: any = {
      method: 'DELETE',
      url: apiUrl + `/freight-management/freights/proposals/${freightProposalId}`,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  getSelectedFreightProposal(freightId: number) {
    const config: any = {
      method: 'GET',
      url: apiUrl + `/freight-management/freights/proposals/${freightId}`,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  getCarrierSelectedFreightProposal(freightId: number) {
    const config: any = {
      method: 'GET',
      url: apiUrl + `/freight-management/freights/proposals/${freightId}`,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  updateFreightProposal(data: any) {
    const payLoad: any = {
      ...data,
      currency: {currencyId: data.currency},
      proposalScope: {proposalScopeId: Number(data.proposalScope)},
    }
    const config: any = {
      method: 'PUT',
      url: apiUrl + `/freight-management/freights/proposals`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(payLoad),
    }

    return axios(config)
  }

  updateAdminFreightProposal(data: any) {
    const payLoad: any = {
      ...data,
      currency: {currencyId: data.currency},
      proposalScope: {proposalScopeId: Number(data.proposalScope)},
    }
    const config: any = {
      method: 'PUT',
      url: apiUrl + `/freight-management/freights/proposals`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(payLoad),
    }

    return axios(config)
  }

  updateCarrierFreightProposal(data: any) {
    const payLoad: any = {
      ...data,
      currency: {currencyId: data.currency},
      proposalScope: {proposalScopeId: Number(data.proposalScope)},
    }
    const config: any = {
      method: 'PUT',
      url: apiUrl + `/freight-management/freights/proposals`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(payLoad),
    }

    return axios(config)
  }

  getAdvertLocation(advertId: number) {
    const config: any = {
      method: 'GET',
      url: apiUrl + '/freight-management/freights/' + advertId + '/location',
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  carrierGetAdvertLocation(advertId: number) {
    const config: any = {
      method: 'GET',
      url: apiUrl + '/freight-management/freights/' + advertId + '/location',
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  getAdvertsWithStatus(statusId: number) {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        '/freight-management/freights/filter?pageIndex=0&pageCount=10000&freightStatusId=' +
        statusId,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  getCarrierAdvertsWithStatus(statusId: number) {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        `/freight-management/freights/filter?pageIndex=0&pageCount=10000&freightStatusId=${
          statusId ?? ''
        }`,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  getAdvertWithId(freightId: number) {
    const config: any = {
      method: 'GET',
      url: apiUrl + '/freight-management/freights/' + freightId,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  getCarrierAdvertWithId(freightId: number) {
    const config: any = {
      method: 'GET',
      url: apiUrl + '/freight-management/freights/' + freightId,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  getFileTypes() {
    const config: any = {
      method: 'GET',
      url: apiUrl + '/file-management/files/types?searchText=&pageIndex=0&pageCount=10000',
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  /** Advert Create End **/

  /** Trailer Info Start **/
  trailerTypes() {
    const config: any = {
      method: 'GET',
      url: apiUrl + '/trailer-management/trailers/types?searchText=&pageIndex=0&pageCount=1000',
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  carrierTrailerTypes() {
    const config: any = {
      method: 'GET',
      url: apiUrl + '/trailer-management/trailers/types?searchText=&pageIndex=0&pageCount=1000',
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  trailerTypesList(transportsTypeId?: number) {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        `/trailer-management/transports/types/${transportsTypeId}/trailers/types?searchText=&pageIndex=0&pageCount=20`,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  carrierTrailerTypesList(transportsTypeId?: number) {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        `/trailer-management/transports/types/${transportsTypeId}/trailers/types?searchText=&pageIndex=0&pageCount=20`,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  transportTypesList(searchText?: string, pageIndex?: number) {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        `/transport-management/transports/types?searchText=${searchText ?? ''}&pageIndex=${
          pageIndex ?? 0
        }&pageCount=20`,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  transportPublicTypesList(searchText?: string, pageIndex?: number) {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        `/transport-management/public/transports/types?searchText=${searchText ?? ''}&pageIndex=${
          pageIndex ?? 0
        }&pageCount=20`,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  carrierTransportTypesList(searchText?: string, pageIndex?: number) {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        `/transport-management/transports/types?searchText=${searchText ?? ''}&pageIndex=${
          pageIndex ?? 0
        }&pageCount=20`,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  carrierAllTransportTypesList(searchText?: string, pageIndex?: number) {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        `/transport-management/transports/types?searchText=${searchText ?? ''}&pageIndex=${
          pageIndex ?? 0
        }&pageCount=1000`,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  trailerSpecifications(trailerTypeId: number) {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        '/trailer-management/trailers/specifications?trailerTypeId=' +
        trailerTypeId +
        '&searchText=&pageIndex=0&pageCount=1000',
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  carrierTrailerSpecifications(trailerTypeId: number) {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        '/trailer-management/trailers/specifications?trailerTypeId=' +
        trailerTypeId +
        '&searchText=&pageIndex=0&pageCount=1000',
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  floorTypes() {
    const config: any = {
      method: 'GET',
      url:
        apiUrl + '/trailer-management/trailers/floors/types?searchText=&pageIndex=0&pageCount=1000',
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  carrierFloorTypes() {
    const config: any = {
      method: 'GET',
      url:
        apiUrl + '/trailer-management/trailers/floors/types?searchText=&pageIndex=0&pageCount=1000',
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  /** Trailer Info End **/

  /** ***************** */

  /** ***************** */
  /** Freight Info Start **/
  freightTypes() {
    const config: any = {
      method: 'GET',
      url: apiUrl + '/freight-management/freights/types?searchText=&pageIndex=0&pageCount=1000',
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  freightPublicTypes() {
    const config: any = {
      method: 'GET',
      url:
        apiUrl + '/freight-management/public/freights/types?searchText=&pageIndex=0&pageCount=1000',
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  carrierFreightTypes() {
    const config: any = {
      method: 'GET',
      url: apiUrl + '/freight-management/freights/types?searchText=&pageIndex=0&pageCount=1000',
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  freightContentTypes() {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        '/freight-management/freights/contents/types?searchText=&pageIndex=0&pageCount=1000',
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  freightPublicContentTypes() {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        '/freight-management/public/freights/contents/types?searchText=&pageIndex=0&pageCount=1000',
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  carrierFreightContentTypes() {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        '/freight-management/freights/contents/types?searchText=&pageIndex=0&pageCount=1000',
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  freightPackageTypes() {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        '/freight-management/freights/packages/types?searchText=&pageIndex=0&pageCount=1000',
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  carrierFreightPackageTypes() {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        '/freight-management/freights/packages/types?searchText=&pageIndex=0&pageCount=1000',
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  freightLoadingTypes() {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        '/freight-management/freights/loading/types?searchText=&pageIndex=0&pageCount=1000',
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  carrierFreightLoadingTypes() {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        '/freight-management/freights/loading/types?searchText=&pageIndex=0&pageCount=1000',
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  /** Freight Info End **/

  /** Currency List Start **/
  currencyList() {
    const config: any = {
      method: 'GET',
      url: apiUrl + '/currency-management/currencies',
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  carrierCurrencyList() {
    const config: any = {
      method: 'GET',
      url: apiUrl + '/currency-management/currencies',
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  publicCurrencyList() {
    const config: any = {
      method: 'GET',
      url: apiUrl + '/currency-management/public/currencies',
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  /** Currency List End **/

  /** Gtip List Start **/
  gtipList(searchText: string, pageIndex: number = 0) {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        '/freight-management/harmonizedsystemcodes?searchText=' +
        searchText +
        '&pageIndex=' +
        pageIndex +
        '&pageCount=' +
        globalPageCount,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  carrierGtipList(searchText: string, pageIndex: number = 0) {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        '/freight-management/harmonizedsystemcodes?searchText=' +
        searchText +
        '&pageIndex=' +
        pageIndex +
        '&pageCount=' +
        globalPageCount,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  /** Gtip List End **/

  /** Distance From Cities Start **/
  distanceFromCities(fromCityId: number, toCityId: number, departureDate?: number) {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        '/distance-matrix-management/distance/from/cities?fromCityId=' +
        fromCityId +
        '&toCityId=' +
        toCityId +
        '&departureDate=' +
        (departureDate ? departureDate : ''),
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  carrierDistanceFromCities(fromCityId: number, toCityId: number, departureDate?: number) {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        '/distance-matrix-management/distance/from/cities?fromCityId=' +
        fromCityId +
        '&toCityId=' +
        toCityId +
        '&departureDate=' +
        (departureDate ? departureDate : ''),
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  /** Distance From Cities End **/

  /** Distance From Coordinates Start **/
  distanceFromCoordinates(
    fromLang: number,
    fromLot: number,
    toLang: number,
    toLot: number,
    departureDate?: number
  ) {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        `/distance-matrix-management/distance/from/coordinates?fromLatitude=${fromLang}&fromLongitude=${fromLot}&toLatitude=${toLang}&toLongitude=${toLot}&departureDate=${
          departureDate ?? ''
        }`,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  /** Distance From Coordinates End **/
}
