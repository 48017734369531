import React, {useEffect} from 'react'
import styles from './Modal.module.scss'
import {IModal} from './types'
/*import {ReactComponent as Warning} from './assets/warning.svg';
import {ReactComponent as Filter} from './assets/filter.svg';*/
import {ReactComponent as Close} from './assets/close.svg'
import useScrollLock from '../../../utils/useScrollLock'

/*const setIcon = (icon: any) => {
    switch (icon) {
        case 'warning':
            return <Warning/>;
        case 'filter':
            return <Filter/>;
        default:
            return <Warning/>;
    }
};*/

const Modal = ({
  iconSettings,
  textData,
  buttonSettings,
  customAll,
  closeBtnSettings,
  middleCustom,
  width,
  height,
  mainTitle,
  containerScopeStyle,
  containerModalWrap,
}: IModal) => {
  const {scrollLock, scrollUnlock} = useScrollLock()

  useEffect(() => {
    scrollLock()
  }, [])

  return (
    <div className={styles.modalMainBack} style={containerScopeStyle}>
      <div
        className={`${styles.modalMainContainer} ${containerModalWrap}`}
        style={{width: width, height: height}}
      >
        {(mainTitle || closeBtnSettings?.show) && (
          <div
            className={styles.headBar + ' ' + (mainTitle && closeBtnSettings?.show && styles.bar)}
          >
            {mainTitle && <h5 className={'mb-0'}>{mainTitle}</h5>}
            {closeBtnSettings?.show && (
              <button
                className={styles.closeButton}
                onClick={() => {
                  scrollUnlock()
                  closeBtnSettings?.onClick()
                }}
              >
                <Close />
              </button>
            )}
          </div>
        )}
        {customAll && customAll}
      </div>
    </div>
  )
}

export default Modal
