import React, {useState} from 'react'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'

import Modal from '../../../Components/Modal'

import {AccountantService} from '../../../../services/InvoiceServices/AccountantService'

const FinancialAdvisor = ({onClose, loading, setLoading}: any) => {
  const intl = useIntl()
  const accountantServ = new AccountantService()
  const [email, setEmail] = useState<string>('')
  const [touchValidate, setTouchValidate] = useState<boolean>(false)

  const handleSendAccountantToken = () => {
    accountantServ
      .createAccountantLoginToken({email: email})
      .then((resp: any) => {
        setLoading(false)
        setTouchValidate(false)
        toast.success(intl.formatMessage({id: 'financialAdv.success'}))
        onClose()
      })
      .catch((e: any) => {
        setLoading(false)
        setTouchValidate(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const validateEmail = (email: any) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
  }

  const handleFinancialSumbit = () => {
    if (validateEmail(email)) {
      setLoading(true)
      handleSendAccountantToken()
    } else {
      setTouchValidate(true)
    }
  }

  return (
    <Modal
      closeBtnSettings={{
        show: true,
        onClick: () => onClose(),
      }}
      width='450px'
      height='auto'
      customAll={
        <div>
          <div className='mb-5' style={{marginTop: '-30px'}}>
            <h2>{intl.formatMessage({id: 'financialAdv.title'})}</h2>
          </div>
          <div className='fv-row mb-10'>
            <label className='form-label fs-6 fw-bolder text-dark'>
              {intl.formatMessage({id: 'CreateClient.create.email'})}
            </label>
            <input
              placeholder={intl.formatMessage({id: 'CreateClient.create.email'})}
              className='form-control form-control-lg form-control-solid'
              onChange={(event: any) => {
                if (touchValidate) {
                  setTouchValidate(false)
                }
                setEmail(event.target.value)
              }}
              value={email}
              type='text'
              name='name'
              autoComplete='off'
            />

            {touchValidate && (
              <div className='fv-plugins-message-container mt-2'>
                <div className='fv-help-block'>
                  <span role='alert'>{intl.formatMessage({id: 'global.emailInvalid'})}</span>
                </div>
              </div>
            )}
          </div>

          <div className='d-flex justify-content-center'>
            <button
              type='button'
              disabled={email.length === 0}
              className='btn btn-lg btn-primary w-50 mb-5'
              onClick={() => handleFinancialSumbit()}
            >
              {intl.formatMessage({id: 'financialAdv.submit'})}
            </button>
          </div>
        </div>
      }
    />
  )
}

export default FinancialAdvisor
