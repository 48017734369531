import React, {useState, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'

import useScrollLock from '../../../../../utils/useScrollLock'
import {priceConversion} from '../../../../../utils/common'

import DataTableAdvanced from '../../../../Components/DataTableAdvanced'
import RightLayoutPopup from '../../../../Components/RightLayoutPopup'
import ShowProposalDetail from './ShowProposalDetail'
import ShowDetails from './ShowDetails'
import EditProposal from './EditProposal'

import {ContainerService} from '../../../../../services/AdminServices/ContainerService'

const ShowAllProposalList = ({
  proposalList,
  onClose,
  setLoading,
  proposalDetails,
  getSelectedProposalDetail,
  getContainerDetail,
  containerDetails,
  currencyList,
  proposalScopeList,
  getAllProposalByUser,
  pageProposalAllListIndex,
  handleAllProposalPagination,
}: any) => {
  const intl = useIntl()
  const containerServ = new ContainerService()

  const [showProposalDetailPopup, setShowProposalDetailPopup] = useState<boolean>(false)
  const [showContainerDetailPopup, setShowContainerDetailPopup] = useState<boolean>(false)
  const [showEditProposalPopup, setShowEditProposalPopup] = useState<boolean>(false)

  const [containerProposalId, setContainerProposalId] = useState<any>('')
  const [selectedEditProposal, setSelectedEditProposal] = useState<any>({
    price: '',
    currency: '',
    explanation: '',
  })

  const [selectedCurrencyName, setSelectedCurrencyName] = useState<any>('')
  const [selectedProposalScope, setSelectedProposalScope] = useState<any>('')

  const handleGetSelectedContainerProposalDetails = (containerProposalId: number) => {
    containerServ
      .getSelectedCarrierContainerProposal(containerProposalId)
      .then((resp: any) => {
        setLoading(false)
        setSelectedCurrencyName(resp?.data?.currency?.description)
        setSelectedProposalScope(resp?.data?.proposalScope?.description)
        setSelectedEditProposal((item: any) => ({
          ...item,
          price: resp?.data?.price,
          currency: resp?.data?.currency?.currencyId,
          explanation: resp?.data?.explanation,
          proposalScope: resp?.data?.proposalScope?.proposalScopeId,
        }))
      })
      .catch((e: any) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
        setLoading(false)
      })
  }

  const handleUpdateProposal = (data: any) => {
    containerServ
      .updateCarrierContainerProposal(data)
      .then(() => {
        getAllProposalByUser(pageProposalAllListIndex)
        toast.success(intl.formatMessage({id: 'Admin.Adverts.updateProposal.success'}))
        setLoading(false)
      })
      .catch((e: any) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
        setLoading(false)
      })
  }

  const {scrollLock} = useScrollLock()
  useEffect(() => {
    scrollLock()
  }, [])

  return (
    <>
      <RightLayoutPopup
        title={intl.formatMessage({id: 'Carrier.freightPostings.proposal'})}
        className='carrier-freight-proposal-list-exchange-wrap'
        isCloseIcon
        closeOnClick={() => onClose()}
      >
        {!proposalList.length && (
          <div className='alert alert-warning mb-5 fw-bolder' role='alert'>
            {intl.formatMessage({id: 'global.noData'})}
          </div>
        )}
        <DataTableAdvanced
          columns={[
            {
              Header: 'ID',
              accessor: 'id',
            },
            {
              Header: intl.formatMessage({id: 'Carrier.freightPosting.proposalList.description'}),
              accessor: 'description',
            },
            {
              Header: intl.formatMessage({id: 'Admin.Containers.from'}),
              accessor: 'fromLocation',
            },

            {
              Header: intl.formatMessage({id: 'Admin.Containers.to'}),
              accessor: 'toLocation',
            },
            {
              Header: intl.formatMessage({id: 'Carrier.freightPosting.proposalList.freightStatus'}),
              accessor: 'freightStatus',
            },
            {
              Header: () => (
                <div className='d-flex justify-content-end'>
                  {intl.formatMessage({id: 'freightPosting.proposalList.price'})}
                </div>
              ),
              accessor: 'price',
              cellStyle: {
                textAlign: 'end',
              },
            },
            {
              Header: intl.formatMessage({id: 'freightPosting.proposalList.currency'}),
              accessor: 'currency',
            },
            {
              Header: intl.formatMessage({id: 'freightPosting.proposalList.proposalScope'}),
              accessor: 'proposalScope',
            },
          ]}
          data={
            proposalList &&
            proposalList.map((item: any) => {
              return {
                id: item?.containerProposalId,
                description: item?.container?.freightContentType?.description,
                freightStatus: item?.container?.containerStatus?.description,
                price: item?.price && priceConversion(item?.price, item?.currency?.currencyCode),
                currency: item?.currency?.currencyCode,
                fromLocation: item?.container?.fromAddress?.city?.name
                  ? item?.container?.fromAddress?.city?.name +
                    ' / ' +
                    item?.container?.fromAddress?.country?.name
                  : '',
                toLocation: item?.container?.toAddress?.city?.name
                  ? item?.container?.toAddress?.city?.name +
                    ' / ' +
                    item?.container?.toAddress?.country?.name
                  : '',
                proposalScope:
                  item.proposalScope.code === 'OTHER'
                    ? item?.explanation
                    : item?.proposalScope?.description,
                data: item,
              }
            })
          }
          editableRows={['']}
          deleteText={intl.formatMessage({id: 'global.table.deleteText'})}
          showDetailBtn={(row: any) => {
            setLoading(true)
            setShowProposalDetailPopup(true)
            getSelectedProposalDetail(row.values.id)
          }}
          showFile={(row: any) => {
            setLoading(true)
            setShowContainerDetailPopup(true)
            getContainerDetail(row?.original?.data?.container?.containerId)
          }}
          editBtn={(row: any) => {
            setLoading(true)
            setShowEditProposalPopup(true)
            setContainerProposalId(row?.values?.id)
            handleGetSelectedContainerProposalDetails(row?.values?.id)
          }}
          showFileText={intl.formatMessage({id: 'Carrier.freightPosting.proposalList.showFreight'})}
          setPageMIndex={handleAllProposalPagination}
          pageMIndex={pageProposalAllListIndex}
        />

        {showEditProposalPopup && (
          <EditProposal
            setLoading={setLoading}
            setSelectedCurrencyName={setSelectedCurrencyName}
            selectedCurrencyName={selectedCurrencyName}
            selectedProposalScope={selectedProposalScope}
            setSelectedProposalScope={setSelectedProposalScope}
            setShowEditProposalPopup={setShowEditProposalPopup}
            selectedProposalId={containerProposalId}
            currencyList={currencyList}
            handleUpdateProposal={handleUpdateProposal}
            setSelectedEditProposal={setSelectedEditProposal}
            selectedEditProposal={selectedEditProposal}
            proposalScopeList={proposalScopeList}
          />
        )}

        {showProposalDetailPopup && (
          <ShowProposalDetail
            proposalDetails={proposalDetails}
            onClose={() => setShowProposalDetailPopup(false)}
          />
        )}

        {showContainerDetailPopup && (
          <ShowDetails
            onClose={() => setShowContainerDetailPopup(false)}
            containerDetailedList={containerDetails}
          />
        )}

        <div className='text-center mt-5 row view-details-freight-popup'>
          <div className={'col-md-6'}>
            <button
              onClick={onClose}
              type='button'
              id='kt_sign_up_submit'
              className='btn btn-lg btn-danger w-85 mb-5 '
            >
              {intl.formatMessage({id: 'Carrier.freightPosting.view.close'})}
            </button>
          </div>
        </div>
      </RightLayoutPopup>
    </>
  )
}

export default ShowAllProposalList
