import React from 'react'
import {useIntl} from 'react-intl'

const Agreements = () => {
  const intl = useIntl()
  return (
    <div className='overflow-auto'>
      <div className={'container my-10 d-flex align-items-center justify-content-center'}>
        <div dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'agreements'})}} />
      </div>
    </div>
  )
}

export default Agreements
