import React from 'react'
import {useIntl} from 'react-intl'

const privacyContent: any = {
  en: '/privacy_policy_en/',
  tr: '/privacy_policy_tr/',
  ru: '/privacy_policy_ru/',
}

const deliveryRefundContent: any = {
  en: '/delivery_refund_en/',
  tr: '/delivery_refund_tr/',
  ru: '/delivery_refund_ru/',
}

const distanceSalesContent: any = {
  en: '/distance_sales_en/',
  tr: '/distance_sales_tr/',
  ru: '/distance_sales_ru/',
}

export const FooterContent = () => {
  const intl = useIntl()

  return (
    <div className='footer-area'>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 footer-content-wrap'>
            <div className='footer-logo'>
              <a href='/'>
                <img src='/media/footer-logo.png' alt='' width={163} height={37} />
              </a>
            </div>
          </div>
          <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 footer-content-wrap'>
            <div className='footer-link-area'>
              <div className='footer-heading'>
                <h4>{intl.formatMessage({id: 'public.newLandingpage.links'})}</h4>
              </div>
              <ul className='footer-quick-links'>
                <li>
                  <a href='/public/available-vehicles' target='_blank'>
                    {intl.formatMessage({id: 'freight.truckExchange.title'})}
                  </a>
                </li>
                <li>
                  <a href={deliveryRefundContent[intl.locale]} target='_blank'>
                    {intl.formatMessage({id: 'Footer.content.delivery'})}
                  </a>
                </li>
                <li>
                  <a href={distanceSalesContent[intl.locale]} target='_blank'>
                    {intl.formatMessage({id: 'Footer.content.roSales'})}
                  </a>
                </li>
                <li onClick={() => window.open('/agreement/tio-certificate.pdf')}>
                  <span>{intl.formatMessage({id: 'Footer.content.tioCert'})}</span>
                </li>
              </ul>
            </div>
          </div>
          <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 d-flex align-items-start justify-content-center flex-column footer-content-wrap blog-mobile-content'>
            <div
              className='cursor-pointer footer-heading footer-heading-link header-blog-second'
              onClick={() => window.open('/blogs', '_blank')}
            >
              <h4>{intl.formatMessage({id: 'footer.blog.title'})}</h4>
            </div>

            <div
              className='cursor-pointer footer-heading footer-heading-link header-blog-second'
              onClick={() => window.open('/about-us', '_blank')}
            >
              <h4>{intl.formatMessage({id: 'Header.about.title'})}</h4>
            </div>
          </div>
          <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 footer-content-wrap'>
            <div className='app-store'>
              <a
                rel='noreferrer'
                href='https://play.google.com/store/apps/details?id=com.iwa.logistics'
                target='_blank'
              >
                <img
                  src='/media/google-play.png'
                  alt=''
                  className='app-img-wrap'
                  width={198}
                  height={60}
                />
              </a>
              <a
                rel='noreferrer'
                href='https://apps.apple.com/us/app/rivalog/id1613475053'
                target='_blank'
              >
                <img
                  src='/media/app-store.png'
                  alt=''
                  className='app-img-wrap'
                  width={198}
                  height={60}
                />
              </a>
            </div>
          </div>
          <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 footer-content-wrap'>
            <div className='footer-link-area footer-contact'>
              <div className='footer-heading'>
                <h4>{intl.formatMessage({id: 'Footer.content.contact'})}</h4>
              </div>
              <ul className='footer-contact'>
                <li>
                  <span>
                    <img src='/media/img10.svg' alt='' />
                  </span>
                  <a href='tel:+90 850 242 25 85'>+90 850 242 25 85</a>
                </li>
                <li>
                  <span>
                    <img src='/media/img11.svg' alt='' />
                  </span>
                  <a href='https://wa.me/905306284164' target='_blank'>
                    +90 530 628 41 64
                  </a>
                </li>
                <li>
                  <span>
                    <img src='/media/img12.svg' alt='' />
                  </span>
                  <a href='mailto:contact@rivalog.com'>contact@rivalog.com</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FooterContent
