import React from 'react'
import {Helmet} from 'react-helmet'

const Seo = (props: any = {}) => {
  return (
    <Helmet>
      <title>{props?.data?.title}</title>
      <meta name='description' content={props?.data?.metaDescription} />
      <link rel='canonical' href={props?.data?.href} />
      {props?.children}
    </Helmet>
  )
}

export default Seo
