import React, {useEffect} from 'react'
import {useIntl} from 'react-intl'

import RightLayoutPopup from '../../../../Components/RightLayoutPopup'

import useScrollLock from '../../../../../utils/useScrollLock'

const ClientDetails = ({selectedClientDetails, onClose}: any) => {
  const intl = useIntl()
  const {scrollLock, scrollUnlock} = useScrollLock()
  useEffect(() => {
    scrollLock()
  }, [])

  return (
    <RightLayoutPopup
      title={intl.formatMessage({id: 'CreateClient.show.title'})}
      className='view-client-wrap'
      isCloseIcon
      closeOnClick={() => onClose()}
    >
      <div className={'detail-modal-scroll-container'}>
        <div className={'mb-10 '}>
          <div className={'row'}>
            <div className={'col-md-6 mb-10'}>
              <h6 className={'text-muted  '}>
                {intl.formatMessage({id: 'CreateClient.create.clientType'})}
              </h6>
              <b className={'fs-5'}>{selectedClientDetails?.clientType?.description}</b>
            </div>
            <div className={'col-md-6 mb-10'}>
              <h6 className={'text-muted  '}>
                {intl.formatMessage({
                  id:
                    selectedClientDetails?.clientType?.clientTypeId === 1
                      ? 'CreateClient.create.companyName'
                      : 'CreateClient.create.name.name',
                })}
              </h6>
              <b className={'fs-5'}>{selectedClientDetails?.name}</b>
            </div>
          </div>

          <div className={'row'}>
            <div className={'col-md-6 mb-10'}>
              <h6 className={'text-muted '}>
                {intl.formatMessage({
                  id:
                    selectedClientDetails?.clientType?.clientTypeId === 1
                      ? 'CreateClient.create.companyTitle'
                      : 'CreateClient.create.surName',
                })}
              </h6>
              <b className={'fs-5'}>
                {selectedClientDetails?.clientType?.clientTypeId === 1
                  ? selectedClientDetails?.title
                  : selectedClientDetails?.surname}
              </b>
            </div>

            <div className={'col-md-6 mb-10'}>
              <h6 className={'text-muted  '}>
                {intl.formatMessage({id: 'CreateClient.create.email'})}
              </h6>
              <b className={'fs-5'}>{selectedClientDetails?.email}</b>
            </div>
          </div>

          <div className={'row'}>
            <div className={'col-md-6 mb-10'}>
              <h6 className={'text-muted  '}>
                {intl.formatMessage({id: 'Carrier.drivers.create.phoneCode'})}
              </h6>
              <b className={'fs-5'}>{selectedClientDetails?.countryCode}</b>
            </div>
            <div className={'col-md-6 mb-10'}>
              <h6 className={'text-muted '}>
                {intl.formatMessage({id: 'Carrier.drivers.create.phone'})}
              </h6>
              <b className={'fs-5'}>{selectedClientDetails?.phone}</b>
            </div>
          </div>

          <div className={'row'}>
            <div
              className={`${
                selectedClientDetails?.clientType?.clientTypeId === 1 ? 'col-md-6' : 'col-md-6'
              } mb-10`}
            >
              <h6 className={'text-muted  '}>
                {intl.formatMessage({id: 'CreateClient.create.country'})}
              </h6>
              <b className={'fs-5'}>{selectedClientDetails?.country?.name}</b>
            </div>
            <div
              className={`${
                selectedClientDetails?.clientType?.clientTypeId === 1 ? 'col-md-6' : 'col-md-6'
              } mb-10`}
            >
              <h6 className={'text-muted  '}>
                {intl.formatMessage({id: 'CreateClient.create.web'})}
              </h6>
              <b className={'fs-5'}>{selectedClientDetails?.web}</b>
            </div>
            {selectedClientDetails?.clientType?.clientTypeId === 2 && (
              <div className={'col-md-6 mb-10'}>
                <h6 className={'text-muted '}>
                  {intl.formatMessage({id: 'CreateClient.create.identityNumber'})}
                </h6>
                <b className={'fs-5'}>{selectedClientDetails?.identityNumber ?? ''}</b>
              </div>
            )}
          </div>

          {selectedClientDetails?.clientType?.clientTypeId === 1 && (
            <div className={'row'}>
              <div className={'col-md-6 mb-10'}>
                <h6 className={'text-muted  '}>
                  {intl.formatMessage({id: 'CreateClient.create.taxNumber'})}
                </h6>
                <b className={'fs-5'}>{selectedClientDetails?.taxNumber}</b>
              </div>
              <div className={'col-md-6 mb-10'}>
                <h6 className={'text-muted '}>
                  {intl.formatMessage({id: 'CreateClient.create.taxOffice'})}
                </h6>
                <b className={'fs-5'}>{selectedClientDetails?.taxOffice ?? ''}</b>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className='text-center row view-details-freight-popup freightPost-popup-container'>
        <div className={'col-md-6'}>
          <button
            onClick={() => {
              onClose()
              scrollUnlock()
            }}
            type='button'
            id='kt_sign_up_submit'
            className='btn btn-lg btn-danger w-100 mb-5 '
          >
            {intl.formatMessage({id: 'Carrier.freightPosting.view.close'})}
          </button>
        </div>
      </div>
    </RightLayoutPopup>
  )
}

export default ClientDetails
