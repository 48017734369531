import React, {useEffect} from 'react'
import {useIntl} from 'react-intl'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {useHistory} from 'react-router-dom'

import RightLayoutPopup from '../../../../Components/RightLayoutPopup'

import useScrollLock from '../../../../../utils/useScrollLock'

import './../CreateNewAdvert.scss'

const PreviewScreen = ({
  formikValues,
  showPreviewSuccess,
  addressList,
  trailerTypes,
  floorTypes,
  trailerSpecifications,
  currencyList,
  freightTypes,
  transporterTypeList,
  selectedTransportType,
  freightPackageTypes,
  freightLoadTypes,
  gtipList,
  createNewAdvert,
  onClose,
}: any) => {
  const intl = useIntl()
  const history = useHistory()
  const {scrollLock, scrollUnlock} = useScrollLock()
  useEffect(() => {
    scrollLock()
  }, [])

  const initialValues = {
    title: '',
    trailerType: '',
    trailerSpecification: [],
    floorType: '',

    loadDate: '',
    loadNonFormatDate: new Date().getTime(),
    loadTime: '',

    destDate: '',
    destNonFormatDate: new Date().getTime(),
    destTime: '',

    loadAddress: '',
    destinationAddress: '',
    freightType: '',
    freightPackageType: '',
    freightLoadType: '',
    weight: '',
    value: '',
    currency: '',
    adr1: false,
    stackable: false,

    width: '',
    height: '',
    freightLength: '',
    desi: '',
    ldm: '',
    transportType: '',
    note: '',
    harmonizedSystemCode: '',
    distanceMatrixId: '',

    fromCountry: '',
    fromCity: '',
    fromDistrict: '',
    fromNeighborhood: '',
    fromPostCode: '',
    fromStreet: '',
    fromStreetNumber: '',
    fromLatitude: '',
    fromLongitude: '',

    toCountry: '',
    toCity: '',
    toDistrict: '',
    toNeighborhood: '',
    toPostCode: '',
    toStreet: '',
    toStreetNumber: '',
    toLatitude: '',
    toLongitude: '',

    consignorName: '',
    consignorSurName: '',
    consignorCountryCode: '+90',
    consignorPhone: '',
    consignorEmail: '',
    consignorTaxNumber: '',
    consignorTaxOffice: '',
    consignorReferenceClientId: '',

    consigneeName: '',
    consigneeSurName: '',
    consigneeCountryCode: '+90',
    consigneePhone: '',
    consigneeEmail: '',
    consigneeTaxNumber: '',
    consigneeTaxOffice: '',
    consigneeReferenceClientId: '',
  }
  const loginSchema = Yup.object().shape(
    {
      width: Yup.string().notRequired(),
      height: Yup.string().notRequired(),
      freightLength: Yup.string().notRequired(),
      desi: Yup.string().notRequired(),
      ldm: Yup.string().notRequired(),
      note: Yup.string().notRequired(),

      title: Yup.string().notRequired(),
      trailerType: Yup.string().notRequired(),
      floorType: Yup.string().notRequired(),
      /* .required(intl.formatMessage({id: 'global.mandatory'}))*/ trailerSpecification:
        Yup.array().notRequired() /*.min(1, intl.formatMessage({id: 'global.mandatory'}))*/,

      loadDate: Yup.string().required(intl.formatMessage({id: 'global.mandatory'})),
      loadTime: Yup.string().required(intl.formatMessage({id: 'global.mandatory'})),
      destDate: Yup.string().required(intl.formatMessage({id: 'global.mandatory'})),
      destTime: Yup.string().required(intl.formatMessage({id: 'global.mandatory'})),

      loadAddress: Yup.string().when(['fromCountry', 'toCountry'], {
        is: (fromCountry: any, toCountry: any) => !fromCountry && !toCountry,
        then: Yup.string().required(intl.formatMessage({id: 'global.mandatory'})),
        otherwise: Yup.string(), // unnecessary
      }),
      destinationAddress: Yup.string().when(['fromCountry', 'toCountry'], {
        is: (fromCountry: any, toCountry: any) => !fromCountry && !toCountry,
        then: Yup.string().required(intl.formatMessage({id: 'global.mandatory'})),
        otherwise: Yup.string(), // unnecessary
      }),
      freightType: Yup.string().required(intl.formatMessage({id: 'global.mandatory'})),
      transportType: Yup.string().required(intl.formatMessage({id: 'global.mandatory'})),
      freightPackageType: Yup.string().notRequired(),
      freightLoadType: Yup.string().notRequired(),
      weight: Yup.string().notRequired(),
      value: Yup.string().when(['currency'], {
        is: (currency: any) => !currency,
        then: Yup.string(), // unnecessary
        otherwise: Yup.string().required(intl.formatMessage({id: 'global.mandatory'})),
      }),
      currency: Yup.string().when(['value'], {
        is: (value: any) => !value,
        then: Yup.string(), // unnecessary
        otherwise: Yup.string().required(intl.formatMessage({id: 'global.mandatory'})),
      }),
      harmonizedSystemCode: Yup.string().notRequired(),
      distanceMatrixId: Yup.string(),
      adr1: Yup.boolean(),
      stackable: Yup.boolean(),

      fromCountry: Yup.string().when(['loadAddress'], {
        is: (loadAddress: any) => !loadAddress,
        then: Yup.string().required(intl.formatMessage({id: 'global.mandatory'})),
        otherwise: Yup.string(), // unnecessary
      }),
      fromCity: Yup.string().when(['loadAddress'], {
        is: (loadAddress: any) => !loadAddress,
        then: Yup.string().required(intl.formatMessage({id: 'global.mandatory'})),
        otherwise: Yup.string(), // unnecessary
      }),
      fromDistrict: Yup.string().notRequired(),
      fromNeighborhood: Yup.string().notRequired(),
      fromPostCode: Yup.string().notRequired(),
      fromStreet: Yup.string().notRequired(),
      fromStreetNumber: Yup.string().notRequired(),
      fromLatitude: Yup.string().notRequired(),
      fromLongitude: Yup.string().notRequired(),

      toCountry: Yup.string().when(['loadAddress'], {
        is: (loadAddress: any) => !loadAddress,
        then: Yup.string().required(intl.formatMessage({id: 'global.mandatory'})),
        otherwise: Yup.string(), // unnecessary
      }),
      toCity: Yup.string().when(['loadAddress'], {
        is: (loadAddress: any) => !loadAddress,
        then: Yup.string().required(intl.formatMessage({id: 'global.mandatory'})),
        otherwise: Yup.string(), // unnecessary
      }),
      toDistrict: Yup.string().notRequired(),
      toNeighborhood: Yup.string().notRequired(),
      toPostCode: Yup.string().notRequired(),
      toStreet: Yup.string().notRequired(),
      toStreetNumber: Yup.string().notRequired(),
      toLatitude: Yup.string().notRequired(),
      toLongitude: Yup.string().notRequired(),
      targetCost: Yup.string().when(['targetCostCurrency'], {
        is: (targetCostCurrency: any) => !targetCostCurrency,
        then: Yup.string(), // unnecessary
        otherwise: Yup.string().required(intl.formatMessage({id: 'global.mandatory'})),
      }),
      targetCostCurrency: Yup.string().when(['targetCost'], {
        is: (targetCost: any) => !targetCost,
        then: Yup.string(), // unnecessary
        otherwise: Yup.string().required(intl.formatMessage({id: 'global.mandatory'})),
      }),

      consignorName: Yup.string().notRequired(),
      consignorSurName: Yup.string().notRequired(),
      consignorCountryCode: Yup.string().notRequired(),
      consignorPhone: Yup.string().notRequired(),
      consignorEmail: Yup.string().notRequired(),
      consignorTaxNumber: Yup.string().notRequired(),
      consignorTaxOffice: Yup.string().notRequired(),
      consignorReferenceClientId: Yup.string().notRequired(),

      consigneeName: Yup.string().notRequired(),
      consigneeSurName: Yup.string().notRequired(),
      consigneeCountryCode: Yup.string().notRequired(),
      consigneePhone: Yup.string().notRequired(),
      consigneeEmail: Yup.string().notRequired(),
      consigneeTaxNumber: Yup.string().notRequired(),
      consigneeTaxOffice: Yup.string().notRequired(),
      consigneeReferenceClientId: Yup.string().notRequired(),
    },
    [
      ['fromCountry', 'toCountry'],
      ['loadAddress', 'fromCountry'],
      ['loadAddress', 'toCountry'],
      ['loadAddress', 'fromCity'],
      ['loadAddress', 'toCity'],
      ['destinationAddress', 'fromCountry'],
      ['destinationAddress', 'toCountry'],
      ['targetCostCurrency', 'targetCost'],
      ['value', 'currency'],
    ]
  )

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      createNewAdvert(true)
    },
  })
  /** Formik Preferences End**/

  const getDate = (data?: any) => {
    const date = data.split('-').reverse()
    return `${date[1]}/${date[0]}/${date[2]}`
  }

  useEffect(() => {
    formikValues && formik.setValues(formikValues)
  }, [formikValues])

  return (
    <>
      <RightLayoutPopup
        title={
          showPreviewSuccess
            ? intl.formatMessage({id: 'Freight.createAdvert.info'})
            : intl.formatMessage({id: 'Freight.createAdvert.previewPreference'})
        }
        width={'50%'}
        isCloseIcon={!showPreviewSuccess}
        closeOnClick={() => onClose()}
      >
        {!showPreviewSuccess ? (
          <div className={'advert-preview-modal-container'}>
            <div className={'preview-modal-scroll-container'}>
              {/*Araç Seçenekleri -1-*/}
              <div className={'mb-10'}>
                <h4 className={'form-label fw-bolder text-dark fs-2 '}>
                  {intl.formatMessage({id: 'Freight.createAdvert.preview.vehicleOption'})}
                </h4>
                <hr />
                <div className={'row m-auto'}>
                  <div className={'col-md-6 mb-7'}>
                    <h6 className={'text-muted fs-6 '}>
                      {intl.formatMessage({id: 'Freight.createAdvert.preview.description'})}
                    </h6>
                    <b className={'fs-5'}>{formik.values.title}</b>
                  </div>
                  <div className={'col-md-6 mb-7'}>
                    <h6 className={'text-muted fs-6'}>
                      {intl.formatMessage({id: 'Freight.createAdvert.preview.trailerType'})}
                    </h6>
                    <b className={'fs-5'}>
                      {trailerTypes
                        .filter(
                          (item: any) => item?.trailerTypeId === parseInt(formik.values.trailerType)
                        )
                        .map((dat: any) => dat?.description)}
                    </b>
                  </div>
                  <div className={'col-md-6 mb-7'}>
                    <h6 className={'text-muted fs-6'}>
                      {intl.formatMessage({id: 'Freight.createAdvert.preview.floorType'})}
                    </h6>
                    <b className={'fs-5'}>
                      {floorTypes
                        .filter(
                          (item: any) => item?.floorTypeId === parseInt(formik.values.floorType)
                        )
                        .map((dat: any) => dat?.description)}
                    </b>
                  </div>
                  <div className={'col-md-6 mb-7'}>
                    <h6 className={'text-muted fs-6'}>
                      {intl.formatMessage({id: 'Freight.createAdvert.preview.specificationList'})}
                    </h6>
                    {formik.values.trailerSpecification.map((item: any, key: number) => {
                      let son = trailerSpecifications.filter(
                        (it: any) => it.specificationId === parseInt(item)
                      )
                      return (
                        <p key={key} className={'fs-5 text-bold'}>
                          {
                            // @ts-ignore
                            son[0]?.description
                          }
                        </p>
                      )
                    })}
                  </div>
                  <div className={'col-md-6 mb-7'}>
                    <h6 className={'text-muted fs-6'}>
                      {intl.formatMessage({id: 'Carrier.transporters.create.transportType'})}
                    </h6>
                    <b className={'fs-5'}>
                      {transporterTypeList
                        .filter(
                          (item: any) => item?.transportTypeId === parseInt(selectedTransportType)
                        )
                        .map((dat: any) => dat?.description)}
                    </b>
                  </div>

                </div>
              </div>

              {/*Tarih ve Zaman -2-*/}
              <div className={'mb-10'}>
                <h4 className={'form-label fw-bolder text-dark fs-2'}>
                  {intl.formatMessage({id: 'Freight.createAdvert.preview.dateAndTime'})}
                </h4>
                <hr />
                <div className={'row m-auto'}>
                  <div className={'col-md-6 mb-7'}>
                    <h6 className={'text-muted fs-6 '}>
                      {intl.formatMessage({
                        id: 'Freight.createAdvert.preview.plannedDepartureDate',
                      })}
                    </h6>
                    <b className={'fs-5'}>
                      {formik.values.loadDate && getDate(formik.values.loadDate)}
                    </b>
                  </div>
                  <div className={'col-md-6 mb-7'}>
                    <h6 className={'text-muted fs-6'}>
                      {intl.formatMessage({
                        id: 'Freight.createAdvert.preview.plannedDepartureTime',
                      })}
                    </h6>
                    <b className={'fs-5'}>{formik.values.loadTime}</b>
                  </div>
                  <div className={'col-md-6 mb-7'}>
                    <h6 className={'text-muted fs-6'}>
                      {intl.formatMessage({id: 'Freight.createAdvert.preview.plannedArrivalDate'})}
                    </h6>
                    <b className={'fs-5'}>
                      {formik.values.destDate && getDate(formik.values.destDate)}
                    </b>
                  </div>
                  <div className={'col-md-6 mb-7'}>
                    <h6 className={'text-muted fs-6'}>
                      {intl.formatMessage({id: 'Freight.createAdvert.preview.plannedArrivalTime'})}
                    </h6>
                    <b className={'fs-5'}>{formik.values.destTime}</b>
                  </div>
                </div>
              </div>

              <div className='mb-10'>
                <div className='row d-flex justify-content-between'>
                  <h4 className={'form-label col-md-6 fw-bolder text-dark fs-3'}>
                    {intl.formatMessage({id: 'Carrier.freight.details.consignor'})}
                  </h4>

                  <h4 className={'form-label col-md-6 fw-bolder text-dark fs-3'}>
                    {intl.formatMessage({id: 'Carrier.freight.details.consignee'})}
                  </h4>
                </div>
                <hr />

                <div className={'row m-auto'}>
                  <div className='col-md-6'>
                    <div className='row'>
                      <div className={'col-md-6 mb-7'}>
                        <h6 className={'text-muted'}>
                          {intl.formatMessage({id: 'Carrier.freight.details.name'})}
                        </h6>
                        <b className={'fs-5'}>{formik.values?.consignorName}</b>
                      </div>

                      <div className={'col-md-6 mb-7'}>
                        <h6 className={'text-muted'}>
                          {intl.formatMessage({id: 'Carrier.freight.details.surName'})}
                        </h6>
                        <b className={'fs-5'}>{formik.values?.consignorSurName}</b>
                      </div>

                      <div className={'col-md-6 mb-7'}>
                        <h6 className={'text-muted '}>
                          {intl.formatMessage({id: 'Carrier.freight.details.countryCode'})}
                        </h6>
                        <b className={'fs-5'}>{formik.values?.consignorCountryCode}</b>
                      </div>

                      <div className={'col-md-6 mb-7'}>
                        <h6 className={'text-muted  '}>
                          {intl.formatMessage({id: 'Carrier.freight.details.phone'})}
                        </h6>
                        <b className={'fs-5'}>{formik.values?.consignorPhone}</b>
                      </div>

                      <div className={'col-md-12 mb-7'}>
                        <h6 className={'text-muted'}>
                          {intl.formatMessage({id: 'Carrier.freight.details.email'})}
                        </h6>
                        <b className={'fs-5'}>{formik.values?.consignorEmail}</b>
                      </div>
                    </div>
                  </div>

                  <div className='col-md-6'>
                    <div className='row'>
                      <div className={'col-md-6 mb-7'}>
                        <h6 className={'text-muted'}>
                          {intl.formatMessage({id: 'Carrier.freight.details.name'})}
                        </h6>
                        <b className={'fs-5'}>{formik.values?.consigneeName}</b>
                      </div>

                      <div className={'col-md-6 mb-7'}>
                        <h6 className={'text-muted'}>
                          {intl.formatMessage({id: 'Carrier.freight.details.surName'})}
                        </h6>
                        <b className={'fs-5'}>{formik.values?.consigneeSurName}</b>
                      </div>

                      <div className={'col-md-6 mb-7'}>
                        <h6 className={'text-muted'}>
                          {intl.formatMessage({id: 'Carrier.freight.details.countryCode'})}
                        </h6>
                        <b className={'fs-5'}>{formik.values?.consigneeCountryCode}</b>
                      </div>

                      <div className={'col-md-6 mb-7'}>
                        <h6 className={'text-muted '}>
                          {intl.formatMessage({id: 'Carrier.freight.details.phone'})}
                        </h6>
                        <b className={'fs-5'}>{formik.values?.consigneePhone}</b>
                      </div>

                      <div className={'col-md-12 mb-7'}>
                        <h6 className={'text-muted'}>
                          {intl.formatMessage({id: 'Carrier.freight.details.email'})}
                        </h6>
                        <b className={'fs-5'}>{formik.values?.consigneeEmail}</b>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/*Adres Tercihleri -3-*/}
              <div className={'mb-10'}>
                <h4 className={'form-label fw-bolder text-dark fs-2 '}>
                  {intl.formatMessage({id: 'Freight.createAdvert.preview.addressPreferences'})}
                </h4>
                <hr />
                <div className={'row m-auto'}>
                  <div className={'col-md-6 mb-7'}>
                    <h6 className={'text-muted fs-6 '}>
                      {intl.formatMessage({id: 'Freight.createAdvert.preview.fromLocation'})}
                    </h6>
                    <b className={'fs-5'}>
                      {addressList
                        .filter(
                          (item: any) => item?.addressId === parseInt(formik.values.loadAddress)
                        )
                        .map((dat: any) => dat?.addressText)}
                    </b>
                  </div>
                  <div className={'col-md-6 mb-7'}>
                    <h6 className={'text-muted fs-6'}>
                      {intl.formatMessage({id: 'Freight.createAdvert.preview.toLocation'})}
                    </h6>
                    <b className={'fs-5'}>
                      {addressList
                        .filter(
                          (item: any) =>
                            item?.addressId === parseInt(formik.values.destinationAddress)
                        )
                        .map((dat: any) => dat?.addressText)}
                    </b>
                  </div>
                </div>
              </div>

              {/*Mal Bilgileri -4-*/}
              <div className={'mb-10'}>
                <h4 className={'form-label fw-bolder text-dark fs-2'}>
                  {intl.formatMessage({id: 'Freight.createAdvert.preview.freightInformation'})}
                </h4>
                <hr />
                <div className={'row m-auto'}>
                  <div className={'col-md-4 mb-7'}>
                    <h6 className={'text-muted fs-6 '}>
                      {intl.formatMessage({id: 'Freight.createAdvert.preview.value'})}
                    </h6>
                    <b className={'fs-5'}>{formik.values.value}</b>
                  </div>
                  <div className={'col-md-4 mb-7'}>
                    <h6 className={'text-muted fs-6'}>
                      {intl.formatMessage({id: 'Freight.createAdvert.preview.currency'})}
                    </h6>
                    <b className={'fs-5'}>
                      {currencyList
                        .filter(
                          (item: any) => item?.currencyId === parseInt(formik.values.currency)
                        )
                        .map((dat: any) => dat?.description)}
                    </b>
                  </div>
                  <div className={'col-md-4 mb-7'}>
                    <h6 className={'text-muted fs-6'}>
                      {intl.formatMessage({id: 'Freight.createAdvert.preview.freightType'})}
                    </h6>
                    <b className={'fs-5'}>
                      {freightTypes
                        .filter(
                          (item: any) => item?.freightTypeId === parseInt(formik.values.freightType)
                        )
                        .map((dat: any) => dat?.description)}
                    </b>
                  </div>
                  <div className={'col-md-4 mb-7'}>
                    <h6 className={'text-muted fs-6'}>
                      {intl.formatMessage({id: 'Freight.createAdvert.preview.packageType'})}
                    </h6>
                    <b className={'fs-5'}>
                      {freightPackageTypes
                        .filter(
                          (item: any) =>
                            item?.freightPackageTypeId ===
                            parseInt(formik.values.freightPackageType)
                        )
                        .map((dat: any) => dat?.description)}
                    </b>
                  </div>
                  <div className={'col-md-4 mb-7'}>
                    <h6 className={'text-muted fs-6'}>
                      {intl.formatMessage({id: 'Freight.createAdvert.preview.loadingType'})}
                    </h6>
                    <b className={'fs-5'}>
                      {freightLoadTypes
                        .filter(
                          (item: any) =>
                            item?.freightLoadingTypeId === parseInt(formik.values.freightLoadType)
                        )
                        .map((dat: any) => dat?.description)}
                    </b>
                  </div>
                  <div className={'col-md-4 mb-7'}>
                    <h6 className={'text-muted fs-6'}>
                      {intl.formatMessage({id: 'Freight.createAdvert.preview.gtip'})}
                    </h6>
                    <b className={'fs-5'}>
                      {gtipList
                        .filter((item: any) => item?.code === formik.values.harmonizedSystemCode)
                        .map((dat: any) => dat?.description)}
                    </b>
                  </div>
                  <div className={'col-md-4 mb-7'}>
                    <h6 className={'text-muted fs-6'}>
                      {intl.formatMessage({id: 'Freight.createAdvert.preview.lengthValue'})}
                    </h6>
                    <b className={'fs-5'}>{formik.values.freightLength}</b>
                  </div>
                  <div className={'col-md-4 mb-7'}>
                    <h6 className={'text-muted fs-6'}>
                      {intl.formatMessage({id: 'Freight.createAdvert.preview.widthValue'})}
                    </h6>
                    <b className={'fs-5'}>{formik.values.width}</b>
                  </div>
                  <div className={'col-md-4 mb-7'}>
                    <h6 className={'text-muted fs-6'}>
                      {intl.formatMessage({id: 'Freight.createAdvert.preview.heightValue'})}
                    </h6>
                    <b className={'fs-5'}>{formik.values.height}</b>
                  </div>
                  <div className={'col-md-4 mb-7'}>
                    <h6 className={'text-muted fs-6'}>
                      {intl.formatMessage({id: 'Freight.createAdvert.preview.grossKg'})}
                    </h6>
                    <b className={'fs-5'}>{formik.values.weight}</b>
                  </div>
                  <div className={'col-md-4 mb-7'}>
                    <h6 className={'text-muted fs-6'}>
                      {intl.formatMessage({id: 'Freight.createAdvert.preview.desi'})}
                    </h6>
                    <b className={'fs-5'}>{formik.values.desi}</b>
                  </div>
                  <div className={'col-md-4 mb-7'}>
                    <h6 className={'text-muted fs-6'}>
                      {intl.formatMessage({id: 'Freight.createAdvert.preview.ldm'})}
                    </h6>
                    <b className={'fs-5'}>{formik.values.ldm}</b>
                  </div>
                  <div className={'col-md-4 mb-7'}>
                    <h6 className={'text-muted fs-6'}>
                      {intl.formatMessage({id: 'Freight.createAdvert.preview.productContaining'})}
                    </h6>
                    <b className={'fs-5'}>
                      {formik.values.adr1
                        ? intl.formatMessage({id: 'Freight.myAdvert.yes'})
                        : intl.formatMessage({id: 'Freight.myAdvert.no'})}
                    </b>
                  </div>
                  <div className={'col-md-4 mb-7'}>
                    <h6 className={'text-muted fs-6'}>
                      {intl.formatMessage({id: 'Freight.createAdvert.preview.stackable'})}
                    </h6>
                    <b className={'fs-5'}>
                      {formik.values.stackable
                        ? intl.formatMessage({id: 'Freight.myAdvert.yes'})
                        : intl.formatMessage({id: 'Freight.myAdvert.no'})}
                    </b>
                  </div>
                  <div className={'col-md-4 mb-7'}>
                    <h6 className={'text-muted fs-6'}>
                      {intl.formatMessage({id: 'Freight.createAdvert.note'})}
                    </h6>
                    <b className={'fs-5'}>{formik.values.note}</b>
                  </div>
                </div>
                <hr />
              </div>
            </div>
            <div className={''} style={{marginTop: '-15px', paddingBottom: '20px'}}>
              <div className='text-center mt-5 row'>
                <div className={'col-md-6'}>
                  <button
                    onClick={() => {
                      onClose()
                      scrollUnlock()
                    }}
                    type='button'
                    id='kt_sign_up_submit'
                    className='btn btn-lg btn-danger w-100 mb-5 '
                  >
                    {intl.formatMessage({id: 'CreateNewContainerPopup.cancel'})}
                  </button>
                </div>
                <div className={'col-md-6'}>
                  <button
                    onClick={() => formik.handleSubmit()}
                    type='submit'
                    id='kt_sign_up_submit'
                    className='btn btn-lg btn-primary w-100 fs-3'
                  >
                    {intl.formatMessage({id: 'Freight.createAdvert.preview.save'})}
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className={'preview-success-container mx-4'}>
            <div className={'success-image-container text-center'}>
              <img
                className={'success-image m-auto mb-10'}
                src='/media/success-black.png'
                alt='success'
              />
              <h1 className={'m-auto'}>
                {intl.formatMessage({
                  id: 'Freight.createAdvert.preview.freightSuccessfullyCreated',
                })}
              </h1>
            </div>
            <div className='text-center mt-10 row m-auto'>
              <div className={'col-md-6'}>
                <button
                  onClick={() => window.location.reload()}
                  type='submit'
                  id='kt_sign_up_submit'
                  className='btn btn-lg btn-info w-100'
                >
                  {intl.formatMessage({id: 'Freight.createAdvert.preview.createFreight'})}
                </button>
              </div>
              <div className={'col-md-6 '}>
                <button
                  onClick={() => history.push('/carrier/my-adverts')}
                  type='submit'
                  id='kt_sign_up_submit'
                  className='btn btn-lg btn-primary w-100 '
                >
                  {intl.formatMessage({id: 'Freight.createAdvert.preview.goToMyFreights'})}
                </button>
              </div>
            </div>
          </div>
        )}
      </RightLayoutPopup>
    </>
  )
}

export default PreviewScreen
