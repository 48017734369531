/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import clsx from 'clsx'
import {shallowEqual, useSelector} from 'react-redux'

import {RootState} from '../../../setup'

import DataTableAdvanced from '../../Components/DataTableAdvanced'
import Loading from '../../Components/Loading'
import RightLayoutPopup from '../RightLayoutPopup'

import useScrollLock from '../../../utils/useScrollLock'
import {exceptThisSymbols} from '../../../utils/common'
import Dropbox from '../Dropbox'

import './styles.scss'

const AddNewFreightPopup = ({
  onClose,
  onSubmit,
  transporterList = [],
  currencyList = [],
  proposalScopeList = [],
  setSelectedTransporterType,
  setSelectedTransporterId,
  selectedTransporterType,
  pageTransporterIndex,
  onTransporterPaginationChange,
}: any) => {
  const intl = useIntl()
  const role: any = useSelector<RootState>(({auth}) => auth.role, shallowEqual)
  const [loading, setLoading] = useState<boolean>(false)
  const [price, setPrice] = useState<any>(null)
  const [currency, setCurrency] = useState<any>('')
  const [proposalScope, setProposalScope] = useState<any>('')
  const [description, setDescription] = useState<any>('')

  const {scrollLock, scrollUnlock} = useScrollLock()
  useEffect(() => {
    scrollLock()
  }, [])

  return (
    <RightLayoutPopup
      title={intl.formatMessage({id: 'Carrier.freightPosting.create.create'})}
      className='create-new-freight-wrap'
      isCloseIcon
      closeOnClick={() => onClose()}
    >
      {loading && <Loading />}
      {!transporterList.length && (
        <div className='alert alert-warning mb-5 fw-bolder' role='alert'>
          {intl.formatMessage({id: 'global.noData'})}
        </div>
      )}
      <div className='table-wrap'>
        <DataTableAdvanced
          columns={[
            {
              Header: 'ID',
              accessor: 'id',
            },
            {
              Header: intl.formatMessage({id: 'Carrier.transporters.driverName'}),
              accessor: 'driverName',
            },
            {
              Header: intl.formatMessage({id: 'Carrier.transporters.driverSurName'}),
              accessor: 'driverSurName',
            },
            {
              Header: intl.formatMessage({id: 'Carrier.transporters.vehiclePlate'}),
              accessor: 'vehiclePlate',
            },
            {
              Header: intl.formatMessage({id: 'Carrier.transporters.trailerPlate'}),
              accessor: 'trailerPlate',
            },
          ]}
          data={
            transporterList &&
            transporterList?.map((item: any) => {
              return {
                id: item?.transporterId,
                driverName: item?.driver?.name,
                driverSurName: item?.driver?.surname,
                vehiclePlate: item?.vehicle?.plate,
                trailerPlate: item?.trailer?.plate,
                isFreightPostings: true,
              }
            })
          }
          selectedRowData={selectedTransporterType}
          onRowClick={(row: any) => {
            setSelectedTransporterType(row.id)
            setSelectedTransporterId(row?.values?.id)
          }}
          setPageMIndex={onTransporterPaginationChange}
          pageMIndex={pageTransporterIndex}
        />
        <div className={'row mt-5'}>
          <div className={'col-md-12 row mb-5'}>
            <div className={'col-md-4 mb-5'}>
              <label className={'form-label fw-bolder text-dark fs-6 mb-2'}>
                {intl.formatMessage({id: 'Carrier.freightPosting.offered.price'})}
              </label>
              <input
                type='number'
                id={'value'}
                step='any'
                onKeyDown={(e) => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                placeholder={intl.formatMessage({id: 'Admin.Adverts.value'})}
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                min={0}
                className={clsx('form-control', {
                  'price-input-wrap': price,
                })}
              />
            </div>
            <div className={'col-md-4'}>
              <label className='form-label fs-6 fw-bolder text-dark'>
                {intl.formatMessage({id: 'Admin.Adverts.currency'})}
              </label>
              <Dropbox
                hideArrow
                customClass={'form-select'}
                placeholder={intl.formatMessage({id: 'Admin.Adverts.currency'})}
                customSearchValue={''}
                customOnSearch={(e: any) => {}}
                disabledSearch
                onScroll={() => {}}
                setScrollCalled={() => {}}
                currentPageIndex={0}
                menuList={
                  currencyList &&
                  currencyList.map((item: any) => {
                    return {
                      id: item?.currencyId,
                      title: item?.description,
                    }
                  })
                }
                selectedItemData={(item: any) => {
                  if (item?.id) {
                    setCurrency(item?.id)
                  }
                }}
              />
            </div>
            <div className={'col-md-4 mb-5'}>
              <label className='form-label fw-bolder text-dark fs-6 mb-2'>
                {intl.formatMessage({id: 'Admin.Adverts.createProposal.proposalScope'})}
              </label>
              <Dropbox
                hideArrow
                id={'proposalScope'}
                customClass={'form-select'}
                placeholder={intl.formatMessage({id: 'Admin.Adverts.createProposal.proposalScope'})}
                customSearchValue={''}
                customOnSearch={(e: any) => {}}
                disabledSearch
                onScroll={() => {}}
                setScrollCalled={() => {}}
                currentPageIndex={0}
                menuList={
                  proposalScopeList &&
                  proposalScopeList.map((item: any) => {
                    return {
                      id: item?.proposalScopeId,
                      title: item?.description,
                    }
                  })
                }
                selectedItemData={(item: any) => {
                  if (item?.id) {
                    setProposalScope(item?.id)
                  }
                }}
              />
            </div>
          </div>
          <div>
            <label className='class="form-label fw-bolder text-dark fs-6 mb-2'>
              {intl.formatMessage({id: 'Admin.Adverts.createProposal.description'})}
            </label>
            <textarea
              rows={6}
              placeholder={intl.formatMessage({
                id: 'Admin.Adverts.createProposal.description.placeHolder',
              })}
              disabled={proposalScope !== 4}
              autoComplete='off'
              className='form-control form-control-lg form-control-solid bg-white'
              value={description}
              onChange={(e: any) => setDescription(e.target.value)}
            />
          </div>
        </div>
        <div className='text-center mt-5 row freightPost-popup-container'>
          <div className={'col-md-6'}>
            <button
              onClick={() => {
                onClose()
                scrollUnlock()
              }}
              type='button'
              id='kt_sign_up_submit'
              className='btn btn-lg btn-danger w-100 mb-5 '
            >
              {intl.formatMessage({id: 'CreateNewContainerPopup.cancel'})}
            </button>
          </div>
          <div className={'col-md-6'}>
            <button
              onClick={() => onSubmit(price, currency, proposalScope, description)}
              disabled={
                (role.includes('FORWARDER_OPERATOR') ? false : !selectedTransporterType) ||
                !price ||
                !currency ||
                !proposalScope
              }
              type='button'
              id='kt_sign_up_submit'
              className='btn btn-lg btn-primary w-100 mb-5'
            >
              {intl.formatMessage({id: 'Carrier.drivers.create.create'})}
            </button>
          </div>
        </div>
      </div>
    </RightLayoutPopup>
  )
}

export default AddNewFreightPopup
