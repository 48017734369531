import React, {useEffect, useState} from 'react'
import {toast} from 'react-toastify'
import {useIntl} from 'react-intl'
import {useHistory} from 'react-router-dom'
import {shallowEqual, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'

import {RootState} from '../../../../setup'
import Loading from '../../../Components/Loading'
import DataTableAdvanced from '../../../Components/DataTableAdvanced'
import Dropbox from '../../../Components/Dropbox'
import CardComponent from '../../DefaultPages/NewHomePage/CardComponent/CardComponent'

import {priceConversion, getCountries} from '../../../../utils/common'
import {PageTitle} from '../../../../_metronic/layout/core'
import getDateTime from '../../../../utils/common'
import {KTSVG} from '../../../../_metronic/helpers'

import {FreightPostingsService} from '../../../../services/CarrierServices/FreightPostingsServices'
import {CommonService} from '../../../../services/FreightOperatorServices/CommonService'
import {VehiclesService} from '../../../../services/CarrierServices/VehiclesService'
import {ListingService} from '../../../../services/FreightOperatorServices/ListingService'

import './PublicFreightPostings.scss'

const PublicFreightPostings = () => {
  const intl = useIntl()
  const history = useHistory()
  /**
   ** UTILS
   **/
  const frieghtPostServ = new FreightPostingsService()
  const commonServ = new CommonService()
  const vehicleServ = new VehiclesService()
  const listingServ = new ListingService()

  const isAuthorized = useSelector<RootState>(({auth}) => auth.user, shallowEqual)
  const role: any = useSelector<RootState>(({auth}) => auth.role, shallowEqual)

  const [loading, setLoading] = useState<boolean>(false)
  const [pageMIndex, setPageMIndex] = useState<number>(0)

  const [dashboardDetails, setDashboardDetails] = useState<any>({})
  const [filterData, setFilterData] = useState<any>({})
  const [selectedCountryNameFilter, setSelectedCountryNameFilter] = useState<any>({})

  /*List States*/
  const [freightPostingList, setFreightPostingList] = useState<any[]>([])

  const [fromCityList, setFromCityList] = useState<any>([])
  const [toCityList, setToCityList] = useState<any>([])
  const [fromCountryList, setFromCountryList] = useState<any>([])
  const [toCountryList, setToCountryList] = useState<any>([])
  const [transportTypeList, setTransportTypeList] = useState<any>([])
  const [vehicleTypeList, setVehicleTypeList] = useState<any>([])

  const [showFilterPanel, setShowFilterPanel] = useState<boolean>(false)

  const [fromCountrySearch, setFromCountrySearch] = useState<string>('')
  const [fromCountryPageIndex, setFromCountryPageIndex] = useState<number>(0)
  const [fromCountryId, setFromCountryId] = useState<string>('')

  const [fromCitySearch, setFromCitySearch] = useState<string>('')
  const [fromCityPageIndex, setFromCityPageIndex] = useState<number>(0)
  const [fromCityId, setFromCityId] = useState<string>('all')

  const [toCountrySearch, setToCountrySearch] = useState<string>('')
  const [toCountryPageIndex, setToCountryPageIndex] = useState<number>(0)
  const [toCountryId, setToCountryId] = useState<string>('')

  const [toCitySearch, setToCitySearch] = useState<string>('')
  const [toCityPageIndex, setToCityPageIndex] = useState<number>(0)
  const [toCityId, setToCityId] = useState<string>('all')

  const [transportTypeSearch, setTransportTypeSearch] = useState<string>('')
  const [transportTypePageIndex, setTransportTypePageIndex] = useState<number>(0)
  const [transportTypeId, setTransportTypeId] = useState<string>('all')

  const [vehicleTypeSearch, setVehicleTypeSearch] = useState<string>('')
  const [vehicleTypePageIndex, setVehicleTypePageIndex] = useState<number>(0)
  const [vehicleTypeId, setVehicleTypeId] = useState<string>('all')

  const [dependentFromCity, setDependentFromCity] = useState<boolean>(false)
  const [dependentToCity, setDependentToCity] = useState<boolean>(false)

  /*Screens and modals*/

  const [selectedFromFilterCountry, setSelectedFromFilterCountry] = useState<any>('')
  const [selectedToFilterCountry, setSelectedToFilterCountry] = useState<any>('')

  const [fromCountryScrollAPICalled, setFromCountryScrollAPICalled] = useState<boolean>(false)
  const [fromCityScrollAPICalled, setFromCityScrollAPICalled] = useState<boolean>(false)
  const [toCountryScrollAPICalled, setToCountryScrollAPICalled] = useState<boolean>(false)
  const [toCityScrollAPICalled, setToCityScrollAPICalled] = useState<boolean>(false)
  const [transportTypeScrollAPICalled, setTransportTypeScrollAPICalled] = useState<boolean>(false)

  const [vehicleTypeScrollAPICalled, setVehicleTypeScrollAPICalled] = useState<boolean>(false)

  const [isNextPage, setIsNextPage] = useState<boolean>(false)

  /**
   *! API CALL START
   **/
  const getFreightPostingList = (pageIndex?: number) => {
    frieghtPostServ
      .getPublicFreightPostings(
        pageIndex,
        '',
        fromCountryId === 'all' ? '' : fromCountryId,
        fromCityId === 'all' ? '' : fromCityId,
        toCountryId === 'all' ? '' : toCountryId,
        toCityId === 'all' ? '' : toCityId,
        transportTypeId === 'all' ? '' : transportTypeId,
        vehicleTypeId === 'all' ? '' : vehicleTypeId
      )
      .then((resp: any) => {
        setFreightPostingList(resp?.data)
        setLoading(false)
        setIsNextPage(false)
      })
      .catch((e: any) => {
        setLoading(false)
        setIsNextPage(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  useEffect(() => {
    setLoading(true)
    getFreightPostingList(pageMIndex)
  }, [fromCountryId, fromCityId, toCountryId, toCityId, transportTypeId, vehicleTypeId])

  useEffect(() => {
    fromCountryId && getFromSearchCityList(fromCountryId, 0)
  }, [fromCountryId])

  useEffect(() => {
    toCountryId && getToSearchCityList(toCountryId, 0)
  }, [toCountryId])

  /**
   *! API CALL END
   **/

  const getPublicContainerDashboardList = () => {
    listingServ
      .getPublicContainerDashboard()
      .then((resp: any) => setDashboardDetails(resp?.data))
      .catch((e) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const getFromCountryList = (pageIndex: number = 0, searchText?: string) => {
    commonServ
      .publicCountrySearchList(searchText ?? '', pageIndex ?? 0)
      .then((resp: any) => {
        setFromCountryScrollAPICalled(false)
        if (pageIndex === 0)
          setFromCountryList(
            [
              {
                code: 'all',
                name: intl.formatMessage({id: 'Admin.Adverts.all'}),
              },
            ].concat(resp?.data?.filter((item: any) => item?.name))
          )
        else setFromCountryList((item: any) => [...item, ...resp?.data])
        setLoading(false)
      })
      .catch((e: any) => {
        setFromCountryScrollAPICalled(false)
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const getFromSearchCityList = (
    countryCode: string,
    pageIndex: number = 0,
    searchText?: string
  ) => {
    commonServ
      .publicCitySearchList(countryCode, searchText ?? '', pageIndex ?? 0)
      .then((resp: any) => {
        setFromCityScrollAPICalled(false)
        if (pageIndex === 0)
          setFromCityList(
            [
              {
                cityId: 'all',
                name: intl.formatMessage({id: 'Admin.Adverts.all'}),
              },
            ].concat(resp?.data?.filter((item: any) => item?.name))
          )
        else setFromCityList((item: any) => [...item, ...resp?.data])
        setLoading(false)
      })
      .catch((e: any) => {
        setFromCityScrollAPICalled(false)
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const getToCountryList = (pageIndex: number = 0, searchText?: string) => {
    commonServ
      .publicCountrySearchList(searchText ?? '', pageIndex ?? 0)
      .then((resp: any) => {
        setToCountryScrollAPICalled(false)
        if (pageIndex === 0)
          setToCountryList(
            [
              {
                code: 'all',
                name: intl.formatMessage({id: 'Admin.Adverts.all'}),
              },
            ].concat(resp?.data?.filter((item: any) => item?.name))
          )
        else setToCountryList((item: any) => [...item, ...resp?.data])
        setLoading(false)
      })
      .catch((e: any) => {
        setToCountryScrollAPICalled(false)
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const getToSearchCityList = (countryCode: string, pageIndex: number = 0, searchText?: any) => {
    commonServ
      .publicCitySearchList(countryCode, searchText ?? '', pageIndex ?? 0)
      .then((resp: any) => {
        setToCityScrollAPICalled(false)
        if (pageIndex === 0)
          setToCityList(
            [
              {
                cityId: 'all',
                name: intl.formatMessage({id: 'Admin.Adverts.all'}),
              },
            ].concat(resp?.data?.filter((item: any) => item?.name))
          )
        else setToCityList((item: any) => [...item, ...resp?.data])
        setLoading(false)
      })
      .catch((e: any) => {
        setToCityScrollAPICalled(false)
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const getTransportTypeList = (pageIndex: number = 0, searchText?: any) => {
    commonServ
      .publicTransportTypeList(searchText ?? '', pageIndex ?? 0)
      .then((resp: any) => {
        setTransportTypeScrollAPICalled(false)
        if (pageIndex === 0)
          setTransportTypeList(
            [
              {
                cityId: 'all',
                name: intl.formatMessage({id: 'Admin.Adverts.all'}),
              },
            ].concat(resp?.data?.filter((item: any) => item?.description))
          )
        else setTransportTypeList((item: any) => [...item, ...resp?.data])
        setLoading(false)
      })
      .catch((e: any) => {
        setTransportTypeScrollAPICalled(false)
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const getVehicleTypeList = (pageIndex: number = 0, searchText?: any) => {
    vehicleServ
      .getPublicVehicleTypes(pageIndex ?? 0, 20, searchText ?? '')
      .then((resp: any) => {
        setVehicleTypeScrollAPICalled(false)
        if (pageIndex === 0)
          setVehicleTypeList(
            [
              {
                cityId: 'all',
                name: intl.formatMessage({id: 'Admin.Adverts.all'}),
              },
            ].concat(resp?.data?.filter((item: any) => item?.description))
          )
        else setVehicleTypeList((item: any) => [...item, ...resp?.data])
        setLoading(false)
      })
      .catch((e: any) => {
        setVehicleTypeScrollAPICalled(false)
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const onPaginationChange = (page: number) => {
    if (!isNextPage) {
      getFreightPostingList(page)
      setPageMIndex(page)
    }
    setIsNextPage(true)
  }

  useEffect(() => {
    setLoading(true)
    getFreightPostingList(pageMIndex)
    getFromCountryList()
    getToCountryList()
    getTransportTypeList()
    getVehicleTypeList()
    getPublicContainerDashboardList()

    if (isAuthorized) {
      if (role?.includes('CARRIER_OPERATOR')) {
        history.push('/carrier/freight-postings')
      } else if (role?.includes('FORWARDER_OPERATOR')) {
        history.push('/forwarder/freight-postings')
      } else if (role?.includes('SYSTEM_OPERATOR')) {
        history.push('/admin/freight-postings')
      }
    }
  }, [])

  useEffect(() => {
    if (
      filterData &&
      Object.keys(filterData).length > 0 &&
      filterData?.fromCountryCode !== '' &&
      selectedCountryNameFilter &&
      Object.keys(selectedCountryNameFilter)?.length > 0 &&
      selectedCountryNameFilter?.selectedFromFilterCountry !== ''
    ) {
      setFromCountryId(filterData?.fromCountryCode)
      setFromCityId('')
      setDependentFromCity(true)
      setSelectedFromFilterCountry(selectedCountryNameFilter?.selectedFromFilterCountry)
    }

    if (
      filterData &&
      Object.keys(filterData).length > 0 &&
      filterData?.toCountryCode !== '' &&
      selectedCountryNameFilter &&
      Object.keys(selectedCountryNameFilter)?.length > 0 &&
      selectedCountryNameFilter?.selectedToFilterCountry !== ''
    ) {
      setToCountryId(filterData?.toCountryCode)
      setToCityId('')
      setDependentToCity(true)
      setSelectedToFilterCountry(selectedCountryNameFilter?.selectedToFilterCountry)
    }
  }, [filterData, selectedCountryNameFilter])

  return (
    <div style={{padding: '20px 20px', overflow: 'auto'}} id='freight-post-public'>
      {loading && <Loading />}

      <div className='row col-md-2 justify-content-end w-100'>
        <Link
          to='/'
          className='link-primary fs-6 fw-bolder'
          style={{
            display: 'flex',
            justifyContent: 'end',
          }}
        >
          {intl.formatMessage({id: 'freight.public.home'})}
        </Link>
      </div>

      <div style={{display: 'flex', justifyContent: 'center'}} className='mb-15'>
        <h1>{intl.formatMessage({id: 'public.newLandingPage.freightExc'})}</h1>
      </div>

      <div className='row db-body-area-inner'>
        <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12'>
          <CardComponent
            dashboardDetails={dashboardDetails}
            handleExternalFilterOpen={() => {
              if (!showFilterPanel) {
                setShowFilterPanel(!showFilterPanel)
              }
            }}
            handleExternalFilterData={(data: any) => {
              if (Object.keys(data).length > 0) {
                setFilterData({...filterData, ...data})
              } else {
                Object.keys(filterData).forEach((i) => (filterData[i] = null))
                setFilterData({...filterData})
              }
            }}
            handleExternalCountryName={(data: any) => {
              if (data) {
                setSelectedCountryNameFilter({...selectedCountryNameFilter, ...data})
              } else {
                Object.keys(selectedCountryNameFilter).forEach(
                  (i) => (selectedCountryNameFilter[i] = null)
                )
                setSelectedCountryNameFilter({...selectedCountryNameFilter})
              }
            }}
            isFilter={showFilterPanel}
          />
        </div>
      </div>

      <div className={'row justify-content-end mb-5'}>
        <span
          className={`${
            showFilterPanel ? 'col-md-3' : 'col-md-2'
          } menu-icon d-flex justify-content-end`}
        >
          <div
            className={`btn btn-icon btn-active-light-primary btn-custom ${
              showFilterPanel ? 'menu-button-active' : ''
            }`}
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='bottom'
            onClick={() => setShowFilterPanel(!showFilterPanel)}
          >
            <KTSVG path='/media/filtering.svg' className='svg-icon-2x' />
          </div>
        </span>
      </div>

      {showFilterPanel && (
        <div className='row justify-content-between'>
          <div className='mb-10 col-md-2'>
            <h6 className={'mb-3'}>
              {intl.formatMessage({id: 'addNewAddressPopup.area2.fromCountry'})}
            </h6>
            <div className={''}>
              <Dropbox
                key={selectedFromFilterCountry}
                hideArrow
                customClass={'form-select'}
                placeholder={intl.formatMessage({
                  id: 'addNewAddressPopup.area2.country.placeholder',
                })}
                customSearchValue={fromCountrySearch}
                customOnSearch={(e: any) => {
                  setFromCountryPageIndex(0)
                  setFromCountrySearch(e.target.value)
                  getFromCountryList(0, e.target.value)
                }}
                onScroll={(val: any) => {
                  setFromCountryPageIndex(val)
                  getFromCountryList(val, fromCountrySearch)
                }}
                setScrollCalled={() => {
                  setFromCountryScrollAPICalled(true)
                }}
                scrollAPIResponse={fromCountryScrollAPICalled}
                currentPageIndex={fromCountryPageIndex}
                isTemplate
                menuList={
                  fromCountryList &&
                  fromCountryList
                    ?.filter(function ({code}: any) {
                      // @ts-ignore
                      return !this[code] && (this[code] = code)
                    }, {})
                    ?.map((item: any) => {
                      return {
                        id: item?.code,
                        title: (
                          <div className='countryWrap'>
                            <KTSVG path={getCountries[item?.code]} className='svg-icon-2x' />
                            {item?.name}
                          </div>
                        ),
                      }
                    })
                }
                defaultClearItem={{
                  id: 'all',
                  title: intl.formatMessage({id: 'Admin.Adverts.all'}),
                }}
                defaultSelected={{id: fromCountryId, title: selectedFromFilterCountry}}
                selectedItemData={(item: any) => {
                  if (item?.id) {
                    setFromCountryId(item?.id)
                    setSelectedFromFilterCountry(item?.title)
                    setDependentFromCity(true)
                    setFromCityId('')
                  }
                }}
              />
            </div>
          </div>

          <div className='mb-10 col-md-2'>
            <h6 className={'mb-3'}>
              {intl.formatMessage({id: 'addNewAddressPopup.area2.fromCity'})}
            </h6>
            <div className={''}>
              <Dropbox
                hideArrow
                disabled={!fromCountryId || fromCountryId === 'all'}
                customClass={'form-select'}
                placeholder={intl.formatMessage({id: 'addNewAddressPopup.area2.city.placeholder'})}
                customSearchValue={fromCitySearch}
                customOnSearch={(e: any) => {
                  setFromCityPageIndex(0)
                  setFromCitySearch(e.target.value)
                  getFromSearchCityList(fromCountryId, 0, e.target.value)
                }}
                onScroll={(val: any) => {
                  if (fromCountryId) {
                    setFromCityPageIndex(val)
                    getFromSearchCityList(fromCountryId, val, fromCountrySearch)
                  }
                }}
                setScrollCalled={() => {
                  setFromCityScrollAPICalled(true)
                }}
                scrollAPIResponse={fromCityScrollAPICalled}
                currentPageIndex={fromCityPageIndex}
                menuList={
                  fromCityList &&
                  fromCityList
                    ?.filter(function ({cityId}: any) {
                      // @ts-ignore
                      return !this[cityId] && (this[cityId] = cityId)
                    }, {})
                    ?.map((item: any) => {
                      return {id: item?.cityId, title: item?.name}
                    })
                }
                defaultClearItem={{
                  id: 'all',
                  title: intl.formatMessage({id: 'Admin.Adverts.all'}),
                }}
                defaultSelected={{id: fromCityId}}
                dependentValue={dependentFromCity}
                selectedItemData={(item: any) => {
                  if (item?.id) {
                    setFromCityId(item?.id)
                    setDependentFromCity(false)
                  }
                }}
              />
            </div>
          </div>

          <div className='mb-10 col-md-2'>
            <h6 className={'mb-3'}>
              {intl.formatMessage({id: 'addNewAddressPopup.area2.toCountry'})}
            </h6>
            <div className={''}>
              <Dropbox
                key={selectedToFilterCountry}
                hideArrow
                customClass={'form-select'}
                placeholder={intl.formatMessage({
                  id: 'addNewAddressPopup.area2.country.placeholder',
                })}
                customSearchValue={toCountrySearch}
                customOnSearch={(e: any) => {
                  setToCountryPageIndex(0)
                  setToCountrySearch(e.target.value)
                  getToCountryList(0, e.target.value)
                }}
                onScroll={(val: any) => {
                  setToCountryPageIndex(val)
                  getToCountryList(val, toCountrySearch)
                }}
                setScrollCalled={() => {
                  setToCountryScrollAPICalled(true)
                }}
                defaultClearItem={{
                  id: 'all',
                  title: intl.formatMessage({id: 'Admin.Adverts.all'}),
                }}
                scrollAPIResponse={toCountryScrollAPICalled}
                defaultSelected={{id: toCountryId, title: selectedToFilterCountry}}
                currentPageIndex={toCountryPageIndex}
                isTemplate
                menuList={
                  toCountryList &&
                  toCountryList
                    ?.filter(function ({code}: any) {
                      // @ts-ignore
                      return !this[code] && (this[code] = code)
                    }, {})
                    ?.map((item: any) => {
                      return {
                        id: item?.code,
                        title: (
                          <div className='countryWrap'>
                            <KTSVG path={getCountries[item?.code]} className='svg-icon-2x' />
                            {item?.name}
                          </div>
                        ),
                      }
                    })
                }
                selectedItemData={(item: any) => {
                  if (item?.id) {
                    setToCountryId(item?.id)
                    setSelectedToFilterCountry(item?.title)
                    setDependentToCity(true)
                    setToCityId('')
                  }
                }}
              />
            </div>
          </div>

          <div className='mb-10 col-md-2'>
            <h6 className={'mb-3'}>
              {intl.formatMessage({id: 'addNewAddressPopup.area2.toCity'})}
            </h6>
            <div className={''}>
              <Dropbox
                hideArrow
                disabled={!toCountryId || toCountryId === 'all'}
                customClass={'form-select'}
                placeholder={intl.formatMessage({id: 'addNewAddressPopup.area2.city.placeholder'})}
                customSearchValue={toCitySearch}
                customOnSearch={(e: any) => {
                  setToCityPageIndex(0)
                  setToCitySearch(e.target.value)
                  getToSearchCityList(toCountryId, 0, e.target.value)
                }}
                onScroll={(val: any) => {
                  if (toCountryId) {
                    setToCityPageIndex(val)
                    getToSearchCityList(toCountryId, val, fromCountrySearch)
                  }
                }}
                setScrollCalled={() => {
                  setToCityScrollAPICalled(true)
                }}
                scrollAPIResponse={toCityScrollAPICalled}
                currentPageIndex={toCityPageIndex}
                dependentValue={dependentToCity}
                menuList={
                  toCityList &&
                  toCityList
                    ?.filter(function ({cityId}: any) {
                      // @ts-ignore
                      return !this[cityId] && (this[cityId] = cityId)
                    }, {})
                    ?.map((item: any) => {
                      return {id: item?.cityId, title: item?.name}
                    })
                }
                defaultClearItem={{
                  id: 'all',
                  title: intl.formatMessage({id: 'Admin.Adverts.all'}),
                }}
                defaultSelected={{id: toCityId}}
                selectedItemData={(item: any) => {
                  if (item?.id) {
                    setToCityId(item?.id)
                    setDependentToCity(false)
                  }
                }}
              />
            </div>
          </div>

          <div className='mb-10 col-md-2'>
            <h6 className={'mb-3'}>{intl.formatMessage({id: 'Freight.myAdvert.transportType'})}</h6>
            <div className={''}>
              <Dropbox
                hideArrow
                customClass={'form-select'}
                placeholder={intl.formatMessage({id: 'Freight.myAdvert.transportType'})}
                customSearchValue={transportTypeSearch}
                customOnSearch={(e: any) => {
                  setTransportTypePageIndex(0)
                  setTransportTypeSearch(e.target.value)
                  getTransportTypeList(0, e.target.value)
                }}
                onScroll={(val: any) => {
                  setTransportTypePageIndex(val)
                  getTransportTypeList(val, transportTypeSearch)
                }}
                setScrollCalled={() => {
                  setTransportTypeScrollAPICalled(true)
                }}
                scrollAPIResponse={transportTypeScrollAPICalled}
                currentPageIndex={transportTypePageIndex}
                menuList={
                  transportTypeList &&
                  transportTypeList
                    ?.filter(function ({transportTypeId}: any) {
                      // @ts-ignore
                      return !this[transportTypeId] && (this[transportTypeId] = transportTypeId)
                    }, {})
                    ?.map((item: any) => {
                      return {id: item?.transportTypeId, title: item?.description}
                    })
                }
                defaultSelected={{id: transportTypeId}}
                defaultClearItem={{
                  id: '',
                  title: '',
                }}
                selectedItemData={(item: any) => {
                  if (item?.id) {
                    setTransportTypeId(item?.id)
                  }

                  if (item?.id === '' && item?.title === '') {
                    setTransportTypeId('')
                  }
                }}
              />
            </div>
          </div>

          <div className='mb-10 col-md-2'>
            <h6 className={'mb-3'}>
              {intl.formatMessage({id: 'Carrier.vehicles.create.vehicleType'})}
            </h6>
            <div className={''}>
              <Dropbox
                hideArrow
                customClass={'form-select'}
                placeholder={intl.formatMessage({
                  id: 'Carrier.vehicles.create.vehicleType.placeHolder',
                })}
                customSearchValue={vehicleTypeSearch}
                customOnSearch={(e: any) => {
                  setVehicleTypePageIndex(0)
                  setVehicleTypeSearch(e.target.value)
                  getVehicleTypeList(0, e.target.value)
                }}
                onScroll={(val: any) => {
                  setVehicleTypePageIndex(val)
                  getVehicleTypeList(val, vehicleTypeSearch)
                }}
                setScrollCalled={() => {
                  setVehicleTypeScrollAPICalled(true)
                }}
                scrollAPIResponse={vehicleTypeScrollAPICalled}
                currentPageIndex={vehicleTypePageIndex}
                menuList={
                  vehicleTypeList &&
                  vehicleTypeList
                    ?.filter(function ({vehicleTypeId}: any) {
                      // @ts-ignore
                      return !this[vehicleTypeId] && (this[vehicleTypeId] = vehicleTypeId)
                    }, {})
                    ?.map((item: any) => {
                      return {id: item?.vehicleTypeId, title: item?.description}
                    })
                }
                defaultClearItem={{
                  id: '',
                  title: '',
                }}
                defaultSelected={{id: vehicleTypeId}}
                selectedItemData={(item: any) => {
                  if (item?.id) {
                    setVehicleTypeId(item?.id)
                  }
                  if (item?.id === '' && item?.title === '') {
                    setVehicleTypeId('')
                  }
                }}
              />
            </div>
          </div>
        </div>
      )}

      <div>
        {!freightPostingList.length && (
          <div className='alert alert-warning mb-5 fw-bolder' role='alert'>
            {intl.formatMessage({id: 'Carrier.freightPosting.noData'})}
          </div>
        )}
        <DataTableAdvanced
          columns={[
            {
              Header: 'ID',
              accessor: 'id',
            },
            {
              Header: intl.formatMessage({id: 'Freight.createAdvert.freightContentType'}),
              accessor: 'freightContentType',
            },
            {
              Header: intl.formatMessage({id: 'Freight.createAdvert.preview.freightType'}),
              accessor: 'freightType',
            },
            {
              Header: intl.formatMessage({id: 'Freight.createAdvert.transportType'}),
              accessor: 'transportType',
            },
            {
              Header: intl.formatMessage({id: 'Carrier.vehicles.details.vehicleType'}),
              accessor: 'vehicleType',
            },
            {
              Header: intl.formatMessage({id: 'Carrier.trailer.trailerType'}),
              accessor: 'trailerType',
            },
            {
              Header: intl.formatMessage({id: 'Carrier.myAdvert.table.targetCost'}),
              accessor: 'price',
              cellStyle: {
                textAlign: 'end',
              },
            },
            {
              Header: intl.formatMessage({id: 'Carrier.freightPosting.currencyCode'}),
              accessor: 'currencyCode',
            },
            {
              Header: '',
              accessor: 'fromAddressIcon',
              isAction: true,
              Cell: ({row}: any) => (
                <>
                  <KTSVG
                    path={getCountries[row?.values?.fromAddressIcon]}
                    className='svg-icon-2x'
                  />
                </>
              ),
            },
            {
              Header: intl.formatMessage({id: 'Carrier.freightPosting.fromAddress'}),
              accessor: 'fromAddress',
            },
            {
              Header: '',
              accessor: 'toAddressIcon',
              isAction: true,
              Cell: ({row}: any) => (
                <>
                  <KTSVG path={getCountries[row?.values?.toAddressIcon]} className='svg-icon-2x' />
                </>
              ),
            },
            {
              Header: intl.formatMessage({id: 'Carrier.freightPosting.toAddress'}),
              accessor: 'toAddress',
            },
            {
              Header: intl.formatMessage({id: 'Carrier.freightPosting.plannedDepartureDate'}),
              accessor: 'plannedDepartureDate',
            },
            {
              Header: intl.formatMessage({id: 'Carrier.freightPosting.plannedArrivalDate'}),
              accessor: 'plannedArrivalDate',
            },
            {
              Header: 'appliedForTransport',
              accessor: 'appliedForTransport',
              hidden: true,
            },
            {
              Header: 'freightDetail',
              accessor: 'freightDetail',
              hidden: true,
            },
            {
              Header: 'hiddenButtons',
              accessor: 'hiddenButtons',
              hidden: true,
            },
            {
              Header: 'isContainer',
              accessor: 'isContainer',
              hidden: true,
            },
          ]}
          editableRows={['']}
          data={
            freightPostingList &&
            freightPostingList?.map((item: any) => {
              return {
                id: item?.containerId,
                freightContentType: item.freightContentType?.description,
                freightType: item.freightType?.description,
                vehicleType: item?.vehicleType?.description,
                transportType: item.transportType?.description,
                trailerType: item?.trailerType?.description,
                price:
                  item?.price && priceConversion(item?.price, item?.priceCurrency?.currencyCode),
                currencyCode: item?.priceCurrency?.currencyCode,
                fromAddressIcon: item?.fromAddress?.country?.code,
                fromAddress: item?.fromAddress?.city.name + ', ' + item?.fromAddress?.country.name,
                toAddressIcon: item?.toAddress?.country?.code,
                toAddress: item?.toAddress?.city.name + ', ' + item?.toAddress?.country.name,
                plannedDepartureDate:
                  item?.plannedDepartureDate !== null &&
                  getDateTime(item?.plannedDepartureDate, intl.locale),
                plannedArrivalDate:
                  item?.plannedArrivalDate !== null &&
                  getDateTime(item?.plannedArrivalDate, intl.locale),
                appliedForTransport: item?.appliedForTransport,
                status:
                  item?.appliedForTransport === 'Y'
                    ? intl.formatMessage({id: 'Carrier.freightPosting.status'})
                    : '',
                isFreightPostings: true,
                freightDetail: item,
                isContainer: true,
                hiddenButtons: {
                  createBtn: item?.appliedForTransport === 'Y',
                  deleteRow: item?.appliedForTransport === 'N',
                  proposalBtn: item?.appliedForTransport !== 'Y',
                },
              }
            })
          }
          createBtn={() => {
            history.push('/auth/login')
          }}
          showDetailBtn={() => {
            history.push('/auth/login')
          }}
          setPageMIndex={onPaginationChange}
          pageMIndex={pageMIndex}
        />
      </div>
    </div>
  )
}

export {PublicFreightPostings}
