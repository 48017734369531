import React from 'react'
import './DetailedListItem.scss'
import {IDetailedListItem} from './types'

const DetailedListItem = ({
  data,
  title,
  subTitle,
  stickColor,
  onClick,
  active,
}: IDetailedListItem) => {
  return (
    <div
      className={'detailed-list-item-main ' + (active ? 'active' : '')}
      onClick={onClick}
      style={{borderLeft: stickColor ? '2px solid ' + stickColor : ''}}
    >
      <div className={'d-flex align-items-center elipsis-wrap-detail-contain'}>
        <h3 className={'title text-truncate'} title={title} style={{marginBottom: 0}}>
          {title}
        </h3>
        <small className={'text-muted'} style={{marginLeft: '10px', marginRight: '5px'}}>
          {subTitle}
        </small>
        {data &&
          data?.map((item: any) => (
            <div key={item?.id} className={'col-xl-3 inside-content-wrap col-sm-6'}>
              <small className='text-muted'>{item?.title}</small>
              <p style={{marginBottom: '10px'}}>{item?.desc}</p>
            </div>
          ))}
      </div>
      {/* <div className={'row'}>
            {data && data?.map((item: any) => <div key={item?.id} className={'col-xl-3 col-sm-6'}>
                <small className="text-muted">{item?.title}</small>
                <p style={{ marginBottom: '10px'}}>{item?.desc}</p>
            </div>)}
        </div> */}
    </div>
  )
}

export default DetailedListItem
