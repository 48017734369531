import React, {useEffect} from 'react'
import {useIntl} from 'react-intl'

import RightLayoutPopup from '../../../../Components/RightLayoutPopup'

import useScrollLock from '../../../../../utils/useScrollLock'

const ShowDetails = ({companyDetails, onClose}: any) => {
  const intl = useIntl()
  const {scrollLock, scrollUnlock} = useScrollLock()
  useEffect(() => {
    scrollLock()
  }, [])

  return (
    <RightLayoutPopup
      title={intl.formatMessage({id: 'Carrier.searchDocument.show.title'})}
      className='carrier-search-document-wrap'
      isCloseIcon
      closeOnClick={() => onClose()}
    >
      <div className={'detail-modal-scroll-container'}>
        <div className={'mb-10'}>
          <div className={'row'}>
            <div className={'col-md-6 mb-10'}>
              <h6 className={'text-muted'}>
                {intl.formatMessage({id: 'Carrier.searchDocument.show.companyName'})}
              </h6>
              <b className={'fs-5'}>{companyDetails?.name}</b>
            </div>
            <div className={'col-md-6 mb-10'}>
              <h6 className={'text-muted'}>
                {intl.formatMessage({
                  id: 'Carrier.searchDocument.show.companyType',
                })}
              </h6>
              <b className={'fs-5'}>{companyDetails?.companyType?.description}</b>
            </div>
          </div>

          <div className={'row'}>
            <div className={'col-md-6 mb-10'}>
              <h6 className={'text-muted'}>
                {intl.formatMessage({id: 'Carrier.searchDocument.show.companyStatus'})}
              </h6>
              <b className={'fs-5'}>{companyDetails?.companyStatus?.description}</b>
            </div>
            <div className={'col-md-6 mb-10'}>
              <h6 className={'text-muted'}>
                {intl.formatMessage({
                  id: 'Carrier.searchDocument.show.email',
                })}
              </h6>
              <b className={'fs-5'}>{companyDetails?.email}</b>
            </div>
          </div>

          <div className={'row'}>
            <div className={'col-md-6 mb-10'}>
              <h6 className={'text-muted'}>
                {intl.formatMessage({id: 'Carrier.searchDocument.show.phone'})}
              </h6>
              <b className={'fs-5'}>{companyDetails?.phone}</b>
            </div>
            <div className={'col-md-6 mb-10'}>
              <h6 className={'text-muted'}>
                {intl.formatMessage({
                  id: 'Carrier.searchDocument.show.web',
                })}
              </h6>
              <b className={'fs-5'}>{companyDetails?.web}</b>
            </div>
          </div>

          <div className={'row'}>
            <div className={'col-md-6 mb-10'}>
              <h6 className={'text-muted'}>
                {intl.formatMessage({id: 'Carrier.searchDocument.show.taxNumber'})}
              </h6>
              <b className={'fs-5'}>{companyDetails?.taxNumber}</b>
            </div>
            <div className={'col-md-6 mb-10'}>
              <h6 className={'text-muted'}>
                {intl.formatMessage({
                  id: 'Carrier.searchDocument.show.taxOffice',
                })}
              </h6>
              <b className={'fs-5'}>{companyDetails?.taxOffice}</b>
            </div>
          </div>
        </div>
      </div>

      <div className='text-center row view-details-freight-popup freightPost-popup-container'>
        <div className={'col-md-6'}>
          <button
            onClick={() => {
              onClose()
              scrollUnlock()
            }}
            type='button'
            id='kt_sign_up_submit'
            className='btn btn-lg btn-danger w-100 mb-5 '
          >
            {intl.formatMessage({id: 'Carrier.freightPosting.view.close'})}
          </button>
        </div>
      </div>
    </RightLayoutPopup>
  )
}

export default ShowDetails
