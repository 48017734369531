import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'

import RightLayoutPopup from '../../../Components/RightLayoutPopup'

import useScrollLock from '../../../../utils/useScrollLock'

const Details = ({allData, onClose}: {allData: any; onClose: any}) => {
  const intl = useIntl()

  const {scrollLock, scrollUnlock} = useScrollLock()
  useEffect(() => {
    scrollLock()
  }, [])

  return (
    <div className={''}>
      <RightLayoutPopup
        title={intl.formatMessage({id: 'Admin.Companies.Detail.title'})}
        className='admin-details-company-wrap'
        isCloseIcon
        closeOnClick={() => onClose()}
      >
        <div className={'row'}>
          <div className='fv-row mb-10 col-md-4'>
            <h6 className={'text-muted'}>
              {intl.formatMessage({id: 'Admin.Companies.Detail.companyName'})}
            </h6>
            <b className={'fs-5'}>{allData?.name}</b>
          </div>
          <div className='fv-row mb-10 col-md-4'>
            <h6 className={'text-muted'}>
              {intl.formatMessage({id: 'Admin.Companies.Detail.companyType'})}
            </h6>
            <b className={'fs-5'}>{allData?.companyType?.description}</b>
          </div>
          <div className='fv-row mb-10 col-md-4'>
            <h6 className={'text-muted'}>
              {intl.formatMessage({id: 'Admin.Companies.Detail.country'})}
            </h6>
            <b className={'fs-5'}>{allData?.country?.name}</b>
          </div>
          <div className='fv-row mb-10 col-md-4'>
            <h6 className={'text-muted'}>
              {intl.formatMessage({id: 'Admin.Companies.Detail.status'})}
            </h6>
            <b className={'fs-5'}>{allData?.companyStatus?.description}</b>
          </div>
          <div className='fv-row mb-10 col-md-4'>
            <h6 className={'text-muted'}>
              {intl.formatMessage({id: 'Admin.Companies.Detail.website'})}
            </h6>
            <b className={'fs-5'}>{allData?.web}</b>
          </div>
          <div className='fv-row mb-10 col-md-4'>
            <h6 className={'text-muted'}>
              {intl.formatMessage({id: 'Admin.Companies.Detail.taxOffice'})}
            </h6>
            <b className={'fs-5'}>{allData.taxOffice}</b>
          </div>
          <div className='fv-row mb-10 col-md-4'>
            <h6 className={'text-muted'}>
              {intl.formatMessage({id: 'Admin.Companies.Detail.taxNumber'})}
            </h6>
            <b className={'fs-5'}>{allData.taxNumber}</b>
          </div>
        </div>
        <div className='text-center mt-5 row view-details-freight-popup freightPost-popup-container'>
          <div className={'col-md-6'}>
            <button
              onClick={() => {
                onClose()
                scrollUnlock()
              }}
              type='button'
              id='kt_sign_up_submit'
              className='btn btn-lg btn-danger w-100 mb-5 '
            >
              {intl.formatMessage({id: 'Carrier.freightPosting.view.close'})}
            </button>
          </div>
        </div>
      </RightLayoutPopup>
    </div>
  )
}

export default Details
