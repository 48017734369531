import {apiUrl, globalPageCount} from '../conf'
import axios from 'axios'

export class VehiclesService {
  getVehicles(pageIndex?: number, pageCount?: number, searchText?: string) {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        '/vehicle-management/vehicles?searchText=' +
        (searchText ?? '') +
        '&pageIndex=' +
        (pageIndex ?? 0) +
        '&pageCount=' +
        (pageCount ?? globalPageCount),
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  getVehicle(id: number) {
    const config: any = {
      method: 'GET',
      url: apiUrl + '/vehicle-management/vehicles/' + id,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  getVehicleTypes(pageIndex?: number, pageCount?: number, searchText?: string) {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        '/vehicle-management/vehicles/types?searchText=' +
        (searchText ?? '') +
        '&pageIndex=' +
        (pageIndex ?? 0) +
        '&pageCount=' +
        (pageCount ?? 10000),
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  getPublicVehicleTypes(pageIndex?: number, pageCount?: number, searchText?: string) {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        '/vehicle-management/public/vehicles/types?searchText=' +
        (searchText ?? '') +
        '&pageIndex=' +
        (pageIndex ?? 0) +
        '&pageCount=' +
        (pageCount ?? 10000),
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  getFreightPublicTypes(pageIndex?: number, pageCount?: number, searchText?: string) {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        '/freight-management/public/freights/types?searchText=' +
        (searchText ?? '') +
        '&pageIndex=' +
        (pageIndex ?? 0) +
        '&pageCount=' +
        (pageCount ?? 10000),
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  getAllMetadata(vehicleId: number) {
    const config: any = {
      method: 'GET',
      url: apiUrl + '/vehicle-management/vehicles/' + vehicleId + '/files',
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  deleteVehicle(vehicleId: number) {
    const config: any = {
      method: 'DELETE',
      url: apiUrl + '/vehicle-management/vehicles/' + vehicleId,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  updateVehicle(
    vehicleId?: number,
    year?: number,
    plate?: string,
    /*capacity?: number,*/ make?: string,
    model?: string,
    vehicleType?: number
  ) {
    const config: any = {
      method: 'PUT',
      url: apiUrl + '/vehicle-management/vehicles',
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({
        vehicleId: vehicleId && vehicleId,
        year: year && year,
        plate: plate && plate,
        /* capacity: capacity && capacity,*/
        make: make && make,
        model: model && model,
        vehicleType: {
          vehicleTypeId: vehicleType && vehicleType,
        },
      }),
    }

    return axios(config)
  }

  createVehicle(
    year?: number,
    plate?: string,
    /* capacity?: number,*/ make?: string,
    model?: string,
    vehicleType?: number
  ) {
    const config: any = {
      method: 'POST',
      url: apiUrl + '/vehicle-management/vehicles',
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({
        year: year && year,
        plate: plate && plate,
        /* capacity: capacity && capacity,*/
        make: make && make,
        model: model && model,
        vehicleType: {
          vehicleTypeId: vehicleType && vehicleType,
        },
      }),
    }

    return axios(config)
  }

  uploadVehicleLicense(vehicleId: number, file: any) {
    const data = new FormData()
    data.append('file', file)
    const config: any = {
      method: 'POST',
      url: apiUrl + '/vehicle-management/vehicles/' + vehicleId + '/files/licence/upload',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: data,
    }

    return axios(config)
  }

  uploadVehiclePlate(vehicleId: number, file: any) {
    const data = new FormData()
    data.append('file', file)
    const config: any = {
      method: 'POST',
      url: apiUrl + '/vehicle-management/vehicles/' + vehicleId + '/files/plate/upload',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: data,
    }

    return axios(config)
  }

  uploadVehicleCarnet(vehicleId: number, file: any) {
    const data = new FormData()
    data.append('file', file)
    const config: any = {
      method: 'POST',
      url: apiUrl + '/vehicle-management/vehicles/' + vehicleId + '/files/tircarnet/upload',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: data,
    }

    return axios(config)
  }
}
