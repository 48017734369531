/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'

import {PageTitle} from '../../../../_metronic/layout/core'

import StatCard from '../../../Components/StatCard/StatCard'
import Loading from '../../../Components/Loading'

import {DashboardService} from '../../../../services/AdminServices/DashboardService'

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  const dashServ = new DashboardService()

  const [loading, setLoading] = useState<boolean>(false)
  const [statistics, setStatistics] = useState<any>([])

  const getStatistics = () => {
    dashServ
      .getStatistics()
      .then((resp: any) => {
        setLoading(false)
        setStatistics(resp?.data)
      })
      .catch((e: any) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const getStat = (id: string | number, data: any) =>
    data &&
    Array.isArray(data) &&
    data.length &&
    data?.find((item: any) => item?.freightStatusId === id)?.count
      ? data?.find((item: any) => item?.freightStatusId === id)?.count
      : 0

  useEffect(() => {
    setLoading(true)
    getStatistics()
  }, [])

  return (
    <>
      {loading && <Loading />}
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({id: 'ADMIN.DASHBOARD.MAIN.TITLE'})}
      </PageTitle>
      <div className={'row'}>
        <div className={'col-xl-4 col-md-6 mb-10'}>
          <StatCard
            icon={'/media/icons/duotune/ecommerce/ecm005.svg'}
            title={intl.formatMessage({id: 'ADMIN.DASHBOARD.STATCARD.DRAFT'})}
            value={getStat(10, statistics)}
            url={'/admin/adverts?filter=draft'}
            cardColor={'primary'}
          />
        </div>
        <div className={'col-xl-4 col-md-6 mb-10'}>
          <StatCard
            icon={'/media/icons/duotune/arrows/arr031.svg'}
            title={intl.formatMessage({id: 'ADMIN.DASHBOARD.STATCARD.CARRIER_MATCHED'})}
            value={getStat(7, statistics)}
            url={'/admin/adverts?filter=ready-carrier'}
            cardColor={'primary'}
          />
        </div>
        <div className={'col-xl-4 col-md-6 mb-10'}>
          <StatCard
            icon={'/media/icons/duotune/arrows/arr031.svg'}
            title={intl.formatMessage({id: 'ADMIN.DASHBOARD.STATCARD.CONTAINER_MATCHED'})}
            value={getStat(6, statistics)}
            url={'/admin/adverts?filter=ready-container'}
            cardColor={'primary'}
          />
        </div>
        <div className={'col-xl-4 col-md-6 mb-10'}>
          <StatCard
            icon={'/media/icons/duotune/general/gen012.svg'}
            title={intl.formatMessage({id: 'ADMIN.DASHBOARD.STATCARD.WAITING_REVIEW'})}
            value={getStat(1, statistics)}
            url={'/admin/adverts?filter=waiting'}
            cardColor={'warning'}
          />
        </div>
        <div className={'col-xl-4 col-md-6 mb-10'}>
          <StatCard
            icon={'/media/icons/duotune/ecommerce/ecm006.svg'}
            title={intl.formatMessage({id: 'ADMIN.DASHBOARD.STATCARD.ONROAD'})}
            value={getStat(8, statistics)}
            url={'/admin/adverts?filter=onroad'}
            cardColor={'info'}
          />
        </div>
        <div className={'col-xl-4 col-md-6 mb-10'}>
          <StatCard
            icon={'/media/icons/duotune/arrows/arr012.svg'}
            title={intl.formatMessage({id: 'ADMIN.DASHBOARD.STATCARD.APPROVED'})}
            value={getStat(5, statistics)}
            url={'/admin/adverts?filter=approved'}
            cardColor={'success'}
          />
        </div>
        <div className={'col-xl-4 col-md-6 mb-10'}>
          <StatCard
            icon={'/media/icons/duotune/arrows/arr011.svg'}
            title={intl.formatMessage({id: 'ADMIN.DASHBOARD.STATCARD.REJECTED'})}
            value={getStat(3, statistics)}
            url={'/admin/adverts?filter=reject'}
            cardColor={'danger'}
          />
        </div>{' '}
        <div className={'col-xl-4 col-md-6 mb-10'}>
          <StatCard
            icon={'/media/icons/duotune/general/gen017.svg'}
            title={intl.formatMessage({id: 'ADMIN.DASHBOARD.STATCARD.DELIVERED'})}
            value={getStat(9, statistics)}
            url={'/admin/adverts?filter=deliver'}
            cardColor={'success'}
          />
        </div>
      </div>
    </>
  )
}

export {DashboardWrapper}
