import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useFormik} from 'formik'
import * as Yup from 'yup'

import DetailedList from '../../../../Components/DetailedList/DetailedList'
import RightLayoutPopup from '../../../../Components/RightLayoutPopup'

import useScrollLock from '../../../../../utils/useScrollLock'
import getDateTime from '../../../../../utils/common'

const AttachmentFreightToContainer = ({onClose, onSubmit, freightList}: any) => {
  const intl = useIntl()
  const {scrollLock} = useScrollLock()

  const initialValues = {
    freight: '',
  }

  const registrationSchema = Yup.object().shape({
    freight: Yup.string().required(
      intl.formatMessage({id: 'CreateNewContainerPopup.areaRequired'})
    ),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      onSubmit({
        freightId: values?.freight,
      }).then((itm: any) => {
        if (itm) {
          formik.setFieldValue('freight', '')
        }
      })
    },
  })

  useEffect(() => {
    scrollLock()
  }, [])

  return (
    <RightLayoutPopup
      title={intl.formatMessage({id: 'Admin.Adverts.attachFreight.title'})}
      className='admin-attach-freight-to-container'
      isCloseIcon
      closeOnClick={() => onClose()}
    >
      {/* begin::Form group Freight */}
      <div className='mb-10 attachment-Wrap'>
        <label htmlFor={'selectList'} className='form-label fw-bolder text-dark fs-6 mb-2'>
          {intl.formatMessage({id: 'CreateNewContainerPopup.freightAttach'})}
        </label>
        <DetailedList
          id={'selectList'}
          selectedItem={(val: any) => {
            formik.setFieldValue('freight', val)
          }}
          data={
            freightList &&
            freightList?.map((item: any) => ({
              id: item?.freightId,
              title: item?.description,
              subTitle:
                intl.formatMessage({id: 'CreateNewContainerPopup.companyLabel'}) +
                item?.company?.name,
              data: [
                {
                  title: intl.formatMessage({id: 'CreateNewContainerPopup.fromAddress'}),
                  desc: `${item?.fromAddress?.country?.name} / ${item?.fromAddress?.city?.name}`,
                },
                {
                  title: intl.formatMessage({id: 'CreateNewContainerPopup.toAddress'}),
                  desc: `${item?.toAddress?.country?.name} / ${item?.toAddress?.city?.name}`,
                },
                {
                  title: intl.formatMessage({id: 'CreateNewContainerPopup.plannedArrivalDate'}),
                  desc: getDateTime(item?.plannedArrivalDate, intl.locale),
                },
                {
                  title: intl.formatMessage({
                    id: 'CreateNewContainerPopup.plannedDepartureDate',
                  }),
                  desc: getDateTime(item?.plannedDepartureDate, intl.locale),
                },
              ],
            }))
          }
        />
        {formik.touched.freight && formik.errors.freight && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert' style={{color: '#DC3444'}}>
                {formik.errors.freight}
              </span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/*Add adress button area*/}
      <div className='text-center mt-5 row'>
        <div className={'col-md-6'}>
          <button
            onClick={onClose}
            type='button'
            id='kt_sign_up_submit'
            className='btn btn-lg btn-danger w-100 mb-5 '
          >
            {intl.formatMessage({id: 'CreateNewContainerPopup.cancel'})}
          </button>
        </div>
        <div className={'col-md-6'}>
          <button
            onClick={() => {
              formik.handleSubmit()
            }}
            type='button'
            id='kt_sign_up_submit'
            className='btn btn-lg btn-primary w-100 mb-5'
          >
            {intl.formatMessage({id: 'Admin.Adverts.attachFreight.title'})}
          </button>
        </div>
      </div>
    </RightLayoutPopup>
  )
}

export default AttachmentFreightToContainer
