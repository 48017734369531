import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import clsx from 'clsx'

import RightLayoutPopup from '../../../../Components/RightLayoutPopup'
import useScrollLock from '../../../../../utils/useScrollLock'

const CreateTransporterGroup = ({onClose, setLoading, createTransporterGroup}: any) => {
  const intl = useIntl()
  const [transporterGroupName, setTransporterGroupName] = useState<any>('')
  const [explanation, setExplanation] = useState<any>('')
  const {scrollLock} = useScrollLock()
  useEffect(() => {
    scrollLock()
  }, [])

  return (
    <RightLayoutPopup
      className='carrier-transporter-group-wrap'
      isCloseIcon
      title={intl.formatMessage({id: 'Carrier.transporterGroup.create'})}
      closeOnClick={() => onClose()}
    >
      <div>
        <div className='fv-row mb-10'>
          <label className='form-label fs-6 fw-bolder text-dark'>
            {intl.formatMessage({id: 'Carrier.transporterGroup.table.name'})}
          </label>
          <input
            placeholder={intl.formatMessage({id: 'Carrier.transporterGroup.table.name'})}
            className={clsx('form-control form-control-lg form-control-solid')}
            onChange={(event: any) => setTransporterGroupName(event.target.value)}
            value={transporterGroupName}
            type='text'
            name='name'
            autoComplete='off'
          />
        </div>

        <div className={'mb-10'}>
          <label htmlFor='rejText' className={'form-label fs-6 fw-bolder text-dark'}>
            {intl.formatMessage({id: 'Carrier.transporterGroup.table.explanation'})}
          </label>
          <textarea
            onChange={(e: any) => setExplanation(e.target.value)}
            value={explanation}
            id={'rejText'}
            className={'form-control form-control-lg form-control-solid'}
            placeholder={intl.formatMessage({id: 'Carrier.transporterGroup.table.explanation'})}
            rows={7}
            style={{resize: 'none'}}
          />
        </div>
      </div>

      <div className='text-center mt-5 row freightPost-popup-container'>
        <div className={'col-md-6'}>
          <button
            onClick={onClose}
            type='button'
            id='kt_sign_up_submit'
            className='btn btn-lg btn-danger w-100 mb-5 '
          >
            {intl.formatMessage({id: 'CreateNewContainerPopup.cancel'})}
          </button>
        </div>
        <div className={'col-md-6'}>
          <button
            className={'btn btn-success px-10 m-auto w-100'}
            type={'button'}
            disabled={!transporterGroupName || !explanation}
            onClick={() => {
              setLoading(true)
              onClose()
              createTransporterGroup(transporterGroupName, explanation)
            }}
          >
            {intl.formatMessage({id: 'Carrier.drivers.create.create'})}
          </button>
        </div>
      </div>
    </RightLayoutPopup>
  )
}

export {CreateTransporterGroup}
