import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {geocodeByPlaceId} from 'react-google-places-autocomplete'

import Map from '../../../../Components/GoogleMap/Map'
import MapAutoCompleteSelect from '../../../../Components/MapAutoCompleteSelect'
import RightLayoutPopup from '../../../../Components/RightLayoutPopup'

import {CommonService} from '../../../../../services/FreightOperatorServices/CommonService'

import useScrollLock from '../../../../../utils/useScrollLock'

const EditAddresses = ({
  setUpdatedAddress,
  updatedAddress,
  // getCityList,
  countries,
  cities,
  setLoading,
  updateAddress,
  onClose,
  latLong,
  setLatLong,
}: any) => {
  const intl = useIntl()
  const commonServ = new CommonService()
  const isEmpty = (obj: any) => !Object.keys(obj).length
  const [addressDetail, setAddressDetail] = useState<any>({})
  const [updateAddressError, setUpdateAddressError] = useState<any>({})
  const [distanceInKm, setDistanceInKm] = useState<number>(-1)
  const {scrollLock, scrollUnlock} = useScrollLock()
  const [cityList, setCityList] = useState([])
  useEffect(() => {
    scrollLock()
  }, [])

  const getCityList = (countryCode: string) => {
    commonServ.cityList(countryCode).then((resp) => {
      setCityList(resp.data)
      setLoading(false)
    })
  }

  useEffect(() => {
    if (updatedAddress?.description) {
      setAddressDetail(updatedAddress)
      getCityList(updatedAddress?.country)
    }
  }, [])

  const searchSelectedControl = async (addressData: any) => {
    !isEmpty(addressData) &&
      inputActions('fill', {
        district: addressData?.district,
        neighborhood: addressData?.neighborhood,
        addressText: addressData?.addressText,
        country: addressData?.country,
        postCode: addressData?.postCode,
        street: addressData?.street,
        streetNumber: addressData?.streetNumber,
        latitude: addressData?.latitude,
        longitude: addressData?.longitude,
      })
    if (addressData?.country) {
      const resp = await commonServ.cityList(addressData?.country)
      await setCityList(resp.data)
      await setLoading(false)
      const eq = resp?.data?.find(
        (item: any) => addressData?.city.toLowerCase() === item?.originalName.toLowerCase()
      )
      setAddressDetail((item: any) => ({
        ...item,
        city: eq?.cityId ?? null,
      }))
    }
  }

  const inputActions = (action: 'clear' | 'fill', data?: any) => {
    if (action === 'clear') {
      setAddressDetail({
        postCode: '',
        district: '',
        neighborhood: '',
        street: '',
        streetNumber: '',
        country: '',
        addressText: '',
        city: null,
        latitude: '',
        longitude: '',
      })
    } else if (data) {
      setAddressDetail((item: any) => ({
        ...item,
        postCode: data?.postCode ? data?.postCode : item.postCode,
        district: data?.district ? data?.district : item.district,
        neighborhood: data?.neighborhood ? data?.neighborhood : item.neighborhood,
        street: data?.street ? data?.street : item.street,
        streetNumber: data?.streetNumber ? data?.streetNumber : item.streetNumber,
        country: data?.country ? data?.country : item.country,
        addressText: data?.addressText ? data?.addressText : item.addressText,
        city: data?.city ? data?.city : item.city,
        latitude: data?.latitude ? data?.latitude : item.latitude,
        longitude: data?.longitude ? data?.longitude : item.longitude,
      }))
    }
  }

  return (
    <RightLayoutPopup
      title={intl.formatMessage({id: 'Freight.addresses.update.title'})}
      className='edit-address-wrap'
      isCloseIcon
      closeOnClick={() => onClose()}
    >
      <div className={'mb-5 mt-5'}>
        <label className='form-label fw-bolder text-dark fs-6'>
          {intl.formatMessage({id: 'Freight.addresses.create.searchAddress'})}
        </label>
        <MapAutoCompleteSelect
          selectedLoc={async (item: any) => {
            let locationData = item?.value?.terms?.sort((a: any, b: any) => b.offset - a.offset)

            let con: any = countries?.find((item: any) => locationData[0]?.value === item.name)
            const [place] = await geocodeByPlaceId(item?.value?.place_id)
            let addressData = {
              country: con?.code,
              city: item?.value?.terms[1]?.value,
              district: item?.value?.terms[3]?.value,
              neighborhood: item?.value?.terms[2]?.value,
              addressText: item?.value?.description,
            }
            setLatLong({
              longitude: place.geometry.location.lng(),
              latitude: place.geometry.location.lat(),
            })
            setAddressDetail((data: any) => ({
              ...data,
              addressText: item?.label,
            }))
            searchSelectedControl(addressData)
          }}
        />
      </div>
      <div className='map-container'>
        <Map
          mapType={google.maps.MapTypeId.ROADMAP}
          mapTypeControl={true}
          setDistanceInKm={setDistanceInKm}
          location={(item: any) => {
            let addressData = {
              country: item?.allData[0]?.address_components.find((item: any) =>
                item?.types?.includes('country')
              )?.short_name,
              city: item?.allData[0]?.address_components.find((item: any) =>
                item?.types?.includes('administrative_area_level_1')
              )?.short_name,
              postCode: item?.allData[0]?.address_components.find((item: any) =>
                item?.types?.includes('postal_code')
              )?.short_name,
              district: item?.allData[0]?.address_components.find((item: any) =>
                item?.types?.includes('administrative_area_level_2')
              )?.short_name,
              neighborhood: item?.allData[0]?.address_components.find((item: any) =>
                item?.types?.includes('administrative_area_level_4')
              )?.short_name,
              street: item?.allData[0]?.address_components.find((item: any) =>
                item?.types?.includes('route')
              )?.short_name,
              streetNumber: item?.allData[0]?.address_components.find((item: any) =>
                item?.types?.includes('street_number')
              )?.short_name,
              addressText: item?.allData[0]?.formatted_address,
              latitude: item?.allData[0]?.geometry?.location?.lat(),
              longitude: item?.allData[0]?.geometry?.location?.lng(),
            }
            setLatLong({})
            searchSelectedControl(addressData)
          }}
          searchLabel={addressDetail?.addressText}
          searchLat={latLong?.latitude || addressDetail?.latitude}
          searchLong={latLong?.longitude || addressDetail?.longitude}
        />
        <div className={'col-md-4 form-display-wrap'}>
          <div className={'row mb-10'}>
            {/* begin::Form group Title */}
            <div className='col-md-12 mb-5'>
              <label className='form-label fw-bolder text-dark fs-6'>
                {intl.formatMessage({id: 'Freight.addresses.update.description'})}
              </label>
              <input
                placeholder={intl.formatMessage({
                  id: 'Freight.addresses.update.description.placeHolder',
                })}
                type='text'
                autoComplete='off'
                onChange={(event: any) =>
                  setAddressDetail((item: any) => ({
                    ...item,
                    description: event.target.value,
                  }))
                }
                value={addressDetail?.description}
                className={'form-control form-control-lg form-control-solid bg-white'}
              />
              {updateAddressError && updateAddressError.description && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className={'text-danger'} role='alert'>
                      {updateAddressError.description}
                    </span>
                  </div>
                </div>
              )}
            </div>
            {/* end::Form group */}
            {/* begin::Form group Country */}
            <div className='col-md-12 mb-5'>
              <label className='form-label fw-bolder text-dark fs-6'>
                {intl.formatMessage({id: 'Freight.addresses.update.country'})}
              </label>
              <select
                placeholder={intl.formatMessage({
                  id: 'Freight.addresses.update.country.placeholder',
                })}
                onChange={(event: any) => {
                  setAddressDetail((item: any) => ({
                    ...item,
                    country: event.target.value,
                  }))
                  if (event.target.value) {
                    getCityList(event.target.value)
                  }
                }}
                value={addressDetail?.country}
                className={'form-control form-control-lg'}
              >
                <option value='' disabled>
                  {intl.formatMessage({id: 'Carrier.freight.create.country'})}
                </option>
                {countries &&
                  countries?.map((item: any, key: number) => (
                    <option
                      key={key}
                      /*selected={country === item?.code}*/
                      value={item?.code}
                    >
                      {item?.name}
                    </option>
                  ))}
              </select>
              {updateAddressError && updateAddressError.country && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className={'text-danger'} role='alert'>
                      {updateAddressError.country}
                    </span>
                  </div>
                </div>
              )}
            </div>
            {/* end::Form group */}

            {/* begin::Form group City */}
            <div className='col-md-12 mb-5'>
              <label className='form-label fw-bolder text-dark fs-6'>
                {intl.formatMessage({id: 'Freight.addresses.update.city'})}
              </label>
              <select
                disabled={!updatedAddress?.country}
                placeholder={intl.formatMessage({id: 'Freight.addresses.update.city.placeholder'})}
                onChange={(event: any) =>
                  setAddressDetail((item: any) => ({
                    ...item,
                    city: event.target.value,
                  }))
                }
                value={addressDetail?.city}
                className={'form-control form-control-lg'}
              >
                <option value='' disabled>
                  {intl.formatMessage({id: 'Carrier.freight.create.city'})}
                </option>
                {addressDetail?.country &&
                  cityList &&
                  cityList?.map((item: any) => (
                    <option key={item.cityId} value={item?.cityId}>
                      {item?.originalName}
                    </option>
                  ))}
              </select>
              {updateAddressError && updateAddressError.city && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className={'text-danger'} role='alert'>
                      {updateAddressError.city}
                    </span>
                  </div>
                </div>
              )}
            </div>
            {/* end::Form group */}
          </div>

          <div className={'row mb-10'}>
            {/* begin::Form group Street */}
            <div className='col-md-12 mb-5'>
              <label className='form-label fw-bolder text-dark fs-6'>
                {intl.formatMessage({id: 'addNewAddressPopup.area2.district1'})}
              </label>
              <input
                placeholder={intl.formatMessage({
                  id: 'addNewAddressPopup.area2.district1.placeholder',
                })}
                type='text'
                autoComplete='off'
                onChange={(event: any) =>
                  setAddressDetail((item: any) => ({
                    ...item,
                    district: event.target.value,
                  }))
                }
                value={addressDetail?.district}
                className={'form-control form-control-lg form-control-solid bg-white'}
              />
              {updateAddressError && updateAddressError.district && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className={'text-danger'} role='alert'>
                      {updateAddressError.district}
                    </span>
                  </div>
                </div>
              )}
            </div>
            {/* end::Form group */}

            {/* begin::Form group neighborhood */}
            <div className='col-md-12 mb-5'>
              <label className='form-label fw-bolder text-dark fs-6'>
                {intl.formatMessage({id: 'addNewAddressPopup.area2.neighborhood'})}
              </label>
              <input
                placeholder={intl.formatMessage({
                  id: 'addNewAddressPopup.area2.neighborhood.placeholder',
                })}
                type='text'
                autoComplete='off'
                onChange={(event: any) =>
                  setAddressDetail((item: any) => ({
                    ...item,
                    neighborhood: event.target.value,
                  }))
                }
                value={addressDetail?.neighborhood}
                className={'form-control form-control-lg form-control-solid bg-white'}
              />
              {updateAddressError && updateAddressError.neighborhood && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className={'text-danger'} role='alert'>
                      {updateAddressError.neighborhood}
                    </span>
                  </div>
                </div>
              )}
            </div>
            {/* end::Form group */}

            {/* begin::Form group PostCode */}
            <div className='col-md-12 mb-5'>
              <label className='form-label fw-bolder text-dark fs-6'>
                {intl.formatMessage({id: 'Freight.addresses.update.postCode'})}
              </label>
              <input
                placeholder={intl.formatMessage({
                  id: 'Freight.addresses.update.postCode.placeholder',
                })}
                type='text'
                autoComplete='off'
                onChange={(event: any) =>
                  setAddressDetail((item: any) => ({
                    ...item,
                    postCode: event.target.value,
                  }))
                }
                value={addressDetail?.postCode}
                className={'form-control form-control-lg form-control-solid bg-white'}
              />
              {updateAddressError && updateAddressError.postCode && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className={'text-danger'} role='alert'>
                      {updateAddressError.postCode}
                    </span>
                  </div>
                </div>
              )}
            </div>
            {/* end::Form group */}

            <div className='col-md-12 mb-5'>
              <label className='form-label fw-bolder text-dark fs-6'>
                {intl.formatMessage({id: 'addNewAddressPopup.area2.street'})}
              </label>
              <input
                placeholder={intl.formatMessage({
                  id: 'addNewAddressPopup.area2.street.placeholder',
                })}
                type='text'
                autoComplete='off'
                onChange={(event: any) =>
                  setAddressDetail((item: any) => ({
                    ...item,
                    street: event.target.value,
                  }))
                }
                value={addressDetail?.street}
                className={'form-control form-control-lg form-control-solid bg-white'}
              />
            </div>

            <div className='col-md-12 mb-5'>
              <label className='form-label fw-bolder text-dark fs-6'>
                {intl.formatMessage({id: 'addNewAddressPopup.area2.streetNumber'})}
              </label>
              <input
                placeholder={intl.formatMessage({
                  id: 'addNewAddressPopup.area2.streetNumber.placeholder',
                })}
                type='text'
                autoComplete='off'
                onChange={(event: any) =>
                  setAddressDetail((item: any) => ({
                    ...item,
                    streetNumber: event.target.value,
                  }))
                }
                value={addressDetail?.streetNumber}
                className={'form-control form-control-lg form-control-solid bg-white'}
              />
            </div>
          </div>
          <div className={'row'}>
            {/* begin::Form group Address */}
            <div className='mb-5'>
              <label className='form-label fw-bolder text-dark fs-6'>
                {intl.formatMessage({id: 'addNewAddressPopup.area2.address1'})}
              </label>
              <textarea
                rows={6}
                placeholder={intl.formatMessage({
                  id: 'addNewAddressPopup.area2.address1.placeholder',
                })}
                autoComplete='off'
                onChange={(event: any) =>
                  setAddressDetail((item: any) => ({
                    ...item,
                    addressText: event.target.value,
                  }))
                }
                value={addressDetail?.addressText}
                className={'form-control form-control-lg form-control-solid bg-white'}
              />
            </div>
            {updateAddressError && updateAddressError.addressText && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className={'text-danger'} role='alert'>
                    {updateAddressError.addressText}
                  </span>
                </div>
              </div>
            )}
            {/* end::Form group */}
          </div>
        </div>
      </div>

      <hr />

      <div className='text-center mt-5 row'>
        <div className={'col-md-6'}>
          <button
            onClick={() => {
              onClose()
              scrollUnlock()
              // setLatLong({})
            }}
            type='button'
            id='kt_sign_up_submit'
            className='btn btn-lg btn-danger mb-5 w-100 mb-5 '
          >
            {intl.formatMessage({id: 'CreateNewContainerPopup.cancel'})}
          </button>
        </div>
        <div className={'col-md-6'}>
          <button
            className={'btn btn-success px-10 m-auto w-100'}
            /*disabled={Object.values(updatedAddress).includes('')}*/
            onClick={() => {
              let addressData = {...addressDetail}
              if (!(addressData?.district && addressData?.district?.length > 0)) {
                delete addressData.district
              }

              delete addressData?.streetNumber
              delete addressData?.neighborhood
              delete addressData?.street
              delete addressData?.postCode
              delete addressData?.district
              delete addressData?.addressText

              if (Object.values(addressData).some((x) => x === null || x === '')) {
                let errorAddress: any = {}
                if (addressDetail?.description.length === 0) {
                  errorAddress.description = intl.formatMessage({
                    id: 'Freight.addresses.update.desc.required',
                  })
                }
                // if (addressDetail?.postCode.length === 0) {
                //   errorAddress.postCode = intl.formatMessage({id: 'Freight.addresses.update.postCode.required'})
                // }
                // if (!addressDetail?.district || addressDetail?.district.length === 0) {
                //   errorAddress.district = intl.formatMessage({id: 'Freight.addresses.update.district.required'})
                // }
                if (addressDetail?.country.length === 0) {
                  errorAddress.country = intl.formatMessage({
                    id: 'Freight.addresses.update.country.required',
                  })
                }
                // if (addressDetail?.addressText.length === 0) {
                //   errorAddress.addressText = intl.formatMessage({id: 'Freight.addresses.update.address.required'})
                // }
                if (addressDetail?.city.length === 0) {
                  errorAddress.city = intl.formatMessage({
                    id: 'Freight.addresses.update.city.required',
                  })
                }
                setUpdateAddressError(errorAddress)
              } else {
                setLoading(true)
                updateAddress(addressDetail)
              }
            }}
          >
            {intl.formatMessage({id: 'Freight.addresses.update.update'})}
          </button>
        </div>
      </div>
    </RightLayoutPopup>
  )
}

export default EditAddresses
