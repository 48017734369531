import React, {useEffect} from 'react'
import {useIntl} from 'react-intl'

import RightLayoutPopup from '../../../../Components/RightLayoutPopup'

import useScrollLock from '../../../../../utils/useScrollLock'

const EditInvoiceAddresses = ({
  setInvoiceValues,
  invoiceValues,
  countries,
  setSelectedCountry,
  cities,
  setLoading,
  updateAddress,
  onClose,
}: any) => {
  const intl = useIntl()
  const {scrollLock, scrollUnlock} = useScrollLock()
  useEffect(() => {
    scrollLock()
  }, [])

  return (
    <RightLayoutPopup
      title={intl.formatMessage({id: 'Freight.invoiceAddress.update.updateAccount'})}
      className='edit-invoice-address-wrap'
      isCloseIcon
      closeOnClick={() => onClose()}
    >
      <div className={''}>
        <div className={'row mt-10 mb-10'}>
          <div className='col-md-4 mb-5'>
            <label className='form-label fs-6 fw-bolder text-dark'>
              {intl.formatMessage({id: 'Freight.invoiceAddress.update.explanation'})}
            </label>
            <input
              placeholder={intl.formatMessage({id: 'Freight.invoiceAddress.update.explanation'})}
              className={'form-control form-control-lg bg-white'}
              onChange={(event: any) =>
                setInvoiceValues((item: any) => ({
                  ...item,
                  description: event.target.value,
                }))
              }
              value={invoiceValues.description}
              type='text'
              autoComplete='off'
            />
          </div>
          {/* begin::Form group Country */}
          <div className='col-md-4 mb-5'>
            <label className='form-label fw-bolder text-dark mb-2 fs-6'>
              {intl.formatMessage({id: 'addNewAddressPopup.area2.country'})}
            </label>
            <select
              placeholder={intl.formatMessage({id: 'addNewAddressPopup.area2.country.placeholder'})}
              onChange={(e: any) => {
                setSelectedCountry(e.target.value)
                setInvoiceValues((item: any) => ({
                  ...item,
                  country: e.target.value,
                }))
                setInvoiceValues((item: any) => ({
                  ...item,
                  city: '',
                }))
              }}
              value={invoiceValues.country}
              className={'form-control form-control-lg'}
            >
              <option value='' selected disabled>
                {intl.formatMessage({id: 'addNewAddressPopup.area2.country.placeholder'})}
              </option>
              {countries &&
                countries?.map((item: any, key: number) => (
                  <option key={key} value={item?.code}>
                    {item?.name}
                  </option>
                ))}
            </select>
          </div>
          {/* end::Form group */}

          {/* begin::Form group City */}
          <div className='col-md-4 mb-5'>
            <label className='form-label fw-bolder text-dark mb-2 fs-6'>
              {intl.formatMessage({id: 'addNewAddressPopup.area2.city'})}
            </label>
            <select
              disabled={!invoiceValues?.country}
              placeholder={intl.formatMessage({id: 'addNewAddressPopup.area2.city.placeholder'})}
              onChange={(e: any) => {
                setInvoiceValues((item: any) => ({
                  ...item,
                  city: e.target.value,
                }))
              }}
              value={invoiceValues.city}
              className={'form-control form-control-lg'}
            >
              <option value='' selected disabled>
                {intl.formatMessage({id: 'addNewAddressPopup.area2.city.placeholder'})}
              </option>
              {cities &&
                cities?.map((item: any, key: number) => (
                  <option key={key} value={item?.cityId}>
                    {item?.name}
                  </option>
                ))}
            </select>
          </div>
          {/* end::Form group */}

          <div className='col-md-4 mb-10'>
            <label className='form-label fs-6 fw-bolder text-dark'>
              {intl.formatMessage({id: 'addNewAddressPopup.area2.district1'})}
            </label>
            <input
              placeholder={intl.formatMessage({
                id: 'addNewAddressPopup.area2.district1.placeholder',
              })}
              className={'form-control form-control-lg bg-white'}
              onChange={(event: any) =>
                setInvoiceValues((item: any) => ({
                  ...item,
                  district: event.target.value,
                }))
              }
              value={invoiceValues.district}
              type='text'
              autoComplete='off'
            />
          </div>

          <div className='col-md-4 mb-10'>
            <label className='form-label fs-6 fw-bolder text-dark'>
              {intl.formatMessage({id: 'addNewAddressPopup.area2.neighborhood'})}
            </label>
            <input
              placeholder={intl.formatMessage({
                id: 'addNewAddressPopup.area2.neighborhood.placeholder',
              })}
              className={'form-control form-control-lg bg-white'}
              onChange={(event: any) =>
                setInvoiceValues((item: any) => ({
                  ...item,
                  neighborhood: event.target.value,
                }))
              }
              value={invoiceValues.neighborhood}
              type='text'
              autoComplete='off'
            />
          </div>

          <div className='col-md-4 mb-10'>
            <label className='form-label fs-6 fw-bolder text-dark'>
              {intl.formatMessage({id: 'addNewAddressPopup.area2.postCode'})}
            </label>
            <input
              placeholder={intl.formatMessage({
                id: 'statusAddNewAddressPopup.area2.postCode.placeHolder',
              })}
              className={'form-control form-control-lg bg-white'}
              onChange={(event: any) =>
                setInvoiceValues((item: any) => ({
                  ...item,
                  postCode: event.target.value,
                }))
              }
              value={invoiceValues.postCode}
              type='text'
              autoComplete='off'
            />
          </div>

          <div className='col-md-4 mb-10'>
            <label className='form-label fs-6 fw-bolder text-dark'>
              {intl.formatMessage({id: 'addNewAddressPopup.area2.street'})}
            </label>
            <input
              placeholder={intl.formatMessage({
                id: 'addNewAddressPopup.area2.street.placeholder',
              })}
              className={'form-control form-control-lg bg-white'}
              onChange={(event: any) =>
                setInvoiceValues((item: any) => ({
                  ...item,
                  street: event.target.value,
                }))
              }
              value={invoiceValues.street}
              type='text'
              autoComplete='off'
            />
          </div>

          <div className='col-md-4 mb-10'>
            <label className='form-label fs-6 fw-bolder text-dark'>
              {intl.formatMessage({id: 'addNewAddressPopup.area2.streetNumber'})}
            </label>
            <input
              placeholder={intl.formatMessage({
                id: 'addNewAddressPopup.area2.streetNumber.placeholder',
              })}
              className={'form-control form-control-lg bg-white'}
              onChange={(event: any) =>
                setInvoiceValues((item: any) => ({
                  ...item,
                  streetNumber: event.target.value,
                }))
              }
              value={invoiceValues.streetNumber}
              type='text'
              autoComplete='off'
            />
          </div>
          {/* <div className='col-md-12 mb-10'>
            <label className='form-label fs-6 fw-bolder text-dark'>
              {intl.formatMessage({id: 'addNewAddressPopup.area2.address1'})}
            </label>
            <textarea
              rows={3}
              placeholder={intl.formatMessage({id: 'addNewAddressPopup.area2.address1.placeHolder'})}
              autoComplete='off'
              className={'form-control form-control-lg bg-white'}
              onChange={(event: any) =>
                setInvoiceValues((item: any) => ({
                  ...item,
                  addressText: event.target.value,
                }))
              }
              value={invoiceValues.addressText}
            />
          </div> */}
        </div>
      </div>

      <div className='text-center mt-5 row freightPost-popup-container'>
        <div className={'col-md-6'}>
          <button
            onClick={() => {
              onClose()
              scrollUnlock()
            }}
            type='button'
            id='kt_sign_up_submit'
            className='btn btn-lg btn-danger w-100 mb-5 '
          >
            {intl.formatMessage({id: 'CreateNewContainerPopup.cancel'})}
          </button>
        </div>
        <div className={'col-md-6'}>
          <button
            className={'btn btn-success px-10 m-auto w-100'}
            disabled={
              !invoiceValues.invoiceAddressId ||
              !invoiceValues.description ||
              !invoiceValues.country ||
              !invoiceValues.city ||
              !invoiceValues.postCode
            }
            onClick={() => {
              setLoading(true)
              updateAddress(invoiceValues)
            }}
          >
            {intl.formatMessage({id: 'Freight.invoiceAddress.update.update'})}
          </button>
        </div>
      </div>
    </RightLayoutPopup>
  )
}

export default EditInvoiceAddresses
