import React, {useEffect, useState} from 'react'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const PhoneInp = ({
  onSelect,
  className,
  defaultValue,
  id,
  disabled,
  onClickSelect,
  countryCodeEditable = true,
  isDisbaledInput = false,
  onChangeCountry = true,
}: any) => {
  const [phone, setPhone] = useState<any>(null)
  useEffect(() => {
    defaultValue && setPhone(defaultValue)
  }, [defaultValue])
  return (
    <div id={id}>
      {isDisbaledInput ? (
        <PhoneInput
          disabled={disabled}
          inputClass={className ?? 'form-control form-control-lg form-control-solid'}
          searchClass={'form-control p-0'}
          searchStyle={{border: 'none', borderBottom: '1px dashed gray'}}
          dropdownStyle={{overflowX: 'hidden'}}
          country={'tr'}
          enableSearch
          countryCodeEditable={countryCodeEditable}
          enableLongNumbers={false}
          disableSearchIcon
          value={phone}
          inputProps={{
            onChange: () => {},
          }}
          onChange={(phone) => {
            if (!onChangeCountry) {
              onClickSelect(phone)
              setPhone(phone)
            }
          }}
        />
      ) : (
        <PhoneInput
          disabled={disabled}
          inputClass={className ?? 'form-control form-control-lg form-control-solid w-100'}
          searchClass={'form-control p-0'}
          searchStyle={{border: 'none', borderBottom: '1px dashed gray'}}
          dropdownStyle={{overflowX: 'hidden'}}
          country={'tr'}
          enableSearch
          disableSearchIcon
          value={phone}
          onChange={(phone) => {
            setPhone(phone)
            onSelect && onSelect(phone)
          }}
        />
      )}
    </div>
  )
}

export default PhoneInp
