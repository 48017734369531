import {apiUrl} from "../conf";
import axios from "axios";

export class DashboardService {

    getStatistics() {
        const config: any = {
            method: 'GET',
            url: apiUrl + '/dashboard-management/portal/items',
            headers: {
                'Content-Type': 'application/json',
            },
        };
        return axios(config);
    }

}
