import {apiUrl, globalPageCount} from '../conf'
import axios from 'axios'

export class DocumentService {
  getDocumentList({searchText, pageIndex}: any) {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        `/document-management/documents/?searchText=${searchText ?? ''}&pageIndex=${
          pageIndex ?? 0
        }&pageCount=${globalPageCount}`,
      headers: {
        'Content-Type': 'application/json',
      },
    }
    return axios(config)
  }

  getCompanyDetails(companyId?: number) {
    const config: any = {
      method: 'GET',
      url: apiUrl + `/company-management/companies/${companyId}`,
      headers: {
        'Content-Type': 'application/json',
      },
    }
    return axios(config)
  }

  getFilterDocument({pageIndex, companyId, documentTypeId, country}: any) {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        `/document-management/documents/filter?companyId=${
          companyId && companyId !== 'all' ? companyId : ''
        }&documentTypeId=${
          documentTypeId && documentTypeId !== 'all' ? documentTypeId : ''
        }&issueDate=&expireDate=&country=${country && country !== 'all' ? country : ''}&pageIndex=${
          pageIndex ?? 0
        }&pageCount=${globalPageCount}`,
      headers: {
        'Content-Type': 'application/json',
      },
    }
    return axios(config)
  }

  getCompanyList(searchText?: any, pageIndex?: any) {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        `/company-management/companies?searchText=${searchText ?? ''}&pageIndex=${
          pageIndex ?? 0
        }&pageCount=20`,
      headers: {
        'Content-Type': 'application/json',
      },
    }
    return axios(config)
  }

  getCountryList(searchText?: any, pageIndex?: any) {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        `/address-management/countries?searchText=${searchText ?? ''}&pageIndex=${
          pageIndex ?? 0
        }&pageCount=20`,
      headers: {
        'Content-Type': 'application/json',
      },
    }
    return axios(config)
  }

  getDocumentTypesList(searchText?: any, pageIndex?: any) {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        `/document-management/documents/types?searchText=${searchText ?? ''}&pageIndex=${
          pageIndex ?? 0
        }&pageCount=${globalPageCount}`,
      headers: {
        'Content-Type': 'application/json',
      },
    }
    return axios(config)
  }

  createDocument({documentNumber, documentType, issueDate, expireDate, description}: any) {
    const payLoad = {
      documentNumber: documentNumber,
      documentType: {
        documentTypeId: documentType,
      },
      issueDate: issueDate,
      expireDate: expireDate,
      description: description,
    }
    const config: any = {
      method: 'POST',
      url: apiUrl + '/document-management/documents',
      headers: {
        'Content-Type': 'application/json',
      },
      data: payLoad,
    }

    return axios(config)
  }

  updateDocument({documentNumber, documentType, documentId, issueDate, expireDate}: any) {
    const payLoad = {
      documentId: documentId,
      documentNumber: documentNumber,
      documentType: {
        documentTypeId: documentType,
      },
      issueDate: issueDate,
      expireDate: expireDate,
    }
    const config: any = {
      method: 'PUT',
      url: apiUrl + '/document-management/documents',
      headers: {
        'Content-Type': 'application/json',
      },
      data: payLoad,
    }

    return axios(config)
  }

  uploadDocument(documentId: number, file: any) {
    const data = new FormData()
    data.append('file', file)
    const config: any = {
      method: 'POST',
      url: apiUrl + `/document-management/documents/${documentId}/files`,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: data,
    }

    return axios(config)
  }

  getDocumentDetailById(documentId?: number) {
    const config: any = {
      method: 'GET',
      url: apiUrl + `/document-management/documents/${documentId}`,
      headers: {
        'Content-Type': 'application/json',
      },
    }
    return axios(config)
  }

  getDocumentTypeList({searchText, pageIndex}: any) {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        `/document-management/documents/types?searchText=${searchText ?? ''}&pageIndex=${
          pageIndex ?? 0
        }&pageCount=${globalPageCount}`,
      headers: {
        'Content-Type': 'application/json',
      },
    }
    return axios(config)
  }

  deleteDocument(documentId: number) {
    const config: any = {
      method: 'DELETE',
      url: apiUrl + `/document-management/documents/${documentId}`,
      headers: {
        'Content-Type': 'application/json',
      },
    }
    return axios(config)
  }
}
