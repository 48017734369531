import React, {useEffect} from 'react'
import {useIntl} from 'react-intl'
import clsx from 'clsx'

import RightLayoutPopup from '../../../../Components/RightLayoutPopup'

import useScrollLock from '../../../../../utils/useScrollLock'
import {exceptThisSymbols} from '../../../../../utils/common'

const UpdateBankAccount = ({
  setNewAccount,
  newAccount,
  updatedAccount,
  updateAccount,
  setLoading,
  onClose,
}: any) => {
  const intl = useIntl()
  const {scrollLock} = useScrollLock()
  useEffect(() => {
    scrollLock()
  }, [])

  return (
    <RightLayoutPopup
      title={intl.formatMessage({id: 'Carrier.bankAccount.update.title'})}
      className='carrier-update-bank-account-wrap'
      isCloseIcon
      closeOnClick={() => onClose()}
    >
      <div className={''}>
        <div className={''}>
          <div className='fv-row mb-10'>
            <label className='form-label fs-6 fw-bolder text-dark'>
              {intl.formatMessage({id: 'Carrier.bankAccount.update.accDescription'})}
            </label>
            <input
              placeholder={intl.formatMessage({
                id: 'Carrier.bankAccount.update.accDescription.placeHolder',
              })}
              className={clsx('form-control form-control-lg form-control-solid')}
              onChange={(event: any) =>
                setNewAccount((item: any) => ({
                  ...item,
                  explanation: event.target.value,
                }))
              }
              value={newAccount.explanation}
              type='text'
              autoComplete='off'
            />
          </div>
          <div className='fv-row mb-10'>
            <label className='form-label fs-6 fw-bolder text-dark'>
              {intl.formatMessage({id: 'Carrier.bankAccount.update.accHolder'})}
            </label>
            <input
              placeholder={intl.formatMessage({
                id: 'Carrier.bankAccount.update.accHolder.placeHolder',
              })}
              className={clsx('form-control form-control-lg form-control-solid')}
              onChange={(event: any) =>
                setNewAccount((item: any) => ({
                  ...item,
                  accountHolder: event.target.value,
                }))
              }
              value={newAccount.accountHolder}
              type='text'
              autoComplete='off'
            />
          </div>
          <div className='fv-row mb-10'>
            <label className='form-label fs-6 fw-bolder text-dark'>
              {intl.formatMessage({id: 'Carrier.bankAccount.update.accNumber'})}
            </label>
            <input
              placeholder={intl.formatMessage({
                id: 'Carrier.bankAccount.update.accNumber.placeHolder',
              })}
              className={clsx('form-control form-control-lg form-control-solid')}
              onChange={(event: any) =>
                setNewAccount((item: any) => ({
                  ...item,
                  iban: event.target.value,
                }))
              }
              value={newAccount.iban}
              type='text'
              autoComplete='off'
              maxLength={50}
            />
          </div>
          <div className='fv-row '>
            <label className='form-label fs-6 fw-bolder text-dark'>
              {intl.formatMessage({id: 'Carrier.bankAccount.update.swiftCode'})}
            </label>
            <input
              placeholder={intl.formatMessage({
                id: 'Carrier.bankAccount.update.swiftCode.placeHolder',
              })}
              className={clsx('form-control form-control-lg form-control-solid')}
              onChange={(event: any) =>
                setNewAccount((item: any) => ({
                  ...item,
                  swiftCode: event.target.value,
                }))
              }
              value={newAccount.swiftCode}
              type='number'
              min={1}
              maxLength={11}
              onKeyDown={(e) => exceptThisSymbols.includes(e.key) && e.preventDefault()}
            />
          </div>
        </div>
      </div>

      <div className='text-center mt-5 row freightPost-popup-container'>
        <div className={'col-md-6'}>
          <button
            onClick={onClose}
            type='button'
            id='kt_sign_up_submit'
            className='btn btn-lg btn-danger w-100 mb-5 '
          >
            {intl.formatMessage({id: 'CreateNewContainerPopup.cancel'})}
          </button>
        </div>
        <div className={'col-md-6'}>
          <button
            className={'btn btn-success px-10 m-auto w-100'}
            disabled={
              !newAccount.explanation ||
              !newAccount.iban ||
              !newAccount.swiftCode ||
              !newAccount.accountHolder
            }
            onClick={() => {
              setLoading(true)
              updateAccount(updatedAccount)
            }}
          >
            {intl.formatMessage({id: 'Carrier.bankAccount.update.addAcc'})}
          </button>
        </div>
      </div>
    </RightLayoutPopup>
  )
}

export default UpdateBankAccount
