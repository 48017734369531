import {apiUrl, globalPageCount} from '../conf'
import axios from 'axios'

export class TransporterService {
  getCurrentLocations({
    name,
    surName,
    vehiclePlate,
    trailerPlate,
    fromCountryCode,
    fromCityId,
    toCountryCode,
    toCityId,
    transporterStatusCodeList,
  }: any) {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        `/transporter-management/transporters/tracking/carriers?name=${name ?? ''}&surname=${
          surName ?? ''
        }&vehiclePlate=${vehiclePlate ?? ''}&trailerPlate=${trailerPlate ?? ''}&fromCountryCode=${
          fromCountryCode ?? ''
        }&fromCityId=${fromCityId ?? ''}&toCountryCode=${toCountryCode ?? ''}&toCityId=${
          toCityId ?? ''
        }&transporterStatusCodeList=${transporterStatusCodeList ?? ''}&pageIndex=0&pageCount=1000`,
      headers: {
        'Content-Type': 'application/json',
      },
    }
    return axios(config)
  }

  getCurrentLocationsForTruckExchange({
    name,
    surName,
    vehiclePlate,
    trailerPlate,
    fromCountryCode,
    fromCityId,
    toCountryCode,
    toCityId,
    trailerTypeId,
    floorTypeId,
    latitude,
    longitude,
    distance,
  }: any) {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        `/transporter-management/transporters/tracking?name=${name ?? ''}&surname=${
          surName ?? ''
        }&vehiclePlate=${vehiclePlate ?? ''}&trailerPlate=${trailerPlate ?? ''}&fromCountryCode=${
          fromCountryCode ?? ''
        }&fromCityId=${fromCityId ?? ''}&toCountryCode=${toCountryCode ?? ''}&toCityId=${
          toCityId ?? ''
        }&trailerTypeId=${trailerTypeId ?? ''}&floorTypeId=${floorTypeId ?? ''}&latitude=${
          latitude === 0 || !latitude ? 38.96272541161338 : latitude
        }&longitude=${longitude === 0 || !longitude ? 35.49645569209267 : longitude}&distance=${
          distance === 0 || !distance ? 1000 : distance
        }&pageIndex=0&pageCount=100`,
      headers: {
        'Content-Type': 'application/json',
      },
    }
    return axios(config)
  }

  getPublicCurrentLocationsForTruckExchange({
    name,
    surName,
    vehiclePlate,
    trailerPlate,
    fromCountryCode,
    fromCityId,
    toCountryCode,
    toCityId,
    trailerTypeId,
    floorTypeId,
    latitude,
    longitude,
    distance,
  }: any) {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        `/transporter-management/public/transporters/tracking?name=${name ?? ''}&surname=${
          surName ?? ''
        }&vehiclePlate=${vehiclePlate ?? ''}&trailerPlate=${trailerPlate ?? ''}&fromCountryCode=${
          fromCountryCode ?? ''
        }&fromCityId=${fromCityId ?? ''}&toCountryCode=${toCountryCode ?? ''}&toCityId=${
          toCityId ?? ''
        }&trailerTypeId=${trailerTypeId ?? ''}&floorTypeId=${floorTypeId ?? ''}&latitude=${
          latitude === 0 || !latitude ? 38.96272541161338 : latitude
        }&longitude=${longitude === 0 || !longitude ? 35.49645569209267 : longitude}&distance=${
          distance === 0 || !distance ? 1000 : distance
        }&pageIndex=0&pageCount=100`,
      headers: {
        'Content-Type': 'application/json',
      },
    }
    return axios(config)
  }

  getTransporterById(transporterId?: number) {
    const config: any = {
      method: 'GET',
      url: apiUrl + `/transporter-management/transporters/${transporterId}`,
      headers: {
        'Content-Type': 'application/json',
      },
    }
    return axios(config)
  }

  getTransporterList(
    pageIndex?: number,
    driverId?: number,
    name?: string,
    surname?: string,
    vehicleId?: string,
    vehiclePlate?: string,
    trailerId?: string,
    trailerPlate?: string,
    companyId?: string
  ) {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        `/transporter-management/transporters/carriers/filter?driverId=${driverId ?? ''}&name=${
          name ?? ''
        }&surname=${surname ?? ''}&vehicleId=${vehicleId ?? ''}&vehiclePlate=${
          vehiclePlate ?? ''
        }&trailerId=${trailerId ?? ''}&trailerPlate=${trailerPlate ?? ''}&companyId=${
          companyId ?? ''
        }&pageIndex=${pageIndex}&pageCount=${globalPageCount}`,
      headers: {
        'Content-Type': 'application/json',
      },
    }
    return axios(config)
  }

  getDriverListSearch(searchText?: string, pageIndex?: number) {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        `/user-management/users/drivers/unattached?searchText=${searchText}&pageIndex=${pageIndex}&pageCount=20`,
      headers: {
        'Content-Type': 'application/json',
      },
    }
    return axios(config)
  }

  getTransporterTypeList(searchText?: string, pageIndex?: number) {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        `/transport-management/transports/types?searchText=${searchText ?? ''}&pageIndex=${
          pageIndex ?? 0
        }&pageCount=20`,
      headers: {
        'Content-Type': 'application/json',
      },
    }
    return axios(config)
  }

  getCompanyVehicleList(searchText?: string, pageIndex?: number) {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        `/vehicle-management/vehicles/unattached?searchText=${searchText}&pageIndex=${pageIndex}&pageCount=20`,
      headers: {
        'Content-Type': 'application/json',
      },
    }
    return axios(config)
  }

  getTrailerList(searchText?: string, pageIndex?: number) {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        `/trailer-management/trailers/unattached?searchText=${searchText}&pageIndex=${pageIndex}&pageCount=20`,
      headers: {
        'Content-Type': 'application/json',
      },
    }
    return axios(config)
  }

  createTransporter(
    vehicleId?: number,
    trailerId?: number,
    driverId?: number,
    transporterId?: number
  ) {
    const data: any = JSON.stringify({
      vehicleId: vehicleId,
      trailerId: trailerId,
      driverId: driverId,
      transportTypeId: transporterId,
    })
    const config: any = {
      method: 'POST',
      url: apiUrl + '/transporter-management/transporters',
      headers: {
        'Content-Type': 'application/json',
      },
      data,
    }
    return axios(config)
  }

  updateTransporter(
    transporterId?: number,
    vehicleId?: number,
    trailerId?: number,
    driverId?: number,
    transporterTypeId?: number
  ) {
    const data: any = JSON.stringify({
      vehicleId: vehicleId,
      trailerId: trailerId,
      driverId: driverId,
      transporterId: transporterId,
      transportTypeId: transporterTypeId,
    })
    const config: any = {
      method: 'PUT',
      url: apiUrl + '/transporter-management/transporters',
      headers: {
        'Content-Type': 'application/json',
      },
      data,
    }
    return axios(config)
  }

  deleteTransporter(transporterId?: number) {
    const config: any = {
      method: 'DELETE',
      url: apiUrl + `/transporter-management/transporters/${transporterId}`,
      headers: {
        'Content-Type': 'application/json',
      },
    }
    return axios(config)
  }

  approveSubContractor(transporterId?: number) {
    const config: any = {
      method: 'PATCH',
      url: apiUrl + `/transporter-management/subcontractors/transporters/${transporterId}/approve`,
      headers: {
        'Content-Type': 'application/json',
      },
    }
    return axios(config)
  }

  getTransporterFileList(transporterId?: number) {
    const config: any = {
      method: 'GET',
      url: apiUrl + `/transporter-management/transporters/${transporterId}/files`,
      headers: {
        'Content-Type': 'application/json',
      },
    }
    return axios(config)
  }

  rejectSubContractor(transporterId?: number, rejectReason?: string) {
    const data: any = JSON.stringify({
      transporterId: transporterId,
      rejectReason: rejectReason,
    })

    const config: any = {
      method: 'PATCH',
      url: apiUrl + `/transporter-management/subcontractors/transporters/reject`,
      headers: {
        'Content-Type': 'application/json',
      },
      data,
    }
    return axios(config)
  }
}
