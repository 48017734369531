import {apiUrl} from "../conf";
import axios from "axios";

export class CompanyService {

    getCompany() {
        const config: any = {
            method: 'GET',
            url: apiUrl + '/company-management/companies/current',
            headers: {
                'Content-Type': 'application/json',
            },
        };

        return axios(config);
    }

    updateCompany(web: string, phone: string, taxNumber: string, taxOffice: string) {

        const data: any = JSON.stringify({
            web: web,
            phone: phone,
            taxNumber: taxNumber,
            taxOffice: taxOffice,
        });

        const config: any = {
            method: 'PUT',
            url: apiUrl + '/company-management/companies',
            headers: {
                'Content-Type': 'application/json',
            },
            data: data
        };

        return axios(config);
    }

}
