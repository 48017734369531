import React, {useState, useEffect} from 'react'
import {useIntl} from 'react-intl'

import useScrollLock from '../../../../../utils/useScrollLock'
import {priceConversion} from '../../../../../utils/common'

import Modal from '../../../../Components/Modal'
import DataTableAdvanced from '../../../../Components/DataTableAdvanced'
import RightLayoutPopup from '../../../../Components/RightLayoutPopup'
import EditProposal from './EditProposal'
import ShowProposalDetail from './ShowProposalDetail'

const ShowProposalList = ({
  proposalList,
  onClose,
  setLoading,
  currencyList,
  handleUpdateProposal,
  setSelectedEditProposal,
  selectedEditProposal,
  proposalScopeList,
  getSelectedFreightProposal,
  deleteFreigthProposal,
  proposalDetails,
  getSelectedProposalDetail,
  pageProposalListIndex,
  handleProposalListPagination,
}: any) => {
  const intl = useIntl()
  const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false)
  const [showEditProposalPopup, setShowEditProposalPopup] = useState<boolean>(false)
  const [showProposalDetailPopup, setShowProposalDetailPopup] = useState<boolean>(false)
  const [selectedProposalId, setSelectedProposalId] = useState<number>(0)

  const {scrollLock} = useScrollLock()
  useEffect(() => {
    scrollLock()
  }, [])

  return (
    <>
      <RightLayoutPopup
        title={intl.formatMessage({id: 'Carrier.freightPostings.proposal'})}
        className='carrier-freight-proposal-list-exchange-wrap'
        isCloseIcon
        closeOnClick={() => onClose()}
      >
        {!proposalList.length && (
          <div className='alert alert-warning mb-5 fw-bolder' role='alert'>
            {intl.formatMessage({id: 'global.noData'})}
          </div>
        )}
        <DataTableAdvanced
          columns={[
            {
              Header: 'ID',
              accessor: 'id',
            },
            {
              Header: intl.formatMessage({id: 'freightPosting.proposalList.explanation'}),
              accessor: 'explanation',
            },
            {
              Header: intl.formatMessage({id: 'freightPosting.proposalList.price'}),
              accessor: 'price',
              cellStyle: {
                textAlign: 'end',
              },
            },
            {
              Header: intl.formatMessage({id: 'freightPosting.proposalList.currency'}),
              accessor: 'currency',
            },
            {
              Header: intl.formatMessage({id: 'freightPosting.proposalList.proposalScope'}),
              accessor: 'proposalScope',
            },
          ]}
          data={
            proposalList &&
            proposalList.map((item: any) => {
              return {
                id: item?.containerProposalId,
                explanation: item?.explanation,
                price: item?.price && priceConversion(item?.price, item?.currency?.currencyCode),
                currency: item?.currency?.currencyCode,
                proposalScope: item?.proposalScope?.description,
              }
            })
          }
          editableRows={['']}
          editBtn={(row: any) => {
            setLoading(true)
            setShowEditProposalPopup(true)
            setSelectedProposalId(row?.values?.id)
            getSelectedFreightProposal(row?.values?.id)
          }}
          deleteRow={(row: any) => {
            setShowDeletePopup(true)
            setSelectedProposalId(row?.values?.id)
          }}
          deleteText={intl.formatMessage({id: 'global.table.deleteText'})}
          showDetailBtn={(row: any) => {
            setLoading(true)
            setShowProposalDetailPopup(true)
            getSelectedProposalDetail(row.values.id)
          }}
          setPageMIndex={handleProposalListPagination}
          pageMIndex={pageProposalListIndex}
        />

        {/*DELETE POPUP*/}
        {showDeletePopup && (
          <Modal
            closeBtnSettings={{
              show: true,
              onClick: () => setShowDeletePopup(false),
            }}
            customAll={
              <div className={''}>
                <h3 className={'mt-5 mb-15'}>
                  {intl.formatMessage({id: 'Carrier.drivers.delete.confirmDelete'})}
                </h3>
                <div className={'row'}>
                  <div className={'col-md-6'}>
                    <button
                      className={'btn btn-danger w-100'}
                      onClick={() => {
                        setShowDeletePopup(false)
                      }}
                    >
                      {intl.formatMessage({id: 'Carrier.drivers.delete.cancel'})}
                    </button>
                  </div>
                  <div className={'col-md-6'}>
                    <button
                      className={'btn btn-success w-100'}
                      onClick={() => {
                        setLoading(true)
                        setShowDeletePopup(false)
                        deleteFreigthProposal(selectedProposalId)
                      }}
                    >
                      {intl.formatMessage({id: 'Carrier.drivers.delete.approve'})}
                    </button>
                  </div>
                </div>
              </div>
            }
          />
        )}

        {showEditProposalPopup && (
          <EditProposal
            setLoading={setLoading}
            setShowEditProposalPopup={setShowEditProposalPopup}
            selectedProposalId={selectedProposalId}
            currencyList={currencyList}
            handleUpdateProposal={handleUpdateProposal}
            setSelectedEditProposal={setSelectedEditProposal}
            selectedEditProposal={selectedEditProposal}
            proposalScopeList={proposalScopeList}
          />
        )}

        {showProposalDetailPopup && (
          <ShowProposalDetail
            proposalDetails={proposalDetails}
            onClose={() => setShowProposalDetailPopup(false)}
          />
        )}

        <div className='text-center mt-5 row view-details-freight-popup freightPost-popup-container'>
          <div className={'col-md-6'}>
            <button
              onClick={onClose}
              type='button'
              id='kt_sign_up_submit'
              className='btn btn-lg btn-danger w-85 mb-5 '
            >
              {intl.formatMessage({id: 'Carrier.freightPosting.view.close'})}
            </button>
          </div>
        </div>
      </RightLayoutPopup>
    </>
  )
}

export default ShowProposalList
