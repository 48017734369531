/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {useIntl} from 'react-intl'

import {getDate} from '../../../utils/common'
import {KTSVG} from '../../../_metronic/helpers'

import {ReactComponent as NextActive} from '../DataTableAdvanced/assets/next-active.svg'
import {ReactComponent as NextInActive} from '../DataTableAdvanced/assets/next-inactive.svg'

import './MyMessageWidgetComponent.scss'

const MyMessageWidgetComponent = ({
  data,
  setPageMIndex,
  pageMIndex = 0,
  handleShowButton,
  handleMessageRead,
}: any) => {
  const [showToolTipIdList, setShowToolTipIdList] = useState<any>([])
  const intl = useIntl()

  return (
    <>
      <div className='card card-xl-stretch mb-5 mb-xl-8'>
        {!data?.length && (
          <div className='alert alert-warning mb-5 fw-bolder' role='alert'>
            {intl.formatMessage({id: 'Carrier.transporters.noData'})}
          </div>
        )}
        <div className='row card-body pt-0 p-0 mt-5'>
          {data.map((item: any) => (
            <div
              className={'col-xl-12 mb-2'}
              style={{position: 'relative'}}
              key={item?.listingMessageThreadId}
            >
              <div className='border-bottom p-2'>
                <div className='row'>
                  <div className='col-md-2 mobile-image-wrap' style={{height: 'fit-content'}}>
                    <div className='d-flex align-items-center justify-content-center'>
                      <img
                        className={'success-image img-fluid m-auto'}
                        src={item?.listing?.defaultImage}
                        alt='success'
                        style={{height: '50px'}}
                      />
                    </div>
                  </div>
                  <div className='row col-md-10' style={{height: 'fit-content'}}>
                    <div
                      className='row col-md-8 mobile-content-wrap'
                      style={{height: 'fit-content'}}
                    >
                      <div
                        className='col-md-3 mb-2 inside-label-content-wrap'
                        style={{height: 'fit-content'}}
                      >
                        <small className={'mb-1 text-muted'}>
                          {intl.formatMessage({id: 'freight.myListing.user'})}
                        </small>
                        <p className='text-dark fw-bolder d-block text-hover-primary mb-1'>
                          {item?.listing?.user}
                        </p>
                      </div>

                      <div
                        className='col-md-3 mb-2 inside-label-content-wrap'
                        style={{height: 'fit-content'}}
                      >
                        <small className={'text-muted'}>
                          {intl.formatMessage({id: 'freight.myListing.date'})}
                        </small>
                        <p className='text-dark fw-bold d-block text-hover-primary mb-1'>
                          {!!item?.listing?.issueDate && getDate(item?.listing?.issueDate)}
                        </p>
                      </div>

                      <div
                        className='col-md-3 mb-2 inside-label-content-wrap'
                        style={{height: 'fit-content'}}
                      >
                        <small className={'text-muted'}>
                          {intl.formatMessage({id: 'freight.myListing.brand'})}
                        </small>
                        <p className='text-dark fw-bold d-block text-hover-primary mb-1'>
                          {item?.listing?.brand}
                        </p>
                      </div>
                      <div
                        className='col-md-3 mb-2 inside-label-content-wrap'
                        style={{height: 'fit-content'}}
                      >
                        <small className={'text-muted'}>
                          {intl.formatMessage({id: 'freight.myListing.model'})}
                        </small>
                        <p className='text-dark fw-bold d-block text-hover-primary mb-1'>
                          {item?.listing?.model}
                        </p>
                      </div>
                    </div>

                    <div className='col-md-2'>
                      <small className={'text-muted'}>
                        {intl.formatMessage({id: 'freight.myListing.message'})}
                      </small>
                      {item?.lastMessage?.length > 210 ? (
                        <span>
                          {!showToolTipIdList?.includes(item?.listingId) ? (
                            <p className='detail-font-wrap' style={{fontWeight: 500}}>
                              {item?.lastMessage}...
                              <span
                                style={{cursor: 'pointer', color: '#0000EE'}}
                                onClick={() =>
                                  setShowToolTipIdList([...showToolTipIdList, item?.listingId])
                                }
                              >
                                {'  '}
                                {intl.formatMessage({id: 'freight.listing.showMore'})}
                              </span>
                            </p>
                          ) : (
                            <p className='detail-font-wrap' style={{fontWeight: 500}}>
                              {item?.lastMessage}
                              <span
                                onClick={() => {
                                  setShowToolTipIdList(
                                    showToolTipIdList.filter(
                                      (dataItem: any) => dataItem !== item?.listingId
                                    )
                                  )
                                }}
                                style={{cursor: 'pointer', color: '#0000EE'}}
                              >
                                {'  '}
                                {intl.formatMessage({id: 'freight.listing.showLess'})}
                              </span>
                            </p>
                          )}
                        </span>
                      ) : (
                        <p className='detail-font-wrap' style={{fontWeight: 500}}>
                          {item?.lastMessage}
                        </p>
                      )}
                    </div>

                    <div className='col-md-2 cursor-pointer action-widget-wrap d-flex align-items-center justify-content-center'>
                      <span className='menu-icon d-flex justify-content-end'>
                        <div
                          className={`btn btn-icon btn-active-light-primary btn-custom`}
                          data-kt-menu-attach='parent'
                          data-kt-menu-placement='bottom-end'
                          data-kt-menu-flip='bottom'
                          onClick={() => {
                            handleShowButton(item?.listingMessageThreadId, item?.listing?.listingId)
                            if (item?.unreadMessage === 'Y') {
                              handleMessageRead(item?.listingMessageThreadId)
                            }
                          }}
                          style={{position: 'relative'}}
                        >
                          <KTSVG
                            path='/media/icons/duotune/general/message_envelop.svg'
                            className='message-icon-wrap'
                          />
                          {item?.unreadMessage === 'Y' && (
                            <span className='badge-wrapper-content' />
                          )}
                        </div>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className='pagination'>
          <div className={'pagination-buttons'}>
            <button
              className={'back pag-btn'}
              onClick={() => setPageMIndex(pageMIndex - 1)}
              disabled={pageMIndex === 0}
            >
              <span className={'previous-arrow'}>
                {!(pageMIndex === 0) ? <NextActive /> : <NextInActive />}
              </span>
            </button>
            <button
              className={'next pag-btn'}
              onClick={() => setPageMIndex(pageMIndex + 1)}
              disabled={data.length < 20}
            >
              {!(data.length < 20) ? <NextActive /> : <NextInActive />}
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export {MyMessageWidgetComponent}
