import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'

import RightLayoutPopup from '../../../../Components/RightLayoutPopup'
import Dropbox from '../../../../Components/Dropbox'

import useScrollLock from '../../../../../utils/useScrollLock'

const CreateRoute = ({
  countries,
  fromCities,
  toCities,
  createRoute,
  onClose,
  transportTypeList,
  setFromCountryCustomSearch,
  fromCountryCustomSearch,
  setToCountryCustomSearch,
  toCountryCustomSearch,
  fromCitySearch,
  setFromCityPageIndex,
  setFromCitySearch,
  setFromCityScrollAPICalled,
  fromCityScrollAPICalled,
  fromCityPageIndex,
  getFromSearchCityList,
  toCitySearch,
  setToCityPageIndex,
  setToCitySearch,
  setToCityScrollAPICalled,
  toCityScrollAPICalled,
  toCityPageIndex,
  getToSearchCityList,
}: any) => {
  const intl = useIntl()
  const {scrollLock} = useScrollLock()
  useEffect(() => {
    scrollLock()
  }, [])

  const [newRoute, setNewRoute] = useState<any>({
    fromCountry: '',
    toCountry: '',
    fromCity: '',
    toCity: '',
    transportTypeId: '',
  })

  useEffect(() => {
    if (newRoute.fromCountry) {
      getFromSearchCityList(newRoute?.fromCountry, fromCityPageIndex, fromCitySearch)
      setNewRoute((item: any) => ({
        ...item,
        fromCity: '',
      }))
    }
  }, [newRoute.fromCountry])

  useEffect(() => {
    if (newRoute.toCountry) {
      getToSearchCityList(newRoute.toCountry, toCityPageIndex, toCitySearch)
      setNewRoute((item: any) => ({
        ...item,
        toCity: '',
      }))
    }
  }, [newRoute.toCountry])

  return (
    <>
      <RightLayoutPopup
        title={intl.formatMessage({id: 'Carrier.prefRoute.create.addNewRoute'})}
        className='create-preferredRoutes-right-side-wrap'
        isCloseIcon
        closeOnClick={() => onClose()}
      >
        <div className={''}>
          <div className={'row mb-10'}>
            {/*From*/}
            <div className={'col-md-6 mb-5'}>
              <label className='form-label fs-6 fw-bolder text-dark required'>
                {intl.formatMessage({id: 'Carrier.prefRoute.create.fromCountry'})}
              </label>
              <Dropbox
                hideArrow
                id={'country'}
                customClass={'form-select'}
                placeholder={intl.formatMessage({id: 'Carrier.prefRoute.create.selectCountry'})}
                customSearchValue={fromCountryCustomSearch}
                customOnSearch={(e: any) => {
                  setFromCountryCustomSearch(e.target.value)
                }}
                onScroll={() => {}}
                setScrollCalled={() => {}}
                currentPageIndex={0}
                menuList={
                  countries &&
                  countries.map((item: any) => {
                    return {
                      id: item?.code,
                      title: item?.name,
                    }
                  })
                }
                defaultClearItem={{
                  id: '',
                  title: '',
                }}
                defaultSelected={{id: newRoute?.fromCountry}}
                selectedItemData={(dataItem: any) => {
                  if (dataItem?.id) {
                    setNewRoute((item: any) => ({
                      ...item,
                      fromCountry: dataItem?.id,
                    }))
                  }
                  if (dataItem?.id === '' && dataItem?.title === '') {
                    setNewRoute((item: any) => ({
                      ...item,
                      fromCountry: '',
                    }))
                  }
                }}
              />
            </div>
            <div className={'col-md-6 mb-5'}>
              <label className='form-label fs-6 fw-bolder text-dark'>
                {intl.formatMessage({id: 'Carrier.prefRoute.create.fromCity'})}
              </label>
              <Dropbox
                hideArrow
                disabled={!newRoute.fromCountry}
                id={'city'}
                key={newRoute.fromCountry}
                customClass={'form-select'}
                placeholder={intl.formatMessage({id: 'Carrier.prefRoute.create.selectCity'})}
                customSearchValue={fromCitySearch}
                customOnSearch={(e: any) => {
                  setFromCityPageIndex(0)
                  setFromCitySearch(e.target.value)
                  getFromSearchCityList(newRoute?.fromCountry, 0, e.target.value)
                }}
                onScroll={(val: any) => {
                  if (newRoute?.fromCountry) {
                    setFromCityPageIndex(val)
                    getFromSearchCityList(newRoute?.fromCountry, val, fromCitySearch)
                  }
                }}
                setScrollCalled={() => {
                  setFromCityScrollAPICalled(true)
                }}
                scrollAPIResponse={fromCityScrollAPICalled}
                currentPageIndex={fromCityPageIndex}
                defaultClearItem={{
                  id: '',
                  title: '',
                }}
                defaultSelected={{id: newRoute?.fromCity}}
                menuList={
                  fromCities &&
                  fromCities.map((item: any) => {
                    return {
                      id: item?.cityId,
                      title: item?.name,
                    }
                  })
                }
                selectedItemData={(dataItem: any) => {
                  if (dataItem?.id) {
                    setNewRoute((item: any) => ({
                      ...item,
                      fromCity: dataItem?.id,
                    }))
                  }
                  if (dataItem?.id === '' && dataItem?.title === '') {
                    setNewRoute((item: any) => ({
                      ...item,
                      fromCity: '',
                    }))
                  }
                }}
              />
            </div>
            {/*To*/}
            <div className={'col-md-6 mb-5'}>
              <label className='form-label fs-6 fw-bolder text-dark required'>
                {intl.formatMessage({id: 'Carrier.prefRoute.create.toCountry'})}
              </label>
              <Dropbox
                hideArrow
                id={'toCountry'}
                customClass={'form-select'}
                placeholder={intl.formatMessage({id: 'Carrier.prefRoute.create.selectCountry'})}
                customSearchValue={toCountryCustomSearch}
                customOnSearch={(e: any) => {
                  setToCountryCustomSearch(e.target.value)
                }}
                onScroll={() => {}}
                setScrollCalled={() => {}}
                currentPageIndex={0}
                menuList={
                  countries &&
                  countries.map((item: any) => {
                    return {
                      id: item?.code,
                      title: item?.name,
                    }
                  })
                }
                defaultClearItem={{
                  id: '',
                  title: '',
                }}
                defaultSelected={{id: newRoute?.toCountry}}
                selectedItemData={(dataItem: any) => {
                  if (dataItem?.id) {
                    setNewRoute((item: any) => ({
                      ...item,
                      toCountry: dataItem?.id,
                    }))
                  }
                  if (dataItem?.id === '' && dataItem?.title === '') {
                    setNewRoute((item: any) => ({
                      ...item,
                      toCountry: '',
                    }))
                  }
                }}
              />
            </div>
            <div className={'col-md-6 mb-5'}>
              <label className='form-label fs-6 fw-bolder text-dark'>
                {intl.formatMessage({id: 'Carrier.prefRoute.create.toCity'})}
              </label>
              <Dropbox
                hideArrow
                key={newRoute.toCountry}
                disabled={!newRoute.toCountry}
                id={'toCity'}
                customClass={'form-select'}
                placeholder={intl.formatMessage({id: 'Carrier.prefRoute.create.selectCity'})}
                customSearchValue={toCitySearch}
                customOnSearch={(e: any) => {
                  setToCityPageIndex(0)
                  setToCitySearch(e.target.value)
                  getToSearchCityList(newRoute?.toCountry, 0, e.target.value)
                }}
                onScroll={(val: any) => {
                  if (newRoute?.toCountry) {
                    setToCityPageIndex(val)
                    getToSearchCityList(newRoute?.toCountry, val, toCitySearch)
                  }
                }}
                setScrollCalled={() => {
                  setToCityScrollAPICalled(true)
                }}
                scrollAPIResponse={toCityScrollAPICalled}
                currentPageIndex={toCityPageIndex}
                defaultClearItem={{
                  id: '',
                  title: '',
                }}
                defaultSelected={{id: newRoute?.toCity}}
                menuList={
                  toCities &&
                  toCities.map((item: any) => {
                    return {
                      id: item?.cityId,
                      title: item?.name,
                    }
                  })
                }
                selectedItemData={(dataItem: any) => {
                  if (dataItem?.id) {
                    setNewRoute((item: any) => ({
                      ...item,
                      toCity: dataItem?.id,
                    }))
                  }
                  if (dataItem?.id === '' && dataItem?.title === '') {
                    setNewRoute((item: any) => ({
                      ...item,
                      toCity: '',
                    }))
                  }
                }}
              />
            </div>

            <div className={'col-md-6 mb-5'}>
              <label className='form-label fs-6 fw-bolder text-dark required'>
                {intl.formatMessage({id: 'Carrier.transporters.create.transportType'})}
              </label>
              <Dropbox
                hideArrow
                id={'transportType'}
                customClass={'form-select'}
                placeholder={intl.formatMessage({
                  id: 'Carrier.transporters.create.transportType.placeHolder',
                })}
                customSearchValue={''}
                customOnSearch={(e: any) => {}}
                disabledSearch
                onScroll={() => {}}
                setScrollCalled={() => {}}
                currentPageIndex={0}
                menuList={
                  transportTypeList &&
                  transportTypeList.map((item: any) => {
                    return {
                      id: item?.transportTypeId,
                      title: item?.description,
                    }
                  })
                }
                selectedItemData={(dataItem: any) => {
                  if (dataItem?.id) {
                    setNewRoute((item: any) => ({
                      ...item,
                      transportTypeId: dataItem?.id,
                    }))
                  }
                }}
              />
            </div>
          </div>
        </div>

        <div className='text-center mt-5 row freightPost-popup-container'>
          <div className={'col-md-6'}>
            <button
              onClick={onClose}
              type='button'
              id='kt_sign_up_submit'
              className='btn btn-lg btn-danger w-100 mb-5 '
            >
              {intl.formatMessage({id: 'CreateNewContainerPopup.cancel'})}
            </button>
          </div>
          <div className={'col-md-6'}>
            <button
              className={'btn btn-success px-10 m-auto w-100'}
              disabled={
                !newRoute.fromCountry /*|| !newRoute.fromCity*/ ||
                !newRoute.toCountry /*|| !newRoute.toCity*/ ||
                !newRoute.transportTypeId
              }
              onClick={() => {
                createRoute(newRoute)
              }}
            >
              {intl.formatMessage({id: 'Carrier.prefRoute.create.addRoute'})}
            </button>
          </div>
        </div>
      </RightLayoutPopup>
    </>
  )
}

export default CreateRoute
