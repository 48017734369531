import React, {useEffect} from 'react'
import {useIntl} from 'react-intl'

import RightLayoutPopup from '../../../../Components/RightLayoutPopup'

import useScrollLock from '../../../../../utils/useScrollLock'
import getDateTime from '../../../../../utils/common'

const ShowDetails = ({containerDetailedList, onClose}: any) => {
  const intl = useIntl()
  const {scrollLock, scrollUnlock} = useScrollLock()
  useEffect(() => {
    scrollLock()
  }, [])

  return (
    <RightLayoutPopup
      title={intl.formatMessage({id: 'Admin.Containers.containerDetails'})}
      className='admin-container-details'
      isCloseIcon
      closeOnClick={() => onClose()}
    >
      <div className={'advert-detail-modal-container'}>
        <div className={'detail-modal-scroll-container'}>
          {/*Araç Seçenekleri -1-*/}
          <div className={'mb-10 '}>
            <h4 className={'form-label fw-bolder text-dark fs-3'}>
              {intl.formatMessage({id: 'Admin.Containers.vehiclePreferences'})}
            </h4>
            <hr />
            <div className={'row'}>
              <div className={'col-md-4 mb-10'}>
                <h6 className={'text-muted  '}>
                  {intl.formatMessage({id: 'Admin.Containers.description'})}
                </h6>
                <b className={'fs-5'}>{containerDetailedList?.description}</b>
              </div>
              <div className={'col-md-4 mb-10'}>
                <h6 className={'text-muted  '}>
                  {intl.formatMessage({id: 'Admin.Containers.value'})}
                </h6>
                <b className={'fs-5'}>{containerDetailedList?.price}</b>
              </div>
              <div className={'col-md-4 mb-10'}>
                <h6 className={'text-muted '}>
                  {intl.formatMessage({id: 'Admin.Containers.currency'})}
                </h6>
                <b className={'fs-5'}>{containerDetailedList?.priceCurrency?.description}</b>
              </div>
              <div className={'col-md-4 mb-10'}>
                <h6 className={'text-muted '}>
                  {intl.formatMessage({id: 'Admin.Containers.approved'})}
                </h6>
                <b className={'fs-5'}>
                  {containerDetailedList?.appliedForTransport === 'Y'
                    ? intl.formatMessage({id: 'global.yes'})
                    : intl.formatMessage({id: 'global.no'})}
                </b>
              </div>
              <div className={'col-md-4 mb-10'}>
                <h6 className={'text-muted '}>
                  {intl.formatMessage({id: 'Admin.Containers.trailerType'})}
                </h6>
                <b className={'fs-5'}>{containerDetailedList?.trailerType?.description}</b>
              </div>
              <div className={'col-md-4 mb-10'}>
                <h6 className={'text-muted '}>
                  {intl.formatMessage({id: 'Admin.Containers.floorType'})}
                </h6>
                <b className={'fs-5'}>{containerDetailedList?.floorType?.description}</b>
              </div>

              <div className={'col-md-4 mb-10'}>
                <h6 className={'text-muted '}>
                  {intl.formatMessage({id: 'Admin.Containers.specificationList'})}
                </h6>
                {containerDetailedList?.specificationList?.map((item: any, i: number) => (
                  <p key={i} className={'font-weight-bold '}>
                    {item?.description}
                  </p>
                ))}
              </div>

              <div className={'col-md-4 mb-10'}>
                <h6 className={'text-muted '}>
                  {intl.formatMessage({id: 'freight.details.content'})}
                </h6>
                <b className={'fs-5'}>{containerDetailedList?.freightContentType?.description}</b>
              </div>
            </div>
          </div>

          {/*Tarih ve Zaman -2-*/}
          <div className={'mb-10'}>
            <h4 className={'form-label fw-bolder text-dark fs-3'}>
              {intl.formatMessage({id: 'Admin.Containers.dateAndTime'})}
            </h4>
            <hr />
            <div className={'row m-auto'}>
              <div className={'col-md-6 mb-7'}>
                <h6 className={'text-muted  '}>
                  {intl.formatMessage({id: 'Admin.Containers.plannedDepartureDate'})}
                </h6>
                <b className={'fs-5'}>
                  {getDateTime(containerDetailedList?.plannedDepartureDate, intl.locale)}
                </b>
              </div>
              {/*   <div className={'col-md-6 mb-7'}>
                                                <h6 className={'text-muted '}>Yükleme Saati</h6>
                                                <b className={'fs-5'}>{advertList[selectedFreightId].plannedArrivalDate}</b>
                                            </div>*/}
              <div className={'col-md-6 mb-7'}>
                <h6 className={'text-muted '}>
                  {intl.formatMessage({id: 'Admin.Containers.plannedArrivalDate'})}
                </h6>
                <b className={'fs-5'}>
                  {getDateTime(containerDetailedList?.plannedArrivalDate, intl.locale)}
                </b>
              </div>
              {/* <div className={'col-md-6 mb-7'}>
                                                <h6 className={'text-muted '}>Varış Saati</h6>
                                                <b className={'fs-5'}>{formik.values.destTime}</b>
                                            </div>*/}
            </div>
          </div>

          {/*Adres Tercihleri -3-*/}
          <div className={'mb-10'}>
            <h4 className={'form-label fw-bolder text-dark fs-3  '}>
              {intl.formatMessage({id: 'Admin.Containers.addressPreferences'})}
            </h4>
            <hr />
            <div className={'row m-auto'}>
              <div className={'col-md-6 mb-7'}>
                <h6 className={'text-muted  '}>
                  {intl.formatMessage({id: 'Admin.Containers.fromLocation'})}
                </h6>
                <b className={'fs-5'}>{containerDetailedList?.fromAddress?.addressText}</b>
              </div>
              <div className={'col-md-6 mb-7'}>
                <h6 className={'text-muted '}>
                  {intl.formatMessage({id: 'Admin.Containers.toLocation'})}
                </h6>
                <b className={'fs-5'}>{containerDetailedList?.toAddress?.addressText}</b>
              </div>
            </div>

            <div className={'row'} style={{margin: 0}}>
              {containerDetailedList?.fromAddress && (
                <div className={'col-md-6 '} style={{paddingLeft: 0}}>
                  <div className={'row border border-2 rounded py-4'} style={{margin: 0}}>
                    <div className={'col-md-4 mb-5 '}>
                      <p className={'mb-0 text-muted'}>
                        {intl.formatMessage({id: 'Freight.createAdvert.departure.explanation'})}
                      </p>
                      <b>{containerDetailedList?.fromAddress?.description}</b>
                    </div>
                    <div className={'col-md-4 mb-5 '}>
                      <p className={'mb-0 text-muted'}>
                        {intl.formatMessage({id: 'Freight.createAdvert.departure.country'})}
                      </p>
                      <b>{containerDetailedList?.fromAddress?.country?.name}</b>
                    </div>
                    <div className={'col-md-4 mb-5 '}>
                      <p className={'mb-0 text-muted'}>
                        {intl.formatMessage({id: 'Freight.createAdvert.departure.city'})}
                      </p>
                      <b>{containerDetailedList?.fromAddress?.city?.name}</b>
                    </div>
                    <div className={'col-md-4 mb-5 '}>
                      <p className={'mb-0 text-muted'}>
                        {intl.formatMessage({id: 'Freight.createAdvert.departure.town'})}
                      </p>
                      <b>{containerDetailedList?.fromAddress?.town}</b>
                    </div>
                    <div className={'col-md-4 mb-5 '}>
                      <p className={'mb-0 text-muted'}>
                        {intl.formatMessage({id: 'Freight.createAdvert.departure.district'})}
                      </p>
                      <b>{containerDetailedList?.fromAddress?.district}</b>
                    </div>
                    <div className={'col-md-4 mb-5 '}>
                      <p className={'mb-0 text-muted'}>
                        {intl.formatMessage({id: 'Freight.createAdvert.departure.postCode'})}
                      </p>
                      <b>{containerDetailedList?.fromAddress?.postCode}</b>
                    </div>
                  </div>
                </div>
              )}
              {containerDetailedList?.toAddress && (
                <div className={'col-md-6 '} style={{paddingRight: 0}}>
                  <div className={'row border border-2 rounded py-4'} style={{margin: 0}}>
                    <div className={'col-md-4 mb-5 '}>
                      <p className={'mb-0 text-muted'}>
                        {intl.formatMessage({id: 'Freight.createAdvert.arrival.explanation'})}
                      </p>
                      <b>{containerDetailedList?.toAddress?.description}</b>
                    </div>
                    <div className={'col-md-4 mb-5 '}>
                      <p className={'mb-0 text-muted'}>
                        {intl.formatMessage({id: 'Freight.createAdvert.arrival.country'})}
                      </p>
                      <b>{containerDetailedList?.toAddress?.country?.name}</b>
                    </div>
                    <div className={'col-md-4 mb-5 '}>
                      <p className={'mb-0 text-muted'}>
                        {intl.formatMessage({id: 'Freight.createAdvert.arrival.city'})}
                      </p>
                      <b>{containerDetailedList?.toAddress?.city?.name}</b>
                    </div>
                    <div className={'col-md-4 mb-5 '}>
                      <p className={'mb-0 text-muted'}>
                        {intl.formatMessage({id: 'Freight.createAdvert.arrival.district'})}
                      </p>
                      <b>{containerDetailedList?.toAddress?.town}</b>
                    </div>
                    <div className={'col-md-4 mb-5 '}>
                      <p className={'mb-0 text-muted'}>
                        {intl.formatMessage({id: 'Freight.createAdvert.arrival.dis'})}
                      </p>
                      <b>{containerDetailedList?.toAddress?.district}</b>
                    </div>
                    <div className={'col-md-4 mb-5 '}>
                      <p className={'mb-0 text-muted'}>
                        {intl.formatMessage({id: 'Freight.createAdvert.arrival.postCode'})}
                      </p>
                      <b>{containerDetailedList?.toAddress?.postCode}</b>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className='text-center mt-5 row view-details-freight-popup'>
        <div className={'col-md-6'}>
          <button
            onClick={() => {
              onClose()
              scrollUnlock()
            }}
            type='button'
            id='kt_sign_up_submit'
            className='btn btn-lg btn-danger w-100 mb-5 '
          >
            {intl.formatMessage({id: 'Carrier.freightPosting.view.close'})}
          </button>
        </div>
      </div>
    </RightLayoutPopup>
  )
}

export default ShowDetails
