import React, {useEffect} from 'react'
import {useIntl} from 'react-intl'
import {useHistory} from 'react-router-dom'

import RightLayoutPopup from '../../../../Components/RightLayoutPopup'

import useScrollLock from '../../../../../utils/useScrollLock'

const PreviewSuccess = () => {
  const intl = useIntl()
  const history = useHistory()
  const {scrollLock} = useScrollLock()
  useEffect(() => {
    scrollLock()
  }, [])

  return (
    <>
      <RightLayoutPopup title={intl.formatMessage({id: 'Freight.createAdvert.info'})} width={'50%'}>
        <div className={'preview-success-container mx-4'}>
          <div className={'success-image-container text-center'}>
            <img
              className={'success-image m-auto mb-10'}
              src='/media/success-black.png'
              alt='success'
            />
            <h1 className={'m-auto'}>
              {intl.formatMessage({
                id: 'Freight.createAdvert.preview.freightSuccessfullyCreated',
              })}
            </h1>
          </div>
          <div className='text-center mt-10 row m-auto'>
            <div className={'col-md-6'}>
              <button
                onClick={() => window.location.reload()}
                type='submit'
                id='kt_sign_up_submit'
                className='btn btn-lg btn-info w-100'
              >
                {intl.formatMessage({id: 'Freight.createAdvert.preview.createQuickFreight'})}
              </button>
            </div>
            <div className={'col-md-6 '}>
              <button
                onClick={() => history.push('/carrier/my-adverts')}
                type='submit'
                id='kt_sign_up_submit'
                className='btn btn-lg btn-primary w-100 '
              >
                {intl.formatMessage({id: 'Freight.createAdvert.preview.goToMyFreights'})}
              </button>
            </div>
          </div>
        </div>
      </RightLayoutPopup>
    </>
  )
}

export default PreviewSuccess
