import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'

import {ListWidgetComponent} from '../../../../Components/ListWidgetComponent/ListWidgetComponent'
import {TrailerDetails} from '../../../FreightOperator/VehicleBuyingSelling/Components/TrailerDetails'
import {TruckDetails} from '../../../FreightOperator/VehicleBuyingSelling/Components/TruckDetails'
import ActionDropDown from '../../../../../_metronic/partials/content/dropdown/ActionDropdown'
import {RejectReview} from './FileDetailModal/RejectReview'

import {ListingService} from '../../../../../services/FreightOperatorServices/ListingService'

const ListingComponent = ({setLoading}: any) => {
  const listingServ = new ListingService()
  const intl = useIntl()

  const [listingData, setListingData] = useState<any>([])
  const [pageIndex, setPageIndex] = useState<number>(0)

  const [selectedListingId, setSelectedListingId] = useState<any>('')

  const [showReject, setShowReject] = useState<boolean>(false)

  const [showTruckDetails, setShowTruckDetail] = useState<boolean>(false)
  const [showTrailerDetails, setShowTrailerDetail] = useState<boolean>(false)

  const [selectedListTruckDetails, setSelectedListTruckDetails] = useState<any>({})
  const [selectedListTrailerDetails, setSelectedListTrailerDetails] = useState<any>({})

  const [selectedListImageList, setSelectedListImageList] = useState<any>([])

  const [selectedContactList, setSelectedContactList] = useState<any>([])

  const getListingData = (data: any) => {
    listingServ
      .getListingsReview(data)
      .then((resp: any) => {
        setListingData(resp?.data)
        setLoading(false)
      })
      .catch((e) => {
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  useEffect(() => {
    getListingData({pageIndex: pageIndex})
  }, [])

  const onPaginationChange = (page: number) => {
    getListingData({pageIndex: page})
    setPageIndex(page)
  }

  const getTrailerDetails = (itemId: any) => {
    listingServ
      .getTrailerDetails(itemId)
      .then((resp: any) => {
        setLoading(false)
        setSelectedListTrailerDetails(resp?.data)
      })
      .catch((e) => {
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const getTruckDetails = (itemId: any) => {
    listingServ
      .getTruckDetails(itemId)
      .then((resp: any) => {
        setLoading(false)
        setSelectedListTruckDetails(resp?.data)
      })
      .catch((e) => {
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const getListingFile = (itemId: any) => {
    listingServ
      .getListingFileList(itemId)
      .then((resp: any) => {
        setLoading(false)
        setSelectedListImageList(resp?.data)
      })
      .catch((e) => {
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const getListingContactDetails = (itemId: any) => {
    listingServ
      .getListingContact(itemId)
      .then((resp: any) => {
        setSelectedContactList(resp?.data)
      })
      .catch((e) => {
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const approveListing = (listingId: any) => {
    listingServ
      .approveListing(listingId)
      .then((resp: any) => {
        setLoading(false)
        toast.success(intl.formatMessage({id: 'admin.list.review.approve'}))
        getListingData({pageIndex: 0})
      })
      .catch((e) => {
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const rejectListing = (listingId: any, reason: any) => {
    listingServ
      .rejectListing(listingId, reason)
      .then((resp: any) => {
        setLoading(false)
        getListingData({pageIndex: 0})
        toast.success(intl.formatMessage({id: 'admin.list.review.reject'}))
      })
      .catch((e) => {
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  return (
    <>
      <ListWidgetComponent
        data={listingData}
        setPageMIndex={onPaginationChange}
        pageMIndex={pageIndex}
        actionContent={(type: any, id: any) => (
          <ActionDropDown menuTitle={intl.formatMessage({id: 'freight.myListing.actions'})}>
            <div className='menu-item px-3'>
              <button
                onClick={() => {
                  if (type === 'TRUCK') {
                    setLoading(true)
                    setShowTruckDetail(true)
                    getTruckDetails(id)
                    getListingFile(id)
                    getListingContactDetails(id)
                  }
                  if (type === 'TRAILER') {
                    setLoading(true)
                    setShowTrailerDetail(true)
                    getTrailerDetails(id)
                    getListingFile(id)
                    getListingContactDetails(id)
                  }
                }}
                className='btn btn-sm btn-light-primary mb-2 border border-2 w-100'
              >
                {intl.formatMessage({id: 'freight.listing.show'})}
              </button>

              <button
                onClick={() => {
                  setLoading(true)
                  approveListing(id)
                }}
                className='btn btn-sm btn-light-primary mb-2 border border-2 w-100'
              >
                {intl.formatMessage({id: 'DataTableAdvanced.buttons.loadApprove'})}
              </button>

              <button
                onClick={() => {
                  setSelectedListingId(id)
                  setShowReject(true)
                }}
                className='btn btn-sm btn-light-primary mb-2 border border-2 w-100'
              >
                {intl.formatMessage({id: 'DataTableAdvanced.buttons.rejectApprove'})}
              </button>
            </div>
          </ActionDropDown>
        )}
      />
      {showTrailerDetails && (
        <TrailerDetails
          trailerDetails={selectedListTrailerDetails}
          onClose={() => setShowTrailerDetail(false)}
          selectedListImageList={selectedListImageList}
          contactList={selectedContactList}
          setLoading={setLoading}
        />
      )}

      {showTruckDetails && (
        <TruckDetails
          truckDetails={selectedListTruckDetails}
          onClose={() => setShowTruckDetail(false)}
          selectedListImageList={selectedListImageList}
          contactList={selectedContactList}
          setLoading={setLoading}
        />
      )}

      {showReject && (
        <RejectReview
          selectedFile={{id: selectedListingId}}
          setRejectReview={setShowReject}
          rejectOnClick={(data: any) => {
            setLoading(true)
            rejectListing(data?.id, data?.reason)
          }}
        />
      )}
    </>
  )
}

export {ListingComponent}
