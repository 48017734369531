import React, {useEffect, useState} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import {Carousel} from 'react-responsive-carousel'

import {UserModel} from '../../../auth/models/UserModel'
import RightLayoutPopup from '../../../../Components/RightLayoutPopup'
import {RootState} from '../../../../../setup'
import {getDate} from '../../../../../utils/common'
import {apiUrl} from '../../../../../services/conf'

import {ListingService} from '../../../../../services/FreightOperatorServices/ListingService'

import 'react-responsive-carousel/lib/styles/carousel.min.css'

const TrailerDetails = ({
  onClose,
  trailerDetails,
  selectedListImageList,
  contactList,
  setLoading,
}: any) => {
  const intl = useIntl()
  const [showToolTipIdList, setShowToolTipIdList] = useState<any>([])
  const [showImageInPopup, setShowImageInPopup] = useState<any>(false)

  const [showFileIndex, setShowFileIndex] = useState<any>(null)

  const [listingMessgae, setListingMessage] = useState<any>([])
  const [listingThreadMessgae, setListingThreadMessage] = useState<any>([])
  const [message, setMessage] = useState<any>('')

  const listingServ = new ListingService()

  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  const getListingMessageList = (listingId: any) => {
    listingServ
      .getLisitngMessageListOfCurrentCompany(listingId)
      .then((resp: any) => {
        setLoading(false)
        setListingMessage(resp?.data)
      })
      .catch((e) => {
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const getListingThreadList = (listingId: any) => {
    listingServ
      .getLisitngThreadList(listingId)
      .then((resp: any) => {
        setLoading(false)
        setListingThreadMessage(resp?.data)
      })
      .catch((e) => {
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const handleSendMessage = (data: any) => {
    listingServ
      .sendMessage(data)
      .then((resp: any) => {
        setLoading(false)
        setMessage('')
        getListingMessageList(trailerDetails?.listing?.listingId)
      })
      .catch((e) => {
        setLoading(false)
        setMessage('')
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  useEffect(() => {
    if (trailerDetails?.listing?.listingId) {
      if (user?.company?.companyId !== trailerDetails?.listing?.company?.companyId) {
        getListingMessageList(trailerDetails?.listing?.listingId)
      } else {
        getListingThreadList(trailerDetails?.listing?.listingId)
      }
    }
  }, [trailerDetails])

  return (
    <RightLayoutPopup
      title={intl.formatMessage({id: 'freight.trailer.title'})}
      className='right-layout-show-wrap'
      isCloseIcon
      closeOnClick={() => onClose()}
    >
      <div className='row'>
        <div className='col-md-3' />
        <div className='col-md-6'>
          <Carousel
            className='carousal-wrap'
            showThumbs={false}
            showArrows={true}
            showStatus={false}
            useKeyboardArrows
          >
            {selectedListImageList
              .sort((dataItem: any, nextItem: any) =>
                nextItem?.defaultImage?.localeCompare(dataItem?.defaultImage)
              )
              .map((item: any, index: any) => (
                <div
                  onClick={() => {
                    setShowFileIndex(index)
                    setShowImageInPopup(true)
                  }}
                  style={{cursor: 'pointer', display: 'flex', justifyContent: 'center'}}
                  className='image-hover-wrap'
                >
                  <img
                    src={item?.url}
                    alt={'trailer-img'}
                    style={{
                      height: '100%',
                      minHeight: '275px',
                      maxHeight: '337px',
                      maxWidth: '337px',
                      objectFit: 'fill',
                    }}
                  />
                </div>
              ))}
          </Carousel>
        </div>
        <div className='col-md-3' />
      </div>
      <div className='row mt-10 mb-5'>
        <div className='col-md-4 mb-5'>
          <h6 className={'details-posting-wrap fw-bolder'}>
            {intl.formatMessage({id: 'freight.myListing.postingNumber'})}
          </h6>
          <b className={'fs-5 fw-bolder'}>{trailerDetails?.listing?.listingId}</b>
        </div>

        <div className='col-md-4 mb-5'>
          <h6 className={'text-muted'}>{intl.formatMessage({id: 'freight.listing.brand'})}</h6>
          <b className={'fs-5'}>{trailerDetails?.listing?.brand?.name}</b>
        </div>

        <div className='col-md-4 mb-5'>
          <h6 className={'text-muted'}>{intl.formatMessage({id: 'freight.listing.model'})}</h6>
          <b className={'fs-5'}>{trailerDetails?.listing?.model}</b>
        </div>

        <div className='col-md-4 mb-5'>
          <h6 className={'text-muted'}>{intl.formatMessage({id: 'freight.listing.payload'})}</h6>
          <b className={'fs-5'}>{trailerDetails?.payload}</b>
        </div>

        <div className='col-md-4 mb-5'>
          <h6 className={'text-muted'}>{intl.formatMessage({id: 'freight.listing.country'})}</h6>
          <b className={'fs-5'}>{trailerDetails?.listing?.listingType?.country?.name}</b>
        </div>

        <div className='col-md-4 mb-5'>
          <h6 className={'text-muted'}>{intl.formatMessage({id: 'freight.listing.city'})}</h6>
          <b className={'fs-5'}>{trailerDetails?.listing?.listingType?.city?.name}</b>
        </div>

        <div className='col-md-4 mb-5'>
          <h6 className={'text-muted'}>{intl.formatMessage({id: 'freight.listing.gwr'})}</h6>
          <b className={'fs-5'}>{trailerDetails?.gwr}</b>
        </div>

        <div className='col-md-4 mb-5'>
          <h6 className={'text-muted'}>{intl.formatMessage({id: 'freight.listing.price'})}</h6>
          <b className={'fs-5'}>{trailerDetails?.listing?.price}</b>
        </div>

        <div className='col-md-4 mb-5'>
          <h6 className={'text-muted'}>
            {intl.formatMessage({id: 'Admin.Adverts.table_currency'})}
          </h6>
          <b className={'fs-5'}>{trailerDetails?.listing?.currency?.description}</b>
        </div>
      </div>

      <div className='accordion accordion-flush mb-10' id='accordionFlushExample'>
        <div className='accordion-item mb-4'>
          <h2 className='accordion-header accordian-main-wrap' id='flush-headingOne'>
            <button
              className='accordion-button collapsed accordian-btn-wrap'
              type='button'
              data-bs-toggle='collapse'
              data-bs-target='#flush-collapseOne'
              aria-expanded='false'
              aria-controls='flush-collapseOne'
            >
              {intl.formatMessage({id: 'freight.myListing.description'})}
            </button>
          </h2>
          <div
            id='flush-collapseOne'
            className='accordion-collapse collapse'
            aria-labelledby='flush-headingOne'
            data-bs-parent='#accordionFlushExample'
          >
            <div className='accordion-body'>
              <div className='row'>
                <div className='col-md-12'>
                  <b className={'fs-5'}>{trailerDetails?.listing?.description}</b>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='accordion-item mb-4'>
          <h2 className='accordion-header accordian-main-wrap' id='flush-headingTwo'>
            <button
              className='accordion-button collapsed accordian-btn-wrap'
              type='button'
              data-bs-toggle='collapse'
              data-bs-target='#flush-collapseTwo'
              aria-expanded='false'
              aria-controls='flush-collapseTwo'
            >
              {intl.formatMessage({id: 'freight.myListing.contacts'})}
            </button>
          </h2>
          <div
            id='flush-collapseTwo'
            className='accordion-collapse collapse'
            aria-labelledby='flush-headingTwo'
            data-bs-parent='#accordionFlushExample'
          >
            <div className='accordion-body'>
              <div className='row' style={{padding: '14px'}}>
                {contactList.map((item: any) => (
                  <div className='row col-md-12 mb-5 box-wrap-content'>
                    {item?.name && (
                      <div className='col-md-6 mb-3'>
                        <h6 className={'text-muted'}>
                          {intl.formatMessage({id: 'freight.myListing.name'})}
                        </h6>
                        <b className={'fs-5'}>{item?.name}</b>
                      </div>
                    )}

                    {item?.email && (
                      <div className='col-md-6 mb-3'>
                        <h6 className={'text-muted'}>
                          {intl.formatMessage({id: 'freight.myListing.email'})}
                        </h6>
                        <a href={`mailto:${item?.email}`} className='contact-deco'>
                          <span className={'fs-5'}>{item?.email}</span>
                        </a>
                      </div>
                    )}

                    {item?.countryCode && (
                      <div className='col-md-6 mb-3'>
                        <h6 className={'text-muted'}>
                          {intl.formatMessage({id: 'freight.myListing.phone'})}
                        </h6>
                        <a href={`tel:${item?.countryCode + item?.phone}`} className='contact-deco'>
                          <span className={'fs-5'}>{item?.countryCode + ' ' + item?.phone}</span>
                        </a>
                      </div>
                    )}

                    {item?.web && (
                      <div className='col-md-6 mb-3'>
                        <h6 className={'text-muted'}>
                          {intl.formatMessage({id: 'freight.myListing.website'})}
                        </h6>
                        <a href={item?.web} className='contact-deco'>
                          <span className={'fs-5'}>{item?.web}</span>
                        </a>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        {user?.company?.companyId !== trailerDetails?.listing?.company?.companyId ? (
          <div className='accordion-item mb-4'>
            <h2 className='accordion-header accordian-main-wrap' id='flush-headingThree'>
              <button
                className='accordion-button collapsed accordian-btn-wrap'
                type='button'
                data-bs-toggle='collapse'
                data-bs-target='#flush-collapseThree'
                aria-expanded='false'
                aria-controls='flush-collapseThree'
              >
                {intl.formatMessage({id: 'freight.myListing.message'})}
              </button>
            </h2>
            <div
              id='flush-collapseThree'
              className='accordion-collapse collapse'
              aria-labelledby='flush-headingThree'
              data-bs-parent='#accordionFlushExample'
            >
              <div
                className='accordion-body'
                style={{
                  background: '#ebf1ef',
                  borderRadius: '15px',
                  marginTop: '5px',
                  paddingTop: '10px',
                }}
              >
                {listingMessgae?.map((item: any) => (
                  <div className='row mt-4'>
                    <div
                      className='d-flex justify-content-center col-md-1'
                      style={{paddingRight: 0, alignItems: 'start'}}
                    >
                      <img
                        style={{width: '30px', height: '30px', borderRadius: '20px'}}
                        src={item?.sender?.image}
                        alt='profile'
                      />
                    </div>
                    <div className='col-md-11'>
                      <div>
                        <small className={'fs-8'}>
                          {item?.sender?.name} {item?.sender?.surname}
                        </small>
                      </div>
                      <div>
                        <b className={'fs-6'}>{item?.message}</b>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <div
                className='mt-4 d-flex justify-content-center'
                style={{width: '100%', padding: '0 80px'}}
              >
                <div className='input-group'>
                  <input
                    id='btn-input'
                    type='text'
                    className='form-control input-sm'
                    placeholder={intl.formatMessage({id: 'freight.myListing.message.placeHolder'})}
                    value={message}
                    onChange={(e: any) => setMessage(e?.target?.value)}
                  />
                  <span className='input-group-btn'>
                    <button
                      className='btn btn-primary'
                      id='btn-chat'
                      disabled={!(message.length > 0)}
                      onClick={() => {
                        setLoading(true)
                        handleSendMessage({
                          message: message,
                          listing: {listingId: trailerDetails?.listing?.listingId},
                        })
                      }}
                    >
                      {intl.formatMessage({id: 'freight.myListing.message.send'})}
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className='accordion-item mb-4'>
            <h2 className='accordion-header accordian-main-wrap' id='flush-headingFour'>
              <button
                className='accordion-button collapsed accordian-btn-wrap'
                type='button'
                data-bs-toggle='collapse'
                data-bs-target='#flush-collapseFour'
                aria-expanded='false'
                aria-controls='flush-collapseFour'
              >
                {intl.formatMessage({id: 'freight.myListing.thread'})}
              </button>
            </h2>
            <div
              id='flush-collapseFour'
              className='accordion-collapse collapse'
              aria-labelledby='flush-headingFour'
              data-bs-parent='#accordionFlushExample'
            >
              <div
                className='accordion-body'
                style={{
                  background: '#ebf1ef',
                  borderRadius: '15px',
                  marginTop: '5px',
                  paddingTop: '10px',
                }}
              >
                {listingThreadMessgae?.length > 0 ? (
                  listingThreadMessgae?.map((item: any) => (
                    <div className='border-bottom p-2'>
                      <div className='row'>
                        <div className='col-md-1 mobile-image-wrap' style={{height: 'fit-content'}}>
                          <div className='d-flex align-items-center justify-content-center'>
                            <img
                              className={'success-image img-fluid m-auto'}
                              src={item?.listing?.defaultImage}
                              alt='success'
                              style={{height: '50px'}}
                            />
                          </div>
                        </div>
                        <div className='row col-md-11' style={{height: 'fit-content'}}>
                          <div
                            className='row col-md-10 mobile-content-wrap'
                            style={{height: 'fit-content'}}
                          >
                            <div
                              className='col-md-3 mb-2 inside-label-content-wrap'
                              style={{height: 'fit-content'}}
                            >
                              <small className={'mb-1 text-muted'}>
                                {intl.formatMessage({id: 'freight.myListing.user'})}
                              </small>
                              <p className='text-dark fw-bolder d-block text-hover-primary mb-1'>
                                {item?.listing?.user}
                              </p>
                            </div>

                            <div
                              className='col-md-3 mb-2 inside-label-content-wrap'
                              style={{height: 'fit-content'}}
                            >
                              <small className={'text-muted'}>
                                {intl.formatMessage({id: 'freight.myListing.date'})}
                              </small>
                              <p className='text-dark fw-bold d-block text-hover-primary mb-1'>
                                {!!item?.listing?.issueDate && getDate(item?.listing?.issueDate)}
                              </p>
                            </div>

                            <div
                              className='col-md-3 mb-2 inside-label-content-wrap'
                              style={{height: 'fit-content'}}
                            >
                              <small className={'text-muted'}>
                                {intl.formatMessage({id: 'freight.myListing.brand'})}
                              </small>
                              <p className='text-dark fw-bold d-block text-hover-primary mb-1'>
                                {item?.listing?.brand}
                              </p>
                            </div>
                            <div
                              className='col-md-3 mb-2 inside-label-content-wrap'
                              style={{height: 'fit-content'}}
                            >
                              <small className={'text-muted'}>
                                {intl.formatMessage({id: 'freight.myListing.model'})}
                              </small>
                              <p className='text-dark fw-bold d-block text-hover-primary mb-1'>
                                {item?.listing?.model}
                              </p>
                            </div>
                          </div>

                          <div className='col-md-2'>
                            <small className={'text-muted'}>
                              {intl.formatMessage({id: 'freight.myListing.message'})}
                            </small>
                            {item?.lastMessage?.length > 210 ? (
                              <span>
                                {!showToolTipIdList?.includes(item?.listingId) ? (
                                  <p className='detail-font-wrap' style={{fontWeight: 500}}>
                                    {item?.lastMessage}...
                                    <span
                                      style={{cursor: 'pointer', color: '#0000EE'}}
                                      onClick={() =>
                                        setShowToolTipIdList([
                                          ...showToolTipIdList,
                                          item?.listingId,
                                        ])
                                      }
                                    >
                                      {'  '}
                                      {intl.formatMessage({id: 'freight.listing.showMore'})}
                                    </span>
                                  </p>
                                ) : (
                                  <p className='detail-font-wrap' style={{fontWeight: 500}}>
                                    {item?.lastMessage}
                                    <span
                                      onClick={() => {
                                        setShowToolTipIdList(
                                          showToolTipIdList.filter(
                                            (dataItem: any) => dataItem !== item?.listingId
                                          )
                                        )
                                      }}
                                      style={{cursor: 'pointer', color: '#0000EE'}}
                                    >
                                      {'  '}
                                      {intl.formatMessage({id: 'freight.listing.showLess'})}
                                    </span>
                                  </p>
                                )}
                              </span>
                            ) : (
                              <p className='detail-font-wrap' style={{fontWeight: 500}}>
                                {item?.lastMessage}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className='alert alert-warning mb-5 fw-bolder' role='alert'>
                    {intl.formatMessage({id: 'global.noData'})}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>

      {showImageInPopup && (
        <div id='modal' className='modal-image'>
          <span
            id='modal-close'
            className='modal-close'
            onClick={() => {
              setShowImageInPopup(false)
              setShowFileIndex(null)
            }}
          >
            &times;
          </span>
          <Carousel
            className='modal-carousal-wrap'
            showThumbs={false}
            showArrows={true}
            showStatus={false}
            useKeyboardArrows
            selectedItem={showFileIndex || 0}
          >
            {selectedListImageList
              .sort((dataItem: any, nextItem: any) =>
                nextItem?.defaultImage?.localeCompare(dataItem?.defaultImage)
              )
              .map((item: any) => (
                <img
                  src={`${apiUrl}/listing-management/listings/files/name/${item?.fileName}?size=big`}
                  id='modal-content'
                  className='modal-content-image'
                  style={{maxHeight: 'calc(100vh - 170px)', objectFit: 'contain'}}
                  alt='modal-pop'
                />
              ))}
          </Carousel>
        </div>
      )}

      <div className='text-center mt-5 row view-details-freight-popup'>
        <div className={'col-md-6'}>
          <button
            onClick={() => {
              onClose()
            }}
            type='button'
            id='kt_sign_up_submit'
            className='btn btn-lg btn-danger w-100 mb-5 '
          >
            {intl.formatMessage({id: 'Carrier.freightPosting.view.close'})}
          </button>
        </div>
      </div>
    </RightLayoutPopup>
  )
}

export {TrailerDetails}
