import React from 'react'
import {useIntl} from 'react-intl'

import RelatedBlogs from '../RelatedBlogs'
import Seo from '../../utils/seo'
import {toAbsoluteUrl} from '../../_metronic/helpers'

import './FreightExchangeImproveServices.scss'

// const siteNavigationStructuredData: any = {
//   '@context': 'http://schema.org',
//   '@type': 'BlogPosting',
//   name: 'blog post',
//   url: 'https://www.rivalog.com/blog/speed-and-efficiency-advantages-of-air',
//   publisher: {
//     '@type': 'Organization',
//     name: 'Rivalog',
//     logo: 'https://www.rivalog.com/media/logo.png',
//   },
// }

const TheFutureOfBuyingFreightTrucks = () => {
  const intl = useIntl()
  const date = new Date()
  return (
    <div style={{overflow: 'auto'}}>
      <div style={{position: 'relative'}}>
        <div
          className='bg-photo-overlay'
          style={{backgroundImage: 'url("/media/future-of-buying-freight-truck.jpg")'}}
        ></div>
        <Seo
          data={{
            title: intl.formatMessage({id: 'blog.futureOfBuyingFreightTruck.seo.title'}),
            metaDescription: intl.formatMessage({
              id: 'blog.futureOfBuyingFreightTruck.seo.description',
            }),
            href: 'https://www.rivalog.com/blog/the-future-of-buying-freight-trucks-why-online-sales-are-the-way-to-go',
          }}
        >
          {/* <script type='application/ld+json'>{JSON.stringify(siteNavigationStructuredData)}</script> */}
        </Seo>
        <div className='page-body'>
          <div className='centered-wrap-main'>
            <header className='centered-top-container animating' role='banner'>
              <div className='centered-top'>
                <div className='blog-name container'>
                  <div className='container section' id='header'>
                    <div className='widget Header' data-version='2' id='Header1'>
                      <div className='header-widget'>
                        <div>
                          <h1>
                            <a href='https://rivalog.blogspot.com/'>Rivalog</a>
                          </h1>
                        </div>
                        <p></p>
                      </div>
                    </div>
                  </div>
                  <nav role='navigation'>
                    <div className='clearboth no-items section' id='page_list_top'></div>
                  </nav>
                </div>
              </div>
            </header>

            <div>
              <main className='centered-bottom' role='main'>
                <div className='main section'>
                  <div className='widget'>
                    <article className='post-outer-container'>
                      <div className='post-outer'>
                        <div className='post'>
                          <div className='post-start-content-head-wrap'>
                            <p>
                              {intl.formatMessage({id: 'blog.futureOfBuyingFreightTruck.title'})}
                            </p>
                          </div>
                          <h3 className='post-title entry-title'>
                            {intl.formatMessage({id: 'blog.futureOfBuyingFreightTruck.title1'})}
                          </h3>
                          <div className='post-header'>
                            <span className='byline'>{date.toDateString()}</span>
                          </div>
                          <div className='post-body'>
                            <h2 className='img-road-wrap'>
                              <div className='separator-wrap'>
                                <img
                                  className='improved-img-wrap'
                                  src={toAbsoluteUrl('/media/future-of-buying-freight-truck.jpg')}
                                  alt='max-effi'
                                />
                              </div>
                              <br />
                            </h2>

                            <p style={{textAlign: 'left'}}>
                              {intl.formatMessage({id: 'blog.futureOfBuyingFreightTruck.label'})}
                            </p>

                            <h2 className='img-road-wrap'>
                              {intl.formatMessage({id: 'blog.futureOfBuyingFreightTruck.label1'})}
                            </h2>

                            <p style={{textAlign: 'left'}}>
                              {intl.formatMessage({id: 'blog.futureOfBuyingFreightTruck.label2'})}
                            </p>

                            <h3 className='point-content-wrap point-content-wrap-color'>
                              {intl.formatMessage({id: 'blog.futureOfBuyingFreightTruck.label3'})}
                            </h3>

                            <p className='point-content-wrap-second'>
                              {intl.formatMessage({id: 'blog.futureOfBuyingFreightTruck.label4'})}
                            </p>

                            <p className='point-content-wrap-second'>
                              {intl.formatMessage({id: 'blog.futureOfBuyingFreightTruck.label5'})}
                            </p>

                            <h3 className='point-content-wrap point-content-wrap-color'>
                              {intl.formatMessage({id: 'blog.futureOfBuyingFreightTruck.label6'})}
                            </h3>

                            <p className='point-content-wrap-second'>
                              {intl.formatMessage({id: 'blog.futureOfBuyingFreightTruck.label7'})}
                            </p>

                            <p className='point-content-wrap-second'>
                              {intl.formatMessage({id: 'blog.futureOfBuyingFreightTruck.label8'})}
                            </p>

                            <h3 className='point-content-wrap point-content-wrap-color'>
                              {intl.formatMessage({id: 'blog.futureOfBuyingFreightTruck.label9'})}
                            </h3>

                            <p className='point-content-wrap-second'>
                              {intl.formatMessage({id: 'blog.futureOfBuyingFreightTruck.label10'})}
                            </p>

                            <p className='point-content-wrap-second'>
                              {intl.formatMessage({id: 'blog.futureOfBuyingFreightTruck.label11'})}
                            </p>

                            <h3 className='point-content-wrap point-content-wrap-color'>
                              {intl.formatMessage({id: 'blog.futureOfBuyingFreightTruck.label12'})}
                            </h3>

                            <p className='point-content-wrap-second'>
                              {intl.formatMessage({id: 'blog.futureOfBuyingFreightTruck.label13'})}
                            </p>

                            <p className='point-content-wrap-second'>
                              {intl.formatMessage({id: 'blog.futureOfBuyingFreightTruck.label14'})}
                            </p>

                            <h3 className='point-content-wrap point-content-wrap-color'>
                              {intl.formatMessage({id: 'blog.futureOfBuyingFreightTruck.label15'})}
                            </h3>

                            <p className='point-content-wrap-second'>
                              {intl.formatMessage({id: 'blog.futureOfBuyingFreightTruck.label16'})}
                            </p>

                            <p className='point-content-wrap-second'>
                              {intl.formatMessage({id: 'blog.futureOfBuyingFreightTruck.label17'})}
                            </p>

                            <h3 className='point-content-wrap point-content-wrap-color'>
                              {intl.formatMessage({id: 'blog.futureOfBuyingFreightTruck.label18'})}
                            </h3>

                            <p className='point-content-wrap-second'>
                              {intl.formatMessage({id: 'blog.futureOfBuyingFreightTruck.label19'})}
                            </p>

                            <p className='point-content-wrap-second'>
                              {intl.formatMessage({id: 'blog.futureOfBuyingFreightTruck.label20'})}
                            </p>

                            <p className='point-content-wrap-second'>
                              {intl.formatMessage({id: 'blog.futureOfBuyingFreightTruck.label21'})}
                            </p>

                            <h3 className='point-content-wrap point-content-wrap-color'>
                              {intl.formatMessage({id: 'blog.futureOfBuyingFreightTruck.label22'})}
                            </h3>

                            <p className='point-content-wrap-second'>
                              {intl.formatMessage({id: 'blog.futureOfBuyingFreightTruck.label23'})}
                            </p>

                            <p className='point-content-wrap-second'>
                              {intl.formatMessage({id: 'blog.futureOfBuyingFreightTruck.label24'})}
                            </p>

                            <h3 className='point-content-wrap point-content-wrap-color'>
                              {intl.formatMessage({id: 'blog.futureOfBuyingFreightTruck.label25'})}
                            </h3>

                            <p className='point-content-wrap-second'>
                              {intl.formatMessage({id: 'blog.futureOfBuyingFreightTruck.label26'})}
                            </p>

                            <p className='point-content-wrap-second'>
                              {intl.formatMessage({id: 'blog.futureOfBuyingFreightTruck.label27'})}
                            </p>

                            <h2 className='img-road-wrap'>
                              {intl.formatMessage({id: 'blog.futureOfBuyingFreightTruck.label28'})}
                            </h2>

                            <p style={{textAlign: 'left'}}>
                              {intl.formatMessage({id: 'blog.futureOfBuyingFreightTruck.label29'})}
                            </p>

                            <h3 className='point-content-wrap point-content-wrap-color'>
                              {intl.formatMessage({id: 'blog.futureOfBuyingFreightTruck.label30'})}
                            </h3>

                            <p className='point-content-wrap-second'>
                              {intl.formatMessage({id: 'blog.futureOfBuyingFreightTruck.label31'})}
                            </p>

                            <p className='point-content-wrap-second'>
                              {intl.formatMessage({id: 'blog.futureOfBuyingFreightTruck.label32'})}
                            </p>

                            <h3 className='point-content-wrap point-content-wrap-color'>
                              {intl.formatMessage({id: 'blog.futureOfBuyingFreightTruck.label33'})}
                            </h3>

                            <p className='point-content-wrap-second'>
                              {intl.formatMessage({id: 'blog.futureOfBuyingFreightTruck.label34'})}
                            </p>

                            <p className='point-content-wrap-second'>
                              {intl.formatMessage({id: 'blog.futureOfBuyingFreightTruck.label35'})}
                            </p>

                            <h3 className='point-content-wrap point-content-wrap-color'>
                              {intl.formatMessage({id: 'blog.futureOfBuyingFreightTruck.label36'})}
                            </h3>

                            <p className='point-content-wrap-second'>
                              {intl.formatMessage({id: 'blog.futureOfBuyingFreightTruck.label37'})}
                            </p>

                            <p className='point-content-wrap-second'>
                              {intl.formatMessage({id: 'blog.futureOfBuyingFreightTruck.label38'})}
                            </p>

                            <h2 className='img-road-wrap'>
                              {intl.formatMessage({id: 'blog.futureOfBuyingFreightTruck.label39'})}
                            </h2>

                            <p style={{textAlign: 'left'}}>
                              {intl.formatMessage({id: 'blog.futureOfBuyingFreightTruck.label40'})}
                            </p>

                            <p className='point-content-wrap-second'>
                              {intl.formatMessage({id: 'blog.futureOfBuyingFreightTruck.label41'})}
                            </p>
                          </div>
                        </div>
                      </div>
                    </article>
                  </div>
                </div>
              </main>
            </div>
          </div>
        </div>
      </div>

      <RelatedBlogs url='/blog/the-future-of-buying-freight-trucks-why-online-sales-are-the-way-to-go' />
    </div>
  )
}

export default TheFutureOfBuyingFreightTrucks
