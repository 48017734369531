/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import {IRouteWidget} from './types'
import {useIntl} from 'react-intl'

const RouteWidget = ({
  data,
  title,
  desc,
  onEdit,
  onDelete,
  onActivate,
  onDeactivate,
}: IRouteWidget) => {
  const intl = useIntl()
  return (
    <div className='card card-xl-stretch mb-5 mb-xl-8'>
      <div className='card-header border-0 d-flex align-items-center justify-content-between'>
        <h3 className='card-title fw-bolder text-dark'>{title}</h3>
        <small className='card-title fs-6 text-muted'>{desc}</small>
      </div>
      <div className='row card-body pt-0 mt-5'>
        {!data?.length && <p>{intl.formatMessage({id: 'RouteWidget.noAnyRoute'})}</p>}
        {data?.map((item) => (
          <div key={item.id} className={'col-xl-4 p-2 mb-4'}>
            <div
              className={
                ' rounded p-5 mb-7 ' +
                (item?.color === 'yellow'
                  ? 'bg-light-warning'
                  : item?.color === 'green'
                  ? 'bg-light-success border border-success '
                  : item?.color === 'red'
                  ? 'bg-light-danger border border-danger '
                  : item?.color === 'blue'
                  ? 'bg-light-primary'
                  : 'bg-light-warning')
              }
            >
              <div className='row mb-7'>
                <div className={'col-6'}>
                  <div className={'mb-5'}>
                    <span
                      className={
                        'svg-icon me-2 mt-1 ' +
                        (item?.color === 'yellow'
                          ? 'svg-icon-warning'
                          : item?.color === 'green'
                          ? 'svg-icon-success'
                          : item?.color === 'red'
                          ? 'svg-icon-danger'
                          : item?.color === 'blue'
                          ? 'bg-light-info'
                          : 'svg-icon-warning')
                      }
                    >
                      <KTSVG path='/media/icons/duotune/maps/map001.svg' className='svg-icon-1' />
                    </span>
                    <b className={'fs-5 '}>{intl.formatMessage({id: 'RouteWidget.from'})}</b>
                  </div>
                  <small className={'text-muted fs-6 mt-3'}>{item.fromCountry}</small> /{' '}
                  <small className={'text-muted fs-6 mt-3'}>{item.fromCity}</small>
                </div>
                <div className={'col-6'}>
                  <div className={'mb-5'}>
                    <span
                      className={
                        'svg-icon me-2 mt-1 ' +
                        (item?.color === 'yellow'
                          ? 'svg-icon-warning'
                          : item?.color === 'green'
                          ? 'svg-icon-success'
                          : item?.color === 'red'
                          ? 'svg-icon-danger'
                          : item?.color === 'blue'
                          ? 'bg-light-info'
                          : 'svg-icon-warning')
                      }
                    >
                      <KTSVG path='/media/icons/duotune/maps/map008.svg' className='svg-icon-1' />
                    </span>
                    <b className={'fs-5 mb-5'}>{intl.formatMessage({id: 'RouteWidget.to'})}</b>
                  </div>
                  <small className={'text-muted fs-6 mt-3'}>{item.toCountry}</small> /{' '}
                  <small className={'text-muted fs-6 mt-3'}>{item.toCity}</small>
                </div>
              </div>
              <div className='row mb-3'>
                <div className={'col-6'} style={{width: '44%'}}>
                  <div className={'mb-2'}>
                    <span
                      className={
                        'svg-icon me-2 mt-1 ' +
                        (item?.color === 'yellow'
                          ? 'svg-icon-warning'
                          : item?.color === 'green'
                          ? 'svg-icon-success'
                          : item?.color === 'red'
                          ? 'svg-icon-danger'
                          : item?.color === 'blue'
                          ? 'bg-light-info'
                          : 'svg-icon-warning')
                      }
                    >
                      <KTSVG path='/media/freight.svg' className='svg-icon-1' />
                    </span>
                    <b className={'fs-5 '}>
                      {intl.formatMessage({id: 'Freight.myAdvert.transportType'})}
                    </b>
                  </div>
                </div>

                <div className='col-6'>
                  <small className={'text-muted fs-6 mt-3'}>
                    {item.transportType?.description}
                  </small>
                </div>
              </div>
              <div className={'row'}>
                <div className={'col-2 d-flex align-items-end'}>
                  <button
                    title={intl.formatMessage({id: 'RouteWidget.edit'})}
                    className='btn btn-icon btn-bg-secondary btn-active-color-primary btn-sm me-1 mb-1'
                    onClick={() => onEdit(item)}
                  >
                    <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                  </button>
                </div>
                <div className={'col-2 d-flex align-items-end'}>
                  <button
                    title={intl.formatMessage({id: 'RouteWidget.delete'})}
                    className='btn btn-icon btn-bg-secondary btn-active-color-danger btn-sm me-1 mb-1'
                    onClick={() => onDelete(item)}
                  >
                    <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
                  </button>
                </div>
                <div
                  className={
                    'col-2 d-flex align-items-end ' + (item?.isActive ? 'order-2' : 'order-1')
                  }
                >
                  <button
                    title={intl.formatMessage({id: 'RouteWidget.activate'})}
                    className={
                      'btn btn-icon btn-active-color-warning btn-sm me-1 mb-1 ' +
                      (item?.isActive ? 'btn-bg-success' : 'btn-bg-secondary')
                    }
                    onClick={() => onActivate(item)}
                    disabled={item?.isActive}
                  >
                    <KTSVG path='/media/icons/duotune/arrows/arr085.svg' className='svg-icon-3' />
                  </button>
                </div>
                <div
                  className={
                    'col-2 d-flex align-items-end ' + (!item?.isActive ? 'order-2' : 'order-1')
                  }
                >
                  <button
                    title={intl.formatMessage({id: 'RouteWidget.deactivate'})}
                    className={
                      'btn btn-icon btn-active-color-danger btn-sm me-1 mb-1 ' +
                      (!item?.isActive ? 'btn-bg-danger' : 'btn-bg-secondary')
                    }
                    onClick={() => onDeactivate(item)}
                    disabled={!item?.isActive}
                  >
                    <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-3' />
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export {RouteWidget}
