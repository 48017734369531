import React from 'react'
import {useIntl} from 'react-intl'
import {useHistory} from 'react-router-dom'

export const Header = () => {
  const intl = useIntl()
  const history = useHistory()

  return (
    <header className='header-area'>
      <nav className='navbar navbar-expand-lg'>
        <div className='container-fluid'>
          <a className='navbar-brand' href='/'>
            <img src='/media/logo.png' alt='' width={134} height={23}/>
          </a>
          <div className='header-store-main-content-wrap'>
            <button type='button' style={{border: 'none'}}>
              <a
                rel='noreferrer'
                href='https://play.google.com/store/apps/details?id=com.iwa.logistics'
                target='_blank'
              >
                <img src='/media/google-play.png' alt='' className='store-img-wrap' width={130} height={39} />
              </a>
            </button>
            <button type='button' style={{border: 'none'}}>
              <a
                rel='noreferrer'
                href='https://apps.apple.com/us/app/rivalog/id1613475053'
                target='_blank'
              >
                <img src='/media/app-store.png' alt='' className='store-img-wrap' width={130} height={39} />
              </a>
            </button>
          </div>
          <button
            className='navbar-toggler nav-border-wrap'
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='#navbarSupportedContent'
            aria-controls='navbarSupportedContent'
            aria-expanded='false'
            aria-label='Toggle navigation'
          >
            <span className='navbar-toggler-icon'>
              <i className='fa fa-bars' style={{fontSize: '18px'}}></i>
            </span>
          </button>
          <div className='collapse navbar-collapse' id='navbarSupportedContent'>
            <div className='d-flex header-button-area'>
              <button
                type='button'
                className='secondary-button'
                onClick={() => history.push('/public-freight-create')}
              >
                <span>
                  <img src='/media/img1.svg' />
                </span>
                {intl.formatMessage({id: 'public.newLandingPage.createFreightPostings'})}
              </button>
              <button
                type='button'
                className='primary-button'
                onClick={() => history.push('/auth/login')}
              >
                <span>
                  <img src='/media/img2.svg' />
                </span>
                {intl.formatMessage({id: 'public.newLandingPage.signIn'})}
              </button>
              <button
                type='button'
                className='secondary-button'
                onClick={() => history.push('/auth/registration')}
              >
                <span>
                  <img src='/media/img3.svg' />
                </span>
                {intl.formatMessage({id: 'public.newLandingPage.signUp'})}
              </button>
            </div>
          </div>
        </div>
      </nav>
    </header>
  )
}

export default Header
