import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'

import {PageTitle} from '../../../../_metronic/layout/core'
import {KTSVG} from '../../../../_metronic/helpers'
import {ListsWidget} from '../../../../_metronic/partials/widgets'

import Loading from '../../../Components/Loading'
import Modal from '../../../Components/Modal'
import CreateBankAccount from './Popups/CreateBankAccount'
import UpdateBankAccount from './Popups/UpdateBankAccount'

import {BankAccountsService} from '../../../../services/CarrierServices/BankAccountsService'

import useScrollLock from '../../../../utils/useScrollLock'
import {exceptThisSymbols} from '../../../../utils/common'

import './BankAccount.scss'

const BankAccount = () => {
  const intl = useIntl()
  /**
   ** UTILS
   **/
  const bankServ = new BankAccountsService()
  const [loading, setLoading] = useState<boolean>(false)
  /* const intl = useIntl()*/

  /*List States*/
  const [accountList, setAccountList] = useState<any[]>([])

  /*Screens and modals*/
  const [selectedAccount, setSelectedAccount] = useState<any>(null)
  const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false)
  const [showCreatePopup, setShowCreatePopup] = useState<boolean>(false)
  const [showUpdatePopup, setShowUpdatePopup] = useState<boolean>(false)

  const {scrollUnlock} = useScrollLock()
  useEffect(() => {
    if (!showDeletePopup && !showCreatePopup && !showUpdatePopup) scrollUnlock()
  }, [showDeletePopup, showCreatePopup, showUpdatePopup])

  /*Popup States*/
  const [newAccount, setNewAccount] = useState<any>({
    explanation: '',
    swiftCode: null,
    accountHolder: '',
    iban: '',
  })
  const [updatedAccount, setUpdatedAccount] = useState<any>({
    bankAccountId: -1,
    explanation: '',
    swiftCode: null,
    accountHolder: '',
    iban: '',
  })

  /**
   *! API CALL START
   **/
  const getAccountsList = () => {
    bankServ
      .getAccounts()
      .then((resp: any) => {
        setAccountList(resp?.data)
        setLoading(false)
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const getAccount = (id: number) => {
    return bankServ.getAccount(id)
  }

  const deleteAccount = (id: number) => {
    bankServ
      .deleteAccount(id)
      .then((resp: any) => {
        toast.success(intl.formatMessage({id: 'Carrier.bankAccount.bankAccDeleteSuccess'}))
        getAccountsList()
        setShowDeletePopup(false)
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const setPrimary = (id: number) => {
    bankServ
      .setPrimary(id)
      .then((resp: any) => {
        toast.success(intl.formatMessage({id: 'Carrier.bankAccount.bankAccPrimarySucess'}))
        getAccountsList()
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const updateAccount = (data: {
    id?: number
    explanation?: string
    swiftCode?: number
    accountHolder?: string
    iban?: string
  }) => {
    bankServ
      .updateAccount(data.id, data.explanation, data.swiftCode, data.accountHolder, data.iban)
      .then((resp: any) => {
        toast.success(intl.formatMessage({id: 'Carrier.bankAccount.bankAccUpdateSuccess'}))
        getAccountsList()
        setShowUpdatePopup(false)
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const createAccount = (data: {swiftCode?: number; accountHolder?: string; iban?: string}) => {
    bankServ
      .createAccount(data.swiftCode, data.accountHolder, data.iban)
      .then((resp: any) => {
        toast.success(intl.formatMessage({id: 'Carrier.bankAccount.bankAccCreateSuccess'}))
        getAccountsList()
        setShowCreatePopup(false)
        setNewAccount({
          explanation: '',
          swiftCode: 0,
          accountHolder: '',
          iban: '',
        })
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }
  /**
   *! API CALL END
   **/

  useEffect(() => {
    setLoading(true)
    getAccountsList()
  }, [])

  return (
    <>
      {loading && <Loading />}
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({id: 'Carrier.bankAccount.title'})}
      </PageTitle>
      <div>
        <div className={'d-flex align-items-center justify-content-end'}>
          <button
            onClick={() => setShowCreatePopup(true)}
            className='btn btn-sm btn-light-primary mb-5 border border-2 fs-4'
          >
            <KTSVG path='media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
            {intl.formatMessage({id: 'Carrier.bankAccount.addBankAcc'})}
          </button>
        </div>
        <div className=''>
          <ListsWidget
            title={intl.formatMessage({id: 'Carrier.bankAccount.account'})}
            desc={intl.formatMessage({id: 'Carrier.bankAccount.account.desc'})}
            data={
              accountList &&
              accountList?.map((item: any) => ({
                id: item.bankAccountId,
                name: item.accountHolder,
                iban: item.iban,
                swf: item.swiftCode,
                explanation: item.explanation,
                color: item.isPrimary && item.isPrimary === 'Y' ? 'green' : 'red',
                isActive: item.isPrimary === 'Y',
              }))
            }
            /*onEdit={(data: any) => {
                            setLoading(true);
                            setSelectedAccount(data);
                            getAccount(data.id)
                                .then((resp: any) => {
                                    setLoading(false);
                                    setUpdatedAccount((item: any) =>
                                        ({
                                            ...item,
                                            id: resp.data.bankAccountId,
                                            explanation: resp.data.explanation,
                                            iban: resp.data.iban,
                                            swiftCode: resp.data.swiftCode,
                                            accountHolder: resp.data.accountHolder,
                                        }))
                                    setShowUpdatePopup(true);
                                }).catch((e) => {
                                toast.error(e.response.data.message + ' | ' + e.response.data.code)
                            })
                        }}*/
            onDelete={(data: any) => {
              setSelectedAccount(data)
              setShowDeletePopup(true)
            }}
            setPrimary={(data: any) => {
              setSelectedAccount(data)
              setLoading(true)
              setPrimary(data.id)
            }}
          />
        </div>
      </div>

      {/*DELETE POPUP*/}
      {showDeletePopup && (
        <Modal
          closeBtnSettings={{
            show: true,
            onClick: () => setShowDeletePopup(false),
          }}
          customAll={
            <div className={''}>
              <h3 className={'mt-5 mb-15'}>
                {intl.formatMessage({id: 'Carrier.bankAccount.delete.title'})}
              </h3>
              <div className={'row'}>
                <div className={'col-md-6'}>
                  <button
                    className={'btn btn-danger w-100'}
                    onClick={() => {
                      setShowDeletePopup(false)
                    }}
                  >
                    {intl.formatMessage({id: 'Carrier.bankAccount.delete.cancel'})}
                  </button>
                </div>
                <div className={'col-md-6'}>
                  <button
                    className={'btn btn-success w-100'}
                    onClick={() => {
                      setLoading(true)
                      deleteAccount(selectedAccount.id)
                    }}
                  >
                    {intl.formatMessage({id: 'Carrier.bankAccount.delete.approve'})}
                  </button>
                </div>
              </div>
            </div>
          }
        />
      )}

      {/*CREATE POPUP*/}
      {showCreatePopup && (
        <CreateBankAccount
          setNewAccount={setNewAccount}
          newAccount={newAccount}
          exceptThisSymbols={exceptThisSymbols}
          setLoading={setLoading}
          createAccount={createAccount}
          onClose={() => {
            setShowCreatePopup(false)
            setNewAccount({
              explanation: '',
              swiftCode: 0,
              accountHolder: '',
              iban: '',
            })
          }}
        />
      )}

      {/*UPDATE POPUP*/}
      {showUpdatePopup && (
        <UpdateBankAccount
          setNewAccount={setNewAccount}
          newAccount={newAccount}
          exceptThisSymbols={exceptThisSymbols}
          updatedAccount={updatedAccount}
          updateAccount={updateAccount}
          setLoading={setLoading}
          onClose={() => setShowUpdatePopup(false)}
        />
      )}
    </>
  )
}
export {BankAccount}
