import React, {useState} from 'react'
import {useIntl} from 'react-intl'
import {shallowEqual, useSelector} from 'react-redux'

import {UserModel} from '../../../auth/models/UserModel'
import RightLayoutPopup from '../../../../Components/RightLayoutPopup'
import {RootState} from '../../../../../setup'

import './MessageChatBox.scss'

const MessageChatBox = ({onClose, data, sendMessage, setLoading}: any) => {
  const intl = useIntl()
  const [message, setMessage] = useState<any>('')

  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  return (
    <RightLayoutPopup
      title={intl.formatMessage({id: 'freight.myListing.myMessage'})}
      className='right-layout-show-wrap'
      isCloseIcon
      closeOnClick={() => onClose()}
    >
      <div className='main' style={{height: 'calc(100% - 176px)'}}>
        <div className='container' style={{height: '100%', paddingRight: 0}}>
          <div className='chat-log'>
            {data?.map((item: any) => (
              <>
                {item?.sender?.userId !== user?.userId && (
                  <div className='chat-prof-wrap'>
                    <span className='chat-img right'>
                      <img src={item?.sender?.image} alt='profile' className='img-circle' />
                    </span>
                    <div className='chat-log__item'>
                      <h3 className='chat-log__author'>
                        {item?.sender?.name} {item?.sender?.surname}
                      </h3>
                      <div className='chat-log__message'>{item?.message}</div>
                    </div>
                  </div>
                )}
                {item?.sender?.userId === user?.userId && (
                  <div className='chat-log__item chat-log__item--own'>
                    <h3 className='chat-log__author'>
                      {item?.sender?.name} {item?.sender?.surname}{' '}
                      {intl.formatMessage({id: 'freight.myListing.you'})}
                    </h3>
                    <div className='chat-log__message'>{item?.message}</div>
                  </div>
                )}
              </>
            ))}
          </div>
        </div>
        <div className='chat-form'>
          <div className='container'>
            <div className='form-horizontal'>
              <div className='row'>
                <div className='col-sm-10 col-xs-8'>
                  <input
                    type='text'
                    className='form-control'
                    id='message'
                    placeholder='Message'
                    value={message}
                    onChange={(e: any) => setMessage(e?.target?.value)}
                  />
                </div>
                <div className='col-sm-2 col-xs-4'>
                  <button
                    disabled={!(message.length > 0)}
                    className='btn btn-success btn-block'
                    onClick={() => {
                      sendMessage(message)
                      setMessage('')
                      setLoading(true)
                    }}
                  >
                    {intl.formatMessage({id: 'freight.myListing.message.send'})}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </RightLayoutPopup>
  )
}

export {MessageChatBox}
