import {apiUrl, globalPageCount} from "../conf";
import axios from "axios";

export class FileReviewService {

    getUserFiles(pageIndex?: number) {
        const config: any = {
            method: 'GET',
            url: apiUrl + `/user-management/users/files/unapproved?pageIndex=${pageIndex}&pageCount=${globalPageCount}`,
            headers: {
                'Content-Type': 'application/json',
            },
        };
        return axios(config);
    }

    getVehicleFiles(pageIndex?: number) {
        const config: any = {
            method: 'GET',
            url: apiUrl + `/vehicle-management/vehicles/files/unapproved?pageIndex=${pageIndex}&pageCount=${globalPageCount}`,
            headers: {
                'Content-Type': 'application/json',
            },
        };
        return axios(config);
    }

    getTrailerFiles(pageIndex?: number) {
        const config: any = {
            method: 'GET',
            url: apiUrl + `/trailer-management/trailers/files/unapproved?pageIndex=${pageIndex}&pageCount=${globalPageCount}`,
            headers: {
                'Content-Type': 'application/json',
            },
        };
        return axios(config);
    }

    approveDriverFile(id: number | string) {
        const config: any = {
            method: 'PATCH',
            url: apiUrl + '/user-management/users/files/' + id + '/approve',
            headers: {
                'Content-Type': 'application/json',
            },
        };
        return axios(config);
    }

    approveVehicleFile(id: number | string) {
        const config: any = {
            method: 'PATCH',
            url: apiUrl + '/vehicle-management/vehicles/files/' + id + '/approve',
            headers: {
                'Content-Type': 'application/json',
            },
        };
        return axios(config);
    }

    approveTrailerFile(id: number | string) {
        const config: any = {
            method: 'PATCH',
            url: apiUrl + '/trailer-management/trailers/files/' + id + '/approve',
            headers: {
                'Content-Type': 'application/json',
            },
        };
        return axios(config);
    }

    rejectDriverFile(id: number | string, reason: string) {
        const config: any = {
            method: 'PATCH',
            url: apiUrl + '/user-management/users/files/reject',
            headers: {
                'Content-Type': 'application/json',
            },
            data: JSON.stringify({
                userFileId: id,
                rejectReason: reason
            })
        };
        return axios(config);
    }

    rejectVehicleFile(id: number | string, reason: string) {
        const config: any = {
            method: 'PATCH',
            url: apiUrl + '/vehicle-management/vehicles/files/reject',
            headers: {
                'Content-Type': 'application/json',
            },
            data: JSON.stringify({
                vehicleFileId: id,
                rejectReason: reason
            })
        };
        return axios(config);
    }

    rejectTrailerFile(id: number | string, reason: string) {
        const config: any = {
            method: 'PATCH',
            url: apiUrl + '/trailer-management/trailers/files/reject',
            headers: {
                'Content-Type': 'application/json',
            },
            data: JSON.stringify({
                trailerFileId: id,
                rejectReason: reason
            })
        };
        return axios(config);
    }

}
