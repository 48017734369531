import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {useHistory, useLocation} from 'react-router-dom'

import {PageTitle} from '../../../../_metronic/layout/core'
import ActionDropDown from '../../../../_metronic/partials/content/dropdown/ActionDropdown'

import Loading from '../../../Components/Loading'
import DataTableAdvanced from '../../../Components/DataTableAdvanced'
import RightLayoutPopup from '../../../Components/RightLayoutPopup'
import Modal from '../../../Components/Modal'
import {loadMapApi} from '../../../Components/GoogleMap/utils/GoogleMapsUtils'
import Map from '../../../Components/GoogleMap/Map'
import Dropbox from '../../../Components/Dropbox'
import EditPreferences from './Popups/EditPreferences'
import ShowProposalTable from './Popups/ShowProposalTable'

import {AdvertService} from '../../../../services/FreightOperatorServices/AdvertService'
import {AddressService} from '../../../../services/FreightOperatorServices/AddressService'
import {CommonService} from '../../../../services/FreightOperatorServices/CommonService'
import {AdvertService as AdminAdvertService} from '../../../../services/AdminServices/AdvertService'

import useScrollLock from '../../../../utils/useScrollLock'
import getDateTime, {priceConversion, getFreightIcon} from '../../../../utils/common'
import {KTSVG} from '../../../../_metronic/helpers'

import './MyAdverts.scss'

const MyAdverts = () => {
  const intl = useIntl()
  const useQuery = () => new URLSearchParams(useLocation().search)
  let query = useQuery()
  useEffect(() => {
    if (query?.get('filter'))
      switch (query?.get('filter')) {
        case 'waiting':
          return setFilter('1')
        case 'cancel':
          return setFilter('2')
        case 'reject':
          return setFilter('3')
        case 'approved':
          return setFilter('5')
        case 'ready-container':
          return setFilter('6')
        case 'ready-carrier':
          return setFilter('7')
        case 'onroad':
          return setFilter('8')
        case 'deliver':
          return setFilter('9')
        case 'draft':
          return setFilter('10')
      }
  }, [])

  /* const intl = useIntl()*/
  const [loading, setLoading] = useState<boolean>(false)
  const [pageMIndex, setPageMIndex] = useState<number>(0)
  const [proposalPageMIndex, setProposalPageMIndex] = useState<number>(0)
  const [filterCounter, setFilterCounter] = useState<number>(0)
  const [selectedProposalTableList, setSelectedProposalTableList] = useState<any[]>([])
  const history = useHistory()

  /*Service States*/
  const advertServ = new AdvertService()
  const addressServ = new AddressService()
  const commonServ = new CommonService()
  const adminAdvertService = new AdminAdvertService()

  /*List States*/
  const [advertList, setAdvertList] = useState<any[]>([])
  const [advertDetailedList, setAdvertDetailedList] = useState<any>({})
  const [selectedFreightId, setSelectedFreightId] = useState<number>(-1)

  /*Transport States Start*/
  const [transportTypes, setTransportTypes] = useState([])
  /*Transport States End*/

  /*Screens and modals*/
  const [showDetailsScreen, setShowDetailsScreen] = useState<boolean>(false)
  const [showMapScreen, setShowMapScreen] = useState<boolean>(false)
  const [showUpdateScreen, setShowUpdateScreen] = useState<boolean>(false)
  const [showUpdateSuccess, setShowUpdateSuccess] = useState<boolean>(false)
  const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false)
  const [showProposalTablePopup, setShowProposalTablePopup] = useState<boolean>(false)

  const [selectedEditProposal, setSelectedEditProposal] = useState<any>({
    price: '',
    currency: '',
    explanation: '',
  })

  const [selectedCountryName, setSelectedCountryName] = useState<any>('')

  const {scrollLock, scrollUnlock} = useScrollLock()
  useEffect(() => {
    if (
      !showDetailsScreen &&
      !showMapScreen &&
      !showUpdateScreen &&
      !showDeletePopup &&
      !showUpdateSuccess
    )
      scrollUnlock()
  }, [showMapScreen, showDetailsScreen, showUpdateScreen, showDeletePopup, showUpdateSuccess])

  /*Trailer States Start*/
  const [trailerTypes, setTrailerTypes] = useState([])
  const [floorTypes, setFloorTypes] = useState([])
  const [trailerSpecifications, setTrailerSpecifications] = useState([])
  /*Trailer States End*/

  /*Freight States Start*/
  const [freightTypes, setFreightTypes] = useState<any>([])
  const [freightLoadTypes, setFreightLoadTypes] = useState<any>([])
  const [freightPackageTypes, setFreightPackageTypes] = useState<any>([])
  /*Freight States End*/

  /*Other Lists Start*/
  const [addressList, setAdressList] = useState<any>([])
  const [currencyList, setCurrencyList] = useState<any>([])
  const [gtipList, setGtipList] = useState<any>([])
  const [countries, setCountries] = useState<any>([])
  const [cities, setCities] = useState<any>([])
  const [advertLocation, setAdvertLocation] = useState<any>()
  /*Other Lists End*/

  /*Filter Select States*/
  const [filter, setFilter] = useState<string>('all')
  const [statusList, setStatusList] = useState<any>([])

  const [scriptLoaded, setScriptLoaded] = useState(false)
  const [distanceInKm, setDistanceInKm] = useState<number>(-1)
  const [selectedMarkerAddress, setSelectedMarkerAddress] = useState<any>()
  const [showFilterPanel, setShowFilterPanel] = useState<boolean>(false)

  const [isNextPage, setIsNextPage] = useState<boolean>(false)

  useEffect(() => {
    if ((filter && filterCounter > 0) || query?.get('filter') === null) {
      setLoading(true)
      getAdvertList({freightStatusId: filter})
    }
  }, [filter])

  const renderDistanceSentence = () => {
    return (
      <div className='distance-info'>
        {intl.formatMessage({id: 'Admin.trackOnMap.renderDistance'}, {value: distanceInKm})}
      </div>
    )
  }

  /*----------------------------*/
  /** Formik Preferences Start**/
  const initialValues = {
    title: '',
    trailerType: '',
    trailerSpecification: [],
    floorType: '',

    loadDate: '',
    loadNonFormatDate: new Date().getTime(),
    loadTime: '',

    destDate: '',
    destNonFormatDate: new Date().getTime(),
    destTime: '',

    loadAddress: '',
    destinationAddress: '',
    freightType: '',
    freightPackageType: '',
    freightLoadType: '',
    /* volumeDm3: '',*/
    weight: '',
    value: '',
    currency: '',
    flammable: false,
    stackable: false,

    width: '',
    height: '',
    freightLenght: '',
    desi: '',
    ldm: '',
    harmonizedSystemCode: '',
  }
  const loginSchema = Yup.object().shape({
    width: Yup.string().notRequired(),
    height: Yup.string().notRequired(),
    freightLenght: Yup.string().notRequired(),
    desi: Yup.string().notRequired(),
    ldm: Yup.string().notRequired(),

    title: Yup.string().notRequired(),
    trailerType: Yup.string().notRequired(),
    floorType: Yup.string().notRequired(),
    trailerSpecification: Yup.array().notRequired(),

    loadDate: Yup.string().required(intl.formatMessage({id: 'global.thisFieldRequired'})),
    loadTime: Yup.string().required(intl.formatMessage({id: 'global.thisFieldRequired'})),
    destDate: Yup.string().required(intl.formatMessage({id: 'global.thisFieldRequired'})),
    destTime: Yup.string().required(intl.formatMessage({id: 'global.thisFieldRequired'})),

    loadAddress: Yup.string().required(intl.formatMessage({id: 'global.thisFieldRequired'})),
    destinationAdress: Yup.string().required(intl.formatMessage({id: 'global.thisFieldRequired'})),
    freightType: Yup.string().required(intl.formatMessage({id: 'global.thisFieldRequired'})),
    freightPackageType: Yup.string().notRequired(),
    freightLoadType: Yup.string().notRequired(),
    /* volumeDm3: Yup.string(),*/
    weight: Yup.string().notRequired(),
    value: Yup.string().notRequired(),
    currency: Yup.string().notRequired(),
    harmonizedSystemCode: Yup.string().required(intl.formatMessage({id: 'global.gtipRequired'})),
    flammable: Yup.boolean(),
    stackable: Yup.boolean(),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setShowUpdateScreen(false)
    },
  })
  /** Formik Preferences End**/

  const fillUpdateScreenInputs = (dat: any) => {
    formik.setFieldValue('title', dat?.description)
    formik.setFieldValue('value', dat?.value)
    formik.setFieldValue('width', dat?.width)
    formik.setFieldValue('height', dat?.height)
    formik.setFieldValue('freightLenght', dat?.freightLenght)
    formik.setFieldValue('desi', dat?.desi)
    formik.setFieldValue('ldm', dat?.ldm)
    formik.setFieldValue('flammable', dat?.flammable === 'Y')
    formik.setFieldValue('stackable', dat?.stackable === 'Y')
    formik.setFieldValue('currency', dat?.currency)
    formik.setFieldValue('plannedDepartureDate', dat?.plannedDepartureDate)
    formik.setFieldValue('plannedArrivalDate', dat?.plannedArrivalDate)
    formik.setFieldValue('loadAddress', dat?.fromAddress?.addressId)
    formik.setFieldValue('destinationAddress', dat?.toAddress?.addressId)
    formik.setFieldValue('trailerType', dat?.trailerType?.trailerTypeId)
    formik.setFieldValue('floorType', dat?.floorType?.floorTypeId)
    formik.setFieldValue('freightType', dat?.freightType?.freightTypeId)
    formik.setFieldValue('freightLoadingType', dat?.freightLoadingType?.freightLoadingTypeId)
    formik.setFieldValue('freightPackageType', dat?.freightPackageType?.freightPackageTypeId)
    formik.setFieldValue('harmonizedSystemCode', dat?.harmonizedSystemCode?.code)
    setCurrSpecDat(dat.specificationList)
  }

  /** Advert Api call Start **/
  const getAdvertList = (data?: any) => {
    advertServ
      .getFreightAdverts({
        ...(data?.freightStatusId && {freightStatusId: data?.freightStatusId}),
        ...{pageIndex: data?.pageMIndex ?? pageMIndex},
      })
      .then((resp) => {
        setLoading(false)
        setIsNextPage(false)
        setAdvertList(resp?.data)
      })
      .catch((e: any) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
        setLoading(false)
        setIsNextPage(false)
      })
  }

  const getAdvertDetailedList = (freightId: number) => {
    advertServ
      .getAdvertWithId(freightId)
      .then((resp) => {
        setAdvertDetailedList(resp?.data)
        fillUpdateScreenInputs(resp.data)
        setLoading(false)
      })
      .catch((e: any) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
        setLoading(false)
      })
  }

  /** Transport api call Start **/
  const getTransportTypes = () => {
    advertServ
      .carrierTransportTypesList()
      .then((resp) => {
        setTransportTypes(resp?.data)
      })
      .catch((e: any) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
        setLoading(false)
      })
  }
  /** Transport api call End **/

  const deleteAdvert = (advertId: number) => {
    advertServ
      .deleteAdvert(advertId)
      .then((resp) => {
        setLoading(false)
        toast.success(intl.formatMessage({id: 'Admin.Adverts.deleteMessage'}), {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
      .catch((e: any) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
        setLoading(false)
      })
  }

  const sendAdvert = (advertId: number) => {
    advertServ
      .sendAdvertToApproval(advertId)
      .then((resp) => {
        getAdvertList({freightStatusId: filter, pageMIndex})
        toast.success(intl.formatMessage({id: 'Freight.advert.load.success'}), {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
      .catch((e: any) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
        setLoading(false)
      })
  }

  const updateAdvert = (data: any) => {
    const fromAddressData = {
      latitude: data.fromLatitude,
      longitude: data.fromLongitude,
      neighborhood: data.fromNeighborhood,
      postCode: data.fromPostCode,
      street: data.fromStreet,
      streetNumber: data.fromStreetNumber,
      city: {
        cityId: parseInt(data.fromCity),
      },
      country: {
        code: data.fromCountry,
      },
    }

    const toAddressData = {
      latitude: data.toLatitude,
      longitude: data.toLongitude,
      neighborhood: data.toNeighborhood,
      postCode: data.toPostCode,
      street: data.toStreet,
      streetNumber: data.toStreetNumber,
      city: {
        cityId: parseInt(data.toCity),
      },
      country: {
        code: data.toCountry,
      },
    }

    let consignor = null
    let consignee = null

    if (
      data.consignorName ||
      data.consignorSurName ||
      data.consignorPhone ||
      data.consignorEmail ||
      data.consignorTaxNumber ||
      data.consignorTaxOffice ||
      data.consignorReferenceClientId
    ) {
      consignor = {
        name: data?.consignorName || null,
        surname: data?.consignorSurName || null,
        countryCode: data?.consignorPhone ? data.consignorCountryCode : null,
        phone: data?.consignorPhone || null,
        email: data?.consignorEmail || null,
        taxNumber: data?.consignorTaxNumber || null,
        taxOffice: data?.consignorTaxOffice || null,
        referenceClientId: data?.consignorReferenceClientId || null,
      }
    }

    if (
      data.consigneeName ||
      data.consigneeSurName ||
      data.consigneePhone ||
      data.consigneeEmail ||
      data.consigneeTaxNumber ||
      data.consigneeTaxOffice ||
      data.consigneeReferenceClientId
    ) {
      consignee = {
        name: data?.consigneeName || null,
        surname: data?.consigneeSurName || null,
        countryCode: data?.consigneePhone ? data?.consigneeCountryCode : null,
        phone: data?.consigneePhone || null,
        email: data?.consigneeEmail || null,
        taxNumber: data?.consigneeTaxNumber || null,
        taxOffice: data?.consigneeTaxOffice || null,
        referenceClientId: data?.consigneeReferenceClientId || null,
      }
    }

    advertServ
      .updateAdvertFreight(
        data.freightId,
        data.title,
        data.value,
        data.weight,
        data.width,
        data.height,
        data.freightLength,
        data.desi,
        data.ldm,
        data.flammable,
        data.stackable,
        parseFloat(data.currency),
        new Date(data.loadDate + ' ' + data.loadTime.replace(/(AM|PM)/, '')).getTime().toString(),
        new Date(data.destDate + ' ' + data.destTime.replace(/(AM|PM)/, '')).getTime().toString(),
        parseInt(data.loadAddress),
        parseInt(data.destinationAddress),
        parseInt(data.trailerType),
        parseInt(data.floorType),
        data.specificationList,
        parseInt(data.transportType),
        parseInt(data.freightType),
        parseInt(data.freightLoadType),
        parseInt(data.freightPackageType),
        data.harmonizedSystemCode,
        parseInt(data.freightContentTypeId),
        fromAddressData,
        toAddressData,
        consignor,
        consignee
      )
      .then(() => {
        setLoading(false)
        getAdvertList({freightStatusId: filter, pageMIndex})
        setShowUpdateSuccess(true)
        toast.success(intl.formatMessage({id: 'Admin.Adverts.updateMessage'}), {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
      .catch((e: any) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }
  /** Advert Api call End **/

  /** Trailer api call Start **/
  const getTrailerTypes = () => {
    advertServ
      .trailerTypes()
      .then((resp) => {
        setTrailerTypes(resp?.data)
      })
      .catch((e: any) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
        setLoading(false)
      })
  }

  const getFloorTypes = () => {
    advertServ
      .floorTypes()
      .then((resp) => {
        setFloorTypes(resp?.data)
      })
      .catch((e: any) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
        setLoading(false)
      })
  }
  const getTrailerSpecifications = (trailerId: number) => {
    return advertServ.trailerSpecifications(trailerId).then((resp) => {
      setTrailerSpecifications(resp?.data)
      setLoading(false)
    })
  }
  /** Trailer api call End **/

  /** Freights api call Start **/
  const getFreightTypes = () => {
    advertServ
      .freightTypes()
      .then((resp) => {
        setFreightTypes(resp?.data)
      })
      .catch((e: any) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
        setLoading(false)
      })
  }
  const getFreightLoadTypes = () => {
    advertServ
      .freightLoadingTypes()
      .then((resp) => {
        setFreightLoadTypes(resp?.data)
      })
      .catch((e: any) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
        setLoading(false)
      })
  }
  const getFreightPackageTypes = () => {
    advertServ
      .freightPackageTypes()
      .then((resp) => {
        setFreightPackageTypes(resp?.data)
      })
      .catch((e: any) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
        setLoading(false)
      })
  }
  /** Freights api call End **/

  /** Address api call Start **/
  const getAddressList = () => {
    addressServ
      .getAdressList()
      .then((resp) => {
        setAdressList(resp?.data)
      })
      .catch((e: any) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
        setLoading(false)
      })
  }
  /** Address api call End **/

  /** Currencies api call Start **/
  const getCurrencyList = () => {
    advertServ
      .currencyList()
      .then((resp) => {
        setCurrencyList(resp?.data)
      })
      .catch((e: any) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
        setLoading(false)
      })
  }
  /** Currencies api call End **/

  /** Gtip api call Start **/
  const getGtip = (searchText?: string, pageCount: number = 20) => {
    advertServ
      .gtipList(searchText ?? '', pageCount)
      .then((resp) => {
        setGtipList(resp?.data)
      })
      .catch((e: any) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
        setLoading(false)
      })
  }
  /** Gtip api call End **/

  /** Country and City List api call Start **/
  const getCountryList = () => {
    commonServ
      .countryList()
      .then((resp) => {
        setCountries(resp.data)
        setLoading(false)
      })
      .catch((e: any) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
        setLoading(false)
      })
  }

  /** Country and City List api call End **/

  /** Address Types api call Start **/
  const getLocation = (advertId: number) => {
    advertServ
      .getAdvertLocation(advertId)
      .then((resp) => {
        setAdvertLocation(resp?.data)
        setLoading(false)
        setShowMapScreen(true)
      })
      .catch((e: any) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
        setLoading(false)
      })
  }
  /** Address Types api call End **/

  const getCityList = (countryCode: string) => {
    commonServ
      .cityList(countryCode)
      .then((resp) => {
        setCities(resp.data)
        setLoading(false)
      })
      .catch((e: any) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
        setLoading(false)
      })
  }

  const getStatusTypes = () => {
    adminAdvertService
      .getStatusTypes()
      .then((resp: any) => {
        setStatusList(
          [
            {
              freightStatusId: 'all',
              description: intl.formatMessage({id: 'Admin.Adverts.all'}),
            },
          ].concat(resp?.data)
        )
        setLoading(false)
      })
      .catch((e: any) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
        setLoading(false)
      })
  }

  /*disabled days for arrive date input */
  let disDate = new Date(formik.values.loadNonFormatDate)

  const getApiCalls = () => {
    setLoading(true)
    getTrailerTypes()
    setLoading(true)
    getFloorTypes()
    setLoading(true)
    getTransportTypes()
    setLoading(true)
    getFreightTypes()
    setLoading(true)
    getFreightLoadTypes()
    setLoading(true)
    getFreightPackageTypes()
    setLoading(true)
    getAddressList()
    setLoading(true)
    getCurrencyList()
    setLoading(true)
    getGtip()
    setLoading(true)
    getAddressList()
    setLoading(true)
    getCountryList()
    setLoading(true)
    getStatusTypes()

    const googleMapScript = loadMapApi()
    googleMapScript.addEventListener('load', function () {
      setScriptLoaded(true)
    })
  }

  useEffect(() => {
    getApiCalls()
    setFilterCounter(1)
  }, [])

  const [currSpecDat, setCurrSpecDat] = useState<any>(null)

  useEffect(() => {
    formik.values.trailerType &&
      getTrailerSpecifications(parseInt(formik.values.trailerType))
        .then((resp: any) => {
          formik.setFieldValue('freightSpecificationList', currSpecDat)
        })
        .catch((e: any) => {
          toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
          setLoading(false)
        })
  }, [formik.values.trailerType])

  const onPaginationChange = (page: number) => {
    if (!isNextPage) {
      getAdvertList({
        freightStatusId: filter,
        pageMIndex: page,
      })
      setPageMIndex(page)
    }

    setIsNextPage(true)
  }

  const getProposalTableList = (freightId: number, pageIndex: number) => {
    advertServ
      .getFreightProposalList(freightId, pageIndex)
      .then((resp: any) => {
        setLoading(false)
        setSelectedProposalTableList(resp.data)
      })
      .catch((e: any) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
        setLoading(false)
      })
  }

  const handleGetSelectedFreightProposal = (freightProposalId: number) => {
    advertServ
      .getSelectedFreightProposal(freightProposalId)
      .then((resp: any) => {
        setLoading(false)
        setSelectedCountryName(resp?.data?.currency?.description)
        setSelectedEditProposal((item: any) => ({
          ...item,
          price: resp?.data?.price,
          currency: resp?.data?.currency?.currencyId,
          explanation: resp?.data?.explanation,
        }))
      })
      .catch((e: any) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
        setLoading(false)
      })
  }

  const handleDeleteFreightProposal = (freightProposalId: number) => {
    advertServ
      .deleteFreigthProposal(freightProposalId)
      .then(() => {
        toast.success(intl.formatMessage({id: 'Admin.Adverts.createProposal.delete.success'}))
        setLoading(false)
        getProposalTableList(selectedFreightId, proposalPageMIndex)
        getAdvertList({freightStatusId: filter})
      })
      .catch((e: any) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
        setLoading(false)
      })
  }

  const handleUpdateProposal = (data: any) => {
    advertServ
      .updateFreightProposal(data)
      .then(() => {
        toast.success(intl.formatMessage({id: 'Admin.Adverts.updateProposal.success'}))
        setLoading(false)
        getProposalTableList(selectedFreightId, proposalPageMIndex)
      })
      .catch((e: any) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
        setLoading(false)
      })
  }

  const onProposalPaginationChange = (page: number) => {
    getProposalTableList(selectedFreightId, page)
    setProposalPageMIndex(page)
  }

  return (
    <>
      {loading && <Loading />}
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'Freight.myAdvert.title'})}</PageTitle>

      <div className='row mb-5 justify-content-end'>
        <div className='d-flex justify-content-end'>
          <ActionDropDown menuTitle={intl.formatMessage({id: 'freight.create.createFreight'})}>
            <div className='menu-item px-3'>
              <button
                onClick={() => history.push('/fr-op/create-quick-new-advert')}
                className='btn btn-sm btn-light-primary mb-2 border border-2 w-100'
              >
                {intl.formatMessage({id: 'freight.create.quick'})}
              </button>

              <button
                onClick={() => history.push('/fr-op/create-new-advert')}
                className='btn btn-sm btn-light-primary mb-2 border border-2 w-100'
              >
                {intl.formatMessage({id: 'freight.create.detailed'})}
              </button>
            </div>
          </ActionDropDown>
        </div>
      </div>

      <div
        className={`d-flex align-items-center ${
          showFilterPanel ? 'justify-content-between' : 'justify-content-end'
        }`}
      >
        {showFilterPanel && (
          <div className='mb-10 col-md-3 '>
            <h6 className={'mb-3'}>
              {intl.formatMessage({id: 'Freight.myAdvert.filterByStatus'})}
            </h6>
            <div className={''}>
              <Dropbox
                hideArrow
                customClass={'form-select'}
                placeholder={intl.formatMessage({id: 'Admin.Containers.statusFilterPlaceholder'})}
                customSearchValue={''}
                customOnSearch={(e: any) => {}}
                disabledSearch
                onScroll={() => {}}
                setScrollCalled={() => {}}
                currentPageIndex={0}
                menuList={
                  statusList &&
                  statusList.map((item: any) => {
                    return {
                      id: item?.freightStatusId,
                      title: item?.description,
                    }
                  })
                }
                selectedItemData={(item: any) => {
                  if (item?.id) {
                    setFilter(item?.id)
                  }
                }}
              />
            </div>
          </div>
        )}
        <div className={'d-flex mb-10 align-items-center justify-content-end'}>
          <span className='menu-icon d-flex justify-content-end'>
            <div
              className={`btn btn-icon btn-active-light-primary btn-custom ${
                showFilterPanel ? 'menu-button-active' : ''
              }`}
              data-kt-menu-attach='parent'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='bottom'
              onClick={() => setShowFilterPanel(!showFilterPanel)}
            >
              <KTSVG path='/media/filtering.svg' className='svg-icon-2x' />
            </div>
          </span>
        </div>
      </div>
      <div>
        {!advertList.length && (
          <div className='alert alert-warning mb-5 fw-bolder' role='alert'>
            {intl.formatMessage({id: 'Freight.myAdvert.noData'})}
          </div>
        )}
        <DataTableAdvanced
          columns={[
            {
              Header: 'ID',
              accessor: 'id',
            },
            {
              Header: '',
              accessor: 'freightIcon',
              isAction: true,
              Cell: ({row}: any) => (
                <>
                  {getFreightIcon[row?.values?.freightIcon] && (
                    <img
                      src={getFreightIcon[row?.values?.freightIcon]}
                      className='freightIconWrap'
                      alt='frieghtIcon'
                    />
                  )}
                </>
              ),
            },
            {
              Header: intl.formatMessage({id: 'Carrier.myAdvert.table.description'}),
              accessor: 'title',
            },
            {
              Header: intl.formatMessage({id: 'Carrier.myAdvert.table.targetCost'}),
              accessor: 'value',
              cellStyle: {
                textAlign: 'end',
              },
            },
            {
              Header: intl.formatMessage({id: 'Admin.Adverts.table_currency'}),
              accessor: 'currencyCode',
            },
            {
              Header: intl.formatMessage({id: 'Freight.myAdvert.from'}),
              accessor: 'fromLocation',
            },
            {
              Header: intl.formatMessage({id: 'Freight.myAdvert.to'}),
              accessor: 'toLocation',
            },
            {
              Header: intl.formatMessage({id: 'Freight.myAdvert.departureDate'}),
              accessor: 'departureDate',
            },
            {
              Header: intl.formatMessage({id: 'Freight.myAdvert.arrivalDate'}),
              accessor: 'arriveDate',
            },
            {
              Header: intl.formatMessage({id: 'Freight.myAdvert.status'}),
              accessor: 'status',
            },
            {
              Header: 'hiddenButtons',
              accessor: 'hiddenButtons',
              hidden: true,
            },
            ...(filter === '3'
              ? [
                  {
                    Header: intl.formatMessage({id: 'Freight.myAdvert.reasonRejection'}),
                    accessor: 'statusDetail',
                  },
                ]
              : []),
            {
              Header: intl.formatMessage({id: 'Admin.Adverts.table_proposal_count'}),
              accessor: 'proposalCount',
              isAction: true,
              Cell: ({row}: any) => (
                <div
                  className={
                    row?.values?.proposalCount !== '-' && row?.values?.proposalCount !== 0
                      ? 'proposal-wrap'
                      : ''
                  }
                  onClick={() => {
                    if (row?.values?.proposalCount !== '-' && row?.values?.proposalCount !== 0) {
                      setLoading(true)
                      const num = row?.values?.id?.split('.')[0]
                      getProposalTableList(parseInt(num), proposalPageMIndex)
                      setSelectedFreightId(parseInt(num))
                      setShowProposalTablePopup(true)
                    }
                  }}
                >
                  {row?.values?.proposalCount}
                </div>
              ),
            },
          ]}
          editableRows={['']}
          data={
            advertList &&
            advertList.map((item: any) => {
              return {
                id: item?.freightId + '.' + item?.freightStatus?.freightStatusId,
                title: item?.freightContentType?.description,
                value:
                  item?.targetCost &&
                  priceConversion(item?.targetCost, item?.targetCostCurrency?.currencyCode),
                currencyCode: item?.targetCostCurrency?.currencyCode,
                freightIcon: item?.freightType?.freightTypeId,
                fromLocation:
                  item?.fromAddress?.city?.name + ' / ' + item?.fromAddress?.country?.name,
                toLocation: item?.toAddress?.city?.name + ' / ' + item?.toAddress?.country?.name,
                departureDate:
                  item?.plannedDepartureDate !== null &&
                  getDateTime(item?.plannedDepartureDate, intl.locale),
                arriveDate:
                  item?.plannedArrivalDate !== null &&
                  getDateTime(item?.plannedArrivalDate, intl.locale),
                status: item?.freightStatus?.description,
                statusId: item?.freightStatus?.freightStatusId,
                hiddenButtons: {
                  showMapBtn: item?.freightStatus?.freightStatusId !== 8,
                  editBtn: item?.freightStatus?.freightStatusId !== 10,
                  sendToApproveBtn:
                    item?.freightStatus?.freightStatusId !== 10 &&
                    item?.freightStatus?.freightStatusId !== 3,
                  deleteRowSecondIcon:
                    item?.freightStatus?.freightStatusId !== 10 &&
                    item?.freightStatus?.freightStatusId !== 3,
                  proposalBtn: false,
                },
                ...(filter === '3' && {statusDetail: item?.statusDetail}),
                proposalCount: item?.proposalCount ?? '-',
              }
            })
          }
          updateMyData={() => {}}
          updateRow={() => {}}
          deleteRowSecondIcon={(row: any) => {
            let num = row?.values?.id?.split('.')[0]
            setSelectedFreightId(num)
            setShowDeletePopup(true)
          }}
          deleteRowSecondIconText={intl.formatMessage({id: 'Freight.myAdvert.tabel.deleteText'})}
          editBtn={(row: any) => {
            let num = row?.values?.id?.split('.')[0]
            setLoading(true)
            getAdvertDetailedList(parseInt(num))
            setSelectedFreightId(row.index)
            setTimeout(() => setShowUpdateScreen(true), 1000)
          }}
          proposalBtnText={intl.formatMessage({
            id: 'Carrier.freightPosting.table.action.showProposal',
          })}
          proposalBtn={(row: any) => {
            setLoading(true)
            const num = row?.values?.id?.split('.')[0]
            getProposalTableList(parseInt(num), proposalPageMIndex)
            setSelectedFreightId(parseInt(num))
            setShowProposalTablePopup(true)
          }}
          sendToApproveBtn={(row: any) => {
            let num = row?.values?.id?.split('.')[0]
            setSelectedFreightId(parseInt(num))
            setLoading(true)
            sendAdvert(parseInt(num))
          }}
          showMapBtn={(row: any) => {
            let num = row?.values?.id?.split('.')[0]
            setSelectedFreightId(parseInt(num))
            setLoading(true)
            getLocation(parseInt(num))
          }}
          showDetailBtn={(row: any) => {
            let num = row?.values?.id?.split('.')[0]
            setLoading(true)
            scrollLock()
            getAdvertDetailedList(parseInt(num))
            setSelectedFreightId(row.index)
            setShowDetailsScreen(true)
          }}
          hiddenButtons={{
            showMapBtn: filter !== '8',
            editBtn: filter !== '10',
            sendToApproveBtn: filter !== '10' && filter !== '3',
            deleteRowSecondIcon: filter !== '10' && filter !== '3',
          }}
          setPageMIndex={onPaginationChange}
          pageMIndex={pageMIndex}
        />
      </div>

      {showDeletePopup && (
        <Modal
          closeBtnSettings={{
            show: true,
            onClick: () => setShowDeletePopup(false),
          }}
          customAll={
            <div className={''}>
              <h3 className={'mt-5 mb-15'}>
                {intl.formatMessage({id: 'Freight.myAdvert.delete.confirmDelete'})}
              </h3>
              <div className={'row'}>
                <div className={'col-md-6'}>
                  <button
                    className={'btn btn-danger w-100'}
                    onClick={() => {
                      setShowDeletePopup(false)
                    }}
                  >
                    {intl.formatMessage({id: 'Freight.myAdvert.delete.cancel'})}
                  </button>
                </div>
                <div
                  className={'col-md-6'}
                  onClick={() => {
                    setLoading(true)
                    deleteAdvert(selectedFreightId)
                    setShowDeletePopup(false)
                    setLoading(true)
                    getAdvertList({freightStatusId: filter, pageMIndex})
                  }}
                >
                  <button className={'btn btn-success w-100'}>
                    {intl.formatMessage({id: 'Freight.myAdvert.delete.approve'})}
                  </button>
                </div>
              </div>
            </div>
          }
        />
      )}

      {showDetailsScreen && (
        <RightLayoutPopup
          title={intl.formatMessage({id: 'Freight.myAdvert.freightDetails'})}
          className='freight-advert-show-details'
          isCloseIcon
          closeOnClick={() => setShowDetailsScreen(false)}
        >
          <div className={''}>
            <div className={'detail-modal-scroll-container'}>
              {/*Araç Seçenekleri -1-*/}
              <div className={'mb-10 '}>
                <h4 className={'form-label fw-bolder text-dark fs-3'}>
                  {intl.formatMessage({id: 'Freight.myAdvert.vehicleOption'})}
                </h4>
                <hr />
                <div className={'row'}>
                  <div className={'col-md-6 mb-7'}>
                    <h6 className={'text-muted  '}>
                      {intl.formatMessage({id: 'Freight.myAdvert.descriptions'})}
                    </h6>
                    <b className={'fs-5'}>{advertDetailedList?.description}</b>
                  </div>
                  <div className={'col-md-6 mb-7'}>
                    <h6 className={'text-muted '}>
                      {intl.formatMessage({id: 'Freight.myAdvert.trailerType'})}
                    </h6>
                    <b className={'fs-5'}>{advertDetailedList?.trailerType?.description}</b>
                  </div>
                  <div className={'col-md-6 mb-7'}>
                    <h6 className={'text-muted '}>
                      {intl.formatMessage({id: 'Freight.myAdvert.floorType'})}
                    </h6>
                    <b className={'fs-5'}>{advertDetailedList?.floorType?.description}</b>
                  </div>
                  <div className={'col-md-6 mb-7'}>
                    <h6 className={'text-muted '}>
                      {intl.formatMessage({id: 'Freight.myAdvert.specificationList'})}
                    </h6>
                    {advertDetailedList?.specificationList?.map((item: any, i: number) => (
                      <p key={i} className={'fs-5 font-weight-bold text-bold'}>
                        {item?.description}
                      </p>
                    ))}
                  </div>

                  <div className={'col-md-6 mb-7'}>
                    <h6 className={'text-muted '}>
                      {intl.formatMessage({id: 'freight.details.content'})}
                    </h6>
                    <b className={'fs-5'}>{advertDetailedList?.freightContentType?.description}</b>
                  </div>
                </div>
              </div>

              {/*Tarih ve Zaman -2-*/}
              <div className={'mb-10'}>
                <h4 className={'form-label fw-bolder text-dark fs-3'}>
                  {intl.formatMessage({id: 'Freight.myAdvert.dateAndTime'})}
                </h4>
                <hr />
                <div className={'row m-auto'}>
                  <div className={'col-md-6 mb-7'}>
                    <h6 className={'text-muted  '}>
                      {intl.formatMessage({id: 'Freight.myAdvert.plannedDepartureDate'})}
                    </h6>
                    <b className={'fs-5'}>
                      {getDateTime(advertDetailedList?.plannedDepartureDate, intl.locale)}
                    </b>
                  </div>
                  {/*   <div className={'col-md-6 mb-7'}>
                                                <h6 className={'text-muted '}>Yükleme Saati</h6>
                                                <b className={'fs-5'}>{advertList[selectedFreightId].plannedArrivalDate}</b>
                                            </div>*/}
                  <div className={'col-md-6 mb-7'}>
                    <h6 className={'text-muted '}>
                      {intl.formatMessage({id: 'Freight.myAdvert.plannedArrivalDate'})}
                    </h6>
                    <b className={'fs-5'}>
                      {getDateTime(advertDetailedList?.plannedArrivalDate, intl.locale)}
                    </b>
                  </div>
                  {/* <div className={'col-md-6 mb-7'}>
                                                <h6 className={'text-muted '}>Varış Saati</h6>
                                                <b className={'fs-5'}>{formik.values.destTime}</b>
                                            </div>*/}
                </div>
              </div>

              <div className='mb-10'>
                <div className='row d-flex justify-content-between'>
                  <h4 className={'form-label col-md-6 fw-bolder text-dark fs-3'}>
                    {intl.formatMessage({id: 'Carrier.freight.details.consignor'})}
                  </h4>

                  <h4 className={'form-label col-md-6 fw-bolder text-dark fs-3'}>
                    {intl.formatMessage({id: 'Carrier.freight.details.consignee'})}
                  </h4>
                </div>
                <hr />

                <div className={'row m-auto'}>
                  <div className='col-md-6'>
                    <div className='row'>
                      <div className={'col-md-6 mb-7'}>
                        <h6 className={'text-muted'}>
                          {intl.formatMessage({id: 'Carrier.freight.details.name'})}
                        </h6>
                        <b className={'fs-5'}>{advertDetailedList?.consignor?.name}</b>
                      </div>

                      <div className={'col-md-6 mb-7'}>
                        <h6 className={'text-muted'}>
                          {intl.formatMessage({id: 'Carrier.freight.details.surName'})}
                        </h6>
                        <b className={'fs-5'}>{advertDetailedList?.consignor?.surname}</b>
                      </div>

                      <div className={'col-md-6 mb-7'}>
                        <h6 className={'text-muted '}>
                          {intl.formatMessage({id: 'Carrier.freight.details.countryCode'})}
                        </h6>
                        <b className={'fs-5'}>
                          {advertDetailedList?.consignor?.country?.phoneCode}
                        </b>
                      </div>

                      <div className={'col-md-6 mb-7'}>
                        <h6 className={'text-muted  '}>
                          {intl.formatMessage({id: 'Carrier.freight.details.phone'})}
                        </h6>
                        <b className={'fs-5'}>{advertDetailedList?.consignor?.phone}</b>
                      </div>

                      <div className={'col-md-12 mb-7'}>
                        <h6 className={'text-muted'}>
                          {intl.formatMessage({id: 'Carrier.freight.details.email'})}
                        </h6>
                        <b className={'fs-5'}>{advertDetailedList?.consignor?.email}</b>
                      </div>
                    </div>
                  </div>

                  <div className='col-md-6'>
                    <div className='row'>
                      <div className={'col-md-6 mb-7'}>
                        <h6 className={'text-muted'}>
                          {intl.formatMessage({id: 'Carrier.freight.details.name'})}
                        </h6>
                        <b className={'fs-5'}>{advertDetailedList?.consignee?.name}</b>
                      </div>

                      <div className={'col-md-6 mb-7'}>
                        <h6 className={'text-muted'}>
                          {intl.formatMessage({id: 'Carrier.freight.details.surName'})}
                        </h6>
                        <b className={'fs-5'}>{advertDetailedList?.consignee?.surname}</b>
                      </div>

                      <div className={'col-md-6 mb-7'}>
                        <h6 className={'text-muted'}>
                          {intl.formatMessage({id: 'Carrier.freight.details.countryCode'})}
                        </h6>
                        <b className={'fs-5'}>
                          {advertDetailedList?.consignee?.country?.phoneCode}
                        </b>
                      </div>

                      <div className={'col-md-6 mb-7'}>
                        <h6 className={'text-muted '}>
                          {intl.formatMessage({id: 'Carrier.freight.details.phone'})}
                        </h6>
                        <b className={'fs-5'}>{advertDetailedList?.consignee?.phone}</b>
                      </div>

                      <div className={'col-md-12 mb-7'}>
                        <h6 className={'text-muted'}>
                          {intl.formatMessage({id: 'Carrier.freight.details.email'})}
                        </h6>
                        <b className={'fs-5'}>{advertDetailedList?.consignee?.email}</b>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/*Adres Tercihleri -3-*/}
              <div className={'mb-10'}>
                <h4 className={'form-label fw-bolder text-dark fs-3  '}>
                  {intl.formatMessage({id: 'Freight.myAdvert.addressPreferences'})}
                </h4>
                <hr />
                <div className={'row m-auto'}>
                  <div className={'col-md-6 mb-7'}>
                    <h6 className={'text-muted  '}>
                      {intl.formatMessage({id: 'Freight.myAdvert.fromLocation'})}
                    </h6>
                    <b className={'fs-5'}>{advertDetailedList?.fromAddress?.addressText}</b>
                  </div>
                  <div className={'col-md-6 mb-7'}>
                    <h6 className={'text-muted '}>
                      {intl.formatMessage({id: 'Freight.myAdvert.toLocation'})}
                    </h6>
                    <b className={'fs-5'}>{advertDetailedList?.toAddress?.addressText}</b>
                  </div>
                </div>

                <div className={'row'} style={{margin: 0}}>
                  {advertDetailedList?.fromAddress && (
                    <div className={'col-md-6 '} style={{paddingLeft: 0}}>
                      <div className={'row border border-2 rounded py-4'} style={{margin: 0}}>
                        <div className={'col-md-4 mb-5 '}>
                          <p className={'mb-0 text-muted'}>
                            {intl.formatMessage({id: 'Freight.createAdvert.departure.explanation'})}
                          </p>
                          <b>{advertDetailedList?.fromAddress?.description}</b>
                        </div>
                        <div className={'col-md-4 mb-5 '}>
                          <p className={'mb-0 text-muted'}>
                            {intl.formatMessage({id: 'Freight.createAdvert.departure.country'})}
                          </p>
                          <b>{advertDetailedList?.fromAddress?.country?.name}</b>
                        </div>
                        <div className={'col-md-4 mb-5 '}>
                          <p className={'mb-0 text-muted'}>
                            {intl.formatMessage({id: 'Freight.createAdvert.departure.city'})}
                          </p>
                          <b>{advertDetailedList?.fromAddress?.city?.name}</b>
                        </div>
                        <div className={'col-md-4 mb-5 '}>
                          <p className={'mb-0 text-muted'}>
                            {intl.formatMessage({id: 'Freight.createAdvert.departure.town'})}
                          </p>
                          <b>{advertDetailedList?.fromAddress?.town}</b>
                        </div>
                        <div className={'col-md-4 mb-5 '}>
                          <p className={'mb-0 text-muted'}>
                            {intl.formatMessage({id: 'Freight.createAdvert.departure.district'})}
                          </p>
                          <b>{advertDetailedList?.fromAddress?.district}</b>
                        </div>
                        <div className={'col-md-4 mb-5 '}>
                          <p className={'mb-0 text-muted'}>
                            {intl.formatMessage({id: 'Freight.createAdvert.departure.postCode'})}
                          </p>
                          <b>{advertDetailedList?.fromAddress?.postCode}</b>
                        </div>
                      </div>
                    </div>
                  )}
                  {advertDetailedList?.toAddress && (
                    <div className={'col-md-6 '} style={{paddingRight: 0}}>
                      <div className={'row border border-2 rounded py-4'} style={{margin: 0}}>
                        <div className={'col-md-4 mb-5 '}>
                          <p className={'mb-0 text-muted'}>
                            {intl.formatMessage({id: 'Freight.createAdvert.arrival.explanation'})}
                          </p>
                          <b>{advertDetailedList?.toAddress?.description}</b>
                        </div>
                        <div className={'col-md-4 mb-5 '}>
                          <p className={'mb-0 text-muted'}>
                            {intl.formatMessage({id: 'Freight.createAdvert.arrival.country'})}
                          </p>
                          <b>{advertDetailedList?.toAddress?.country?.name}</b>
                        </div>
                        <div className={'col-md-4 mb-5 '}>
                          <p className={'mb-0 text-muted'}>
                            {intl.formatMessage({id: 'Freight.createAdvert.arrival.city'})}
                          </p>
                          <b>{advertDetailedList?.toAddress?.city?.name}</b>
                        </div>
                        <div className={'col-md-4 mb-5 '}>
                          <p className={'mb-0 text-muted'}>
                            {intl.formatMessage({id: 'Freight.createAdvert.arrival.district'})}
                          </p>
                          <b>{advertDetailedList?.toAddress?.town}</b>
                        </div>
                        <div className={'col-md-4 mb-5 '}>
                          <p className={'mb-0 text-muted'}>
                            {intl.formatMessage({id: 'Freight.createAdvert.arrival.dis'})}
                          </p>
                          <b>{advertDetailedList?.toAddress?.district}</b>
                        </div>
                        <div className={'col-md-4 mb-5 '}>
                          <p className={'mb-0 text-muted'}>
                            {intl.formatMessage({id: 'Freight.createAdvert.arrival.postCode'})}
                          </p>
                          <b>{advertDetailedList?.toAddress?.postCode}</b>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {/* mal Bilgileri -4*/}
              <div className={''}>
                <h4 className={'form-label fw-bolder text-dark fs-3'}>
                  {intl.formatMessage({id: 'Freight.myAdvert.goodsInfo'})}
                </h4>
                <hr />
                <div className={'row m-auto'}>
                  <div className={'col-md-4 mb-7'}>
                    <h6 className={'text-muted  '}>
                      {intl.formatMessage({id: 'Freight.myAdvert.values'})}
                    </h6>
                    <b className={'fs-5'}>{advertDetailedList?.value}</b>
                  </div>
                  <div className={'col-md-4 mb-7'}>
                    <h6 className={'text-muted '}>
                      {intl.formatMessage({id: 'Freight.myAdvert.currency'})}
                    </h6>
                    <b className={'fs-5'}>{advertDetailedList?.valueCurrency?.description}</b>
                  </div>
                  <div className={'col-md-4 mb-7'}>
                    <h6 className={'text-muted '}>
                      {intl.formatMessage({id: 'Freight.myAdvert.freightType'})}
                    </h6>
                    <b className={'fs-5'}>{advertDetailedList?.freightType?.description}</b>
                  </div>
                  <div className={'col-md-4 mb-7'}>
                    <h6 className={'text-muted '}>
                      {intl.formatMessage({id: 'Freight.myAdvert.packageType'})}
                    </h6>
                    <b className={'fs-5'}>{advertDetailedList?.freightPackageType?.description}</b>
                  </div>
                  <div className={'col-md-4 mb-7'}>
                    <h6 className={'text-muted '}>
                      {intl.formatMessage({id: 'Freight.myAdvert.loadingType'})}
                    </h6>
                    <b className={'fs-5'}>{advertDetailedList?.freightLoadingType?.description}</b>
                  </div>
                  <div className={'col-md-4 mb-7'}>
                    <h6 className={'text-muted '}>
                      {intl.formatMessage({id: 'Freight.myAdvert.gtip'})}
                    </h6>
                    <p className={'fs-5 mb-0'}>
                      <b>{advertDetailedList?.harmonizedSystemCode?.code}</b>
                    </p>
                    <b className={'fs-5'}>
                      {advertDetailedList?.harmonizedSystemCode?.description}
                    </b>
                  </div>
                  <div className={'col-md-4 mb-7'}>
                    <h6 className={'text-muted '}>
                      {intl.formatMessage({id: 'Freight.myAdvert.lengthValue'})}
                    </h6>
                    <b className={'fs-5'}>{advertDetailedList?.length}</b>
                  </div>
                  <div className={'col-md-4 mb-7'}>
                    <h6 className={'text-muted '}>
                      {intl.formatMessage({id: 'Freight.myAdvert.widthValue'})}
                    </h6>
                    <b className={'fs-5'}>{advertDetailedList?.width}</b>
                  </div>
                  <div className={'col-md-4 mb-7'}>
                    <h6 className={'text-muted '}>
                      {intl.formatMessage({id: 'Freight.myAdvert.heightValue'})}
                    </h6>
                    <b className={'fs-5'}>{advertDetailedList?.height}</b>
                  </div>
                  <div className={'col-md-4 mb-7'}>
                    <h6 className={'text-muted '}>
                      {intl.formatMessage({id: 'Freight.myAdvert.grossKg'})}
                    </h6>
                    <b className={'fs-5'}>{advertDetailedList?.weight}</b>
                  </div>
                  <div className={'col-md-4 mb-7'}>
                    <h6 className={'text-muted '}>
                      {intl.formatMessage({id: 'Freight.myAdvert.desi'})}
                    </h6>
                    <b className={'fs-5'}>{advertDetailedList?.desi}</b>
                  </div>
                  <div className={'col-md-4 mb-7'}>
                    <h6 className={'text-muted '}>
                      {intl.formatMessage({id: 'Freight.myAdvert.ldm'})}
                    </h6>
                    <b className={'fs-5'}>{advertDetailedList?.ldm}</b>
                  </div>

                  <div className={'col-md-4 mb-7'}>
                    <h6 className={'text-muted '}>
                      {intl.formatMessage({id: 'Freight.myAdvert.productContaining'})}
                    </h6>
                    <b className={'fs-5'}>
                      {advertDetailedList?.flammable === 'Y'
                        ? intl.formatMessage({id: 'Freight.myAdvert.yes'})
                        : intl.formatMessage({id: 'Freight.myAdvert.no'})}
                    </b>
                  </div>
                  <div className={'col-md-4 mb-7'}>
                    <h6 className={'text-muted '}>
                      {intl.formatMessage({id: 'Freight.myAdvert.stackable'})}
                    </h6>
                    <b className={'fs-5'}>
                      {advertDetailedList?.stackable === 'Y'
                        ? intl.formatMessage({id: 'Freight.myAdvert.yes'})
                        : intl.formatMessage({id: 'Freight.myAdvert.no'})}
                    </b>
                  </div>
                  {/* <div className={'col-md-4 mb-7'}>
                                            <h6 className={'text-muted '}>Hacim DM3 (Opsiyonel)</h6>
                                            <b className={'fs-5'}>{''}</b>
                                        </div>*/}
                </div>
                <hr />
              </div>
            </div>
            <div className='text-center mt-5 row view-details-freight-popup'>
              <div className='text-center mt-5 row advert-details-modal-button-close'>
                <div className={'col-md-6'}>
                  <button
                    onClick={() => {
                      setShowDetailsScreen(false)
                      scrollUnlock()
                    }}
                    type='button'
                    id='kt_sign_up_submit'
                    className='btn btn-lg btn-danger w-100 mb-5 '
                  >
                    {intl.formatMessage({id: 'Carrier.freightPosting.view.close'})}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </RightLayoutPopup>
      )}

      {showMapScreen && (
        <Modal
          closeBtnSettings={{
            show: true,
            onClick: () => setShowMapScreen(false),
          }}
          mainTitle={intl.formatMessage({id: 'Freight.myAdvert.whereMyLoad?'})}
          width={'75%'}
          height={'70%'}
          customAll={
            <div>
              {scriptLoaded && (
                <Map
                  height={'57vh'}
                  mapType={google.maps.MapTypeId.ROADMAP}
                  mapTypeControl={true}
                  setDistanceInKm={setDistanceInKm}
                  customLocation={{
                    lat: advertLocation?.latitude ? advertLocation?.latitude : '36.197900',
                    lot: advertLocation?.longitude ? advertLocation?.longitude : '36.159033',
                  }}
                  location={(item: any) => {
                    setSelectedMarkerAddress(item)
                  }}
                />
              )}
              {distanceInKm > -1 && renderDistanceSentence()}
            </div>
          }
        />
      )}

      {showUpdateScreen && (
        <EditPreferences
          currentData={advertDetailedList}
          editedData={(data: any) => {
            data && updateAdvert(data)
            data && setShowUpdateScreen(false)
          }}
          selectedTrailerType={(val: any) => {
            val && getTrailerSpecifications(val)
          }}
          setLoading={setLoading}
          trailerTypes={trailerTypes}
          trailerSpecifications={trailerSpecifications}
          floorTypes={floorTypes}
          countries={countries}
          cities={cities}
          currencyList={currencyList}
          freightTypes={freightTypes}
          transportTypes={transportTypes}
          freightPackageTypes={freightPackageTypes}
          freightLoadTypes={freightLoadTypes}
          gtipList={gtipList}
          onClose={() => setShowUpdateScreen(false)}
        />
      )}

      {showProposalTablePopup && (
        <ShowProposalTable
          currencyList={currencyList}
          getSelectedFreightProposal={(freightProposalId: any) =>
            handleGetSelectedFreightProposal(freightProposalId)
          }
          selectedProposalTableList={selectedProposalTableList}
          setLoading={setLoading}
          onProposalPaginationChange={onProposalPaginationChange}
          proposalPageMIndex={proposalPageMIndex}
          onClose={() => setShowProposalTablePopup(false)}
          deleteFreigthProposal={(freightProposalId: any) =>
            handleDeleteFreightProposal(freightProposalId)
          }
          selectedEditProposal={selectedEditProposal}
          setSelectedEditProposal={setSelectedEditProposal}
          handleUpdateProposal={handleUpdateProposal}
          selectedCountryName={selectedCountryName}
          setSelectedCountryName={setSelectedCountryName}
        />
      )}
    </>
  )
}
export {MyAdverts}
