import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {Carousel} from 'react-responsive-carousel'
import {Link} from 'react-router-dom'

import RightLayoutPopup from '../../../../Components/RightLayoutPopup'
import {apiUrl} from '../../../../../services/conf'

import 'react-responsive-carousel/lib/styles/carousel.min.css'

const TruckDetails = ({onClose, truckDetails, selectedListImageList}: any) => {
  const intl = useIntl()
  const [showImageInPopup, setShowImageInPopup] = useState<any>(false)

  const [showFileIndex, setShowFileIndex] = useState<any>(null)

  return (
    <RightLayoutPopup
      title={intl.formatMessage({id: 'freight.truck.title'})}
      className='right-layout-show-wrap'
      isCloseIcon
      closeOnClick={() => onClose()}
    >
      <div className='row'>
        <div className='col-md-3' />
        <div className='col-md-6'>
          <Carousel
            className='carousal-wrap'
            showThumbs={false}
            showArrows={true}
            showStatus={false}
            useKeyboardArrows
          >
            {selectedListImageList
              .sort((dataItem: any, nextItem: any) =>
                nextItem?.defaultImage?.localeCompare(dataItem?.defaultImage)
              )
              .map((item: any, index: any) => (
                <div
                  onClick={() => {
                    setShowFileIndex(index)
                    setShowImageInPopup(true)
                  }}
                  style={{cursor: 'pointer', display: 'flex', justifyContent: 'center'}}
                  className='image-hover-wrap'
                >
                  <img
                    src={item?.url}
                    alt={'truck-img'}
                    style={{
                      height: '100%',
                      minHeight: '275px',
                      maxHeight: '337px',
                      maxWidth: '337px',
                      objectFit: 'fill',
                    }}
                  />
                </div>
              ))}
          </Carousel>
        </div>
        <div className='col-md-3' />
      </div>

      <div className='row mt-10 mb-5'>
        <div className='col-md-3 mb-4'>
          <h6 className={'details-posting-wrap fw-bolder'}>
            {intl.formatMessage({id: 'freight.myListing.postingNumber'})}
          </h6>
          <b className={'fs-5 fw-bolder'}>{truckDetails?.listing?.listingId}</b>
        </div>

        <div className='col-md-3 mb-4'>
          <h6 className={'text-muted'}>{intl.formatMessage({id: 'freight.listing.brand'})}</h6>
          <b className={'fs-5'}>{truckDetails?.listing?.brand?.name}</b>
        </div>

        <div className='col-md-3 mb-4'>
          <h6 className={'text-muted'}>{intl.formatMessage({id: 'freight.listing.model'})}</h6>
          <b className={'fs-5'}>{truckDetails?.listing?.model}</b>
        </div>

        <div className='col-md-3 mb-4'>
          <h6 className={'text-muted'}>{intl.formatMessage({id: 'freight.listing.mileage'})}</h6>
          <b className={'fs-5'}>{truckDetails?.mileage}</b>
        </div>

        <div className='col-md-3 mb-4'>
          <h6 className={'text-muted'}>{intl.formatMessage({id: 'freight.listing.country'})}</h6>
          <b className={'fs-5'}>{truckDetails?.listing?.listingType?.country?.name}</b>
        </div>

        <div className='col-md-3 mb-4'>
          <h6 className={'text-muted'}>{intl.formatMessage({id: 'freight.listing.city'})}</h6>
          <b className={'fs-5'}>{truckDetails?.listing?.listingType?.city?.name}</b>
        </div>

        <div className='col-md-3 mb-4'>
          <h6 className={'text-muted'}>{intl.formatMessage({id: 'freight.listing.price'})}</h6>
          <b className={'fs-5'}>{truckDetails?.listing?.price}</b>
        </div>

        <div className='col-md-3 mb-4'>
          <h6 className={'text-muted'}>
            {intl.formatMessage({id: 'Admin.Adverts.table_currency'})}
          </h6>
          <b className={'fs-5'}>{truckDetails?.listing?.currency?.description}</b>
        </div>

        <div className='col-md-3 mb-4'>
          <h6 className={'text-muted'}>{intl.formatMessage({id: 'freight.listing.cabinType'})}</h6>
          <b className={'fs-5'}>{truckDetails?.cabinType?.description}</b>
        </div>

        <div className='col-md-3 mb-4'>
          <h6 className={'text-muted'}>{intl.formatMessage({id: 'freight.listing.axleType'})}</h6>
          <b className={'fs-5'}>{truckDetails?.axleType?.description}</b>
        </div>
      </div>

      <div className='accordion accordion-flush mb-10' id='accordionFlushExample'>
        <div className='accordion-item mb-4'>
          <h2 className='accordion-header accordian-main-wrap' id='flush-headingOne'>
            <button
              className='accordion-button collapsed accordian-btn-wrap'
              type='button'
              data-bs-toggle='collapse'
              data-bs-target='#flush-collapseOne'
              aria-expanded='false'
              aria-controls='flush-collapseOne'
            >
              {intl.formatMessage({id: 'freight.myListing.description'})}
            </button>
          </h2>
          <div
            id='flush-collapseOne'
            className='accordion-collapse collapse'
            aria-labelledby='flush-headingOne'
            data-bs-parent='#accordionFlushExample'
          >
            <div className='accordion-body'>
              {truckDetails?.listing?.description ? (
                <div className='row'>
                  <div className='col-md-12'>
                    <b className={'fs-5'}>{truckDetails?.listing?.description}</b>
                  </div>
                </div>
              ) : (
                <div className='alert alert-warning mb-5 fw-bolder' role='alert'>
                  {intl.formatMessage({id: 'global.noData'})}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className='accordion-item mb-4'>
          <h2 className='accordion-header accordian-main-wrap' id='flush-headingTwo'>
            <button
              className='accordion-button collapsed accordian-btn-wrap'
              type='button'
              data-bs-toggle='collapse'
              data-bs-target='#flush-collapseTwo'
              aria-expanded='false'
              aria-controls='flush-collapseTwo'
            >
              {intl.formatMessage({id: 'freight.myListing.contacts'})}
            </button>
          </h2>
          <div
            id='flush-collapseTwo'
            className='accordion-collapse collapse'
            aria-labelledby='flush-headingTwo'
            data-bs-parent='#accordionFlushExample'
          >
            <div className='accordion-body d-flex justify-content-center align-items-center'>
              <span>
                <b>{intl.formatMessage({id: 'public.truckDetails.please'})}</b>
              </span>
              <Link
                to='/auth/login'
                className='link-primary fs-6 fw-bolder'
                style={{
                  marginLeft: '5px',
                  marginRight: '5px',
                }}
              >
                {intl.formatMessage({id: 'login'})}
              </Link>
              <span>
                <b>{intl.formatMessage({id: 'public.truckDetails.contact'})}</b>
              </span>
            </div>
          </div>
        </div>

        <div className='accordion-item mb-4'>
          <h2 className='accordion-header accordian-main-wrap' id='flush-headingThree'>
            <button
              className='accordion-button collapsed accordian-btn-wrap'
              type='button'
              data-bs-toggle='collapse'
              data-bs-target='#flush-collapseThree'
              aria-expanded='false'
              aria-controls='flush-collapseThree'
            >
              {intl.formatMessage({id: 'freight.myListing.message'})}
            </button>
          </h2>
          <div
            id='flush-collapseThree'
            className='accordion-collapse collapse'
            aria-labelledby='flush-headingThree'
            data-bs-parent='#accordionFlushExample'
          >
            <div className='accordion-body d-flex justify-content-center align-items-center'>
              <span>
                <b>{intl.formatMessage({id: 'public.truckDetails.please'})}</b>
              </span>
              <Link
                to='/auth/login'
                className='link-primary fs-6 fw-bolder'
                style={{
                  marginLeft: '5px',
                  marginRight: '5px',
                }}
              >
                {intl.formatMessage({id: 'login'})}
              </Link>
              <span>
                <b>{intl.formatMessage({id: 'public.truckDetails.message'})}</b>
              </span>
            </div>
          </div>
        </div>
      </div>

      {showImageInPopup && (
        <div id='modal' className='modal-image'>
          <span
            id='modal-close'
            className='modal-close'
            onClick={() => {
              setShowImageInPopup(false)
              setShowFileIndex(null)
            }}
          >
            &times;
          </span>
          <Carousel
            className='modal-carousal-wrap'
            showThumbs={false}
            showArrows={true}
            showStatus={false}
            useKeyboardArrows
            selectedItem={showFileIndex || 0}
          >
            {selectedListImageList
              .sort((dataItem: any, nextItem: any) =>
                nextItem?.defaultImage?.localeCompare(dataItem?.defaultImage)
              )
              .map((item: any) => (
                <img
                  src={`${apiUrl}/listing-management/listings/files/name/${item?.fileName}?size=big`}
                  id='modal-content'
                  className='modal-content-image'
                  style={{maxHeight: 'calc(100vh - 170px)', objectFit: 'contain'}}
                  alt='modal-pop'
                />
              ))}
          </Carousel>
        </div>
      )}

      <div className='text-center mt-5 row view-details-freight-popup'>
        <div className={'col-md-6'}>
          <button
            onClick={() => {
              onClose()
            }}
            type='button'
            id='kt_sign_up_submit'
            className='btn btn-lg btn-danger w-100 mb-5 '
          >
            {intl.formatMessage({id: 'Carrier.freightPosting.view.close'})}
          </button>
        </div>
      </div>
    </RightLayoutPopup>
  )
}

export {TruckDetails}
