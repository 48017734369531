import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'

import {PageTitle} from '../../../../_metronic/layout/core'
import {KTSVG} from '../../../../_metronic/helpers'

import Loading from '../../../Components/Loading'
import Modal from '../../../Components/Modal'
import {AddressListsWidget} from '../../../Components/AddressListWidget/AddressListsWidget'
import AddNewAddressPopup from '../../../Components/AddNewAddressPopup'
import EditInvoiceAddresses from './Popups/EditInvoiceAddress'

import {InvoicesService} from '../../../../services/FreightOperatorServices/InvoicesService'
import {CommonService} from '../../../../services/FreightOperatorServices/CommonService'

import useScrollLock from '../../../../utils/useScrollLock'

import './InvoiceAddress.scss'

const InvoiceAddress = () => {
  const intl = useIntl()
  const invoiceServ = new InvoicesService()
  const commonServ = new CommonService()
  const [loading, setLoading] = useState<boolean>(false)
  /* const intl = useIntl()*/

  const [countries, setCountries] = useState<any[]>([])
  const [selectedCountry, setSelectedCountry] = useState<string>('')
  const [cities, setCities] = useState<any[]>([])

  /*List States*/
  const [addressList, setAddressList] = useState<any[]>([])

  /*Screens and modals*/
  const [selectedAddress, setSelectedAddress] = useState<any>(null)
  const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false)
  const [showCreatePopup, setShowCreatePopup] = useState<boolean>(false)
  const [showUpdatePopup, setShowUpdatePopup] = useState<boolean>(false)

  const {scrollUnlock} = useScrollLock()
  useEffect(() => {
    if (!showDeletePopup && !showCreatePopup && !showUpdatePopup) scrollUnlock()
  }, [showDeletePopup, showCreatePopup, showUpdatePopup])

  /*Popup States*/
  const [invoiceValues, setInvoiceValues] = useState<any>({
    invoiceAddressId: -1,
    description: '',
    country: '',
    city: '',
    district: '',
    neighborhood: '',
    street: '',
    streetNumber: '',
    town: '',
    postCode: '',
    addressText: '',
  })

  /**
   *! API CALL START
   **/
  const getCountryList = () => {
    commonServ
      .countryList()
      .then((resp: any) => {
        setCountries(resp.data)
        setLoading(false)
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const getCityList = (countryCode: string) => {
    commonServ
      .cityList(countryCode)
      .then((resp: any) => {
        setCities(resp.data)
        setLoading(false)
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const getAddressList = () => {
    invoiceServ
      .getAddresses()
      .then((resp: any) => {
        setAddressList(resp?.data)
        setLoading(false)
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const getAddress = (id: number) => {
    return invoiceServ.getAddress(id)
  }

  const deleteAddress = (id: number) => {
    invoiceServ
      .deleteAddress(id)
      .then((resp: any) => {
        toast.success(intl.formatMessage({id: 'Freight.invoiceAddress.address.delete.success'}))
        getAddressList()
        setShowDeletePopup(false)
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const setPrimary = (id: number) => {
    invoiceServ
      .setPrimary(id)
      .then((resp: any) => {
        toast.success(intl.formatMessage({id: 'Freight.invoiceAddress.address.default.success'}))
        setLoading(false)
        getAddressList()
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const updateAddress = ({
    invoiceAddressId,
    description,
    country,
    city,
    district,
    neighborhood,
    street,
    streetNumber,
    postCode,
  }: // addressText,
  any) => {
    invoiceServ
      .updateAddress(
        invoiceAddressId,
        description,
        country,
        city,
        district,
        neighborhood,
        street,
        streetNumber,
        postCode
      )
      .then((resp: any) => {
        toast.success(intl.formatMessage({id: 'Freight.invoiceAddress.address.update.success'}))
        getAddressList()
        setShowUpdatePopup(false)
      })
      .catch((e) => {
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const createAddress = ({
    title,
    country,
    city,
    district,
    neighborhood,
    street,
    streetNumber,
    postCode,
  }: any) => {
    invoiceServ
      .createAddress(title, country, city, district, neighborhood, street, streetNumber, postCode)
      .then((resp: any) => {
        toast.success(intl.formatMessage({id: 'Freight.invoiceAddress.address.create.success'}))
        getAddressList()
        setShowCreatePopup(false)
      })
      .catch((e) => {
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }
  /**
   *! API CALL END
   * **/

  useEffect(() => {
    setLoading(true)
    getAddressList()
    setLoading(true)
    getCountryList()
  }, [])

  useEffect(() => {
    if (selectedCountry) {
      setLoading(true)
      selectedCountry && getCityList(selectedCountry)
    }
  }, [selectedCountry])

  return (
    <>
      {loading && <Loading />}
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({id: 'Freight.invoiceAddress.title'})}
      </PageTitle>
      <div>
        <div className={'d-flex align-items-center justify-content-end'}>
          <button
            onClick={() => setShowCreatePopup(true)}
            className='btn btn-sm btn-light-primary mb-5 border border-2 fs-4'
          >
            <KTSVG path='media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
            {intl.formatMessage({id: 'Freight.invoiceAddress.addNewBAddress'})}
          </button>
        </div>
        <div className=''>
          <AddressListsWidget
            title={intl.formatMessage({id: 'Freight.invoiceAddress.addresses'})}
            desc={intl.formatMessage({id: 'Freight.invoiceAddress.addresses.description'})}
            data={
              addressList &&
              addressList?.map((item: any) => ({
                id: item.invoiceAddressId,
                color: item.isPrimary && item.isPrimary === 'Y' ? 'green' : 'red',
                isActive: item.isPrimary === 'Y',
                description: item?.description,
                country: item?.country?.name,
                city: item?.city?.name,
                district: item?.district,
                town: item?.town,
                postCode: item?.postCode,
                addressText: item?.addressText,
              }))
            }
            onEdit={(data: any) => {
              setLoading(true)
              setSelectedAddress(data)
              getAddress(data.id)
                .then((resp: any) => {
                  setLoading(false)
                  setInvoiceValues((item: any) => ({
                    ...item,
                    invoiceAddressId: resp?.data?.invoiceAddressId,
                    description: resp?.data?.description,
                    country: resp?.data?.country?.code,
                    countryName: resp?.data?.country?.name,
                    city: resp?.data?.city?.cityId,
                    cityName: resp?.data?.city?.name,
                    neighborhood: resp?.data?.neighborhood,
                    street: resp?.data?.street,
                    streetNumber: resp?.data?.streetNumber,
                    district: resp?.data?.district,
                    town: resp?.data?.town,
                    postCode: resp?.data?.postCode,
                    addressText: resp?.data?.addressText,
                  }))
                  setSelectedCountry(resp?.data?.country?.code)
                  setShowUpdatePopup(true)
                })
                .catch((e) => {
                  toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
                })
            }}
            /* onDelete={(data: any) => {
                             setSelectedAddress(data);
                             setShowDeletePopup(true);
                         }}*/
            setPrimary={(data: any) => {
              setSelectedAddress(data)
              setLoading(true)
              setPrimary(data.id)
            }}
          />
        </div>
      </div>

      {/*DELETE POPUP*/}
      {showDeletePopup && (
        <Modal
          closeBtnSettings={{
            show: true,
            onClick: () => setShowDeletePopup(false),
          }}
          customAll={
            <div className={''}>
              <h3 className={'mt-5 mb-15'}>
                {intl.formatMessage({id: 'Freight.addresses.delete.title'})}
              </h3>
              <div className={'row'}>
                <div className={'col-md-6'}>
                  <button
                    className={'btn btn-danger w-100'}
                    onClick={() => {
                      setShowDeletePopup(false)
                    }}
                  >
                    {intl.formatMessage({id: 'Freight.addresses.delete.cancel'})}
                  </button>
                </div>
                <div className={'col-md-6'}>
                  <button
                    className={'btn btn-success w-100'}
                    onClick={() => {
                      setLoading(true)
                      deleteAddress(selectedAddress.id)
                    }}
                  >
                    {intl.formatMessage({id: 'Freight.addresses.delete.approve'})}
                  </button>
                </div>
              </div>
            </div>
          }
        />
      )}

      {/*UPDATE POPUP*/}
      {showUpdatePopup && (
        <EditInvoiceAddresses
          setInvoiceValues={setInvoiceValues}
          invoiceValues={invoiceValues}
          countries={countries}
          setSelectedCountry={setSelectedCountry}
          cities={cities}
          updateAddress={updateAddress}
          setLoading={setLoading}
          onClose={() => setShowUpdatePopup(false)}
        />
      )}

      {showCreatePopup && (
        <AddNewAddressPopup
          type={'default'}
          billingAddress
          selectedCountry={(item: any) => setSelectedCountry(item)}
          countryList={countries && countries}
          cityList={cities && cities}
          onSubmit={(data: any) => {
            setLoading(true)
            createAddress(data)
          }}
          onClose={() => setShowCreatePopup(false)}
        />
      )}
    </>
  )
}
export {InvoiceAddress}
