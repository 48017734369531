import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {useHistory, useLocation} from 'react-router-dom'
import {shallowEqual, useSelector} from 'react-redux'

import {PageTitle} from '../../../../_metronic/layout/core'
import ActionDropDown from '../../../../_metronic/partials/content/dropdown/ActionDropdown'

import Loading from '../../../Components/Loading'
import DataTableAdvanced from '../../../Components/DataTableAdvanced'
import RightLayoutPopup from '../../../Components/RightLayoutPopup'
import Modal from '../../../Components/Modal'
import {loadMapApi} from '../../../Components/GoogleMap/utils/GoogleMapsUtils'
import Map from '../../../Components/GoogleMap/Map'
import Dropbox from '../../../Components/Dropbox'
import EditPreferences from './Popups/EditPreferences'
import PublishPartialFreightsList from './Popups/PublishPartialFreightsList'
import FreightPublishPopup from './Popups/FreightPublishPopup'

import {AdvertService} from '../../../../services/FreightOperatorServices/AdvertService'
import {AddressService} from '../../../../services/FreightOperatorServices/AddressService'
import {CommonService} from '../../../../services/FreightOperatorServices/CommonService'
import {AdvertService as AdminAdvertService} from '../../../../services/AdminServices/AdvertService'
import {TransporterGroupService} from '../../../../services/CarrierServices/TransporterGroupService'

import {RootState} from '../../../../setup'
import useScrollLock from '../../../../utils/useScrollLock'
import getDateTime, {priceConversion, getFreightIcon} from '../../../../utils/common'
import {getCurrentUrl} from '../../../../_metronic/helpers'
import {KTSVG} from '../../../../_metronic/helpers'

import './MyAdverts.scss'

const CarrierMyAdverts = () => {
  const intl = useIntl()
  const location = useLocation()
  const useQuery = () => new URLSearchParams(useLocation().search)
  const role: any = useSelector<RootState>(({auth}) => auth.role, shallowEqual)
  let query = useQuery()
  useEffect(() => {
    if (query?.get('filter'))
      switch (query?.get('filter')) {
        case 'waiting':
          return setFilter('1')
        case 'cancel':
          return setFilter('2')
        case 'reject':
          return setFilter('3')
        case 'approved':
          return setFilter('5')
        case 'ready-container':
          return setFilter('6')
        case 'ready-carrier':
          return setFilter('7')
        case 'onroad':
          return setFilter('8')
        case 'deliver':
          return setFilter('9')
        case 'draft':
          return setFilter('10')
      }
  }, [])

  /* const intl = useIntl()*/
  const [loading, setLoading] = useState<boolean>(false)
  const [isForwarder, setForwarder] = useState<boolean>(false)
  const [pageMIndex, setPageMIndex] = useState<number>(0)
  const [filterCounter, setFilterCounter] = useState<number>(0)
  const history = useHistory()

  /*Service States*/
  const advertServ = new AdvertService()
  const addressServ = new AddressService()
  const commonServ = new CommonService()
  const adminAdvertService = new AdminAdvertService()
  const transporterGroupServ = new TransporterGroupService()

  /*List States*/
  const [advertList, setAdvertList] = useState<any[]>([])
  const [advertDetailedList, setAdvertDetailedList] = useState<any>({})
  const [selectedFreightDetails, setSelectedFreightDetails] = useState<any>({})
  const [selectedFreightId, setSelectedFreightId] = useState<number>(-1)
  const [selectedPublishFreightList, setSelectedPublishFreightList] = useState<any>([])

  /*Screens and modals*/
  const [showDetailsScreen, setShowDetailsScreen] = useState<boolean>(false)
  const [showMapScreen, setShowMapScreen] = useState<boolean>(false)
  const [showUpdateScreen, setShowUpdateScreen] = useState<boolean>(false)
  const [showUpdateSuccess, setShowUpdateSuccess] = useState<boolean>(false)
  const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false)
  const [showFilterPanel, setShowFilterPanel] = useState<boolean>(false)
  const [showPartialFreightList, setShowPartialFreightList] = useState<boolean>(false)
  const [isNextPage, setIsNextPage] = useState<boolean>(false)
  const [dependentFromCity, setDependentFromCity] = useState<boolean>(false)
  const [dependentToCity, setDependentToCity] = useState<boolean>(false)
  const [showPublishFreight, setShowPublishFreight] = useState<boolean>(false)

  const [selectedPublishFreightTransporterGroup, setSelectedPublishFreightTransporterGroup] =
    useState<any>([])
  const [publishDate, setPublishDate] = useState<any>(null)
  const [publishNonFormatDate, setPublishNonFormatDate] = useState<any>('')

  const {scrollLock, scrollUnlock} = useScrollLock()
  useEffect(() => {
    if (
      !showDetailsScreen &&
      !showMapScreen &&
      !showUpdateScreen &&
      !showDeletePopup &&
      !showUpdateSuccess
    )
      scrollUnlock()
  }, [showMapScreen, showDetailsScreen, showUpdateScreen, showDeletePopup, showUpdateSuccess])

  /*Trailer States Start*/
  const [trailerTypes, setTrailerTypes] = useState([])
  const [floorTypes, setFloorTypes] = useState([])
  const [trailerSpecifications, setTrailerSpecifications] = useState([])
  /*Trailer States End*/

  /*Transport States Start*/
  const [transportTypes, setTransportTypes] = useState([])
  /*Transport States End*/

  /*Freight States Start*/
  const [freightTypes, setFreightTypes] = useState<any>([])
  const [freightLoadTypes, setFreightLoadTypes] = useState<any>([])
  const [freightPackageTypes, setFreightPackageTypes] = useState<any>([])
  /*Freight States End*/

  /*Other Lists Start*/
  const [addressList, setAdressList] = useState<any>([])
  const [currencyList, setCurrencyList] = useState<any>([])
  const [gtipList, setGtipList] = useState<any>([])
  const [countries, setCountries] = useState<any>([])
  const [cities, setCities] = useState<any>([])
  const [advertLocation, setAdvertLocation] = useState<any>()
  /*Other Lists End*/

  /*Filter Select States*/
  const [filter, setFilter] = useState<string>('all')
  const [statusList, setStatusList] = useState<any>([])

  const [scriptLoaded, setScriptLoaded] = useState(false)
  const [distanceInKm, setDistanceInKm] = useState<number>(-1)
  const [selectedMarkerAddress, setSelectedMarkerAddress] = useState<any>()

  const [description, setDescription] = useState<any>('')
  const [publicFreightExchange, setPublicFreightExchange] = useState<boolean>(false)
  const [privateFreightExchange, setPrivateFreightExchange] = useState<boolean>(false)
  const [selectedTransporterGroup, setSelectedTransporterGroup] = useState<any>([])
  const [targetCost, setTargetCost] = useState<any>('')
  const [selectedCurrency, setSelectedCurrency] = useState<any>('')
  const [selectedCurrencyName, setSelectedCurrencyName] = useState<any>('')
  const [issueDate, setIssueDate] = useState<any>(null)
  const [loadIssueDate, setLoadIssueDate] = useState<any>('')
  const [plannedDepartureDate, setPlannedDepartureDate] = useState<any>(null)
  const [loadPannedDepartureDate, setLoadPlannedDepartureDate] = useState<any>('')
  const [selectedPartialPublicFreightList, setSelectedPartialPublicFreightList] = useState<any>([])

  const [freightContentTypeSearch, setFreightContentTypeSearch] = useState<string>('')
  const [freightContentTypePageIndex, setFreightContentTypePageIndex] = useState<number>(0)
  const [freightContentTypeId, setFreightContentTypeId] = useState<string>('all')
  const [freightContentTypeName, setFreightContentTypeName] = useState<string>('')

  const [selectedFreightType, setSelectedFreightType] = useState<any>('')

  const [transportTypeSearch, setTransportTypeSearch] = useState<string>('')
  const [transportTypePageIndex, setTransportTypePageIndex] = useState<number>(0)
  const [transportTypeId, setTransportTypeId] = useState<string>('all')
  const [transportTypeName, setTransportTypeName] = useState<string>('')

  const [allTransportTypes, setAllTransportTypes] = useState<any>([])
  const [allFreightTypes, setAllFreightTypes] = useState<any>([])
  const [allFreightContentTypes, setAllFreightContentTypes] = useState<any>([])
  const [transporterGroupUserList, setTransporterGroupUserList] = useState<any>([])

  const [fromCityList, setFromCityList] = useState<any>([])
  const [toCityList, setToCityList] = useState<any>([])
  const [fromCountryList, setFromCountryList] = useState<any>([])
  const [toCountryList, setToCountryList] = useState<any>([])

  const [fromCountrySearch, setFromCountrySearch] = useState<string>('')
  const [fromCountryId, setFromCountryId] = useState<string>('')
  const [fromCountryName, setFromCountryName] = useState<string>('')

  const [fromCitySearch, setFromCitySearch] = useState<string>('')
  const [fromCityId, setFromCityId] = useState<string>('all')
  const [fromCityName, setFromCityName] = useState<string>('')

  const [toCountrySearch, setToCountrySearch] = useState<string>('')
  const [toCountryId, setToCountryId] = useState<string>('')
  const [toCountryName, setToCountryName] = useState<string>('')

  const [toCitySearch, setToCitySearch] = useState<string>('')
  const [toCityId, setToCityId] = useState<string>('all')
  const [toCityName, setToCityName] = useState<string>('')

  useEffect(() => {
    if ((filter && filterCounter > 0) || query?.get('filter') === null) {
      setLoading(true)
      getAdvertList({freightStatusId: filter})
    }
  }, [filter])

  const renderDistanceSentence = () => {
    return (
      <div className='distance-info'>
        {intl.formatMessage({id: 'Admin.trackOnMap.renderDistance'}, {value: distanceInKm})}
      </div>
    )
  }

  /*----------------------------*/
  /** Formik Preferences Start**/
  const initialValues = {
    title: '',
    trailerType: '',
    trailerSpecification: [],
    floorType: '',

    loadDate: '',
    loadNonFormatDate: new Date().getTime(),
    loadTime: '',

    destDate: '',
    destNonFormatDate: new Date().getTime(),
    destTime: '',

    loadAddress: '',
    destinationAddress: '',
    freightType: '',
    freightPackageType: '',
    freightLoadType: '',
    /* volumeDm3: '',*/
    weight: '',
    value: '',
    currency: '',
    flammable: false,
    stackable: false,

    width: '',
    height: '',
    freightLenght: '',
    desi: '',
    ldm: '',
    harmonizedSystemCode: '',
  }
  const loginSchema = Yup.object().shape({
    width: Yup.string().notRequired(),
    height: Yup.string().notRequired(),
    freightLenght: Yup.string().notRequired(),
    desi: Yup.string().notRequired(),
    ldm: Yup.string().notRequired(),

    title: Yup.string().notRequired(),
    trailerType: Yup.string().notRequired(),
    floorType: Yup.string().notRequired(),
    trailerSpecification: Yup.array().notRequired(),

    loadDate: Yup.string().required(intl.formatMessage({id: 'global.thisFieldRequired'})),
    loadTime: Yup.string().required(intl.formatMessage({id: 'global.thisFieldRequired'})),
    destDate: Yup.string().required(intl.formatMessage({id: 'global.thisFieldRequired'})),
    destTime: Yup.string().required(intl.formatMessage({id: 'global.thisFieldRequired'})),

    loadAddress: Yup.string().required(intl.formatMessage({id: 'global.thisFieldRequired'})),
    destinationAdress: Yup.string().required(intl.formatMessage({id: 'global.thisFieldRequired'})),
    freightType: Yup.string().required(intl.formatMessage({id: 'global.thisFieldRequired'})),
    freightPackageType: Yup.string().notRequired(),
    freightLoadType: Yup.string().notRequired(),
    /* volumeDm3: Yup.string(),*/
    weight: Yup.string().notRequired(),
    value: Yup.string().notRequired(),
    currency: Yup.string().notRequired(),
    harmonizedSystemCode: Yup.string().required(intl.formatMessage({id: 'global.gtipRequired'})),
    flammable: Yup.boolean(),
    stackable: Yup.boolean(),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setShowUpdateScreen(false)
    },
  })
  /** Formik Preferences End**/

  const fillUpdateScreenInputs = (dat: any) => {
    formik.setFieldValue('title', dat?.description)
    formik.setFieldValue('value', dat?.value)
    formik.setFieldValue('width', dat?.width)
    formik.setFieldValue('height', dat?.height)
    formik.setFieldValue('freightLenght', dat?.freightLenght)
    formik.setFieldValue('desi', dat?.desi)
    formik.setFieldValue('ldm', dat?.ldm)
    formik.setFieldValue('flammable', dat?.flammable === 'Y')
    formik.setFieldValue('stackable', dat?.stackable === 'Y')
    formik.setFieldValue('currency', dat?.currency)
    formik.setFieldValue('plannedDepartureDate', dat?.plannedDepartureDate)
    formik.setFieldValue('plannedArrivalDate', dat?.plannedArrivalDate)
    formik.setFieldValue('loadAddress', dat?.fromLocation?.locationId)
    formik.setFieldValue('destinationAddress', dat?.toLocation?.locationId)
    formik.setFieldValue('trailerType', dat?.trailerType?.trailerTypeId)
    formik.setFieldValue('floorType', dat?.floorType?.floorTypeId)
    formik.setFieldValue('freightType', dat?.freightType?.freightTypeId)
    formik.setFieldValue('freightLoadingType', dat?.freightLoadingType?.freightLoadingTypeId)
    formik.setFieldValue('freightPackageType', dat?.freightPackageType?.freightPackageTypeId)
    formik.setFieldValue('harmonizedSystemCode', dat?.harmonizedSystemCode?.code)
    setCurrSpecDat(dat.specificationList)
  }

  /** Advert Api call Start **/
  const getAdvertList = (data?: any) => {
    advertServ
      .getCarrierAdverts({
        ...(data?.freightStatusId && {freightStatusId: data?.freightStatusId}),
        ...{pageIndex: data?.pageMIndex ?? pageMIndex},
        ...(data?.willBeExcludedFreightIdList && {
          willBeExcludedFreightIdList: data?.willBeExcludedFreightIdList.toString(),
          freightTypeId: data?.freightTypeId,
          transportTypeId: data?.transportTypeId,
        }),
      })
      .then((resp) => {
        setIsNextPage(false)
        setLoading(false)
        setAdvertList(resp?.data)
      })
      .catch((e: any) => {
        setIsNextPage(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
        setLoading(false)
      })
  }

  const getAdvertDetailedList = (freightId: number) => {
    advertServ
      .getCarrierAdvertWithId(freightId)
      .then((resp) => {
        setAdvertDetailedList(resp?.data)
        fillUpdateScreenInputs(resp.data)
        setLoading(false)
      })
      .catch((e: any) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
        setLoading(false)
      })
  }

  const resetField = () => {
    setSelectedFreightDetails({})
    setSelectedFreightType('')
    setDescription('')
    setTargetCost('')
    setTransportTypeName('')
    setTransportTypeId('')
    setFreightContentTypeName('')
    setFreightContentTypeId('')
    setSelectedCurrencyName('')
    setSelectedCurrency('')
    setFromCountryName('')
    setFromCountryId('')
    setFromCityName('')
    setFromCityId('')
    setToCountryName('')
    setToCountryId('')
    setToCityName('')
    setToCityId('')
    setPublicFreightExchange(false)
    setPrivateFreightExchange(false)
    setSelectedTransporterGroup([])
    setSelectedPublishFreightList([])
    setSelectedPublishFreightTransporterGroup([])
    setPublishNonFormatDate('')
    setPublishDate(null)
    setIssueDate(null)
    setLoadIssueDate('')
    setPlannedDepartureDate(null)
    setLoadPlannedDepartureDate('')
  }

  const handlePublishFreight = (data: any) => {
    advertServ
      .createAndPublishFromFullTruckFreight(data)
      .then(() => {
        toast.success(intl.formatMessage({id: 'Carrier.freight.publishFreight.publish.success'}))
        resetField()
        setShowPublishFreight(false)
        setLoading(false)
        getAdvertList({freightStatusId: filter, pageMIndex})
      })
      .catch((e: any) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
        setLoading(false)
        setShowPublishFreight(false)
      })
  }

  const createAndPublishPartialContainer = (data: any) => {
    advertServ
      .createAndPublishPartialContainer(data)
      .then(() => {
        toast.success(
          intl.formatMessage({id: 'Carrier.advert.publishToPrivateFreight.create.success'})
        )
        resetField()
        setShowPartialFreightList(false)
        setLoading(false)
        getAdvertList({freightStatusId: filter, pageMIndex})
      })
      .catch((e: any) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
        setLoading(false)
        setShowPartialFreightList(false)
      })
  }

  const getSelectedFreightDetailed = (freightId: number) => {
    advertServ
      .getCarrierAdvertWithId(freightId)
      .then((resp) => {
        setSelectedFreightDetails(resp?.data)
        setSelectedFreightType(resp?.data?.freightType?.freightTypeId)
        setDescription(resp?.data?.description)
        setTargetCost(resp?.data?.targetCost)
        setTransportTypeName(resp?.data?.transportType?.description)
        setTransportTypeId(resp?.data?.transportType?.transportTypeId)
        setFreightContentTypeName(resp?.data?.freightContentType?.description)
        setFreightContentTypeId(resp?.data?.freightContentType?.freightContentTypeId)
        setSelectedCurrencyName(resp?.data?.targetCostCurrency?.description)
        setSelectedCurrency(resp?.data?.targetCostCurrency?.currencyCode)
        setFromCountryName(resp?.data?.fromAddress?.country?.name)
        setFromCountryId(resp?.data?.fromAddress?.country?.code)
        setFromCityName(resp?.data?.fromAddress?.city?.name)
        setFromCityId(resp?.data?.fromAddress?.city?.cityId)
        setToCountryName(resp?.data?.toAddress?.country?.name)
        setToCountryId(resp?.data?.toAddress?.country?.code)
        setToCityName(resp?.data?.toAddress?.city?.name)
        setToCityId(resp?.data?.toAddress?.city?.cityId)
        setLoading(false)
      })
      .catch((e: any) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
        setLoading(false)
      })
  }

  const deleteAdvert = (advertId: number) => {
    advertServ
      .deleteCarrierAdvert(advertId)
      .then((resp) => {
        setLoading(false)
        toast.success(intl.formatMessage({id: 'Admin.Adverts.deleteMessage'}), {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
      .catch((e: any) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
        setLoading(false)
      })
  }

  const sendAdvert = (advertId: number) => {
    advertServ
      .sendCarrierAdvertToApproval(advertId)
      .then((resp) => {
        getAdvertList({freightStatusId: filter, pageMIndex})
        toast.success(intl.formatMessage({id: 'Freight.advert.load.success'}), {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
      .catch((e: any) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
        setLoading(false)
      })
  }

  const updateAdvert = (data: any) => {
    const fromAddressData = {
      latitude: data.fromLatitude,
      longitude: data.fromLongitude,
      neighborhood: data.fromNeighborhood,
      postCode: data.fromPostCode,
      street: data.fromStreet,
      streetNumber: data.fromStreetNumber,
      city: {
        cityId: parseInt(data.fromCity),
      },
      country: {
        code: data.fromCountry,
      },
    }

    const toAddressData = {
      latitude: data.toLatitude,
      longitude: data.toLongitude,
      neighborhood: data.toNeighborhood,
      postCode: data.toPostCode,
      street: data.toStreet,
      streetNumber: data.toStreetNumber,
      city: {
        cityId: parseInt(data.toCity),
      },
      country: {
        code: data.toCountry,
      },
    }

    let consignor = null
    let consignee = null

    if (
      data.consignorName ||
      data.consignorSurName ||
      data.consignorPhone ||
      data.consignorEmail ||
      data.consignorTaxNumber ||
      data.consignorTaxOffice ||
      data.consignorReferenceClientId
    ) {
      consignor = {
        name: data?.consignorName || null,
        surname: data?.consignorSurName || null,
        countryCode: data?.consignorPhone ? data.consignorCountryCode : null,
        phone: data?.consignorPhone || null,
        email: data?.consignorEmail || null,
        taxNumber: data?.consignorTaxNumber || null,
        taxOffice: data?.consignorTaxOffice || null,
        referenceClientId: data?.consignorReferenceClientId || null,
      }
    }

    if (
      data.consigneeName ||
      data.consigneeSurName ||
      data.consigneePhone ||
      data.consigneeEmail ||
      data.consigneeTaxNumber ||
      data.consigneeTaxOffice ||
      data.consigneeReferenceClientId
    ) {
      consignee = {
        name: data?.consigneeName || null,
        surname: data?.consigneeSurName || null,
        countryCode: data.consigneePhone ? data.consigneeCountryCode : null,
        phone: data?.consigneePhone || null,
        email: data?.consigneeEmail || null,
        taxNumber: data?.consigneeTaxNumber || null,
        taxOffice: data?.consigneeTaxOffice || null,
        referenceClientId: data?.consigneeReferenceClientId || null,
      }
    }

    advertServ
      .updateCarrierAdvert(
        data.freightId,
        data.title,
        data.value,
        data.weight,
        data.width,
        data.height,
        data.freightLength,
        data.desi,
        data.ldm,
        data.flammable,
        data.stackable,
        parseFloat(data.currency),
        new Date(data.loadDate + ' ' + data.loadTime.replace(/(AM|PM)/, '')).getTime().toString(),
        new Date(data.destDate + ' ' + data.destTime.replace(/(AM|PM)/, '')).getTime().toString(),
        parseInt(data.loadAddress),
        parseInt(data.destinationAddress),
        parseInt(data.trailerType),
        parseInt(data.floorType),
        data.specificationList,
        parseInt(data.transportType),
        parseInt(data.freightType),
        parseInt(data.freightLoadType),
        parseInt(data.freightPackageType),
        data.harmonizedSystemCode,
        parseInt(data.freightContentTypeId),
        fromAddressData,
        toAddressData,
        consignor,
        consignee
      )
      .then(() => {
        setLoading(false)
        getAdvertList({freightStatusId: filter, pageMIndex})
        setShowUpdateSuccess(true)
        toast.success(intl.formatMessage({id: 'Admin.Adverts.updateMessage'}), {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
      .catch((e: any) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }
  /** Advert Api call End **/

  /** Trailer api call Start **/
  const getTrailerTypes = () => {
    advertServ
      .carrierTrailerTypes()
      .then((resp) => {
        setTrailerTypes(resp?.data)
      })
      .catch((e: any) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
        setLoading(false)
      })
  }

  const getFloorTypes = () => {
    advertServ
      .carrierFloorTypes()
      .then((resp) => {
        setFloorTypes(resp?.data)
      })
      .catch((e: any) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
        setLoading(false)
      })
  }
  const getTrailerSpecifications = (trailerId: number) => {
    return advertServ.carrierTrailerSpecifications(trailerId).then((resp) => {
      setTrailerSpecifications(resp?.data)
      setLoading(false)
    })
  }
  /** Trailer api call End **/

  /** Transport api call Start **/
  const getTransportTypes = () => {
    advertServ
      .carrierTransportTypesList()
      .then((resp) => {
        setTransportTypes(resp?.data)
      })
      .catch((e: any) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
        setLoading(false)
      })
  }

  const getAllTransportTypes = () => {
    advertServ
      .carrierAllTransportTypesList()
      .then((resp) => {
        setAllTransportTypes(resp?.data)
      })
      .catch((e: any) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
        setLoading(false)
      })
  }
  /** Transport api call End **/

  /** Freights api call Start **/
  const getFreightTypes = () => {
    advertServ
      .carrierFreightTypes()
      .then((resp) => {
        setFreightTypes(resp?.data)
      })
      .catch((e: any) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
        setLoading(false)
      })
  }

  const getAllFreightTypes = () => {
    advertServ
      .carrierFreightTypes()
      .then((resp) => {
        setAllFreightTypes(resp?.data)
      })
      .catch((e: any) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
        setLoading(false)
      })
  }

  const getAllFreightContentTypeList = (pageIndex: number = 0, searchText?: any) => {
    commonServ
      .allFreightContentTypeList(searchText ?? '', pageIndex ?? 0)
      .then((resp: any) => {
        setAllFreightContentTypes(resp?.data)
        setLoading(false)
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const hadnleGetTransporterGroupList = () => {
    transporterGroupServ
      .getTransporterGroupListCurrentUser()
      .then((res: any) => {
        setTransporterGroupUserList(
          res?.data.map((item: any) => ({id: item.transporterGroupId, name: item.description}))
        )
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
        setLoading(false)
      })
  }

  const getFromCountryList = (pageIndex: number = 0, searchText?: string) => {
    commonServ
      .countryAllSearchList(searchText ?? '', pageIndex ?? 0)
      .then((resp: any) => {
        if (pageIndex === 0)
          setFromCountryList(
            [
              {
                code: 'all',
                name: intl.formatMessage({id: 'Admin.Adverts.all'}),
              },
            ].concat(resp?.data?.filter((item: any) => item?.name))
          )
        else setFromCountryList((item: any) => [...item, ...resp?.data])
        setLoading(false)
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const getFromSearchCityList = (
    countryCode: string,
    pageIndex: number = 0,
    searchText?: string
  ) => {
    commonServ
      .cityAllSearchList(countryCode, searchText ?? '', pageIndex ?? 0)
      .then((resp: any) => {
        if (pageIndex === 0)
          setFromCityList(
            [
              {
                cityId: 'all',
                name: intl.formatMessage({id: 'Admin.Adverts.all'}),
              },
            ].concat(resp?.data?.filter((item: any) => item?.name))
          )
        else setFromCityList((item: any) => [...item, ...resp?.data])
        setLoading(false)
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const getToCountryList = (pageIndex: number = 0, searchText?: string) => {
    commonServ
      .countryAllSearchList(searchText ?? '', pageIndex ?? 0)
      .then((resp: any) => {
        if (pageIndex === 0)
          setToCountryList(
            [
              {
                code: 'all',
                name: intl.formatMessage({id: 'Admin.Adverts.all'}),
              },
            ].concat(resp?.data?.filter((item: any) => item?.name))
          )
        else setToCountryList((item: any) => [...item, ...resp?.data])
        setLoading(false)
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const getToSearchCityList = (countryCode: string, pageIndex: number = 0, searchText?: any) => {
    commonServ
      .cityAllSearchList(countryCode, searchText ?? '', pageIndex ?? 0)
      .then((resp: any) => {
        if (pageIndex === 0)
          setToCityList(
            [
              {
                cityId: 'all',
                name: intl.formatMessage({id: 'Admin.Adverts.all'}),
              },
            ].concat(resp?.data?.filter((item: any) => item?.name))
          )
        else setToCityList((item: any) => [...item, ...resp?.data])
        setLoading(false)
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  useEffect(() => {
    fromCountryId && getFromSearchCityList(fromCountryId, 0)
  }, [fromCountryId])

  useEffect(() => {
    toCountryId && getToSearchCityList(toCountryId, 0)
  }, [toCountryId])

  const getFreightLoadTypes = () => {
    advertServ
      .carrierFreightLoadingTypes()
      .then((resp) => {
        setFreightLoadTypes(resp?.data)
      })
      .catch((e: any) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
        setLoading(false)
      })
  }
  const getFreightPackageTypes = () => {
    advertServ
      .carrierFreightPackageTypes()
      .then((resp) => {
        setFreightPackageTypes(resp?.data)
      })
      .catch((e: any) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
        setLoading(false)
      })
  }
  /** Freights api call End **/

  /** Address api call Start **/
  const getAddressList = () => {
    addressServ
      .getCarrierAdressList()
      .then((resp) => {
        setAdressList(resp?.data)
      })
      .catch((e: any) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
        setLoading(false)
      })
  }
  /** Address api call End **/

  /** Currencies api call Start **/
  const getCurrencyList = () => {
    advertServ
      .carrierCurrencyList()
      .then((resp) => {
        setCurrencyList(resp?.data)
      })
      .catch((e: any) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
        setLoading(false)
      })
  }
  /** Currencies api call End **/

  /** Gtip api call Start **/
  const getGtip = (searchText?: string, pageCount: number = 20) => {
    advertServ
      .carrierGtipList(searchText ?? '', pageCount)
      .then((resp) => {
        setGtipList(resp?.data)
      })
      .catch((e: any) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
        setLoading(false)
      })
  }
  /** Gtip api call End **/

  /** Country and City List api call Start **/
  const getCountryList = () => {
    commonServ
      .carrierCountryList()
      .then((resp) => {
        setCountries(resp.data)
        setLoading(false)
      })
      .catch((e: any) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
        setLoading(false)
      })
  }

  /** Country and City List api call End **/

  /** Address Types api call Start **/
  const getLocation = (advertId: number) => {
    advertServ
      .carrierGetAdvertLocation(advertId)
      .then((resp) => {
        setAdvertLocation(resp?.data)
        setLoading(false)
        setShowMapScreen(true)
      })
      .catch((e: any) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
        setLoading(false)
      })
  }
  /** Address Types api call End **/

  const getCityList = (countryCode: string) => {
    commonServ
      .carrierCityList(countryCode)
      .then((resp) => {
        setCities(resp.data)
        setLoading(false)
      })
      .catch((e: any) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
        setLoading(false)
      })
  }

  const getStatusTypes = () => {
    adminAdvertService
      .getCarrierStatusTypes()
      .then((resp: any) => {
        setStatusList(
          [
            {
              freightStatusId: 'all',
              description: intl.formatMessage({id: 'Admin.Adverts.all'}),
            },
          ].concat(resp?.data)
        )
        setLoading(false)
      })
      .catch((e: any) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
        setLoading(false)
      })
  }

  const getApproved = (freightId: number) => {
    adminAdvertService
      .approveAdvert(freightId)
      .then(() => {
        toast.success(intl.formatMessage({id: 'Admin.Adverts.approveMessage'}))
        setLoading(false)
        getAdvertList({freightStatusId: filter, pageMIndex})
      })
      .catch((e: any) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
        setLoading(false)
      })
  }

  /*disabled days for arrive date input */
  let disDate = new Date(formik.values.loadNonFormatDate)

  const getApiCalls = () => {
    setLoading(true)
    getTrailerTypes()
    setLoading(true)
    getFloorTypes()
    setLoading(true)
    getTransportTypes()
    setLoading(true)
    getFreightTypes()
    setLoading(true)
    getFreightLoadTypes()
    setLoading(true)
    getFreightPackageTypes()
    setLoading(true)
    getAddressList()
    setLoading(true)
    getCurrencyList()
    setLoading(true)
    getGtip()
    getAllTransportTypes()
    getAllFreightTypes()
    getAllFreightContentTypeList()
    hadnleGetTransporterGroupList()
    getFromCountryList()
    getToCountryList()
    setLoading(true)
    getAddressList()
    setLoading(true)
    getCountryList()
    setLoading(true)
    getStatusTypes()

    const googleMapScript = loadMapApi()
    googleMapScript.addEventListener('load', function () {
      setScriptLoaded(true)
    })
  }

  useEffect(() => {
    getApiCalls()
    setFilterCounter(1)
    if (getCurrentUrl(location.pathname) === '/forwarder/my-adverts') {
      setForwarder(true)
    }
  }, [])

  const [currSpecDat, setCurrSpecDat] = useState<any>(null)

  useEffect(() => {
    formik.values.trailerType &&
      getTrailerSpecifications(parseInt(formik.values.trailerType))
        .then((resp: any) => {
          formik.setFieldValue('freightSpecificationList', currSpecDat)
        })
        .catch((e: any) => {
          toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
          setLoading(false)
        })
  }, [formik.values.trailerType])

  const onPaginationChange = (page: number) => {
    if (!isNextPage) {
      getAdvertList({
        freightStatusId: filter,
        pageMIndex: page,
      })
      setPageMIndex(page)
    }
    setIsNextPage(true)
  }

  return (
    <>
      {loading && <Loading />}
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'Freight.myAdvert.title'})}</PageTitle>
      <div className='row mb-5 justify-content-end'>
        <div className='d-flex justify-content-end'>
          <ActionDropDown menuTitle={intl.formatMessage({id: 'freight.create.createFreight'})}>
            <div className='menu-item px-3'>
              <button
                onClick={() =>
                  history.push(
                    isForwarder
                      ? '/forwarder/create-quick-new-advert'
                      : '/carrier/create-quick-new-advert'
                  )
                }
                className='btn btn-sm btn-light-primary mb-2 border border-2 w-100'
              >
                {intl.formatMessage({id: 'freight.create.quick'})}
              </button>

              <button
                onClick={() =>
                  history.push(
                    isForwarder ? '/forwarder/create-new-advert' : '/carrier/create-new-advert'
                  )
                }
                className='btn btn-sm btn-light-primary mb-2 border border-2 w-100'
              >
                {intl.formatMessage({id: 'freight.create.detailed'})}
              </button>
            </div>
          </ActionDropDown>
        </div>
      </div>
      <div
        className={`d-flex mb-5 ${
          showFilterPanel
            ? 'justify-content-between align-items-end'
            : showPartialFreightList
            ? 'justify-content-end align-items-center'
            : 'justify-content-between align-items-center'
        } `}
      >
        {showFilterPanel && (
          <div className='col-md-3'>
            <h6 className={'mb-3'}>
              {intl.formatMessage({id: 'Freight.myAdvert.filterByStatus'})}
            </h6>
            <div className={''}>
              <Dropbox
                hideArrow
                customClass={'form-select'}
                placeholder={intl.formatMessage({id: 'Freight.myAdvert.selectStatus'})}
                customSearchValue={''}
                customOnSearch={(e: any) => {}}
                disabledSearch
                onScroll={() => {}}
                setScrollCalled={() => {}}
                currentPageIndex={0}
                menuList={
                  statusList &&
                  statusList.map((item: any) => {
                    return {
                      id: item?.freightStatusId,
                      title: item?.description,
                    }
                  })
                }
                selectedItemData={(item: any) => {
                  if (item?.id) {
                    setFilter(item?.id)
                  }
                }}
              />
            </div>
          </div>
        )}
        {!showPartialFreightList && (
          <div>
            <button
              className={'btn btn-primary'}
              onClick={() => {
                setShowPartialFreightList(true)
              }}
            >
              {intl.formatMessage({id: 'Carrier.freight.partialFreight.publish'})}
            </button>
          </div>
        )}
        <div className={'d-flex align-items-center justify-content-end'}>
          <span className='menu-icon d-flex justify-content-end'>
            <div
              className={`btn btn-icon btn-active-light-primary btn-custom ${
                showFilterPanel ? 'menu-button-active' : ''
              }`}
              data-kt-menu-attach='parent'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='bottom'
              onClick={() => setShowFilterPanel(!showFilterPanel)}
            >
              <KTSVG path='/media/filtering.svg' className='svg-icon-2x' />
            </div>
          </span>
        </div>
      </div>
      <div>
        {!advertList.length && (
          <div className='alert alert-warning mb-5 fw-bolder' role='alert'>
            {intl.formatMessage({id: 'Freight.myAdvert.noData'})}
          </div>
        )}
        <DataTableAdvanced
          columns={[
            {
              Header: 'ID',
              accessor: 'id',
            },
            {
              Header: '',
              accessor: 'freightIcon',
              isAction: true,
              Cell: ({row}: any) => (
                <>
                  {getFreightIcon[row?.values?.freightIcon] && (
                    <img
                      src={getFreightIcon[row?.values?.freightIcon]}
                      className='freightIconWrap'
                      alt='frieghtIcon'
                    />
                  )}
                </>
              ),
            },
            {
              Header: '',
              accessor: 'publishFreightList',
              isAction: true,
              hidden: showPartialFreightList ? false : true,
              Cell: ({row}: any) => (
                <button
                  className={'btn btn-primary'}
                  onClick={() => {
                    setLoading(true)
                    setSelectedPublishFreightList((publishItem: any) =>
                      publishItem.concat(row?.values?.publishFreightList)
                    )
                    getAdvertList({
                      freightStatusId: filter,
                      pageMIndex,
                      willBeExcludedFreightIdList: selectedPartialPublicFreightList.concat(
                        row?.values?.publishFreightList?.freightId
                      ),
                    })
                    setSelectedPartialPublicFreightList(
                      selectedPartialPublicFreightList.concat(
                        row?.values?.publishFreightList?.freightId
                      )
                    )
                    getSelectedFreightDetailed(row?.values?.id?.split('.')[0])
                  }}
                >
                  {intl.formatMessage({id: 'Carrier.freight.partialFreight.add'})}
                </button>
              ),
            },
            {
              Header: intl.formatMessage({id: 'Carrier.myAdvert.table.description'}),
              accessor: 'title',
            },
            {
              Header: intl.formatMessage({id: 'Carrier.myAdvert.table.targetCost'}),
              accessor: 'value',
              cellStyle: {
                textAlign: 'end',
              },
            },
            {
              Header: intl.formatMessage({id: 'Admin.Adverts.table_currency'}),
              accessor: 'currencyCode',
            },
            {
              Header: intl.formatMessage({id: 'Freight.myAdvert.from'}),
              accessor: 'fromLocation',
            },
            {
              Header: intl.formatMessage({id: 'Freight.myAdvert.to'}),
              accessor: 'toLocation',
            },
            {
              Header: intl.formatMessage({id: 'Freight.myAdvert.departureDate'}),
              accessor: 'departureDate',
            },
            {
              Header: intl.formatMessage({id: 'Freight.myAdvert.arrivalDate'}),
              accessor: 'arriveDate',
            },
            {
              Header: intl.formatMessage({id: 'Freight.myAdvert.status'}),
              accessor: 'status',
            },
            {
              Header: 'hiddenButtons',
              accessor: 'hiddenButtons',
              hidden: true,
            },
            ...(filter === '3'
              ? [
                  {
                    Header: intl.formatMessage({id: 'Freight.myAdvert.reasonRejection'}),
                    accessor: 'statusDetail',
                  },
                ]
              : []),
          ]}
          editableRows={['']}
          data={
            advertList &&
            advertList.map((item: any) => {
              return {
                id: item?.freightId + '.' + item?.freightStatus?.freightStatusId,
                title: item?.freightContentType?.description,
                value:
                  item?.targetCost &&
                  priceConversion(item?.targetCost, item?.targetCostCurrency?.currencyCode),
                currencyCode: item?.targetCostCurrency?.currencyCode,
                freightIcon: item?.freightType?.freightTypeId,
                fromLocation:
                  item?.fromAddress?.city?.name + ' / ' + item?.fromAddress?.country?.name,
                toLocation: item?.toAddress?.city?.name + ' / ' + item?.toAddress?.country?.name,
                departureDate:
                  item?.plannedDepartureDate !== null &&
                  getDateTime(item?.plannedDepartureDate, intl.locale),
                arriveDate:
                  item?.plannedArrivalDate !== null &&
                  getDateTime(item?.plannedArrivalDate, intl.locale),
                status: item?.freightStatus?.description,
                statusId: item?.freightStatus?.freightStatusId,
                publishFreightList: item,
                hiddenButtons: {
                  showMapBtn: item?.freightStatus?.freightStatusId !== 8,
                  editBtn: item?.freightStatus?.freightStatusId !== 10,
                  deleteRow: item?.freightStatus?.freightStatusId !== 10,
                  sendToApproveBtn:
                    (item?.freightStatus?.freightStatusId !== 10 &&
                      item?.freightStatus?.freightStatusId !== 3) ||
                    role?.includes('CARRIER_OPERATOR') ||
                    role?.includes('FORWARDER_OPERATOR'),
                  deleteRowSecondIcon:
                    item?.freightStatus?.freightStatusId !== 10 &&
                    item?.freightStatus?.freightStatusId !== 3,
                },
                ...(filter === '3' && {statusDetail: item?.statusDetail}),
              }
            })
          }
          updateMyData={() => {}}
          updateRow={() => {}}
          deleteRowSecondIcon={(row: any) => {
            let num = row?.values?.id?.split('.')[0]
            setSelectedFreightId(num)
            setShowDeletePopup(true)
          }}
          deleteRowSecondIconText={intl.formatMessage({id: 'Freight.table.deleteText'})}
          editBtn={(row: any) => {
            let num = row?.values?.id?.split('.')[0]
            setLoading(true)
            getAdvertDetailedList(parseInt(num))
            setSelectedFreightId(row.index)
            setTimeout(() => setShowUpdateScreen(true), 1000)
          }}
          sendToApproveBtn={(row: any) => {
            let num = row?.values?.id?.split('.')[0]
            setSelectedFreightId(parseInt(num))
            setLoading(true)
            sendAdvert(parseInt(num))
          }}
          showMapBtn={(row: any) => {
            let num = row?.values?.id?.split('.')[0]
            setSelectedFreightId(parseInt(num))
            setLoading(true)
            getLocation(parseInt(num))
          }}
          showDetailBtn={(row: any) => {
            let num = row?.values?.id?.split('.')[0]
            setLoading(true)
            scrollLock()
            getAdvertDetailedList(parseInt(num))
            setSelectedFreightId(row.index)
            setShowDetailsScreen(true)
          }}
          deleteRow={(row: any) => {
            setLoading(true)
            let num = row?.values?.id?.split('.')[0]
            getApproved(num)
          }}
          deleteText={intl.formatMessage({id: 'Admin.adverts.actions.done'})}
          hiddenButtons={{
            showMapBtn: filter !== '8',
            editBtn: filter !== '10',
            deleteRow: filter !== '10',
            sendToApproveBtn:
              (filter !== '10' && filter !== '3') ||
              role?.includes('CARRIER_OPERATOR') ||
              role?.includes('FORWARDER_OPERATOR'),
            deleteRowSecondIcon: filter !== '10' && filter !== '3',
          }}
          showFile={(row: any) => {
            let num = row?.values?.id?.split('.')[0]
            setSelectedFreightId(parseInt(num))
            setShowPublishFreight(true)
          }}
          showFileText={intl.formatMessage({id: 'DataTableAdvanced.buttons.publish'})}
          setPageMIndex={onPaginationChange}
          pageMIndex={pageMIndex}
        />
      </div>

      {showPublishFreight && (
        <FreightPublishPopup
          publishDate={publishDate}
          publishNonFormatDate={publishNonFormatDate}
          setPublishNonFormatDate={setPublishNonFormatDate}
          setPublishDate={setPublishDate}
          transporterGroupUserList={transporterGroupUserList}
          selectedPublishFreightTransporterGroup={selectedPublishFreightTransporterGroup}
          setSelectedPublishFreightTransporterGroup={setSelectedPublishFreightTransporterGroup}
          selectedFreightId={selectedFreightId}
          onClose={() => {
            resetField()
            setShowPublishFreight(false)
          }}
          setPublicFreightExchange={setPublicFreightExchange}
          publicFreightExchange={publicFreightExchange}
          setPrivateFreightExchange={setPrivateFreightExchange}
          privateFreightExchange={privateFreightExchange}
          publishFreight={(data: any) => {
            setLoading(true)
            handlePublishFreight({
              ...data,
              transporterGroupList: selectedTransporterGroup.map((item: any) => ({
                transporterGroupId: item.id,
              })),
            })
          }}
          setDescription={setDescription}
          description={description}
          targetCost={targetCost}
          setTargetCost={setTargetCost}
          selectedCurrency={selectedCurrency}
          selectedCurrencyName={selectedCurrencyName}
          currencyList={currencyList}
          setSelectedCurrency={setSelectedCurrency}
          setSelectedCurrencyName={setSelectedCurrencyName}
        />
      )}

      {showPartialFreightList && (
        <PublishPartialFreightsList
          advertDetailedList={selectedPublishFreightList}
          onClose={() => {
            resetField()
            getAdvertList({freightStatusId: filter, pageMIndex})
            setShowPartialFreightList(false)
          }}
          selectedFreightDetails={selectedFreightDetails}
          transportTypeList={allTransportTypes}
          freightTypes={allFreightTypes}
          freightContentTypeList={allFreightContentTypes}
          transporterGroupUserList={transporterGroupUserList}
          currencyList={currencyList}
          fromCountryList={fromCountryList}
          toCountryList={toCountryList}
          fromCityList={fromCityList}
          toCityList={toCityList}
          fromCountryId={fromCountryId}
          toCountryId={toCountryId}
          setFromCountryId={setFromCountryId}
          setFromCountryName={setFromCountryName}
          fromCountryName={fromCountryName}
          setToCountryId={setToCountryId}
          setToCountryName={setToCountryName}
          toCountryName={toCountryName}
          setFromCityId={setFromCityId}
          fromCityId={fromCityId}
          fromCityName={fromCityName}
          setFromCityName={setFromCityName}
          setToCityId={setToCityId}
          toCityId={toCityId}
          setToCityName={setToCityName}
          toCityName={toCityName}
          transportTypeSearch={transportTypeSearch}
          setTransportTypeSearch={setTransportTypeSearch}
          transportTypePageIndex={transportTypePageIndex}
          setTransportTypePageIndex={setTransportTypePageIndex}
          transportTypeId={transportTypeId}
          setTransportTypeName={setTransportTypeName}
          transportTypeName={transportTypeName}
          setTransportTypeId={(itemId: any) => {
            setTransportTypeId(itemId)
            getAdvertList({
              freightStatusId: filter,
              pageMIndex,
              willBeExcludedFreightIdList: selectedPartialPublicFreightList,
              transportTypeId: itemId,
              freightTypeId: selectedFreightType,
            })
          }}
          selectedFreightType={selectedFreightType}
          setSelectedFreightType={(itemId: any) => {
            setSelectedFreightType(itemId)
            getAdvertList({
              freightStatusId: filter,
              pageMIndex,
              willBeExcludedFreightIdList: selectedPartialPublicFreightList,
              transportTypeId,
              freightTypeId: itemId,
            })
          }}
          freightContentTypeSearch={freightContentTypeSearch}
          setFreightContentTypePageIndex={setFreightContentTypePageIndex}
          setFreightContentTypeSearch={setFreightContentTypeSearch}
          freightContentTypePageIndex={freightContentTypePageIndex}
          setFreightContentTypeId={setFreightContentTypeId}
          freightContentTypeId={freightContentTypeId}
          freightContentTypeName={freightContentTypeName}
          setFreightContentTypeName={setFreightContentTypeName}
          setDescription={setDescription}
          description={description}
          setPrivateFreightExchange={setPrivateFreightExchange}
          privateFreightExchange={privateFreightExchange}
          publicFreightExchange={publicFreightExchange}
          setPublicFreightExchange={setPublicFreightExchange}
          selectedTransporterGroup={selectedTransporterGroup}
          setSelectedTransporterGroup={setSelectedTransporterGroup}
          targetCost={targetCost}
          setTargetCost={setTargetCost}
          setSelectedCurrency={setSelectedCurrency}
          selectedCurrency={selectedCurrency}
          selectedCurrencyName={selectedCurrencyName}
          setSelectedCurrencyName={setSelectedCurrencyName}
          setLoadIssueDate={setLoadIssueDate}
          loadIssueDate={loadIssueDate}
          setIssueDate={setIssueDate}
          issueDate={issueDate}
          plannedDepartureDate={plannedDepartureDate}
          setLoadPlannedDepartureDate={setLoadPlannedDepartureDate}
          setPlannedDepartureDate={setPlannedDepartureDate}
          loadPannedDepartureDate={loadPannedDepartureDate}
          fromCountrySearch={fromCountrySearch}
          setFromCountrySearch={setFromCountrySearch}
          fromCitySearch={fromCitySearch}
          toCountrySearch={toCountrySearch}
          toCitySearch={toCitySearch}
          setFromCitySearch={setFromCitySearch}
          setToCountrySearch={setToCountrySearch}
          setToCitySearch={setToCitySearch}
          setDependentToCity={setDependentToCity}
          dependentToCity={dependentToCity}
          dependentFromCity={dependentFromCity}
          setDependentFromCity={setDependentFromCity}
          setSelectedPublishFreightList={setSelectedPublishFreightList}
          setSelectedPartialPublicFreightList={(selectedId: any) =>
            setSelectedPartialPublicFreightList(
              selectedPartialPublicFreightList.filter((dataItem: any) => dataItem !== selectedId)
            )
          }
          getAdvertList={(selectedId: any) =>
            getAdvertList({
              freightStatusId: filter,
              pageMIndex,
              willBeExcludedFreightIdList: selectedPartialPublicFreightList.filter(
                (dataItem: any) => dataItem !== selectedId
              ),
            })
          }
          createAndPublishPartialContainer={() => {
            setLoading(true)
            createAndPublishPartialContainer({
              transportType: {
                transportTypeId: transportTypeId,
              },
              freightType: {
                freightTypeId: 2,
              },
              freightContentType: {
                freightContentTypeId: freightContentTypeId,
              },
              description: description,
              freightList: selectedPartialPublicFreightList.map((item: any) => ({freightId: item})),
              publishToPublicFreightExchange: publicFreightExchange ? 'Y' : 'N',
              publishToPrivateFreightExchange: privateFreightExchange ? 'Y' : 'N',
              transporterGroupList: selectedTransporterGroup.map((item: any) => ({
                transporterGroupId: item.id,
              })),
              fromLocation: {
                country: {
                  code: fromCountryId === 'all' ? '' : null,
                },
                city: {
                  cityId: fromCityId === 'all' ? '' : null,
                },
              },
              toLocation: {
                country: {
                  code: toCountryId === 'all' ? '' : null,
                },
                city: {
                  cityId: toCityId === 'all' ? '' : null,
                },
              },
              price: targetCost,
              priceCurrency: {
                currencyId: selectedCurrency,
              },
              issueStartDate: !!issueDate ? Math.round(new Date(issueDate).getTime() / 1000) : null,
              plannedDepartureDate: !!plannedDepartureDate
                ? Math.round(new Date(plannedDepartureDate).getTime() / 1000)
                : null,
            })
          }}
        />
      )}

      {showDeletePopup && (
        <Modal
          closeBtnSettings={{
            show: true,
            onClick: () => setShowDeletePopup(false),
          }}
          customAll={
            <div className={''}>
              <h3 className={'mt-5 mb-15'}>
                {intl.formatMessage({id: 'Freight.myAdvert.delete.confirmDelete'})}
              </h3>
              <div className={'row'}>
                <div className={'col-md-6'}>
                  <button
                    className={'btn btn-danger w-100'}
                    onClick={() => {
                      setShowDeletePopup(false)
                    }}
                  >
                    {intl.formatMessage({id: 'Freight.myAdvert.delete.cancel'})}
                  </button>
                </div>
                <div
                  className={'col-md-6'}
                  onClick={() => {
                    setLoading(true)
                    deleteAdvert(selectedFreightId)
                    setShowDeletePopup(false)
                    setLoading(true)
                    getAdvertList({freightStatusId: filter, pageMIndex})
                  }}
                >
                  <button className={'btn btn-success w-100'}>
                    {intl.formatMessage({id: 'Freight.myAdvert.delete.approve'})}
                  </button>
                </div>
              </div>
            </div>
          }
        />
      )}

      {showDetailsScreen && (
        <RightLayoutPopup
          title={intl.formatMessage({id: 'Freight.myAdvert.freightDetails'})}
          className={'right-layout-show-wrap'}
          isCloseIcon
          closeOnClick={() => setShowDetailsScreen(false)}
        >
          <div className={''}>
            <div className={'detail-modal-scroll-container'}>
              {/*Araç Seçenekleri -1-*/}
              <div className={'mb-10 '}>
                <h4 className={'form-label fw-bolder text-dark fs-3'}>
                  {intl.formatMessage({id: 'Freight.myAdvert.vehicleOption'})}
                </h4>
                <hr />
                <div className={'row'}>
                  <div className={'col-md-6 mb-7'}>
                    <h6 className={'text-muted  '}>
                      {intl.formatMessage({id: 'Freight.myAdvert.descriptions'})}
                    </h6>
                    <b className={'fs-5'}>{advertDetailedList?.description}</b>
                  </div>
                  <div className={'col-md-6 mb-7'}>
                    <h6 className={'text-muted '}>
                      {intl.formatMessage({id: 'Freight.myAdvert.trailerType'})}
                    </h6>
                    <b className={'fs-5'}>{advertDetailedList?.trailerType?.description}</b>
                  </div>
                  <div className={'col-md-6 mb-7'}>
                    <h6 className={'text-muted '}>
                      {intl.formatMessage({id: 'Freight.myAdvert.floorType'})}
                    </h6>
                    <b className={'fs-5'}>{advertDetailedList?.floorType?.description}</b>
                  </div>
                  <div className={'col-md-6 mb-7'}>
                    <h6 className={'text-muted '}>
                      {intl.formatMessage({id: 'Freight.myAdvert.specificationList'})}
                    </h6>
                    {advertDetailedList?.specificationList?.map((item: any, i: number) => (
                      <p key={i} className={'fs-5 font-weight-bold text-bold'}>
                        {item?.description}
                      </p>
                    ))}
                  </div>

                  <div className={'col-md-6 mb-7'}>
                    <h6 className={'text-muted '}>
                      {intl.formatMessage({id: 'Freight.myAdvert.transportType'})}
                    </h6>
                    <b className={'fs-5'}>{advertDetailedList?.transportType?.description}</b>
                  </div>

                  <div className={'col-md-6 mb-7'}>
                    <h6 className={'text-muted '}>
                      {intl.formatMessage({id: 'freight.details.content'})}
                    </h6>
                    <b className={'fs-5'}>{advertDetailedList?.freightContentType?.description}</b>
                  </div>
                </div>
              </div>

              {/*Tarih ve Zaman -2-*/}
              <div className={'mb-10'}>
                <h4 className={'form-label fw-bolder text-dark fs-3'}>
                  {intl.formatMessage({id: 'Freight.myAdvert.dateAndTime'})}
                </h4>
                <hr />
                <div className={'row m-auto'}>
                  <div className={'col-md-6 mb-7'}>
                    <h6 className={'text-muted  '}>
                      {intl.formatMessage({id: 'Freight.myAdvert.plannedDepartureDate'})}
                    </h6>
                    <b className={'fs-5'}>
                      {getDateTime(advertDetailedList?.plannedDepartureDate, intl.locale)}
                    </b>
                  </div>
                  {/*   <div className={'col-md-6 mb-7'}>
                                                <h6 className={'text-muted '}>Yükleme Saati</h6>
                                                <b className={'fs-5'}>{advertList[selectedFreightId].plannedArrivalDate}</b>
                                            </div>*/}
                  <div className={'col-md-6 mb-7'}>
                    <h6 className={'text-muted '}>
                      {intl.formatMessage({id: 'Freight.myAdvert.plannedArrivalDate'})}
                    </h6>
                    <b className={'fs-5'}>
                      {getDateTime(advertDetailedList?.plannedArrivalDate, intl.locale)}
                    </b>
                  </div>
                  {/* <div className={'col-md-6 mb-7'}>
                                                <h6 className={'text-muted '}>Varış Saati</h6>
                                                <b className={'fs-5'}>{formik.values.destTime}</b>
                                            </div>*/}
                </div>
              </div>

              <div className='mb-10'>
                <div className='row d-flex justify-content-between'>
                  <h4 className={'form-label col-md-6 fw-bolder text-dark fs-3'}>
                    {intl.formatMessage({id: 'Carrier.freight.details.consignor'})}
                  </h4>

                  <h4 className={'form-label col-md-6 fw-bolder text-dark fs-3'}>
                    {intl.formatMessage({id: 'Carrier.freight.details.consignee'})}
                  </h4>
                </div>
                <hr />

                <div className={'row m-auto'}>
                  <div className='col-md-6'>
                    <div className='row'>
                      <div className={'col-md-6 mb-7'}>
                        <h6 className={'text-muted'}>
                          {intl.formatMessage({id: 'Carrier.freight.details.name'})}
                        </h6>
                        <b className={'fs-5'}>{advertDetailedList?.consignor?.name}</b>
                      </div>

                      <div className={'col-md-6 mb-7'}>
                        <h6 className={'text-muted'}>
                          {intl.formatMessage({id: 'Carrier.freight.details.surName'})}
                        </h6>
                        <b className={'fs-5'}>{advertDetailedList?.consignor?.surname}</b>
                      </div>

                      <div className={'col-md-6 mb-7'}>
                        <h6 className={'text-muted '}>
                          {intl.formatMessage({id: 'Carrier.freight.details.countryCode'})}
                        </h6>
                        <b className={'fs-5'}>
                          {advertDetailedList?.consignor?.country?.phoneCode}
                        </b>
                      </div>

                      <div className={'col-md-6 mb-7'}>
                        <h6 className={'text-muted  '}>
                          {intl.formatMessage({id: 'Carrier.freight.details.phone'})}
                        </h6>
                        <b className={'fs-5'}>{advertDetailedList?.consignor?.phone}</b>
                      </div>

                      <div className={'col-md-12 mb-7'}>
                        <h6 className={'text-muted'}>
                          {intl.formatMessage({id: 'Carrier.freight.details.email'})}
                        </h6>
                        <b className={'fs-5'}>{advertDetailedList?.consignor?.email}</b>
                      </div>
                    </div>
                  </div>

                  <div className='col-md-6'>
                    <div className='row'>
                      <div className={'col-md-6 mb-7'}>
                        <h6 className={'text-muted'}>
                          {intl.formatMessage({id: 'Carrier.freight.details.name'})}
                        </h6>
                        <b className={'fs-5'}>{advertDetailedList?.consignee?.name}</b>
                      </div>

                      <div className={'col-md-6 mb-7'}>
                        <h6 className={'text-muted'}>
                          {intl.formatMessage({id: 'Carrier.freight.details.surName'})}
                        </h6>
                        <b className={'fs-5'}>{advertDetailedList?.consignee?.surname}</b>
                      </div>

                      <div className={'col-md-6 mb-7'}>
                        <h6 className={'text-muted'}>
                          {intl.formatMessage({id: 'Carrier.freight.details.countryCode'})}
                        </h6>
                        <b className={'fs-5'}>
                          {advertDetailedList?.consignee?.country?.phoneCode}
                        </b>
                      </div>

                      <div className={'col-md-6 mb-7'}>
                        <h6 className={'text-muted '}>
                          {intl.formatMessage({id: 'Carrier.freight.details.phone'})}
                        </h6>
                        <b className={'fs-5'}>{advertDetailedList?.consignee?.phone}</b>
                      </div>

                      <div className={'col-md-12 mb-7'}>
                        <h6 className={'text-muted'}>
                          {intl.formatMessage({id: 'Carrier.freight.details.email'})}
                        </h6>
                        <b className={'fs-5'}>{advertDetailedList?.consignee?.email}</b>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/*Adres Tercihleri -3-*/}
              <div className={'mb-10'}>
                <h4 className={'form-label fw-bolder text-dark fs-3  '}>
                  {intl.formatMessage({id: 'Freight.myAdvert.addressPreferences'})}
                </h4>
                <hr />
                <div className={'row m-auto'}>
                  <div className={'col-md-6 mb-7'}>
                    <h6 className={'text-muted  '}>
                      {intl.formatMessage({id: 'Freight.myAdvert.fromLocation'})}
                    </h6>
                    <b className={'fs-5'}>{advertDetailedList?.fromAddress?.addressText}</b>
                  </div>
                  <div className={'col-md-6 mb-7'}>
                    <h6 className={'text-muted '}>
                      {intl.formatMessage({id: 'Freight.myAdvert.toLocation'})}
                    </h6>
                    <b className={'fs-5'}>{advertDetailedList?.toAddress?.addressText}</b>
                  </div>
                </div>

                <div className={'row'} style={{margin: 0}}>
                  {advertDetailedList?.fromAddress && (
                    <div className={'col-md-6 '} style={{paddingLeft: 0}}>
                      <div className={'row border border-2 rounded py-4'} style={{margin: 0}}>
                        <div className={'col-md-4 mb-5 '}>
                          <p className={'mb-0 text-muted'}>
                            {intl.formatMessage({id: 'Freight.createAdvert.departure.explanation'})}
                          </p>
                          <b>{advertDetailedList?.fromAddress?.description}</b>
                        </div>
                        <div className={'col-md-4 mb-5 '}>
                          <p className={'mb-0 text-muted'}>
                            {intl.formatMessage({id: 'Freight.createAdvert.departure.country'})}
                          </p>
                          <b>{advertDetailedList?.fromAddress?.country?.name}</b>
                        </div>
                        <div className={'col-md-4 mb-5 '}>
                          <p className={'mb-0 text-muted'}>
                            {intl.formatMessage({id: 'Freight.createAdvert.departure.city'})}
                          </p>
                          <b>{advertDetailedList?.fromAddress?.city?.name}</b>
                        </div>
                        <div className={'col-md-4 mb-5 '}>
                          <p className={'mb-0 text-muted'}>
                            {intl.formatMessage({id: 'Freight.createAdvert.departure.town'})}
                          </p>
                          <b>{advertDetailedList?.fromAddress?.town}</b>
                        </div>
                        <div className={'col-md-4 mb-5 '}>
                          <p className={'mb-0 text-muted'}>
                            {intl.formatMessage({id: 'Freight.createAdvert.departure.district'})}
                          </p>
                          <b>{advertDetailedList?.fromAddress?.district}</b>
                        </div>
                        <div className={'col-md-4 mb-5 '}>
                          <p className={'mb-0 text-muted'}>
                            {intl.formatMessage({id: 'Freight.createAdvert.departure.postCode'})}
                          </p>
                          <b>{advertDetailedList?.fromAddress?.postCode}</b>
                        </div>
                      </div>
                    </div>
                  )}
                  {advertDetailedList?.toAddress && (
                    <div className={'col-md-6 '} style={{paddingRight: 0}}>
                      <div className={'row border border-2 rounded py-4'} style={{margin: 0}}>
                        <div className={'col-md-4 mb-5 '}>
                          <p className={'mb-0 text-muted'}>
                            {intl.formatMessage({id: 'Freight.createAdvert.arrival.explanation'})}
                          </p>
                          <b>{advertDetailedList?.toAddress?.description}</b>
                        </div>
                        <div className={'col-md-4 mb-5 '}>
                          <p className={'mb-0 text-muted'}>
                            {intl.formatMessage({id: 'Freight.createAdvert.arrival.country'})}
                          </p>
                          <b>{advertDetailedList?.toAddress?.country?.name}</b>
                        </div>
                        <div className={'col-md-4 mb-5 '}>
                          <p className={'mb-0 text-muted'}>
                            {intl.formatMessage({id: 'Freight.createAdvert.arrival.city'})}
                          </p>
                          <b>{advertDetailedList?.toAddress?.city?.name}</b>
                        </div>
                        <div className={'col-md-4 mb-5 '}>
                          <p className={'mb-0 text-muted'}>
                            {intl.formatMessage({id: 'Freight.createAdvert.arrival.district'})}
                          </p>
                          <b>{advertDetailedList?.toAddress?.town}</b>
                        </div>
                        <div className={'col-md-4 mb-5 '}>
                          <p className={'mb-0 text-muted'}>
                            {intl.formatMessage({id: 'Freight.createAdvert.arrival.dis'})}
                          </p>
                          <b>{advertDetailedList?.toAddress?.district}</b>
                        </div>
                        <div className={'col-md-4 mb-5 '}>
                          <p className={'mb-0 text-muted'}>
                            {intl.formatMessage({id: 'Freight.createAdvert.arrival.postCode'})}
                          </p>
                          <b>{advertDetailedList?.toAddress?.postCode}</b>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {/* mal Bilgileri -4*/}
              <div className={''}>
                <h4 className={'form-label fw-bolder text-dark fs-3'}>
                  {intl.formatMessage({id: 'Freight.myAdvert.goodsInfo'})}
                </h4>
                <hr />
                <div className={'row m-auto'}>
                  <div className={'col-md-4 mb-7'}>
                    <h6 className={'text-muted  '}>
                      {intl.formatMessage({id: 'Freight.myAdvert.values'})}
                    </h6>
                    <b className={'fs-5'}>{advertDetailedList?.value}</b>
                  </div>
                  <div className={'col-md-4 mb-7'}>
                    <h6 className={'text-muted '}>
                      {intl.formatMessage({id: 'Freight.myAdvert.currency'})}
                    </h6>
                    <b className={'fs-5'}>{advertDetailedList?.valueCurrency?.description}</b>
                  </div>
                  <div className={'col-md-4 mb-7'}>
                    <h6 className={'text-muted '}>
                      {intl.formatMessage({id: 'Freight.myAdvert.freightType'})}
                    </h6>
                    <b className={'fs-5'}>{advertDetailedList?.freightType?.description}</b>
                  </div>
                  <div className={'col-md-4 mb-7'}>
                    <h6 className={'text-muted '}>
                      {intl.formatMessage({id: 'Freight.myAdvert.packageType'})}
                    </h6>
                    <b className={'fs-5'}>{advertDetailedList?.freightPackageType?.description}</b>
                  </div>
                  <div className={'col-md-4 mb-7'}>
                    <h6 className={'text-muted '}>
                      {intl.formatMessage({id: 'Freight.myAdvert.loadingType'})}
                    </h6>
                    <b className={'fs-5'}>{advertDetailedList?.freightLoadingType?.description}</b>
                  </div>
                  <div className={'col-md-4 mb-7'}>
                    <h6 className={'text-muted '}>
                      {intl.formatMessage({id: 'Freight.myAdvert.gtip'})}
                    </h6>
                    <p className={'fs-5 mb-0'}>
                      <b>{advertDetailedList?.harmonizedSystemCode?.code}</b>
                    </p>
                    <b className={'fs-5'}>
                      {advertDetailedList?.harmonizedSystemCode?.description}
                    </b>
                  </div>
                  <div className={'col-md-4 mb-7'}>
                    <h6 className={'text-muted '}>
                      {intl.formatMessage({id: 'Freight.myAdvert.lengthValue'})}
                    </h6>
                    <b className={'fs-5'}>{advertDetailedList?.length}</b>
                  </div>
                  <div className={'col-md-4 mb-7'}>
                    <h6 className={'text-muted '}>
                      {intl.formatMessage({id: 'Freight.myAdvert.widthValue'})}
                    </h6>
                    <b className={'fs-5'}>{advertDetailedList?.width}</b>
                  </div>
                  <div className={'col-md-4 mb-7'}>
                    <h6 className={'text-muted '}>
                      {intl.formatMessage({id: 'Freight.myAdvert.heightValue'})}
                    </h6>
                    <b className={'fs-5'}>{advertDetailedList?.height}</b>
                  </div>
                  <div className={'col-md-4 mb-7'}>
                    <h6 className={'text-muted '}>
                      {intl.formatMessage({id: 'Freight.myAdvert.grossKg'})}
                    </h6>
                    <b className={'fs-5'}>{advertDetailedList?.weight}</b>
                  </div>
                  <div className={'col-md-4 mb-7'}>
                    <h6 className={'text-muted '}>
                      {intl.formatMessage({id: 'Freight.myAdvert.desi'})}
                    </h6>
                    <b className={'fs-5'}>{advertDetailedList?.desi}</b>
                  </div>
                  <div className={'col-md-4 mb-7'}>
                    <h6 className={'text-muted '}>
                      {intl.formatMessage({id: 'Freight.myAdvert.ldm'})}
                    </h6>
                    <b className={'fs-5'}>{advertDetailedList?.ldm}</b>
                  </div>

                  <div className={'col-md-4 mb-7'}>
                    <h6 className={'text-muted '}>
                      {intl.formatMessage({id: 'Freight.myAdvert.productContaining'})}
                    </h6>
                    <b className={'fs-5'}>
                      {advertDetailedList?.flammable === 'Y'
                        ? intl.formatMessage({id: 'Freight.myAdvert.yes'})
                        : intl.formatMessage({id: 'Freight.myAdvert.no'})}
                    </b>
                  </div>
                  <div className={'col-md-4 mb-7'}>
                    <h6 className={'text-muted '}>
                      {intl.formatMessage({id: 'Freight.myAdvert.stackable'})}
                    </h6>
                    <b className={'fs-5'}>
                      {advertDetailedList?.stackable === 'Y'
                        ? intl.formatMessage({id: 'Freight.myAdvert.yes'})
                        : intl.formatMessage({id: 'Freight.myAdvert.no'})}
                    </b>
                  </div>
                  {/* <div className={'col-md-4 mb-7'}>
                                            <h6 className={'text-muted '}>Hacim DM3 (Opsiyonel)</h6>
                                            <b className={'fs-5'}>{''}</b>
                                        </div>*/}
                </div>
                <hr />
              </div>
            </div>
            <div className='text-center mt-5 row view-details-freight-popup'>
              <div className='text-center mt-5 row advert-details-modal-button-close'>
                <div className={'col-md-6'}>
                  <button
                    onClick={() => {
                      setShowDetailsScreen(false)
                      scrollUnlock()
                    }}
                    type='button'
                    id='kt_sign_up_submit'
                    className='btn btn-lg btn-danger w-100 mb-5 '
                  >
                    {intl.formatMessage({id: 'Carrier.freightPosting.view.close'})}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </RightLayoutPopup>
      )}

      {showMapScreen && (
        <Modal
          closeBtnSettings={{
            show: true,
            onClick: () => setShowMapScreen(false),
          }}
          mainTitle={intl.formatMessage({id: 'Freight.myAdvert.whereMyLoad?'})}
          width={'75%'}
          height={'70%'}
          customAll={
            <div>
              {scriptLoaded && (
                <Map
                  height={'57vh'}
                  mapType={google.maps.MapTypeId.ROADMAP}
                  mapTypeControl={true}
                  setDistanceInKm={setDistanceInKm}
                  customLocation={{
                    lat: advertLocation?.latitude ? advertLocation?.latitude : '36.197900',
                    lot: advertLocation?.longitude ? advertLocation?.longitude : '36.159033',
                  }}
                  location={(item: any) => {
                    setSelectedMarkerAddress(item)
                  }}
                />
              )}
              {distanceInKm > -1 && renderDistanceSentence()}
            </div>
          }
        />
      )}

      {showUpdateScreen && (
        <EditPreferences
          currentData={advertDetailedList}
          editedData={(data: any) => {
            data && updateAdvert(data)
            data && setShowUpdateScreen(false)
          }}
          selectedTrailerType={(val: any) => {
            val && getTrailerSpecifications(val)
          }}
          setLoading={setLoading}
          trailerTypes={trailerTypes}
          trailerSpecifications={trailerSpecifications}
          floorTypes={floorTypes}
          countries={countries}
          cities={cities}
          addressList={addressList}
          currencyList={currencyList}
          transportTypes={transportTypes}
          freightTypes={freightTypes}
          freightPackageTypes={freightPackageTypes}
          freightLoadTypes={freightLoadTypes}
          gtipList={gtipList}
          onClose={() => setShowUpdateScreen(false)}
        />
      )}
    </>
  )
}
export {CarrierMyAdverts}
