import React, {useState} from 'react'
import {useIntl} from 'react-intl'

import {BlogsData} from '../utils/common'
import {ReactComponent as NextActive} from '../app/Components/DataTableAdvanced/assets/next-active.svg'
import {ReactComponent as NextInActive} from '../app/Components/DataTableAdvanced/assets/next-inactive.svg'

import './blogs.scss'

const RelatedBlogs = ({url}: any) => {
  const intl = useIntl()
  const [page, setPage] = useState<any>(3)

  return (
    <div className='container'>
      <div className='d-flex justify-content-center'>
        <h2 className='related_post_title'>{intl.formatMessage({id: 'relatedBlogs.title'})}</h2>
      </div>

      <div className='related_post_desc mb-10'>
        {intl.formatMessage({id: 'relatedBlogs.title1'})}Rivalog.
      </div>
      <div className='row'>
        {BlogsData(intl)
          .filter((blog: any) => blog.url !== url)
          .splice(page - 3, 3)
          .map((item: any) => {
            return (
              <div className='col-4 mb-10 blog_mobile_wrap'>
                <article className='blog-card' style={{paddingBottom: '5px'}}>
                  <div className='blog-card__background'>
                    <div className='card__background--wrapper'>
                      <div
                        className='card__background--main'
                        style={{backgroundImage: `url("${item.imgUrl}")`}}
                      >
                        <div className='card__background--layer'></div>
                      </div>
                    </div>
                  </div>
                  <div className='blog-card__head' onClick={() => window.open(item.url, '_blank')}>
                    <span className='date__box'>
                      <span className='date__month'>
                        {intl.formatMessage({id: 'relatedBlogs.readMore'})}
                      </span>
                    </span>
                  </div>
                  <div className='blog-card__info'>
                    <h5>{item.title}</h5>
                    <p className='mt-2'>{item.description}</p>
                    <a
                      href={item.url}
                      rel='noreferrer'
                      className='btn btn--with-icon mt-2 read-more-wrap'
                      target='_blank'
                    >
                      <i className='btn-icon fa fa-long-arrow-right'></i>
                      {intl.formatMessage({id: 'relatedBlogs.readMore'})}
                    </a>
                  </div>
                </article>
              </div>
            )
          })}
      </div>
      <div>
        <div className='pagination related-page-nav-wrap mb-10'>
          <div className={'pagination-buttons'}>
            <button
              className={'back pag-btn'}
              onClick={() => setPage(page - 3)}
              disabled={page === 3}
            >
              <span className={'previous-arrow'}>
                {!(page === 3) ? <NextActive /> : <NextInActive />}
              </span>
            </button>
            <button
              className={'next pag-btn'}
              onClick={() => {
                setPage(page + 3)
              }}
              disabled={BlogsData(intl).length - page < 3 || BlogsData(intl).length === page}
            >
              {!(BlogsData(intl).length - page < 3 || BlogsData(intl).length === page) ? (
                <NextActive />
              ) : (
                <NextInActive />
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RelatedBlogs
