import React, {useState, useEffect} from 'react'
import {useIntl} from 'react-intl'
import clsx from 'clsx'

import RightLayoutPopup from '../../../../Components/RightLayoutPopup'

import useScrollLock from '../../../../../utils/useScrollLock'

const UploadFiles = ({selectedTrailer, trailerMetadata, uploadTrailerPlate, onClose}: any) => {
  const intl = useIntl()
  const {scrollLock} = useScrollLock()
  useEffect(() => {
    scrollLock()
  }, [])

  const [trailerFiles, setTrailerFiles] = useState<any>(null)

  return (
    <>
      <RightLayoutPopup
        title={intl.formatMessage({id: 'Carrier.trailer.upload.uploadTrailer'})}
        className='file-trailer-side-wrap'
        isCloseIcon
        closeOnClick={() => onClose()}
      >
        <div className={''}>
          <div className={''}>
            <div className='fv-row mb-10'>
              <label className='form-label fs-6 fw-bolder text-dark'>
                {intl.formatMessage({id: 'Carrier.trailer.upload.trailerPlate'})}
              </label>
              <input
                disabled={
                  trailerMetadata?.find((item: any) => item?.fileType?.code === 'PLATE')?.fileStatus
                    ?.fileStatusId === 4
                }
                placeholder={intl.formatMessage({
                  id: 'Carrier.trailer.upload.trailerPlate.placeHolder',
                })}
                className={clsx('form-control form-control-lg')}
                onChange={(event: any) => setTrailerFiles(event.target.files[0])}
                type='file'
                name='Trailer Plate'
                autoComplete='off'
              />
              <p className={'text-danger mb-3'}>
                {trailerMetadata?.find((item: any) => item?.fileType?.code === 'PLATE')?.fileStatus
                  ?.fileStatusId === 4 &&
                  intl.formatMessage({id: 'Carrier.drivers.file.alreadyApproved'})}
              </p>
              <p className={'text-danger mb-3'}>
                {trailerMetadata?.find((item: any) => item?.fileType?.code === 'PLATE')?.fileStatus
                  ?.fileStatusId === 1 && intl.formatMessage({id: 'Carrier.drivers.file.approved'})}
              </p>
            </div>
          </div>
        </div>

        <div className='text-center mt-5 row freightPost-popup-container'>
          <div className={'col-md-6'}>
            <button
              onClick={onClose}
              type='button'
              id='kt_sign_up_submit'
              className='btn btn-lg btn-danger w-100 mb-5 '
            >
              {intl.formatMessage({id: 'CreateNewContainerPopup.cancel'})}
            </button>
          </div>
          <div className={'col-md-6'}>
            <button
              className={'btn btn-info px-5 w-100'}
              disabled={!trailerFiles}
              onClick={() => {
                uploadTrailerPlate({id: selectedTrailer.id, file: trailerFiles})
              }}
            >
              {intl.formatMessage({id: 'Carrier.trailer.upload.uploadDoc'})}
            </button>
          </div>
        </div>
      </RightLayoutPopup>
    </>
  )
}

export default UploadFiles
