import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import clsx from 'clsx'

import RightLayoutPopup from '../../../../Components/RightLayoutPopup'

import useScrollLock from '../../../../../utils/useScrollLock'

const UploadDocument = ({handleUploadDocument, onClose, setLoading}: any) => {
  const intl = useIntl()
  const [document, setDocument] = useState<any>(null)
  const {scrollLock} = useScrollLock()
  useEffect(() => {
    scrollLock()
  }, [])

  return (
    <RightLayoutPopup
      title={intl.formatMessage({id: 'CreateDocument.file.title'})}
      className='carrier-upload-document-wrap'
      isCloseIcon
      closeOnClick={() => onClose()}
    >
      <div className={''}>
        <div className='fv-row mb-10'>
          <label className='form-label fs-6 fw-bolder text-dark'>
            {intl.formatMessage({id: 'CreateDocument.file.document'})}
          </label>
          <input
            placeholder={intl.formatMessage({
              id: 'CreateDocument.file.document',
            })}
            className={clsx('form-control form-control-lg')}
            onChange={(event: any) => setDocument(event.target.files[0])}
            type='file'
            name='Driver License'
          />
        </div>

        <div className='text-center mt-5 row freightPost-popup-container'>
          <div className={'col-md-6'}>
            <button
              onClick={onClose}
              type='button'
              id='kt_sign_up_submit'
              className='btn btn-lg btn-danger w-100 mb-5 '
            >
              {intl.formatMessage({id: 'CreateNewContainerPopup.cancel'})}
            </button>
          </div>
          <div className={'col-md-6'}>
            <button
              className={'btn btn-info w-100 px-5'}
              onClick={() => {
                setLoading(true)
                handleUploadDocument(document)
              }}
            >
              {intl.formatMessage({id: 'CreateDocument.file.uploadDoc'})}
            </button>
          </div>
        </div>
      </div>
    </RightLayoutPopup>
  )
}

export default UploadDocument
