import React from 'react';
import './TableLoader.scss'

const TableLoader = () => {
    return <div className={'loading-component-main-layer-table-data'}>
        <button className={'loading-btn-table'}></button >
    </div>;
};

export default TableLoader;
